import React, { Component } from 'react';
import { Field } from 'react-final-form';

import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import Input from './formComponents/Input';
import InputNumber from './formComponents/InputNumber';

class SettingsForm extends Component {
    render() {
        return <div>
            <Field
                name='unitExchange'
                component={InputNumber}
                label='Курс юнита' />
            <Field
                name='nds'
                component={InputNumber}
                label='Значение НДС' />
            <Field
                name='ensiServiceContent'
                component={Input}
                label='Услуга' />
            <Field
                name='ensiServiceCode'
                component={Input}
                label='Код услуги из классификации ' />
            <Field
                name='ensiResponsibilityCenterCode'
                component={Input}
                label='Код ЦФО' />
            <Field
                name='ensiResponsibilityCode'
                component={Input}
                label='Код ответственного за счёт' />
            <Field
                name='ensiNdsType'
                component={Input}
                label='Тип НДС' />
            <SubmitButton type='primary'>Сохранить</SubmitButton>
        </div>;
    }
}

export default withFormWrapper(SettingsForm, {
    mapPropsToValues: ({ item }) => item
});
