import React, { Component } from 'react';
import { message } from 'antd';
import { path } from 'ramda';
import { withAsyncActions } from 'react-async-client';

import CommandForm from '../forms/CommandForm';
import { postCommand } from '../../actions/asyncActions';
import Modal from './Modal';

class CommandModal extends Component {
    render() {
        const { modal, postCommand } = this.props;

        return <Modal
            {...modal}
            title='Добавить команду'
            footer={null}>
            <CommandForm
                formAction={postCommand}
                command={path(['params', 'command'], this.props)}
                inputFilesRoutes={path(['params', 'inputFilesRoutes'], this.props)} />
        </Modal>;
    }
}

export default withAsyncActions({
    postCommand: postCommand
        .withSuccessHandler(({ close }) => {
            message.success('Команда была успешно добавлена');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(CommandModal);
