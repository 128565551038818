import React, { Component } from 'react';
import { Radio } from 'antd';

import withFieldWrapper from '../../hocs/withFieldWrapper';

class RadioComponent extends Component {
    onChange = e => {
        this.props.onChange(e.target.checked);
    }

    render() {
        const { input: { value }, disabled } = this.props;

        return <Radio
            checked={!!value}
            disabled={disabled}
            onChange={this.onChange} />;
    }
}

export default withFieldWrapper(RadioComponent);
