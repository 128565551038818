import React from 'react';
import Modal from './Modal';

export default function CommandDebugModal({ params: { command = {} }, modal }) {
    return <Modal
        {...modal}
        title='Результат выполнения команды'
        footer={null}>
        {command.status === 'fail' &&
        <code
            dangerouslySetInnerHTML={{ __html: command.errorOutput.replace(/\n/g, '<br />') }}
        />
        }
    </Modal>;
}
