import React, { PureComponent, Component, Fragment } from 'react';
import { Button, Popconfirm, message, Tooltip } from 'antd';
import * as yup from 'yup';
import { Field } from 'react-final-form';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withAsyncActions } from 'react-async-client';
import { withRouter } from 'react-router-dom';
import { pathOr, contains, omit, isEmpty, path } from 'ramda';
import { EditOutlined, LinkOutlined, DeleteOutlined, EyeOutlined, EyeInvisibleOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import SubmitButton from './formComponents/SubmitButton';
import { patchCategory, deleteCategory, putCategory, postCategory, getUser } from '../../actions/asyncActions';
import { openQuestionsDistributionModal, openCategoryErrorModal } from '../../actions/modalActions';
import { CATEGORY_POPPER_MODAL_HEIGHT, CATEGORY_POPPER_BUTTONS_HEIGHT } from '../../constants/questions';
import Modal from '../modals/Modal';
import { getFilters, extendSearchPath } from '../../utils/urlParams';
import ERRORS, { CATEGORY_IN_PROJECT_ERROR } from '../../constants/errors';

const FormWrapper = styled.div`
    position: relative;
    .ant-form-item {
        margin-bottom: 5px;
    }
`;

const Buttons = styled.div`
    margin-top: 5px;
    display: flex;
    min-width: auto;
    button {
        width: 100%;
    }
`;

const ActionButtons = styled.div`
    position: absolute;
    top: ${({ top }) => -11 - top}px;
    left: 290px;
    display: flex;
    flex-direction: column;
    button {
        background: rgba(0,0,0,.6);
        border-radius: 3px;
        color: #e6e6e6;
        padding: 6px 12px 6px 8px;
        border: 0;
        margin-bottom: 5px;
    }
`;

const StyledModal = styled(Modal)`
    margin: 0 !important;
    padding-bottom: 0 !important;
    .ant-modal-body {
        padding: 0;
    }
`;

class CategoryForm extends Component {
    componentDidMount() {
        this.props.getRef(this);
    }

    cancel = () => {
        this.props.form.reset();
        this.props.onVisibleChange(false);
    }

    changeVisible = () => {
        this.props.patchVisibleCategory.dispatch({
            id: this.props.item.id,
            data: [{ op: 'replace', path: '/visible', value: !this.props.item.visible }]
        });
    }

    changeValid = () => {
        this.props.patchValidCategory.dispatch({
            id: this.props.item.id,
            data: [{ op: 'replace', path: '/valid', value: !this.props.item.valid }]
        });
    }

    changeСasesEnabled = () => {
        this.props.patchСasesEnabled.dispatch({
            id: this.props.item.id,
            data: [{ op: 'replace', path: '/casesEnabled', value: !this.props.item.casesEnabled }]
        });
    }

    delete = () => {
        const { deleteCategory, item } = this.props;

        deleteCategory.dispatch(item.id);
    }

    render() {
        const { handleSubmit, edit, item, patchVisibleCategory, patchValidCategory, patchСasesEnabled, deleteCategory, buttonsTop } = this.props;
        const visiblePending = patchVisibleCategory.meta.pending;
        const validPending = patchValidCategory.meta.pending;
        const deletePending = deleteCategory.meta.pending;
        const casesEnabledPending = patchСasesEnabled.meta.pending;

        return <FormWrapper>
            <Field
                name='name'
                component={Input}
                placeholder='Название'
                showTextError={false} />
            { !!item.company &&
                <Field
                    name='code'
                    component={Input}
                    placeholder='Код'
                    showTextError={false} />
            }
            { edit &&
                <ActionButtons top={buttonsTop}>
                    <Popconfirm
                        title='Вы уверены, что хотите удалить категорию?'
                        onConfirm={this.delete}>
                        <Button
                            loading={deletePending}
                            disabled={deletePending}
                            icon={<DeleteOutlined />}>
                            Удалить
                        </Button>
                    </Popconfirm>
                    <Button
                        loading={visiblePending}
                        disabled={visiblePending}
                        icon={item.visible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                        onClick={this.changeVisible}>
                        { item.visible ? 'Скрыть' : 'Показать' }
                    </Button>
                    <Button
                        loading={validPending}
                        disabled={validPending}
                        icon={item.valid ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                        onClick={this.changeValid}>
                        {item.valid ?  'Закрыть доступ' : 'Открыть доступ'}
                    </Button>
                    <Button
                        loading={casesEnabledPending}
                        disabled={casesEnabledPending}
                        icon={item.casesEnabled ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                        onClick={this.changeСasesEnabled}>
                        {item.casesEnabled ?  'Запретить распределение' : 'Использовать распределение'}
                    </Button>
                </ActionButtons>
            }
            <Buttons>
                <SubmitButton type='primary' onClick={handleSubmit} disabled={validPending || visiblePending}>
                    { edit ? 'Изменить' : 'Добавить' }
                </SubmitButton>
            </Buttons>
        </FormWrapper>;
    }
}

const validationSchema = yup.object().shape({
    name: yup.string().required()
});

const stateToProps = state => {
    const user = getUser.selectData(state);

    return {
        userCompany: user.ownQuestions ? user.company : null
    };
}

const Form = connect(stateToProps, { openCategoryErrorModal })(withRouter(withFormWrapper(withAsyncActions({
    patchVisibleCategory: patchCategory
        .withParams(({ item, common }) => ({ parent: common ? null : item.parent, id: item.id, type: 'visible' }))
        .withSuccessHandler(({ form, onVisibleChange }) => {
            form.reset();
            onVisibleChange(false);
        })
        .withOptions({ resetOnUnmount: true }),
    patchValidCategory: patchCategory
        .withParams(({ item, common }) => ({ parent: common ? null : item.parent, id: item.id, type: 'valid' }))
        .withOptions({ resetOnUnmount: true }),
    patchСasesEnabled: patchCategory
        .withParams(({ item, common }) => ({ parent: common ? null : item.parent, id: item.id, type: 'casesEnabled' }))
        .withOptions({ resetOnUnmount: true }),
    deleteCategory: deleteCategory
        .withParams(({ item, common }) => ({ parent: common ? null : item.parent, id: item.id }))
        .withSuccessHandler(({ onVisibleChange, deleteCategory: { data: { id }}, location, history }) => {
            const filters = getFilters(location) || {};
            const categories = pathOr([], ['categories'], getFilters(location));

            if (contains(id, categories)) {
                const newFilter = omit(['categories'], filters);

                history.replace(extendSearchPath(location, {
                    filter: isEmpty(newFilter) ? null : JSON.stringify(newFilter),
                    offset: 0
                }));
            }
            message.success('Компетенция успешно удалена');
            onVisibleChange(false);
        })
        .withErrorHandler(({ openCategoryErrorModal, deleteCategory: { meta: { error }}}) => {
            const errorMessage = path(['data', 'errors', 0, 'message'], error);
            const isProjectError = CATEGORY_IN_PROJECT_ERROR === errorMessage;

            if (isProjectError) {
                openCategoryErrorModal({ projects: path(['data', 'attributes', 'projects'], error) });
            }

            message.error(ERRORS[errorMessage] || 'Не удалось удалить компетенцию');
        })
        .withOptions({ resetOnUnmount: true }),
    putCategory: putCategory
        .withParams(({ item, common }) => ({ parent: common ? null : item.parent, id: item.id }))
        .withSuccessHandler(({ putCategory: { data }, form }) => form.reset(data)),
    postCategory: postCategory
        .withParams(({ item, common }) => ({ parent: common ? null : item.parent }))
        .withSuccessHandler(({ form }) => form.reset())
})(CategoryForm), {
    validationSchema,
    mapPropsToValues: ({ item }) => item,
    mapBeforeSubmit: (values, { edit, location, userCompany }) => edit ? values : ({
        ...values,
        company: path(['company'], getFilters(location)) || userCompany
    }),
    enableReinitialize: false
})));

class CategoryFormPopover extends PureComponent {
    open = () => this.props.onVisibleChange(true);

    getPosition = () => {
        const ref = this.props.elementRef || this.addBtn;

        if (!ref) {
            return {};
        }

        const { top, left } = (ref.buttonNode || ref).getBoundingClientRect();
        const height = document.documentElement.clientHeight;

        return {
            top: height > top + CATEGORY_POPPER_MODAL_HEIGHT ? top : `calc(100% - ${CATEGORY_POPPER_MODAL_HEIGHT}px - 10px)`,
            left,
            buttonsTop: height > top + CATEGORY_POPPER_BUTTONS_HEIGHT ? 0 : 15
        };
    }

    openLinkModal = () => {
        const { openQuestionsDistributionModal, item, common } = this.props;

        openQuestionsDistributionModal({
            item,
            common
        });
    }
    render() {
        const position = this.getPosition();

        return <Fragment>
            <span ref={node => this.anchorEl = node}>
                { this.props.edit ?
                    <EditOutlined onClick={this.open} /> :
                    <Button type='link' size='small' onClick={this.open} ref={node => this.addBtn = node}>добавить</Button>
                }
            </span>
            { this.props.edit &&
                <Tooltip title='Привязка к распределению'>
                    <LinkOutlined onClick={this.openLinkModal} />
                </Tooltip>
            }
            <Modal
                visible={this.props.visible}
                onCancel={e => {
                    e.stopPropagation();
                    this.form.cancel();

                }}
                title={null}
                footer={null}
                closable={false}
                width={290}
                maskClosable
                style={{ left: position.left, top: position.top }}
                ModalComponent={StyledModal}>
                <div onClick={e => e.stopPropagation()} style={{ padding: 10 }}>
                    <Form {...this.props} buttonsTop={position.buttonsTop} getRef={node => this.form = node} />
                </div>
            </Modal>
        </Fragment>
    }
}

export default connect(null, { openQuestionsDistributionModal })(CategoryFormPopover);
