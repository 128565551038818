import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import Modal from './Modal';
import { postQuestionnary, putQuestionnary } from '../../actions/asyncActions';
import QuestionnaryForm from '../forms/QuestionnaryForm';

class QuestionnaryModal extends Component {
    render() {
        const { modal, postQuestionnary, putQuestionnary, params } = this.props;

        return <Modal
            {...modal}
            title={params ? 'Редактировать опросник' : 'Добавить опросник'}
            footer={null}>
            <QuestionnaryForm formAction={params ? putQuestionnary : postQuestionnary} item={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    postQuestionnary: postQuestionnary
        .withSuccessHandler(({ close }) => {
            message.success('Опросник был успешно добавлен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось добавить опросник'))
        .withOptions({ resetOnUnmount: true }),
    putQuestionnary: putQuestionnary
        .withSuccessHandler(({ close }) => {
            message.success('Опросник успешно изменен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось изменить опросник'))
        .withOptions({ resetOnUnmount: true })
})(QuestionnaryModal);
