import React, { Component, Fragment } from 'react';
import { prop, pathOr } from 'ramda';
import * as yup from 'yup';
import { Field } from 'react-final-form';
import { withAsyncActions } from 'react-async-client';
import { Form, Switch } from 'antd';

import withFormWrapper from '../../hocs/withFormWrapper';
import SubmitButton from '../formComponents/SubmitButton';
import Input from '../formComponents/Input';
import { PhoneInput } from '../formComponents/MaskedInput';
import Select from '../formComponents/Select';
import { getBusinessUnitsOptions } from '../../../utils/companies';
import { getBusinessUnits } from '../../../actions/asyncActions';
import ListenerField from '../ListenerField';

const inlineLabelCol = {
    xs: { span: 24 },
    sm: { span: 6 },
};

const inlineWrapperCol = {
    xs: { span: 24 },
    sm: { span: 18 },
};

class SurveyRespondentForm extends Component {
    state = {
        addToEmployees: false,
        hideAdditional: true
    };

    componentDidMount() {
        const { getRef } = this.props;

        getRef && getRef(this);
    }

    reset = () => {
        if (this.state.addToEmployees) {
            this.setState({ addToEmployees: false });
        }
        this.props.form.reset();
    }

    toggleAddToEmployees = () => this.setState(prev => ({ addToEmployees: !prev.addToEmployees }));

    toggleAdditional = () => this.setState(prev => ({ hideAdditional: !prev.hideAdditional }));

    render() {
        const { item, groups, block } = this.props;
        const { addToEmployees } = this.state;

        const showGroupField = groups && groups.length > 1;
        const showEmployeeField = !item.id;
        const showPositionField = addToEmployees || (item.id && item.businessUnit);
        const wrapperCol = block ? {} : inlineWrapperCol;
        const labelCol = block ? {} : inlineLabelCol;

        return <Fragment>
            <h3>Информация</h3>
            <Field
                name='lastName'
                component={Input}
                label='Фамилия'
                labelCol={labelCol}
                wrapperCol={wrapperCol} />
            <Field
                name='firstName'
                component={Input}
                label='Имя'
                labelCol={labelCol}
                wrapperCol={wrapperCol} />
            <Field
                name='middleName'
                component={Input}
                label='Отчество'
                labelCol={labelCol}
                wrapperCol={wrapperCol} />
            <ListenerField listenFieldName='email'>
                { ({ email }) =>
                    <Field
                        name='code'
                        component={Input}
                        label='Код'
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                        hideOptional={!(email || '').length} />
                }
            </ListenerField>
            <h3>Контакты</h3>
            <ListenerField listenFieldName='code'>
                { ({ code }) =>
                    <Field
                        name='email'
                        component={Input}
                        label='Email'
                        type='email'
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                        hideOptional={!(code || '').length} />
                }
            </ListenerField>
            <Field
                name='phone'
                component={PhoneInput}
                label='Телефон'
                labelCol={labelCol}
                wrapperCol={wrapperCol} />
            { (showGroupField || showEmployeeField || showPositionField) && (
                <h3>Прочее</h3>
            )}
            { showGroupField &&
                <Field
                    name='group'
                    component={Select}
                    label='Группа'
                    options={groups}
                    labelCol={labelCol}
                    wrapperCol={wrapperCol} />
            }
            { showEmployeeField &&
                <Form.Item
                    label='Добавить в сотрудники'
                    labelCol={labelCol}
                    wrapperCol={wrapperCol}>
                    <Switch
                        checked={addToEmployees}
                        onChange={this.toggleAddToEmployees} />
                </Form.Item>
            }
            { showPositionField &&
                <Field
                    name='businessUnit'
                    component={Select}
                    label='Должность'
                    options={getBusinessUnitsOptions(pathOr([], ['data', 'items'], this.props.getBusinessUnits))}
                    wrapperCol={wrapperCol}
                    labelCol={labelCol}
                    allowClear={!item.id} />
            }
            <SubmitButton type='primary'>Сохранить</SubmitButton>
        </Fragment>;
    }
}

const validationSchema = () => yup.object().shape({
    firstName: yup.string().nullable().required(),
    lastName: yup.string().nullable().required(),
    email: yup.string().email().nullable().when('code', (value, schema) => value ? schema : schema.required('Заполните одно из полей: email, код')),
    code: yup.string().nullable(),
    phone: yup.string().nullable().phone(),
});

export default withAsyncActions({
    getBusinessUnits: getBusinessUnits
        .withPayload(({ item }) => item.company)
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})(
    withFormWrapper(SurveyRespondentForm, {
        validationSchema,
        mapPropsToValues: prop('item'),
    })
);
