import React from 'react';
import { Switch } from 'react-router-dom';
import styled from 'styled-components';

import Login from '../../guest/Login';
import BGLogin from '../../../assets/img/bg-login.jpg';
import Route from '../../Route';
import Recovery from '../../guest/Recovery';
import ResetPassword from '../../guest/ResetPassword';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-image: url("${BGLogin}");
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (orientation: landscape) and (max-width: 896px) {
        padding: 15px 0;
    }
`;

const Content = styled.div`
    position: relative;
    z-index: 2;
    background-color: #fff;
    box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
    border-radius: 10px;
    padding: 35px 40px 40px;
    text-align: center;
    width: 400px;
    @media (max-width: 767px) {
        margin: 0 15px;
        padding: 40px 20px 45px;
    }
    @media screen and (orientation: landscape) and (max-width: 896px) {
        padding: 10px 20px;
        .ant-row {
            margin-bottom: 10px;
        }
    }
    .ant-btn-primary {
        padding: 0 20px;
        .ant-btn-loading-icon {
          display: none;
        }
    }
`;

const GuestLayout = () =>
    <Container>
        <Content>
            <Switch>
                <Route path='/recovery/:id' component={ResetPassword} />
                <Route path='/recovery' component={Recovery} />
                <Route path='/' component={Login} />
            </Switch>
        </Content>
    </Container>;

export default GuestLayout;
