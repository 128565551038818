import React, { Component, Fragment } from 'react';
import { Switch, message } from 'antd';
import { withState } from 'recompose';
import { Link } from 'react-router-dom';
import { asyncConnect } from 'react-async-client';
import { append, includes, path, without } from 'ramda';
import { QuestionCircleOutlined } from '@ant-design/icons';

import Table from '../table/Table';
import TableList from '../table/TableList';
import { getQuestionnaries, patchCompany } from '../../../actions/asyncActions';
import ClientAllowSwitch from './ClientAllowSwitch';

class CompanySurveyQuestionnaires extends Component {
    getColumns = () => {
        return [
            this.props.isAdmin && {
                title: 'Доступно',
                dataIndex: 'id',
                key: 'selected',
                width: 120,
                align: 'center',
                render: (id) =>  <Switch
                    checked={includes(id, this.props.company.surveyQuestionnaires)}
                    onChange={value => this.updateCompany(id, value)}
                />
            },
            {
                title: 'Опросник',
                dataIndex: 'name',
                key: 'name',
                width: 250,
                render: this.props.isAdmin ? (
                    (name, { id }) => <Link className='link' to={`/questionnaries/${id}/info`}>{ name || '-' }</Link>
                ) : undefined,
            },
            {
                title: 'Описание',
                dataIndex: 'description',
                key: 'description',
            },
            {
                title: 'Кол-во вопросов',
                dataIndex: 'drivers',
                key: 'amount',
                width: 160,
                render: drivers => {
                    const questionsAmount = (drivers || []).reduce((res, cur) => res + (cur.questions || []).length, 0);

                    return <span><QuestionCircleOutlined /> { questionsAmount }</span>;
                }
            }
        ].filter(Boolean);
    }

    updateCompany = (value, selected) => {
        const { id, company, patchCompany } = this.props;

        patchCompany.dispatch({
            id,
            data: [{ op:
                'replace',
                path: '/surveyQuestionnaires',
                value: selected ? append(value, company.surveyQuestionnaires) : without([value], company.surveyQuestionnaires ),
            }]
        });
    }

    render() {
        const { company } = this.props;

        return this.props.isAdmin ? (
            <Fragment>
                <ClientAllowSwitch id={company.id} commonSettings={company.commonSettings} path='clientAllowQuestionnaries' style={{ padding: '15px 35px' }} />
                <TableList
                    action={getQuestionnaries}
                    externalPending={this.props.companyMeta.pending || this.props.patchCompany.meta.pending}
                    columns={this.getColumns()} />
            </Fragment>
        ) : (
            <Table
                action={{
                    data: { items: path(['user', '_embedded', 'company', '_embedded', 'surveyQuestionnaires'], this.props) },
                    meta: { success: true },
                }}
                externalPending={this.props.companyMeta.pending || this.props.patchCompany.meta.pending}
                columns={this.getColumns()} />
        );
    }
}

export default withState('selected', 'setSelected', [])(
    asyncConnect({
        patchCompany: patchCompany
            .withSuccessHandler(() => message.success('Состояние успешно изменено'))
            .withErrorHandler(() => message.error('Не удалось изменить состояние'))
            .withOptions({ resetOnUnmount: true })
    })(CompanySurveyQuestionnaires)
);
