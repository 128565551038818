import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';

import Modal from './Modal';
import PaymentForm from '../forms/PaymentForm';
import { postCompanyPayment } from '../../actions/asyncActions';
import { message, Alert } from 'antd';

class PaymentModal extends Component {
    render() {
        const { modal, params, postCompanyPayment } = this.props;

        return <Modal
            {...modal}
            title='Пополнение'
            footer={null}>
            <Alert message={
                <div>
                    <div>Пополните баланс юнитов для выставления счета</div>
                    <div>Курс юнита 1 = 500 &#8381;</div>
                </div>
            } />
            <PaymentForm
                item={{ company: params.company, value: 1 }}
                formAction={postCompanyPayment} />
        </Modal>
    }
}

export default withAsyncActions({
    postCompanyPayment: postCompanyPayment
        .withSuccessHandler(({ close }) => {
            message.success('Баланс компании успешно пополнен');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(PaymentModal);
