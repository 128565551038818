import evolve from './evolve';
import { parseCompanyTemplate } from '../utils/companies';

const letterEncode = evolve({
    content: string => parseCompanyTemplate(string)
});

export default [
    {
        pattern: /companies\/\w+\/templates(\/\w+)?$/,
        encode: evolve({
            content: string => parseCompanyTemplate(string)
        }),
        decode: evolve({
            items: {
                content: string => parseCompanyTemplate(string, true)
            }
        })
    },
    {
        pattern: /companies\/\w+\/projects\/\w+\/messages/,
        encode: letterEncode
    },
    {
        pattern: /survey\/messages/,
        encode: letterEncode
    },
    {
        pattern: /test_templates\/\w+\/messages$/,
        encode: letterEncode
    },
    {
        pattern: /test_templates\/\w+\/respondent_messages$/,
        encode: letterEncode
    }
];
