import React from 'react';
import { Field } from 'react-final-form';
import { Col } from 'antd';

import BaseFilter from './BaseFilter';
import { getRespondents, getCompanyTests } from '../../../actions/asyncActions';
import withFilterForm from '../../hocs/withFilterForm';
import Select from '../formComponents/Select';

export default withFilterForm(({ staticFilter: { company, projects }, filterProps }) =>
    <BaseFilter
        action={getRespondents}
        searchFieldName='text'
        searchFieldPlaceholder='Поиск'
        span={12}>
        <Col span={12}>
            <Field
                name='testSuites'
                component={Select}
                action={getCompanyTests}
                payload={{ project: projects[0], company }}
                placeholder='Тест'
                namePath='name'
                hide={!filterProps.showTests}
                allowClear
                isMulti />
        </Col>
    </BaseFilter>
);
