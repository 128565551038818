import React, { Component } from 'react';
import { message } from 'antd';
import { withAsyncActions } from 'react-async-client';
import { pluralize } from 'numeralize-ru';
import { pathOr } from 'ramda';

import Modal from './Modal';
import LetterForm from '../forms/LetterForm';
import { postTestTemplateRespondentMessages } from '../../actions/asyncActions';

class TestTemplateRespondentMessagesModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            count: pathOr([], ['params', 'item', 'respondents'], props).length
        };
    }

    setCount = count => this.setState({ count });

    render() {
        const { modal, params: { item }, postTestTemplateRespondentMessages } = this.props;
        const { count } = this.state;

        return <Modal
            {...modal}
            title={`Отправить письмо ${count} ${pluralize(count, 'респонденту', 'респондентам', 'респондентам')}`}
            footer={null}>
            <LetterForm
                formAction={postTestTemplateRespondentMessages}
                item={item}
                setCount={this.setCount}
                respondentsField='respondents' />
        </Modal>
    }
}

export default withAsyncActions({
    postTestTemplateRespondentMessages: postTestTemplateRespondentMessages
        .withSuccessHandler(({ close, params: { clearSelected } }) => {
            message.success('Письмо было успешно отправлено');
            clearSelected();
            close();
        })
        .withErrorHandler(() => message.error('Не удалось отправить письмо'))
        .withOptions({ resetOnUnmount: true })
})(TestTemplateRespondentMessagesModal);
