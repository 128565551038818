import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';

const Wrapper = styled.div`
    display: inline-flex;
    align-items: baseline;
    margin-right: 2px;
`;

const Bar = styled.div`
    background: ${({ level, index }) => level === 3 ? '#f54d2e' : (level === 2 && index !== 2) ? '#ED9D29' : level === 1 && !index ? '#5cb85c' : '#B4B4B4'};
    width: 3px;
    margin-right: 2px;
    height: ${({ index }) => index === 2 ? 15 : index === 1 ? 11 : 6}px;
`;

const Complexity = ({ level }) =>
    <Tooltip title={level === 3 ? 'Сложный' : level === 2 ? 'Средний' : 'Легкий'}>
        <Wrapper>
            <Bar index={0} level={level} />
            <Bar index={1} level={level} />
            <Bar index={2} level={level} />
        </Wrapper>
    </Tooltip>;

export default Complexity;
