import React from 'react';
import Complexity from '../components/user/table/Complexity';

export const QUESTION_LEVELS = [
    { id: 1, value: 'Легкий', icon: <Complexity level={1} /> },
    { id: 2, value: 'Средний', icon: <Complexity level={2} /> },
    { id: 3, value: 'Сложный', icon: <Complexity level={3} /> }
];

export const CATEGORY_POPPER_MODAL_HEIGHT = 90;
export const CATEGORY_POPPER_BUTTONS_HEIGHT = 110;

export const QUESTION_TYPES = [
    { id: 'choice', value: 'Выбор' },
    { id: 'open', value: 'Открытый' },
    { id: 'ranging', value: 'Ранжирование' }
];
