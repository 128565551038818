import React, { Component } from 'react';
import { Input } from 'antd';

import withFieldWrapper from '../../hocs/withFieldWrapper';

class InputSearchComponent extends Component {
    render() {
        const { input: { value }, placeholder, className, onChange: onSearch } = this.props;

        return <Input.Search
            className={className}
            onSearch={onSearch}
            placeholder={placeholder}
            defaultValue={value}
        />;
    }
}

export default withFieldWrapper(InputSearchComponent);
