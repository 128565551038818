import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { fromPairs } from 'ramda';
import * as yup from 'yup';
import styled from 'styled-components';

import { getQuestionnariesReportTypes } from '../../actions/asyncActions';
import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import Textarea from './formComponents/Textarea';
import InputNumber from './formComponents/InputNumber';
import Editor from './formComponents/Editor';
import InlineEditor from './formComponents/InlineEditor';
import Select from './formComponents/Select';
import SubmitButton from './formComponents/SubmitButton';

const FieldItem = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .ant-form-item {
        margin-bottom: 0;
        width: 100%;
        margin-right: 10px;
    }

    .ant-btn {
        width: 60px;
    }
`;

class QuestionnaryForm extends Component {
    renderScaleFields = ({ fields }) => {
        return <div>
            { fields.map((name, index) =>
                <FieldItem key={`variable-field-${index}`}>
                    <Field
                        name={`${name}.key`}
                        component={Input}
                        placeholder='Значение'
                        validate={value => !value ? 'required' : undefined}
                        hideErrorMsg />
                    <span style={{ marginRight: 15 }}>:</span>
                    <Field
                        name={`${name}.value`}
                        placeholder='Вес'
                        component={InputNumber}
                        hideErrorMsg />
                    <Button danger icon={<DeleteOutlined />} onClick={() => fields.remove(index)} />
                </FieldItem>
            )}
            <Button icon={<PlusOutlined />} onClick={() => fields.push('')} style={{ marginBottom: 15 }}>
                Добавить значение
            </Button>
        </div>;
    }

    render() {
        return <Fragment>
            <Field
                name='name'
                component={Input}
                label='Название' />
            <Field
                name='description'
                component={Textarea}
                label='Описание' />
            <Field
                name='availableReports'
                component={Select}
                action={getQuestionnariesReportTypes}
                label='Отчеты'
                valuePath='type'
                namePath='title'
                optionsPath={['asyncAction', 'data']}
                allowClear
                isMulti />
            { this.props.item &&
                <Fragment>
                    <Field
                        name='title'
                        component={InlineEditor}
                        label='Заголовок'
                        inline
                        translations />
                    <Field
                        name='welcomeText'
                        component={Editor}
                        label='Текст приветствия' />
                    <Form.Item label='Шкала' wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
                        <FieldArray name='scale'>
                            { this.renderScaleFields }
                        </FieldArray>
                    </Form.Item>
                </Fragment>
            }
            <SubmitButton type='primary'>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    name: yup.string().nullable().required()
});

export default withFormWrapper(QuestionnaryForm, {
    validationSchema,
    mapPropsToValues: ({ item }) => item ? ({
        ...item,
        scale: (item.scale).map(key => ({
            key,
            value: (item.answerMap || {})[key]
        }))
    }) : {},
    mapBeforeSubmit: (values) => ({
        ...values,
        scale: (values.scale || []).map(({ key }) => key),
        answerMap: fromPairs((values.scale || []).map(({ key, value }) => ([ key, value ]))),
    }),
});
