import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';

import Modal from './Modal';
import { postDistribution, putDistribution } from '../../actions/asyncActions';
import { message } from 'antd';
import DistributionForm from '../forms/DistributionForm';

class DistributionModal extends Component {
    render() {
        const { modal, params, postDistribution, putDistribution } = this.props;

        return <Modal
            {...modal}
            title={params.id ? 'Редактировать распределение вопросов' : 'Добавить распределение вопросов'}
            footer={null}>
            <DistributionForm
                formAction={params.id ? putDistribution : postDistribution}
                item={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    postDistribution: postDistribution
        .withSuccessHandler(({ close }) => {
            message.success('Распредение вопросов успешно добавлено');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось добавить распределение вопросов'))
        .withOptions({ resetOnUnmount: true }),
    putDistribution: putDistribution
        .withSuccessHandler(({ close }) => {
            message.success('Распределение вопросов успешно изменено');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось изменить распределение вопросов'))
        .withOptions({ resetOnUnmount: true })
})(DistributionModal);
