import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import CompanyPublicPageForm from '../../forms/CompanyPublicPageForm';
import { putCompany } from '../../../actions/asyncActions';

class CompanyPublicPage extends Component {
    render() {
        const { putCompany, company } = this.props;

        return <CompanyPublicPageForm
            formAction={putCompany}
            item={company} />;
    }
}

export default withAsyncActions({
    putCompany: putCompany
        .withSuccessHandler(() => message.success('Дизайн страницы успешно сохранен'))
        .withErrorHandler(() => message.error('Не удалось сохранить дизайн страницы'))
        .withOptions({ resetOnUnmount: true })
})(CompanyPublicPage);
