import React, { Component, Fragment } from 'react';
import { Table, Button, Popconfirm, message, Tooltip } from 'antd';
import { asyncConnect } from 'react-async-client';
import styled from 'styled-components';
import { EditOutlined, SettingOutlined, UnlockOutlined, LockOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import { deleteCompanyTest, getUser, putCompanyTestStatus } from '../../../actions/asyncActions';
import { openTestModal, openTestSettingsModal } from '../../../actions/modalActions';
import { ADMIN } from '../../../constants/roles';

const Header = styled.div`
    text-align: right;
    padding-bottom: 15px;
`;
const TableStyled = styled(Table)`
    .ant-table-thead > tr > th{
        padding: 10px;
        background: #fafafa;
        font-size: 1.17em;
    }
`;

class ProjectTestsStep extends Component {
    changeStatus = test => {
        this.props.putCompanyTestStatus.dispatch({
            company: test.company,
            project: test.project,
            id: test.id,
            data: {
                status: test.status === 'sent' ? 'formed' : 'sent'
            }
        });
    }

    getColumns = tests => {
        const { allowSettingsEdit, openTestSettingsModal, openTestModal, deleteCompanyTest } = this.props;

        return [
            {
                title: 'Название',
                key: 'name',
                dataIndex: 'name'
            },
            {
                key: 'actions',
                width: 150,
                align: 'right',
                render: item => {

                    return <Button.Group>
                        <Button
                            icon={<EditOutlined />}
                            onClick={() => openTestModal(item)} />
                        { allowSettingsEdit &&
                            <Button
                                icon={<SettingOutlined />}
                                onClick={() => openTestSettingsModal(item)} />
                        }
                        { this.props.isAdmin &&
                            <Tooltip title={item.status === 'sent' ? 'Разблокировать' : 'Заблокировать'}>
                                <Button
                                    icon={item.status === 'sent' ? <UnlockOutlined /> : <LockOutlined />} onClick={() => this.changeStatus(item)} />
                            </Tooltip>
                        }
                        { tests.length > 1 &&
                            <Popconfirm
                                title='Вы уверены, что хотите удалить тест?'
                                onConfirm={() => deleteCompanyTest.dispatch({
                                    project: item.project,
                                    company: item.company,
                                    id: item.id
                                })}>
                                <Button icon={<DeleteOutlined />} type='danger' />
                            </Popconfirm>
                        }
                    </Button.Group>;
                }
            }
        ]
    }
    render() {
        const { item, openTestModal, submitBtn, goPrev, goNext, hideAddBtn, testSuites } = this.props;

        return <Fragment>
            { item.id ?
                <Fragment>
                    { !hideAddBtn &&
                        <Header>
                            <Button
                                type='primary'
                                icon={<PlusOutlined />}
                                onClick={() => openTestModal({
                                    project: item.id,
                                    company: item.company,
                                    type: item.type
                                })}>
                                <span className='hide-mobile'>Добавить</span>
                            </Button>
                        </Header>
                    }
                    <TableStyled
                        dataSource={testSuites}
                        columns={this.getColumns(testSuites)}
                        pagination={false}
                        rowKey='id'
                        size='small' />
                </Fragment> : null
            }
            { submitBtn ||
                <Button.Group style={{ marginTop: 15 }}>
                    <Button onClick={goPrev}>Назад</Button>
                    <Button type='primary' onClick={goNext}>Далее</Button>
                </Button.Group>
            }
        </Fragment>;
    }
}

const stateToProps = state => ({
    isAdmin: getUser.selectData(state).role === ADMIN
});

export default asyncConnect({
    deleteCompanyTest: deleteCompanyTest
        .withSuccessHandler(() => message.success('Тест был успешно удален'))
        .withErrorHandler(() => message.error('Не удалось удалить тест'))
        .withOptions({ resetOnUnmount: true }),
    putCompanyTestStatus: putCompanyTestStatus
        .withSuccessHandler(({ putCompanyTestStatus }) => message.success(`Тест был успешно ${putCompanyTestStatus.data.status === 'formed' ? 'разблокирован' : 'заблокирован'}`))
        .withErrorHandler(() => message.error('Не удалось изменить статус теста'))
        .withOptions({ resetOnUnmount: true })
}, stateToProps, { openTestModal, openTestSettingsModal })(ProjectTestsStep);
