import { curry, map, mapObjIndexed, chain } from 'ramda';

import { isArray, isFunction, isPlainObject } from '../utils/ramdaAdditions';

const evolve = curry((transformations, item) => {
    if (isArray(item)) {
        return map(evolve(transformations), item);
    }

    return mapObjIndexed((value, key) => {
        const transformation = transformations[key];
        if (isFunction(transformation)) {
            if (isArray(value)) {
                return chain(val => transformation(val, item), value);
            }
            return transformation(value, item);
        } else if (isPlainObject(transformation)) {
            return evolve(transformation, value);
        }
        return value;
    }, item);
});

export default evolve;
