import 'react-app-polyfill/ie11';
import 'string.prototype.startswith';
import 'ie-array-find-polyfill';
import 'core-js/modules/es6.array.find-index';
import 'core-js/modules/es7.object.values';

import React from 'react';
import ReactDOM from 'react-dom';
import dotenv from 'dotenv';
import { locale } from 'moment';
import 'moment/locale/ru';
import 'antd/dist/antd.css';
import 'yup';

import './utils/validation';
import App from './components/App';
import { setBaseUrl } from './utils/http';
import { provideResponseInterceptors, provideRequestInterceptors } from './utils/httpInterceptors';
import configureStore from './store/configureStore';
import rollbarInit from './utils/rollbar';
import './assets/styles/index.less';

setBaseUrl();
provideResponseInterceptors();
provideRequestInterceptors();
locale('ru');

window.pdfMake = require('pdfmake/build/pdfmake.min');
const robotoVft = require('pdfmake/build/vfs_fonts');
window.pdfMake.vfs = robotoVft.pdfMake.vfs;

export const store = configureStore();
const MOUNT_NODE = document.getElementById('root');

if (process.env.NODE_ENV === 'production') {
    rollbarInit({
        token: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
        version: process.env.REACT_APP_VERSION,
        environment: window.location.host
    });

    window._version = {
        version: process.env.REACT_APP_VERSION,
    };
}

dotenv.config();

window._mercureHubUrl = process.env.REACT_APP_MERCURE_URL || '/_mercure/hub';

ReactDOM.render(<App store={store} />, MOUNT_NODE);
