import React, { Component, Fragment } from 'react';
import { prop } from 'ramda';
import * as yup from 'yup';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import InputNumber from './formComponents/InputNumber';
import Switch from './formComponents/Switch';
import Select from './formComponents/Select';
import { getCompanies, getUser } from '../../actions/asyncActions';
import { ADMIN } from '../../constants/roles';

class PaymentForm extends Component {
    onChangeValue = value => {
        if (!value) {
            this.props.form.change('value', 1);
        }
    }

    render() {
        const { isAdmin } = this.props;

        return <Fragment>
            { !this.props.item.company &&
                <Field
                    name='company'
                    component={Select}
                    label='Компания'
                    action={getCompanies}
                    filter={{ enabled: true }}
                    placeholder='Компания'
                    namePath='name' />
            }
            <Field
                name='value'
                component={InputNumber}
                label='Юниты'
                min={1}
                onChange={this.onChangeValue} />
            { isAdmin &&
                <Field
                    name='status'
                    component={Switch}
                    label='Оплачено'
                    hideOptional />
            }
            <SubmitButton type='primary'>Пополнить</SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    company: yup.string().required(),
    value: yup.number().min(1, 'Количество юнитов должно быть больше 0').required()
});

const stateToProps = state => ({
    isAdmin: getUser.selectData(state).role === ADMIN
});

export default connect(stateToProps)(
    withFormWrapper(PaymentForm, {
        mapPropsToValues: prop('item'),
        mapBeforeSubmit: values => ({
            ...values,
            status: values.status ? 'paid' : 'request'
        }),
        validationSchema
    })
);
