import React from 'react';
import { Field } from 'react-final-form';
import { Col } from 'antd';
import { connect } from 'react-redux';
import { contains } from 'ramda';

import BaseFilter from './BaseFilter';
import { getRespondents, getCompanies, getCompanyProjects, getUser } from '../../../actions/asyncActions';
import withFilterForm from '../../hocs/withFilterForm';
import Select from '../formComponents/Select';
import ListenerField from '../ListenerField';
import { MANAGERS } from '../../../constants/roles';

const stateToProps = state => {
    const user = getUser.selectData(state);

    return {
        isManager: contains(user.role, MANAGERS),
        userCompany: user.company
    };
};

export default connect(stateToProps)(withFilterForm(({ form, isManager, userCompany }) =>
    <ListenerField listenFieldName='company'>
        { ({ company }) =>
            <BaseFilter
                action={getRespondents}
                searchFieldName='text'
                searchFieldPlaceholder='Поиск'
                span={company ? 8 : 12}>
                { !isManager &&
                    <Col span={company ? 8 : 12}>
                        <Field
                            name='company'
                            component={Select}
                            action={getCompanies}
                            filter={{ enabled: true }}
                            placeholder='Компания'
                            namePath='name'
                            onChange={value => !value && form.change('project', null)}
                            searchable
                            allowClear />
                    </Col>
                }
                { (company || isManager) &&
                    <Col span={company ? 8 : 12}>
                        <Field
                            name='project'
                            component={Select}
                            action={getCompanyProjects}
                            payload={{ company: company || userCompany }}
                            placeholder='Тест'
                            namePath='name'
                            allowClear />
                    </Col>
                }
            </BaseFilter>
        }
    </ListenerField>
));
