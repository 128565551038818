import React, { Component } from 'react';
import { asyncConnect, toSuccess } from 'react-async-client';
import { prop } from 'ramda';
import { Alert } from 'antd';
import styled from 'styled-components';
import { takeEvery } from 'redux-saga/effects';
import { DeleteOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';

import { getCompanyTemplates, deleteCompanyTemplate } from '../../../actions/asyncActions';
import { Button, Spin, List, Card, Popconfirm, message } from 'antd';
import { openCompanyTemplateModal } from '../../../actions/modalActions';
import { POST_COMPANY_TEMPLATE, PUT_COMPANY_TEMPLATE, DELETE_COMPANY_TEMPLATE } from '../../../constants/actionTypes';

const Wrapper = styled.div`
    margin: 15px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
`;

const StyledCard = styled(Card)`
    width: 100%;
    p {
        margin: 0;
    }
    .variable-text {
        color: #1890ff;
    }
`;

const StyledList = styled(List)`
    &.ant-list{
        margin-top: 20px;
    }
    .ant-list-item{
        padding: 0;
    }
    .ant-card{
        border-radius: 6px;
    }
    .ant-card-head{
        border-top: 1px solid #e8e8e8;
        padding: 10px 25px;
        background: #fafafa;
        margin: -1px 0px;
        .ant-card-head-title,.ant-card-extra{
            padding: 0;
        }
    }
`;

class CompanyTemplates extends Component {
    addTemplate = () => this.props.openCompanyTemplateModal({ company: this.props.id });

    deleteTemplate = id => this.props.deleteCompanyTemplate.dispatch({ id, company: this.props.id });

    renderExtra = item => {
        const { openCompanyTemplateModal } = this.props;

        return <Button.Group>
            <Button icon={<EditOutlined />} onClick={() => openCompanyTemplateModal(item)} />
            <Popconfirm
                title='Вы уверены, что хотите удалить шаблон?'
                onConfirm={() => this.deleteTemplate(item.id)}
                okText='Да'
                cancelText='Нет'>
                <Button icon={<DeleteOutlined />} type='danger' />
            </Popconfirm>
        </Button.Group>;
    }

    render() {
        const { data: { items = [] }, meta } = this.props.getCompanyTemplates;

        return <Wrapper>
            <Header>
                <h1>Шаблоны писем { meta.pending && <Spin /> }</h1>
                <Button icon={<PlusOutlined />} type='primary' onClick={this.addTemplate}><span className='hide-mobile'>Добавить</span></Button>
            </Header>
            <Alert message="Здесь вы можете отредактировать шаблоны текстов писем для переписки с кандидатами. Письма настраиваются для всей компании. Для того, чтобы в отправляемых письмах отображались индивидуальные контакты кандидатов / рекрутеров, используйте переменные, которые будут отображаться справа при редактировании шаблонов." type="info" />
            <StyledList
                dataSource={items}
                loading={meta.pending}
                renderItem={item => (
                    <List.Item>
                        <StyledCard
                            title={item.title}
                            extra={this.renderExtra(item)}>
                            <div style={{ marginBottom: 10 }}><b>Тема письма:</b> { item.subject }</div>
                            <div dangerouslySetInnerHTML={{ __html: item.content }} />
                        </StyledCard>
                    </List.Item>
                )} />
        </Wrapper>
    }
}

export default asyncConnect({
    getCompanyTemplates: getCompanyTemplates
        .withPayload(props => ({
            company: prop('id', props),
            onlyOwn: true,
        }))
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(POST_COMPANY_TEMPLATE), toSuccess(PUT_COMPANY_TEMPLATE), toSuccess(DELETE_COMPANY_TEMPLATE)], function() {
                const { getCompanyTemplates } = getProps();

                getCompanyTemplates.refresh();
            })
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    deleteCompanyTemplate: deleteCompanyTemplate
        .withSuccessHandler(() => message.success('Шаблон успешно удален'))
        .withErrorHandler(() => message.error('Не удалось удалить шаблон'))
        .withOptions({ resetOnUnmount: true })
}, null, { openCompanyTemplateModal })(CompanyTemplates);
