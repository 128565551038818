import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withAsyncActions, toSuccess } from 'react-async-client';
import { takeEvery, call } from 'redux-saga/effects';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { isEmpty } from 'ramda';

import Table from './Table';
import Toolbar from './Toolbar';
import { getFilters, getUrlParams, getUrlPagination, getSorting } from '../../../utils/urlParams';
import { intervalChannel } from '../../../utils/saga';
import { withState } from 'recompose';

class TableListComponent extends Component {
    static propTypes = {
        action: PropTypes.func,
        externalPending: PropTypes.bool,
        tableColumns: PropTypes.array,
        className: PropTypes.string,
        additionalButtons: PropTypes.element,
        location: PropTypes.object,
        filterForm: PropTypes.elementType,
        buttons: PropTypes.element,
        title: PropTypes.element,
        initFilters: PropTypes.object,
        onRow: PropTypes.func,
        param: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        asyncActions: PropTypes.object,
        expandedRowRender: PropTypes.func,
        staticFilter: PropTypes.object,
        urlPrefix: PropTypes.string,
        history: PropTypes.object
    };

    constructor(props) {
        super(props);

        const action = this.props.action
            .withDefaultPayload(({ location, initFilters, staticFilter = {}, urlPrefix, payload, defaultSort = {} }) => ({
                ...(payload || {}),
                ...getUrlPagination(location, urlPrefix),
                ...(isEmpty(getSorting(location)) ? defaultSort : getSorting(location)),
                q: {
                    ...staticFilter,
                    ...(getFilters(location, urlPrefix) || initFilters || {})
                }
            }))
            .withParams(this.props.param)
            .withOptions({
                dispatchOnMount: true,
                dispatchOnUpdate: true,
                resetOnUnmount: true
            })
            .withSuccessHandler(({ intervalRefreshing, setIntervalRefreshing }) => intervalRefreshing && setIntervalRefreshing(false))
            .withSaga(function* (getProps) {
                const { refreshActions = [], refreshInterval } = getProps();
                const successActions = refreshActions.map(toSuccess);

                if (refreshInterval) {
                    const chan = yield call(intervalChannel);

                    yield takeEvery(chan, () => {
                        getProps().setIntervalRefreshing(true);
                        getProps().action.refresh();
                    });
                }

                yield takeEvery(successActions, () => {
                    getProps().action.refresh();
                });
            });
        this.component = withAsyncActions({
            action,
            ...this.props.asyncActions
        })(ListTableStateless);
    }

    componentDidUpdate(props) {
        const prevParams = getUrlParams(props.location);

        if (getUrlParams(this.props.location).filter !== prevParams.filter && Number(prevParams.offset) > 0) {
            this.setOffset(0);
        }
    }

    setOffset = offset => {
        const { history, location, urlPrefix } = this.props;
        const params = getUrlParams(location);
        const search = { ...params, offset };

        history.push({
            pathname: history.location.pathname,
            search: qs.stringify(urlPrefix ? {
                [urlPrefix]: search
            } : search),
        });
    }

    render() {
        const Component = this.component;

        return (
            <Component
                {...this.props}
                setOffset={this.setOffset}
            />
        );
    }
}

const TableList = withState('intervalRefreshing', 'setIntervalRefreshing', false)(TableListComponent);

class ListTableStateless extends Component {
    render() {
        const {
            action,
            externalPending,
            setOffset,
            columns,
            className,
            buttons,
            title,
            filterForm : FilterForm,
            scroll,
            location,
            initFilters,
            onRow,
            rowSelection,
            expandedRowRender,
            staticFilter,
            urlPrefix,
            buttonsTop,
            parseItems,
            rowClassName,
            getExpanded,
            filterProps,
            intervalRefreshing
        } = this.props;

        const filterForm = FilterForm && (
            <FilterForm
                initFilters={{
                    ...initFilters,
                    ...getFilters(location, urlPrefix)
                }}
                staticFilter={staticFilter}
                urlPrefix={urlPrefix}
                filterProps={filterProps}
            />
        );

        const toolbar = (filterForm || buttons) && (
            <Toolbar
                title={title}
                filterForm={filterForm}
                buttons={buttons}
                buttonsTop={buttonsTop}
            />
        );

        return (
            <div className={className}>
                {toolbar}
                <Table
                    action={action}
                    externalPending={externalPending}
                    setOffset={setOffset}
                    columns={columns}
                    scroll={scroll}
                    onRow={onRow}
                    location={location}
                    rowClassName={rowClassName}
                    rowSelection={rowSelection}
                    expandedRowRender={expandedRowRender}
                    urlPrefix={urlPrefix}
                    parseItems={parseItems}
                    getExpanded={getExpanded}
                    intervalRefreshing={intervalRefreshing}
                />
            </div>
        );
    }
}

export default withRouter(TableList);
