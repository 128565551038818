import React, { Component, Fragment } from 'react';
import { prop } from 'ramda';
import { Form, Button } from 'antd';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import withFormWrapper from '../../hocs/withFormWrapper';
import SubmitButton from '../formComponents/SubmitButton';
import Input from '../formComponents/Input';
import { parseToPatch } from '../../../utils/forms';

const FieldItem = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    .ant-form-item {
        margin-bottom: 0;
        width: 100%;
        margin-right: 10px;
    }
`;

class GroupVariablesForm extends Component {
    renderFields = ({ fields }) => {
        return <div>
            { fields.map((name, index) =>
                <FieldItem key={`variable-field-${index}`}>
                    <Field
                        name={name}
                        component={Input}
                        validate={value => !value ? 'required' : undefined}
                        hideErrorMsg />
                    <Button danger icon={<DeleteOutlined />} onClick={() => fields.remove(index)} />
                </FieldItem>
            )}
            <Button icon={<PlusOutlined />} onClick={() => fields.push('')} style={{ marginBottom: 15 }}>
                Добавить значение
            </Button>
        </div>;
    }

    render() {
        return <Fragment>
            <Form.Item label='Переменные' wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
                <FieldArray name='variables'>
                    { this.renderFields }
                </FieldArray>
            </Form.Item>
            <SubmitButton type='primary'>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

export default withFormWrapper(GroupVariablesForm, {
    mapPropsToValues: prop('item'),
    mapBeforeSubmit: (values, props) =>
        parseToPatch(values, props.item, ['variables'])
});
