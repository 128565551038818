import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Steps } from 'antd';
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import SubmitButton from '../formComponents/SubmitButton';

const Wrapper = styled.div`
    padding: 15px;
    border-left: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    background: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    .ant-table-wrapper{
        .ant-table-small{
            border: 0;
            border-top: 1px solid #eaeaea;
        }
    }
`;

const StepWrapper = styled.div`
    padding-top: 20px;
`;

const Header = styled.div`
    background: #FAFAFA;
    border-bottom: 1px solid #D9D9D9;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
        color: #2B3D4F;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 0;
    }
`;

const withStepsPagination = WrappedComponent =>
    class StepsPagination extends Component {
        render() {
            const { current, steps = [] } = this.props;

            return <Fragment>
                <Header>
                    <div>
                        <Button onClick={this.props.goPrev}><ArrowLeftOutlined /> НАЗАД</Button>
                    </div>
                    <h1>Создание теста</h1>
                    <div>
                        { this.props.formAction ?
                            <SubmitButton type='primary'>{current === steps.length - 1 ? 'СОХРАНИТЬ' : 'ДАЛЕЕ'} <ArrowRightOutlined /></SubmitButton> :
                            <Button type='primary' onClick={this.props.goNext}>{current === this.props.steps.length - 1 ? 'СОХРАНИТЬ' : 'ДАЛЕЕ'} <ArrowRightOutlined /></Button>
                        }
                    </div>
                </Header>
                <Wrapper>
                    <Steps
                        size='small'
                        current={this.props.current}>
                        { steps.map(step =>
                            <Steps.Step title={step.title} key={step.key} />
                        )}
                    </Steps>
                    <StepWrapper>
                        <WrappedComponent {...this.props} />
                    </StepWrapper>
                </Wrapper>
            </Fragment>;
        }
    }

export default withStepsPagination;
