import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { Spin, message } from 'antd';
import { assocPath, any, findIndex, filter, path, propEq, values } from 'ramda';
import styled from 'styled-components';

import { DEFAULT_ERROR } from '../../constants/errors';
import { putCompanyTest, getCategories, getCompanyCategoryDistribution } from '../../actions/asyncActions';
import Modal from './Modal';
import CategoryDistributionSettingsForm from '../forms/CategoryDistributionSettingsForm';

const ModalComponent = styled(Modal)`
    .ant-modal-body {
        padding: 0;

        .ant-spin {
            height: 80px;
        }

        .ant-form.ant-form-horizontal > div {
            display: block;
            position: relative;
        }
    }
`;

class CategoryDistributionSettingsModal extends Component {
    getProportions = () => {
        const proportions = {};
        const { distributions = [] } = this.props.getCompanyCategoryDistribution.data;

        distributions.forEach(item => {
            if (item.proportions && item.proportions.length) {
                proportions[item.level] = {};
                item.proportions.forEach(proportion => {
                    proportions[item.level][proportion.level] = proportion;
                });
            }
        });

        return proportions;
    }

    getCategories = () => {
        return  filter(item => any(Boolean, values(item.questionsCount)), path(['data', 'items'], this.props.getCategories) || []);
    }

    render() {
        const { modal, close, getCompanyCategoryDistribution, getCategories, params: { item, test, value, levels, category }, putCompanyTest } = this.props;
        const isSuccess = getCompanyCategoryDistribution.meta.success && getCategories.meta.success;
        const isError = getCompanyCategoryDistribution.meta.error || getCategories.meta.error;

        return <Modal
            {...modal}
            title='Настроить распределение по индикаторам'
            footer={null}
            ModalComponent={ModalComponent}>
            { isSuccess ? (
                <CategoryDistributionSettingsForm
                    id={test.id}
                    item={item}
                    test={test}
                    value={value}
                    levels={levels}
                    category={category}
                    proportions={this.getProportions()}
                    categories={this.getCategories()}
                    formAction={putCompanyTest}
                    disabled={!path(['data', 'items', 'length'], getCategories)}
                    onCancel={() => close()}
                    onClear={path(['categoryDistributionSettings', 'length'], item) ? () => {
                        const categoryIndex = findIndex(propEq('category', category.id), test.categorySelections);

                        if (categoryIndex >= 0) {
                            this.props.putCompanyTest.dispatch(assocPath(['categorySelections', categoryIndex, 'categoryDistributionSettings'], [], test))
                        }
                    } : null}
                />
            ) : isError ? DEFAULT_ERROR : <Spin />}
        </Modal>
    }
}

export default withAsyncActions({
    getCategories: getCategories
        .withParams(() => 'distributions')
        .withPayload(({ params: { category, test } }) => ({
            q: {
                visible: true,
                valid: true,
                byParent: true,
                parent: category.id,
                company: test.company,
            }
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    getCompanyCategoryDistribution: getCompanyCategoryDistribution
        .withParams(({ params: { category } }) => ({ category: category.id }))
        .withPayload(({ params: { category, test } }) => ({
            category: category.id,
            company: test.company,
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    putCompanyTest: putCompanyTest
        .withSuccessHandler(({ close }) => {
            message.success('Настройки были успешно сохранены');
            close();
        })
        .withErrorHandler(() => {
            message.error('Не удалось сохранить настройки распределения - проверьте индикаторы');
        })
        .withOptions({ resetOnUnmount: true })
})(CategoryDistributionSettingsModal);
