import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { List, Input, Badge, Avatar } from 'antd';
import styled from 'styled-components';
import { pathOr } from 'ramda';
import { Link, withRouter } from 'react-router-dom';
import { LoginOutlined, GlobalOutlined } from '@ant-design/icons';

import { getCompanies } from '../../../actions/asyncActions';
import { getUrlParams, extendSearchPath } from '../../../utils/urlParams';
import { getAvatar } from '../../../constants/urls';

const CompanyList = styled(List)`
    width: 30%;
    background: #fff;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    h3{
        background: #fafafa;
        border-bottom: 1px solid #eaeaea;
        padding: 10px 15px;
        margin: 0;
    }
    .ant-list-header{
        padding: 0;
        border-radius: 6px;
    }
    &.ant-list{
        .ant-list-header{
            border: 0;
        }
    }
`;

const CompanyItem = styled(List.Item)`
    cursor: pointer;
    transition: background .3s;
    background: ${({ active }) => active ? 'rgba(243, 78, 45, 0.12)' : 'transparent'};
    &:last-child{
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    &:hover {
        background: ${({ active }) => active ? 'rgba(243, 78, 45, 0.12)' : 'transparent'};
    }
    .ant-avatar{
        margin-right: 5px;
    }
    &.ant-list-item{
        padding-right: 30px;
    }
`;

const CompanyItemLink= styled(Link)`
    position: absolute;
    right: 5px;
    margin-top: -12px;
    padding: 0 5px;
    top: 50%;
    color: #969696;
    font-size: 1.2rem;
    opacity: 0;
    transition: opacity .4s;
`;

const CompanyItemWrap = styled.div`
    position: relative;
    &:hover {
        background: rgba(243, 78, 45, 0.05);
        ${CompanyItemLink}{
            opacity: 1;
        }
    }
`;

export const SearchWrapper = styled.div`
    display: flex;
    background: #fafafa;
    border-bottom: 1px solid #eaeaea;
    padding: 10px 15px;
`;

class CompanySelector extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: getUrlParams(props.location).text || ''
        };
    }

    onSearch = text => {
        const { history, location } = this.props;

        history.replace(extendSearchPath(location, { text }));
    }

    onChangeSearch = e => {
        this.setState({ search: e.target.value });
    }

    getSelectedCompany = () => {
        return getUrlParams(this.props.location).company;
    }

    selectCompany = company => {
        const { location, history } = this.props;

        history.replace(extendSearchPath(location, { company }));
    }

    render() {
        const { getCompanies: { data: { items = [], _meta }, meta }} = this.props;
        const count = pathOr(0, ['count'], _meta);

        return <CompanyList
            loading={meta.pending}
            header={
                <div>
                    <h3>Компании <Badge count={count} style={{ backgroundColor: '#f54d2e' }} /></h3>
                    <SearchWrapper>
                        <Input.Search
                            value={this.state.search}
                            onChange={this.onChangeSearch}
                            onSearch={this.onSearch}
                            placeholder='Поиск' />
                    </SearchWrapper>
                </div>
            }
            dataSource={items}
            renderItem={item =>
                <CompanyItemWrap>
                    <CompanyItem
                        onClick={() => this.selectCompany(item.id)}
                        active={item.id === this.getSelectedCompany()}>
                        <Avatar icon={<GlobalOutlined />} src={getAvatar(item.logo)} /> { item.name }
                    </CompanyItem>
                    <CompanyItemLink to={`/companies/${item.id}`}><LoginOutlined /></CompanyItemLink>
                </CompanyItemWrap>
            } />;
    }
}

export default withRouter(
    withAsyncActions({
        getCompanies: getCompanies
            .withPayload(({ location }) => ({
                limit: 0,
                q: {
                    enabled: true,
                    text: getUrlParams(location).text
                },
            }))
            .withOptions({ resetOnUnmount: true, dispatchOnMount: true, dispatchOnUpdate: true }),
    })(CompanySelector)
);
