import React from 'react';
import { Field } from 'react-final-form';
import { Col } from 'antd';

import BaseFilter from './BaseFilter';
import { getQuestions } from '../../../actions/asyncActions';
import withFilterForm from '../../hocs/withFilterForm';
import Rangepicker from '../formComponents/Rangepicker';

export default withFilterForm(props =>
    <BaseFilter
        action={getQuestions}
        searchFieldName='text'
        searchFieldPlaceholder='Вопрос'
        span={10}>
        <Col span={14}>
            <Field
                name='refreshAfter'
                component={Rangepicker}
                toPath='refreshBefore'
                change={props.form.change}
            />
        </Col>
    </BaseFilter>
);
