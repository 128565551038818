import React, { Component, Fragment } from 'react';
import { Table, Form, Switch } from 'antd';
import styled from 'styled-components';
import { prop } from 'ramda';
import { Field } from 'react-final-form';
import { withState } from 'recompose';

import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import LevelInputNumber from './formComponents/LevelInputNumber';
import LevelTimeMasked from './formComponents/LevelTimeMasked';
import ListenerField from './ListenerField';
import { INITIAL_COMPLEXITIES_SETTINGS } from '../../constants/tests';

const fields = [
    { label: 'Время', field: 'executionTime', component: LevelTimeMasked, min: 11 },
    { label: 'Баллы', field: 'points', component: LevelInputNumber, min: 1 },
    { label: 'Штрафы', field: 'penalization', component: LevelInputNumber, min: 0 }
];

const StyledTable = styled(Table)`
    &.ant-table-wrapper{
        margin-top: 10px;
    }
`;

const Footer = styled.div`
    padding: 15px;
`;

const ToggleWrapper = styled.div`
    margin: 15px 15px 0;
`;

const FieldWrapper = styled.div`
    div.ant-row.ant-form-item {
        margin-bottom: 0;
    }
`;

class QuestionsSettingsForm extends Component {
    getColumns = items => {
        return [
            {
                dataIndex: 'label',
                key: 'label'
            },
            ...items.map((item, index) => ({
                dataIndex: 'field',
                key: `level-${item.level}`,
                width: 100,
                render: (field, { component, min }) =>
                    <FieldWrapper>
                        <Field
                            name={`items.${index}.${field}`}
                            component={component}
                            level={item.level}
                            min={min}
                            validate={value => {
                                return value < min ? `Минимально: ${min}` : undefined;
                            }}
                            disableClear />
                    </FieldWrapper>
            }))
        ];
    }

    onChangeGlobal = global => {
        this.props.form.change('items', global ? null : INITIAL_COMPLEXITIES_SETTINGS);
        this.props.globalEdit(global);
    }

    render() {
        const { ableToggle, global } = this.props;

        return <Fragment>
            { ableToggle &&
                <ToggleWrapper className='ant-form-inline'>
                    <Form.Item wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} label='Использовать глобальные настройки'>
                        <Switch checked={global} onChange={this.onChangeGlobal} />
                    </Form.Item>
                </ToggleWrapper>
            }
            <ListenerField listenFieldName='items'>
                { ({ items }) =>
                    <Fragment>
                        { ableToggle && (!items || global)  ? null :
                            <StyledTable
                                dataSource={fields}
                                columns={this.getColumns(items)}
                                rowKey='field'
                                pagination={false}
                                components={{
                                    header: { wrapper: () => null }
                                }} />
                        }
                        { this.props.buttons ||
                            <Footer>
                                <SubmitButton type='primary'>Сохранить</SubmitButton>
                            </Footer>
                        }
                    </Fragment>
                }
            </ListenerField>
        </Fragment>;
    }
}

export default withState('global', 'globalEdit', ({ data }) => !(data && data.items))(
    withFormWrapper(QuestionsSettingsForm, {
        mapPropsToValues: prop('data'),
        mapBeforeSubmit: (values, { global, mapBeforeSubmit }) => {
            const form = {
                ...values,
                items: global ? null: values.items,
            };

            return mapBeforeSubmit ? mapBeforeSubmit(form) : form
        }
    })
);
