import React from 'react';
import { Switch } from 'react-router-dom';
import { Layout, Dropdown, Menu } from 'antd';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { contains } from 'ramda';
import { Link } from 'react-router-dom';
import { UserOutlined, LogoutOutlined, DownOutlined } from '@ant-design/icons';

import Sidebar from './Sidebar';
import Search from './Search';
import { logout } from '../../../actions/appActions';
import Modals from '../../modals/Modals';
import LogoImg from '../../../assets/img/logo.svg';
import SecureRoute from '../../SecureRoute';

import Questions from '../../user/questions/Questions';
import QuestionAdd from '../../user/questions/QuestionAdd';
import QuestionEdit from '../../user/questions/QuestionEdit';
import Admins from '../../user/Admins';
import Companies from '../../user/companies/Companies';
import Company from '../../user/companies/Company';
import CompanyEmployee from '../../user/companies/CompanyEmployee';
import Projects from '../../user/projects/Projects';
import AddProject from '../../user/projects/AddProject';
import Project from '../../user/projects/Project';
import ProjectRespondent from '../../user/projects/ProjectRespondent';
import Surveys from '../../user/surveys/Surveys';
import Survey from '../../user/surveys/Survey';
import { ADMIN_MODERATOR, SENIOR_MANAGER, ADMIN, MANAGER } from '../../../constants/roles';
import { getUser } from '../../../actions/asyncActions';
import ProjectRespondentsAdd from '../../user/projects/ProjectRespondentsAdd';
import Commands from '../../user/Commands';
import Tests from '../../user/tests/Tests';
import Test from '../../user/tests/Test';
import Respondents from '../../user/Respondents';
import Experts from '../../user/Experts';
import Expert from '../../user/Expert';
import Pdf from './Pdf';
import Payments from '../../user/Payments';
import AddTestAudit from '../../user/tests/AddTestAudit';
import Username from '../../user/table/Username';
import Profile from '../../user/Profile';
import Recommendations from '../../user/Recommendations';
import Settings from '../../user/settings/Settings';
import Acceptors from '../../user/Acceptors';
import Reports from '../../user/Reports';
import Questionnaries from '../../user/Questionnaries';
import Questionnary from '../../user/Questionnary';

const StyledLayout = styled(Layout)`
    height: 100%;
    min-height: 100vh;
    min-width: 767px;
    @media screen and (max-width: 896px) {
        overflow-y: hidden;
        max-height: 100vh;
    }

`;

const Wrapper = styled.div`
    margin: 20px;
    margin-left: 0;
    border-radius: 6px;
    .wrap-bg,.ant-table-wrapper{
        border-left: 1px solid #eaeaea;
        border-right: 1px solid #eaeaea;
        border-bottom: 1px solid #eaeaea;
        background: #fff;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        .ant-row{
            border: 0;
        }
    }
    .ant-tabs-bar{
        background: #fff;
        border-left: 1px solid #eaeaea;
        border-right: 1px solid #eaeaea;
    }
    @media (max-width: 1024px) {
        margin: 10px;
        margin-left: 0;
    }
    @media (max-width: 767px) {
        margin: 5px;
        margin-left: 0;
    }
`;

const Header = styled(Layout.Header)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 767px) {
        padding: 0 15px;
    }
`;

const HeaderButtons = styled.div`
    display: inline-block;
`;

const ProfileBtn = styled.span`
    color: hsla(0,0%,100%,.65);
    cursor: pointer;
    .anticon {
        margin-right: 8px;
        @media (max-width: 767px) {
            margin-right: 0;
        }
    }
    &:hover {
        color: #fff;
    }
    .user-name,.anticon-down {
        @media (max-width: 767px) {
            display: none!important;
        }
    }
`;

const Logo = styled.div`
    display: inline-block;
    background-image: url("${LogoImg}");
    background-repeat: no-repeat;
    width: 160px;
    height: 40px;
    margin-top: 25px;
    background-size: contain;
    @media (max-width: 767px) {
        background-size: auto 26px;
        width: 29px;
        margin-right: -7px;
        background-position-x: 0px;
        margin-top: 32px;
    }
`;

const questionsRouteCond = user => user.role !== ADMIN ? user.ownQuestions : true;

const UserLayout = ({ logout, user }) =>
    <StyledLayout>
        <Header>
            <Link to='/'>
                <Logo />
            </Link>
            { contains(user.role, ADMIN_MODERATOR) && <Search />}
            <HeaderButtons>
                <Pdf />
                <Dropdown trigger={['click']} overlay={
                    <Menu>
                        <Menu.Item>
                            <Link to='/profile'><UserOutlined style={{ minWidth: 12, marginRight: 8 }} /> Профиль</Link>
                        </Menu.Item>
                        <Menu.Item onClick={logout}>
                            <LogoutOutlined /> Выйти
                        </Menu.Item>
                    </Menu>
                }>
                    <ProfileBtn>
                        <UserOutlined /><span className='user-name'><Username user={user} /></span> <DownOutlined />
                    </ProfileBtn>
                </Dropdown>
            </HeaderButtons>
        </Header>
        <Layout>
            <Sidebar role={user.role} />
            <Layout.Content>
                <Wrapper>
                    <Switch>
                        <SecureRoute path='/' exact component={Projects} roles={[ADMIN, MANAGER, SENIOR_MANAGER]} />
                        <SecureRoute path='/company/:company/projects/wizard/:step/:id?' exact component={AddProject} roles={[ADMIN, MANAGER, SENIOR_MANAGER]} />
                        <SecureRoute path='/company/:company/projects/:project/test/:test/respondent/:id' exact component={ProjectRespondent} roles={[ADMIN, MANAGER, SENIOR_MANAGER]} />
                        <SecureRoute path='/company/:company/projects/:id/:type' exact component={Project} roles={[ADMIN, MANAGER, SENIOR_MANAGER]} />
                        <SecureRoute path='/company/:company/projects/:id/test/:test/:type/:itemId' exact component={Project} roles={[ADMIN, MANAGER, SENIOR_MANAGER]} />
                        <SecureRoute path='/company/:company/projects/:id/respondents/add' component={ProjectRespondentsAdd} roles={[ADMIN, MANAGER, SENIOR_MANAGER]} />
                        <SecureRoute path='/surveys' exact component={Surveys} roles={[ADMIN, MANAGER, SENIOR_MANAGER]} />
                        <SecureRoute path='/surveys/:id/:type/:itemId' component={Survey} roles={[ADMIN, MANAGER, SENIOR_MANAGER]} />
                        <SecureRoute path='/surveys/:id/:type?' exact component={Survey} roles={[ADMIN, MANAGER, SENIOR_MANAGER]} />
                        <SecureRoute path='/questions' exact component={Questions} roles={[ADMIN, MANAGER, SENIOR_MANAGER]} cond={questionsRouteCond} />
                        <SecureRoute path='/questions/add' component={QuestionAdd} roles={[ADMIN, MANAGER, SENIOR_MANAGER]} cond={questionsRouteCond} />
                        <SecureRoute path='/questions/:id' component={QuestionEdit} roles={[ADMIN, MANAGER, SENIOR_MANAGER]} cond={questionsRouteCond} />
                        <SecureRoute path='/recommendations' exact component={Recommendations} roles={[ADMIN, MANAGER, SENIOR_MANAGER]} cond={questionsRouteCond} />
                        <SecureRoute path='/admins' component={Admins} roles={[ADMIN]} />
                        <SecureRoute path='/companies' exact component={Companies} roles={[ADMIN]}  />
                        <SecureRoute path='/companies/:company/employees/:id' component={CompanyEmployee} roles={[ADMIN, SENIOR_MANAGER]} />
                        <SecureRoute path='/companies/:id/:type?' component={Company} roles={[ADMIN, SENIOR_MANAGER]} />
                        <SecureRoute path='/companies/:id/billing/:tab' exact component={Company} roles={[ADMIN, SENIOR_MANAGER]} />
                        <SecureRoute path='/commands' component={Commands} roles={[ADMIN, SENIOR_MANAGER]} cond={user => user.role === SENIOR_MANAGER ? user.ownQuestions : true} />
                        <SecureRoute path='/tests' component={Tests} exact roles={ADMIN_MODERATOR} />
                        <SecureRoute path='/tests/:id/audits/add' exact component={AddTestAudit} />
                        <SecureRoute path='/tests/:id/:type/:itemId?' component={Test} roles={ADMIN_MODERATOR} />
                        <SecureRoute path='/respondents' component={Respondents} roles={[ADMIN, MANAGER, SENIOR_MANAGER]} />
                        <SecureRoute path='/experts' component={Experts} exact roles={ADMIN_MODERATOR} />
                        <SecureRoute path='/experts/:id' component={Expert} roles={ADMIN_MODERATOR} />
                        <SecureRoute path='/payments' component={Payments} roles={[ADMIN]} />
                        <SecureRoute path='/profile' component={Profile} />
                        <SecureRoute path='/settings/:type?' component={Settings} roles={[ADMIN]} />
                        <SecureRoute path='/acceptors' component={Acceptors} roles={[ADMIN]} />
                        <SecureRoute path='/reports' component={Reports} roles={[ADMIN]} />
                        <SecureRoute path='/questionnaries/:id/:type' component={Questionnary} roles={ADMIN_MODERATOR} />
                        <SecureRoute path='/questionnaries' component={Questionnaries} roles={ADMIN_MODERATOR} />
                    </Switch>
                </Wrapper>
            </Layout.Content>
        </Layout>
        <Modals />
    </StyledLayout>;

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps, { logout })(UserLayout);
