import React, { Component, Fragment } from 'react';
import { Tag, Avatar, Button, message } from 'antd';
import { find, propEq, equals, has } from 'ramda';
import { asyncConnect } from 'react-async-client';
import styled from 'styled-components';
import { MailOutlined, PhoneOutlined, UserOutlined, CloseCircleOutlined, CheckCircleOutlined, PlusOutlined } from '@ant-design/icons';

import TableList from './table/TableList';
import { getAdmins, patchAdmins } from '../../actions/asyncActions';
import { ADMIN_ROLES } from '../../constants/admins';
import SpanAsLink from './table/SpanAsLink';
import { openAdminModal } from '../../actions/modalActions';
import { POST_ADMIN, PUT_ADMIN, PATCH_ADMINS } from '../../constants/actionTypes';
import AdminsFilter from '../forms/filters/AdminsFilter';
import DateFormat from './table/DateFormat';
import { withState } from 'recompose';
import { getFilters, getUrlParams } from '../../utils/urlParams';
import { getAvatar } from '../../constants/urls';

const WrapTableList = styled(TableList)`
    .ant-row{
        border-bottom: 0;
    }
`;

class Admins extends Component {
    componentDidUpdate(prev) {
        if (!equals(getUrlParams(this.props.location), getUrlParams(prev.location))) {
            this.props.setSelected([]);
        }
    }

    getColumns = () => {
        return [
            {
                dataIndex: 'photo',
                key: 'photo',
                width: 50,
                render: photo => <Avatar src={getAvatar(photo)} icon={<UserOutlined />} />
            },
            {
                title: 'ФИО',
                dataIndex: 'fullName',
                key: 'lastName',
                sorter: true,
                render: (fullName, item) => <SpanAsLink onClick={() => this.props.openAdminModal(item)}>{ fullName }</SpanAsLink>
            },
            {
                title: 'Контакты',
                dataIndex: 'email',
                key: 'email',
                render: (email, { phone }) =>
                    <Fragment>
                        <div><MailOutlined /> { email }</div>
                        { phone && <div><PhoneOutlined /> { phone }</div> }
                    </Fragment>
            },
            {
                title: 'Дата добавления',
                dataIndex: 'createdAt',
                key: 'createdAt',
                sorter: true,
                render: date => <DateFormat date={date} />
            },
            {
                dataIndex: 'role',
                key: 'roles',
                className: 'right-column',
                filters: ADMIN_ROLES.map(i => ({ text: i.value, value: i.id })),
                render: role => <Tag color={ find(propEq('id', role), ADMIN_ROLES).color }>{ find(propEq('id', role), ADMIN_ROLES).value }</Tag>
            }
        ];
    }

    setEnabled = (enabled = true) => {
        this.props.patchAdmins.dispatch({
            items: this.props.selected,
            data: [{ op: 'replace', path: '/enabled', value: enabled }]
        });
    }

    renderButtons = () => {
        const { selected, openAdminModal, location } = this.props;
        const filters = getFilters(location) || {};
        const enabled = has('enabled', filters) ? filters.enabled : true;

        return <Button.Group>
            { enabled ?
                <Button icon={<CloseCircleOutlined />} type='danger' disabled={!selected.length} onClick={() => this.setEnabled(false)}>
                    <span className='hide-mobile'>Заблокировать</span>
                </Button> :
                <Button icon={<CheckCircleOutlined />} disabled={!selected.length} onClick={this.setEnabled}>
                    <span className='hide-mobile'>Разблокировать</span>
                </Button>
            }
            <Button icon={<PlusOutlined />} type='primary' onClick={() => openAdminModal()}>
                <span className='hide-mobile'>Добавить</span>
            </Button>
        </Button.Group>;
    }

    setSelected = selected => this.props.setSelected(selected);

    render() {
        return <WrapTableList
            action={getAdmins}
            columns={this.getColumns()}
            refreshActions={[POST_ADMIN, PUT_ADMIN, PATCH_ADMINS]}
            filterForm={AdminsFilter}
            initFilters={{ enabled: true }}
            rowSelection={{
                selectedRowKeys: this.props.selected,
                onChange: this.setSelected,
                type: 'checkbox'
            }}
            buttons={this.renderButtons()} />;
    }
}

export default withState('selected', 'setSelected', [])(
    asyncConnect({
        patchAdmins: patchAdmins
            .withSuccessHandler(({ setSelected }) => {
                message.success('Состояние успешно изменено');
                setSelected([]);
            })
            .withErrorHandler(() => message.error('Не удалось изменить состояние'))
            .withOptions({ resetOnUnmount: true })
    }, null, { openAdminModal })(Admins)
);
