import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Row, Col } from 'antd';
import { withState } from 'recompose';
import { pathOr, take, reduce } from 'ramda';
import { withAsyncHandlers } from 'react-async-client';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { openSurveyPreviewModal } from '../../../actions/modalActions';
import { DRIVER_OPTIONS } from '../../../constants/questionnary';

const Wrapper = styled.div`
    padding: 15px;
    background: #fff;
`;

const Item = styled.div`
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    background: #fff;
    display: flex;
    ol {
        padding-left: 15px;
        margin-bottom: 0;
    }
`;

const ItemContent = styled.div`
    padding: 10px 15px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .ant-btn-group {
        min-width: 63px;
    }
`;

class SurveyDrivers extends Component {
    getNum = (row, index) => {
        const { items } = this.props;
        const prevNums = reduce((res, cur) => res + ((cur.questions || []).length || 1), 0, take(row, items));

        return prevNums + index + 1;
    }

    getOptions = (type) => {
        const additionalOptions = DRIVER_OPTIONS[type] || [];

        return [
            { type: 'hideQuestions', label: 'Скрывать вопрос' },
            { type: 'hideDriver', label: 'Скрывать драйвер' },
            { type: 'custom', label: 'Кастомное значение' },
            ...additionalOptions,
        ];
    }

    render() {
        return <Wrapper>
            <Row gutter={8} type='flex' align='middle' style={{ width: '100%' }}>
                <Col span={24}><strong>№ Вопрос</strong></Col>
            </Row>
            { this.props.items.length ?
                <DragDropContext>
                    <Droppable droppableId='droppable'>
                        { provided =>
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}>
                                { this.props.items.map((item, index) =>
                                    <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled>
                                        { provided =>
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                style={{ ...provided.draggableProps.style, marginBottom: 15 }}>
                                                <Item>
                                                    <ItemContent>
                                                        <Row gutter={8} type='flex' align='middle' style={{ width: '100%' }}>
                                                            <Col span={24}>
                                                                { item.questions.length ?
                                                                    item.questions.map((question, i) =>
                                                                        <div key={`question-${i}`}>{ this.getNum(index, i) }. { question }</div>
                                                                    ) : <span>{ this.getNum(index, 0) }.</span>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Button
                                                            key='preview'
                                                            onClick={() => this.props.openSurveyPreviewModal({
                                                                title: item.name,
                                                                survey: this.props.id,
                                                                index
                                                            })}>
                                                            Просмотр
                                                        </Button>
                                                    </ItemContent>
                                                </Item>
                                            </div>
                                        }
                                    </Draggable>
                                )}
                                { provided.placeholder }
                            </div>
                        }
                    </Droppable>
                </DragDropContext> :
                <div style={{ textAlign: 'center' }}>Нет добавленных вопросов</div>
            }
        </Wrapper>;
    }
}

export default withState('items', 'setItems', pathOr([], ['survey', 'drivers']))(
    withAsyncHandlers(props => ({
        [props.getAction || 'getSurvey']: {
            successHandler: ({ project, setItems }) => setItems(pathOr([], ['drivers'], project)),
            errorHandler: ({ project, setItems }) => setItems(pathOr([], ['drivers'], project))
        }
    }))(
        connect(null, { openSurveyPreviewModal })(SurveyDrivers)
    )
);
