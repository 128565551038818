import React from 'react';
import { Col } from 'antd';
import { Field } from 'react-final-form';

import BaseFilter from './BaseFilter';
import { getTestTemplates, getTestGroups } from '../../../actions/asyncActions';
import withFilterForm from '../../hocs/withFilterForm';
import Select from '../formComponents/Select';

export default withFilterForm(() =>
    <BaseFilter
        action={getTestTemplates}
        searchFieldName='text'
        searchFieldPlaceholder='Поиск'
        span={12}>
        <Col span={12}>
            <Field
                name='testGroup'
                component={Select}
                placeholder='Группа'
                action={getTestGroups}
                namePath='name'
                allowClear />
        </Col>
    </BaseFilter>
);
