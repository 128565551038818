import React, { Component, Fragment } from 'react';
import { Table, Button, message, Tooltip, Alert, Select, Popconfirm } from 'antd';
import { asyncConnect, withAsyncActions } from 'react-async-client';
import styled from 'styled-components';
import { DeleteOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { append, contains, filter, find, findIndex, flatten, forEach, fromPairs, path, pathOr, propEq, remove, toUpper, update } from 'ramda';
import * as yup from 'yup';

import { deleteCompanyTest, getCategoryDistribution, getComplexity, getUser, putCompanyTest, putCompanyTestStatus } from '../../../actions/asyncActions';
import { openTestModal, openTestSettingsModal, openCategoriesSettingsInfoModal, openProjectCategorySelectModal, openComplexityDistributionSettingsModal } from '../../../actions/modalActions';
import { ADMIN } from '../../../constants/roles';
import SpanAsLink from '../../user/table/SpanAsLink';
import { getCategoriesTree } from '../../../utils/categories';
import { getExecutionTime } from '../../../utils/time';
import Complexity from '../../user/table/Complexity';
import ListenerField from '../ListenerField';
import withFormWrapper from '../../hocs/withFormWrapper';
import { FormSpy } from 'react-final-form';
import { TEST_QUESTIONS_TIME } from '../../../constants/tests';
import withStepsPagination from './withStepsPagination';

const TableStyled = styled(Table)`
    .ant-table-thead > tr > th{
        padding: 10px;
        background: #fafafa;
        font-size: 1.17em;
    }
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -15px;
    .ant-alert-info {
        margin: 40px 10px;
    }
`;

const HeaderTitle = styled.div`
    color: #2B3D4F;
    font-size: 13px;
    font-weight: 600;
`;

const HeaderButton = styled.div`
    color: #F54D2E;
    font-size: 13px;
    cursor: pointer;
    display: inline-block;
`;

const SettingsButton = styled(Button)`
    color: ${({ selected }) => selected ? '#f54d2e' : '#dcdcdc'};

    &:hover, &:active, &:focus {
        color: #000;
    }
`;

class TimeComponent extends Component {
    getDistribution = distributions => {
        const data = {};

        forEach(item => {
            data[item.level] = fromPairs(item.proportions.map(({ level, count }) => ([ level, count ])));
        }, distributions);

        return data;
    }

    getTime = () => {
        const { test, getComplexity, item } = this.props;

        const time = (test.categorySelections || []).reduce((res, cur, index) => {
            const distributions = pathOr([], [`getCategoryDistribution${index}`, 'data', 'distributions'], this.props);
            const projectComplexitySettings = item.testsTimeSettings.complexitiesSettings || [];
            const settings = (projectComplexitySettings.length ? projectComplexitySettings : getComplexity.data.items) || [];
            const time = settings.reduce((res, set) => pathOr(0, [cur.level, set.level], this.getDistribution(distributions)) * set.executionTime + res, 0)

            return res + time;
        }, 0);

        return time;
    }

    render() {
        const { pristine, test } = this.props;
        const time = this.getTime();

        return getExecutionTime(pristine ? test.executionTime : time);
    }
}

const Time = withAsyncActions(props =>
    fromPairs(pathOr([], ['_embedded', 'categories'], props.item).map((category, index) => ([
        `getCategoryDistribution${index}`,
        getCategoryDistribution
            .withParams(() => ({ category: category.id }))
            .withPayload(() => category.id)
            .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
    ])))
)(TimeComponent);

class AddProjectTestsStep extends Component {
    changeStatus = test => {
        this.props.putCompanyTestStatus.dispatch({
            company: test.company,
            project: test.project,
            id: test.id,
            data: {
                status: test.status === 'sent' ? 'formed' : 'sent'
            }
        });
    }

    openTestModal = () => {
        const { item, openTestModal } = this.props;

        openTestModal({
            project: item.id,
            company: item.company,
            type: item.type
        });
    }

    openCategoriesModal = () => {
        const { openProjectCategorySelectModal, item } = this.props;
        const isCustom = item.type === 'custom';

        openProjectCategorySelectModal({
            item,
            company: item.company,
            onlyOwn: false,
            complete: !isCustom,
            lastLevel: isCustom,
            allowSelectSecondLevel: isCustom,
        });
    }

    getCategory = (categorySelections, category) => {
        return find(propEq('category', category), categorySelections);
    }

    getLevel = (category) => {
        const level = path(['level'], category);
        return level ? String(level) : undefined;
    }

    onChange = (level, category, index, test) => {
        const { form } = this.props;
        const { items } = form.getState().values;
        const { categorySelections } = items[index];
        const categoryIndex = findIndex(propEq('category', category), categorySelections);

        const categories = categoryIndex > -1 ? (
            level ? categorySelections.map((item, i) => i === categoryIndex ? ({ ...item, level }) : item) : remove(categoryIndex, 1, categorySelections)
        ) : (
            level ? append({
                category,
                level,
                id: test.id
            }, categorySelections) : categorySelections
        );
        const newTest = {...items[index], categorySelections: categories};
        const newItems = update(index, newTest, items);

        form.change('items', newItems);
        this.props.putCompanyTest.dispatch(newTest);
    }

    getColumns = (items) => {
        const { openTestModal, openComplexityDistributionSettingsModal, deleteCompanyTest, testTemplate, getComplexity } = this.props;
        const hasCompetence = !!items.length;

        return [
            {
                title: <div>
                    <HeaderTitle>КОМПЕТЕНЦИИ</HeaderTitle>
                    { hasCompetence ? <HeaderButton onClick={this.openCategoriesModal}>изменить</HeaderButton> : <HeaderButton style={{ height: 20 }} />}
                </div>,
                key: 'name',
                dataIndex: 'name',
                render: (name, { parent, result, childrenCount }) => result ? <strong>Итоговое время</strong> : parent ? name : <strong>{ name } ({childrenCount})</strong>
            },
            ...items.map((test, index) => ({
                title: <div>
                    <HeaderTitle>
                        { toUpper(test.name) } { items.length > 1 && (
                            <Popconfirm title='Вы уверены, что хотите удалить тест?' onConfirm={() => deleteCompanyTest.dispatch(test)}>
                                <HeaderButton><DeleteOutlined /></HeaderButton>
                            </Popconfirm>
                        )}
                    </HeaderTitle>
                    <HeaderButton onClick={() => openTestModal(test)}>изменить</HeaderButton>
                </div>,
                key: test.id,
                render: (item) => {
                    if (item.result) {
                        const time = (test.categorySelections || []).reduce((res, cur) => {
                            const projectComplexitySettings = this.props.item.testsTimeSettings.complexitiesSettings || [];
                            const settings = (projectComplexitySettings.length ? projectComplexitySettings : getComplexity.data.items) || [];
                            const time = settings.reduce((res, set) => pathOr(0, [cur.level, set.level], TEST_QUESTIONS_TIME) * set.executionTime + res, 0)

                            return res + time;
                        }, 0);

                        return <FormSpy subscription={{ pristine: true }}>
                            { ({ pristine }) => this.props.item.type === 'competence' ?
                                <Time item={this.props.item} test={test} pristine={pristine} getComplexity={getComplexity} /> :
                                getExecutionTime(pristine ? test.executionTime : time)
                            }
                        </FormSpy>;
                    }

                    const levels = filter(l => contains(l, item.availableCompetenceLevels), [1, 2, 3]);
                    const category = this.getCategory(test.categorySelections, item.id);
                    const value = this.getLevel(category);
                    const isSent = test.status === 'sent';
                    const notExistedValue = !contains(Number(value), levels) && value;

                    return item.parent &&
                        <Tooltip title={isSent ?
                                'Тест уже выслан респондентам' : testTemplate ?
                                'Готовый тест нельзя редактировать' : null}>
                            <Select
                                style={{ width: 60 }}
                                value={notExistedValue ? <Complexity level={Number(value)} /> : value}
                                dropdownMatchSelectWidth={!!levels.length}
                                onChange={value => this.onChange(value, item.id, index, test)}
                                disabled={isSent || testTemplate}
                                allowClear>
                                { levels.map(level =>
                                    <Select.Option value={level.toString()} key={`${item.id}-level-${level}`}>
                                        <Complexity level={level} />
                                    </Select.Option>
                                )}
                            </Select>
                            <SettingsButton
                                type='link'
                                selected={!!path(['categoryDistributionSettings', 'length'], category)}
                                icon={<SettingOutlined />}
                                onClick={() => openComplexityDistributionSettingsModal({
                                    item: category,
                                    category: item,
                                    project: this.props.item,
                                    levels,
                                    test,
                                    value,
                                })}
                            />
                        </Tooltip>;
                }
            })),
            {
                title: <Button
                    icon={<PlusOutlined />}
                    onClick={this.openTestModal}>
                    ДОБАВИТЬ ТЕСТ
                </Button>,
                align: 'right',
                width: 170
            }
        ]
    }

    render() {
        const { submitFailed, item, itemMeta, testSuitesMeta, openCategoriesSettingsInfoModal } = this.props;
        const categories = pathOr([], ['_embedded', 'categories'], item);
        const grouped = flatten(getCategoriesTree(categories, true).map(item => ([item, ...item.items])));

        return <Fragment>
            <FormSpy subscription={{ errors: true }}>
                { ({ errors }) =>
                    !!pathOr([], ['_arrays', 'items'], errors).length && submitFailed &&
                        <Alert
                            type='error'
                            message={
                                !path(['categories', 'length'], item) ? 'Необходимо добавить как минимум одну компетенцию'
                                : 'Необходимо выбрать как минимум одну сложность для компетенции'
                            }
                            style={{ marginBottom: 15 }}
                        />
                }
            </FormSpy>
            <ListenerField listenFieldName='items'>
                {({ items }) => <TableStyled
                    dataSource={grouped.length ? [...grouped, { result: true, id: 'result' }] : []}
                    columns={this.getColumns(items)}
                    pagination={false}
                    loading={testSuitesMeta.pending || itemMeta.pending}
                    rowKey='id'
                    size='small'
                    locale={{
                        emptyText: (testSuitesMeta.success || testSuitesMeta.lastSucceedAt) && (itemMeta.success || itemMeta.lastSucceedAt) ? <Alert
                            type='info'
                            message={
                                <div style={{ textAlign: 'center' }}>
                                    <div>Выберите компетенции из межотраслевой модели профессиональных компетенций, необходимые для оценки респондентов. В момент выбора помните о временном критерии: чем больше компетенций, тем больше времени требуется на их проверку. Рекомендуемая длительность процедуры одного сеанса тестирования составляет не более 1,5 часов. <SpanAsLink onClick={() => openCategoriesSettingsInfoModal()}>Подробнее</SpanAsLink></div>
                                    <Button onClick={this.openCategoriesModal} style={{ background: '#2B3D4F', borderColor: '#2B3D4F', marginTop: 16, marginBottom: 16 }} type='primary' icon={<PlusOutlined />}>ДОБАВИТЬ КОМПЕТЕНЦИИ</Button>
                                </div>
                            } /> : null
                }} />}
            </ListenerField>
        </Fragment>;
    }
}

const stateToProps = state => ({
    isAdmin: getUser.selectData(state).role === ADMIN
});

const validationSchema = yup.object().shape({
    items: yup.array().of(yup.object().shape({
        categories: yup.array().min(1, 'Необходимо добавить как минимум одну компетенцию'),
        categorySelections: yup.array().required().min(1)
    }))
});

export default asyncConnect({
    putCompanyTest: putCompanyTest
        .withParams(() => 'project')
        .withErrorHandler(() => {
            message.error('Не удалось сохранить настройки теста');
        })
        .withOptions({ resetOnUnmount: true }),
    deleteCompanyTest: deleteCompanyTest
        .withSuccessHandler(() => message.success('Тест был успешно удален'))
        .withErrorHandler(() => message.error('Не удалось удалить тест'))
        .withOptions({ resetOnUnmount: true }),
    putCompanyTestStatus: putCompanyTestStatus
        .withSuccessHandler(({ putCompanyTestStatus }) => message.success(`Тест был успешно ${putCompanyTestStatus.data.status === 'formed' ? 'разблокирован' : 'заблокирован'}`))
        .withErrorHandler(() => message.error('Не удалось изменить статус теста'))
        .withOptions({ resetOnUnmount: true }),
    getComplexity: getComplexity
        .withOptions({ dispatchOnMount: true }),
}, stateToProps, { openTestModal, openTestSettingsModal, openCategoriesSettingsInfoModal, openProjectCategorySelectModal, openComplexityDistributionSettingsModal })(
    withFormWrapper(
        withStepsPagination(AddProjectTestsStep),
        {
            mapPropsToValues: ({ item, testSuites }) => ({
                ...item,
                items: testSuites
            }),
            validationSchema,
            mapBeforeSubmit: values => ({
                ...values,
                items: filter(({ status }) => status !== 'sent', values.items)
            })
        }
    )
);
