import { FontSizeOutlined, CalculatorOutlined, CheckSquareOutlined } from "@ant-design/icons";

export const TEST_STATUSES = [
    { id: 'unformed', value: 'Черновик', color: 'geekblue' },
    { id: 'checking', value: 'Экспертиза', color: 'orange' },
    { id: 'approbation', value: 'Апробация', color: 'blue' },
    { id: 'published', value: 'Публикация', color: 'green' }
];

export const TEST_QUESTIONS_TIME = {
    1: {
        1: 8,
        2: 4,
        3: 0
    },
    2: {
        1: 3,
        2: 6,
        3: 3
    },
    3: {
        1: 3,
        2: 3,
        3: 6
    }
};

export const INITIAL_COMPLEXITIES_SETTINGS = [
    { level: 1, executionTime: 11, penalization: 0, points: 1 },
    { level: 2, executionTime: 11, penalization: 0, points: 1 },
    { level: 3, executionTime: 11, penalization: 0, points: 1 }
];

export const DEFAULT_TEST_TEMPLATE_MESSAGE_CONTENT = `
    <p>Уважаемый (ая) <span data-var="FullName" data-title="Полное имя респондента" class="variable-text"><span contenteditable="false">Полное имя респондента</span></span></p>
    <p><br>Приглашаем вас пройти наш новый тест.</p>
    <p>Для этого необходимо перейти по ссылке: <span data-var="TestLink" data-title="Ссылка на прохождение теста" class="variable-text"><span contenteditable="false">Ссылка на прохождение теста</span></span></p>
    <p><br>Успешного тестирования!</p>
`;

export const DEFAULT_TEST_TEMPLATE_AUDIT_MESSAGE_CONTENT = '';

export const DEFAULT_RESPONDENT_FORM_FIELDS = [
    {
        type: 'string',
        name: 'Город',
        code: 'city',
        settings: {
            show: true,
            static: true
        }
    },
    {
        type: 'number',
        name: 'Возраст',
        code: 'age',
        settings: {
            show: true,
            static: true
        }
    },
    {
        type: 'choice',
        name: 'Пол',
        code: 'gender',
        choices: ['мужской', 'женский'],
        settings: {
            show: true,
            static: true,
            choiceType: 'radio'
        }
    },
    {
        code: 'other',
        name: 'Другое'
    }
];

export const RESPONDENT_FORM_FIELD_TYPES = [
    { id: 'string', value: 'Строка', icon: FontSizeOutlined },
    { id: 'number', value: 'Число', icon: CalculatorOutlined },
    { id: 'choice', value: 'Выбор', icon: CheckSquareOutlined }
];

export const RESPONDENT_FORM_FIELD_CHOICE_TYPES = [
    { id: 'radio', value: 'Радио кнопки' },
    { id: 'select', value: 'Выпадающий список' }
];

export const INCORRECT_ANSWER_OPTIONS = [
    { id: 'fewRightAnswer', name: 'задание содержит несколько ответов, которые можно отнести к верным' },
    { id: 'noDistractor', name: 'набор ответов не содержит дисракторов' },
    { id: 'noRightAnswer', name: 'у задания нет верных ответов' },
    { id: 'incorrectRightAnswer', name: 'неверно указан ключ(и) задания' },
    { id: 'obviousAnswers', name: 'eсть очевидные правильные/неправильные варианты ответа' },
    { id: 'sameQuestion', name: 'задание дублирует уже имеющееся задание' },
    { id: 'wrongCategory', name: 'задание НЕ относится к указанной компетенции/индикатору' },
    { id: 'incorrectQuestionText', name: 'некорректная формулировка задания/вариантов ответ' },
];
