import { append, remove, findIndex, propEq } from 'ramda';
import { toReset } from 'react-async-client';

import { GET_COMPANY_RESPONDENT_PDF, LOGOUT } from '../constants/actionTypes';

export default (state = [], action) => {
    switch (action.type) {
        case GET_COMPANY_RESPONDENT_PDF:
            return append(action.params, state);
        case toReset(GET_COMPANY_RESPONDENT_PDF):
            return remove(findIndex(propEq('id', action.params.id), state), 1, state);
        case LOGOUT:
            return [];
        default:
            return state;
    }
}
