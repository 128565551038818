import React, { Component, Fragment } from 'react';
import { Popconfirm, Input, Button, Badge } from 'antd';
import { asyncConnect } from 'react-async-client';
import { FormSpy } from 'react-final-form';
import styled from 'styled-components';
import { isEmpty, toPairs, pathOr, filter, update, forEach, addIndex } from 'ramda';
import { Field } from 'react-final-form';
import { GlobalOutlined } from '@ant-design/icons';

import { getSurvey } from '../../../actions/asyncActions';
import { EditorComponent } from './Editor';
import { openLanguagesSelectorModal } from '../../../actions/modalActions';

const Bg = styled.div`
    position: fixed;
    background: #000;
    opacity: .5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
`;

const LanguageButton = styled(Button)`
    padding: 0 8px;
    .ant-badge-count {
        height: 14px;
        min-width: 14px;
        line-height: 14px;
        font-size: 9px;
        padding: 0 3px;
    }
`;

class LanguagesSelectorComponent extends Component {
    state = {
        visible: false,
        values: []
    };

    onChange = (value, index) => {
        this.setState(prev => ({
            values: update(index, value, prev.values)
        }));
    }

    renderLanguages = () => {
        const { getSurvey: { data }, editable, defaultValue, translationsWithoutRewrite } = this.props;
        const InputComponent = editable ? EditorComponent : Input;

        return toPairs(data.languages).map(([ lang, langString ], index) =>
            <div key={`translation-${index}`} style={{ marginLeft: -22, marginBottom: 5 }}>
                { langString }
                <InputComponent
                    disabled={data.defaultLanguage === lang && !translationsWithoutRewrite}
                    value={defaultValue && data.defaultLanguage === lang && !translationsWithoutRewrite ? defaultValue : this.state.values[index]}
                    onChange={e => this.onChange(editable ? e : e.target.value, index)}
                    width={280}
                    inline />
            </div>
        );
    }

    getTranslationCount = () => {
        const { getSurvey: { data }, formProps, name, translationsWithoutRewrite, getTranslationPath } = this.props;
        const valuePath = filter(i => i !== '', name.split(/\.|\].|\]|\[/)).map(p => isNaN(Number(p)) ? p : Number(p));

        return filter(v => !!v, filter(([ id ]) => translationsWithoutRewrite || data.defaultLanguage !== id, toPairs(data.languages)).map(([ id ]) =>
            pathOr('', (getTranslationPath ? getTranslationPath(id).split('.') : ['translations', id, ...valuePath]), formProps.values)
        )).length;
    }

    getInitialValues = () => {
        const { getSurvey: { data }, formProps, name, translationsWithoutRewrite, getTranslationPath } = this.props;
        const valuePath = filter(i => i !== '', name.split(/\.|\].|\]|\[/)).map(p => isNaN(Number(p)) ? p : Number(p));

        return toPairs(data.languages).map(([ id ]) =>
            pathOr('', data.defaultLanguage === id && !translationsWithoutRewrite ? valuePath : (getTranslationPath ? getTranslationPath(id).split('.') : ['translations', id, ...valuePath]), formProps.values)
        );
    }

    open = () => {
        this.setState({
            visible: true,
            values: this.getInitialValues()
        });
    }

    close = () => {
        this.setState({ visible: false });
    }

    save = values => {
        const { formProps: { form }, getSurvey: { data }, name, getTranslationPath, modal } = this.props;

        form.batch(() => {
            addIndex(forEach)(([ id ], index) => {
                form.change(getTranslationPath ? getTranslationPath(id) : `translations.${id}.${name}`, (modal ? values : this.state.values)[index]);
            }, toPairs(data.languages));
        });

        this.close();
    }

    renderFields = () => {
        const { getSurvey, getTranslationPath, name } = this.props;

        return toPairs(getSurvey.data.languages).map(([ id ]) =>
            <Field
                key={`translation-field-${id}`}
                name={getTranslationPath ? getTranslationPath(id) : `translations.${id}.${name}`}
                component={() => null} />
        );
    }

    render() {
        const { getSurvey: { data }, modal, openLanguagesSelectorModal } = this.props;

        return isEmpty(data.languages) ? null :
            <Fragment>
                { this.state.visible && <Bg /> }
                { modal ?
                    <LanguageButton onClick={() => openLanguagesSelectorModal({
                        languages: data.languages,
                        save: this.save,
                        values: this.getInitialValues()
                    })}>
                        <Badge count={this.getTranslationCount()} offset={[0, 2]}>
                            <GlobalOutlined />
                        </Badge>
                    </LanguageButton>:
                    <Popconfirm
                        title={this.renderLanguages()}
                        okText='Сохранить'
                        visible={this.state.visible}
                        onCancel={this.close}
                        onConfirm={this.save}
                        placement='left'
                        icon={null}>
                        <LanguageButton onClick={this.open}>
                            <Badge count={this.getTranslationCount()} offset={[0, 2]}>
                               <GlobalOutlined />
                            </Badge>
                        </LanguageButton>
                    </Popconfirm>
                }
                { this.renderFields() }
            </Fragment>;
    }
}

const LanguagesSelector = props =>
    <FormSpy>
        { formProps => <LanguagesSelectorComponent {...props} formProps={formProps} /> }
    </FormSpy>;

export default asyncConnect({
    getSurvey
}, null, { openLanguagesSelectorModal })(LanguagesSelector);
