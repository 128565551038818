import { equals, filter, path } from 'ramda';

export const parseToPatch = (values, initial, fields) => {
    return {
        id: values.id,
        data: filter(field => {
            const val = path(field.split('.'), values);
            const prevVal = path(field.split('.'), initial);

            return (val || prevVal) && !equals(val, prevVal);
        }, fields).map(field => {
            const value = path(field.split('.'), values);

            return {
                op: value ? (path(field.split('.'), initial) === undefined ? 'add' : 'replace') : 'remove',
                path: field.split('.').reduce((res, cur) => `${res}/${cur}`, ''),
                value
            };
        })
    };
}
