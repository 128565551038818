import { CalendarOutlined, LoginOutlined, LogoutOutlined } from '@ant-design/icons';
import React, { Component } from 'react';

import { messageHistoryContractor } from '../../../actions/asyncActions';
import MessageHistoryFilter from '../../forms/filters/MessageHistoryFilter';
import DateFormat from '../table/DateFormat';
import TableList from '../table/TableList';

class CompanyMessageHistoryContractor extends Component {
    getColumns = () => {
        return [
            {
                dataIndex: 'type',
                key: 'type',
                render: type => type === 'consumer' ? <LoginOutlined /> : <LogoutOutlined />
            },
            {
                title: 'Сообщение',
                key: 'data',
                dataIndex: 'data'
            },
            {
                title: 'Дата создания',
                key: 'createdAt',
                dataIndex: 'createdAt',
                render: date => <DateFormat date={date} icon={<CalendarOutlined />} withTime />
            },
            {
                title: 'Дата обновления',
                key: 'updatedAt',
                dataIndex: 'updatedAt',
                render: date => <DateFormat date={date} icon={<CalendarOutlined />} withTime />
            }
        ]
    }

    render() {
        const { id } = this.props;

        return <TableList
            action={messageHistoryContractor}
            payload={{ company: id }}
            filterForm={MessageHistoryFilter}
            columns={this.getColumns()}
            defaultSort={{
                sort_by: 'createdAt',
                sort_order: 'desc'
            }} />;
    }
}

export default CompanyMessageHistoryContractor;
