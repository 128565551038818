import React, { Component, Fragment } from 'react';
import { asyncConnect, toSuccess } from 'react-async-client';
import { Button, Popconfirm, Spin, Tag, message, Col, Row, Tooltip, Menu, Dropdown } from 'antd';
import styled from 'styled-components';
import { find, propEq, path, pathOr, contains, filter, pathEq, all, isNil } from 'ramda';
import { takeEvery } from 'redux-saga/effects';
import { connect } from 'react-redux';
import { SolutionOutlined, MailOutlined, AppstoreOutlined, PhoneOutlined, EditOutlined, FilePdfOutlined, DownOutlined } from '@ant-design/icons';

import { downloadRespondentsPilotPdf } from '@planka/common/lib/utils/respondentsPilotPdf';

import { getCompanyRespondent, deleteCompanyRespondent, getCompanyRespondentPdf, postCompanyTestRestart, getUser, postCompanyTestFinish, patchCompanyRespondent } from '../../../actions/asyncActions';
import { openCompanyRespondentModal, openRespondentAnalyticsModal } from '../../../actions/modalActions';
import { PROJECT_RESPONDENT_STATUSES } from '../../../constants/companies';
import { PUT_COMPANY_RESPONDENT, POST_COMPANY_TEST_RESTART, POST_COMPANY_TEST_FINISH, PATCH_COMPANY_RESPONDENT } from '../../../constants/actionTypes';
import { ADMIN } from '../../../constants/roles';
import TestSolutionInfo from '../tests/TestSolutionInfo';
import AnswersResult from '../tests/AnswersResult';
import { downloadCustomRespondentPdf } from '../../../utils/respondent';
import { MenuIcon } from '../surveys/SurveyRespondent';

const Wrapper = styled.div`
    padding: 25px 15px 15px;
    background: #fff;
    h1{
        color: #2b3d4f;
    }
`;

const Field = styled.div`
    &:last-child{
        border: 0;
    }
    strong{
        min-width: 100px;
        display: inline-block;
    }
`;

const OtherInfo = styled.div`
    font-size: 1rem;
    color: #2b3d4f;
    font-weight: normal;
    .anticon{
        margin-right: 2px;
    }
    ${Field}{
        margin-bottom: 5px;
    }
`;

const Name = styled.h1`
    font-size: 1.6rem;
    margin: -10px 0 0;
    .ant-tag{
        vertical-align: middle;
        margin-left: 10px;
    }
    ${Field}{
        margin: 0;
        display: inline-block;
    }
`;

const ColStatus = styled(Col)`
    text-align: right;
`;

const AnswersResultWrapper = styled.div`
    margin-top: 15px;
`;

const RespondentFormFields = styled.div`
    margin-top: 16px;
    padding: 15px;
    background: #fafafa;
`;

const MenuItem = styled(Menu.Item)`
    padding: 0;
    & > span {
        padding: 5px 12px;
        display: inline-block;
    }
`;

export const styledComponents = {
    Wrapper,
    Field,
    OtherInfo,
    Name,
    ColStatus,
};

export const RespondentHeader = ({ data }) => {
    const businessUnit = path(['_embedded', 'employee', '_embedded', 'businessUnit', 'name'], data);
    const statusStr = path(['value'], find(propEq('id', data.status), PROJECT_RESPONDENT_STATUSES));

    return (
        <Wrapper>
            <Row>
                <Col span={10}>
                    <Name>
                        <Field>{data.lastName} {data.firstName} {data.middleName}</Field>
                    </Name>
                    <OtherInfo>
                        {businessUnit && <Field><SolutionOutlined />{businessUnit}</Field>}
                        {data.rating && <small>Внешняя оценка: {data.rating}</small>}
                    </OtherInfo>
                </Col>
                <Col span={8}>
                    <OtherInfo>
                        <Field>
                            { data.email ?
                                <span><MailOutlined /> {data.email}</span> :
                                !!data.code && <Tooltip title='Код'>
                                    <span><AppstoreOutlined /> {data.code}</span>
                                </Tooltip>
                            }
                        </Field>
                        {data.phone && <Field><PhoneOutlined /> {data.phone}</Field>}
                    </OtherInfo>
                </Col>
                <ColStatus span={6}>
                    { data.canceled ?
                        <Tooltip title={statusStr}>
                            <Tag color='red'><Field>Отменен</Field></Tag>
                        </Tooltip> :
                        <Tag
                        color={path(['color'], find(propEq('id', data.status), PROJECT_RESPONDENT_STATUSES))}><Field>{statusStr}</Field></Tag>
                    }
                </ColStatus>
            </Row>
        </Wrapper>
    );
};

class ProjectRespondentButtonsComponent extends Component {
    state = {
        visibleMenu: false,
        visibleReports: false
    };

    handleMenuVisibleReports = visibleReports => this.setState({ visibleReports });

    delete = () => {
        const { deleteCompanyRespondent, match: { params: { id, itemId, company, test } } } = this.props;

        this.setState({ visibleMenu: false });
        deleteCompanyRespondent.dispatch({
            id: itemId,
            project: id,
            company,
            test
        });
    }

    edit = () => {
        const { data, openCompanyRespondentModal, testSuites } = this.props;

        openCompanyRespondentModal({
            ...data,
            tests: testSuites || [],
            businessUnit: path(['_embedded', 'employee', 'businessUnit'], data)
        });
    }

    cancelTest = () => {
        const { patchCompanyRespondent, match: { params: { id, itemId, company, test } } } = this.props;

        this.setState({ visibleMenu: false });
        patchCompanyRespondent.dispatch({
            company,
            project: id,
            test,
            id: itemId,
            data: [{ op: 'replace', path: '/canceled', value: true }]
        });
    }

    downloadPdf = () => {
        const { getCompanyRespondentPdf, match: { params: { id, itemId, company, test } } } = this.props;

        getCompanyRespondentPdf.dispatch({
            id: itemId,
            project: id,
            company,
            test
        });
    }

    restartTest = () => {
        const { postCompanyTestRestart, data: { company, project, id, testSuite }} = this.props;

        this.setState({ visibleMenu: false });
        postCompanyTestRestart.dispatch({ company, project, respondent: id, id: testSuite });
    }

    finishTest = () => {
        const { postCompanyTestFinish, data: { company, project, id, testSuite }} = this.props;

        postCompanyTestFinish.dispatch({ company, project, respondent: id, id: testSuite });
    }

    handleVisibleMenuChange = visibleMenu => this.setState({ visibleMenu });

    getButtonsMenu = () => {
        const { data: { status, canceled }, isAdmin } = this.props;

        return <Menu>
            <MenuItem key='delete'>
                <Popconfirm
                    title='Вы уверены, что хотите удалить?'
                    okText='Да'
                    cancelText='Нет'
                    onConfirm={this.delete}
                    placement='left'>
                    Удалить
                </Popconfirm>
            </MenuItem>
            { contains(status, ['sent', 'started']) && !canceled &&
                <MenuItem key='cancel'>
                    <Popconfirm
                        title='Вы уверены, что хотите отменить?'
                        okText='Да'
                        cancelText='Нет'
                        onConfirm={this.cancelTest}
                        placement='left'>
                        Отменить
                    </Popconfirm>
                </MenuItem>
            }
            { isAdmin && contains(status, ['finished', 'started']) &&
                <MenuItem key='reset'>
                    <Popconfirm
                        title='Вы уверены, что хотите сбросить?'
                        okText='Да'
                        cancelText='Нет'
                        onConfirm={this.restartTest}
                        placement='left'>
                        Сбросить
                    </Popconfirm>
                </MenuItem>
            }
        </Menu>;
    }

    getCustomPdf = () => {
        downloadCustomRespondentPdf(this.props.data);
    }

    getRespondentsPilotPdf = () => {
        downloadRespondentsPilotPdf(this.props.data);
    }

    getReportActionsMenu = () => {
        const { getCompanyRespondentPdf: { meta }, getCompanyProject, data } = this.props;
        const isCompetence = pathEq(['data', 'type'], 'competence', getCompanyProject);
        const answers = pathOr([], ['_embedded', 'solution', '_embedded', 'answers'], data);
        const fullAnswers = all(i => !isNil(i.correct), answers);

        return <Menu>
                { meta.lastSucceedAt && !meta.pending ?
                    <Menu.Item style={{ padding: 0 }} key='respondent' onClick={!isCompetence && this.getCustomPdf}>
                        { isCompetence ? <a style={{ padding: '5px 12px' }} target='_blank' rel="noopener noreferrer" href={data.fileUrl}>
                            <MenuIcon>
                                <FilePdfOutlined />
                            </MenuIcon>
                            Индивидуальный отчет для руководителя
                        </a> : <div style={{ padding: '5px 12px' }}>
                            <MenuIcon>
                                <FilePdfOutlined />
                            </MenuIcon>
                            Индивидуальный отчет для руководителя
                        </div>}
                    </Menu.Item> :
                    !meta.pending && data.status === 'started' ?
                        <Menu.Item style={{ padding: 0 }} key='respondent'>
                            <Popconfirm
                                title='Респондент не завершил тестирование. Хотите принудительно завершить тестирование и получить отчет?'
                                onConfirm={this.finishTest}
                                placement='bottomRight'>
                                <div style={{ padding: '5px 12px' }}>
                                    <MenuIcon>
                                        <FilePdfOutlined />
                                    </MenuIcon>
                                    Индивидуальный отчет для руководителя
                                </div>
                            </Popconfirm>
                        </Menu.Item> :
                        <Menu.Item style={{ padding: 0 }} key='respondent' onClick={isCompetence ? this.downloadPdf : this.getCustomPdf} disabled={meta.pending}>
                            <div style={{ padding: '5px 12px' }}>
                                <MenuIcon>
                                    <FilePdfOutlined />
                                </MenuIcon>
                                { meta.pending ? <span>Скачиваем...</span> : <span>Индивидуальный отчет для руководителя</span>}
                            </div>
                        </Menu.Item>
                }
                { answers.length && fullAnswers &&
                    <Menu.Item style={{ padding: 0 }} key='client'>
                        <div style={{ padding: '5px 12px' }} onClick={this.getRespondentsPilotPdf}>
                            <MenuIcon>
                                <FilePdfOutlined />
                            </MenuIcon>
                            Индивидуальный отчет для респондента (пилот)
                        </div>
                    </Menu.Item>
                }
        </Menu>;
    }

    render() {
        const { data: { status }, pending } = this.props;

        return <Button.Group>
            <Button type='primary' icon={<EditOutlined />} onClick={this.edit} disabled={pending}>
                <span className='hide-mobile'>Редактировать</span>
            </Button>
            { contains(status, ['started', 'finished']) && (
                <Dropdown
                    trigger={['click']}
                    overlay={this.getReportActionsMenu()}
                    visible={this.state.visibleReports}
                    onVisibleChange={this.handleMenuVisibleReports}>
                    <Button icon={<FilePdfOutlined />}>
                        <span className='hide-mobile'>
                            Скачать отчет <DownOutlined />
                        </span>
                    </Button>
                </Dropdown>
            )}
            <Dropdown
                overlay={this.getButtonsMenu()}
                onVisibleChange={this.handleVisibleMenuChange}
                visible={this.state.visibleMenu}
                trigger={['click']}
                disabled={pending}>
                <Button icon={<DownOutlined />} />
            </Dropdown>
        </Button.Group>
    }
}

const stateToProps = state => ({
    data: getCompanyRespondent.selectData(state),
    pending: getCompanyRespondent.selectMeta(state).pending,
    isAdmin: getUser.selectData(state).role === ADMIN
});

export const ProjectRespondentButtons = connect(stateToProps)(asyncConnect({
    deleteCompanyRespondent: deleteCompanyRespondent
        .withSuccessHandler(({ history }) => {
            message.success('Респондент успешно удален');
            history.goBack();
        })
        .withErrorHandler(() => message.error('Не удалось удалить респондента'))
        .withOptions({ resetOnUnmount: true }),
    getCompanyRespondentPdf: getCompanyRespondentPdf
        .withPayload(({ match: { params: { company, test, id, itemId } } }) => ({ id: itemId, company, test, project: id }))
        .withParams(({ match: { params: { itemId }}, data }) => ({ id: itemId, name: `${data.lastName || ''} ${data.firstName || ''} ${data.middleName || ''}` }))
        .withErrorHandler(() => message.error('Не удалось загрузить отчет')),
    postCompanyTestRestart: postCompanyTestRestart
        .withSuccessHandler(() => message.success('Прохождение теста успешно сброшено'))
        .withErrorHandler(() => message.error('Не удалось сбросить прохождение теста'))
        .withOptions({ resetOnUnmount: true }),
    postCompanyTestFinish: postCompanyTestFinish
        .withSuccessHandler(({ getCompanyRespondentPdf, match: { params: { id, itemId, company, test } } }) => {
            message.success('Тест успешно завершен');
            getCompanyRespondentPdf.dispatch({
                id: itemId,
                project: id,
                company,
                test
            });
        })
        .withErrorHandler(() => message.error('Не удалось завершить тест')),
    patchCompanyRespondent: patchCompanyRespondent
        .withSuccessHandler(() => message.success('Тест успешно отменен'))
        .withErrorHandler(() => message.error('Не удалось отменить тест'))
        .withOptions({ resetOnUnmount: true })
}, null, { openCompanyRespondentModal })(ProjectRespondentButtonsComponent));

export class ProjectRespondent extends Component {
    render() {
        const { getRespondent: { data, meta }, testTemplate, isAdmin } = this.props;
        const company = pathOr({}, ['_embedded',  'company'], data);
        const answers = pathOr([], ['_embedded',  'solution', '_embedded', 'answers'], data);
        const respondentFormFields = filter(({ answer }) => !!answer, data.respondentFormFields || []);
        const comments = pathOr([], ['_embedded', 'solution', '_embedded', 'auditComments'], data);

        return meta.pending && !meta.lastSucceedAt ?
            <Spin/> :
            <Fragment>
                <RespondentHeader data={data} />
                { !!respondentFormFields.length &&
                    <RespondentFormFields>
                        { respondentFormFields.map(field =>
                            <div key={field.code}>
                                <strong>{ field.name }:</strong> { field.answer }
                            </div>
                        )}
                    </RespondentFormFields>
                }
                <TestSolutionInfo data={data} testTemplate={testTemplate} />
                { (isAdmin || company.showRespondentAnswers) && !!answers.length &&
                    <AnswersResultWrapper>
                        <AnswersResult
                            test={path(['_embedded', testTemplate ? 'testTemplate' : 'testSuite'], data)}
                            answers={answers}
                            comments={comments}
                            audit={data.auditEnabled} />
                    </AnswersResultWrapper>
                }
            </Fragment>;
    }
}

const projectRespondentStateToProps = state => ({
    isAdmin: getUser.selectData(state).role === ADMIN
});

export default asyncConnect({
    getRespondent: getCompanyRespondent
        .withPayload(({ match: { params: { company, test, id, respondent } } }) => ({ id: respondent, company, test, project: id }))
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_COMPANY_RESPONDENT), toSuccess(POST_COMPANY_TEST_RESTART), toSuccess(POST_COMPANY_TEST_FINISH), toSuccess(PATCH_COMPANY_RESPONDENT)], function() {
                const { getRespondent } = getProps();

                getRespondent.refresh();
            })
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
}, projectRespondentStateToProps, { openRespondentAnalyticsModal })(ProjectRespondent);
