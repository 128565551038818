import React, { Component } from 'react';
import { prop } from 'ramda';
import { Field } from 'react-final-form';
import styled from 'styled-components';
import * as yup from 'yup';
import { message, Tooltip, Row, Col } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { LinkOutlined } from '@ant-design/icons';

import { getResponseHost } from '../../utils/urlParams';
import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import Editor from './formComponents/Editor';
import Switch from './formComponents/Switch';
import Input from './formComponents/Input';
import ListenerField from './ListenerField';

const Wrapper = styled.div`
    padding: 15px;
`;

const SlugField = styled.div`
    .ant-input-affix-wrapper + .ant-input-group-addon {
        padding: 0;
    }
`;

const LinkButton = styled.div`
    cursor: ${({ slug }) => slug ? 'pointer' : 'not-allowed'};
    padding: 0 10px;
    color: rgba(0, 0, 0, ${({ slug }) => slug ? 0.65 : 0.30});
`;

class CompanyPublicPageForm extends Component {
    getSlugAddonBefore = () => {
        return `${getResponseHost()}/`;
    }

    getSlugAddonAfter = slug => {
        return slug ?
            <CopyToClipboard
                text={`${this.getSlugAddonBefore()}${slug}`}
                onCopy={() => message.success('Ссылка успешно скопирована')}>
                <LinkButton slug={slug}>
                    <LinkOutlined />
                </LinkButton>
            </CopyToClipboard> :
            <Tooltip title='Введите код компании' placement='right'>
                <LinkButton slug={slug}>
                    <LinkOutlined />
                </LinkButton>
            </Tooltip>;
    }

    render() {
        return <Wrapper>
            <ListenerField listenFieldName='slug'>
                { ({ slug }) =>
                    <SlugField>
                        <Field
                            name='slug'
                            component={Input}
                            label='Код компании'
                            addonBefore={this.getSlugAddonBefore()}
                            addonAfter={this.getSlugAddonAfter(slug)} />
                    </SlugField>
                }
            </ListenerField>
            <Row gutter={16}>
                <Col xs={12}>
                    <Field
                        name='showPublicTestsLink'
                        component={Switch}
                        label='Показывать после завершения тестирования ссылку на все тесты'
                        hideOptional />
                </Col>
                <Col xs={12}>
                    <Field
                        name='showPublicTestsLinkInTest'
                        component={Switch}
                        label='Возврат к списку тестов'
                        hideOptional />
                </Col>
            </Row>
            <Field
                name='welcomeText'
                component={Editor}
                label='Приветственный текст' />
            <SubmitButton type='primary'>Сохранить</SubmitButton>
        </Wrapper>;
    }
}

const validationSchema = yup.object().shape({
    slug: yup.string().nullable().required()
});

export default withFormWrapper(CompanyPublicPageForm, {
    mapPropsToValues: prop('item'),
    validationSchema,
});
