import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import { putQuestion, getQuestion } from '../../../actions/asyncActions';
import QuestionForm from '../../forms/QuestionForm';
import withErrorHandler from '../../hocs/withErrorHandler';

class QuestionEdit extends Component {
    render() {
        const { putQuestion, getQuestion } = this.props;

        return <QuestionForm
            formAction={putQuestion}
            item={getQuestion.data}
            pending={getQuestion.meta.pending} />;
    }
}

export default withAsyncActions({
    getQuestion: getQuestion
        .withPayload(({ match }) => match.params.id)
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    putQuestion: putQuestion
        .withSuccessHandler(({ history }) => {
            message.success('Вопрос успешно изменен');
            history.goBack();
        })
        .withErrorHandler(() => message.error('Не удалось изменить вопрос'))
})(withErrorHandler('getQuestion')(QuestionEdit));
