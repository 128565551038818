import React from 'react';
import styled from 'styled-components';

const Styled = styled.div`
    display: inline-block;
    * {
        margin: 0;
        padding: 0;
        font-size: inherit;
        font-weight: normal;
        font-style: normal;
        display: inline;
        pointer-events: none;
        color: inherit;
    }
    img, video {
        display: none;
    }
`;

const StringWithoutStyles = ({ text }) =>
    <Styled dangerouslySetInnerHTML={{ __html: text }} />;

export default StringWithoutStyles;
