import UrlPattern from 'url-pattern';

const construct = url => new UrlPattern('(*)' + url);

export const BASE_URL = '/api';

export const getAvatar = (id, size = 50) => id ? `${BASE_URL}/images/${id}?q={"type":"square_thumbnail","width":${size},"height":${size}}` : null;

export const LOGIN = construct('/login_check');
export const PDA = construct('/profile/pda');
export const USER = construct('/sessions/current');
export const REFRESH_TOKEN = construct('/token/refresh');
export const PASSWORD_RECOVERY = construct('/password_recovery(/:id)');

export const CATEGORIES = construct('/categories(/:id)');
export const QUESTIONS = construct('/questions(/:id)');

export const ADMINS = construct('/admins(/:id)');
export const COMPANIES = construct('/companies(/:id)');
export const COMPANIES_FAVORITE = construct('/companies/:id/favorite');
export const COMPANY_CLIENTS = construct('/companies/:company/clients');
export const COMPANY_TESTS = construct('/companies/:company/projects/:project/tests(/:id)');
export const COMPANY_TEST_STATUS = construct('/companies/:company/projects/:project/tests/:id/status');
export const COMPANY_TEST_QUESTIONS = construct('/companies/:company/projects/:project/tests/:id/questions');
export const COMPANY_TEST_RESTART = construct('/companies/:company/projects/:project/tests/:id/respondents/:respondent/restart');
export const COMPANY_TEST_FINISH = construct('/companies/:company/projects/:project/tests/:id/respondents/:respondent/finish');
export const COMPANY_RESPONDENTS = construct('/companies/:company/projects/:project/tests/:test/respondents(/:id)');
export const COMPANY_RESPONDENTS_STAT = construct('/companies/:company/projects/:project/tests/:test/respondents/:id/stat');
export const COMPANY_RESPONDENTS_IMPORT = construct('/companies/:company/projects/:project/tests/:test/respondents/import/:id');
export const CLIENTS = construct('/clients(/:id)');
export const COMPANY_EMPLOYEES = construct('/companies/:company/employees(/:id)');
export const BUSINESS_UNITS = construct('/companies/:id/business_units');
export const COMPANY_PROJECTS = construct('/companies/:company/projects(/:id)');
export const COMPANY_TEMPLATES = construct('/companies/:company/templates(/:id)');
export const COMPANY_MESSAGES = construct('/companies/:company/projects/:project/messages');
export const COMPANY_PROJECT_TIME_SETTINGS = construct('/companies/:company/projects/:project/tests_time_settings');
export const COMPANY_PAYMENTS = construct('/companies/:company/payments(/:id)');
export const COMPANY_ACCOUNT_OPERATIONS = construct('/companies/:id/account_operations');
export const BANK_DETAILS = construct('/companies/:company/bank_details');
export const COMPANY_PAYMENT_TYPE = construct('/companies/:id/payment_type');
export const COMPANY_ENABLED = construct('/companies/:id/enabled');
export const COMPANY_SETTINGS = construct('/companies/:id/settings');

export const SEARCH = construct('/search');

export const COMPLEXITY = construct('/questions/complexity(/:id)');

export const RESPONDENTS = construct('/respondents');

export const PROJECT_ANALYTICS_PERCENTS = construct('/companies/:company/projects/:id/analytics/percents');
export const PROJECT_ANALYTICS_DISTRIBUTION = construct('/companies/:company/projects/:id/analytics/distribution');
export const PROJECT_ANALYTICS_STATUS = construct('/companies/:company/projects/:id/analytics/status');

export const COMMANDS = construct('/commands');
export const COMMANDS_UPLOAD = construct('/commands/upload');
export const COMMAND_CANCEL = construct('/commands/:id/cancel');

export const TEST_TEMPLATES = construct('/test_templates(/:id)');
export const TEST_TEMPLATE_CHANGES = construct('/test_templates/:id/changes');
export const TEST_TEMPLATE_VERSIONS = construct('/test_templates/:id/versions');
export const TEST_TEMPLATE_RESPONDENT_MESSAGES = construct('/test_templates/:id/respondent_messages');
export const TEST_TEMPLATE_AUDITS = construct('/test_templates/:test/audits(/:id)');
export const TEST_TEMPLATE_AUDIT_MESSAGES = construct('/test_templates/:id/messages');

export const RESPONDENTS_SCREENING = construct('/respondents/screening(/:id)');

export const EXPERTS = construct('/experts(/:id)');
export const EXPERTS_AUDITS = construct('/experts/:id/audits');

export const TEST_GROUPS = construct('/test_groups(/:id)');

export const PAYMENTS = construct('/payments(/:id)');
export const PAYMENT_BANK_DETAILS = construct('/payments/:id/bank_details');

export const PROJECT_STAT_COMPETENCIES = construct('/companies/:company/projects/:id/analytics/competencies');
export const PROJECT_STAT_INTEGRAL = construct('/companies/:company/projects/:id/analytics/integral');

export const PROJECTS = construct('/projects(/:id)');

export const DISTRIBUTIONS = construct('/distributions(/:id)');
export const DEFAULT_DISTRIBUTION = construct('/distributions/defaults');

export const CATEGORY_DISTRIBUTION = construct('/categories/:id/distribution');
export const CATEGORY_RECOMMENDATIONS = construct('/categories/:id/recommendations');

export const ENSI_CONTRACTS = construct('/ensi/contracts/:company');

export const SETTINGS = construct('/settings');
export const SETTINGS_TEMPLATES = construct('/settings/templates(/:id)');

export const ACCEPTORS = construct('/acceptors(/:id)');

export const MESSAGE_HISTORY_CONTRACTOR = construct('/message-history/contractor');
export const MESSAGE_HISTORY_PAYMENT = construct('/message-history/payment');

export const QUESTIONNARIES = construct('/survey/questionnaires(/:id)');
export const QUESTIONNARIES_REPORT_TYPES = construct('/survey/questionnaires/reports/types');
export const SURVEYS = construct('/survey/surveys(/:id)');
export const SURVEY_RESPONDENT = construct('/survey/respondents(/:id)');
export const SURVEY_RESPONDENT_CUSTOMER_FOCUS = construct('/survey/reports/customer_focus/:survey/respondents/:id');
export const SURVEY_RESPONDENT_DRIVER_PLUS = construct('/survey/reports/driver_plus/:survey/respondents/:id');
export const SURVEY_RESPONDENT_DRIVER_PLUS_IPSATIVE = construct('/survey/reports/driver_plus_ipsative/:survey/respondents/:id');
export const SURVEY_COMPANY_MESSAGES = construct('/survey/messages');

export const RESPONDENT_REPORT = construct('/public/respondents/:id/report');
