import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';
import { pathOr } from 'ramda';
import styled from 'styled-components';

import { putCompanyProjectTimeSettings } from '../../actions/asyncActions';
import Modal from './Modal';
import QuestionsSettingsForm from '../forms/QuestionsSettingsForm';

const ModalComponent = styled(Modal)`
    .ant-modal-body {
        padding: 0;
    }
`;

class ProjectQuestionsSettingsModal extends Component {
    mapBeforeSubmit = values => {
        return {
            ...values,
            items: {
                ...this.props.params.testsTimeSettings,
                complexitiesSettings: values.items
            }
        };
    }

    render() {
        const { modal, params, putCompanyProjectTimeSettings } = this.props;
        const complexitiesSettings = pathOr([], ['testsTimeSettings', 'complexitiesSettings'], params);

        return <Modal
            {...modal}
            title='Настройка для сложностей'
            footer={null}
            ModalComponent={ModalComponent}>
            <QuestionsSettingsForm
                formAction={putCompanyProjectTimeSettings}
                data={{
                    project: params.id,
                    company: params.company,
                    items: complexitiesSettings.length ? complexitiesSettings : null
                }}
                mapBeforeSubmit={this.mapBeforeSubmit}
                ableToggle />
        </Modal>
    }
}

export default withAsyncActions({
    putCompanyProjectTimeSettings: putCompanyProjectTimeSettings
        .withSuccessHandler(({ close }) => {
            message.success('Настройки были успешно сохранены');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(ProjectQuestionsSettingsModal);
