import React, { Component, Fragment } from 'react';
import { asyncConnect, toSuccess } from 'react-async-client';
import styled from 'styled-components';
import { Button, Spin, Row, Col } from 'antd';
import { takeEvery } from 'redux-saga/effects';
import { SolutionOutlined, MailOutlined, PhoneOutlined, EditOutlined } from '@ant-design/icons';

import DetailToolbar from '../DetailToolbar';
import { getCompanyEmployee } from '../../../actions/asyncActions';
import { openCompanyEmployeeModal } from '../../../actions/modalActions';
import { PUT_COMPANY_EMPLOYEE } from '../../../constants/actionTypes';

const ToolbarTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const Wrapper = styled.div`
    padding: 15px;
    background: #fff;
    background: #2b3d4f;
    h1{
        color: #fff;
    }
`;

const Name = styled.h1`
    font-size: 1.6rem;
    margin: -10px 0 0;
    .ant-tag{
        vertical-align: middle;
        margin-left: 10px;
    }
`;

const Field = styled.div`
    &:last-child{
        border: 0;
    }
    strong{
        min-width: 100px;
        display: inline-block;
    }
`;

const OtherInfo = styled.div`
    font-size: 1rem;
    color: #fff;
    font-weight: normal;
    .anticon{
        margin-right: 2px;
    }
    span{
        display: inline-block;
        margin-right: 20px;
    }
    ${Field}{
        margin-bottom: 5px;
    }
`;

class CompanyEmployee extends Component {
    renderTitle = () => {
        const { getCompanyEmployee: { data, meta }, openCompanyEmployeeModal } = this.props;

        return meta.lastSucceedAt &&
            <ToolbarTitle>
                <h1>{ `${data.lastName} ${data.firstName} ${data.middleName || ''}` }</h1>
                <Button type='primary' icon={<EditOutlined />} onClick={() => openCompanyEmployeeModal(data)}>
                    <span className='hide-mobile'>Редактировать</span>
                </Button>
            </ToolbarTitle>
    }

    render() {
        const { getCompanyEmployee: { data, meta }, match: { params: { company }}} = this.props;

        return <Fragment>
            <DetailToolbar title={this.renderTitle()} backUrl={`/companies/${company}`} />
            { meta.pending && !meta.lastSucceedAt ?
                <Spin /> :
                <Wrapper>
                    <Row>
                        <Col span={12}>
                            <Name>{ `${data.lastName} ${data.firstName} ${data.middleName || ''}` }</Name>
                            <OtherInfo>
                                { !!data.position && <span><SolutionOutlined />{ data.position }</span> }
                            </OtherInfo>
                        </Col>
                        <Col span={12}>
                            <OtherInfo>
                                <div><MailOutlined /> { data.email }</div>
                                { data.phone && <span><PhoneOutlined /> { data.phone }</span> }
                            </OtherInfo>
                        </Col>
                    </Row>
                </Wrapper>
            }
        </Fragment>;
    }
}

export default asyncConnect({
    getCompanyEmployee: getCompanyEmployee
        .withPayload(({ match: { params: { id, company }}}) => ({ id, company }))
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_COMPANY_EMPLOYEE)], function() {
                const { getCompanyEmployee } = getProps();

                getCompanyEmployee.refresh();
            })
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
}, null, { openCompanyEmployeeModal })(CompanyEmployee);
