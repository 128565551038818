import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { getAsyncReducers } from 'react-async-client';

import { history } from '../store/middlewares';
import modals from './modals';
import pdf from './pdf';

export default () => combineReducers({
    ...getAsyncReducers(),
    router: connectRouter(history),
    modals,
    pdf
});
