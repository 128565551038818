import React, { Component, Fragment } from 'react';
import * as yup from 'yup';
import { Field } from 'react-final-form';
import { MailOutlined } from '@ant-design/icons';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';

class RecoveryForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='email'
                component={Input}
                htmlType='email'
                size='large'
                icon={<MailOutlined />}
                placeholder='Email' />
            <SubmitButton size='large' type='primary'>
                Восстановить пароль
            </SubmitButton>
        </Fragment>
    }
}

const validationSchema = yup.object().shape({
    email: yup.string().email().required()
});

export default withFormWrapper(RecoveryForm, {
    validationSchema
});
