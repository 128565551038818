import React, { Component, Fragment } from 'react';
import { withAsyncActions } from 'react-async-client';
import { Link } from 'react-router-dom';

import { Logo, H3 } from './Login';
import ResetPasswordForm from '../forms/ResetPasswordForm';
import { postResetPassword } from '../../actions/asyncActions';
import { message } from 'antd';

class ResetPassword extends Component {
    render() {
        return <Fragment>
            <Logo />
            <H3>Изменение пароля</H3>
            <ResetPasswordForm
                id={this.props.match.params.id}
                formAction={this.props.postResetPassword} />
            <Link style={{ marginTop: 15, display: 'inline-block' }} to='/'>Войти</Link>
        </Fragment>;
    }
}

export default withAsyncActions({
    postResetPassword: postResetPassword
        .withSuccessHandler(({ history }) => {
            message.success('Пароль успешно изменен');
            history.push('/');
        })
        .withErrorHandler(() => message.error('Не удалось изменить пароль'))
        .withOptions({ resetOnUnmount: true })
})(ResetPassword);
