import { message, Switch } from 'antd';
import { path } from 'ramda';
import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import { withState } from 'recompose';

import { getUser, putCompanySettings, putCompany } from '../../../actions/asyncActions';
import { ADMIN } from '../../../constants/roles';

class ClientAllowSwitch extends Component {
    static defaultProps = {
        commonSettings: {}
    };

    onChange = value => {
        this.props.putCompanySettings.dispatch({
            id: this.props.id,
            data: {
                commonSettings: {
                    ...(this.props.commonSettings || {}),
                    [this.props.path]: value
                }
            }
        });
        this.props.setAllowed(value);
    }

    onChangeCompany = value => {
        this.props.putCompany.dispatch({
            ...(this.props.commonSettings || {}),
            [this.props.path]: value
        });
        this.props.setAllowed(value);
    }

    render() {
        const { user, style, label = 'Доступно клиенту', isCompanySave, putCompany, putCompanySettings } = this.props;

        return user.role === ADMIN ? <div style={style}>
            <Switch
                checked={this.props.allowed}
                onChange={isCompanySave ? this.onChangeCompany : this.onChange}
                style={{ marginRight: 10 }}
                loading={putCompanySettings.meta.pending || putCompany.meta.pending}
            /> {label}
        </div> : null;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default withState('allowed', 'setAllowed', props => !!path([props.path], props.commonSettings))(asyncConnect({
    putCompanySettings: putCompanySettings
        .withSuccessHandler(() => message.success('Настройки успешно сохранены'))
        .withErrorHandler(({ setAllowed, allowed }) => {
            setAllowed(!allowed);
            message.error('Не удалось сохранить настройки');
        })
        .withOptions({ resetOnUnmount: true }),
    putCompany: putCompany
        .withParams(() => 'switch')
        .withSuccessHandler(() => message.success('Настройки успешно сохранены'))
        .withErrorHandler(({ setAllowed, allowed }) => {
            setAllowed(!allowed);
            message.error('Не удалось сохранить настройки');
        })
        .withOptions({ resetOnUnmount: true })
}, stateToProps)(ClientAllowSwitch));
