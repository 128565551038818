import React, { Component } from 'react';
import { InputNumber } from 'antd';

import withFieldWrapper from '../../hocs/withFieldWrapper';

class InputNumberComponent extends Component {
    render() {
        const { placeholder, min, max, disabled, input: { value }, onChange } = this.props;

        return <InputNumber
            value={value}
            disabled={disabled}
            onChange={onChange}
            min={min}
            max={max}
            placeholder={placeholder} />;
    }
}

export default withFieldWrapper(InputNumberComponent);
