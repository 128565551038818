import React, { Component } from 'react';
import { Card, Tabs } from 'antd';
import styled from 'styled-components';
import { find, propEq } from 'ramda';

import Route from '../../Route';
import SettingsTemplatesTab from './SettingsTemplatesTab';
import SettingsTab from './SettingsTab';

const StyledCard = styled(Card)`
    .ant-card-head {
        background: rgb(250, 250, 250);
        border: 1px solid rgb(232, 232, 232);
    }
    .ant-card-body {
        padding: 0;
        border-top: 1px solid rgb(232, 232, 232);
    }
`;

const StyledTabs = styled(Tabs)`
    .ant-tabs-bar {
        margin: 0;
    }

    .ant-tabs-content {
        padding: 15px;
        border: 1px solid rgb(232, 232, 232);
        border-top: none;
    }
`;

const tabs = [
    { key: '', title: 'Общие', component: SettingsTab },
    { key: 'template', title: 'Шаблоны', component: SettingsTemplatesTab },
];

const getRoute = (key) => `/settings${key ? `/${key}` : ''}`;

class Settings extends Component {
    onChange = key => {
        const { history } = this.props;
        const tab = find(propEq('key', key), tabs);

        history.replace(getRoute(tab.route || key));
    }

    render() {
        const { match: { params: { type } } } = this.props;

        return <StyledCard title='Настройки'>
            <StyledTabs
                onChange={this.onChange}
                activeKey={type || ''}
                animated={{ tabPane: false }}>
                { tabs.map(tab =>
                    <Tabs.TabPane key={tab.key} tab={tab.title}>
                        <Route
                            path={getRoute(tab.key)}
                            render={props => <tab.component {...props} />}
                        />
                    </Tabs.TabPane>
                )}
            </StyledTabs>
        </StyledCard>;
    }
}

export default Settings;
