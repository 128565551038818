import React, { Fragment } from 'react';
import TooltipParagraph from '../table/TooltipParagraph';

const Contacts = ({ email, phone }) =>
    <Fragment>
        { email && <TooltipParagraph>{ email }</TooltipParagraph> }
        { phone && <TooltipParagraph>{ phone }</TooltipParagraph> }
    </Fragment>;

export default Contacts;
