import React, { Component } from 'react';
import { message } from 'antd';
import { asyncConnect } from 'react-async-client';
import { path, isNil } from 'ramda';

import { openPaymentModal } from '../../actions/modalActions';
import { postCompanyMessages } from '../../actions/asyncActions';
import LetterForm from '../forms/LetterForm';
import Modal from './Modal';
import { COMPANY_PARTICIPANT_REPORT_TEMPLATE_VARIABLES } from '../../constants/companies';

class RespondentReportsLetterModal extends Component {
    state = {
        count: null
    }

    setCount = count => this.setState({ count });

    render() {
        const { modal, postCompanyMessages, params: { item, filtered }} = this.props;

        return <Modal
            {...modal}
            title={`Массовая отправка индивидуального отчета для респондента` + (!isNil(this.state.count) ? ` (${this.state.count})` : '')}
            footer={null}>
            <LetterForm
                reportLetter
                formAction={postCompanyMessages}
                filtered={filtered}
                item={item}
                contentOptions={COMPANY_PARTICIPANT_REPORT_TEMPLATE_VARIABLES}
                setCount={this.setCount}
                amount={this.state.count}
            />
        </Modal>;
    }
}

export default asyncConnect({
    postCompanyMessages: postCompanyMessages
        .withSuccessHandler(({ close }) => {
            message.success('Письмо было успешно отправлено');
            close();
        })
        .withErrorHandler(({ postCompanyMessages: { meta }, openPaymentModal, params }) => {
            const neededBalance = path(['error', 'data', 'errors', 0, 'attributes', 'neededBalance'], meta);

            if (neededBalance) {
                message.error('Недостаточно юнитов на балансе компании');
                openPaymentModal({ company: path(['item', 'company'], params) });
            } else {
                message.error('Не удалось отправить письмо');
            }
        })
        .withOptions({ resetOnUnmount: true })
}, null, { openPaymentModal })(RespondentReportsLetterModal);
