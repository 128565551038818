import React, { Component } from 'react';
import { message, Spin } from 'antd';
import { toSuccess, withAsyncActions } from 'react-async-client';
import { takeEvery } from '@redux-saga/core/effects';

import { getSettings, putSettings } from '../../../actions/asyncActions';
import SettingsForm from '../../forms/SettingsForm';
import { PUT_SETTINGS } from '../../../constants/actionTypes';

class Settings extends Component {
    render() {
        const { getSettings: { data, meta }, putSettings } = this.props;

        return (
            <Spin spinning={meta.pending && !meta.lastSuccessAt}>
                <SettingsForm
                    formAction={putSettings}
                    item={data} />
            </Spin>
        );
    }
}

export default withAsyncActions({
    getSettings: getSettings
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_SETTINGS)], () => {
                getProps().getSettings.refresh();
            });
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    putSettings: putSettings
        .withSuccessHandler(() => message.success('Настройки успешно сохранены'))
        .withErrorHandler(() => message.error('Не удалось сохранить настройки'))
        .withOptions({ resetOnUnmount: true })
})(Settings);
