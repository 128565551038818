import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import withFormWrapper from '../../hocs/withFormWrapper';
import { openCompanyDesignPageModal } from '../../../actions/modalActions';
import ProjectSettingsFields from './ProjectSettingsFields';
import withStepsPagination from './withStepsPagination';

class ProjectSettingsStep extends Component {
    render() {
        return <Fragment>
            <ProjectSettingsFields {...this.props} showRespondentFormFields />
            { this.props.submitBtn || null }
        </Fragment>;
    }
}

export const ProjectSettings = connect(null, { openCompanyDesignPageModal })(
    withFormWrapper(ProjectSettingsStep, {
        mapPropsToValues: ({ item }) => item
    })
);

export default connect(null, { openCompanyDesignPageModal })(
    withFormWrapper(withStepsPagination(ProjectSettingsStep), {
        mapPropsToValues: ({ item }) => item
    })
);
