import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import Modal from './Modal';
import { patchCategory } from '../../actions/asyncActions';
import QuestionsDistributionForm from '../forms/QuestionsDistributionForm';

class QuestionsDistributionModal extends Component {
    render() {
        const { modal, patchCategory, params } = this.props;

        return <Modal
            {...modal}
            title='Привязка к распределению'
            footer={null}>
            <QuestionsDistributionForm
                item={params.item}
                formAction={patchCategory} />
        </Modal>;
    }
}

export default withAsyncActions({
    patchCategory: patchCategory
        .withParams(({ params: { item, common }}) => ({ parent: common ? null : item.parent, id: item.id, type: 'link' }))
        .withSuccessHandler(({ close }) => {
            message.success('Распредение успешно привязано к категории');
            close();
        })
        .withErrorHandler(() => message.error('Не удалсоь привязать распредение с категории'))
        .withOptions({ resetOnUnmount: true }),
})(QuestionsDistributionModal);
