import { drop, dropLast, pathOr, path } from "ramda";
import moment from 'moment';

export const downloadCustomerFocusRespondentPdf = (respondent, data) => {
    const docDefinition = {
        styles: {
            font: 'Geometria Light'
        },
        pageOrientation: 'portrait',
        pageMargins: [0, 55, 0, 50],
        header: {
            columns: [
                {
                    width: 148,
                    layout: 'noBorders',
                    table: {
                        layout: {
                            hLineWidth: () => 0,
                            vLineWidth: () => 0,
                            paddingTop: () => 0,
                            paddingBottom: () =>  0,
                            paddingLeft: () => 0,
                            paddingRight: () => 0
                        },
                        widths: [148],
                        heights: [51],
                        body: [
                            [
                                {
                                    text: [
                                        { text: 'Customer Focus\n', bold: true, fontSize: 18, },
                                        { text: ` ${respondent.firstName} ${respondent.lastName} `, fontSize: 11 }
                                    ],
                                    margin: [15, 5, 0, 0],
                                    fillColor: '#2c3d4f',
                                    style: {
                                        color: '#fff'
                                    },
                                }
                            ]
                        ]
                    },
                },
                {
                    svg: `<svg width="80px" height="55px" viewBox="0 0 80 55">
                        <polygon stroke="#2c3d4f" stroke-width="1px" fill="#2c3d4f" points="0,0 80,0 0,55" />
                    </svg>`,
                    margin: [0, 0, 0, 0],
                    alignment: 'left',
                }
            ],
        },
        content: [
            {
                margin: [55, 5, 50, 5],
                table: {
                    widths: [ 200, '*' ],
                    heights: [25, 25],
                    headerRows: 0,
                    body: [
                        [
                            { text: '\nФИО:', alignment: 'left', fontSize: 14, border: [false, false, false, true], margin: [0, 5] },
                            { text: `\n${respondent.lastName || ''} ${respondent.firstName|| ''} ${respondent.middleName || ''}`, alignment: 'left', fontSize: 14, border: [false, false, false, true], margin: [0, 5] },
                        ], [
                            { text: '\nEmail:', alignment: 'left', fontSize: 14, border: [false, false, false, true], margin: [0, 5] },
                            { text: `\n${respondent.email || '-'}`, alignment: 'left', fontSize: 14, border: [false, false, false, true], margin: [0, 5] },
                        ]
                    ],
                },
                alignment: 'justify'
            },
            {
                svg: window.statChart.chart.getSVG(),
                width: 500,
                alignment: 'center'
            },
            {
                margin: [55, 20, 50, 0],
                table: {
                    widths: [ 80, 80, '*' ],
                    headerRows: 1,
                    body: [
                        [
                            { text: 'Баллы', alignment: 'center', bold: true, fontSize: 10, border: [false, false, true, true] },
                            { text: `Уровень развития`, alignment: 'center', bold: true, fontSize: 10, border: [false, false, true, true] },
                            { text: `Особенности проявления`, alignment: 'center', bold: true, fontSize: 10, border: [false, false, false, true] },
                        ],
                        [
                            { text: '7-10', alignment: 'center', fontSize: 10, border: [false, false, true, true] },
                            { text: `Выше среднего`, alignment: 'center', fontSize: 10, border: [false, false, true, true] },
                            { text: `Высокая склонность к оказанию поддержки клиенту, к сервисному обслуживанию; сохраняет позитивный настрой в большинстве ситуаций.`, alignment: 'center', fontSize: 10, border: [false, false, false, true] },
                        ],
                        [
                            { text: '4-6', alignment: 'center', fontSize: 10, border: [false, false, true, true] },
                            { text: `Нормальные способности`, alignment: 'center', fontSize: 10, border: [false, false, true, true] },
                            { text: `Есть склонность к оказанию поддержки клиенту, сервисному обслуживанию.`, alignment: 'center', fontSize: 10, border: [false, false, false, true] },
                        ],
                        [
                            { text: '0-3', alignment: 'center', fontSize: 10, border: [false, true, true, false] },
                            { text: `Ниже среднего`, alignment: 'center', fontSize: 10, border: [false, true, true, false] },
                            { text: `Требуются усилия, чтобы сохранить должный уровень обслуживания клиентов в течение длительного времени или в стрессовой ситуации.​`, alignment: 'center', fontSize: 10, border: [true, false, false, false] },
                        ],
                    ],
                },
                alignment: 'center',
                layout: {
                    paddingTop: () => 5,
                    paddingBottom: () =>  5,
                    paddingLeft: () => 5,
                    paddingRight: () => 5
                },
            },
        ],
        footer: {
            columns: [
                {
                    svg: `<svg width="80px" height="50px" viewBox="0 0 80 50">
                        <polygon stroke="#E71D32" stroke-width="1px" fill="#E71D32" points="0,50 80,0 80,50" />
                    </svg>`,
                    margin: [0, 0, 0, 0],
                    alignment: 'right',
                },
                {
                    width: 140,
                    layout: 'noBorders',
                    table: {
                        widths: [140],
                        heights: [50],
                        body: [
                            [
                                {
                                    alignment: 'right',
                                    fillColor: '#E71D32',
                                    margin: [0, 15, 5, 0],
                                    svg: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="115px" height="22px" viewBox="0 0 115 22" version="1.1">
                                        <title>logo</title>
                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g id="logo" fill="#FFFFFF" fill-rule="nonzero">
                                                <path d="M106.773,0.388235 L103.214,5.5 L103.214,0.388235 L96.7954,0.388235 L96.7954,21.5471 L103.214,21.5471 L103.214,7.95883 L109.061,7.95883 L114.4,0.388235 L106.773,0.388235 Z M82.2408,15.9176 C79.508,15.9176 77.5378,13.8471 77.5378,11 C77.5378,8.15294 79.5715,6.01765 82.2408,6.01765 C84.9102,6.01765 86.944,8.15294 86.944,11 C87.0072,13.8471 84.9738,15.9176 82.2408,15.9176 Z M82.2408,0 C76.0125,0 71.1187,4.85294 71.1187,11 C71.1187,17.1471 76.0125,22 82.2408,22 C88.4693,22 93.3634,17.1471 93.3634,11 C93.4266,4.85294 88.5326,0 82.2408,0 Z M62.0938,15.9176 C59.1702,15.9176 57.1365,13.9117 57.1365,11 C57.1365,8.15294 59.2338,6.01765 61.9667,6.01765 C63.3648,6.01765 64.7631,6.6 65.78,7.63529 L69.2755,2.65294 C67.3053,0.970587 64.6995,0 61.9667,0 C55.6747,0 50.7173,4.85294 50.7173,11 C50.7173,17.2765 55.5475,22 61.9667,22 C65.0173,22 67.5595,21.0941 69.6568,19.2176 L66.1613,14.1706 C65.208,15.0765 63.8098,15.9176 62.0938,15.9176 Z M39.0867,0 C37.2435,0 34.1929,0.905881 33.1125,2.26471 L33.1125,0.388235 L26.884,0.388235 L26.884,21.5471 L33.3031,21.5471 L33.3031,8.15295 C34.0022,7.24706 35.6547,6.01765 37.6249,6.01765 C38.7053,6.01765 39.6587,6.34117 40.2307,6.98824 C40.7391,7.50588 41.2475,8.28235 41.2475,10.3529 L41.2475,21.5471 L47.6667,21.5471 L47.6667,7.95883 C47.6667,2.65294 43.8533,0 39.0867,0 Z M11.1222,15.9176 C8.38933,15.9176 6.41911,13.8471 6.41911,11 C6.41911,8.15294 8.45288,6.01765 11.1222,6.01765 C13.7915,6.01765 15.8253,8.15294 15.8253,11 C15.8253,13.8471 13.8551,15.9176 11.1222,15.9176 Z M15.5711,2.26471 C14.2365,0.647059 12.2662,0 10.296,0 C4.51245,0 0,4.85294 0,11 C0,13.7824 0.953333,16.3706 2.66933,18.3765 C4.576,20.6412 7.56311,21.9353 10.6138,21.9353 C12.4569,21.9353 14.5542,21.1588 15.6347,19.7353 L15.6347,21.5471 L21.8631,21.5471 L21.8631,0.388235 L15.6347,0.388235 L15.6347,2.26471 L15.5711,2.26471 Z" id="Shape"/>
                                            </g>
                                        </g>
                                    </svg>`
                                }
                            ]
                        ]
                    }
                }
            ],
        },
    };

    window.pdfMake.createPdf(docDefinition).download(`Customer-Focus-${respondent.lastName}.pdf`);
}

export const DRIVER_PLUS_RESULTS = [
    { id: 'potential', title: 'Профессиональный рост и реализация потенциала', description: 'Шкала определяет, насколько для человека важно профессиональное самовыражение, результат его деятельности.  Человек с высоким интересом к профессиональным задачам получает удовольствие от работы, видит смыслы и стимулы в решении профессиональных задач, с низким интересом - не придает особого значения содержанию работы, готов выполнять рутинные и однообразные задачи с той же мотивацией, что и масштабные, сложные.' },
    { id: 'relations', title: 'Отношения и поддержка коллег', description: 'Шкала определяет, насколько для человека важна принадлежность к команде, включенность в коллектив и дружеская поддержка коллег, социальная защищенность, надежность связей и отношений. Высокий балл по данной шкале говорит о том, что мотивация человека к работе зависит от отношений с коллегами, низкий балл свидетельствует об обратном.' },
    { id: 'payment', title: 'Материальное вознаграждение', description: 'Шкала определяет, насколько для человека важна материальная стабильность и достаток, обеспеченность, и насколько она влияет на его мотивацию в трудовой деятельности. Человек с высоким баллом по данной шкале будет стремиться постоянно увеличивать свой доход,  а человек с низким баллом не связывает впрямую мотивацию к работе с материальным доходом.' },
    { id: 'social', title: 'Социальная деятельность', description: 'Шкала определяет, насколько в трудовой деятельности для мотивации человека важна гуманистическая составляющая: помощь людям, улучшение качества жизни, развитие общества, сохранение экологии, забота о планете и т.п. Высокий балл по данной шкале говорит о том, что для человека важно приносить пользу людям и обществу в целом. Низкий балл по данной шкале свидетельствует о том, что для высокого уровня мотивации человеку не важна социально значимая деятельность.' },
    { id: 'influence', title: 'Влияние', description: 'Шкала определяет, насколько для мотивации человека важен фактор возможности влияния на процессы,  на результат деятельности организации,  на команду. Высокий балл по данной шкале говорит о том, что возможность влияния важна для успешной деятельности сотрудника, и он будет стремиться повышать свой статус, строить карьеру и расширять свое влияние, низкий балл свидетельствует об обратном.' },
    { id: 'communication', title: 'Общение с людьми', description: 'Шкала определяет, насколько человеку важно, чтобы процесс трудовой деятельности был связан с общением с другими людьми. Высокий балл по данной шкале говорит о важности для мотивации человека общения с другими людьми, низкий балл свидетельствует о том, что для поддержания высокой мотивации к работе человеку не нужно взаимодействие и общение с другими людьми.' },
    { id: 'acceptance', title: 'Признание', description: 'Шкала определяет, насколько человеку важно общественное признание его талантов и заслуг. Человек с высоким баллом по данной шкале ценит публичное признание, это стимулирует его к победам и свершениям, для человека с низким баллом по данной шкале внешнее признание его заслуг не имеет особого значения.' },
    { id: 'stability', title: 'Стабильность и комфорт', description: 'Шкала определяет, насколько для человека важно следовать сложившейся традиции, ожиданиям близких, обстоятельствам. Для человека с высоким баллом по данной шкале важно сохранять сложившийся порядок, избегать изменений, выбирать традиционный путь, работать по шаблонам, снижать вероятность конфликтов, для сотрудника с низким баллом стабильность и традиции не важны для сохранения высокой мотивации.' },
    { id: 'supervisor', title: 'Отношения с руководителем', description: 'Шкала определяет, насколько для человека важна личность руководителя, его статус и взаимоотношения с ним. Человек с высоким баллом по данной шкале придает большое значение отношениям с непосредственным руководителем, может принимать решения, руководствуясь этими отношениями. Если балл низкий, то отношения с руководителем не имеют особого значения для мотивации человека.' },
];

export const DRIVER_PLUS_IPSATIVE_RESULTS = [
    { id: 'potential', title: 'Профессиональный рост и реализация потенциала', motivator: 'Для Вас важно, чтобы работа предоставляла возможности для самосовершенствования, личностного и профессионального развития, приобретения новых знаний и навыков. Вы стремитесь привносить новое в рабочие процессы, Вас мотивирует выполнение разнообразных и интересных задач.', factor: 'В работе Вы скорее направлены на достижение результата, нежели чем на получение удовольствия от процесса работы и решение различных познавательных задач. Вероятно, профессиональная деятельность является для Вас средством удовлетворения других потребностей, но не самоцелью.' },
    { id: 'relations', title: 'Отношения и поддержка коллег', motivator: 'Для вас важно работать с близкими по духу людьми, иметь поддержку и защиту со стороны коллектива. Вы хотите чувствовать себя частью команды и поддерживать связь с коллегами.', factor: 'Характер взаимоотношений в коллективе не играет важной роли в формировании Вашей профессиональной мотивации. Вы не стремитесь к образованию дружеских связей с коллегами, не склонны видеть в коллегах источник защиты и поддержки. Вероятно, разногласия в коллективе не будут негативно отражаться на Вашей профессиональной деятельности.' },
    { id: 'payment', title: 'Материальное вознаграждение', motivator: 'Уровень материального вознаграждения является для Вас важным фактором мотивации. Вам важно видеть чёткую взаимосвязь между заработной платой, премиями и вложенными в работу усилиями. Увеличение дохода является важным источником Вашей профессиональной мотивации.', factor: 'Деньги и материальные ценности для Вас не главное. Вы не испытываете серьёзной потребности иметь более высокий уровень жизни. Вероятно, работа для Вас - это нечто большее, чем возможность хорошо зарабатывать и иметь высокий статус.' },
    { id: 'social', title: 'Социальная деятельность', motivator: 'Вас вдохновляет и мотивирует работа, приносящая пользу обществу. Вам важно, чтобы Ваша профессиональная деятельность имела гуманистическую направленность: ориентацию на помощь людям, улучшение качества жизни, развитие общества, сохранение экологии, заботу о планете и т.п. Для Вас также важно, чтобы Ваши личные ценности совпадали с ценностями компании.', factor: 'Наличие гуманистической направленности в Вашей трудовой деятельности не является для Вас необходимостью. Вы готовы работать в компании, которая не преследует своей целью помощь людям, заботу об экологии, развитие общества и т.п.' },
    { id: 'influence', title: 'Влияние', motivator: 'Вам важно иметь возможность влияния на процессы, результат деятельности команды и организации. Для Вас важно чувствовать свою значимость в компании, в связи с чем в работе Вы, вероятно, будете стремиться повышать свой статус, строить карьеру и расширять свое влияние.', factor: 'Влияние на рабочие процессы, команду и деятельность организации в целом не является для Вас важным источником мотивации. Вероятно, Вам комфортнее работать в составе группы, чем руководить другими, отдавать распоряжения и указания и контролировать ситуацию.' },
    { id: 'communication', title: 'Общение с людьми', motivator: 'Для Вас важно, чтобы процесс трудовой деятельности был связан с общением с другими людьми. Вы предпочитаете работу в коллективе и, напротив, Вам может быть некомфортно работать в одиночку, в изоляции от коллег.', factor: 'Вы не испытываете выраженной потребности общения с другими людьми в процессе Вашей трудовой деятельности. Вероятно, Вы предпочитаете индивидуальную работу командному формату выполнения задач.' },
    { id: 'acceptance', title: 'Признание', motivator: 'Получение позитивной обратной связи, похвала, благодарность и другие проявления признания достижений оказывают положительное влияние на Вашу мотивацию. Публичное признание Ваших заслуг стимулирует Вас на дальнейшие профессиональные победы и свершения.', factor: 'Вы не стремитесь получать признание своих заслуг со стороны коллег и руководства. Ситуации, в которых результаты Вашей работы оказываются незамеченными руководством, вероятно, не оказывают на Вас демотивирующего воздействия.' },
    { id: 'stability', title: 'Стабильность и комфорт', motivator: 'Вы высоко цените постоянство, стабильность и комфорт в работе. Для Вас важно иметь уверенность в компании-работодателе, Вы также стремитесь завоевать прочную репутацию, которая обеспечит Вам ясное и определённое будущее. Быстро меняющаяся обстановка и рабочие процессы, конфликты в коллективе, вероятно, окажут на Вас негативное воздействие.', factor: 'Стабильность и определённость не являются необходимыми факторами для Вашей профессиональной мотивации. Вы готовы пожертвовать спокойствием и комфортом ради достижения Ваших целей, легко принимаете изменения в рабочих процессах.' },
    { id: 'supervisor', title: 'Отношения с руководителем', motivator: 'Вы придаете большое значение отношениям с непосредственным руководителем,  руководитель является для Вас носителем профессионального образа, к которому Вы стремитесь. Доступность руководителя, доверительные отношения с руководителем и наличие в них преемственности опыта являются важными источниками мотивации в Вашей профессиональной деятельности.', factor: 'Отношения с непосредственным руководителем и личность руководителя не являются важным фактором для Вашей профессиональной мотивации. Вероятно, в своём профессиональном развитии Вы склонны ориентироваться, в первую очередь, на собственные ориентиры и цели, и личность руководителя не является для Вас профессиональным примером.' },
];

export const DRIVER_PLUS_IPSATIVE_CLIENT_RESULTS = [
    {
        id: 'potential',
        title: 'Профессиональный рост и реализация потенциала',
        description: 'Сотруднику важно, чтобы работа способствовала профессиональному развитию, приобретению новых знаний и навыков; важно иметь возможность привносить новое и иметь разнообразные задачи.',
        recommendation: '1) Предоставьте сотруднику возможность для обучения и освоения новых навыков и знаний.\n2) Вовлекайте сотрудника в решение разнообразных задач, выходящих за пределы рутинных обязанностей и, возможно, требующих дополнительных усилий для изучения и освоения новых профессиональных областей и знаний. \n3) Предоставьте сотруднику возможность творческого решения задач, внедрения инициатив.',
        warning: '1) Не стоит возлагать на сотрудника большое количество рутинных задач, которые не предоставляют возможность для профессионального и личного развития и не способствуют освоению новых знаний и навыков. При данном профиле мотивации рутинность трудовой деятельности снижает готовность и желание сотрудника работать. \n2) Отсутствие профессионального роста и перспектив снижает мотивацию при наличии у человека данного мотивационного профиля, поэтому не пренебрегайте обсуждением с сотрудником его актуального положения в организации и возможностей для профессионального развития.'
    },
    {
        id: 'relations',
        title: 'Отношения и поддержка коллег',
        description: 'Сотруднику важно иметь близкие отношения с коллегами, чувствовать себя полноправным членом команды, работать в атмосфере взаимопомощи и поддержки.',
        recommendation: '1) Организуйте корпоративные мероприятия, направленные на сплочение команды. Это поможет сотруднику поддерживать чувство принадлежности к коллективу и причастности к организации и работе вообще. \n2) Организуйте командные мероприятия, например: встречи по проектам, фокус-группы или сессии мозгового штурма. Такой командный формат работы, способствующий обмену мнениями внутри коллектива, будет мотивировать сотрудника на профессиональные успехи. \n3) Отмечайте значимый вклад сотрудника в работу команды и достижение общих целей. При данном мотивационном профиле сотрудник значительно мотивирован на поддержание контактов с другими и стремится завоевать их расположение и уважение к себе.',
        warning: '1) Не препятствуйте необходимому уровню коммуникации в команде, так как для сотрудника важно находиться в контакте с коллегами и обсуждать текущие задачи и способы их решения. Продумайте баланс удаленной и оффлайн-работы для этого сотрудника. \n2) Не давайте сотруднику слишком много индивидуальных задач без возможности привлечения коллег, так как это может способствовать развитию чувства изолированности от коллектива и снижению мотивации. \n3) Не забывайте о важности поддержания благоприятной психологической обстановки в коллективе, так как наличие конфликтов и иных дестабилизирующих социальное взаимодействие внутри коллектива явлений особенно снижает рабочую мотивацию сотрудника.'
    },
    {
        id: 'payment',
        title: 'Материальное вознаграждение',
        description: 'Материальное вознаграждение существенно влияет на мотивацию сотрудника. Сотруднику с таким мотивационным профилем важно иметь справедливую заработную плату, а также возможность увеличения своего дохода.',
        recommendation: '1) Поощряйте профессиональные успехи и усилия сотрудника повышением заработной платы, бонусами, премиями и иными материальными преимуществами. \n2) Для этого сотрудника важно видеть отражение своего профессионализма и производительности в уровне заработной платы, поэтому в разговоре о карьерных изменениях направляйте фокус на обсуждение связи между вложенными в работу усилиями и полученным за них вознаграждением. \n3) Поддерживайте уровень заработной платы сотрудника не ниже среднерыночного эквивалента, соответствующего уровню должности и сфере деятельности, а также старайтесь избегать неоправданной разницы в заработных платах коллег.',
        warning: '1) Не забывайте, что длительное отсутствие повышения заработной платы или иных финансовых выгод может значительно снижать уровень мотивации сотрудника. \n2) Отсутствие очевидной связи между уровнем материального вознаграждения и количеством затраченных усилий может негативно сказываться на мотивации сотрудника с описываемым мотивационным профилем.'
    },
    {
        id: 'social', title: 'Социальная деятельность',
        description: 'Сотруднику важно, чтобы работа имела общественную направленность и была основана на гуманистических ценностях.',
        recommendation: '1) Подчеркивайте наличие у компании определённого имиджа и ценностей, которые согласуются с личными ценностями и убеждениями сотрудника. \n2) Предоставляйте сотруднику возможность участия в проектах и корпоративных мероприятиях, направленных на помощь людям, развитие общества, сохранение экологии, заботе о планете и т.п.. \n3) Организуйте корпоративные мероприятия, имеющие общественно-полезную направленность, например: субботник, выезд в приют для животных, участие в благотворительном забеге и т.п. ',
        warning: '1) Необходимость выполнять задачи, которые не согласуются с внутренними принципами и убеждениями, может снижать готовность сотрудника с данным мотивационным профилем активно вкладывать в работу, поэтому старайтесь избегать участия сотрудника в проектах, идущих вразрез с гуманистическими ценностями.'
    },
    {
        id: 'influence', title: 'Влияние',
        description: 'Сотруднику важно иметь возможность влиять на процессы, результат деятельности команды, чувствовать свою значимость в организации.',
        recommendation: '1) Для повышения мотивации предоставьте сотруднику возможность контролировать других людей, а также расширьте зону его ответственности. \n2) Обратите внимание на название занимаемой сотрудником позиции - название должности, отражающее статус и важность роли сотрудника в организации, может оказывать положительное влияние на рабочую мотивацию.',
        warning: '1) Не возлагайте на сотрудника с данным мотивационным профилем задачи, предполагающие низкую степень личной ответственности. \n2) Отсутствие возможности влиять на процессы и команду негативно сказывается на рабочей мотивации сотрудника. \n3) Не лишайте сотрудника внешних проявлений статуса и привилегий, соответствующих занимаемой им позиции - это может негативно сказываться на его удовлетворённости и рабочей мотивации.'
    },
    {
        id: 'communication', title: 'Общение с людьми',
        description: 'Сотруднику важно, чтобы процесс трудовой деятельности был связан с общением с людьми, предполагал командное решение рабочих задач.',
        recommendation: '1) Встраивайте в проекты сотрудника элемент взаимодействия с людьми - социальное взаимодействие необходимо сотруднику с высокими баллами по шкале "Общения с людьми", чтобы быть вовлеченным в свою и командную работу. \n2) Предоставьте сотруднику возможность взаимодействия с другими людьми на работе. Повышению мотивации может способствовать открытый офис с развитой культурой общения. \n3) Используйте потребность сотрудника в социальной активности и общении с людьми для того, чтобы помочь построить хорошие взаимоотношения там, где это необходимо - как внутри организации, так и при взаимодействии с клиентами. Развитые социальные навыки сотрудника помогут разрушить границы между отдельными группами в организации и свести заинтересованные стороны вместе.',
        warning: '1) Не забывайте, что рабочая среда, в которой задачи решаются индивидуально, снижает мотивацию этого сотрудника. Сотрудник с данным мотивационным профилем лучше работает в рамках социальной культуры рабочей среды и в условиях командного решения задач. \n2) Не ограничивайте сотрудника в возможности взаимодействия с другими людьми. Так, удалённый формат работы для сотрудника с таким мотивационным профилем будет негативно сказываться на рабочей мотивации, в отличие от работы в офисе. \n3) Из обратной связи следует исключить монолог, основывающийся на индивидуальных показателях и целях сотрудника, напротив: стоит говорить о социальном аспекте, совместных целях, включающих коммуникацию и тесную связь с коллегами. Такой формат обратной связи больше увлечет сотрудника и будет в большей степени способствовать профессиональному росту.'
    },
    {
        id: 'acceptance', title: 'Признание',
        description: 'Сотруднику важно получать публичное признание: позитивную обратную связь, похвалу, благодарность.',
        recommendation: '1) Отмечайте заслуги сотрудника публично - такого рода признание особенно вдохновляет его на новые достижения. \n2) Предоставляйте сотруднику позитивную обратную связь по результатам проделанной работы. \n3) На внутрикорпоративных мероприятиях отводите время для высказывания слов благодарности отличившимся сотрудникам и рабочим группам - это будет мотивировать сотрудника на достижения и связанное с ними получение публичного признания. ',
        warning: '1) Не пренебрегайте публичными высказываниями благодарности и похвалы - отсутствие признания является для сотрудника с описываемым мотивационным профилем фактором, снижающим рабочую мотивацию. \n2) Старайтесь избегать ситуаций, в которых результаты работы сотрудника остаются незамеченными руководством - отсутствие позитивной обратной связи приводит к нарастанию недовольства сотрудника и снижению готовности прилагать дополнительные усилия.'
    },
    {
        id: 'stability', title: 'Стабильность и комфорт',
        description: 'Для сотрудника важно ощущать стабильность своего положения, иметь уверенность в завтрашнем дне. ',
        recommendation: '1) Держите сотрудника в курсе положения организации на рынке и общей ситуации в отрасли. Подчёркивайте стабильность компании и личной роли сотрудника в ней, чтобы укрепить ощущение надёжности и развеять возможные опасения. \n2) Обеспечьте понимание сотрудником своей роли и возможных путей развития, так как осознание стабильности своего положения способствует повышению рабочей мотивации. \n3) При предоставлении обратной связи говорите не только о прогрессе сотрудника, но и о развитии организации в целом. Это поможет сотруднику убедиться в стабильности своего будущего в компании.',
        warning: '1) Не стоит помещать сотрудника в постоянно изменяющуюся рабочую среду, так как это требует постоянной адаптации и снижает эффективность работы сотрудника с данным мотивационным профилем. \n2) Не думайте, что для повышения интереса к работе сотруднику требуются новые и разнообразные задачи. Напротив, ему более комфортно в условиях выполнения своих привычных функций. \n3) Не давайте сотруднику работать с незнакомыми задачами - это приведёт к повышению тревоги и негативно скажется на производительности. В большей степени сотрудника с данным мотивационным профилем мотивирует выполнение рутинных и привычных задач.'
    },
    {
        id: 'supervisor', title: 'Отношения с руководителем',
        description: 'Для сотрудника важно иметь близкие и доверительные отношения с руководителем. В руководителе сотрудник с данным мотивационным профилем видит профессиональный пример и ориентируется на него в своём развитии.',
        recommendation: '1) Организуйте личные встречи для предоставления индивидуальной обратной связи и ответа на возникшие у сотрудника профессиональные вопросы - это будет способствовать повышению открытости ваших взаимоотношений и, тем самым, рабочей мотивации сотрудника. \n2) Делитесь с сотрудником своим опытом и профессиональными знаниями. Образ руководителя является для сотрудника с описываемым мотивационным профилем ориентиром, к которому он стремится в своём развитии, поэтому личный опыт руководителя будет особенно полезен для повышения мотивации сотрудника и его профессионального развития. \n3) Находитесь внутри коллектива и будьте открыты к общению с сотрудником, когда это возможно. Доступность руководителя является важным фактором, способствующим повышению мотивации сотрудника.',
        warning: '1) Не пренебрегайте диалогом о достижениях сотрудника, если он выполняет работу на высоком уровне, делитесь своим опытом и экспертизой для лучшего использования способностей сотрудника и его профессионального роста. \n2) Не используйте персональную критику при предоставлении обратной связи, так как сотрудник с данным мотивационным профилем, вероятно, чувствует личную связь с Вами и видит в Вас больше, чем просто своего руководителя. В рамках обратной связи четко разграничивайте то, что относится к профессиональным умениям и личным качествам сотрудника. \n3) Не отстраняйтесь от частого взаимодействия с сотрудником, которое может показаться незначимым. Игнорирование потребности сотрудника в общении с руководителем может привести к развитию чувства непричастности и снижению ощущения собственной значимости.'
    },
];

export const DRIVER_PLUS_RESULT_IMG = {
    1: `<svg width="322" height="25" viewBox="0 0 322 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="1" y1="11.5" x2="318" y2="11.5" stroke="#929497"/>
        <circle cx="12.5" cy="12.5" r="12.5" fill="#2B3D4F"/>
        <circle cx="12.5" cy="12.5" r="9" fill="#2B3D4F" stroke="white"/>
        <path d="M12.1513 17H13.9843V7.9H12.3593L10.2273 9.044V10.877L12.1513 9.902V17Z" fill="white"/>
        <circle cx="45.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M42.1972 17H48.6712V15.362H44.8752L46.4352 13.932C47.8392 12.645 48.3592 11.8 48.3592 10.5C48.3592 8.771 46.8122 7.718 45.1352 7.718C44.0432 7.718 43.1462 8.069 42.4832 8.589V10.513C43.1722 9.902 43.9392 9.473 44.9402 9.473C45.8762 9.473 46.5002 9.967 46.5002 10.747C46.5002 11.553 46.0452 12.138 44.8752 13.204L42.1972 15.635V17Z" fill="#2B3D4F"/>
        <circle cx="78.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M78.0245 17.182C80.0395 17.182 81.6905 15.973 81.6905 13.984C81.6905 12.255 80.3385 11.397 79.2335 11.189L81.2875 9.304V7.9H75.5415V9.512H78.8955L76.7245 11.514V12.749C77.0235 12.632 77.4265 12.502 78.0115 12.502C79.1425 12.502 79.8185 13.126 79.8185 13.984C79.8185 14.972 78.9345 15.505 77.9205 15.505C76.7505 15.505 75.8405 15.076 75.2555 14.673V16.454C75.8925 16.857 76.7635 17.182 78.0245 17.182Z" fill="#2B3D4F"/>
        <circle cx="111.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M112.198 17H114.018V14.725H115.357V13.217H114.018V7.9H112.172L107.635 13.373V14.725H112.198V17ZM109.702 13.217L112.198 10.253V13.217H109.702Z" fill="#2B3D4F"/>
        <circle cx="144.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M143.883 17.182C145.937 17.182 147.692 16.051 147.692 13.984C147.692 12.164 146.236 10.968 144.26 10.968C143.948 10.968 143.636 11.007 143.389 11.059L143.584 9.512H147.068V7.9H142.206L141.608 12.866C142.349 12.606 143.051 12.476 143.792 12.476C145.066 12.476 145.82 13.1 145.82 13.984C145.82 15.011 144.884 15.505 143.805 15.505C142.713 15.505 141.868 15.141 141.296 14.777V16.532C141.933 16.896 142.739 17.182 143.883 17.182Z" fill="#2B3D4F"/>
        <circle cx="177.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M177.697 17.182C179.556 17.182 180.999 15.895 180.999 13.971C180.999 12.138 179.699 11.033 178.087 11.033C177.164 11.033 176.41 11.358 175.968 11.878C176.059 10.539 176.891 9.408 178.451 9.408C179.114 9.408 179.725 9.603 180.297 9.993V8.303C179.673 7.887 178.958 7.718 178.243 7.718C175.422 7.718 174.07 10.227 174.07 12.918C174.07 15.544 175.396 17.182 177.697 17.182ZM177.58 15.57C176.605 15.57 175.968 14.868 175.916 13.633C176.176 12.97 176.865 12.489 177.58 12.489C178.49 12.489 179.14 13.126 179.14 14.01C179.14 14.933 178.49 15.57 177.58 15.57Z" fill="#2B3D4F"/>
        <circle cx="210.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M207.852 17H209.841L213.689 9.343V7.9H207.267V9.577H211.57L207.852 17Z" fill="#2B3D4F"/>
        <circle cx="243.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M245.445 12.151C246.225 11.787 246.719 11.098 246.719 10.24C246.719 8.732 245.328 7.718 243.508 7.718C241.675 7.718 240.284 8.732 240.284 10.24C240.284 11.098 240.778 11.787 241.558 12.151C240.583 12.502 239.868 13.347 239.868 14.413C239.868 16.207 241.558 17.182 243.508 17.182C245.445 17.182 247.135 16.207 247.135 14.413C247.135 13.347 246.433 12.502 245.445 12.151ZM243.508 9.278C244.301 9.278 244.912 9.746 244.912 10.422C244.912 11.085 244.301 11.553 243.508 11.553C242.715 11.553 242.091 11.085 242.091 10.422C242.091 9.746 242.715 9.278 243.508 9.278ZM243.508 15.609C242.533 15.609 241.805 15.037 241.805 14.296C241.805 13.529 242.533 12.97 243.508 12.97C244.483 12.97 245.198 13.529 245.198 14.296C245.198 15.037 244.483 15.609 243.508 15.609Z" fill="#2B3D4F"/>
        <circle cx="276.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M276.307 7.718C274.448 7.718 273.005 9.005 273.005 10.929C273.005 12.762 274.305 13.867 275.917 13.867C276.84 13.867 277.594 13.542 278.036 13.022C277.945 14.361 277.113 15.492 275.553 15.492C274.89 15.492 274.279 15.297 273.707 14.907V16.597C274.331 17.013 275.046 17.182 275.761 17.182C278.582 17.182 279.934 14.673 279.934 11.982C279.934 9.356 278.608 7.718 276.307 7.718ZM276.424 9.33C277.399 9.33 278.036 10.019 278.088 11.254C277.828 11.917 277.139 12.411 276.424 12.411C275.514 12.411 274.864 11.774 274.864 10.89C274.864 9.967 275.514 9.33 276.424 9.33Z" fill="#2B3D4F"/>
        <circle cx="309.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M304.568 17H306.401V7.9H304.776L302.644 9.044V10.877L304.568 9.902V17ZM312.039 17.182C314.535 17.182 315.952 14.92 315.952 12.45C315.952 9.98 314.535 7.718 312.039 7.718C309.53 7.718 308.113 9.98 308.113 12.45C308.113 14.92 309.53 17.182 312.039 17.182ZM312.039 15.427C310.739 15.427 309.985 14.036 309.985 12.45C309.985 10.864 310.739 9.473 312.039 9.473C313.339 9.473 314.08 10.864 314.08 12.45C314.08 14.036 313.339 15.427 312.039 15.427Z" fill="#2B3D4F"/>
    </svg>
    `,
    2: `<svg width="322" height="25" viewBox="0 0 322 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="1" y1="11.5" x2="318" y2="11.5" stroke="#929497"/>
        <circle cx="12.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M12.1513 17H13.9843V7.9H12.3593L10.2273 9.044V10.877L12.1513 9.902V17Z" fill="#2B3D4F"/>
        <circle cx="45.5" cy="12.5" r="12.5" fill="#2B3D4F"/>
        <circle cx="45.5" cy="12.5" r="9" fill="#2B3D4F" stroke="white"/>
        <path d="M42.1972 17H48.6712V15.362H44.8752L46.4352 13.932C47.8392 12.645 48.3592 11.8 48.3592 10.5C48.3592 8.771 46.8122 7.718 45.1352 7.718C44.0432 7.718 43.1462 8.069 42.4832 8.589V10.513C43.1722 9.902 43.9392 9.473 44.9402 9.473C45.8762 9.473 46.5002 9.967 46.5002 10.747C46.5002 11.553 46.0452 12.138 44.8752 13.204L42.1972 15.635V17Z" fill="white"/>
        <circle cx="78.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M78.0245 17.182C80.0395 17.182 81.6905 15.973 81.6905 13.984C81.6905 12.255 80.3385 11.397 79.2335 11.189L81.2875 9.304V7.9H75.5415V9.512H78.8955L76.7245 11.514V12.749C77.0235 12.632 77.4265 12.502 78.0115 12.502C79.1425 12.502 79.8185 13.126 79.8185 13.984C79.8185 14.972 78.9345 15.505 77.9205 15.505C76.7505 15.505 75.8405 15.076 75.2555 14.673V16.454C75.8925 16.857 76.7635 17.182 78.0245 17.182Z" fill="#2B3D4F"/>
        <circle cx="111.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M112.198 17H114.018V14.725H115.357V13.217H114.018V7.9H112.172L107.635 13.373V14.725H112.198V17ZM109.702 13.217L112.198 10.253V13.217H109.702Z" fill="#2B3D4F"/>
        <circle cx="144.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M143.883 17.182C145.937 17.182 147.692 16.051 147.692 13.984C147.692 12.164 146.236 10.968 144.26 10.968C143.948 10.968 143.636 11.007 143.389 11.059L143.584 9.512H147.068V7.9H142.206L141.608 12.866C142.349 12.606 143.051 12.476 143.792 12.476C145.066 12.476 145.82 13.1 145.82 13.984C145.82 15.011 144.884 15.505 143.805 15.505C142.713 15.505 141.868 15.141 141.296 14.777V16.532C141.933 16.896 142.739 17.182 143.883 17.182Z" fill="#2B3D4F"/>
        <circle cx="177.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M177.697 17.182C179.556 17.182 180.999 15.895 180.999 13.971C180.999 12.138 179.699 11.033 178.087 11.033C177.164 11.033 176.41 11.358 175.968 11.878C176.059 10.539 176.891 9.408 178.451 9.408C179.114 9.408 179.725 9.603 180.297 9.993V8.303C179.673 7.887 178.958 7.718 178.243 7.718C175.422 7.718 174.07 10.227 174.07 12.918C174.07 15.544 175.396 17.182 177.697 17.182ZM177.58 15.57C176.605 15.57 175.968 14.868 175.916 13.633C176.176 12.97 176.865 12.489 177.58 12.489C178.49 12.489 179.14 13.126 179.14 14.01C179.14 14.933 178.49 15.57 177.58 15.57Z" fill="#2B3D4F"/>
        <circle cx="210.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M207.852 17H209.841L213.689 9.343V7.9H207.267V9.577H211.57L207.852 17Z" fill="#2B3D4F"/>
        <circle cx="243.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M245.445 12.151C246.225 11.787 246.719 11.098 246.719 10.24C246.719 8.732 245.328 7.718 243.508 7.718C241.675 7.718 240.284 8.732 240.284 10.24C240.284 11.098 240.778 11.787 241.558 12.151C240.583 12.502 239.868 13.347 239.868 14.413C239.868 16.207 241.558 17.182 243.508 17.182C245.445 17.182 247.135 16.207 247.135 14.413C247.135 13.347 246.433 12.502 245.445 12.151ZM243.508 9.278C244.301 9.278 244.912 9.746 244.912 10.422C244.912 11.085 244.301 11.553 243.508 11.553C242.715 11.553 242.091 11.085 242.091 10.422C242.091 9.746 242.715 9.278 243.508 9.278ZM243.508 15.609C242.533 15.609 241.805 15.037 241.805 14.296C241.805 13.529 242.533 12.97 243.508 12.97C244.483 12.97 245.198 13.529 245.198 14.296C245.198 15.037 244.483 15.609 243.508 15.609Z" fill="#2B3D4F"/>
        <circle cx="276.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M276.307 7.718C274.448 7.718 273.005 9.005 273.005 10.929C273.005 12.762 274.305 13.867 275.917 13.867C276.84 13.867 277.594 13.542 278.036 13.022C277.945 14.361 277.113 15.492 275.553 15.492C274.89 15.492 274.279 15.297 273.707 14.907V16.597C274.331 17.013 275.046 17.182 275.761 17.182C278.582 17.182 279.934 14.673 279.934 11.982C279.934 9.356 278.608 7.718 276.307 7.718ZM276.424 9.33C277.399 9.33 278.036 10.019 278.088 11.254C277.828 11.917 277.139 12.411 276.424 12.411C275.514 12.411 274.864 11.774 274.864 10.89C274.864 9.967 275.514 9.33 276.424 9.33Z" fill="#2B3D4F"/>
        <circle cx="309.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M304.568 17H306.401V7.9H304.776L302.644 9.044V10.877L304.568 9.902V17ZM312.039 17.182C314.535 17.182 315.952 14.92 315.952 12.45C315.952 9.98 314.535 7.718 312.039 7.718C309.53 7.718 308.113 9.98 308.113 12.45C308.113 14.92 309.53 17.182 312.039 17.182ZM312.039 15.427C310.739 15.427 309.985 14.036 309.985 12.45C309.985 10.864 310.739 9.473 312.039 9.473C313.339 9.473 314.08 10.864 314.08 12.45C314.08 14.036 313.339 15.427 312.039 15.427Z" fill="#2B3D4F"/>
    </svg>
    `,
    3: `<svg width="322" height="25" viewBox="0 0 322 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="1" y1="11.5" x2="318" y2="11.5" stroke="#929497"/>
        <circle cx="12.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M12.1513 17H13.9843V7.9H12.3593L10.2273 9.044V10.877L12.1513 9.902V17Z" fill="#2B3D4F"/>
        <circle cx="45.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M42.1972 17H48.6712V15.362H44.8752L46.4352 13.932C47.8392 12.645 48.3592 11.8 48.3592 10.5C48.3592 8.771 46.8122 7.718 45.1352 7.718C44.0432 7.718 43.1462 8.069 42.4832 8.589V10.513C43.1722 9.902 43.9392 9.473 44.9402 9.473C45.8762 9.473 46.5002 9.967 46.5002 10.747C46.5002 11.553 46.0452 12.138 44.8752 13.204L42.1972 15.635V17Z" fill="#2B3D4F"/>
        <circle cx="78.5" cy="12.5" r="12.5" fill="#2B3D4F"/>
        <circle cx="78.5" cy="12.5" r="9" fill="#2B3D4F" stroke="white"/>
        <path d="M78.0245 17.182C80.0395 17.182 81.6905 15.973 81.6905 13.984C81.6905 12.255 80.3385 11.397 79.2335 11.189L81.2875 9.304V7.9H75.5415V9.512H78.8955L76.7245 11.514V12.749C77.0235 12.632 77.4265 12.502 78.0115 12.502C79.1425 12.502 79.8185 13.126 79.8185 13.984C79.8185 14.972 78.9345 15.505 77.9205 15.505C76.7505 15.505 75.8405 15.076 75.2555 14.673V16.454C75.8925 16.857 76.7635 17.182 78.0245 17.182Z" fill="white"/>
        <circle cx="111.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M112.198 17H114.018V14.725H115.357V13.217H114.018V7.9H112.172L107.635 13.373V14.725H112.198V17ZM109.702 13.217L112.198 10.253V13.217H109.702Z" fill="#2B3D4F"/>
        <circle cx="144.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M143.883 17.182C145.937 17.182 147.692 16.051 147.692 13.984C147.692 12.164 146.236 10.968 144.26 10.968C143.948 10.968 143.636 11.007 143.389 11.059L143.584 9.512H147.068V7.9H142.206L141.608 12.866C142.349 12.606 143.051 12.476 143.792 12.476C145.066 12.476 145.82 13.1 145.82 13.984C145.82 15.011 144.884 15.505 143.805 15.505C142.713 15.505 141.868 15.141 141.296 14.777V16.532C141.933 16.896 142.739 17.182 143.883 17.182Z" fill="#2B3D4F"/>
        <circle cx="177.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M177.697 17.182C179.556 17.182 180.999 15.895 180.999 13.971C180.999 12.138 179.699 11.033 178.087 11.033C177.164 11.033 176.41 11.358 175.968 11.878C176.059 10.539 176.891 9.408 178.451 9.408C179.114 9.408 179.725 9.603 180.297 9.993V8.303C179.673 7.887 178.958 7.718 178.243 7.718C175.422 7.718 174.07 10.227 174.07 12.918C174.07 15.544 175.396 17.182 177.697 17.182ZM177.58 15.57C176.605 15.57 175.968 14.868 175.916 13.633C176.176 12.97 176.865 12.489 177.58 12.489C178.49 12.489 179.14 13.126 179.14 14.01C179.14 14.933 178.49 15.57 177.58 15.57Z" fill="#2B3D4F"/>
        <circle cx="210.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M207.852 17H209.841L213.689 9.343V7.9H207.267V9.577H211.57L207.852 17Z" fill="#2B3D4F"/>
        <circle cx="243.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M245.445 12.151C246.225 11.787 246.719 11.098 246.719 10.24C246.719 8.732 245.328 7.718 243.508 7.718C241.675 7.718 240.284 8.732 240.284 10.24C240.284 11.098 240.778 11.787 241.558 12.151C240.583 12.502 239.868 13.347 239.868 14.413C239.868 16.207 241.558 17.182 243.508 17.182C245.445 17.182 247.135 16.207 247.135 14.413C247.135 13.347 246.433 12.502 245.445 12.151ZM243.508 9.278C244.301 9.278 244.912 9.746 244.912 10.422C244.912 11.085 244.301 11.553 243.508 11.553C242.715 11.553 242.091 11.085 242.091 10.422C242.091 9.746 242.715 9.278 243.508 9.278ZM243.508 15.609C242.533 15.609 241.805 15.037 241.805 14.296C241.805 13.529 242.533 12.97 243.508 12.97C244.483 12.97 245.198 13.529 245.198 14.296C245.198 15.037 244.483 15.609 243.508 15.609Z" fill="#2B3D4F"/>
        <circle cx="276.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M276.307 7.718C274.448 7.718 273.005 9.005 273.005 10.929C273.005 12.762 274.305 13.867 275.917 13.867C276.84 13.867 277.594 13.542 278.036 13.022C277.945 14.361 277.113 15.492 275.553 15.492C274.89 15.492 274.279 15.297 273.707 14.907V16.597C274.331 17.013 275.046 17.182 275.761 17.182C278.582 17.182 279.934 14.673 279.934 11.982C279.934 9.356 278.608 7.718 276.307 7.718ZM276.424 9.33C277.399 9.33 278.036 10.019 278.088 11.254C277.828 11.917 277.139 12.411 276.424 12.411C275.514 12.411 274.864 11.774 274.864 10.89C274.864 9.967 275.514 9.33 276.424 9.33Z" fill="#2B3D4F"/>
        <circle cx="309.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M304.568 17H306.401V7.9H304.776L302.644 9.044V10.877L304.568 9.902V17ZM312.039 17.182C314.535 17.182 315.952 14.92 315.952 12.45C315.952 9.98 314.535 7.718 312.039 7.718C309.53 7.718 308.113 9.98 308.113 12.45C308.113 14.92 309.53 17.182 312.039 17.182ZM312.039 15.427C310.739 15.427 309.985 14.036 309.985 12.45C309.985 10.864 310.739 9.473 312.039 9.473C313.339 9.473 314.08 10.864 314.08 12.45C314.08 14.036 313.339 15.427 312.039 15.427Z" fill="#2B3D4F"/>
    </svg>
    `,
    4: `<svg width="322" height="25" viewBox="0 0 322 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="1" y1="11.5" x2="318" y2="11.5" stroke="#929497"/>
        <circle cx="12.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M12.1513 17H13.9843V7.9H12.3593L10.2273 9.044V10.877L12.1513 9.902V17Z" fill="#2B3D4F"/>
        <circle cx="45.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M42.1972 17H48.6712V15.362H44.8752L46.4352 13.932C47.8392 12.645 48.3592 11.8 48.3592 10.5C48.3592 8.771 46.8122 7.718 45.1352 7.718C44.0432 7.718 43.1462 8.069 42.4832 8.589V10.513C43.1722 9.902 43.9392 9.473 44.9402 9.473C45.8762 9.473 46.5002 9.967 46.5002 10.747C46.5002 11.553 46.0452 12.138 44.8752 13.204L42.1972 15.635V17Z" fill="#2B3D4F"/>
        <circle cx="78.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M78.0245 17.182C80.0395 17.182 81.6905 15.973 81.6905 13.984C81.6905 12.255 80.3385 11.397 79.2335 11.189L81.2875 9.304V7.9H75.5415V9.512H78.8955L76.7245 11.514V12.749C77.0235 12.632 77.4265 12.502 78.0115 12.502C79.1425 12.502 79.8185 13.126 79.8185 13.984C79.8185 14.972 78.9345 15.505 77.9205 15.505C76.7505 15.505 75.8405 15.076 75.2555 14.673V16.454C75.8925 16.857 76.7635 17.182 78.0245 17.182Z" fill="#2B3D4F"/>
        <circle cx="111.5" cy="12.5" r="12.5" fill="#2B3D4F"/>
        <circle cx="111.5" cy="12.5" r="9" fill="#2B3D4F" stroke="white"/>
        <path d="M112.198 17H114.018V14.725H115.357V13.217H114.018V7.9H112.172L107.635 13.373V14.725H112.198V17ZM109.702 13.217L112.198 10.253V13.217H109.702Z" fill="white"/>
        <circle cx="144.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M143.883 17.182C145.937 17.182 147.692 16.051 147.692 13.984C147.692 12.164 146.236 10.968 144.26 10.968C143.948 10.968 143.636 11.007 143.389 11.059L143.584 9.512H147.068V7.9H142.206L141.608 12.866C142.349 12.606 143.051 12.476 143.792 12.476C145.066 12.476 145.82 13.1 145.82 13.984C145.82 15.011 144.884 15.505 143.805 15.505C142.713 15.505 141.868 15.141 141.296 14.777V16.532C141.933 16.896 142.739 17.182 143.883 17.182Z" fill="#2B3D4F"/>
        <circle cx="177.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M177.697 17.182C179.556 17.182 180.999 15.895 180.999 13.971C180.999 12.138 179.699 11.033 178.087 11.033C177.164 11.033 176.41 11.358 175.968 11.878C176.059 10.539 176.891 9.408 178.451 9.408C179.114 9.408 179.725 9.603 180.297 9.993V8.303C179.673 7.887 178.958 7.718 178.243 7.718C175.422 7.718 174.07 10.227 174.07 12.918C174.07 15.544 175.396 17.182 177.697 17.182ZM177.58 15.57C176.605 15.57 175.968 14.868 175.916 13.633C176.176 12.97 176.865 12.489 177.58 12.489C178.49 12.489 179.14 13.126 179.14 14.01C179.14 14.933 178.49 15.57 177.58 15.57Z" fill="#2B3D4F"/>
        <circle cx="210.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M207.852 17H209.841L213.689 9.343V7.9H207.267V9.577H211.57L207.852 17Z" fill="#2B3D4F"/>
        <circle cx="243.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M245.445 12.151C246.225 11.787 246.719 11.098 246.719 10.24C246.719 8.732 245.328 7.718 243.508 7.718C241.675 7.718 240.284 8.732 240.284 10.24C240.284 11.098 240.778 11.787 241.558 12.151C240.583 12.502 239.868 13.347 239.868 14.413C239.868 16.207 241.558 17.182 243.508 17.182C245.445 17.182 247.135 16.207 247.135 14.413C247.135 13.347 246.433 12.502 245.445 12.151ZM243.508 9.278C244.301 9.278 244.912 9.746 244.912 10.422C244.912 11.085 244.301 11.553 243.508 11.553C242.715 11.553 242.091 11.085 242.091 10.422C242.091 9.746 242.715 9.278 243.508 9.278ZM243.508 15.609C242.533 15.609 241.805 15.037 241.805 14.296C241.805 13.529 242.533 12.97 243.508 12.97C244.483 12.97 245.198 13.529 245.198 14.296C245.198 15.037 244.483 15.609 243.508 15.609Z" fill="#2B3D4F"/>
        <circle cx="276.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M276.307 7.718C274.448 7.718 273.005 9.005 273.005 10.929C273.005 12.762 274.305 13.867 275.917 13.867C276.84 13.867 277.594 13.542 278.036 13.022C277.945 14.361 277.113 15.492 275.553 15.492C274.89 15.492 274.279 15.297 273.707 14.907V16.597C274.331 17.013 275.046 17.182 275.761 17.182C278.582 17.182 279.934 14.673 279.934 11.982C279.934 9.356 278.608 7.718 276.307 7.718ZM276.424 9.33C277.399 9.33 278.036 10.019 278.088 11.254C277.828 11.917 277.139 12.411 276.424 12.411C275.514 12.411 274.864 11.774 274.864 10.89C274.864 9.967 275.514 9.33 276.424 9.33Z" fill="#2B3D4F"/>
        <circle cx="309.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M304.568 17H306.401V7.9H304.776L302.644 9.044V10.877L304.568 9.902V17ZM312.039 17.182C314.535 17.182 315.952 14.92 315.952 12.45C315.952 9.98 314.535 7.718 312.039 7.718C309.53 7.718 308.113 9.98 308.113 12.45C308.113 14.92 309.53 17.182 312.039 17.182ZM312.039 15.427C310.739 15.427 309.985 14.036 309.985 12.45C309.985 10.864 310.739 9.473 312.039 9.473C313.339 9.473 314.08 10.864 314.08 12.45C314.08 14.036 313.339 15.427 312.039 15.427Z" fill="#2B3D4F"/>
    </svg>
    `,
    5: `<svg width="322" height="25" viewBox="0 0 322 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="1" y1="11.5" x2="318" y2="11.5" stroke="#929497"/>
        <circle cx="12.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M12.1513 17H13.9843V7.9H12.3593L10.2273 9.044V10.877L12.1513 9.902V17Z" fill="#2B3D4F"/>
        <circle cx="45.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M42.1972 17H48.6712V15.362H44.8752L46.4352 13.932C47.8392 12.645 48.3592 11.8 48.3592 10.5C48.3592 8.771 46.8122 7.718 45.1352 7.718C44.0432 7.718 43.1462 8.069 42.4832 8.589V10.513C43.1722 9.902 43.9392 9.473 44.9402 9.473C45.8762 9.473 46.5002 9.967 46.5002 10.747C46.5002 11.553 46.0452 12.138 44.8752 13.204L42.1972 15.635V17Z" fill="#2B3D4F"/>
        <circle cx="78.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M78.0245 17.182C80.0395 17.182 81.6905 15.973 81.6905 13.984C81.6905 12.255 80.3385 11.397 79.2335 11.189L81.2875 9.304V7.9H75.5415V9.512H78.8955L76.7245 11.514V12.749C77.0235 12.632 77.4265 12.502 78.0115 12.502C79.1425 12.502 79.8185 13.126 79.8185 13.984C79.8185 14.972 78.9345 15.505 77.9205 15.505C76.7505 15.505 75.8405 15.076 75.2555 14.673V16.454C75.8925 16.857 76.7635 17.182 78.0245 17.182Z" fill="#2B3D4F"/>
        <circle cx="111.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M112.198 17H114.018V14.725H115.357V13.217H114.018V7.9H112.172L107.635 13.373V14.725H112.198V17ZM109.702 13.217L112.198 10.253V13.217H109.702Z" fill="#2B3D4F"/>
        <circle cx="144.5" cy="12.5" r="12.5" fill="#2B3D4F"/>
        <circle cx="144.5" cy="12.5" r="9" fill="#2B3D4F" stroke="white"/>
        <path d="M143.883 17.182C145.937 17.182 147.692 16.051 147.692 13.984C147.692 12.164 146.236 10.968 144.26 10.968C143.948 10.968 143.636 11.007 143.389 11.059L143.584 9.512H147.068V7.9H142.206L141.608 12.866C142.349 12.606 143.051 12.476 143.792 12.476C145.066 12.476 145.82 13.1 145.82 13.984C145.82 15.011 144.884 15.505 143.805 15.505C142.713 15.505 141.868 15.141 141.296 14.777V16.532C141.933 16.896 142.739 17.182 143.883 17.182Z" fill="white"/>
        <circle cx="177.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M177.697 17.182C179.556 17.182 180.999 15.895 180.999 13.971C180.999 12.138 179.699 11.033 178.087 11.033C177.164 11.033 176.41 11.358 175.968 11.878C176.059 10.539 176.891 9.408 178.451 9.408C179.114 9.408 179.725 9.603 180.297 9.993V8.303C179.673 7.887 178.958 7.718 178.243 7.718C175.422 7.718 174.07 10.227 174.07 12.918C174.07 15.544 175.396 17.182 177.697 17.182ZM177.58 15.57C176.605 15.57 175.968 14.868 175.916 13.633C176.176 12.97 176.865 12.489 177.58 12.489C178.49 12.489 179.14 13.126 179.14 14.01C179.14 14.933 178.49 15.57 177.58 15.57Z" fill="#2B3D4F"/>
        <circle cx="210.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M207.852 17H209.841L213.689 9.343V7.9H207.267V9.577H211.57L207.852 17Z" fill="#2B3D4F"/>
        <circle cx="243.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M245.445 12.151C246.225 11.787 246.719 11.098 246.719 10.24C246.719 8.732 245.328 7.718 243.508 7.718C241.675 7.718 240.284 8.732 240.284 10.24C240.284 11.098 240.778 11.787 241.558 12.151C240.583 12.502 239.868 13.347 239.868 14.413C239.868 16.207 241.558 17.182 243.508 17.182C245.445 17.182 247.135 16.207 247.135 14.413C247.135 13.347 246.433 12.502 245.445 12.151ZM243.508 9.278C244.301 9.278 244.912 9.746 244.912 10.422C244.912 11.085 244.301 11.553 243.508 11.553C242.715 11.553 242.091 11.085 242.091 10.422C242.091 9.746 242.715 9.278 243.508 9.278ZM243.508 15.609C242.533 15.609 241.805 15.037 241.805 14.296C241.805 13.529 242.533 12.97 243.508 12.97C244.483 12.97 245.198 13.529 245.198 14.296C245.198 15.037 244.483 15.609 243.508 15.609Z" fill="#2B3D4F"/>
        <circle cx="276.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M276.307 7.718C274.448 7.718 273.005 9.005 273.005 10.929C273.005 12.762 274.305 13.867 275.917 13.867C276.84 13.867 277.594 13.542 278.036 13.022C277.945 14.361 277.113 15.492 275.553 15.492C274.89 15.492 274.279 15.297 273.707 14.907V16.597C274.331 17.013 275.046 17.182 275.761 17.182C278.582 17.182 279.934 14.673 279.934 11.982C279.934 9.356 278.608 7.718 276.307 7.718ZM276.424 9.33C277.399 9.33 278.036 10.019 278.088 11.254C277.828 11.917 277.139 12.411 276.424 12.411C275.514 12.411 274.864 11.774 274.864 10.89C274.864 9.967 275.514 9.33 276.424 9.33Z" fill="#2B3D4F"/>
        <circle cx="309.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M304.568 17H306.401V7.9H304.776L302.644 9.044V10.877L304.568 9.902V17ZM312.039 17.182C314.535 17.182 315.952 14.92 315.952 12.45C315.952 9.98 314.535 7.718 312.039 7.718C309.53 7.718 308.113 9.98 308.113 12.45C308.113 14.92 309.53 17.182 312.039 17.182ZM312.039 15.427C310.739 15.427 309.985 14.036 309.985 12.45C309.985 10.864 310.739 9.473 312.039 9.473C313.339 9.473 314.08 10.864 314.08 12.45C314.08 14.036 313.339 15.427 312.039 15.427Z" fill="#2B3D4F"/>
    </svg>
    `,
    6: `<svg width="322" height="25" viewBox="0 0 322 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="1" y1="11.5" x2="318" y2="11.5" stroke="#929497"/>
        <circle cx="12.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M12.1513 17H13.9843V7.9H12.3593L10.2273 9.044V10.877L12.1513 9.902V17Z" fill="#2B3D4F"/>
        <circle cx="45.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M42.1972 17H48.6712V15.362H44.8752L46.4352 13.932C47.8392 12.645 48.3592 11.8 48.3592 10.5C48.3592 8.771 46.8122 7.718 45.1352 7.718C44.0432 7.718 43.1462 8.069 42.4832 8.589V10.513C43.1722 9.902 43.9392 9.473 44.9402 9.473C45.8762 9.473 46.5002 9.967 46.5002 10.747C46.5002 11.553 46.0452 12.138 44.8752 13.204L42.1972 15.635V17Z" fill="#2B3D4F"/>
        <circle cx="78.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M78.0245 17.182C80.0395 17.182 81.6905 15.973 81.6905 13.984C81.6905 12.255 80.3385 11.397 79.2335 11.189L81.2875 9.304V7.9H75.5415V9.512H78.8955L76.7245 11.514V12.749C77.0235 12.632 77.4265 12.502 78.0115 12.502C79.1425 12.502 79.8185 13.126 79.8185 13.984C79.8185 14.972 78.9345 15.505 77.9205 15.505C76.7505 15.505 75.8405 15.076 75.2555 14.673V16.454C75.8925 16.857 76.7635 17.182 78.0245 17.182Z" fill="#2B3D4F"/>
        <circle cx="111.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M112.198 17H114.018V14.725H115.357V13.217H114.018V7.9H112.172L107.635 13.373V14.725H112.198V17ZM109.702 13.217L112.198 10.253V13.217H109.702Z" fill="#2B3D4F"/>
        <circle cx="144.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M143.883 17.182C145.937 17.182 147.692 16.051 147.692 13.984C147.692 12.164 146.236 10.968 144.26 10.968C143.948 10.968 143.636 11.007 143.389 11.059L143.584 9.512H147.068V7.9H142.206L141.608 12.866C142.349 12.606 143.051 12.476 143.792 12.476C145.066 12.476 145.82 13.1 145.82 13.984C145.82 15.011 144.884 15.505 143.805 15.505C142.713 15.505 141.868 15.141 141.296 14.777V16.532C141.933 16.896 142.739 17.182 143.883 17.182Z" fill="#2B3D4F"/>
        <circle cx="177.5" cy="12.5" r="12.5" fill="#2B3D4F"/>
        <circle cx="177.5" cy="12.5" r="9" fill="#2B3D4F" stroke="white"/>
        <path d="M177.697 17.182C179.556 17.182 180.999 15.895 180.999 13.971C180.999 12.138 179.699 11.033 178.087 11.033C177.164 11.033 176.41 11.358 175.968 11.878C176.059 10.539 176.891 9.408 178.451 9.408C179.114 9.408 179.725 9.603 180.297 9.993V8.303C179.673 7.887 178.958 7.718 178.243 7.718C175.422 7.718 174.07 10.227 174.07 12.918C174.07 15.544 175.396 17.182 177.697 17.182ZM177.58 15.57C176.605 15.57 175.968 14.868 175.916 13.633C176.176 12.97 176.865 12.489 177.58 12.489C178.49 12.489 179.14 13.126 179.14 14.01C179.14 14.933 178.49 15.57 177.58 15.57Z" fill="white"/>
        <circle cx="210.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M207.852 17H209.841L213.689 9.343V7.9H207.267V9.577H211.57L207.852 17Z" fill="#2B3D4F"/>
        <circle cx="243.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M245.445 12.151C246.225 11.787 246.719 11.098 246.719 10.24C246.719 8.732 245.328 7.718 243.508 7.718C241.675 7.718 240.284 8.732 240.284 10.24C240.284 11.098 240.778 11.787 241.558 12.151C240.583 12.502 239.868 13.347 239.868 14.413C239.868 16.207 241.558 17.182 243.508 17.182C245.445 17.182 247.135 16.207 247.135 14.413C247.135 13.347 246.433 12.502 245.445 12.151ZM243.508 9.278C244.301 9.278 244.912 9.746 244.912 10.422C244.912 11.085 244.301 11.553 243.508 11.553C242.715 11.553 242.091 11.085 242.091 10.422C242.091 9.746 242.715 9.278 243.508 9.278ZM243.508 15.609C242.533 15.609 241.805 15.037 241.805 14.296C241.805 13.529 242.533 12.97 243.508 12.97C244.483 12.97 245.198 13.529 245.198 14.296C245.198 15.037 244.483 15.609 243.508 15.609Z" fill="#2B3D4F"/>
        <circle cx="276.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M276.307 7.718C274.448 7.718 273.005 9.005 273.005 10.929C273.005 12.762 274.305 13.867 275.917 13.867C276.84 13.867 277.594 13.542 278.036 13.022C277.945 14.361 277.113 15.492 275.553 15.492C274.89 15.492 274.279 15.297 273.707 14.907V16.597C274.331 17.013 275.046 17.182 275.761 17.182C278.582 17.182 279.934 14.673 279.934 11.982C279.934 9.356 278.608 7.718 276.307 7.718ZM276.424 9.33C277.399 9.33 278.036 10.019 278.088 11.254C277.828 11.917 277.139 12.411 276.424 12.411C275.514 12.411 274.864 11.774 274.864 10.89C274.864 9.967 275.514 9.33 276.424 9.33Z" fill="#2B3D4F"/>
        <circle cx="309.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M304.568 17H306.401V7.9H304.776L302.644 9.044V10.877L304.568 9.902V17ZM312.039 17.182C314.535 17.182 315.952 14.92 315.952 12.45C315.952 9.98 314.535 7.718 312.039 7.718C309.53 7.718 308.113 9.98 308.113 12.45C308.113 14.92 309.53 17.182 312.039 17.182ZM312.039 15.427C310.739 15.427 309.985 14.036 309.985 12.45C309.985 10.864 310.739 9.473 312.039 9.473C313.339 9.473 314.08 10.864 314.08 12.45C314.08 14.036 313.339 15.427 312.039 15.427Z" fill="#2B3D4F"/>
    </svg>
    `,
    7: `<svg width="322" height="25" viewBox="0 0 322 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="1" y1="11.5" x2="318" y2="11.5" stroke="#929497"/>
        <circle cx="12.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M12.1513 17H13.9843V7.9H12.3593L10.2273 9.044V10.877L12.1513 9.902V17Z" fill="#2B3D4F"/>
        <circle cx="45.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M42.1972 17H48.6712V15.362H44.8752L46.4352 13.932C47.8392 12.645 48.3592 11.8 48.3592 10.5C48.3592 8.771 46.8122 7.718 45.1352 7.718C44.0432 7.718 43.1462 8.069 42.4832 8.589V10.513C43.1722 9.902 43.9392 9.473 44.9402 9.473C45.8762 9.473 46.5002 9.967 46.5002 10.747C46.5002 11.553 46.0452 12.138 44.8752 13.204L42.1972 15.635V17Z" fill="#2B3D4F"/>
        <circle cx="78.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M78.0245 17.182C80.0395 17.182 81.6905 15.973 81.6905 13.984C81.6905 12.255 80.3385 11.397 79.2335 11.189L81.2875 9.304V7.9H75.5415V9.512H78.8955L76.7245 11.514V12.749C77.0235 12.632 77.4265 12.502 78.0115 12.502C79.1425 12.502 79.8185 13.126 79.8185 13.984C79.8185 14.972 78.9345 15.505 77.9205 15.505C76.7505 15.505 75.8405 15.076 75.2555 14.673V16.454C75.8925 16.857 76.7635 17.182 78.0245 17.182Z" fill="#2B3D4F"/>
        <circle cx="111.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M112.198 17H114.018V14.725H115.357V13.217H114.018V7.9H112.172L107.635 13.373V14.725H112.198V17ZM109.702 13.217L112.198 10.253V13.217H109.702Z" fill="#2B3D4F"/>
        <circle cx="144.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M143.883 17.182C145.937 17.182 147.692 16.051 147.692 13.984C147.692 12.164 146.236 10.968 144.26 10.968C143.948 10.968 143.636 11.007 143.389 11.059L143.584 9.512H147.068V7.9H142.206L141.608 12.866C142.349 12.606 143.051 12.476 143.792 12.476C145.066 12.476 145.82 13.1 145.82 13.984C145.82 15.011 144.884 15.505 143.805 15.505C142.713 15.505 141.868 15.141 141.296 14.777V16.532C141.933 16.896 142.739 17.182 143.883 17.182Z" fill="#2B3D4F"/>
        <circle cx="177.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M177.697 17.182C179.556 17.182 180.999 15.895 180.999 13.971C180.999 12.138 179.699 11.033 178.087 11.033C177.164 11.033 176.41 11.358 175.968 11.878C176.059 10.539 176.891 9.408 178.451 9.408C179.114 9.408 179.725 9.603 180.297 9.993V8.303C179.673 7.887 178.958 7.718 178.243 7.718C175.422 7.718 174.07 10.227 174.07 12.918C174.07 15.544 175.396 17.182 177.697 17.182ZM177.58 15.57C176.605 15.57 175.968 14.868 175.916 13.633C176.176 12.97 176.865 12.489 177.58 12.489C178.49 12.489 179.14 13.126 179.14 14.01C179.14 14.933 178.49 15.57 177.58 15.57Z" fill="#2B3D4F"/>
        <circle cx="210.5" cy="12.5" r="12.5" fill="#2B3D4F"/>
        <circle cx="210.5" cy="12.5" r="9" fill="#2B3D4F" stroke="white"/>
        <path d="M207.852 17H209.841L213.689 9.343V7.9H207.267V9.577H211.57L207.852 17Z" fill="white"/>
        <circle cx="243.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M245.445 12.151C246.225 11.787 246.719 11.098 246.719 10.24C246.719 8.732 245.328 7.718 243.508 7.718C241.675 7.718 240.284 8.732 240.284 10.24C240.284 11.098 240.778 11.787 241.558 12.151C240.583 12.502 239.868 13.347 239.868 14.413C239.868 16.207 241.558 17.182 243.508 17.182C245.445 17.182 247.135 16.207 247.135 14.413C247.135 13.347 246.433 12.502 245.445 12.151ZM243.508 9.278C244.301 9.278 244.912 9.746 244.912 10.422C244.912 11.085 244.301 11.553 243.508 11.553C242.715 11.553 242.091 11.085 242.091 10.422C242.091 9.746 242.715 9.278 243.508 9.278ZM243.508 15.609C242.533 15.609 241.805 15.037 241.805 14.296C241.805 13.529 242.533 12.97 243.508 12.97C244.483 12.97 245.198 13.529 245.198 14.296C245.198 15.037 244.483 15.609 243.508 15.609Z" fill="#2B3D4F"/>
        <circle cx="276.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M276.307 7.718C274.448 7.718 273.005 9.005 273.005 10.929C273.005 12.762 274.305 13.867 275.917 13.867C276.84 13.867 277.594 13.542 278.036 13.022C277.945 14.361 277.113 15.492 275.553 15.492C274.89 15.492 274.279 15.297 273.707 14.907V16.597C274.331 17.013 275.046 17.182 275.761 17.182C278.582 17.182 279.934 14.673 279.934 11.982C279.934 9.356 278.608 7.718 276.307 7.718ZM276.424 9.33C277.399 9.33 278.036 10.019 278.088 11.254C277.828 11.917 277.139 12.411 276.424 12.411C275.514 12.411 274.864 11.774 274.864 10.89C274.864 9.967 275.514 9.33 276.424 9.33Z" fill="#2B3D4F"/>
        <circle cx="309.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M304.568 17H306.401V7.9H304.776L302.644 9.044V10.877L304.568 9.902V17ZM312.039 17.182C314.535 17.182 315.952 14.92 315.952 12.45C315.952 9.98 314.535 7.718 312.039 7.718C309.53 7.718 308.113 9.98 308.113 12.45C308.113 14.92 309.53 17.182 312.039 17.182ZM312.039 15.427C310.739 15.427 309.985 14.036 309.985 12.45C309.985 10.864 310.739 9.473 312.039 9.473C313.339 9.473 314.08 10.864 314.08 12.45C314.08 14.036 313.339 15.427 312.039 15.427Z" fill="#2B3D4F"/>
    </svg>
    `,
    8: `<svg width="322" height="25" viewBox="0 0 322 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="1" y1="11.5" x2="318" y2="11.5" stroke="#929497"/>
        <circle cx="12.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M12.1513 17H13.9843V7.9H12.3593L10.2273 9.044V10.877L12.1513 9.902V17Z" fill="#2B3D4F"/>
        <circle cx="45.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M42.1972 17H48.6712V15.362H44.8752L46.4352 13.932C47.8392 12.645 48.3592 11.8 48.3592 10.5C48.3592 8.771 46.8122 7.718 45.1352 7.718C44.0432 7.718 43.1462 8.069 42.4832 8.589V10.513C43.1722 9.902 43.9392 9.473 44.9402 9.473C45.8762 9.473 46.5002 9.967 46.5002 10.747C46.5002 11.553 46.0452 12.138 44.8752 13.204L42.1972 15.635V17Z" fill="#2B3D4F"/>
        <circle cx="78.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M78.0245 17.182C80.0395 17.182 81.6905 15.973 81.6905 13.984C81.6905 12.255 80.3385 11.397 79.2335 11.189L81.2875 9.304V7.9H75.5415V9.512H78.8955L76.7245 11.514V12.749C77.0235 12.632 77.4265 12.502 78.0115 12.502C79.1425 12.502 79.8185 13.126 79.8185 13.984C79.8185 14.972 78.9345 15.505 77.9205 15.505C76.7505 15.505 75.8405 15.076 75.2555 14.673V16.454C75.8925 16.857 76.7635 17.182 78.0245 17.182Z" fill="#2B3D4F"/>
        <circle cx="111.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M112.198 17H114.018V14.725H115.357V13.217H114.018V7.9H112.172L107.635 13.373V14.725H112.198V17ZM109.702 13.217L112.198 10.253V13.217H109.702Z" fill="#2B3D4F"/>
        <circle cx="144.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M143.883 17.182C145.937 17.182 147.692 16.051 147.692 13.984C147.692 12.164 146.236 10.968 144.26 10.968C143.948 10.968 143.636 11.007 143.389 11.059L143.584 9.512H147.068V7.9H142.206L141.608 12.866C142.349 12.606 143.051 12.476 143.792 12.476C145.066 12.476 145.82 13.1 145.82 13.984C145.82 15.011 144.884 15.505 143.805 15.505C142.713 15.505 141.868 15.141 141.296 14.777V16.532C141.933 16.896 142.739 17.182 143.883 17.182Z" fill="#2B3D4F"/>
        <circle cx="177.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M177.697 17.182C179.556 17.182 180.999 15.895 180.999 13.971C180.999 12.138 179.699 11.033 178.087 11.033C177.164 11.033 176.41 11.358 175.968 11.878C176.059 10.539 176.891 9.408 178.451 9.408C179.114 9.408 179.725 9.603 180.297 9.993V8.303C179.673 7.887 178.958 7.718 178.243 7.718C175.422 7.718 174.07 10.227 174.07 12.918C174.07 15.544 175.396 17.182 177.697 17.182ZM177.58 15.57C176.605 15.57 175.968 14.868 175.916 13.633C176.176 12.97 176.865 12.489 177.58 12.489C178.49 12.489 179.14 13.126 179.14 14.01C179.14 14.933 178.49 15.57 177.58 15.57Z" fill="#2B3D4F"/>
        <circle cx="210.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M207.852 17H209.841L213.689 9.343V7.9H207.267V9.577H211.57L207.852 17Z" fill="#2B3D4F"/>
        <circle cx="243.5" cy="12.5" r="12.5" fill="#2B3D4F"/>
        <circle cx="243.5" cy="12.5" r="9" fill="#2B3D4F" stroke="white"/>
        <path d="M245.445 12.151C246.225 11.787 246.719 11.098 246.719 10.24C246.719 8.732 245.328 7.718 243.508 7.718C241.675 7.718 240.284 8.732 240.284 10.24C240.284 11.098 240.778 11.787 241.558 12.151C240.583 12.502 239.868 13.347 239.868 14.413C239.868 16.207 241.558 17.182 243.508 17.182C245.445 17.182 247.135 16.207 247.135 14.413C247.135 13.347 246.433 12.502 245.445 12.151ZM243.508 9.278C244.301 9.278 244.912 9.746 244.912 10.422C244.912 11.085 244.301 11.553 243.508 11.553C242.715 11.553 242.091 11.085 242.091 10.422C242.091 9.746 242.715 9.278 243.508 9.278ZM243.508 15.609C242.533 15.609 241.805 15.037 241.805 14.296C241.805 13.529 242.533 12.97 243.508 12.97C244.483 12.97 245.198 13.529 245.198 14.296C245.198 15.037 244.483 15.609 243.508 15.609Z" fill="white"/>
        <circle cx="276.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M276.307 7.718C274.448 7.718 273.005 9.005 273.005 10.929C273.005 12.762 274.305 13.867 275.917 13.867C276.84 13.867 277.594 13.542 278.036 13.022C277.945 14.361 277.113 15.492 275.553 15.492C274.89 15.492 274.279 15.297 273.707 14.907V16.597C274.331 17.013 275.046 17.182 275.761 17.182C278.582 17.182 279.934 14.673 279.934 11.982C279.934 9.356 278.608 7.718 276.307 7.718ZM276.424 9.33C277.399 9.33 278.036 10.019 278.088 11.254C277.828 11.917 277.139 12.411 276.424 12.411C275.514 12.411 274.864 11.774 274.864 10.89C274.864 9.967 275.514 9.33 276.424 9.33Z" fill="#2B3D4F"/>
        <circle cx="309.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M304.568 17H306.401V7.9H304.776L302.644 9.044V10.877L304.568 9.902V17ZM312.039 17.182C314.535 17.182 315.952 14.92 315.952 12.45C315.952 9.98 314.535 7.718 312.039 7.718C309.53 7.718 308.113 9.98 308.113 12.45C308.113 14.92 309.53 17.182 312.039 17.182ZM312.039 15.427C310.739 15.427 309.985 14.036 309.985 12.45C309.985 10.864 310.739 9.473 312.039 9.473C313.339 9.473 314.08 10.864 314.08 12.45C314.08 14.036 313.339 15.427 312.039 15.427Z" fill="#2B3D4F"/>
    </svg>
    `,
    9: `<svg width="322" height="25" viewBox="0 0 322 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="1" y1="11.5" x2="318" y2="11.5" stroke="#929497"/>
        <circle cx="12.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M12.1513 17H13.9843V7.9H12.3593L10.2273 9.044V10.877L12.1513 9.902V17Z" fill="#2B3D4F"/>
        <circle cx="45.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M42.1972 17H48.6712V15.362H44.8752L46.4352 13.932C47.8392 12.645 48.3592 11.8 48.3592 10.5C48.3592 8.771 46.8122 7.718 45.1352 7.718C44.0432 7.718 43.1462 8.069 42.4832 8.589V10.513C43.1722 9.902 43.9392 9.473 44.9402 9.473C45.8762 9.473 46.5002 9.967 46.5002 10.747C46.5002 11.553 46.0452 12.138 44.8752 13.204L42.1972 15.635V17Z" fill="#2B3D4F"/>
        <circle cx="78.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M78.0245 17.182C80.0395 17.182 81.6905 15.973 81.6905 13.984C81.6905 12.255 80.3385 11.397 79.2335 11.189L81.2875 9.304V7.9H75.5415V9.512H78.8955L76.7245 11.514V12.749C77.0235 12.632 77.4265 12.502 78.0115 12.502C79.1425 12.502 79.8185 13.126 79.8185 13.984C79.8185 14.972 78.9345 15.505 77.9205 15.505C76.7505 15.505 75.8405 15.076 75.2555 14.673V16.454C75.8925 16.857 76.7635 17.182 78.0245 17.182Z" fill="#2B3D4F"/>
        <circle cx="111.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M112.198 17H114.018V14.725H115.357V13.217H114.018V7.9H112.172L107.635 13.373V14.725H112.198V17ZM109.702 13.217L112.198 10.253V13.217H109.702Z" fill="#2B3D4F"/>
        <circle cx="144.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M143.883 17.182C145.937 17.182 147.692 16.051 147.692 13.984C147.692 12.164 146.236 10.968 144.26 10.968C143.948 10.968 143.636 11.007 143.389 11.059L143.584 9.512H147.068V7.9H142.206L141.608 12.866C142.349 12.606 143.051 12.476 143.792 12.476C145.066 12.476 145.82 13.1 145.82 13.984C145.82 15.011 144.884 15.505 143.805 15.505C142.713 15.505 141.868 15.141 141.296 14.777V16.532C141.933 16.896 142.739 17.182 143.883 17.182Z" fill="#2B3D4F"/>
        <circle cx="177.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M177.697 17.182C179.556 17.182 180.999 15.895 180.999 13.971C180.999 12.138 179.699 11.033 178.087 11.033C177.164 11.033 176.41 11.358 175.968 11.878C176.059 10.539 176.891 9.408 178.451 9.408C179.114 9.408 179.725 9.603 180.297 9.993V8.303C179.673 7.887 178.958 7.718 178.243 7.718C175.422 7.718 174.07 10.227 174.07 12.918C174.07 15.544 175.396 17.182 177.697 17.182ZM177.58 15.57C176.605 15.57 175.968 14.868 175.916 13.633C176.176 12.97 176.865 12.489 177.58 12.489C178.49 12.489 179.14 13.126 179.14 14.01C179.14 14.933 178.49 15.57 177.58 15.57Z" fill="#2B3D4F"/>
        <circle cx="210.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M207.852 17H209.841L213.689 9.343V7.9H207.267V9.577H211.57L207.852 17Z" fill="#2B3D4F"/>
        <circle cx="243.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M245.445 12.151C246.225 11.787 246.719 11.098 246.719 10.24C246.719 8.732 245.328 7.718 243.508 7.718C241.675 7.718 240.284 8.732 240.284 10.24C240.284 11.098 240.778 11.787 241.558 12.151C240.583 12.502 239.868 13.347 239.868 14.413C239.868 16.207 241.558 17.182 243.508 17.182C245.445 17.182 247.135 16.207 247.135 14.413C247.135 13.347 246.433 12.502 245.445 12.151ZM243.508 9.278C244.301 9.278 244.912 9.746 244.912 10.422C244.912 11.085 244.301 11.553 243.508 11.553C242.715 11.553 242.091 11.085 242.091 10.422C242.091 9.746 242.715 9.278 243.508 9.278ZM243.508 15.609C242.533 15.609 241.805 15.037 241.805 14.296C241.805 13.529 242.533 12.97 243.508 12.97C244.483 12.97 245.198 13.529 245.198 14.296C245.198 15.037 244.483 15.609 243.508 15.609Z" fill="#2B3D4F"/>
        <circle cx="276.5" cy="12.5" r="12.5" fill="#2B3D4F"/>
        <circle cx="276.5" cy="12.5" r="9" fill="#2B3D4F" stroke="white"/>
        <path d="M276.307 7.718C274.448 7.718 273.005 9.005 273.005 10.929C273.005 12.762 274.305 13.867 275.917 13.867C276.84 13.867 277.594 13.542 278.036 13.022C277.945 14.361 277.113 15.492 275.553 15.492C274.89 15.492 274.279 15.297 273.707 14.907V16.597C274.331 17.013 275.046 17.182 275.761 17.182C278.582 17.182 279.934 14.673 279.934 11.982C279.934 9.356 278.608 7.718 276.307 7.718ZM276.424 9.33C277.399 9.33 278.036 10.019 278.088 11.254C277.828 11.917 277.139 12.411 276.424 12.411C275.514 12.411 274.864 11.774 274.864 10.89C274.864 9.967 275.514 9.33 276.424 9.33Z" fill="white"/>
        <circle cx="309.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M304.568 17H306.401V7.9H304.776L302.644 9.044V10.877L304.568 9.902V17ZM312.039 17.182C314.535 17.182 315.952 14.92 315.952 12.45C315.952 9.98 314.535 7.718 312.039 7.718C309.53 7.718 308.113 9.98 308.113 12.45C308.113 14.92 309.53 17.182 312.039 17.182ZM312.039 15.427C310.739 15.427 309.985 14.036 309.985 12.45C309.985 10.864 310.739 9.473 312.039 9.473C313.339 9.473 314.08 10.864 314.08 12.45C314.08 14.036 313.339 15.427 312.039 15.427Z" fill="#2B3D4F"/>
    </svg>
    `,
    10: `<svg width="322" height="25" viewBox="0 0 322 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="1" y1="11.5" x2="318" y2="11.5" stroke="#929497"/>
        <circle cx="12.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M12.1513 17H13.9843V7.9H12.3593L10.2273 9.044V10.877L12.1513 9.902V17Z" fill="#2B3D4F"/>
        <circle cx="45.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M42.1972 17H48.6712V15.362H44.8752L46.4352 13.932C47.8392 12.645 48.3592 11.8 48.3592 10.5C48.3592 8.771 46.8122 7.718 45.1352 7.718C44.0432 7.718 43.1462 8.069 42.4832 8.589V10.513C43.1722 9.902 43.9392 9.473 44.9402 9.473C45.8762 9.473 46.5002 9.967 46.5002 10.747C46.5002 11.553 46.0452 12.138 44.8752 13.204L42.1972 15.635V17Z" fill="#2B3D4F"/>
        <circle cx="78.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M78.0245 17.182C80.0395 17.182 81.6905 15.973 81.6905 13.984C81.6905 12.255 80.3385 11.397 79.2335 11.189L81.2875 9.304V7.9H75.5415V9.512H78.8955L76.7245 11.514V12.749C77.0235 12.632 77.4265 12.502 78.0115 12.502C79.1425 12.502 79.8185 13.126 79.8185 13.984C79.8185 14.972 78.9345 15.505 77.9205 15.505C76.7505 15.505 75.8405 15.076 75.2555 14.673V16.454C75.8925 16.857 76.7635 17.182 78.0245 17.182Z" fill="#2B3D4F"/>
        <circle cx="111.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M112.198 17H114.018V14.725H115.357V13.217H114.018V7.9H112.172L107.635 13.373V14.725H112.198V17ZM109.702 13.217L112.198 10.253V13.217H109.702Z" fill="#2B3D4F"/>
        <circle cx="144.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M143.883 17.182C145.937 17.182 147.692 16.051 147.692 13.984C147.692 12.164 146.236 10.968 144.26 10.968C143.948 10.968 143.636 11.007 143.389 11.059L143.584 9.512H147.068V7.9H142.206L141.608 12.866C142.349 12.606 143.051 12.476 143.792 12.476C145.066 12.476 145.82 13.1 145.82 13.984C145.82 15.011 144.884 15.505 143.805 15.505C142.713 15.505 141.868 15.141 141.296 14.777V16.532C141.933 16.896 142.739 17.182 143.883 17.182Z" fill="#2B3D4F"/>
        <circle cx="177.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M177.697 17.182C179.556 17.182 180.999 15.895 180.999 13.971C180.999 12.138 179.699 11.033 178.087 11.033C177.164 11.033 176.41 11.358 175.968 11.878C176.059 10.539 176.891 9.408 178.451 9.408C179.114 9.408 179.725 9.603 180.297 9.993V8.303C179.673 7.887 178.958 7.718 178.243 7.718C175.422 7.718 174.07 10.227 174.07 12.918C174.07 15.544 175.396 17.182 177.697 17.182ZM177.58 15.57C176.605 15.57 175.968 14.868 175.916 13.633C176.176 12.97 176.865 12.489 177.58 12.489C178.49 12.489 179.14 13.126 179.14 14.01C179.14 14.933 178.49 15.57 177.58 15.57Z" fill="#2B3D4F"/>
        <circle cx="210.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M207.852 17H209.841L213.689 9.343V7.9H207.267V9.577H211.57L207.852 17Z" fill="#2B3D4F"/>
        <circle cx="243.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M245.445 12.151C246.225 11.787 246.719 11.098 246.719 10.24C246.719 8.732 245.328 7.718 243.508 7.718C241.675 7.718 240.284 8.732 240.284 10.24C240.284 11.098 240.778 11.787 241.558 12.151C240.583 12.502 239.868 13.347 239.868 14.413C239.868 16.207 241.558 17.182 243.508 17.182C245.445 17.182 247.135 16.207 247.135 14.413C247.135 13.347 246.433 12.502 245.445 12.151ZM243.508 9.278C244.301 9.278 244.912 9.746 244.912 10.422C244.912 11.085 244.301 11.553 243.508 11.553C242.715 11.553 242.091 11.085 242.091 10.422C242.091 9.746 242.715 9.278 243.508 9.278ZM243.508 15.609C242.533 15.609 241.805 15.037 241.805 14.296C241.805 13.529 242.533 12.97 243.508 12.97C244.483 12.97 245.198 13.529 245.198 14.296C245.198 15.037 244.483 15.609 243.508 15.609Z" fill="#2B3D4F"/>
        <circle cx="276.5" cy="12.5" r="12.5" fill="#EDEDED"/>
        <path d="M276.307 7.718C274.448 7.718 273.005 9.005 273.005 10.929C273.005 12.762 274.305 13.867 275.917 13.867C276.84 13.867 277.594 13.542 278.036 13.022C277.945 14.361 277.113 15.492 275.553 15.492C274.89 15.492 274.279 15.297 273.707 14.907V16.597C274.331 17.013 275.046 17.182 275.761 17.182C278.582 17.182 279.934 14.673 279.934 11.982C279.934 9.356 278.608 7.718 276.307 7.718ZM276.424 9.33C277.399 9.33 278.036 10.019 278.088 11.254C277.828 11.917 277.139 12.411 276.424 12.411C275.514 12.411 274.864 11.774 274.864 10.89C274.864 9.967 275.514 9.33 276.424 9.33Z" fill="#2B3D4F"/>
        <circle cx="309.5" cy="12.5" r="12.5" fill="#2B3D4F"/>
        <circle cx="309.5" cy="12.5" r="9" fill="#2B3D4F" stroke="white"/>
        <path d="M304.568 17H306.401V7.9H304.776L302.644 9.044V10.877L304.568 9.902V17ZM312.039 17.182C314.535 17.182 315.952 14.92 315.952 12.45C315.952 9.98 314.535 7.718 312.039 7.718C309.53 7.718 308.113 9.98 308.113 12.45C308.113 14.92 309.53 17.182 312.039 17.182ZM312.039 15.427C310.739 15.427 309.985 14.036 309.985 12.45C309.985 10.864 310.739 9.473 312.039 9.473C313.339 9.473 314.08 10.864 314.08 12.45C314.08 14.036 313.339 15.427 312.039 15.427Z" fill="white"/>
    </svg>
    `
}

export const downloadDriverPlusRespondentPdf = (respondent, data) => {
    const docDefinition = {
        styles: {
            font: 'Geometria Light'
        },
        pageOrientation: 'landscape',
        pageMargins: [0, 55, 0, 50],
        header: currentPage => ({
            columns: [
                (currentPage === 1 ? { text: '' } : {
                    width: 148,
                    layout: 'noBorders',
                    table: {
                        layout: {
                            hLineWidth: () => 0,
                            vLineWidth: () => 0,
                            paddingTop: () => 0,
                            paddingBottom: () =>  0,
                            paddingLeft: () => 0,
                            paddingRight: () => 0
                        },
                        widths: [148],
                        heights: [51],
                        body: [
                            [
                                {
                                    text: [
                                        { text: 'Driver+ (n)\n', bold: true, fontSize: currentPage === 1 ? 28 : 18, },
                                        { text: ` ${respondent.firstName} ${respondent.lastName} `, fontSize: 11 }
                                    ],
                                    margin: [15, 5, 0, 0],
                                    fillColor: '#2c3d4f',
                                    style: {
                                        color: '#fff'
                                    },
                                }
                            ]
                        ]
                    },
                }),
                {
                    svg: currentPage === 1 ? `<svg width="2000" height="224" viewBox="0 0 2000 224" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="2000" height="190" fill="#2B3D4F"/>
                        <path d="M264.271 106.828C257.908 106.828 253.281 102.005 253.281 95.4508C253.281 88.8945 257.908 84.0755 264.271 84.0755C270.441 84.0755 275.26 88.8961 275.26 95.4508C275.26 102.007 270.633 106.826 264.271 106.826V106.828ZM264.271 70C249.81 70 238.434 81.1837 238.434 95.4508C238.434 109.719 249.81 120.71 264.271 120.71C278.732 120.71 290.107 109.526 290.107 95.4508C290.107 81.1821 278.732 70 264.271 70V70ZM98.8372 106.828C92.4741 106.828 87.8467 102.005 87.8467 95.4508C87.8467 88.8945 92.4741 84.0755 98.8372 84.0755C105.007 84.0755 109.826 88.8961 109.826 95.4508C109.826 102.007 105.199 106.826 98.8372 106.826V106.828ZM109.248 75.2054C105.97 71.5425 101.537 70 96.9083 70C83.6041 70 73 81.1837 73 95.4508C73 101.814 75.1205 107.791 79.1699 112.418C83.6041 117.625 90.5452 120.708 97.6795 120.708C101.922 120.708 106.741 118.781 109.248 115.696V119.746H123.71V70.9645H109.248V75.2054V75.2054ZM338.886 70.9645H321.34L313.049 82.7262V70.9645H298.203V119.746H313.049V88.5097H326.738L338.886 70.9645ZM217.418 106.828C210.668 106.828 205.849 102.199 205.849 95.4508C205.849 88.8945 210.668 84.0755 217.031 84.0755C220.352 84.0915 223.535 85.4062 225.9 87.7384L233.998 76.1699C229.237 72.1946 223.234 70.0117 217.031 70C202.378 70 190.809 81.1837 190.809 95.4508C190.809 109.913 201.993 120.71 216.838 120.71C223.972 120.71 229.757 118.588 234.77 114.347L226.672 102.585C224.55 104.706 221.272 106.826 217.418 106.826V106.828ZM163.813 70C159.57 70 152.436 72.1205 149.93 75.2054V70.9645H135.468V119.746H150.315V88.8961C152.049 86.774 155.907 84.0738 160.534 84.0738C163.041 84.0738 165.162 84.8467 166.704 86.196C167.86 87.352 169.016 89.2809 169.016 93.9083V119.746H183.863V88.5097C183.863 76.1699 174.805 70 163.814 70H163.813Z" fill="white"/>
                        <path d="M0 190H719.5L696 224H0V190Z" fill="#F54D2E"/>
                    </svg>
                    ` : `<svg width="80px" height="55px" viewBox="0 0 80 55">
                        <polygon stroke="#2c3d4f" stroke-width="1px" fill="#2c3d4f" points="0,0 80,0 0,55" />
                    </svg>`,
                    width: currentPage === 1 ? 900 : undefined,
                    margin: [0, 0, 0, 0],
                    alignment: 'left',
                }
            ],
        }),
        content: [
            {
                text: 'Мотивационный опросник\nDriver+ (n)',
                alignment: 'left',
                color: '#F54D2E',
                margin: [20, 150, 20, 0],
                style: {
                    fontSize: 40,
                    bold: true
                }
            },
            {
                svg: `<svg width="800" height="1" viewBox="0 0 800 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="800" height="1" fill="#2B3D4F"/>
                </svg>`,
                width: 780,
                margin: [20, 30, 20, 30]
            },
            {
                text: [
                    { text: 'Имя: ', color: '#2B3D4F', style: { fontSize: 16, bold: true }},
                    { text: `${respondent.lastName} ${respondent.firstName} ${respondent.middleName || ''}`, color: '#2B3D4F', style: { fontSize: 16 }}
                ],
                color: '#2B3D4F',
                margin: [20, 0, 20, 0],
            },
            {
                text: [
                    { text: 'Email: ', color: '#2B3D4F', style: { fontSize: 16, bold: true }},
                    { text: respondent.email, color: '#2B3D4F', style: { fontSize: 16 }}
                ],
                color: '#2B3D4F',
                margin: [20, 5, 20, 0],
            },
            {
                text: [
                    { text: 'Дата прохождения опросника: ', color: '#2B3D4F', style: { fontSize: 16, bold: true }},
                    { text: moment(path(['response', 'finishedAt'], respondent)).format('DD.MM.YYYY'), color: '#2B3D4F', style: { fontSize: 16 }}
                ],
                color: '#2B3D4F',
                margin: [20, 5, 20, 0],
            },
            {
                pageBreak: 'before',
                text: 'Что показывает DRIVER+',
                margin: [20, 50, 20, 0],
                color: '#2B3D4F',
                style: {
                    bold: true,
                    fontSize: 25
                }
            },
            {
                text: 'Опросник DRIVER\u002B определяет структуру мотивации человека, выявляет факторы, которые оказывают наибольшее влияние и стимулируют работать с полной отдачей. Грамотная оценка мотивационных аспектов может решить проблемы, связанные с высокой текучестью кадров и низкой вовлеченностью персонала. В результате Вы получаете информацию об основных мотивационных факторах участника оценки, которые можно использовать в управлении его эффективностью и вовлеченностью. ',
                alignment: 'justify',
                margin: [20, 30, 20, 0],
                color: '#2B3D4F'
            },
            {
                text: 'Нижеприведенный отчет выполнен на основе ответов респондента и в значительной мере отражает содержание этих ответов. При интерпретации результатов следует уделить особое внимание субъективной природе ответов респондента на вопросы мотивационного опросника. Данный отчёт описывает факторы, влияющие на рабочую мотивацию участника. В нём представлена детальная информация о мотиваторах и демотиваторах участника.',
                alignment: 'justify',
                margin: [20, 20, 20, 0],
                color: '#2B3D4F'
            },
            {
                svg: `<svg width="800" height="1" viewBox="0 0 800 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="800" height="1" fill="#2B3D4F"/>
                </svg>`,
                width: 780,
                margin: [20, 20, 20, 20]
            },
            {
                text: 'Данные, представленные в отчёте, полностью конфиденциальны и сохраняют свою актуальность, как правило, в течение года со дня заполнения опросника. Если за это время в  личной жизни или работе участника произойдут значительные перемены, мотивационный опросник необходимо будет заполнить заново для получения более актуальной и достоверной информации о мотивации.',
                alignment: 'justify',
                margin: [20, 0, 20, 0],
                color: '#666'
            },
            {
                pageBreak: 'before',
                svg: window.driverChart.chart.getSVG(),
                width: 400,
                alignment: 'center',
                absolutePosition: {
                    x: 0,
                    y: 58
                }
            },
            {
                svg: `
                <svg width="1000" height="1000" viewBox="0 0 1000 1000" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_501_7321)">
                    <mask id="path-1-inside-1_501_7321" fill="white">
                    <path d="M1000 500C1000 776.142 776.142 1000 500 1000C223.858 1000 0 776.142 0 500C0 223.858 223.858 0 500 0C776.142 0 1000 223.858 1000 500ZM70 500C70 737.482 262.518 930 500 930C737.482 930 930 737.482 930 500C930 262.518 737.482 70 500 70C262.518 70 70 262.518 70 500Z"/>
                    </mask>
                    <path d="M1000 500C1000 776.142 776.142 1000 500 1000C223.858 1000 0 776.142 0 500C0 223.858 223.858 0 500 0C776.142 0 1000 223.858 1000 500ZM70 500C70 737.482 262.518 930 500 930C737.482 930 930 737.482 930 500C930 262.518 737.482 70 500 70C262.518 70 70 262.518 70 500Z" stroke="white" stroke-width="80" mask="url(#path-1-inside-1_501_7321)"/>
                    <path d="M817.92 121.896C819.874 119.568 823.344 119.267 825.643 121.243C910.659 194.336 968.616 293.915 990.176 403.939C990.759 406.914 988.783 409.783 985.794 410.332L928.747 420.802C925.761 421.35 922.893 419.371 922.302 416.384C903.646 322.16 854.009 236.874 781.297 174.113L780.97 174.491L781.297 174.113C778.991 172.123 778.687 168.651 780.639 166.326L817.92 121.896Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M863.399 242.561L864.903 244.672L869.639 241.3L868.046 249.086L869.967 251.784L871.89 242.724L879.698 243.727L877.86 241.146L871.12 240.245L875.166 237.364L873.663 235.252L863.399 242.561Z" fill="white"/>
                    <path d="M875.394 255.933C874.159 254.096 874.674 251.732 876.646 250.407C878.617 249.081 881 249.496 882.236 251.333C883.471 253.17 882.956 255.534 880.984 256.86C879.012 258.185 876.63 257.77 875.394 255.933ZM873.378 257.289C875.487 260.426 879.458 261.074 882.461 259.055C885.478 257.027 886.361 253.114 884.252 249.977C882.143 246.84 878.187 246.182 875.169 248.211C872.167 250.229 871.269 254.152 873.378 257.289Z" fill="white"/>
                    <path d="M878.109 263.696C878.156 264.007 878.356 264.392 878.538 264.681C880.13 267.21 882.277 267.006 887.314 264.026L889.516 262.726L891.549 265.955L882.851 271.431L884.222 273.609L894.885 266.896L890.148 259.371L886.996 261.291C882.799 263.848 881.214 264.506 880.456 263.303C880.246 262.968 880.138 262.695 880.089 262.449L878.109 263.696Z" fill="white"/>
                    <path d="M884.898 274.44C884.936 274.752 885.125 275.142 885.3 275.436C886.823 278.006 888.976 277.86 894.091 275.017L896.326 273.776L898.272 277.059L889.43 282.299L890.742 284.513L901.582 278.089L897.048 270.44L893.846 272.275C889.582 274.718 887.98 275.333 887.255 274.11C887.053 273.769 886.953 273.494 886.911 273.247L884.898 274.44Z" fill="white"/>
                    <path d="M892.656 287.832L897.253 296.088L899.282 294.959L895.945 288.967L898.776 287.391L901.386 292.077L903.383 290.965L900.773 286.279L902.896 285.096L906.136 290.915L908.165 289.785L903.664 281.702L892.656 287.832Z" fill="white"/>
                    <path d="M897.991 297.452L899.201 299.743L908.289 294.943L911.324 300.689L913.378 299.604L909.132 291.566L897.991 297.452Z" fill="white"/>
                    <path d="M828.33 159.314C826.735 157.779 826.739 155.36 828.386 153.648C830.034 151.936 832.451 151.838 834.046 153.373C835.642 154.908 835.637 157.327 833.99 159.039C832.343 160.752 829.926 160.849 828.33 159.314ZM826.646 161.065C829.369 163.686 833.388 163.481 835.897 160.874C838.418 158.254 838.455 154.243 835.731 151.622C833.007 149.001 829.001 149.193 826.48 151.813C823.971 154.421 823.922 158.444 826.646 161.065Z" fill="white"/>
                    <path d="M840.965 157.046L839.313 158.678L842.108 161.508L834.795 168.731L836.616 170.575L843.929 163.353L846.737 166.196L848.389 164.564L840.965 157.046Z" fill="white"/>
                    <path d="M840.299 174.32L842.072 176.21L846.102 172.428L849.994 176.576L845.965 180.358L847.726 182.234L856.914 173.612L855.152 171.735L851.714 174.962L847.821 170.815L851.26 167.587L849.486 165.697L840.299 174.32Z" fill="white"/>
                    <path d="M854.996 187.079C853.527 185.422 853.724 183.011 855.502 181.435C857.281 179.86 859.698 179.955 861.166 181.612C862.634 183.269 862.438 185.68 860.659 187.255C858.881 188.831 856.464 188.736 854.996 187.079ZM853.177 188.691C855.684 191.52 859.706 191.635 862.414 189.236C865.135 186.825 865.492 182.829 862.985 180C860.478 177.171 856.469 177.044 853.748 179.455C851.04 181.854 850.67 185.861 853.177 188.691Z" fill="white"/>
                    <path d="M858.55 194.35L868.63 206.522L878.334 198.485L876.681 196.489L868.765 203.045L866.193 199.939L874.109 193.383L872.467 191.401L864.552 197.957L861.991 194.865L869.907 188.31L868.254 186.313L858.55 194.35Z" fill="white"/>
                    <path d="M870.63 209.079L876.424 216.545L878.258 215.121L874.053 209.703L876.613 207.717L879.902 211.954L881.708 210.553L878.419 206.315L880.339 204.825L884.422 210.087L886.256 208.663L880.584 201.354L870.63 209.079Z" fill="white"/>
                    <path d="M877.403 217.813L878.94 219.9L883.39 216.624L886.763 221.205L882.313 224.481L883.839 226.554L893.985 219.083L892.459 217.01L888.661 219.806L885.289 215.226L889.087 212.43L887.55 210.343L877.403 217.813Z" fill="white"/>
                    <path d="M885.63 229.038L886.871 230.828L897.179 231.195L890.389 235.902L891.855 238.018L902.21 230.839L900.939 229.005L890.717 228.666L897.462 223.99L895.986 221.86L885.63 229.038Z" fill="white"/>
                    <path d="M892.928 239.571L894.56 242.074L900.852 242.399L902.051 244.239L897.784 247.02L899.199 249.192L909.755 242.311L906.57 237.425C905.096 235.164 902.904 234.637 901.08 235.826C899.677 236.74 899.098 238.257 899.589 239.935L892.928 239.571ZM902.185 240.456C901.546 239.475 901.615 238.593 902.445 238.052C903.259 237.521 904.11 237.804 904.749 238.785L906.439 241.378L903.876 243.049L902.185 240.456Z" fill="white"/>
                    <path d="M903.414 255.899L904.533 257.767L914.794 258.818L907.706 263.064L909.029 265.272L919.838 258.797L918.691 256.882L908.514 255.866L915.555 251.647L914.223 249.424L903.414 255.899Z" fill="white"/>
                    <path d="M912.717 271.685L913.964 273.958L922.975 269.014L925.564 273.732L916.553 278.676L917.791 280.933L928.838 274.872L923.764 265.625L912.717 271.685Z" fill="white"/>
                    <path d="M923.741 287.646C922.732 285.675 923.523 283.389 925.638 282.306C927.752 281.223 930.069 281.917 931.079 283.888C932.088 285.858 931.297 288.144 929.182 289.227C927.067 290.31 924.75 289.616 923.741 287.646ZM921.578 288.753C923.301 292.118 927.168 293.232 930.388 291.582C933.625 289.925 934.965 286.144 933.242 282.78C931.518 279.416 927.668 278.294 924.431 279.951C921.211 281.6 919.855 285.389 921.578 288.753Z" fill="white"/>
                    <path d="M922.845 296.311L923.887 298.507L926.212 297.403L929.91 305.191L927.585 306.295L928.627 308.49L933.05 306.391L932.309 304.83L941.594 300.421L937.795 292.421L935.99 293.278C932.137 295.108 929.84 295.601 927.8 295.334L927.268 294.212L922.845 296.311ZM929.028 297.919C931.813 297.951 934.444 296.862 936.802 295.742L938.384 299.076L931.197 302.488L929.028 297.919Z" fill="white"/>
                    <path d="M929.057 309.39L930.033 311.615L932.391 310.582L935.853 318.478L933.495 319.512L934.471 321.737L938.955 319.771L938.261 318.189L947.674 314.062L944.118 305.951L942.288 306.753C938.381 308.466 936.071 308.89 934.04 308.561L933.541 307.424L929.057 309.39ZM935.189 311.183C937.972 311.299 940.635 310.289 943.025 309.241L944.507 312.62L937.22 315.815L935.189 311.183Z" fill="white"/>
                    <path d="M937.623 322.633L941.183 331.387L943.334 330.512L940.751 324.159L943.752 322.938L945.773 327.907L947.89 327.046L945.87 322.077L948.12 321.162L950.629 327.331L952.78 326.456L949.295 317.886L937.623 322.633Z" fill="white"/>
                    <path d="M941.919 333.257L942.838 335.68L947.5 333.912L948.534 336.639C949.511 339.214 951.59 340.177 953.66 339.392C955.73 338.607 956.63 336.514 955.654 333.939L953.701 328.789L941.919 333.257ZM949.571 333.127L952.499 332.017L953.469 334.575C953.903 335.719 953.666 336.618 952.707 336.981C951.765 337.339 950.975 336.83 950.541 335.685L949.571 333.127Z" fill="white"/>
                    <path d="M945.764 343.238L946.857 346.422L953.579 348.872L948.319 350.678L949.16 353.129L954.421 351.323L950.621 357.385L951.72 360.586L956.164 353.408L963.462 355.984L962.428 352.971L956.055 350.762L961.077 349.038L960.236 346.586L955.214 348.311L958.891 342.671L957.857 339.657L953.674 346.156L945.764 343.238Z" fill="white"/>
                    <path d="M952.057 361.812L952.821 364.289L958.377 362.577L954.416 369.467L955.391 372.632L960.066 364.637L967.162 368.044L966.23 365.017L960.114 362.042L964.862 360.579L964.099 358.102L952.057 361.812Z" fill="white"/>
                    <path d="M955.448 372.796L956.178 375.414L959.32 375.828L960.751 380.96L958.275 382.939L959.015 385.592L969.772 376.988L969.105 374.596L955.448 372.796ZM961.601 376.033L966.312 376.644L962.592 379.587L961.601 376.033Z" fill="white"/>
                    <path d="M986.582 414.71C989.575 414.183 992.427 416.183 992.918 419.175C1011.06 529.814 991.45 643.35 937.243 741.492C935.778 744.146 932.42 745.074 929.778 743.573L879.347 714.924C876.707 713.425 875.782 710.065 877.249 707.397C923.524 623.226 940.321 525.987 924.962 431.169C924.475 428.164 926.474 425.309 929.463 424.782L986.582 414.71Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M930.529 657.596L931.356 655.139L923.184 652.39L927.964 650.883L928.423 649.519L925.509 645.48L933.68 648.23L934.495 645.807L922.553 641.789L921.738 644.212L925.52 649.34L919.408 651.138L918.587 653.578L930.529 657.596Z" fill="white"/>
                    <path d="M935.228 643.57L936.017 640.969L933.587 638.934L935.133 633.835L938.284 633.493L939.083 630.858L925.389 632.347L924.669 634.724L935.228 643.57ZM931.772 637.537L928.124 634.494L932.843 634.006L931.772 637.537Z" fill="white"/>
                    <path d="M927.204 626.516L929.442 627.134L930.5 623.299L940.408 626.032L941.097 623.533L931.189 620.8L932.252 616.948L930.013 616.33L927.204 626.516Z" fill="white"/>
                    <path d="M942.311 619.072L944.621 609.908L942.37 609.341L940.693 615.991L937.551 615.198L938.863 609.997L936.646 609.438L935.335 614.64L932.978 614.045L934.607 607.588L932.355 607.02L930.093 615.991L942.311 619.072Z" fill="white"/>
                    <path d="M945.146 607.717L945.72 605.189L940.858 604.085L941.504 601.241C942.113 598.556 940.914 596.604 938.755 596.113C936.596 595.623 934.688 596.87 934.079 599.555L932.859 604.927L945.146 607.717ZM938.699 603.595L935.645 602.901L936.25 600.233C936.521 599.039 937.215 598.422 938.216 598.649C939.199 598.872 939.576 599.733 939.305 600.927L938.699 603.595Z" fill="white"/>
                    <path d="M947.861 595.642L948.286 593.506L941.387 585.838L949.49 587.45L949.993 584.926L937.635 582.467L937.199 584.656L944.059 592.243L936.009 590.641L935.503 593.183L947.861 595.642Z" fill="white"/>
                    <path d="M950.227 583.773L950.685 581.094L948.021 579.378L948.92 574.126L952.003 573.394L952.468 570.679L939.067 573.864L938.647 576.312L950.227 583.773ZM946.046 578.218L942.048 575.653L946.669 574.581L946.046 578.218Z" fill="white"/>
                    <path d="M952.471 570.006C952.637 569.739 952.733 569.316 952.781 568.977C953.201 566.019 951.431 564.786 945.662 563.803L943.141 563.372L943.678 559.594L953.854 561.039L954.216 558.491L941.741 556.719L940.491 565.523L944.136 566.095C948.992 566.857 950.626 567.38 950.426 568.788C950.371 569.18 950.277 569.458 950.155 569.677L952.471 570.006Z" fill="white"/>
                    <path d="M954.656 555.111L955.267 549.782C955.613 546.759 954.07 544.734 951.638 544.456C949.206 544.177 947.226 545.798 946.88 548.82L946.564 551.574L942.433 551.1L942.138 553.675L954.656 555.111ZM952.68 552.275L948.746 551.824L949.045 549.213C949.215 547.729 950.035 546.899 951.305 547.044C952.557 547.188 953.149 548.18 952.979 549.664L952.68 552.275Z" fill="white"/>
                    <path d="M956.088 542.387L956.314 539.805L950.809 539.323L951.305 533.657L956.81 534.138L957.034 531.574L944.482 530.476L944.257 533.04L948.956 533.451L948.46 539.118L943.762 538.707L943.536 541.289L956.088 542.387Z" fill="white"/>
                    <path d="M955.479 521.815C955.352 524.026 953.513 525.597 951.141 525.461C948.769 525.326 947.122 523.554 947.248 521.344C947.375 519.134 949.213 517.562 951.586 517.698C953.958 517.834 955.605 519.605 955.479 521.815ZM957.905 521.954C958.121 518.18 955.349 515.263 951.737 515.056C948.107 514.849 945.038 517.431 944.822 521.205C944.606 524.979 947.36 527.895 950.99 528.103C954.602 528.31 957.689 525.728 957.905 521.954Z" fill="white"/>
                    <path d="M958.096 513.238L958.381 503.793L956.06 503.722L955.853 510.577L952.615 510.479L952.777 505.118L950.492 505.049L950.33 510.41L947.901 510.337L948.102 503.68L945.781 503.61L945.501 512.857L958.096 513.238Z" fill="white"/>
                    <path d="M950.56 678.045L952.77 672.107C953.705 669.593 952.929 667.499 950.938 666.758C949.117 666.08 947.585 666.893 946.892 667.98C946.869 667.164 946.382 666.099 945.1 665.622C943.143 664.894 941.589 666.025 940.817 668.1L938.751 673.65L950.56 678.045ZM949.372 674.837L946.285 673.688L947.421 670.635C947.861 669.454 948.694 668.919 949.689 669.289C950.684 669.66 950.948 670.603 950.508 671.784L949.372 674.837ZM944.277 672.941L941.68 671.974L942.697 669.241C943.073 668.229 943.772 667.798 944.615 668.112C945.425 668.413 945.671 669.196 945.295 670.208L944.277 672.941Z" fill="white"/>
                    <path d="M955.045 659.186C954.332 661.282 952.14 662.305 949.89 661.54C947.641 660.775 946.527 658.628 947.239 656.532C947.952 654.436 950.144 653.413 952.394 654.178C954.643 654.943 955.757 657.09 955.045 659.186ZM957.345 659.968C958.562 656.389 956.671 652.838 953.245 651.673C949.803 650.502 946.156 652.171 944.939 655.75C943.722 659.329 945.596 662.875 949.039 664.045C952.464 665.21 956.128 663.547 957.345 659.968Z" fill="white"/>
                    <path d="M961.225 648.078C962.228 644.846 961.09 642.439 959.01 641.793C957.411 641.297 956.174 642.063 955.482 642.96C955.408 642.165 954.922 640.997 953.581 640.581C951.57 639.957 949.611 641.29 948.742 644.092C948.267 645.622 948.193 647.258 948.404 648.341L950.69 649.05C950.514 647.978 950.505 646.486 950.991 644.922C951.423 643.529 952.154 642.814 952.962 643.064C953.787 643.32 954.093 644.094 953.683 645.418L953.123 647.223L955.203 647.868L955.672 646.355C956.163 644.774 956.983 643.954 957.963 644.258C959.063 644.599 959.38 645.885 958.815 647.707C958.399 649.048 957.422 650.498 956.352 651.334L958.758 652.081C959.799 651.216 960.623 650.021 961.225 648.078Z" fill="white"/>
                    <path d="M963.237 640.703L963.942 638.209L958.624 636.706L960.172 631.232L965.489 632.735L966.189 630.258L954.064 626.831L953.364 629.308L957.902 630.591L956.355 636.064L951.817 634.781L951.112 637.276L963.237 640.703Z" fill="white"/>
                    <path d="M966.78 628.146L967.445 625.51L964.921 623.593L966.223 618.427L969.355 617.935L970.028 615.265L956.42 617.403L955.813 619.811L966.78 628.146ZM963.042 622.284L959.254 619.417L963.944 618.706L963.042 622.284Z" fill="white"/>
                    <path d="M970.432 613.45L971.002 610.921L960.975 608.661L962.404 602.322L960.139 601.812L958.14 610.679L970.432 613.45Z" fill="white"/>
                    <path d="M972.696 603.334L973.21 600.793L968.322 599.805L968.901 596.947C969.446 594.247 968.201 592.324 966.031 591.885C963.861 591.446 961.984 592.738 961.438 595.437L960.346 600.836L972.696 603.334ZM966.152 599.366L963.083 598.745L963.625 596.063C963.868 594.864 964.547 594.23 965.552 594.433C966.54 594.633 966.937 595.484 966.695 596.684L966.152 599.366Z" fill="white"/>
                    <path d="M974.933 592.287L975.4 589.61L972.741 587.885L973.657 582.636L976.743 581.914L977.216 579.201L963.805 582.343L963.378 584.79L974.933 592.287ZM970.77 586.719L966.78 584.141L971.404 583.084L970.77 586.719Z" fill="white"/>
                    <path d="M977.336 578.747L977.804 575.413L972.921 570.184L978.429 570.957L978.79 568.39L973.282 567.617L979.415 563.934L979.886 560.583L972.672 564.968L967.333 559.366L966.89 562.521L971.571 567.377L966.312 566.638L965.952 569.205L971.21 569.943L965.376 573.305L964.933 576.46L971.606 572.562L977.336 578.747Z" fill="white"/>
                    <path d="M982.45 560.593L982.707 558.177L980.148 557.904L981.062 549.33L983.622 549.603L983.88 547.187L979.011 546.668L978.828 548.386L968.608 547.296L967.669 556.102L969.655 556.314C973.897 556.766 976.113 557.546 977.713 558.839L977.581 560.074L982.45 560.593ZM978.017 555.993C975.658 554.511 972.846 554.066 970.25 553.789L970.642 550.12L978.553 550.964L978.017 555.993Z" fill="white"/>
                    <path d="M981.52 544.534L982.269 535.114L979.955 534.93L979.411 541.766L976.181 541.509L976.606 536.162L974.328 535.981L973.902 541.328L971.48 541.135L972.008 534.496L969.693 534.312L968.96 543.535L981.52 544.534Z" fill="white"/>
                    <path d="M982.471 532.233L982.606 529.644L977.088 529.355L977.386 523.675L982.905 523.964L983.039 521.394L970.457 520.734L970.322 523.304L975.031 523.551L974.733 529.231L970.024 528.984L969.888 531.573L982.471 532.233Z" fill="white"/>
                    <path d="M983.14 518.933L983.196 516.756L975.093 510.373L983.353 510.584L983.418 508.011L970.822 507.69L970.766 509.921L978.816 516.23L970.61 516.021L970.544 518.612L983.14 518.933Z" fill="white"/>
                    <path d="M983.45 504.597L983.436 495.147L981.114 495.15L981.124 502.008L977.884 502.013L977.876 496.649L975.59 496.653L975.598 502.017L973.168 502.02L973.158 495.36L970.836 495.364L970.85 504.616L983.45 504.597Z" fill="white"/>
                    <path d="M927.566 747.433C930.198 748.952 931.097 752.318 929.55 754.925C872.331 851.342 784.329 925.71 679.72 966.048C676.891 967.139 673.722 965.691 672.663 962.843L652.446 908.481C651.388 905.635 652.839 902.467 655.678 901.366C745.231 866.632 820.601 802.939 869.783 720.433C871.342 717.817 874.708 716.915 877.337 718.433L927.566 747.433Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M686.431 937.109C687.636 936.585 688.679 935.779 689.351 934.84L688.311 932.446C687.702 933.574 686.751 934.32 685.578 934.829C683.333 935.804 680.958 934.991 679.991 932.762C679.03 930.549 680.016 928.257 682.196 927.311C683.484 926.751 684.535 926.687 685.621 926.883L684.581 924.489C683.482 924.397 682.351 924.594 681.211 925.089C677.629 926.644 676.029 930.282 677.563 933.816C679.098 937.349 682.881 938.65 686.431 937.109Z" fill="white"/>
                    <path d="M697.478 929.451C695.473 930.391 693.216 929.521 692.208 927.37C691.199 925.218 691.974 922.927 693.978 921.987C695.983 921.047 698.24 921.918 699.248 924.069C700.257 926.22 699.482 928.512 697.478 929.451ZM698.509 931.652C701.932 930.047 703.18 926.222 701.644 922.946C700.101 919.654 696.369 918.182 692.947 919.787C689.524 921.391 688.269 925.201 689.812 928.493C691.348 931.769 695.087 933.256 698.509 931.652Z" fill="white"/>
                    <path d="M716.383 925.47L718.567 924.364L716.355 919.996L714.797 920.785L710.155 911.615L707.842 912.786L712.485 921.956L707.78 924.338L703.137 915.168L700.825 916.339L706.516 927.58L715.22 923.174L716.383 925.47Z" fill="white"/>
                    <path d="M719.422 921.011L721.336 919.972L722.821 909.765L726.763 917.026L729.025 915.798L723.014 904.725L721.052 905.789L719.605 915.914L715.689 908.7L713.411 909.937L719.422 921.011Z" fill="white"/>
                    <path d="M731.094 914.667L733.445 913.302L733.055 910.157L737.664 907.482L740.202 909.381L742.583 907.998L731.552 899.749L729.404 900.996L731.094 914.667ZM732.681 907.898L732.089 903.184L735.872 906.045L732.681 907.898Z" fill="white"/>
                    <path d="M742.051 908.206C742.363 908.162 742.749 907.965 743.04 907.786C745.583 906.217 745.399 904.067 742.465 899.004L741.184 896.791L744.431 894.787L749.829 903.534L752.019 902.182L745.403 891.459L737.835 896.128L739.727 899.297C742.246 903.517 742.89 905.108 741.679 905.855C741.342 906.063 741.069 906.168 740.823 906.214L742.051 908.206Z" fill="white"/>
                    <path d="M754.905 900.37L759.392 897.431C761.937 895.765 762.507 893.283 761.165 891.235C759.824 889.187 757.312 888.702 754.767 890.369L752.448 891.888L750.17 888.409L748.002 889.829L754.905 900.37ZM755.821 897.037L753.651 893.725L755.85 892.285C757.1 891.466 758.262 891.566 758.962 892.635C759.652 893.689 759.269 894.779 758.019 895.598L755.821 897.037Z" fill="white"/>
                    <path d="M765.585 893.36L767.715 891.882L764.564 887.343L769.236 884.099L772.387 888.639L774.502 887.171L767.318 876.82L765.203 878.288L767.892 882.162L763.219 885.405L760.53 881.531L758.401 883.009L765.585 893.36Z" fill="white"/>
                    <path d="M776.414 885.845L778.602 884.233L777.872 881.148L782.161 877.987L784.891 879.597L787.107 877.963L775.242 870.967L773.242 872.441L776.414 885.845ZM777.253 878.944L776.15 874.323L780.223 876.754L777.253 878.944Z" fill="white"/>
                    <path d="M787.904 877.37L790.258 875.529L790.041 869.232L791.771 867.879L794.909 871.892L796.951 870.295L789.189 860.37L784.595 863.962C782.468 865.626 782.131 867.854 783.473 869.57C784.504 870.888 786.065 871.336 787.694 870.702L787.904 877.37ZM787.99 868.071C787.069 868.791 786.183 868.799 785.573 868.019C784.974 867.253 785.184 866.381 786.105 865.66L788.544 863.753L790.429 866.164L787.99 868.071Z" fill="white"/>
                    <path d="M803.606 868.277L805.462 866.709L803.801 864.743L810.386 859.178L812.048 861.144L813.904 859.575L810.744 855.836L809.424 856.951L802.79 849.101L796.026 854.817L797.315 856.343C800.069 859.601 801.132 861.696 801.395 863.736L800.446 864.538L803.606 868.277ZM803.581 861.889C802.901 859.188 801.175 856.922 799.49 854.929L802.309 852.547L807.444 858.624L803.581 861.889Z" fill="white"/>
                    <path d="M815.024 855.168L822.065 848.866L820.517 847.136L815.407 851.709L813.246 849.295L817.243 845.718L815.718 844.014L811.721 847.592L810.101 845.781L815.063 841.339L813.514 839.609L806.621 845.78L815.024 855.168Z" fill="white"/>
                    <path d="M822.613 848.4L824.788 846.351L823.995 840.101L825.594 838.595L829.086 842.304L830.973 840.527L822.335 831.354L818.089 835.352C816.124 837.203 815.992 839.453 817.486 841.038C818.633 842.257 820.229 842.56 821.793 841.779L822.613 848.4ZM821.847 839.132C820.995 839.934 820.114 840.022 819.435 839.301C818.769 838.594 818.897 837.706 819.749 836.904L822.003 834.782L824.101 837.009L821.847 839.132Z" fill="white"/>
                    <path d="M824.177 829.584L825.811 831.234L828.637 828.434L835.871 835.736L837.712 833.912L830.479 826.61L833.318 823.798L831.683 822.148L824.177 829.584Z" fill="white"/>
                    <path d="M840.856 830.785L847.42 823.987L845.75 822.374L840.986 827.307L838.655 825.057L842.381 821.198L840.737 819.61L837.011 823.469L835.263 821.781L839.889 816.99L838.219 815.377L831.792 822.032L840.856 830.785Z" fill="white"/>
                    <path d="M848.677 822.542C848.964 822.415 849.283 822.121 849.513 821.868C851.53 819.664 850.766 817.646 846.559 813.578L844.722 811.799L847.298 808.984L854.881 815.922L856.618 814.023L847.322 805.518L841.32 812.078L844.006 814.609C847.583 817.979 848.636 819.334 847.677 820.383C847.409 820.675 847.175 820.851 846.951 820.963L848.677 822.542Z" fill="white"/>
                    <path d="M858.893 811.48L862.406 807.427C864.398 805.128 864.267 802.585 862.417 800.982C860.568 799.379 858.018 799.599 856.026 801.898L854.211 803.993L851.069 801.27L849.371 803.228L858.893 811.48ZM858.863 808.025L855.87 805.431L857.591 803.445C858.57 802.316 859.715 802.094 860.681 802.931C861.633 803.756 861.562 804.91 860.584 806.039L858.863 808.025Z" fill="white"/>
                    <path d="M867.271 801.775L868.915 799.771L864.643 796.266L868.251 791.869L872.523 795.374L874.155 793.384L864.414 785.392L862.782 787.382L866.428 790.373L862.82 794.771L859.174 791.779L857.53 793.783L867.271 801.775Z" fill="white"/>
                    <path d="M877.834 785.161C876.48 786.912 874.074 787.168 872.195 785.714C870.316 784.26 869.959 781.867 871.314 780.116C872.669 778.365 875.074 778.109 876.953 779.563C878.833 781.017 879.189 783.41 877.834 785.161ZM879.756 786.648C882.069 783.658 881.434 779.685 878.572 777.471C875.697 775.246 871.705 775.64 869.392 778.629C867.079 781.619 867.7 785.581 870.576 787.806C873.437 790.02 877.443 789.637 879.756 786.648Z" fill="white"/>
                    <path d="M889.137 774.134C889.911 773.072 890.402 771.849 890.505 770.699L888.396 769.162C888.442 770.443 888 771.568 887.248 772.601C885.806 774.579 883.346 775.081 881.383 773.65C879.433 772.229 879.122 769.753 880.522 767.833C881.349 766.698 882.223 766.11 883.258 765.729L881.149 764.191C880.155 764.669 879.279 765.412 878.548 766.416C876.247 769.572 876.711 773.519 879.824 775.788C882.937 778.057 886.858 777.262 889.137 774.134Z" fill="white"/>
                    <path d="M881.826 762.316L883.735 763.638L886 760.368L894.448 766.222L895.925 764.091L887.476 758.238L889.752 754.953L887.844 753.631L881.826 762.316Z" fill="white"/>
                    <path d="M898.809 759.899L901.758 755.418C903.43 752.876 902.966 750.373 900.921 749.027C898.876 747.682 896.378 748.236 894.706 750.777L893.182 753.093L889.708 750.807L888.284 752.972L898.809 759.899ZM898.324 756.477L895.016 754.3L896.461 752.105C897.282 750.857 898.388 750.485 899.456 751.188C900.508 751.881 900.59 753.033 899.769 754.281L898.324 756.477Z" fill="white"/>
                    <path d="M330.572 964.037C329.533 966.893 326.374 968.363 323.538 967.292C218.65 927.684 130.131 853.933 72.2401 757.918C70.6748 755.322 71.5504 751.95 74.1715 750.413L124.197 721.063C126.815 719.526 130.188 720.405 131.765 723.01C181.522 805.17 257.335 868.335 347.128 902.443C349.975 903.524 351.447 906.682 350.409 909.535L330.572 964.037Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M128.501 790.677C127.12 788.946 127.441 786.549 129.299 785.067C131.156 783.585 133.565 783.805 134.946 785.535C136.326 787.266 136.005 789.663 134.148 791.145C132.291 792.627 129.882 792.407 128.501 790.677ZM126.602 792.192C128.959 795.147 132.97 795.47 135.798 793.214C138.641 790.946 139.202 786.975 136.845 784.02C134.488 781.065 130.491 780.731 127.648 782.998C124.82 785.255 124.244 789.237 126.602 792.192Z" fill="white"/>
                    <path d="M131.757 798.199L135.26 802.355C137.186 804.64 139.642 804.9 141.486 803.345C143.317 801.802 143.503 799.315 141.577 797.03L139.744 794.856L141.285 793.556L145.508 798.566L147.284 797.069L141.39 790.078L131.757 798.199ZM135.175 798.707L138.093 796.248L139.798 798.271C140.749 799.399 140.781 800.526 139.845 801.315C138.909 802.104 137.832 801.859 136.88 800.731L135.175 798.707Z" fill="white"/>
                    <path d="M148.348 821.126L149.988 822.945L153.624 819.666L152.455 818.369L160.088 811.486L158.364 809.575L150.731 816.458L148.031 813.463L155.664 806.58L153.94 804.669L146.307 811.551L143.607 808.557L151.24 801.674L149.505 799.749L140.147 808.187L150.26 819.403L148.348 821.126Z" fill="white"/>
                    <path d="M153.294 822.635L159.841 829.449L161.515 827.84L156.764 822.895L159.1 820.65L162.817 824.518L164.465 822.934L160.749 819.066L162.501 817.383L167.115 822.185L168.79 820.576L162.379 813.905L153.294 822.635Z" fill="white"/>
                    <path d="M162.047 831.709L163.893 833.529L167.773 829.594L171.823 833.588L167.943 837.523L169.775 839.33L178.623 830.359L176.79 828.552L173.479 831.91L169.429 827.916L172.74 824.558L170.895 822.738L162.047 831.709Z" fill="white"/>
                    <path d="M171.229 840.745L172.82 842.232L182.972 840.411L177.331 846.447L179.212 848.205L187.815 838.999L186.184 837.475L176.122 839.306L181.726 833.309L179.832 831.539L171.229 840.745Z" fill="white"/>
                    <path d="M181.932 850.679L189.007 856.943L190.546 855.204L185.411 850.658L187.559 848.233L191.575 851.788L193.091 850.077L189.075 846.521L190.685 844.702L195.672 849.116L197.211 847.378L190.284 841.245L181.932 850.679Z" fill="white"/>
                    <path d="M199.201 865.859C200.215 866.696 201.407 867.259 202.548 867.431L204.209 865.418C202.928 865.387 201.831 864.879 200.845 864.065C198.957 862.507 198.604 860.022 200.15 858.148C201.685 856.288 204.176 856.126 206.009 857.638C207.091 858.532 207.626 859.439 207.945 860.496L209.606 858.482C209.188 857.461 208.499 856.543 207.541 855.752C204.529 853.266 200.561 853.493 198.109 856.464C195.658 859.435 196.216 863.396 199.201 865.859Z" fill="white"/>
                    <path d="M206.838 871.62C206.99 871.895 207.311 872.187 207.583 872.395C209.955 874.211 211.898 873.273 215.583 868.727L217.194 866.742L220.224 869.062L213.975 877.222L216.019 878.787L223.679 868.783L216.62 863.377L214.333 866.274C211.288 870.131 210.031 871.3 208.902 870.435C208.588 870.194 208.392 869.976 208.261 869.763L206.838 871.62Z" fill="white"/>
                    <path d="M234.852 876.608C232.303 874.788 229.031 875.281 226.877 877.459L225.383 876.392L228.334 872.262L226.196 870.734L218.871 880.987L221.01 882.514L224.013 878.311L225.492 879.368C224.157 882.196 224.598 885.387 227.234 887.271C230.12 889.332 233.905 888.431 236.008 885.487C238.111 882.543 237.737 878.67 234.852 876.608ZM228.647 885.294C227.036 884.143 226.766 881.981 228.231 879.931C229.706 877.865 231.828 877.435 233.439 878.586C235.065 879.747 235.345 881.894 233.87 883.959C232.405 886.01 230.273 886.455 228.647 885.294Z" fill="white"/>
                    <path d="M232.507 893.833L234.532 895.176L235.955 893.031L243.14 897.796L241.718 899.941L243.743 901.284L246.449 897.203L245.009 896.248L250.688 887.682L243.308 882.788L242.203 884.453C239.846 888.009 238.133 889.616 236.248 890.439L235.212 889.753L232.507 893.833ZM238.633 892.021C241.036 890.612 242.727 888.321 244.17 886.146L247.245 888.185L242.848 894.816L238.633 892.021Z" fill="white"/>
                    <path d="M246.37 899.906L250.919 902.749C253.498 904.361 255.99 903.838 257.288 901.762C258.585 899.687 257.972 897.202 255.393 895.59L253.042 894.121L255.246 890.595L253.048 889.221L246.37 899.906ZM249.78 899.341L251.878 895.983L254.107 897.376C255.374 898.168 255.771 899.265 255.093 900.349C254.426 901.417 253.275 901.526 252.008 900.734L249.78 899.341Z" fill="white"/>
                    <path d="M258.135 907.233L260.373 908.539L264.72 901.093L265.228 906.079L266.471 906.805L271.016 904.769L266.669 912.215L268.876 913.504L275.229 902.622L273.021 901.334L267.234 903.999L266.71 897.649L264.487 896.351L258.135 907.233Z" fill="white"/>
                    <path d="M271.954 915.238L273.868 916.279L283.239 911.972L279.291 919.229L281.552 920.459L287.574 909.391L285.613 908.325L276.33 912.619L280.253 905.409L277.976 904.17L271.954 915.238Z" fill="white"/>
                    <path d="M71.9334 746.567C69.3017 748.086 65.9374 747.182 64.4532 744.539C9.56334 646.777 -10.8398 533.381 6.53039 422.618C7.00007 419.623 9.83824 417.602 12.8346 418.109L70.0223 427.782C73.0154 428.288 75.0338 431.129 74.5679 434.138C59.8718 529.06 77.3463 626.179 124.208 710.025C125.693 712.683 124.792 716.049 122.163 717.567L71.9334 746.567Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M28.2933 532.878L28.5739 535.454L38.7915 534.342L39.374 539.692L29.1564 540.805L29.435 543.364L41.961 542L40.8193 531.514L28.2933 532.878Z" fill="white"/>
                    <path d="M29.7893 546.251L30.135 548.82L35.0764 548.155L35.4653 551.045C35.8326 553.774 37.6387 555.184 39.8329 554.888C42.0271 554.593 43.3786 552.759 43.0113 550.029L42.2768 544.57L29.7893 546.251ZM37.2707 547.859L40.3747 547.442L40.7395 550.153C40.9028 551.366 40.4684 552.188 39.4516 552.324C38.4526 552.459 37.7987 551.784 37.6355 550.571L37.2707 547.859Z" fill="white"/>
                    <path d="M31.3277 557.582L31.6726 559.733L40.8082 564.52L32.6505 565.829L33.0581 568.371L45.4991 566.375L45.1456 564.171L36.0747 559.446L44.1791 558.146L43.7686 555.587L31.3277 557.582Z" fill="white"/>
                    <path d="M34.1057 575.514C34.7313 578.84 36.8629 580.435 39.0034 580.033C40.6485 579.723 41.3831 578.468 41.576 577.351C42.0131 578.019 42.9889 578.825 44.3687 578.565C46.4384 578.176 47.5462 576.081 47.0038 573.198C46.7077 571.623 46.008 570.143 45.3153 569.285L42.9626 569.727C43.6199 570.592 44.325 571.907 44.6278 573.517C44.8973 574.949 44.5861 575.924 43.7546 576.08C42.9055 576.24 42.2727 575.7 42.0165 574.337L41.6671 572.48L39.5267 572.883L39.8195 574.439C40.1256 576.067 39.7846 577.175 38.7763 577.365C37.6441 577.578 36.7623 576.59 36.4096 574.714C36.15 573.335 36.3359 571.596 36.8903 570.356L34.4137 570.822C33.898 572.073 33.7297 573.515 34.1057 575.514Z" fill="white"/>
                    <path d="M35.7749 582.955L36.3183 585.489L41.7215 584.331L42.914 589.892L37.5108 591.051L38.0505 593.568L50.3704 590.926L49.8308 588.409L45.2196 589.398L44.0271 583.836L48.6383 582.848L48.0948 580.313L35.7749 582.955Z" fill="white"/>
                    <path d="M38.508 595.67L39.1532 598.31L42.2807 598.825L43.5456 604L41.0078 605.899L41.6616 608.574L52.6894 600.32L52.0997 597.907L38.508 595.67ZM44.5538 599.103L49.2429 599.865L45.4298 602.687L44.5538 599.103Z" fill="white"/>
                    <path d="M42.1599 610.447L42.8467 612.947L48.1752 611.482L49.6823 616.967L44.3538 618.431L45.0359 620.913L57.1855 617.575L56.5035 615.093L51.9561 616.342L50.4489 610.858L54.9964 609.608L54.3096 607.109L42.1599 610.447Z" fill="white"/>
                    <path d="M45.6618 623.107L46.2952 625.191L55.9956 628.695L48.0907 631.098L48.8393 633.561L60.8947 629.897L60.2456 627.761L50.6178 624.31L58.471 621.923L57.7172 619.443L45.6618 623.107Z" fill="white"/>
                    <path d="M49.9111 636.905L52.9019 645.869L55.1046 645.134L52.9341 638.629L56.0076 637.603L57.7052 642.691L59.8737 641.968L58.1761 636.88L60.4812 636.111L62.589 642.428L64.7916 641.693L61.8635 632.917L49.9111 636.905Z" fill="white"/>
                    <path d="M13.5919 413.725C10.5992 413.198 8.6031 410.342 9.16531 407.363C29.9574 297.191 87.2172 197.21 171.721 123.525C174.006 121.533 177.479 121.81 179.448 124.124L217.039 168.293C219.007 170.605 218.727 174.078 216.436 176.084C144.163 239.352 95.1223 324.982 77.1251 419.334C76.5545 422.325 73.7001 424.324 70.7107 423.797L13.5919 413.725Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M53.6279 393.123C53.9536 391.85 53.9565 390.531 53.6264 389.425L51.0979 388.778C51.6153 389.951 51.6217 391.16 51.305 392.398C50.6982 394.77 48.5994 396.147 46.2452 395.544C43.9085 394.946 42.7025 392.761 43.2915 390.46C43.6395 389.099 44.2336 388.229 45.0541 387.492L42.5256 386.845C41.7789 387.657 41.2407 388.671 40.9328 389.875C39.9646 393.659 41.8576 397.153 45.5894 398.108C49.3211 399.063 52.6686 396.872 53.6279 393.123Z" fill="white"/>
                    <path d="M42.5868 384.517L44.8219 385.146L45.8998 381.317L55.7933 384.102L56.4957 381.607L46.6022 378.822L47.685 374.975L45.4499 374.346L42.5868 384.517Z" fill="white"/>
                    <path d="M57.2934 378.67L58.0922 376.072L55.67 374.028L57.2358 368.936L60.388 368.605L61.1974 365.973L47.498 367.411L46.768 369.785L57.2934 378.67ZM53.8606 372.625L50.2246 369.567L54.945 369.098L53.8606 372.625Z" fill="white"/>
                    <path d="M61.625 364.691L63.3624 359.54C64.3174 356.709 63.2667 354.473 60.9812 353.703C58.7128 352.937 56.4888 354.068 55.5338 356.899L54.6248 359.594L52.7146 358.95L54.8087 352.741L52.6085 351.999L49.6859 360.664L61.625 364.691ZM60.2874 361.504L56.6715 360.284L57.5172 357.777C57.989 356.379 58.936 355.767 60.0958 356.158C61.2556 356.55 61.6048 357.598 61.1331 358.997L60.2874 361.504Z" fill="white"/>
                    <path d="M65.6421 352.837L66.3936 350.793L60.7696 342.147L68.5241 344.998L69.4123 342.582L57.5863 338.234L56.8161 340.329L62.4143 348.889L54.7104 346.057L53.816 348.489L65.6421 352.837Z" fill="white"/>
                    <path d="M69.8886 340.961C70.1126 340.74 70.3066 340.353 70.4337 340.035C71.5444 337.261 70.1178 335.643 64.7472 333.318L62.4011 332.301L63.8196 328.759L73.3611 332.579L74.3179 330.19L62.6208 325.506L59.3154 333.761L62.7209 335.182C67.2561 337.076 68.7198 337.972 68.1912 339.292C68.044 339.66 67.8868 339.907 67.7163 340.091L69.8886 340.961Z" fill="white"/>
                    <path d="M75.4998 327.325L77.6272 322.401C78.8336 319.609 77.9424 317.224 75.6951 316.253C73.4479 315.282 71.0839 316.261 69.8774 319.053L68.7781 321.598L64.9611 319.949L63.9331 322.328L75.4998 327.325ZM74.4293 324.039L70.794 322.469L71.8362 320.056C72.4287 318.685 73.4538 318.128 74.627 318.635C75.7836 319.134 76.064 320.255 75.4715 321.627L74.4293 324.039Z" fill="white"/>
                    <path d="M80.4611 315.886L81.5527 313.535L76.5406 311.208L78.9361 306.049L83.9482 308.376L85.0322 306.041L73.6041 300.735L72.5201 303.069L76.7975 305.055L74.402 310.214L70.1246 308.228L69.033 310.579L80.4611 315.886Z" fill="white"/>
                    <path d="M86.7783 297.552C85.789 299.533 83.4791 300.251 81.3534 299.189C79.2278 298.127 78.4137 295.85 79.4029 293.869C80.3921 291.888 82.7021 291.171 84.8278 292.232C86.9534 293.294 87.7675 295.572 86.7783 297.552ZM88.9522 298.638C90.6411 295.256 89.2468 291.482 86.01 289.865C82.7571 288.241 78.9179 289.402 77.229 292.783C75.54 296.165 76.9183 299.932 80.1712 301.556C83.408 303.173 87.2633 302.02 88.9522 298.638Z" fill="white"/>
                    <path d="M95.9083 285.212C96.5286 284.053 96.8463 282.774 96.79 281.621L94.4892 280.388C94.7115 281.651 94.4289 282.827 93.8256 283.953C92.6699 286.111 90.3031 286.947 88.1609 285.8C86.0346 284.661 85.3854 282.251 86.5071 280.157C87.1699 278.919 87.9546 278.216 88.9275 277.696L86.6266 276.464C85.7076 277.074 84.9427 277.931 84.3564 279.025C82.5125 282.469 83.5161 286.314 86.9118 288.133C90.3076 289.951 94.0813 288.623 95.9083 285.212Z" fill="white"/>
                    <path d="M87.1586 274.356L89.179 275.5L91.1396 272.039L100.083 277.105L101.36 274.849L92.417 269.784L94.3865 266.307L92.3661 265.162L87.1586 274.356Z" fill="white"/>
                    <path d="M104.248 269.801L107.009 265.202C108.575 262.594 108.007 260.112 105.908 258.852C103.81 257.592 101.337 258.249 99.7711 260.857L98.3443 263.233L94.7794 261.093L93.4453 263.316L104.248 269.801ZM103.622 266.402L100.227 264.364L101.58 262.111C102.349 260.83 103.439 260.413 104.534 261.071C105.615 261.72 105.744 262.868 104.975 264.149L103.622 266.402Z" fill="white"/>
                    <path d="M113.038 255.369L114.226 253.543L110.67 243.862L117.595 248.369L118.999 246.212L108.439 239.338L107.221 241.209L110.771 250.8L103.892 246.323L102.478 248.495L113.038 255.369Z" fill="white"/>
                    <path d="M122.9 240.438L124.392 238.319L119.638 234.972L127.511 233.889L129.417 231.18L120.233 232.375L118.586 224.678L116.762 227.268L118.151 233.925L114.089 231.065L112.597 233.185L122.9 240.438Z" fill="white"/>
                    <path d="M132.249 223.705C130.923 225.479 128.522 225.774 126.619 224.352C124.716 222.929 124.32 220.543 125.646 218.769C126.971 216.996 129.372 216.701 131.275 218.123C133.178 219.546 133.574 221.932 132.249 223.705ZM134.195 225.16C136.458 222.133 135.757 218.17 132.859 216.004C129.947 213.827 125.962 214.287 123.699 217.315C121.436 220.342 122.123 224.294 125.035 226.471C127.933 228.637 131.932 228.188 134.195 225.16Z" fill="white"/>
                    <path d="M139.357 217.887L140.974 215.862L134.235 210.483L139.242 210.702L140.14 209.577L138.783 204.785L145.521 210.164L147.116 208.166L137.268 200.306L135.674 202.304L137.474 208.416L131.115 208.016L129.509 210.027L139.357 217.887Z" fill="white"/>
                    <path d="M152.387 202.633L154.08 200.646L153.024 199.747C155.562 196.517 154.844 193.306 152.775 191.544C150.706 189.782 147.407 189.574 144.637 192.605L143.76 191.858L142.068 193.846L142.945 194.592C140.394 197.81 141.125 201.033 143.194 202.795C145.264 204.558 148.548 204.754 151.332 201.734L152.387 202.633ZM149.551 200.217C148.062 201.882 146.319 201.957 144.921 200.767C143.523 199.577 143.307 197.832 144.726 196.109L149.551 200.217ZM151.243 198.23L146.419 194.122C147.893 192.446 149.65 192.382 151.048 193.572C152.446 194.762 152.649 196.496 151.243 198.23Z" fill="white"/>
                    <path d="M162.288 187.313C160.797 188.95 158.379 189.012 156.622 187.412C154.866 185.812 154.702 183.399 156.193 181.762C157.684 180.125 160.102 180.064 161.858 181.663C163.615 183.263 163.779 185.677 162.288 187.313ZM164.084 188.95C166.63 186.155 166.315 182.143 163.64 179.707C160.952 177.259 156.942 177.331 154.396 180.126C151.851 182.921 152.152 186.92 154.841 189.369C157.515 191.805 161.539 191.744 164.084 188.95Z" fill="white"/>
                    <path d="M168.743 183.498L170.536 181.627L166.937 178.177L168.954 176.072C170.86 174.083 170.837 171.792 169.239 170.26C167.64 168.728 165.363 168.815 163.458 170.803L159.646 174.779L168.743 183.498ZM165.338 176.645L163.077 174.477L164.97 172.502C165.817 171.619 166.73 171.446 167.471 172.156C168.199 172.854 168.078 173.786 167.231 174.669L165.338 176.645Z" fill="white"/>
                    <path d="M167.834 166.263L169.471 167.91L172.292 165.105L179.539 172.392L181.377 170.564L174.13 163.277L176.963 160.459L175.325 158.812L167.834 166.263Z" fill="white"/>
                    <path d="M183.846 121.253C181.893 118.925 182.199 115.454 184.544 113.534C271.289 42.5019 379.419 2.71785 491.515 0.589987C494.546 0.532451 497.029 2.97671 497.05 6.0155L497.455 64.0141C497.476 67.0496 495.029 69.5306 491.985 69.5943C395.953 71.6048 303.344 105.678 228.91 166.387C226.55 168.312 223.079 168.009 221.128 165.683L183.846 121.253Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M266.631 121.483C267.737 120.773 268.637 119.81 269.148 118.775L267.737 116.579C267.318 117.79 266.499 118.68 265.424 119.371C263.364 120.695 260.89 120.273 259.576 118.229C258.272 116.2 258.877 113.779 260.876 112.494C262.057 111.735 263.084 111.503 264.187 111.521L262.776 109.326C261.677 109.412 260.592 109.788 259.547 110.46C256.261 112.571 255.268 116.419 257.35 119.66C259.433 122.9 263.376 123.576 266.631 121.483Z" fill="white"/>
                    <path d="M275.781 115.439L278.009 114.115L275.462 109.829L277.969 108.339C280.336 106.932 280.926 104.718 279.795 102.815C278.663 100.911 276.446 100.387 274.079 101.794L269.344 104.608L275.781 115.439ZM274.331 107.925L272.731 105.233L275.083 103.835C276.135 103.21 277.061 103.288 277.585 104.17C278.1 105.036 277.735 105.902 276.683 106.528L274.331 107.925Z" fill="white"/>
                    <path d="M287.283 108.96C288.287 108.395 289.476 107.168 289.269 104.414L288.48 93.54L286.08 94.8903L286.684 101.655L280.872 97.8202L278.377 99.2234L286.85 104.556C286.942 106.115 286.655 106.669 285.761 107.172C285.431 107.358 284.854 107.579 284.322 107.589L285.443 109.581C285.927 109.598 286.733 109.269 287.283 108.96Z" fill="white"/>
                    <path d="M295.972 103.797L298.263 102.584L295.542 97.4461L303.051 100.048L305.978 98.4983L297.249 95.4012L299.269 87.793L296.47 89.2757L294.691 95.8395L292.366 91.4492L290.075 92.6623L295.972 103.797Z" fill="white"/>
                    <path d="M311.97 93.0741C309.984 94.0541 307.71 93.2295 306.659 91.0989C305.607 88.9683 306.335 86.6617 308.32 85.6817C310.306 84.7017 312.58 85.5264 313.631 87.657C314.683 89.7875 313.955 92.0942 311.97 93.0741ZM313.045 95.2531C316.435 93.58 317.606 89.73 316.004 86.4858C314.395 83.2254 310.634 81.8296 307.245 83.5027C303.855 85.1759 302.677 89.0097 304.286 92.2701C305.887 95.5144 309.656 96.9263 313.045 95.2531Z" fill="white"/>
                    <path d="M320.917 91.1406L326.676 88.4991C329.114 87.3809 330.044 85.3503 329.159 83.4197C328.348 81.6527 326.69 81.1459 325.432 81.4258C325.992 80.8325 326.399 79.7346 325.829 78.4912C324.958 76.5933 323.059 76.2959 321.047 77.2189L315.664 79.6879L320.917 91.1406ZM322.343 88.0312L320.969 85.0372L323.931 83.6789C325.076 83.1536 326.043 83.3633 326.486 84.3286C326.929 85.2939 326.449 86.1476 325.304 86.6729L322.343 88.0312ZM320.076 83.0902L318.921 80.5706L321.571 79.3549C322.553 78.9046 323.352 79.0926 323.727 79.9106C324.087 80.6959 323.708 81.4242 322.727 81.8745L320.076 83.0902Z" fill="white"/>
                    <path d="M336.109 81.9513C334.073 82.8199 331.848 81.8706 330.916 79.6851C329.984 77.4996 330.839 75.2368 332.875 74.3682C334.912 73.4996 337.136 74.4489 338.069 76.6344C339.001 78.8199 338.146 81.0827 336.109 81.9513ZM337.063 84.1864C340.54 82.7035 341.922 78.9243 340.502 75.5963C339.076 72.2518 335.399 70.65 331.922 72.133C328.445 73.616 327.056 77.3786 328.482 80.7231C329.901 84.0511 333.586 85.6694 337.063 84.1864Z" fill="white"/>
                    <path d="M344.672 83.4484L346.935 82.5626L345.997 80.1657L354.025 77.0229L354.964 79.4198L357.226 78.534L355.442 73.9749L353.833 74.6048L350.086 65.0339L341.84 68.262L342.568 70.1226C344.123 74.0951 344.453 76.4206 344.044 78.4365L342.887 78.8893L344.672 83.4484ZM346.709 77.3933C346.937 74.6173 346.034 71.9165 345.083 69.486L348.519 68.141L351.419 75.5496L346.709 77.3933Z" fill="white"/>
                    <path d="M359.171 75.0597L361.222 74.3271L364.257 64.4697L367.036 72.2503L369.46 71.3844L365.222 59.5187L363.12 60.2695L360.134 70.0518L357.373 62.3221L354.932 63.194L359.171 75.0597Z" fill="white"/>
                    <path d="M366.048 59.2288L366.777 61.4334L370.554 60.1845L373.781 69.9428L376.242 69.129L373.015 59.3707L376.809 58.1161L376.08 55.9115L366.048 59.2288Z" fill="white"/>
                    <path d="M381.542 67.3958L390.583 64.6425L389.906 62.4213L383.346 64.4193L382.402 61.3199L387.533 59.7571L386.867 57.5703L381.736 59.1331L381.028 56.8085L387.399 54.8681L386.722 52.6468L377.871 55.3424L381.542 67.3958Z" fill="white"/>
                    <path d="M390.721 64.4495C391.032 64.494 391.458 64.4133 391.787 64.322C394.667 63.5241 395.089 61.4085 393.683 55.7276L393.07 53.2452L396.747 52.2262L399.492 62.1309L401.973 61.4436L398.608 49.3011L390.039 51.6757L390.972 55.2461C392.215 60.0009 392.389 61.7084 391.019 62.0881C390.637 62.1938 390.345 62.2187 390.096 62.1945L390.721 64.4495Z" fill="white"/>
                    <path d="M405.887 60.4053L415.058 58.1276L414.499 55.8741L407.843 57.527L407.062 54.3825L412.268 53.0897L411.717 50.8711L406.511 52.1639L405.925 49.8055L412.389 48.2004L411.829 45.9468L402.85 48.1767L405.887 60.4053Z" fill="white"/>
                    <path d="M416.056 57.8552L418.587 57.2984L416.735 48.8777L420.489 52.1991L421.895 51.8897L423.855 47.3118L425.707 55.7326L428.204 55.1835L425.497 42.8776L423.001 43.4266L420.553 49.3097L415.863 44.9964L413.35 45.5493L416.056 57.8552Z" fill="white"/>
                    <path d="M272.73 85.6806C270.794 86.7538 268.483 86.0381 267.332 83.9599C266.18 81.8817 266.798 79.5431 268.734 78.4699C270.671 77.3967 272.981 78.1124 274.133 80.1905C275.285 82.2687 274.667 84.6073 272.73 85.6806ZM273.908 87.806C277.215 85.9736 278.201 82.0724 276.447 78.9079C274.685 75.7277 270.862 74.5122 267.556 76.3445C264.25 78.1768 263.255 82.0623 265.017 85.2426C266.771 88.4071 270.602 89.6383 273.908 87.806Z" fill="white"/>
                    <path d="M274.107 73.0298L275.181 75.0887L278.708 73.2492L283.461 82.3624L285.759 81.1638L281.006 72.0506L284.549 70.2029L283.476 68.144L274.107 73.0298Z" fill="white"/>
                    <path d="M290.341 78.7825L292.669 77.6426L290.239 72.6797L295.347 70.1782L297.778 75.1411L300.089 74.009L294.548 62.693L292.236 63.825L294.31 68.0604L289.202 70.562L287.128 66.3265L284.8 67.4665L290.341 78.7825Z" fill="white"/>
                    <path d="M306.696 68.5529C304.681 69.4696 302.434 68.5733 301.451 66.4105C300.467 64.2478 301.268 61.9654 303.283 61.0487C305.299 60.132 307.545 61.0283 308.529 63.191C309.513 65.3538 308.712 67.6362 306.696 68.5529ZM307.703 70.7648C311.143 69.1998 312.435 65.3888 310.937 62.0955C309.432 58.7858 305.718 57.2717 302.277 58.8368C298.836 60.4018 297.537 64.1964 299.042 67.5061C300.54 70.7994 304.262 72.3299 307.703 70.7648Z" fill="white"/>
                    <path d="M315.86 66.7861L330.464 60.7471L325.65 49.1033L323.255 50.0937L327.182 59.5918L323.456 61.1325L319.529 51.6344L317.15 52.618L321.077 62.116L317.368 63.6498L313.44 54.1518L311.045 55.1422L315.86 66.7861Z" fill="white"/>
                    <path d="M332.116 60.1324L340.954 56.7854L340.131 54.6139L333.718 57.0429L332.57 54.0129L337.586 52.1131L336.777 49.9753L331.76 51.8751L330.9 49.6026L337.128 47.2438L336.306 45.0723L327.653 48.3492L332.116 60.1324Z" fill="white"/>
                    <path d="M343.715 55.7615L346.163 54.9089L344.345 49.6904L349.717 47.8194L351.534 53.038L353.965 52.1913L349.821 40.2924L347.39 41.1391L348.941 45.5927L343.57 47.4636L342.018 43.01L339.571 43.8626L343.715 55.7615Z" fill="white"/>
                    <path d="M355.477 51.6707L357.553 51.0098L360.929 41.264L363.436 49.1364L365.888 48.3554L362.065 36.3494L359.938 37.0267L356.615 46.6992L354.124 38.8782L351.654 39.6647L355.477 51.6707Z" fill="white"/>
                    <path d="M367.764 47.751L370.636 46.9258L372.793 41.0058L374.903 40.3993L376.31 45.2952L378.801 44.5793L375.322 32.4694L369.716 34.0801C367.121 34.8258 365.975 36.7659 366.576 38.8592C367.038 40.4681 368.318 41.4675 370.066 41.4896L367.764 47.751ZM371.326 39.1611C370.202 39.4842 369.378 39.1591 369.105 38.2076C368.836 37.2734 369.357 36.5432 370.481 36.2201L373.457 35.365L374.302 38.306L371.326 39.1611Z" fill="white"/>
                    <path d="M500.5 5.99995C500.5 2.96109 502.965 0.499554 505.997 0.535921C618.106 1.88089 726.511 40.9091 813.75 111.334C816.109 113.238 816.439 116.706 814.502 119.048L777.532 163.737C775.597 166.076 772.127 166.404 769.754 164.496C694.897 104.308 602.052 70.8816 506.009 69.5419C502.964 69.4994 500.5 67.0355 500.5 64L500.5 5.99995Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M512.179 50.6733L514.352 50.8178L521.06 42.9835L520.512 51.2273L523.081 51.398L523.917 38.8258L521.689 38.6777L515.056 46.4629L515.601 38.273L513.015 38.1011L512.179 50.6733Z" fill="white"/>
                    <path d="M531.098 52.0869L533.676 52.3536L534.189 47.394L537.09 47.694C539.829 47.9773 541.623 46.5523 541.851 44.3501C542.079 42.1478 540.613 40.4037 537.873 40.1204L532.394 39.5538L531.098 52.0869ZM534.417 45.1918L534.739 42.0764L537.461 42.3578C538.678 42.4838 539.375 43.0987 539.269 44.1192C539.165 45.1219 538.356 45.5991 537.139 45.4732L534.417 45.1918Z" fill="white"/>
                    <path d="M542.677 53.3146L552.051 54.5109L552.345 52.2076L545.542 51.3394L545.953 48.1255L551.273 48.8045L551.563 46.5369L546.242 45.8579L546.55 43.4474L553.156 44.2906L553.45 41.9872L544.272 40.816L542.677 53.3146Z" fill="white"/>
                    <path d="M552.338 54.5239L555.024 54.9365L556.694 52.2428L561.961 53.0515L562.746 56.1222L565.468 56.5402L562.054 43.1954L559.599 42.8184L552.338 54.5239ZM557.82 50.2485L560.317 46.2066L561.467 50.8086L557.82 50.2485Z" fill="white"/>
                    <path d="M566.448 56.5904C566.708 56.7662 567.127 56.8793 567.463 56.9409C570.403 57.4786 571.705 55.759 572.917 50.0337L573.448 47.5324L577.202 48.2191L575.352 58.3293L577.884 58.7925L580.152 46.3983L571.405 44.798L570.688 48.418C569.733 53.2388 569.145 54.8514 567.746 54.5955C567.357 54.5242 567.083 54.4192 566.869 54.2886L566.448 56.5904Z" fill="white"/>
                    <path d="M579.712 59.1374L581.843 59.587L589.59 52.7774L587.884 60.8615L590.403 61.3928L593.004 49.0641L590.82 48.6034L583.155 55.3751L584.849 47.3438L582.313 46.8088L579.712 59.1374Z" fill="white"/>
                    <path d="M597.68 63.3465C600.971 64.134 603.298 62.8399 603.805 60.7217C604.194 59.0937 603.349 57.9103 602.408 57.2781C603.197 57.1522 604.331 56.5906 604.657 55.2252C605.147 53.177 603.688 51.3101 600.834 50.6274C599.276 50.2546 597.639 50.2886 596.573 50.5701L596.016 52.8984C597.074 52.6519 598.562 52.5452 600.155 52.9263C601.573 53.2656 602.335 53.9477 602.138 54.7705C601.937 55.6107 601.185 55.9676 599.837 55.6451L597.999 55.2053L597.492 57.3235L599.033 57.6921C600.643 58.0775 601.516 58.8414 601.277 59.8392C601.009 60.9596 599.747 61.3609 597.891 60.917C596.526 60.5903 595.015 59.7106 594.11 58.6981L593.523 61.1489C594.454 62.1306 595.702 62.8732 597.68 63.3465Z" fill="white"/>
                    <path d="M603.338 64.414L605.964 65.1132L607.914 62.6148L613.063 63.9855L613.513 67.1228L616.175 67.8313L614.216 54.1966L611.816 53.5576L603.338 64.414ZM609.248 60.7533L612.165 57.0035L612.814 61.7026L609.248 60.7533Z" fill="white"/>
                    <path d="M625.953 73.3977L628.299 74.0945L629.693 69.4011L628.019 68.9041L630.945 59.0513L628.46 58.3135L625.535 68.1662L620.479 66.6649L623.405 56.8122L620.92 56.0743L617.333 68.153L626.685 70.9302L625.953 73.3977Z" fill="white"/>
                    <path d="M631.904 72.5285L633.972 73.211L642.424 67.2995L639.836 75.1455L642.28 75.952L646.228 63.9865L644.108 63.2871L635.742 69.1699L638.314 61.3752L635.852 60.563L631.904 72.5285Z" fill="white"/>
                    <path d="M644.381 76.6619L647.191 77.6785L652.411 74.1499L654.476 74.8971L652.743 79.6872L655.18 80.569L659.467 68.7207L653.983 66.7365C651.444 65.8179 649.377 66.7163 648.636 68.7643C648.067 70.3385 648.517 71.8986 649.919 72.9421L644.381 76.6619ZM652.306 71.7956C651.206 71.3976 650.729 70.651 651.066 69.72C651.397 68.806 652.247 68.5201 653.347 68.9182L656.258 69.9715L655.217 72.849L652.306 71.7956Z" fill="white"/>
                    <path d="M662.498 83.3713L664.9 84.345L668.761 74.8197L673.749 76.8415L669.888 86.3667L672.274 87.3336L677.007 75.6565L667.232 71.6941L662.498 83.3713Z" fill="white"/>
                    <path d="M680.721 88.6104C678.693 87.7207 677.862 85.4491 678.817 83.2734C679.772 81.0977 682.007 80.1717 684.034 81.0615C686.061 81.9512 686.892 84.2228 685.938 86.3984C684.983 88.5741 682.748 89.5002 680.721 88.6104ZM679.744 90.8356C683.206 92.3547 686.907 90.7748 688.361 87.4618C689.822 84.1324 688.472 80.3554 685.011 78.8363C681.549 77.3172 677.855 78.8806 676.394 82.2101C674.94 85.523 676.283 89.3165 679.744 90.8356Z" fill="white"/>
                    <path d="M690.824 81.6597L689.837 83.7616L693.438 85.452L689.071 94.7559L691.417 95.8573L695.785 86.5534L699.402 88.2514L700.389 86.1495L690.824 81.6597Z" fill="white"/>
                    <path d="M697.435 98.7117L705.892 102.929L706.928 100.851L700.791 97.7906L702.237 94.8912L707.037 97.2852L708.057 95.2395L703.257 92.8455L704.342 90.6709L710.302 93.6433L711.338 91.5654L703.059 87.4362L697.435 98.7117Z" fill="white"/>
                    <path d="M707.186 103.579L709.476 104.794L712.067 99.9133L717.091 102.58L714.5 107.461L716.773 108.668L722.681 97.5386L720.407 96.3318L718.196 100.497L713.172 97.8305L715.383 93.665L713.094 92.4497L707.186 103.579Z" fill="white"/>
                    <path d="M727.462 117.641L729.589 118.854L732.013 114.6L730.496 113.736L735.587 104.807L733.335 103.523L728.245 112.452L723.663 109.84L728.753 100.911L726.501 99.6273L720.261 110.573L728.737 115.405L727.462 117.641Z" fill="white"/>
                    <path d="M732.252 117.449L734.112 118.581L743.681 114.733L739.386 121.791L741.585 123.129L748.136 112.366L746.229 111.206L736.749 115.045L741.016 108.033L738.802 106.686L732.252 117.449Z" fill="white"/>
                    <path d="M742.074 123.404L744.357 124.879L746.983 123.104L751.458 125.996L750.918 129.119L753.231 130.614L755.574 117.04L753.488 115.692L742.074 123.404ZM748.826 121.745L752.757 119.078L751.925 123.748L748.826 121.745Z" fill="white"/>
                    <path d="M754.149 131.083C754.314 131.35 754.65 131.625 754.932 131.818C757.394 133.512 759.286 132.475 762.734 127.747L764.242 125.682L767.386 127.844L761.562 136.313L763.683 137.771L770.823 127.39L763.496 122.351L761.361 125.361C758.517 129.369 757.321 130.6 756.149 129.794C755.823 129.57 755.616 129.362 755.475 129.155L754.149 131.083Z" fill="white"/>
                    <path d="M765.617 139.111L767.811 140.715L770.535 139.094L774.836 142.238L774.118 145.325L776.342 146.95L779.46 133.533L777.454 132.067L765.617 139.111ZM772.453 137.842L776.53 135.405L775.432 140.02L772.453 137.842Z" fill="white"/>
                    <path d="M531.466 29.1285L534.05 29.3375L534.879 19.093L540.243 19.5271L539.414 29.7716L541.98 29.9792L542.996 17.4202L532.483 16.5695L531.466 29.1285Z" fill="white"/>
                    <path d="M544.227 30.1897L546.804 30.4646L547.333 25.5067L550.233 25.8159C552.971 26.108 554.77 24.6887 555.004 22.4872C555.239 20.2857 553.778 18.5369 551.04 18.2449L545.563 17.6608L544.227 30.1897ZM547.568 23.3052L547.9 20.1909L550.621 20.481C551.838 20.6108 552.532 21.2279 552.423 22.2481C552.316 23.2504 551.505 23.7251 550.288 23.5953L547.568 23.3052Z" fill="white"/>
                    <path d="M562.035 30.0646C559.84 29.7703 558.413 27.8173 558.729 25.4624C559.045 23.1075 560.936 21.5995 563.13 21.8937C565.325 22.188 566.752 24.141 566.436 26.4959C566.12 28.8508 564.229 30.3588 562.035 30.0646ZM561.712 32.473C565.458 32.9754 568.578 30.4335 569.058 26.8476C569.542 23.2438 567.2 19.9877 563.453 19.4853C559.707 18.9829 556.59 21.507 556.106 25.1107C555.625 28.6967 557.965 31.9706 561.712 32.473Z" fill="white"/>
                    <path d="M575.449 34.7373L578.024 35.1662L578.252 33.799C582.327 34.3137 584.711 32.0468 585.158 29.3657C585.605 26.6847 584.087 23.7493 580.062 22.9328L580.251 21.7965L577.677 21.3676L577.487 22.5039C573.416 21.9715 571.028 24.2562 570.581 26.9372C570.134 29.6183 571.656 32.5359 575.677 33.3701L575.449 34.7373ZM576.062 31.0619C573.869 30.6419 572.907 29.186 573.209 27.375C573.511 25.564 574.895 24.4808 577.103 24.8121L576.062 31.0619ZM578.636 31.4909L579.677 25.241C581.873 25.6433 582.832 27.1169 582.53 28.9279C582.229 30.739 580.847 31.8044 578.636 31.4909Z" fill="white"/>
                    <path d="M587.406 36.3259L596.676 38.1591L597.127 35.8812L590.399 34.5508L591.028 31.3724L596.29 32.4129L596.733 30.1704L591.471 29.1298L591.942 26.7459L598.476 28.0379L598.926 25.7601L589.85 23.9652L587.406 36.3259Z" fill="white"/>
                    <path d="M603.937 40.0166C605.219 40.302 606.537 40.2634 607.633 39.8987L608.2 37.3511C607.044 37.9051 605.835 37.9496 604.588 37.672C602.198 37.1401 600.756 35.0857 601.284 32.7138C601.808 30.3594 603.954 29.0852 606.273 29.6014C607.644 29.9065 608.532 30.473 609.295 31.2698L609.862 28.7221C609.027 28.0014 607.996 27.4954 606.784 27.2256C602.971 26.3769 599.538 28.3789 598.701 32.1389C597.864 35.8989 600.159 39.1757 603.937 40.0166Z" fill="white"/>
                    <path d="M617.015 43.1494C618.289 43.4693 619.607 43.4662 620.712 43.1311L621.348 40.5996C620.177 41.1224 618.968 41.1343 617.728 40.8232C615.354 40.2272 613.968 38.1347 614.559 35.7778C615.147 33.4384 617.326 32.2225 619.631 32.8009C620.993 33.1427 621.865 33.7329 622.606 34.55L623.242 32.0186C622.426 31.2756 621.41 30.742 620.205 30.4397C616.416 29.4887 612.931 31.3975 611.993 35.1336C611.055 38.8697 613.261 42.2072 617.015 43.1494Z" fill="white"/>
                    <path d="M622.692 44.338L624.79 44.9249L632.962 38.6322L630.735 46.5886L633.214 47.2822L636.609 35.1483L634.46 34.5468L626.372 40.807L628.584 32.9026L626.088 32.2041L622.692 44.338Z" fill="white"/>
                    <path d="M641.559 47.5466C639.445 46.8895 638.364 44.7254 639.069 42.4564C639.775 40.1875 641.891 39.0169 644.006 39.674C646.12 40.331 647.2 42.4951 646.495 44.7641C645.79 47.0331 643.673 48.2036 641.559 47.5466ZM640.838 49.8671C644.448 50.9889 647.948 49.0043 649.022 45.5493C650.101 42.0771 648.336 38.4752 644.727 37.3534C641.117 36.2316 637.622 38.199 636.543 41.6712C635.469 45.1262 637.228 48.7453 640.838 49.8671Z" fill="white"/>
                    <path d="M648.61 52.0406L651.062 52.8799L652.852 47.6517L658.233 49.4936L656.444 54.7218L658.879 55.5554L662.959 43.6343L660.524 42.8008L658.997 47.2626L653.615 45.4207L655.143 40.9589L652.69 40.1195L648.61 52.0406Z" fill="white"/>
                    <path d="M660.693 56.1694L663.239 57.1224L665.424 54.827L670.414 56.695L670.555 59.8614L673.134 60.8269L672.52 47.0661L670.193 46.1951L660.693 56.1694ZM666.934 53.1049L670.204 49.6586L670.39 54.3987L666.934 53.1049Z" fill="white"/>
                    <path d="M673.977 61.0324C674.196 61.2577 674.582 61.454 674.899 61.5829C677.666 62.7098 679.293 61.2925 681.649 55.9356L682.68 53.5954L686.214 55.0345L682.338 64.5536L684.722 65.5243L689.474 53.8546L681.238 50.5013L679.797 53.8985C677.877 58.4225 676.972 59.881 675.655 59.3447C675.288 59.1954 675.042 59.0368 674.859 58.8652L673.977 61.0324Z" fill="white"/>
                    <path d="M687.529 66.7107L692.441 68.8664C695.227 70.0889 697.617 69.2115 698.601 66.9699C699.584 64.7283 698.619 62.3586 695.834 61.1361L693.296 60.0221L694.967 56.2147L692.593 55.173L687.529 66.7107ZM690.822 65.6591L692.413 62.033L694.819 63.0891C696.188 63.6895 696.739 64.7177 696.225 65.888C695.719 67.0418 694.596 67.3157 693.228 66.7153L690.822 65.6591Z" fill="white"/>
                    <path d="M698.724 71.645L701.068 72.7502L703.424 67.7516L708.57 70.1768L706.213 75.1753L708.542 76.2728L713.914 64.8754L711.586 63.778L709.575 68.0438L704.43 65.6187L706.441 61.3528L704.096 60.2477L698.724 71.645Z" fill="white"/>
                    <path d="M721.528 82.8136L723.837 83.9925L729.567 72.7709L727.259 71.5921L721.528 82.8136ZM711.445 77.6644L715.837 79.9075C718.563 81.2992 720.984 80.555 722.097 78.3748C723.211 76.1946 722.402 73.7807 719.677 72.3891L717.593 71.3249L719.484 67.6218L717.175 66.4429L711.445 77.6644ZM714.793 76.8074L716.594 73.2806L718.55 74.2793C719.88 74.9588 720.37 76.0175 719.789 77.1557C719.216 78.2779 718.079 78.4856 716.749 77.8061L714.793 76.8074Z" fill="white"/>
                    <path d="M725.368 84.8208L727.277 85.8693L736.665 81.6L732.688 88.8416L734.944 90.0807L741.01 79.0369L739.054 77.9624L729.754 82.2191L733.705 75.0248L731.433 73.777L725.368 84.8208ZM736.543 75.8031C738.152 76.6869 739.96 76.3862 740.912 74.691L739.24 73.7724C738.843 74.458 738.118 74.6555 737.376 74.2483C736.635 73.841 736.429 73.1322 736.81 72.438L735.138 71.5195C734.233 73.2407 734.918 74.9105 736.543 75.8031Z" fill="white"/>
                    <path d="M741.755 93.9915L743.98 95.3214L746.538 91.0417L749.041 92.5379C751.405 93.9509 753.633 93.4163 754.769 91.516C755.905 89.6156 755.311 87.4156 752.948 86.0025L748.22 83.1764L741.755 93.9915ZM747.674 89.1414L749.281 86.4531L751.629 87.8569C752.68 88.4849 753.052 89.3363 752.525 90.2169C752.008 91.0821 751.073 91.1732 750.022 90.5452L747.674 89.1414Z" fill="white"/>
                    <path d="M757.948 101.335C756.079 100.148 755.602 97.7763 756.876 95.7706C758.149 93.7649 760.499 93.1884 762.368 94.3753C764.236 95.5622 764.714 97.9335 763.44 99.9393C762.166 101.945 759.817 102.521 757.948 101.335ZM756.645 103.386C759.836 105.412 763.734 104.412 765.673 101.358C767.623 98.2884 766.861 94.3504 763.67 92.324C760.479 90.2976 756.591 91.2827 754.642 94.3521C752.702 97.4062 753.454 101.359 756.645 103.386Z" fill="white"/>
                    <path d="M768.657 111.259C769.747 111.993 770.988 112.439 772.141 112.499L773.599 110.335C772.321 110.428 771.179 110.028 770.119 109.314C768.089 107.946 767.497 105.507 768.855 103.492C770.202 101.492 772.666 101.089 774.636 102.417C775.8 103.201 776.42 104.053 776.84 105.073L778.298 102.909C777.784 101.933 777.009 101.085 775.979 100.391C772.74 98.209 768.813 98.8189 766.66 102.014C764.508 105.208 765.448 109.097 768.657 111.259Z" fill="white"/>
                    <path d="M779.456 103.025L778.113 104.919L781.358 107.22L775.413 115.604L777.528 117.103L783.473 108.719L786.732 111.031L788.075 109.136L779.456 103.025Z" fill="white"/>
                    <path d="M641.579 906.553L641.414 906.081L641.579 906.553C644.454 905.552 647.613 907.024 648.651 909.877L668.488 964.379C669.528 967.235 668.053 970.391 665.192 971.394C559.384 1008.47 444.168 1008.88 338.103 972.535C335.235 971.553 333.739 968.407 334.758 965.544L354.214 910.905C355.232 908.045 358.38 906.551 361.263 907.532L361.424 907.059L361.263 907.532C452.191 938.488 550.869 938.143 641.579 906.553Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M453.316 970.183L459.634 970.661C462.308 970.864 464.106 969.537 464.266 967.42C464.413 965.481 463.208 964.235 461.972 963.87C462.749 963.622 463.637 962.859 463.74 961.495C463.898 959.413 462.38 958.233 460.173 958.066L454.268 957.619L453.316 970.183ZM456.069 968.153L456.318 964.868L459.567 965.114C460.823 965.209 461.568 965.861 461.488 966.92C461.408 967.979 460.574 968.494 459.318 968.399L456.069 968.153ZM456.48 962.732L456.689 959.968L459.597 960.188C460.674 960.27 461.282 960.821 461.214 961.719C461.149 962.58 460.464 963.034 459.387 962.952L456.48 962.732Z" fill="white"/>
                    <path d="M464.762 970.956C465.044 971.096 465.474 971.153 465.815 971.17C468.8 971.315 469.864 969.439 470.311 963.604L470.507 961.054L474.318 961.24L473.818 971.506L476.389 971.631L477.002 959.046L468.121 958.613L467.887 962.296C467.576 967.201 467.206 968.877 465.786 968.808C465.391 968.788 465.105 968.72 464.876 968.619L464.762 970.956Z" fill="white"/>
                    <path d="M480.297 971.784L482.475 971.828L488.815 963.692L488.648 971.952L491.221 972.004L491.476 959.407L489.244 959.362L482.978 967.445L483.144 959.239L480.552 959.186L480.297 971.784Z" fill="white"/>
                    <path d="M491.936 972.038L494.924 972.019L498.598 966.9L500.794 966.885L500.828 971.979L503.42 971.962L503.336 959.362L497.504 959.401C494.804 959.419 493.177 960.978 493.191 963.156C493.202 964.83 494.165 966.138 495.842 966.63L491.936 972.038ZM497.684 964.728C496.514 964.736 495.808 964.201 495.802 963.211C495.795 962.239 496.493 961.676 497.663 961.668L500.759 961.648L500.78 964.707L497.684 964.728Z" fill="white"/>
                    <path d="M507.31 971.908L509.9 971.817L509.706 966.294L515.39 966.095L515.584 971.617L518.157 971.527L517.715 958.935L515.142 959.025L515.308 963.738L509.623 963.938L509.458 959.225L506.867 959.316L507.31 971.908Z" fill="white"/>
                    <path d="M521.599 971.371L523.773 971.232L529.409 962.595L529.934 970.84L532.503 970.677L531.702 958.102L529.475 958.244L523.907 966.823L523.385 958.631L520.798 958.796L521.599 971.371Z" fill="white"/>
                    <path d="M534.737 970.506L544.15 969.668L543.944 967.355L537.113 967.964L536.825 964.736L542.168 964.26L541.965 961.983L536.622 962.459L536.407 960.039L543.04 959.448L542.834 957.135L533.619 957.956L534.737 970.506Z" fill="white"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_501_7321">
                    <rect width="1000" height="1000" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
                `,
                width: 390,
                alignment: 'center',
                absolutePosition: {
                    x: 0,
                    y: 60
                }
            },
            {
                text: 'Мотивационный опросник разработан на основе прикладной модели мотивации и включает в себя 9 универсальных факторов, наиболее полным образом отвечающих реалиям рабочей среды. Данные факторы представлены на «Диаграмме мотивационного профиля» в соответствии с их влиянием на рабочую мотивацию участника.',
                alignment: 'justify',
                margin: [20, 430, 20, 0],
                color: '#2B3D4F'
            },
            {
                pageBreak: 'before',
                margin: [0, 20, 0, 0],
                table: {
                    headerRows: 1,
                    widths: ['*'],
                    body: dropLast(5, DRIVER_PLUS_RESULTS).map(item => ([{
                        layout: 'noBorders',
                        table: {
                            headerRows: 0,
                            width: [400, '*'],
                            body: [
                                [{
                                    columns: [
                                        { text: item.title, fontSize: 16, bold: true, color: '#2B3D4F' },
                                        (pathOr(0, ['integralScores', 'blocks', item.id], data) ? {
                                            svg: DRIVER_PLUS_RESULT_IMG[pathOr(0, ['integralScores', 'blocks', item.id], data)],
                                            width: 280
                                        } : { text: '' })
                                    ],
                                    margin: [10, 5, 10, 5]
                                }],
                                [{ text: item.description, margin: [10, 0, 10, 5], fontSize: 11, color: '#666', alignment: 'justify', }]
                            ]
                        }
                    }]))
                }
            },
            {
                pageBreak: 'before',
                margin: [0, 20, 0, 0],
                table: {
                    headerRows: 1,
                    widths: ['*'],
                    body: drop(4, DRIVER_PLUS_RESULTS).map(item => ([{
                        layout: 'noBorders',
                        table: {
                            headerRows: 0,
                            width: [400, '*'],
                            body: [
                                [{
                                    columns: [
                                        { text: item.title, fontSize: 16, bold: true, color: '#2B3D4F' },
                                        (pathOr(0, ['integralScores', 'blocks', item.id], data) ? {
                                            svg: DRIVER_PLUS_RESULT_IMG[pathOr(0, ['integralScores', 'blocks', item.id], data)],
                                            width: 280
                                        } : { text: '' })
                                    ],
                                    margin: [10, 5, 10, 5]
                                }],
                                [{ text: item.description, margin: [10, 0, 10, 5], fontSize: 11, color: '#666', alignment: 'justify', }]
                            ]
                        }
                    }]))
                }
            }
        ],
        footer: currentPage => currentPage === 1 ? undefined : ({
            columns: [
                {
                    svg: `<svg width="80px" height="50px" viewBox="0 0 80 50">
                        <polygon stroke="#E71D32" stroke-width="1px" fill="#E71D32" points="0,50 80,0 80,50" />
                    </svg>`,
                    margin: [0, 0, 0, 0],
                    alignment: 'right',
                },
                {
                    width: 140,
                    layout: 'noBorders',
                    table: {
                        widths: [140],
                        heights: [50],
                        body: [
                            [
                                {
                                    alignment: 'right',
                                    fillColor: '#E71D32',
                                    margin: [0, 15, 5, 0],
                                    svg: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="115px" height="22px" viewBox="0 0 115 22" version="1.1">
                                        <title>logo</title>
                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g id="logo" fill="#FFFFFF" fill-rule="nonzero">
                                                <path d="M106.773,0.388235 L103.214,5.5 L103.214,0.388235 L96.7954,0.388235 L96.7954,21.5471 L103.214,21.5471 L103.214,7.95883 L109.061,7.95883 L114.4,0.388235 L106.773,0.388235 Z M82.2408,15.9176 C79.508,15.9176 77.5378,13.8471 77.5378,11 C77.5378,8.15294 79.5715,6.01765 82.2408,6.01765 C84.9102,6.01765 86.944,8.15294 86.944,11 C87.0072,13.8471 84.9738,15.9176 82.2408,15.9176 Z M82.2408,0 C76.0125,0 71.1187,4.85294 71.1187,11 C71.1187,17.1471 76.0125,22 82.2408,22 C88.4693,22 93.3634,17.1471 93.3634,11 C93.4266,4.85294 88.5326,0 82.2408,0 Z M62.0938,15.9176 C59.1702,15.9176 57.1365,13.9117 57.1365,11 C57.1365,8.15294 59.2338,6.01765 61.9667,6.01765 C63.3648,6.01765 64.7631,6.6 65.78,7.63529 L69.2755,2.65294 C67.3053,0.970587 64.6995,0 61.9667,0 C55.6747,0 50.7173,4.85294 50.7173,11 C50.7173,17.2765 55.5475,22 61.9667,22 C65.0173,22 67.5595,21.0941 69.6568,19.2176 L66.1613,14.1706 C65.208,15.0765 63.8098,15.9176 62.0938,15.9176 Z M39.0867,0 C37.2435,0 34.1929,0.905881 33.1125,2.26471 L33.1125,0.388235 L26.884,0.388235 L26.884,21.5471 L33.3031,21.5471 L33.3031,8.15295 C34.0022,7.24706 35.6547,6.01765 37.6249,6.01765 C38.7053,6.01765 39.6587,6.34117 40.2307,6.98824 C40.7391,7.50588 41.2475,8.28235 41.2475,10.3529 L41.2475,21.5471 L47.6667,21.5471 L47.6667,7.95883 C47.6667,2.65294 43.8533,0 39.0867,0 Z M11.1222,15.9176 C8.38933,15.9176 6.41911,13.8471 6.41911,11 C6.41911,8.15294 8.45288,6.01765 11.1222,6.01765 C13.7915,6.01765 15.8253,8.15294 15.8253,11 C15.8253,13.8471 13.8551,15.9176 11.1222,15.9176 Z M15.5711,2.26471 C14.2365,0.647059 12.2662,0 10.296,0 C4.51245,0 0,4.85294 0,11 C0,13.7824 0.953333,16.3706 2.66933,18.3765 C4.576,20.6412 7.56311,21.9353 10.6138,21.9353 C12.4569,21.9353 14.5542,21.1588 15.6347,19.7353 L15.6347,21.5471 L21.8631,21.5471 L21.8631,0.388235 L15.6347,0.388235 L15.6347,2.26471 L15.5711,2.26471 Z" id="Shape"/>
                                            </g>
                                        </g>
                                    </svg>`
                                }
                            ]
                        ]
                    }
                }
            ],
        }),
    };

    window.pdfMake.createPdf(docDefinition).download(`Driver-Plus-(n)-${respondent.lastName}.pdf`);
}

export const downloadDriverPlusIpsativeRespondentPdf = (respondent, data) => {
    const motivations = DRIVER_PLUS_IPSATIVE_RESULTS
        .filter(item => pathOr(0, ['integralScores', 'blocks', item.id], data) >= 8);
    const factors = DRIVER_PLUS_IPSATIVE_RESULTS
        .filter(item => pathOr(0, ['integralScores', 'blocks', item.id], data) <= 4);

    const docDefinition = {
        styles: {
            font: 'Geometria Light'
        },
        pageOrientation: 'landscape',
        pageMargins: [0, 55, 0, 50],
        header: currentPage => ({
            columns: [
                (currentPage === 1 ? { text: '' } : {
                    width: 148,
                    layout: 'noBorders',
                    table: {
                        layout: {
                            hLineWidth: () => 0,
                            vLineWidth: () => 0,
                            paddingTop: () => 0,
                            paddingBottom: () =>  0,
                            paddingLeft: () => 0,
                            paddingRight: () => 0
                        },
                        widths: [148],
                        heights: [51],
                        body: [
                            [
                                {
                                    text: [
                                        { text: 'Driver+ (i)\n', bold: true, fontSize: currentPage === 1 ? 28 : 18, },
                                        { text: ` ${respondent.firstName} ${respondent.lastName} `, fontSize: 11 }
                                    ],
                                    margin: [15, 5, 0, 0],
                                    fillColor: '#2c3d4f',
                                    style: {
                                        color: '#fff'
                                    },
                                }
                            ]
                        ]
                    },
                }),
                {
                    svg: currentPage === 1 ? `<svg width="2000" height="224" viewBox="0 0 2000 224" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="2000" height="190" fill="#2B3D4F"/>
                        <path d="M264.271 106.828C257.908 106.828 253.281 102.005 253.281 95.4508C253.281 88.8945 257.908 84.0755 264.271 84.0755C270.441 84.0755 275.26 88.8961 275.26 95.4508C275.26 102.007 270.633 106.826 264.271 106.826V106.828ZM264.271 70C249.81 70 238.434 81.1837 238.434 95.4508C238.434 109.719 249.81 120.71 264.271 120.71C278.732 120.71 290.107 109.526 290.107 95.4508C290.107 81.1821 278.732 70 264.271 70V70ZM98.8372 106.828C92.4741 106.828 87.8467 102.005 87.8467 95.4508C87.8467 88.8945 92.4741 84.0755 98.8372 84.0755C105.007 84.0755 109.826 88.8961 109.826 95.4508C109.826 102.007 105.199 106.826 98.8372 106.826V106.828ZM109.248 75.2054C105.97 71.5425 101.537 70 96.9083 70C83.6041 70 73 81.1837 73 95.4508C73 101.814 75.1205 107.791 79.1699 112.418C83.6041 117.625 90.5452 120.708 97.6795 120.708C101.922 120.708 106.741 118.781 109.248 115.696V119.746H123.71V70.9645H109.248V75.2054V75.2054ZM338.886 70.9645H321.34L313.049 82.7262V70.9645H298.203V119.746H313.049V88.5097H326.738L338.886 70.9645ZM217.418 106.828C210.668 106.828 205.849 102.199 205.849 95.4508C205.849 88.8945 210.668 84.0755 217.031 84.0755C220.352 84.0915 223.535 85.4062 225.9 87.7384L233.998 76.1699C229.237 72.1946 223.234 70.0117 217.031 70C202.378 70 190.809 81.1837 190.809 95.4508C190.809 109.913 201.993 120.71 216.838 120.71C223.972 120.71 229.757 118.588 234.77 114.347L226.672 102.585C224.55 104.706 221.272 106.826 217.418 106.826V106.828ZM163.813 70C159.57 70 152.436 72.1205 149.93 75.2054V70.9645H135.468V119.746H150.315V88.8961C152.049 86.774 155.907 84.0738 160.534 84.0738C163.041 84.0738 165.162 84.8467 166.704 86.196C167.86 87.352 169.016 89.2809 169.016 93.9083V119.746H183.863V88.5097C183.863 76.1699 174.805 70 163.814 70H163.813Z" fill="white"/>
                        <path d="M0 190H719.5L696 224H0V190Z" fill="#F54D2E"/>
                    </svg>
                    ` : `<svg width="80px" height="55px" viewBox="0 0 80 55">
                        <polygon stroke="#2c3d4f" stroke-width="1px" fill="#2c3d4f" points="0,0 80,0 0,55" />
                    </svg>`,
                    width: currentPage === 1 ? 900 : undefined,
                    margin: [0, 0, 0, 0],
                    alignment: 'left',
                }
            ],
        }),
        content: [
            {
                text: 'Мотивационный опросник\nDriver+ (i)',
                alignment: 'left',
                color: '#F54D2E',
                margin: [20, 150, 20, 0],
                style: {
                    fontSize: 40,
                    bold: true
                }
            },
            {
                text: 'Отчет для сотрудника',
                alignment: 'left',
                color: '#a2a2a2',
                margin: [20, 20, 20, 0],
                style: {
                    fontSize: 24,
                    bold: true
                }
            },
            {
                svg: `<svg width="800" height="1" viewBox="0 0 800 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="800" height="1" fill="#2B3D4F"/>
                </svg>`,
                width: 780,
                margin: [20, 30, 20, 30]
            },
            {
                text: [
                    { text: 'Имя: ', color: '#2B3D4F', style: { fontSize: 16, bold: true }},
                    { text: `${respondent.lastName} ${respondent.firstName} ${respondent.middleName || ''}`, color: '#2B3D4F', style: { fontSize: 16 }}
                ],
                color: '#2B3D4F',
                margin: [20, 0, 20, 0],
            },
            {
                text: [
                    { text: 'Email: ', color: '#2B3D4F', style: { fontSize: 16, bold: true }},
                    { text: respondent.email, color: '#2B3D4F', style: { fontSize: 16 }}
                ],
                color: '#2B3D4F',
                margin: [20, 5, 20, 0],
            },
            {
                text: [
                    { text: 'Дата прохождения опросника: ', color: '#2B3D4F', style: { fontSize: 16, bold: true }},
                    { text: moment(path(['response', 'finishedAt'], respondent)).format('DD.MM.YYYY'), color: '#2B3D4F', style: { fontSize: 16 }}
                ],
                color: '#2B3D4F',
                margin: [20, 5, 20, 0],
            },
            {
                pageBreak: 'before',
                text: 'Что показывает DRIVER+',
                margin: [20, 50, 20, 0],
                color: '#2B3D4F',
                style: {
                    bold: true,
                    fontSize: 25
                }
            },
            {
                text: 'Опросник DRIVER\u002B определяет структуру мотивации человека, выявляет факторы, которые стимулируют работать с полной отдачей. Оценка мотивационных аспектов может решить проблемы, связанные с высокой текучестью кадров и низкой вовлеченностью персонала. ',
                alignment: 'justify',
                margin: [20, 30, 20, 0],
                color: '#2B3D4F'
            },
            {
                text: 'Данный отчёт описывает факторы, влияющие на рабочую мотивацию. В нём представлена детальная информация о факторах, оказывающих на Вашу работу наибольшее и наименьшее влияние. ',
                alignment: 'justify',
                margin: [20, 20, 20, 0],
                color: '#2B3D4F'
            },
            {
                svg: `<svg width="800" height="1" viewBox="0 0 800 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="800" height="1" fill="#2B3D4F"/>
                </svg>`,
                width: 780,
                margin: [20, 20, 20, 20]
            },
            {
                text: 'Данные, представленные в отчёте, полностью конфиденциальны и сохраняют свою актуальность, как правило, в течение года со дня заполнения опросника. Если за это время в личной жизни или работе произошли значительные перемены, мотивационный опросник необходимо будет заполнить заново для получения более актуальной и достоверной информации о мотивации. ',
                alignment: 'justify',
                margin: [20, 0, 20, 0],
                color: '#666'
            },
            {
                pageBreak: 'before',
                svg: window.driverChart.chart.getSVG(),
                width: 400,
                alignment: 'center',
                absolutePosition: {
                    x: 0,
                    y: 58
                }
            },
            {
                svg: `
                <svg width="1000" height="1000" viewBox="0 0 1000 1000" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_501_7321)">
                    <mask id="path-1-inside-1_501_7321" fill="white">
                    <path d="M1000 500C1000 776.142 776.142 1000 500 1000C223.858 1000 0 776.142 0 500C0 223.858 223.858 0 500 0C776.142 0 1000 223.858 1000 500ZM70 500C70 737.482 262.518 930 500 930C737.482 930 930 737.482 930 500C930 262.518 737.482 70 500 70C262.518 70 70 262.518 70 500Z"/>
                    </mask>
                    <path d="M1000 500C1000 776.142 776.142 1000 500 1000C223.858 1000 0 776.142 0 500C0 223.858 223.858 0 500 0C776.142 0 1000 223.858 1000 500ZM70 500C70 737.482 262.518 930 500 930C737.482 930 930 737.482 930 500C930 262.518 737.482 70 500 70C262.518 70 70 262.518 70 500Z" stroke="white" stroke-width="80" mask="url(#path-1-inside-1_501_7321)"/>
                    <path d="M817.92 121.896C819.874 119.568 823.344 119.267 825.643 121.243C910.659 194.336 968.616 293.915 990.176 403.939C990.759 406.914 988.783 409.783 985.794 410.332L928.747 420.802C925.761 421.35 922.893 419.371 922.302 416.384C903.646 322.16 854.009 236.874 781.297 174.113L780.97 174.491L781.297 174.113C778.991 172.123 778.687 168.651 780.639 166.326L817.92 121.896Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M863.399 242.561L864.903 244.672L869.639 241.3L868.046 249.086L869.967 251.784L871.89 242.724L879.698 243.727L877.86 241.146L871.12 240.245L875.166 237.364L873.663 235.252L863.399 242.561Z" fill="white"/>
                    <path d="M875.394 255.933C874.159 254.096 874.674 251.732 876.646 250.407C878.617 249.081 881 249.496 882.236 251.333C883.471 253.17 882.956 255.534 880.984 256.86C879.012 258.185 876.63 257.77 875.394 255.933ZM873.378 257.289C875.487 260.426 879.458 261.074 882.461 259.055C885.478 257.027 886.361 253.114 884.252 249.977C882.143 246.84 878.187 246.182 875.169 248.211C872.167 250.229 871.269 254.152 873.378 257.289Z" fill="white"/>
                    <path d="M878.109 263.696C878.156 264.007 878.356 264.392 878.538 264.681C880.13 267.21 882.277 267.006 887.314 264.026L889.516 262.726L891.549 265.955L882.851 271.431L884.222 273.609L894.885 266.896L890.148 259.371L886.996 261.291C882.799 263.848 881.214 264.506 880.456 263.303C880.246 262.968 880.138 262.695 880.089 262.449L878.109 263.696Z" fill="white"/>
                    <path d="M884.898 274.44C884.936 274.752 885.125 275.142 885.3 275.436C886.823 278.006 888.976 277.86 894.091 275.017L896.326 273.776L898.272 277.059L889.43 282.299L890.742 284.513L901.582 278.089L897.048 270.44L893.846 272.275C889.582 274.718 887.98 275.333 887.255 274.11C887.053 273.769 886.953 273.494 886.911 273.247L884.898 274.44Z" fill="white"/>
                    <path d="M892.656 287.832L897.253 296.088L899.282 294.959L895.945 288.967L898.776 287.391L901.386 292.077L903.383 290.965L900.773 286.279L902.896 285.096L906.136 290.915L908.165 289.785L903.664 281.702L892.656 287.832Z" fill="white"/>
                    <path d="M897.991 297.452L899.201 299.743L908.289 294.943L911.324 300.689L913.378 299.604L909.132 291.566L897.991 297.452Z" fill="white"/>
                    <path d="M828.33 159.314C826.735 157.779 826.739 155.36 828.386 153.648C830.034 151.936 832.451 151.838 834.046 153.373C835.642 154.908 835.637 157.327 833.99 159.039C832.343 160.752 829.926 160.849 828.33 159.314ZM826.646 161.065C829.369 163.686 833.388 163.481 835.897 160.874C838.418 158.254 838.455 154.243 835.731 151.622C833.007 149.001 829.001 149.193 826.48 151.813C823.971 154.421 823.922 158.444 826.646 161.065Z" fill="white"/>
                    <path d="M840.965 157.046L839.313 158.678L842.108 161.508L834.795 168.731L836.616 170.575L843.929 163.353L846.737 166.196L848.389 164.564L840.965 157.046Z" fill="white"/>
                    <path d="M840.299 174.32L842.072 176.21L846.102 172.428L849.994 176.576L845.965 180.358L847.726 182.234L856.914 173.612L855.152 171.735L851.714 174.962L847.821 170.815L851.26 167.587L849.486 165.697L840.299 174.32Z" fill="white"/>
                    <path d="M854.996 187.079C853.527 185.422 853.724 183.011 855.502 181.435C857.281 179.86 859.698 179.955 861.166 181.612C862.634 183.269 862.438 185.68 860.659 187.255C858.881 188.831 856.464 188.736 854.996 187.079ZM853.177 188.691C855.684 191.52 859.706 191.635 862.414 189.236C865.135 186.825 865.492 182.829 862.985 180C860.478 177.171 856.469 177.044 853.748 179.455C851.04 181.854 850.67 185.861 853.177 188.691Z" fill="white"/>
                    <path d="M858.55 194.35L868.63 206.522L878.334 198.485L876.681 196.489L868.765 203.045L866.193 199.939L874.109 193.383L872.467 191.401L864.552 197.957L861.991 194.865L869.907 188.31L868.254 186.313L858.55 194.35Z" fill="white"/>
                    <path d="M870.63 209.079L876.424 216.545L878.258 215.121L874.053 209.703L876.613 207.717L879.902 211.954L881.708 210.553L878.419 206.315L880.339 204.825L884.422 210.087L886.256 208.663L880.584 201.354L870.63 209.079Z" fill="white"/>
                    <path d="M877.403 217.813L878.94 219.9L883.39 216.624L886.763 221.205L882.313 224.481L883.839 226.554L893.985 219.083L892.459 217.01L888.661 219.806L885.289 215.226L889.087 212.43L887.55 210.343L877.403 217.813Z" fill="white"/>
                    <path d="M885.63 229.038L886.871 230.828L897.179 231.195L890.389 235.902L891.855 238.018L902.21 230.839L900.939 229.005L890.717 228.666L897.462 223.99L895.986 221.86L885.63 229.038Z" fill="white"/>
                    <path d="M892.928 239.571L894.56 242.074L900.852 242.399L902.051 244.239L897.784 247.02L899.199 249.192L909.755 242.311L906.57 237.425C905.096 235.164 902.904 234.637 901.08 235.826C899.677 236.74 899.098 238.257 899.589 239.935L892.928 239.571ZM902.185 240.456C901.546 239.475 901.615 238.593 902.445 238.052C903.259 237.521 904.11 237.804 904.749 238.785L906.439 241.378L903.876 243.049L902.185 240.456Z" fill="white"/>
                    <path d="M903.414 255.899L904.533 257.767L914.794 258.818L907.706 263.064L909.029 265.272L919.838 258.797L918.691 256.882L908.514 255.866L915.555 251.647L914.223 249.424L903.414 255.899Z" fill="white"/>
                    <path d="M912.717 271.685L913.964 273.958L922.975 269.014L925.564 273.732L916.553 278.676L917.791 280.933L928.838 274.872L923.764 265.625L912.717 271.685Z" fill="white"/>
                    <path d="M923.741 287.646C922.732 285.675 923.523 283.389 925.638 282.306C927.752 281.223 930.069 281.917 931.079 283.888C932.088 285.858 931.297 288.144 929.182 289.227C927.067 290.31 924.75 289.616 923.741 287.646ZM921.578 288.753C923.301 292.118 927.168 293.232 930.388 291.582C933.625 289.925 934.965 286.144 933.242 282.78C931.518 279.416 927.668 278.294 924.431 279.951C921.211 281.6 919.855 285.389 921.578 288.753Z" fill="white"/>
                    <path d="M922.845 296.311L923.887 298.507L926.212 297.403L929.91 305.191L927.585 306.295L928.627 308.49L933.05 306.391L932.309 304.83L941.594 300.421L937.795 292.421L935.99 293.278C932.137 295.108 929.84 295.601 927.8 295.334L927.268 294.212L922.845 296.311ZM929.028 297.919C931.813 297.951 934.444 296.862 936.802 295.742L938.384 299.076L931.197 302.488L929.028 297.919Z" fill="white"/>
                    <path d="M929.057 309.39L930.033 311.615L932.391 310.582L935.853 318.478L933.495 319.512L934.471 321.737L938.955 319.771L938.261 318.189L947.674 314.062L944.118 305.951L942.288 306.753C938.381 308.466 936.071 308.89 934.04 308.561L933.541 307.424L929.057 309.39ZM935.189 311.183C937.972 311.299 940.635 310.289 943.025 309.241L944.507 312.62L937.22 315.815L935.189 311.183Z" fill="white"/>
                    <path d="M937.623 322.633L941.183 331.387L943.334 330.512L940.751 324.159L943.752 322.938L945.773 327.907L947.89 327.046L945.87 322.077L948.12 321.162L950.629 327.331L952.78 326.456L949.295 317.886L937.623 322.633Z" fill="white"/>
                    <path d="M941.919 333.257L942.838 335.68L947.5 333.912L948.534 336.639C949.511 339.214 951.59 340.177 953.66 339.392C955.73 338.607 956.63 336.514 955.654 333.939L953.701 328.789L941.919 333.257ZM949.571 333.127L952.499 332.017L953.469 334.575C953.903 335.719 953.666 336.618 952.707 336.981C951.765 337.339 950.975 336.83 950.541 335.685L949.571 333.127Z" fill="white"/>
                    <path d="M945.764 343.238L946.857 346.422L953.579 348.872L948.319 350.678L949.16 353.129L954.421 351.323L950.621 357.385L951.72 360.586L956.164 353.408L963.462 355.984L962.428 352.971L956.055 350.762L961.077 349.038L960.236 346.586L955.214 348.311L958.891 342.671L957.857 339.657L953.674 346.156L945.764 343.238Z" fill="white"/>
                    <path d="M952.057 361.812L952.821 364.289L958.377 362.577L954.416 369.467L955.391 372.632L960.066 364.637L967.162 368.044L966.23 365.017L960.114 362.042L964.862 360.579L964.099 358.102L952.057 361.812Z" fill="white"/>
                    <path d="M955.448 372.796L956.178 375.414L959.32 375.828L960.751 380.96L958.275 382.939L959.015 385.592L969.772 376.988L969.105 374.596L955.448 372.796ZM961.601 376.033L966.312 376.644L962.592 379.587L961.601 376.033Z" fill="white"/>
                    <path d="M986.582 414.71C989.575 414.183 992.427 416.183 992.918 419.175C1011.06 529.814 991.45 643.35 937.243 741.492C935.778 744.146 932.42 745.074 929.778 743.573L879.347 714.924C876.707 713.425 875.782 710.065 877.249 707.397C923.524 623.226 940.321 525.987 924.962 431.169C924.475 428.164 926.474 425.309 929.463 424.782L986.582 414.71Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M930.529 657.596L931.356 655.139L923.184 652.39L927.964 650.883L928.423 649.519L925.509 645.48L933.68 648.23L934.495 645.807L922.553 641.789L921.738 644.212L925.52 649.34L919.408 651.138L918.587 653.578L930.529 657.596Z" fill="white"/>
                    <path d="M935.228 643.57L936.017 640.969L933.587 638.934L935.133 633.835L938.284 633.493L939.083 630.858L925.389 632.347L924.669 634.724L935.228 643.57ZM931.772 637.537L928.124 634.494L932.843 634.006L931.772 637.537Z" fill="white"/>
                    <path d="M927.204 626.516L929.442 627.134L930.5 623.299L940.408 626.032L941.097 623.533L931.189 620.8L932.252 616.948L930.013 616.33L927.204 626.516Z" fill="white"/>
                    <path d="M942.311 619.072L944.621 609.908L942.37 609.341L940.693 615.991L937.551 615.198L938.863 609.997L936.646 609.438L935.335 614.64L932.978 614.045L934.607 607.588L932.355 607.02L930.093 615.991L942.311 619.072Z" fill="white"/>
                    <path d="M945.146 607.717L945.72 605.189L940.858 604.085L941.504 601.241C942.113 598.556 940.914 596.604 938.755 596.113C936.596 595.623 934.688 596.87 934.079 599.555L932.859 604.927L945.146 607.717ZM938.699 603.595L935.645 602.901L936.25 600.233C936.521 599.039 937.215 598.422 938.216 598.649C939.199 598.872 939.576 599.733 939.305 600.927L938.699 603.595Z" fill="white"/>
                    <path d="M947.861 595.642L948.286 593.506L941.387 585.838L949.49 587.45L949.993 584.926L937.635 582.467L937.199 584.656L944.059 592.243L936.009 590.641L935.503 593.183L947.861 595.642Z" fill="white"/>
                    <path d="M950.227 583.773L950.685 581.094L948.021 579.378L948.92 574.126L952.003 573.394L952.468 570.679L939.067 573.864L938.647 576.312L950.227 583.773ZM946.046 578.218L942.048 575.653L946.669 574.581L946.046 578.218Z" fill="white"/>
                    <path d="M952.471 570.006C952.637 569.739 952.733 569.316 952.781 568.977C953.201 566.019 951.431 564.786 945.662 563.803L943.141 563.372L943.678 559.594L953.854 561.039L954.216 558.491L941.741 556.719L940.491 565.523L944.136 566.095C948.992 566.857 950.626 567.38 950.426 568.788C950.371 569.18 950.277 569.458 950.155 569.677L952.471 570.006Z" fill="white"/>
                    <path d="M954.656 555.111L955.267 549.782C955.613 546.759 954.07 544.734 951.638 544.456C949.206 544.177 947.226 545.798 946.88 548.82L946.564 551.574L942.433 551.1L942.138 553.675L954.656 555.111ZM952.68 552.275L948.746 551.824L949.045 549.213C949.215 547.729 950.035 546.899 951.305 547.044C952.557 547.188 953.149 548.18 952.979 549.664L952.68 552.275Z" fill="white"/>
                    <path d="M956.088 542.387L956.314 539.805L950.809 539.323L951.305 533.657L956.81 534.138L957.034 531.574L944.482 530.476L944.257 533.04L948.956 533.451L948.46 539.118L943.762 538.707L943.536 541.289L956.088 542.387Z" fill="white"/>
                    <path d="M955.479 521.815C955.352 524.026 953.513 525.597 951.141 525.461C948.769 525.326 947.122 523.554 947.248 521.344C947.375 519.134 949.213 517.562 951.586 517.698C953.958 517.834 955.605 519.605 955.479 521.815ZM957.905 521.954C958.121 518.18 955.349 515.263 951.737 515.056C948.107 514.849 945.038 517.431 944.822 521.205C944.606 524.979 947.36 527.895 950.99 528.103C954.602 528.31 957.689 525.728 957.905 521.954Z" fill="white"/>
                    <path d="M958.096 513.238L958.381 503.793L956.06 503.722L955.853 510.577L952.615 510.479L952.777 505.118L950.492 505.049L950.33 510.41L947.901 510.337L948.102 503.68L945.781 503.61L945.501 512.857L958.096 513.238Z" fill="white"/>
                    <path d="M950.56 678.045L952.77 672.107C953.705 669.593 952.929 667.499 950.938 666.758C949.117 666.08 947.585 666.893 946.892 667.98C946.869 667.164 946.382 666.099 945.1 665.622C943.143 664.894 941.589 666.025 940.817 668.1L938.751 673.65L950.56 678.045ZM949.372 674.837L946.285 673.688L947.421 670.635C947.861 669.454 948.694 668.919 949.689 669.289C950.684 669.66 950.948 670.603 950.508 671.784L949.372 674.837ZM944.277 672.941L941.68 671.974L942.697 669.241C943.073 668.229 943.772 667.798 944.615 668.112C945.425 668.413 945.671 669.196 945.295 670.208L944.277 672.941Z" fill="white"/>
                    <path d="M955.045 659.186C954.332 661.282 952.14 662.305 949.89 661.54C947.641 660.775 946.527 658.628 947.239 656.532C947.952 654.436 950.144 653.413 952.394 654.178C954.643 654.943 955.757 657.09 955.045 659.186ZM957.345 659.968C958.562 656.389 956.671 652.838 953.245 651.673C949.803 650.502 946.156 652.171 944.939 655.75C943.722 659.329 945.596 662.875 949.039 664.045C952.464 665.21 956.128 663.547 957.345 659.968Z" fill="white"/>
                    <path d="M961.225 648.078C962.228 644.846 961.09 642.439 959.01 641.793C957.411 641.297 956.174 642.063 955.482 642.96C955.408 642.165 954.922 640.997 953.581 640.581C951.57 639.957 949.611 641.29 948.742 644.092C948.267 645.622 948.193 647.258 948.404 648.341L950.69 649.05C950.514 647.978 950.505 646.486 950.991 644.922C951.423 643.529 952.154 642.814 952.962 643.064C953.787 643.32 954.093 644.094 953.683 645.418L953.123 647.223L955.203 647.868L955.672 646.355C956.163 644.774 956.983 643.954 957.963 644.258C959.063 644.599 959.38 645.885 958.815 647.707C958.399 649.048 957.422 650.498 956.352 651.334L958.758 652.081C959.799 651.216 960.623 650.021 961.225 648.078Z" fill="white"/>
                    <path d="M963.237 640.703L963.942 638.209L958.624 636.706L960.172 631.232L965.489 632.735L966.189 630.258L954.064 626.831L953.364 629.308L957.902 630.591L956.355 636.064L951.817 634.781L951.112 637.276L963.237 640.703Z" fill="white"/>
                    <path d="M966.78 628.146L967.445 625.51L964.921 623.593L966.223 618.427L969.355 617.935L970.028 615.265L956.42 617.403L955.813 619.811L966.78 628.146ZM963.042 622.284L959.254 619.417L963.944 618.706L963.042 622.284Z" fill="white"/>
                    <path d="M970.432 613.45L971.002 610.921L960.975 608.661L962.404 602.322L960.139 601.812L958.14 610.679L970.432 613.45Z" fill="white"/>
                    <path d="M972.696 603.334L973.21 600.793L968.322 599.805L968.901 596.947C969.446 594.247 968.201 592.324 966.031 591.885C963.861 591.446 961.984 592.738 961.438 595.437L960.346 600.836L972.696 603.334ZM966.152 599.366L963.083 598.745L963.625 596.063C963.868 594.864 964.547 594.23 965.552 594.433C966.54 594.633 966.937 595.484 966.695 596.684L966.152 599.366Z" fill="white"/>
                    <path d="M974.933 592.287L975.4 589.61L972.741 587.885L973.657 582.636L976.743 581.914L977.216 579.201L963.805 582.343L963.378 584.79L974.933 592.287ZM970.77 586.719L966.78 584.141L971.404 583.084L970.77 586.719Z" fill="white"/>
                    <path d="M977.336 578.747L977.804 575.413L972.921 570.184L978.429 570.957L978.79 568.39L973.282 567.617L979.415 563.934L979.886 560.583L972.672 564.968L967.333 559.366L966.89 562.521L971.571 567.377L966.312 566.638L965.952 569.205L971.21 569.943L965.376 573.305L964.933 576.46L971.606 572.562L977.336 578.747Z" fill="white"/>
                    <path d="M982.45 560.593L982.707 558.177L980.148 557.904L981.062 549.33L983.622 549.603L983.88 547.187L979.011 546.668L978.828 548.386L968.608 547.296L967.669 556.102L969.655 556.314C973.897 556.766 976.113 557.546 977.713 558.839L977.581 560.074L982.45 560.593ZM978.017 555.993C975.658 554.511 972.846 554.066 970.25 553.789L970.642 550.12L978.553 550.964L978.017 555.993Z" fill="white"/>
                    <path d="M981.52 544.534L982.269 535.114L979.955 534.93L979.411 541.766L976.181 541.509L976.606 536.162L974.328 535.981L973.902 541.328L971.48 541.135L972.008 534.496L969.693 534.312L968.96 543.535L981.52 544.534Z" fill="white"/>
                    <path d="M982.471 532.233L982.606 529.644L977.088 529.355L977.386 523.675L982.905 523.964L983.039 521.394L970.457 520.734L970.322 523.304L975.031 523.551L974.733 529.231L970.024 528.984L969.888 531.573L982.471 532.233Z" fill="white"/>
                    <path d="M983.14 518.933L983.196 516.756L975.093 510.373L983.353 510.584L983.418 508.011L970.822 507.69L970.766 509.921L978.816 516.23L970.61 516.021L970.544 518.612L983.14 518.933Z" fill="white"/>
                    <path d="M983.45 504.597L983.436 495.147L981.114 495.15L981.124 502.008L977.884 502.013L977.876 496.649L975.59 496.653L975.598 502.017L973.168 502.02L973.158 495.36L970.836 495.364L970.85 504.616L983.45 504.597Z" fill="white"/>
                    <path d="M927.566 747.433C930.198 748.952 931.097 752.318 929.55 754.925C872.331 851.342 784.329 925.71 679.72 966.048C676.891 967.139 673.722 965.691 672.663 962.843L652.446 908.481C651.388 905.635 652.839 902.467 655.678 901.366C745.231 866.632 820.601 802.939 869.783 720.433C871.342 717.817 874.708 716.915 877.337 718.433L927.566 747.433Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M686.431 937.109C687.636 936.585 688.679 935.779 689.351 934.84L688.311 932.446C687.702 933.574 686.751 934.32 685.578 934.829C683.333 935.804 680.958 934.991 679.991 932.762C679.03 930.549 680.016 928.257 682.196 927.311C683.484 926.751 684.535 926.687 685.621 926.883L684.581 924.489C683.482 924.397 682.351 924.594 681.211 925.089C677.629 926.644 676.029 930.282 677.563 933.816C679.098 937.349 682.881 938.65 686.431 937.109Z" fill="white"/>
                    <path d="M697.478 929.451C695.473 930.391 693.216 929.521 692.208 927.37C691.199 925.218 691.974 922.927 693.978 921.987C695.983 921.047 698.24 921.918 699.248 924.069C700.257 926.22 699.482 928.512 697.478 929.451ZM698.509 931.652C701.932 930.047 703.18 926.222 701.644 922.946C700.101 919.654 696.369 918.182 692.947 919.787C689.524 921.391 688.269 925.201 689.812 928.493C691.348 931.769 695.087 933.256 698.509 931.652Z" fill="white"/>
                    <path d="M716.383 925.47L718.567 924.364L716.355 919.996L714.797 920.785L710.155 911.615L707.842 912.786L712.485 921.956L707.78 924.338L703.137 915.168L700.825 916.339L706.516 927.58L715.22 923.174L716.383 925.47Z" fill="white"/>
                    <path d="M719.422 921.011L721.336 919.972L722.821 909.765L726.763 917.026L729.025 915.798L723.014 904.725L721.052 905.789L719.605 915.914L715.689 908.7L713.411 909.937L719.422 921.011Z" fill="white"/>
                    <path d="M731.094 914.667L733.445 913.302L733.055 910.157L737.664 907.482L740.202 909.381L742.583 907.998L731.552 899.749L729.404 900.996L731.094 914.667ZM732.681 907.898L732.089 903.184L735.872 906.045L732.681 907.898Z" fill="white"/>
                    <path d="M742.051 908.206C742.363 908.162 742.749 907.965 743.04 907.786C745.583 906.217 745.399 904.067 742.465 899.004L741.184 896.791L744.431 894.787L749.829 903.534L752.019 902.182L745.403 891.459L737.835 896.128L739.727 899.297C742.246 903.517 742.89 905.108 741.679 905.855C741.342 906.063 741.069 906.168 740.823 906.214L742.051 908.206Z" fill="white"/>
                    <path d="M754.905 900.37L759.392 897.431C761.937 895.765 762.507 893.283 761.165 891.235C759.824 889.187 757.312 888.702 754.767 890.369L752.448 891.888L750.17 888.409L748.002 889.829L754.905 900.37ZM755.821 897.037L753.651 893.725L755.85 892.285C757.1 891.466 758.262 891.566 758.962 892.635C759.652 893.689 759.269 894.779 758.019 895.598L755.821 897.037Z" fill="white"/>
                    <path d="M765.585 893.36L767.715 891.882L764.564 887.343L769.236 884.099L772.387 888.639L774.502 887.171L767.318 876.82L765.203 878.288L767.892 882.162L763.219 885.405L760.53 881.531L758.401 883.009L765.585 893.36Z" fill="white"/>
                    <path d="M776.414 885.845L778.602 884.233L777.872 881.148L782.161 877.987L784.891 879.597L787.107 877.963L775.242 870.967L773.242 872.441L776.414 885.845ZM777.253 878.944L776.15 874.323L780.223 876.754L777.253 878.944Z" fill="white"/>
                    <path d="M787.904 877.37L790.258 875.529L790.041 869.232L791.771 867.879L794.909 871.892L796.951 870.295L789.189 860.37L784.595 863.962C782.468 865.626 782.131 867.854 783.473 869.57C784.504 870.888 786.065 871.336 787.694 870.702L787.904 877.37ZM787.99 868.071C787.069 868.791 786.183 868.799 785.573 868.019C784.974 867.253 785.184 866.381 786.105 865.66L788.544 863.753L790.429 866.164L787.99 868.071Z" fill="white"/>
                    <path d="M803.606 868.277L805.462 866.709L803.801 864.743L810.386 859.178L812.048 861.144L813.904 859.575L810.744 855.836L809.424 856.951L802.79 849.101L796.026 854.817L797.315 856.343C800.069 859.601 801.132 861.696 801.395 863.736L800.446 864.538L803.606 868.277ZM803.581 861.889C802.901 859.188 801.175 856.922 799.49 854.929L802.309 852.547L807.444 858.624L803.581 861.889Z" fill="white"/>
                    <path d="M815.024 855.168L822.065 848.866L820.517 847.136L815.407 851.709L813.246 849.295L817.243 845.718L815.718 844.014L811.721 847.592L810.101 845.781L815.063 841.339L813.514 839.609L806.621 845.78L815.024 855.168Z" fill="white"/>
                    <path d="M822.613 848.4L824.788 846.351L823.995 840.101L825.594 838.595L829.086 842.304L830.973 840.527L822.335 831.354L818.089 835.352C816.124 837.203 815.992 839.453 817.486 841.038C818.633 842.257 820.229 842.56 821.793 841.779L822.613 848.4ZM821.847 839.132C820.995 839.934 820.114 840.022 819.435 839.301C818.769 838.594 818.897 837.706 819.749 836.904L822.003 834.782L824.101 837.009L821.847 839.132Z" fill="white"/>
                    <path d="M824.177 829.584L825.811 831.234L828.637 828.434L835.871 835.736L837.712 833.912L830.479 826.61L833.318 823.798L831.683 822.148L824.177 829.584Z" fill="white"/>
                    <path d="M840.856 830.785L847.42 823.987L845.75 822.374L840.986 827.307L838.655 825.057L842.381 821.198L840.737 819.61L837.011 823.469L835.263 821.781L839.889 816.99L838.219 815.377L831.792 822.032L840.856 830.785Z" fill="white"/>
                    <path d="M848.677 822.542C848.964 822.415 849.283 822.121 849.513 821.868C851.53 819.664 850.766 817.646 846.559 813.578L844.722 811.799L847.298 808.984L854.881 815.922L856.618 814.023L847.322 805.518L841.32 812.078L844.006 814.609C847.583 817.979 848.636 819.334 847.677 820.383C847.409 820.675 847.175 820.851 846.951 820.963L848.677 822.542Z" fill="white"/>
                    <path d="M858.893 811.48L862.406 807.427C864.398 805.128 864.267 802.585 862.417 800.982C860.568 799.379 858.018 799.599 856.026 801.898L854.211 803.993L851.069 801.27L849.371 803.228L858.893 811.48ZM858.863 808.025L855.87 805.431L857.591 803.445C858.57 802.316 859.715 802.094 860.681 802.931C861.633 803.756 861.562 804.91 860.584 806.039L858.863 808.025Z" fill="white"/>
                    <path d="M867.271 801.775L868.915 799.771L864.643 796.266L868.251 791.869L872.523 795.374L874.155 793.384L864.414 785.392L862.782 787.382L866.428 790.373L862.82 794.771L859.174 791.779L857.53 793.783L867.271 801.775Z" fill="white"/>
                    <path d="M877.834 785.161C876.48 786.912 874.074 787.168 872.195 785.714C870.316 784.26 869.959 781.867 871.314 780.116C872.669 778.365 875.074 778.109 876.953 779.563C878.833 781.017 879.189 783.41 877.834 785.161ZM879.756 786.648C882.069 783.658 881.434 779.685 878.572 777.471C875.697 775.246 871.705 775.64 869.392 778.629C867.079 781.619 867.7 785.581 870.576 787.806C873.437 790.02 877.443 789.637 879.756 786.648Z" fill="white"/>
                    <path d="M889.137 774.134C889.911 773.072 890.402 771.849 890.505 770.699L888.396 769.162C888.442 770.443 888 771.568 887.248 772.601C885.806 774.579 883.346 775.081 881.383 773.65C879.433 772.229 879.122 769.753 880.522 767.833C881.349 766.698 882.223 766.11 883.258 765.729L881.149 764.191C880.155 764.669 879.279 765.412 878.548 766.416C876.247 769.572 876.711 773.519 879.824 775.788C882.937 778.057 886.858 777.262 889.137 774.134Z" fill="white"/>
                    <path d="M881.826 762.316L883.735 763.638L886 760.368L894.448 766.222L895.925 764.091L887.476 758.238L889.752 754.953L887.844 753.631L881.826 762.316Z" fill="white"/>
                    <path d="M898.809 759.899L901.758 755.418C903.43 752.876 902.966 750.373 900.921 749.027C898.876 747.682 896.378 748.236 894.706 750.777L893.182 753.093L889.708 750.807L888.284 752.972L898.809 759.899ZM898.324 756.477L895.016 754.3L896.461 752.105C897.282 750.857 898.388 750.485 899.456 751.188C900.508 751.881 900.59 753.033 899.769 754.281L898.324 756.477Z" fill="white"/>
                    <path d="M330.572 964.037C329.533 966.893 326.374 968.363 323.538 967.292C218.65 927.684 130.131 853.933 72.2401 757.918C70.6748 755.322 71.5504 751.95 74.1715 750.413L124.197 721.063C126.815 719.526 130.188 720.405 131.765 723.01C181.522 805.17 257.335 868.335 347.128 902.443C349.975 903.524 351.447 906.682 350.409 909.535L330.572 964.037Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M128.501 790.677C127.12 788.946 127.441 786.549 129.299 785.067C131.156 783.585 133.565 783.805 134.946 785.535C136.326 787.266 136.005 789.663 134.148 791.145C132.291 792.627 129.882 792.407 128.501 790.677ZM126.602 792.192C128.959 795.147 132.97 795.47 135.798 793.214C138.641 790.946 139.202 786.975 136.845 784.02C134.488 781.065 130.491 780.731 127.648 782.998C124.82 785.255 124.244 789.237 126.602 792.192Z" fill="white"/>
                    <path d="M131.757 798.199L135.26 802.355C137.186 804.64 139.642 804.9 141.486 803.345C143.317 801.802 143.503 799.315 141.577 797.03L139.744 794.856L141.285 793.556L145.508 798.566L147.284 797.069L141.39 790.078L131.757 798.199ZM135.175 798.707L138.093 796.248L139.798 798.271C140.749 799.399 140.781 800.526 139.845 801.315C138.909 802.104 137.832 801.859 136.88 800.731L135.175 798.707Z" fill="white"/>
                    <path d="M148.348 821.126L149.988 822.945L153.624 819.666L152.455 818.369L160.088 811.486L158.364 809.575L150.731 816.458L148.031 813.463L155.664 806.58L153.94 804.669L146.307 811.551L143.607 808.557L151.24 801.674L149.505 799.749L140.147 808.187L150.26 819.403L148.348 821.126Z" fill="white"/>
                    <path d="M153.294 822.635L159.841 829.449L161.515 827.84L156.764 822.895L159.1 820.65L162.817 824.518L164.465 822.934L160.749 819.066L162.501 817.383L167.115 822.185L168.79 820.576L162.379 813.905L153.294 822.635Z" fill="white"/>
                    <path d="M162.047 831.709L163.893 833.529L167.773 829.594L171.823 833.588L167.943 837.523L169.775 839.33L178.623 830.359L176.79 828.552L173.479 831.91L169.429 827.916L172.74 824.558L170.895 822.738L162.047 831.709Z" fill="white"/>
                    <path d="M171.229 840.745L172.82 842.232L182.972 840.411L177.331 846.447L179.212 848.205L187.815 838.999L186.184 837.475L176.122 839.306L181.726 833.309L179.832 831.539L171.229 840.745Z" fill="white"/>
                    <path d="M181.932 850.679L189.007 856.943L190.546 855.204L185.411 850.658L187.559 848.233L191.575 851.788L193.091 850.077L189.075 846.521L190.685 844.702L195.672 849.116L197.211 847.378L190.284 841.245L181.932 850.679Z" fill="white"/>
                    <path d="M199.201 865.859C200.215 866.696 201.407 867.259 202.548 867.431L204.209 865.418C202.928 865.387 201.831 864.879 200.845 864.065C198.957 862.507 198.604 860.022 200.15 858.148C201.685 856.288 204.176 856.126 206.009 857.638C207.091 858.532 207.626 859.439 207.945 860.496L209.606 858.482C209.188 857.461 208.499 856.543 207.541 855.752C204.529 853.266 200.561 853.493 198.109 856.464C195.658 859.435 196.216 863.396 199.201 865.859Z" fill="white"/>
                    <path d="M206.838 871.62C206.99 871.895 207.311 872.187 207.583 872.395C209.955 874.211 211.898 873.273 215.583 868.727L217.194 866.742L220.224 869.062L213.975 877.222L216.019 878.787L223.679 868.783L216.62 863.377L214.333 866.274C211.288 870.131 210.031 871.3 208.902 870.435C208.588 870.194 208.392 869.976 208.261 869.763L206.838 871.62Z" fill="white"/>
                    <path d="M234.852 876.608C232.303 874.788 229.031 875.281 226.877 877.459L225.383 876.392L228.334 872.262L226.196 870.734L218.871 880.987L221.01 882.514L224.013 878.311L225.492 879.368C224.157 882.196 224.598 885.387 227.234 887.271C230.12 889.332 233.905 888.431 236.008 885.487C238.111 882.543 237.737 878.67 234.852 876.608ZM228.647 885.294C227.036 884.143 226.766 881.981 228.231 879.931C229.706 877.865 231.828 877.435 233.439 878.586C235.065 879.747 235.345 881.894 233.87 883.959C232.405 886.01 230.273 886.455 228.647 885.294Z" fill="white"/>
                    <path d="M232.507 893.833L234.532 895.176L235.955 893.031L243.14 897.796L241.718 899.941L243.743 901.284L246.449 897.203L245.009 896.248L250.688 887.682L243.308 882.788L242.203 884.453C239.846 888.009 238.133 889.616 236.248 890.439L235.212 889.753L232.507 893.833ZM238.633 892.021C241.036 890.612 242.727 888.321 244.17 886.146L247.245 888.185L242.848 894.816L238.633 892.021Z" fill="white"/>
                    <path d="M246.37 899.906L250.919 902.749C253.498 904.361 255.99 903.838 257.288 901.762C258.585 899.687 257.972 897.202 255.393 895.59L253.042 894.121L255.246 890.595L253.048 889.221L246.37 899.906ZM249.78 899.341L251.878 895.983L254.107 897.376C255.374 898.168 255.771 899.265 255.093 900.349C254.426 901.417 253.275 901.526 252.008 900.734L249.78 899.341Z" fill="white"/>
                    <path d="M258.135 907.233L260.373 908.539L264.72 901.093L265.228 906.079L266.471 906.805L271.016 904.769L266.669 912.215L268.876 913.504L275.229 902.622L273.021 901.334L267.234 903.999L266.71 897.649L264.487 896.351L258.135 907.233Z" fill="white"/>
                    <path d="M271.954 915.238L273.868 916.279L283.239 911.972L279.291 919.229L281.552 920.459L287.574 909.391L285.613 908.325L276.33 912.619L280.253 905.409L277.976 904.17L271.954 915.238Z" fill="white"/>
                    <path d="M71.9334 746.567C69.3017 748.086 65.9374 747.182 64.4532 744.539C9.56334 646.777 -10.8398 533.381 6.53039 422.618C7.00007 419.623 9.83824 417.602 12.8346 418.109L70.0223 427.782C73.0154 428.288 75.0338 431.129 74.5679 434.138C59.8718 529.06 77.3463 626.179 124.208 710.025C125.693 712.683 124.792 716.049 122.163 717.567L71.9334 746.567Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M28.2933 532.878L28.5739 535.454L38.7915 534.342L39.374 539.692L29.1564 540.805L29.435 543.364L41.961 542L40.8193 531.514L28.2933 532.878Z" fill="white"/>
                    <path d="M29.7893 546.251L30.135 548.82L35.0764 548.155L35.4653 551.045C35.8326 553.774 37.6387 555.184 39.8329 554.888C42.0271 554.593 43.3786 552.759 43.0113 550.029L42.2768 544.57L29.7893 546.251ZM37.2707 547.859L40.3747 547.442L40.7395 550.153C40.9028 551.366 40.4684 552.188 39.4516 552.324C38.4526 552.459 37.7987 551.784 37.6355 550.571L37.2707 547.859Z" fill="white"/>
                    <path d="M31.3277 557.582L31.6726 559.733L40.8082 564.52L32.6505 565.829L33.0581 568.371L45.4991 566.375L45.1456 564.171L36.0747 559.446L44.1791 558.146L43.7686 555.587L31.3277 557.582Z" fill="white"/>
                    <path d="M34.1057 575.514C34.7313 578.84 36.8629 580.435 39.0034 580.033C40.6485 579.723 41.3831 578.468 41.576 577.351C42.0131 578.019 42.9889 578.825 44.3687 578.565C46.4384 578.176 47.5462 576.081 47.0038 573.198C46.7077 571.623 46.008 570.143 45.3153 569.285L42.9626 569.727C43.6199 570.592 44.325 571.907 44.6278 573.517C44.8973 574.949 44.5861 575.924 43.7546 576.08C42.9055 576.24 42.2727 575.7 42.0165 574.337L41.6671 572.48L39.5267 572.883L39.8195 574.439C40.1256 576.067 39.7846 577.175 38.7763 577.365C37.6441 577.578 36.7623 576.59 36.4096 574.714C36.15 573.335 36.3359 571.596 36.8903 570.356L34.4137 570.822C33.898 572.073 33.7297 573.515 34.1057 575.514Z" fill="white"/>
                    <path d="M35.7749 582.955L36.3183 585.489L41.7215 584.331L42.914 589.892L37.5108 591.051L38.0505 593.568L50.3704 590.926L49.8308 588.409L45.2196 589.398L44.0271 583.836L48.6383 582.848L48.0948 580.313L35.7749 582.955Z" fill="white"/>
                    <path d="M38.508 595.67L39.1532 598.31L42.2807 598.825L43.5456 604L41.0078 605.899L41.6616 608.574L52.6894 600.32L52.0997 597.907L38.508 595.67ZM44.5538 599.103L49.2429 599.865L45.4298 602.687L44.5538 599.103Z" fill="white"/>
                    <path d="M42.1599 610.447L42.8467 612.947L48.1752 611.482L49.6823 616.967L44.3538 618.431L45.0359 620.913L57.1855 617.575L56.5035 615.093L51.9561 616.342L50.4489 610.858L54.9964 609.608L54.3096 607.109L42.1599 610.447Z" fill="white"/>
                    <path d="M45.6618 623.107L46.2952 625.191L55.9956 628.695L48.0907 631.098L48.8393 633.561L60.8947 629.897L60.2456 627.761L50.6178 624.31L58.471 621.923L57.7172 619.443L45.6618 623.107Z" fill="white"/>
                    <path d="M49.9111 636.905L52.9019 645.869L55.1046 645.134L52.9341 638.629L56.0076 637.603L57.7052 642.691L59.8737 641.968L58.1761 636.88L60.4812 636.111L62.589 642.428L64.7916 641.693L61.8635 632.917L49.9111 636.905Z" fill="white"/>
                    <path d="M13.5919 413.725C10.5992 413.198 8.6031 410.342 9.16531 407.363C29.9574 297.191 87.2172 197.21 171.721 123.525C174.006 121.533 177.479 121.81 179.448 124.124L217.039 168.293C219.007 170.605 218.727 174.078 216.436 176.084C144.163 239.352 95.1223 324.982 77.1251 419.334C76.5545 422.325 73.7001 424.324 70.7107 423.797L13.5919 413.725Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M53.6279 393.123C53.9536 391.85 53.9565 390.531 53.6264 389.425L51.0979 388.778C51.6153 389.951 51.6217 391.16 51.305 392.398C50.6982 394.77 48.5994 396.147 46.2452 395.544C43.9085 394.946 42.7025 392.761 43.2915 390.46C43.6395 389.099 44.2336 388.229 45.0541 387.492L42.5256 386.845C41.7789 387.657 41.2407 388.671 40.9328 389.875C39.9646 393.659 41.8576 397.153 45.5894 398.108C49.3211 399.063 52.6686 396.872 53.6279 393.123Z" fill="white"/>
                    <path d="M42.5868 384.517L44.8219 385.146L45.8998 381.317L55.7933 384.102L56.4957 381.607L46.6022 378.822L47.685 374.975L45.4499 374.346L42.5868 384.517Z" fill="white"/>
                    <path d="M57.2934 378.67L58.0922 376.072L55.67 374.028L57.2358 368.936L60.388 368.605L61.1974 365.973L47.498 367.411L46.768 369.785L57.2934 378.67ZM53.8606 372.625L50.2246 369.567L54.945 369.098L53.8606 372.625Z" fill="white"/>
                    <path d="M61.625 364.691L63.3624 359.54C64.3174 356.709 63.2667 354.473 60.9812 353.703C58.7128 352.937 56.4888 354.068 55.5338 356.899L54.6248 359.594L52.7146 358.95L54.8087 352.741L52.6085 351.999L49.6859 360.664L61.625 364.691ZM60.2874 361.504L56.6715 360.284L57.5172 357.777C57.989 356.379 58.936 355.767 60.0958 356.158C61.2556 356.55 61.6048 357.598 61.1331 358.997L60.2874 361.504Z" fill="white"/>
                    <path d="M65.6421 352.837L66.3936 350.793L60.7696 342.147L68.5241 344.998L69.4123 342.582L57.5863 338.234L56.8161 340.329L62.4143 348.889L54.7104 346.057L53.816 348.489L65.6421 352.837Z" fill="white"/>
                    <path d="M69.8886 340.961C70.1126 340.74 70.3066 340.353 70.4337 340.035C71.5444 337.261 70.1178 335.643 64.7472 333.318L62.4011 332.301L63.8196 328.759L73.3611 332.579L74.3179 330.19L62.6208 325.506L59.3154 333.761L62.7209 335.182C67.2561 337.076 68.7198 337.972 68.1912 339.292C68.044 339.66 67.8868 339.907 67.7163 340.091L69.8886 340.961Z" fill="white"/>
                    <path d="M75.4998 327.325L77.6272 322.401C78.8336 319.609 77.9424 317.224 75.6951 316.253C73.4479 315.282 71.0839 316.261 69.8774 319.053L68.7781 321.598L64.9611 319.949L63.9331 322.328L75.4998 327.325ZM74.4293 324.039L70.794 322.469L71.8362 320.056C72.4287 318.685 73.4538 318.128 74.627 318.635C75.7836 319.134 76.064 320.255 75.4715 321.627L74.4293 324.039Z" fill="white"/>
                    <path d="M80.4611 315.886L81.5527 313.535L76.5406 311.208L78.9361 306.049L83.9482 308.376L85.0322 306.041L73.6041 300.735L72.5201 303.069L76.7975 305.055L74.402 310.214L70.1246 308.228L69.033 310.579L80.4611 315.886Z" fill="white"/>
                    <path d="M86.7783 297.552C85.789 299.533 83.4791 300.251 81.3534 299.189C79.2278 298.127 78.4137 295.85 79.4029 293.869C80.3921 291.888 82.7021 291.171 84.8278 292.232C86.9534 293.294 87.7675 295.572 86.7783 297.552ZM88.9522 298.638C90.6411 295.256 89.2468 291.482 86.01 289.865C82.7571 288.241 78.9179 289.402 77.229 292.783C75.54 296.165 76.9183 299.932 80.1712 301.556C83.408 303.173 87.2633 302.02 88.9522 298.638Z" fill="white"/>
                    <path d="M95.9083 285.212C96.5286 284.053 96.8463 282.774 96.79 281.621L94.4892 280.388C94.7115 281.651 94.4289 282.827 93.8256 283.953C92.6699 286.111 90.3031 286.947 88.1609 285.8C86.0346 284.661 85.3854 282.251 86.5071 280.157C87.1699 278.919 87.9546 278.216 88.9275 277.696L86.6266 276.464C85.7076 277.074 84.9427 277.931 84.3564 279.025C82.5125 282.469 83.5161 286.314 86.9118 288.133C90.3076 289.951 94.0813 288.623 95.9083 285.212Z" fill="white"/>
                    <path d="M87.1586 274.356L89.179 275.5L91.1396 272.039L100.083 277.105L101.36 274.849L92.417 269.784L94.3865 266.307L92.3661 265.162L87.1586 274.356Z" fill="white"/>
                    <path d="M104.248 269.801L107.009 265.202C108.575 262.594 108.007 260.112 105.908 258.852C103.81 257.592 101.337 258.249 99.7711 260.857L98.3443 263.233L94.7794 261.093L93.4453 263.316L104.248 269.801ZM103.622 266.402L100.227 264.364L101.58 262.111C102.349 260.83 103.439 260.413 104.534 261.071C105.615 261.72 105.744 262.868 104.975 264.149L103.622 266.402Z" fill="white"/>
                    <path d="M113.038 255.369L114.226 253.543L110.67 243.862L117.595 248.369L118.999 246.212L108.439 239.338L107.221 241.209L110.771 250.8L103.892 246.323L102.478 248.495L113.038 255.369Z" fill="white"/>
                    <path d="M122.9 240.438L124.392 238.319L119.638 234.972L127.511 233.889L129.417 231.18L120.233 232.375L118.586 224.678L116.762 227.268L118.151 233.925L114.089 231.065L112.597 233.185L122.9 240.438Z" fill="white"/>
                    <path d="M132.249 223.705C130.923 225.479 128.522 225.774 126.619 224.352C124.716 222.929 124.32 220.543 125.646 218.769C126.971 216.996 129.372 216.701 131.275 218.123C133.178 219.546 133.574 221.932 132.249 223.705ZM134.195 225.16C136.458 222.133 135.757 218.17 132.859 216.004C129.947 213.827 125.962 214.287 123.699 217.315C121.436 220.342 122.123 224.294 125.035 226.471C127.933 228.637 131.932 228.188 134.195 225.16Z" fill="white"/>
                    <path d="M139.357 217.887L140.974 215.862L134.235 210.483L139.242 210.702L140.14 209.577L138.783 204.785L145.521 210.164L147.116 208.166L137.268 200.306L135.674 202.304L137.474 208.416L131.115 208.016L129.509 210.027L139.357 217.887Z" fill="white"/>
                    <path d="M152.387 202.633L154.08 200.646L153.024 199.747C155.562 196.517 154.844 193.306 152.775 191.544C150.706 189.782 147.407 189.574 144.637 192.605L143.76 191.858L142.068 193.846L142.945 194.592C140.394 197.81 141.125 201.033 143.194 202.795C145.264 204.558 148.548 204.754 151.332 201.734L152.387 202.633ZM149.551 200.217C148.062 201.882 146.319 201.957 144.921 200.767C143.523 199.577 143.307 197.832 144.726 196.109L149.551 200.217ZM151.243 198.23L146.419 194.122C147.893 192.446 149.65 192.382 151.048 193.572C152.446 194.762 152.649 196.496 151.243 198.23Z" fill="white"/>
                    <path d="M162.288 187.313C160.797 188.95 158.379 189.012 156.622 187.412C154.866 185.812 154.702 183.399 156.193 181.762C157.684 180.125 160.102 180.064 161.858 181.663C163.615 183.263 163.779 185.677 162.288 187.313ZM164.084 188.95C166.63 186.155 166.315 182.143 163.64 179.707C160.952 177.259 156.942 177.331 154.396 180.126C151.851 182.921 152.152 186.92 154.841 189.369C157.515 191.805 161.539 191.744 164.084 188.95Z" fill="white"/>
                    <path d="M168.743 183.498L170.536 181.627L166.937 178.177L168.954 176.072C170.86 174.083 170.837 171.792 169.239 170.26C167.64 168.728 165.363 168.815 163.458 170.803L159.646 174.779L168.743 183.498ZM165.338 176.645L163.077 174.477L164.97 172.502C165.817 171.619 166.73 171.446 167.471 172.156C168.199 172.854 168.078 173.786 167.231 174.669L165.338 176.645Z" fill="white"/>
                    <path d="M167.834 166.263L169.471 167.91L172.292 165.105L179.539 172.392L181.377 170.564L174.13 163.277L176.963 160.459L175.325 158.812L167.834 166.263Z" fill="white"/>
                    <path d="M183.846 121.253C181.893 118.925 182.199 115.454 184.544 113.534C271.289 42.5019 379.419 2.71785 491.515 0.589987C494.546 0.532451 497.029 2.97671 497.05 6.0155L497.455 64.0141C497.476 67.0496 495.029 69.5306 491.985 69.5943C395.953 71.6048 303.344 105.678 228.91 166.387C226.55 168.312 223.079 168.009 221.128 165.683L183.846 121.253Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M266.631 121.483C267.737 120.773 268.637 119.81 269.148 118.775L267.737 116.579C267.318 117.79 266.499 118.68 265.424 119.371C263.364 120.695 260.89 120.273 259.576 118.229C258.272 116.2 258.877 113.779 260.876 112.494C262.057 111.735 263.084 111.503 264.187 111.521L262.776 109.326C261.677 109.412 260.592 109.788 259.547 110.46C256.261 112.571 255.268 116.419 257.35 119.66C259.433 122.9 263.376 123.576 266.631 121.483Z" fill="white"/>
                    <path d="M275.781 115.439L278.009 114.115L275.462 109.829L277.969 108.339C280.336 106.932 280.926 104.718 279.795 102.815C278.663 100.911 276.446 100.387 274.079 101.794L269.344 104.608L275.781 115.439ZM274.331 107.925L272.731 105.233L275.083 103.835C276.135 103.21 277.061 103.288 277.585 104.17C278.1 105.036 277.735 105.902 276.683 106.528L274.331 107.925Z" fill="white"/>
                    <path d="M287.283 108.96C288.287 108.395 289.476 107.168 289.269 104.414L288.48 93.54L286.08 94.8903L286.684 101.655L280.872 97.8202L278.377 99.2234L286.85 104.556C286.942 106.115 286.655 106.669 285.761 107.172C285.431 107.358 284.854 107.579 284.322 107.589L285.443 109.581C285.927 109.598 286.733 109.269 287.283 108.96Z" fill="white"/>
                    <path d="M295.972 103.797L298.263 102.584L295.542 97.4461L303.051 100.048L305.978 98.4983L297.249 95.4012L299.269 87.793L296.47 89.2757L294.691 95.8395L292.366 91.4492L290.075 92.6623L295.972 103.797Z" fill="white"/>
                    <path d="M311.97 93.0741C309.984 94.0541 307.71 93.2295 306.659 91.0989C305.607 88.9683 306.335 86.6617 308.32 85.6817C310.306 84.7017 312.58 85.5264 313.631 87.657C314.683 89.7875 313.955 92.0942 311.97 93.0741ZM313.045 95.2531C316.435 93.58 317.606 89.73 316.004 86.4858C314.395 83.2254 310.634 81.8296 307.245 83.5027C303.855 85.1759 302.677 89.0097 304.286 92.2701C305.887 95.5144 309.656 96.9263 313.045 95.2531Z" fill="white"/>
                    <path d="M320.917 91.1406L326.676 88.4991C329.114 87.3809 330.044 85.3503 329.159 83.4197C328.348 81.6527 326.69 81.1459 325.432 81.4258C325.992 80.8325 326.399 79.7346 325.829 78.4912C324.958 76.5933 323.059 76.2959 321.047 77.2189L315.664 79.6879L320.917 91.1406ZM322.343 88.0312L320.969 85.0372L323.931 83.6789C325.076 83.1536 326.043 83.3633 326.486 84.3286C326.929 85.2939 326.449 86.1476 325.304 86.6729L322.343 88.0312ZM320.076 83.0902L318.921 80.5706L321.571 79.3549C322.553 78.9046 323.352 79.0926 323.727 79.9106C324.087 80.6959 323.708 81.4242 322.727 81.8745L320.076 83.0902Z" fill="white"/>
                    <path d="M336.109 81.9513C334.073 82.8199 331.848 81.8706 330.916 79.6851C329.984 77.4996 330.839 75.2368 332.875 74.3682C334.912 73.4996 337.136 74.4489 338.069 76.6344C339.001 78.8199 338.146 81.0827 336.109 81.9513ZM337.063 84.1864C340.54 82.7035 341.922 78.9243 340.502 75.5963C339.076 72.2518 335.399 70.65 331.922 72.133C328.445 73.616 327.056 77.3786 328.482 80.7231C329.901 84.0511 333.586 85.6694 337.063 84.1864Z" fill="white"/>
                    <path d="M344.672 83.4484L346.935 82.5626L345.997 80.1657L354.025 77.0229L354.964 79.4198L357.226 78.534L355.442 73.9749L353.833 74.6048L350.086 65.0339L341.84 68.262L342.568 70.1226C344.123 74.0951 344.453 76.4206 344.044 78.4365L342.887 78.8893L344.672 83.4484ZM346.709 77.3933C346.937 74.6173 346.034 71.9165 345.083 69.486L348.519 68.141L351.419 75.5496L346.709 77.3933Z" fill="white"/>
                    <path d="M359.171 75.0597L361.222 74.3271L364.257 64.4697L367.036 72.2503L369.46 71.3844L365.222 59.5187L363.12 60.2695L360.134 70.0518L357.373 62.3221L354.932 63.194L359.171 75.0597Z" fill="white"/>
                    <path d="M366.048 59.2288L366.777 61.4334L370.554 60.1845L373.781 69.9428L376.242 69.129L373.015 59.3707L376.809 58.1161L376.08 55.9115L366.048 59.2288Z" fill="white"/>
                    <path d="M381.542 67.3958L390.583 64.6425L389.906 62.4213L383.346 64.4193L382.402 61.3199L387.533 59.7571L386.867 57.5703L381.736 59.1331L381.028 56.8085L387.399 54.8681L386.722 52.6468L377.871 55.3424L381.542 67.3958Z" fill="white"/>
                    <path d="M390.721 64.4495C391.032 64.494 391.458 64.4133 391.787 64.322C394.667 63.5241 395.089 61.4085 393.683 55.7276L393.07 53.2452L396.747 52.2262L399.492 62.1309L401.973 61.4436L398.608 49.3011L390.039 51.6757L390.972 55.2461C392.215 60.0009 392.389 61.7084 391.019 62.0881C390.637 62.1938 390.345 62.2187 390.096 62.1945L390.721 64.4495Z" fill="white"/>
                    <path d="M405.887 60.4053L415.058 58.1276L414.499 55.8741L407.843 57.527L407.062 54.3825L412.268 53.0897L411.717 50.8711L406.511 52.1639L405.925 49.8055L412.389 48.2004L411.829 45.9468L402.85 48.1767L405.887 60.4053Z" fill="white"/>
                    <path d="M416.056 57.8552L418.587 57.2984L416.735 48.8777L420.489 52.1991L421.895 51.8897L423.855 47.3118L425.707 55.7326L428.204 55.1835L425.497 42.8776L423.001 43.4266L420.553 49.3097L415.863 44.9964L413.35 45.5493L416.056 57.8552Z" fill="white"/>
                    <path d="M272.73 85.6806C270.794 86.7538 268.483 86.0381 267.332 83.9599C266.18 81.8817 266.798 79.5431 268.734 78.4699C270.671 77.3967 272.981 78.1124 274.133 80.1905C275.285 82.2687 274.667 84.6073 272.73 85.6806ZM273.908 87.806C277.215 85.9736 278.201 82.0724 276.447 78.9079C274.685 75.7277 270.862 74.5122 267.556 76.3445C264.25 78.1768 263.255 82.0623 265.017 85.2426C266.771 88.4071 270.602 89.6383 273.908 87.806Z" fill="white"/>
                    <path d="M274.107 73.0298L275.181 75.0887L278.708 73.2492L283.461 82.3624L285.759 81.1638L281.006 72.0506L284.549 70.2029L283.476 68.144L274.107 73.0298Z" fill="white"/>
                    <path d="M290.341 78.7825L292.669 77.6426L290.239 72.6797L295.347 70.1782L297.778 75.1411L300.089 74.009L294.548 62.693L292.236 63.825L294.31 68.0604L289.202 70.562L287.128 66.3265L284.8 67.4665L290.341 78.7825Z" fill="white"/>
                    <path d="M306.696 68.5529C304.681 69.4696 302.434 68.5733 301.451 66.4105C300.467 64.2478 301.268 61.9654 303.283 61.0487C305.299 60.132 307.545 61.0283 308.529 63.191C309.513 65.3538 308.712 67.6362 306.696 68.5529ZM307.703 70.7648C311.143 69.1998 312.435 65.3888 310.937 62.0955C309.432 58.7858 305.718 57.2717 302.277 58.8368C298.836 60.4018 297.537 64.1964 299.042 67.5061C300.54 70.7994 304.262 72.3299 307.703 70.7648Z" fill="white"/>
                    <path d="M315.86 66.7861L330.464 60.7471L325.65 49.1033L323.255 50.0937L327.182 59.5918L323.456 61.1325L319.529 51.6344L317.15 52.618L321.077 62.116L317.368 63.6498L313.44 54.1518L311.045 55.1422L315.86 66.7861Z" fill="white"/>
                    <path d="M332.116 60.1324L340.954 56.7854L340.131 54.6139L333.718 57.0429L332.57 54.0129L337.586 52.1131L336.777 49.9753L331.76 51.8751L330.9 49.6026L337.128 47.2438L336.306 45.0723L327.653 48.3492L332.116 60.1324Z" fill="white"/>
                    <path d="M343.715 55.7615L346.163 54.9089L344.345 49.6904L349.717 47.8194L351.534 53.038L353.965 52.1913L349.821 40.2924L347.39 41.1391L348.941 45.5927L343.57 47.4636L342.018 43.01L339.571 43.8626L343.715 55.7615Z" fill="white"/>
                    <path d="M355.477 51.6707L357.553 51.0098L360.929 41.264L363.436 49.1364L365.888 48.3554L362.065 36.3494L359.938 37.0267L356.615 46.6992L354.124 38.8782L351.654 39.6647L355.477 51.6707Z" fill="white"/>
                    <path d="M367.764 47.751L370.636 46.9258L372.793 41.0058L374.903 40.3993L376.31 45.2952L378.801 44.5793L375.322 32.4694L369.716 34.0801C367.121 34.8258 365.975 36.7659 366.576 38.8592C367.038 40.4681 368.318 41.4675 370.066 41.4896L367.764 47.751ZM371.326 39.1611C370.202 39.4842 369.378 39.1591 369.105 38.2076C368.836 37.2734 369.357 36.5432 370.481 36.2201L373.457 35.365L374.302 38.306L371.326 39.1611Z" fill="white"/>
                    <path d="M500.5 5.99995C500.5 2.96109 502.965 0.499554 505.997 0.535921C618.106 1.88089 726.511 40.9091 813.75 111.334C816.109 113.238 816.439 116.706 814.502 119.048L777.532 163.737C775.597 166.076 772.127 166.404 769.754 164.496C694.897 104.308 602.052 70.8816 506.009 69.5419C502.964 69.4994 500.5 67.0355 500.5 64L500.5 5.99995Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M512.179 50.6733L514.352 50.8178L521.06 42.9835L520.512 51.2273L523.081 51.398L523.917 38.8258L521.689 38.6777L515.056 46.4629L515.601 38.273L513.015 38.1011L512.179 50.6733Z" fill="white"/>
                    <path d="M531.098 52.0869L533.676 52.3536L534.189 47.394L537.09 47.694C539.829 47.9773 541.623 46.5523 541.851 44.3501C542.079 42.1478 540.613 40.4037 537.873 40.1204L532.394 39.5538L531.098 52.0869ZM534.417 45.1918L534.739 42.0764L537.461 42.3578C538.678 42.4838 539.375 43.0987 539.269 44.1192C539.165 45.1219 538.356 45.5991 537.139 45.4732L534.417 45.1918Z" fill="white"/>
                    <path d="M542.677 53.3146L552.051 54.5109L552.345 52.2076L545.542 51.3394L545.953 48.1255L551.273 48.8045L551.563 46.5369L546.242 45.8579L546.55 43.4474L553.156 44.2906L553.45 41.9872L544.272 40.816L542.677 53.3146Z" fill="white"/>
                    <path d="M552.338 54.5239L555.024 54.9365L556.694 52.2428L561.961 53.0515L562.746 56.1222L565.468 56.5402L562.054 43.1954L559.599 42.8184L552.338 54.5239ZM557.82 50.2485L560.317 46.2066L561.467 50.8086L557.82 50.2485Z" fill="white"/>
                    <path d="M566.448 56.5904C566.708 56.7662 567.127 56.8793 567.463 56.9409C570.403 57.4786 571.705 55.759 572.917 50.0337L573.448 47.5324L577.202 48.2191L575.352 58.3293L577.884 58.7925L580.152 46.3983L571.405 44.798L570.688 48.418C569.733 53.2388 569.145 54.8514 567.746 54.5955C567.357 54.5242 567.083 54.4192 566.869 54.2886L566.448 56.5904Z" fill="white"/>
                    <path d="M579.712 59.1374L581.843 59.587L589.59 52.7774L587.884 60.8615L590.403 61.3928L593.004 49.0641L590.82 48.6034L583.155 55.3751L584.849 47.3438L582.313 46.8088L579.712 59.1374Z" fill="white"/>
                    <path d="M597.68 63.3465C600.971 64.134 603.298 62.8399 603.805 60.7217C604.194 59.0937 603.349 57.9103 602.408 57.2781C603.197 57.1522 604.331 56.5906 604.657 55.2252C605.147 53.177 603.688 51.3101 600.834 50.6274C599.276 50.2546 597.639 50.2886 596.573 50.5701L596.016 52.8984C597.074 52.6519 598.562 52.5452 600.155 52.9263C601.573 53.2656 602.335 53.9477 602.138 54.7705C601.937 55.6107 601.185 55.9676 599.837 55.6451L597.999 55.2053L597.492 57.3235L599.033 57.6921C600.643 58.0775 601.516 58.8414 601.277 59.8392C601.009 60.9596 599.747 61.3609 597.891 60.917C596.526 60.5903 595.015 59.7106 594.11 58.6981L593.523 61.1489C594.454 62.1306 595.702 62.8732 597.68 63.3465Z" fill="white"/>
                    <path d="M603.338 64.414L605.964 65.1132L607.914 62.6148L613.063 63.9855L613.513 67.1228L616.175 67.8313L614.216 54.1966L611.816 53.5576L603.338 64.414ZM609.248 60.7533L612.165 57.0035L612.814 61.7026L609.248 60.7533Z" fill="white"/>
                    <path d="M625.953 73.3977L628.299 74.0945L629.693 69.4011L628.019 68.9041L630.945 59.0513L628.46 58.3135L625.535 68.1662L620.479 66.6649L623.405 56.8122L620.92 56.0743L617.333 68.153L626.685 70.9302L625.953 73.3977Z" fill="white"/>
                    <path d="M631.904 72.5285L633.972 73.211L642.424 67.2995L639.836 75.1455L642.28 75.952L646.228 63.9865L644.108 63.2871L635.742 69.1699L638.314 61.3752L635.852 60.563L631.904 72.5285Z" fill="white"/>
                    <path d="M644.381 76.6619L647.191 77.6785L652.411 74.1499L654.476 74.8971L652.743 79.6872L655.18 80.569L659.467 68.7207L653.983 66.7365C651.444 65.8179 649.377 66.7163 648.636 68.7643C648.067 70.3385 648.517 71.8986 649.919 72.9421L644.381 76.6619ZM652.306 71.7956C651.206 71.3976 650.729 70.651 651.066 69.72C651.397 68.806 652.247 68.5201 653.347 68.9182L656.258 69.9715L655.217 72.849L652.306 71.7956Z" fill="white"/>
                    <path d="M662.498 83.3713L664.9 84.345L668.761 74.8197L673.749 76.8415L669.888 86.3667L672.274 87.3336L677.007 75.6565L667.232 71.6941L662.498 83.3713Z" fill="white"/>
                    <path d="M680.721 88.6104C678.693 87.7207 677.862 85.4491 678.817 83.2734C679.772 81.0977 682.007 80.1717 684.034 81.0615C686.061 81.9512 686.892 84.2228 685.938 86.3984C684.983 88.5741 682.748 89.5002 680.721 88.6104ZM679.744 90.8356C683.206 92.3547 686.907 90.7748 688.361 87.4618C689.822 84.1324 688.472 80.3554 685.011 78.8363C681.549 77.3172 677.855 78.8806 676.394 82.2101C674.94 85.523 676.283 89.3165 679.744 90.8356Z" fill="white"/>
                    <path d="M690.824 81.6597L689.837 83.7616L693.438 85.452L689.071 94.7559L691.417 95.8573L695.785 86.5534L699.402 88.2514L700.389 86.1495L690.824 81.6597Z" fill="white"/>
                    <path d="M697.435 98.7117L705.892 102.929L706.928 100.851L700.791 97.7906L702.237 94.8912L707.037 97.2852L708.057 95.2395L703.257 92.8455L704.342 90.6709L710.302 93.6433L711.338 91.5654L703.059 87.4362L697.435 98.7117Z" fill="white"/>
                    <path d="M707.186 103.579L709.476 104.794L712.067 99.9133L717.091 102.58L714.5 107.461L716.773 108.668L722.681 97.5386L720.407 96.3318L718.196 100.497L713.172 97.8305L715.383 93.665L713.094 92.4497L707.186 103.579Z" fill="white"/>
                    <path d="M727.462 117.641L729.589 118.854L732.013 114.6L730.496 113.736L735.587 104.807L733.335 103.523L728.245 112.452L723.663 109.84L728.753 100.911L726.501 99.6273L720.261 110.573L728.737 115.405L727.462 117.641Z" fill="white"/>
                    <path d="M732.252 117.449L734.112 118.581L743.681 114.733L739.386 121.791L741.585 123.129L748.136 112.366L746.229 111.206L736.749 115.045L741.016 108.033L738.802 106.686L732.252 117.449Z" fill="white"/>
                    <path d="M742.074 123.404L744.357 124.879L746.983 123.104L751.458 125.996L750.918 129.119L753.231 130.614L755.574 117.04L753.488 115.692L742.074 123.404ZM748.826 121.745L752.757 119.078L751.925 123.748L748.826 121.745Z" fill="white"/>
                    <path d="M754.149 131.083C754.314 131.35 754.65 131.625 754.932 131.818C757.394 133.512 759.286 132.475 762.734 127.747L764.242 125.682L767.386 127.844L761.562 136.313L763.683 137.771L770.823 127.39L763.496 122.351L761.361 125.361C758.517 129.369 757.321 130.6 756.149 129.794C755.823 129.57 755.616 129.362 755.475 129.155L754.149 131.083Z" fill="white"/>
                    <path d="M765.617 139.111L767.811 140.715L770.535 139.094L774.836 142.238L774.118 145.325L776.342 146.95L779.46 133.533L777.454 132.067L765.617 139.111ZM772.453 137.842L776.53 135.405L775.432 140.02L772.453 137.842Z" fill="white"/>
                    <path d="M531.466 29.1285L534.05 29.3375L534.879 19.093L540.243 19.5271L539.414 29.7716L541.98 29.9792L542.996 17.4202L532.483 16.5695L531.466 29.1285Z" fill="white"/>
                    <path d="M544.227 30.1897L546.804 30.4646L547.333 25.5067L550.233 25.8159C552.971 26.108 554.77 24.6887 555.004 22.4872C555.239 20.2857 553.778 18.5369 551.04 18.2449L545.563 17.6608L544.227 30.1897ZM547.568 23.3052L547.9 20.1909L550.621 20.481C551.838 20.6108 552.532 21.2279 552.423 22.2481C552.316 23.2504 551.505 23.7251 550.288 23.5953L547.568 23.3052Z" fill="white"/>
                    <path d="M562.035 30.0646C559.84 29.7703 558.413 27.8173 558.729 25.4624C559.045 23.1075 560.936 21.5995 563.13 21.8937C565.325 22.188 566.752 24.141 566.436 26.4959C566.12 28.8508 564.229 30.3588 562.035 30.0646ZM561.712 32.473C565.458 32.9754 568.578 30.4335 569.058 26.8476C569.542 23.2438 567.2 19.9877 563.453 19.4853C559.707 18.9829 556.59 21.507 556.106 25.1107C555.625 28.6967 557.965 31.9706 561.712 32.473Z" fill="white"/>
                    <path d="M575.449 34.7373L578.024 35.1662L578.252 33.799C582.327 34.3137 584.711 32.0468 585.158 29.3657C585.605 26.6847 584.087 23.7493 580.062 22.9328L580.251 21.7965L577.677 21.3676L577.487 22.5039C573.416 21.9715 571.028 24.2562 570.581 26.9372C570.134 29.6183 571.656 32.5359 575.677 33.3701L575.449 34.7373ZM576.062 31.0619C573.869 30.6419 572.907 29.186 573.209 27.375C573.511 25.564 574.895 24.4808 577.103 24.8121L576.062 31.0619ZM578.636 31.4909L579.677 25.241C581.873 25.6433 582.832 27.1169 582.53 28.9279C582.229 30.739 580.847 31.8044 578.636 31.4909Z" fill="white"/>
                    <path d="M587.406 36.3259L596.676 38.1591L597.127 35.8812L590.399 34.5508L591.028 31.3724L596.29 32.4129L596.733 30.1704L591.471 29.1298L591.942 26.7459L598.476 28.0379L598.926 25.7601L589.85 23.9652L587.406 36.3259Z" fill="white"/>
                    <path d="M603.937 40.0166C605.219 40.302 606.537 40.2634 607.633 39.8987L608.2 37.3511C607.044 37.9051 605.835 37.9496 604.588 37.672C602.198 37.1401 600.756 35.0857 601.284 32.7138C601.808 30.3594 603.954 29.0852 606.273 29.6014C607.644 29.9065 608.532 30.473 609.295 31.2698L609.862 28.7221C609.027 28.0014 607.996 27.4954 606.784 27.2256C602.971 26.3769 599.538 28.3789 598.701 32.1389C597.864 35.8989 600.159 39.1757 603.937 40.0166Z" fill="white"/>
                    <path d="M617.015 43.1494C618.289 43.4693 619.607 43.4662 620.712 43.1311L621.348 40.5996C620.177 41.1224 618.968 41.1343 617.728 40.8232C615.354 40.2272 613.968 38.1347 614.559 35.7778C615.147 33.4384 617.326 32.2225 619.631 32.8009C620.993 33.1427 621.865 33.7329 622.606 34.55L623.242 32.0186C622.426 31.2756 621.41 30.742 620.205 30.4397C616.416 29.4887 612.931 31.3975 611.993 35.1336C611.055 38.8697 613.261 42.2072 617.015 43.1494Z" fill="white"/>
                    <path d="M622.692 44.338L624.79 44.9249L632.962 38.6322L630.735 46.5886L633.214 47.2822L636.609 35.1483L634.46 34.5468L626.372 40.807L628.584 32.9026L626.088 32.2041L622.692 44.338Z" fill="white"/>
                    <path d="M641.559 47.5466C639.445 46.8895 638.364 44.7254 639.069 42.4564C639.775 40.1875 641.891 39.0169 644.006 39.674C646.12 40.331 647.2 42.4951 646.495 44.7641C645.79 47.0331 643.673 48.2036 641.559 47.5466ZM640.838 49.8671C644.448 50.9889 647.948 49.0043 649.022 45.5493C650.101 42.0771 648.336 38.4752 644.727 37.3534C641.117 36.2316 637.622 38.199 636.543 41.6712C635.469 45.1262 637.228 48.7453 640.838 49.8671Z" fill="white"/>
                    <path d="M648.61 52.0406L651.062 52.8799L652.852 47.6517L658.233 49.4936L656.444 54.7218L658.879 55.5554L662.959 43.6343L660.524 42.8008L658.997 47.2626L653.615 45.4207L655.143 40.9589L652.69 40.1195L648.61 52.0406Z" fill="white"/>
                    <path d="M660.693 56.1694L663.239 57.1224L665.424 54.827L670.414 56.695L670.555 59.8614L673.134 60.8269L672.52 47.0661L670.193 46.1951L660.693 56.1694ZM666.934 53.1049L670.204 49.6586L670.39 54.3987L666.934 53.1049Z" fill="white"/>
                    <path d="M673.977 61.0324C674.196 61.2577 674.582 61.454 674.899 61.5829C677.666 62.7098 679.293 61.2925 681.649 55.9356L682.68 53.5954L686.214 55.0345L682.338 64.5536L684.722 65.5243L689.474 53.8546L681.238 50.5013L679.797 53.8985C677.877 58.4225 676.972 59.881 675.655 59.3447C675.288 59.1954 675.042 59.0368 674.859 58.8652L673.977 61.0324Z" fill="white"/>
                    <path d="M687.529 66.7107L692.441 68.8664C695.227 70.0889 697.617 69.2115 698.601 66.9699C699.584 64.7283 698.619 62.3586 695.834 61.1361L693.296 60.0221L694.967 56.2147L692.593 55.173L687.529 66.7107ZM690.822 65.6591L692.413 62.033L694.819 63.0891C696.188 63.6895 696.739 64.7177 696.225 65.888C695.719 67.0418 694.596 67.3157 693.228 66.7153L690.822 65.6591Z" fill="white"/>
                    <path d="M698.724 71.645L701.068 72.7502L703.424 67.7516L708.57 70.1768L706.213 75.1753L708.542 76.2728L713.914 64.8754L711.586 63.778L709.575 68.0438L704.43 65.6187L706.441 61.3528L704.096 60.2477L698.724 71.645Z" fill="white"/>
                    <path d="M721.528 82.8136L723.837 83.9925L729.567 72.7709L727.259 71.5921L721.528 82.8136ZM711.445 77.6644L715.837 79.9075C718.563 81.2992 720.984 80.555 722.097 78.3748C723.211 76.1946 722.402 73.7807 719.677 72.3891L717.593 71.3249L719.484 67.6218L717.175 66.4429L711.445 77.6644ZM714.793 76.8074L716.594 73.2806L718.55 74.2793C719.88 74.9588 720.37 76.0175 719.789 77.1557C719.216 78.2779 718.079 78.4856 716.749 77.8061L714.793 76.8074Z" fill="white"/>
                    <path d="M725.368 84.8208L727.277 85.8693L736.665 81.6L732.688 88.8416L734.944 90.0807L741.01 79.0369L739.054 77.9624L729.754 82.2191L733.705 75.0248L731.433 73.777L725.368 84.8208ZM736.543 75.8031C738.152 76.6869 739.96 76.3862 740.912 74.691L739.24 73.7724C738.843 74.458 738.118 74.6555 737.376 74.2483C736.635 73.841 736.429 73.1322 736.81 72.438L735.138 71.5195C734.233 73.2407 734.918 74.9105 736.543 75.8031Z" fill="white"/>
                    <path d="M741.755 93.9915L743.98 95.3214L746.538 91.0417L749.041 92.5379C751.405 93.9509 753.633 93.4163 754.769 91.516C755.905 89.6156 755.311 87.4156 752.948 86.0025L748.22 83.1764L741.755 93.9915ZM747.674 89.1414L749.281 86.4531L751.629 87.8569C752.68 88.4849 753.052 89.3363 752.525 90.2169C752.008 91.0821 751.073 91.1732 750.022 90.5452L747.674 89.1414Z" fill="white"/>
                    <path d="M757.948 101.335C756.079 100.148 755.602 97.7763 756.876 95.7706C758.149 93.7649 760.499 93.1884 762.368 94.3753C764.236 95.5622 764.714 97.9335 763.44 99.9393C762.166 101.945 759.817 102.521 757.948 101.335ZM756.645 103.386C759.836 105.412 763.734 104.412 765.673 101.358C767.623 98.2884 766.861 94.3504 763.67 92.324C760.479 90.2976 756.591 91.2827 754.642 94.3521C752.702 97.4062 753.454 101.359 756.645 103.386Z" fill="white"/>
                    <path d="M768.657 111.259C769.747 111.993 770.988 112.439 772.141 112.499L773.599 110.335C772.321 110.428 771.179 110.028 770.119 109.314C768.089 107.946 767.497 105.507 768.855 103.492C770.202 101.492 772.666 101.089 774.636 102.417C775.8 103.201 776.42 104.053 776.84 105.073L778.298 102.909C777.784 101.933 777.009 101.085 775.979 100.391C772.74 98.209 768.813 98.8189 766.66 102.014C764.508 105.208 765.448 109.097 768.657 111.259Z" fill="white"/>
                    <path d="M779.456 103.025L778.113 104.919L781.358 107.22L775.413 115.604L777.528 117.103L783.473 108.719L786.732 111.031L788.075 109.136L779.456 103.025Z" fill="white"/>
                    <path d="M641.579 906.553L641.414 906.081L641.579 906.553C644.454 905.552 647.613 907.024 648.651 909.877L668.488 964.379C669.528 967.235 668.053 970.391 665.192 971.394C559.384 1008.47 444.168 1008.88 338.103 972.535C335.235 971.553 333.739 968.407 334.758 965.544L354.214 910.905C355.232 908.045 358.38 906.551 361.263 907.532L361.424 907.059L361.263 907.532C452.191 938.488 550.869 938.143 641.579 906.553Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M453.316 970.183L459.634 970.661C462.308 970.864 464.106 969.537 464.266 967.42C464.413 965.481 463.208 964.235 461.972 963.87C462.749 963.622 463.637 962.859 463.74 961.495C463.898 959.413 462.38 958.233 460.173 958.066L454.268 957.619L453.316 970.183ZM456.069 968.153L456.318 964.868L459.567 965.114C460.823 965.209 461.568 965.861 461.488 966.92C461.408 967.979 460.574 968.494 459.318 968.399L456.069 968.153ZM456.48 962.732L456.689 959.968L459.597 960.188C460.674 960.27 461.282 960.821 461.214 961.719C461.149 962.58 460.464 963.034 459.387 962.952L456.48 962.732Z" fill="white"/>
                    <path d="M464.762 970.956C465.044 971.096 465.474 971.153 465.815 971.17C468.8 971.315 469.864 969.439 470.311 963.604L470.507 961.054L474.318 961.24L473.818 971.506L476.389 971.631L477.002 959.046L468.121 958.613L467.887 962.296C467.576 967.201 467.206 968.877 465.786 968.808C465.391 968.788 465.105 968.72 464.876 968.619L464.762 970.956Z" fill="white"/>
                    <path d="M480.297 971.784L482.475 971.828L488.815 963.692L488.648 971.952L491.221 972.004L491.476 959.407L489.244 959.362L482.978 967.445L483.144 959.239L480.552 959.186L480.297 971.784Z" fill="white"/>
                    <path d="M491.936 972.038L494.924 972.019L498.598 966.9L500.794 966.885L500.828 971.979L503.42 971.962L503.336 959.362L497.504 959.401C494.804 959.419 493.177 960.978 493.191 963.156C493.202 964.83 494.165 966.138 495.842 966.63L491.936 972.038ZM497.684 964.728C496.514 964.736 495.808 964.201 495.802 963.211C495.795 962.239 496.493 961.676 497.663 961.668L500.759 961.648L500.78 964.707L497.684 964.728Z" fill="white"/>
                    <path d="M507.31 971.908L509.9 971.817L509.706 966.294L515.39 966.095L515.584 971.617L518.157 971.527L517.715 958.935L515.142 959.025L515.308 963.738L509.623 963.938L509.458 959.225L506.867 959.316L507.31 971.908Z" fill="white"/>
                    <path d="M521.599 971.371L523.773 971.232L529.409 962.595L529.934 970.84L532.503 970.677L531.702 958.102L529.475 958.244L523.907 966.823L523.385 958.631L520.798 958.796L521.599 971.371Z" fill="white"/>
                    <path d="M534.737 970.506L544.15 969.668L543.944 967.355L537.113 967.964L536.825 964.736L542.168 964.26L541.965 961.983L536.622 962.459L536.407 960.039L543.04 959.448L542.834 957.135L533.619 957.956L534.737 970.506Z" fill="white"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_501_7321">
                    <rect width="1000" height="1000" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
                `,
                width: 390,
                alignment: 'center',
                absolutePosition: {
                    x: 0,
                    y: 60
                }
            },
            {
                text: 'На диаграмме мотивационного профиля представлены 9 универсальных факторов, наиболее полным образом отвечающих реалиям рабочей среды: чем выше балл, тем более значим этот фактор.',
                alignment: 'justify',
                margin: [20, 430, 20, 0],
                color: '#2B3D4F'
            },
            {
                pageBreak: 'before',
                margin: [0, 20, 0, 0],
                table: {
                    headerRows: 1,
                    widths: ['*'],
                    body: dropLast(5, DRIVER_PLUS_RESULTS).map(item => ([{
                        layout: 'noBorders',
                        table: {
                            headerRows: 0,
                            width: [400, '*'],
                            body: [
                                [{
                                    columns: [
                                        { text: item.title, fontSize: 16, bold: true, color: '#2B3D4F' },
                                        (pathOr(0, ['integralScores', 'blocks', item.id], data) ? {
                                            svg: DRIVER_PLUS_RESULT_IMG[pathOr(0, ['integralScores', 'blocks', item.id], data)],
                                            width: 280
                                        } : { text: '' })
                                    ],
                                    margin: [10, 5, 10, 5]
                                }],
                                [{ text: item.description, margin: [10, 0, 10, 5], fontSize: 11, color: '#666', alignment: 'justify', }]
                            ]
                        }
                    }]))
                }
            },
            {
                pageBreak: 'before',
                margin: [0, 20, 0, 0],
                table: {
                    headerRows: 1,
                    widths: ['*'],
                    body: drop(4, DRIVER_PLUS_RESULTS).map(item => ([{
                        layout: 'noBorders',
                        table: {
                            headerRows: 0,
                            width: [400, '*'],
                            body: [
                                [{
                                    columns: [
                                        { text: item.title, fontSize: 16, bold: true, color: '#2B3D4F' },
                                        (pathOr(0, ['integralScores', 'blocks', item.id], data) ? {
                                            svg: DRIVER_PLUS_RESULT_IMG[pathOr(0, ['integralScores', 'blocks', item.id], data)],
                                            width: 280
                                        } : { text: '' })
                                    ],
                                    margin: [10, 5, 10, 5]
                                }],
                                [{ text: item.description, margin: [10, 0, 10, 5], fontSize: 11, color: '#666', alignment: 'justify', }]
                            ]
                        }
                    }]))
                }
            },
            {
                pageBreak: 'before',
                text: 'Результаты',
                margin: [20, 20, 20, 0],
                color: '#2B3D4F',
                style: {
                    bold: true,
                    fontSize: 25
                }
            },
            {
                text: 'В отчёте описаны факторы, оказывающие наибольшее (мотиваторы) и наименьшее воздействие на Вашу рабочую мотивацию.​',
                alignment: 'justify',
                margin: [20, 10, 20, 0],
                color: '#2B3D4F'
            },
            {
                margin: [20, 10, 20, 0],
                layout: 'noBorders',
                table: {
                    headerRows: 1,
                    widths: ['50%', '50%'],
                    body: [
                        [
                            { text: 'Мотиваторы', fontSize: 16, bold: true, color: '#2B3D4F', margin: [5, 5, 5, 5], padding: [5, 5, 5, 5] },
                            { text: 'Факторы, наименее важные в работе', fontSize: 16, bold: true, color: '#2B3D4F', margin: [5, 5, 5, 5], padding: [5, 5, 5, 5] },
                        ],
                        ...(DRIVER_PLUS_IPSATIVE_RESULTS).map((row, index) => ([
                            { text: [
                                { text: motivations[index] ? `${motivations[index].title}\n` : '', alignment: 'justify', fontSize: 11, bold: true, color: '#666', margin: [5, 5, 5, 5], padding: [5, 5, 5, 0] },
                                { text: motivations[index] ? `${motivations[index].motivator}` : '', alignment: 'justify', fontSize: 11, color: '#666', margin: [0, 5, 5, 5], padding: [5, 5, 5, 5] },
                            ]},
                            { text: [
                                { text: factors[index] ? `${factors[index].title}\n` : '', alignment: 'justify', fontSize: 11, bold: true, color: '#666', margin: [5, 5, 5, 0], padding: [5, 5, 5, 0] },
                                { text: factors[index] ? `${factors[index].factor}` : '', alignment: 'justify', fontSize: 11, color: '#666', margin: [0, 5, 5, 5], padding: [0, 5, 5, 5] },
                            ]}
                        ]))
                    ]
                }
            },
        ],
        footer: currentPage => currentPage === 1 ? undefined : ({
            columns: [
                {
                    svg: `<svg width="80px" height="50px" viewBox="0 0 80 50">
                        <polygon stroke="#E71D32" stroke-width="1px" fill="#E71D32" points="0,50 80,0 80,50" />
                    </svg>`,
                    margin: [0, 0, 0, 0],
                    alignment: 'right',
                },
                {
                    width: 140,
                    layout: 'noBorders',
                    table: {
                        widths: [140],
                        heights: [50],
                        body: [
                            [
                                {
                                    alignment: 'right',
                                    fillColor: '#E71D32',
                                    margin: [0, 15, 5, 0],
                                    svg: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="115px" height="22px" viewBox="0 0 115 22" version="1.1">
                                        <title>logo</title>
                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g id="logo" fill="#FFFFFF" fill-rule="nonzero">
                                                <path d="M106.773,0.388235 L103.214,5.5 L103.214,0.388235 L96.7954,0.388235 L96.7954,21.5471 L103.214,21.5471 L103.214,7.95883 L109.061,7.95883 L114.4,0.388235 L106.773,0.388235 Z M82.2408,15.9176 C79.508,15.9176 77.5378,13.8471 77.5378,11 C77.5378,8.15294 79.5715,6.01765 82.2408,6.01765 C84.9102,6.01765 86.944,8.15294 86.944,11 C87.0072,13.8471 84.9738,15.9176 82.2408,15.9176 Z M82.2408,0 C76.0125,0 71.1187,4.85294 71.1187,11 C71.1187,17.1471 76.0125,22 82.2408,22 C88.4693,22 93.3634,17.1471 93.3634,11 C93.4266,4.85294 88.5326,0 82.2408,0 Z M62.0938,15.9176 C59.1702,15.9176 57.1365,13.9117 57.1365,11 C57.1365,8.15294 59.2338,6.01765 61.9667,6.01765 C63.3648,6.01765 64.7631,6.6 65.78,7.63529 L69.2755,2.65294 C67.3053,0.970587 64.6995,0 61.9667,0 C55.6747,0 50.7173,4.85294 50.7173,11 C50.7173,17.2765 55.5475,22 61.9667,22 C65.0173,22 67.5595,21.0941 69.6568,19.2176 L66.1613,14.1706 C65.208,15.0765 63.8098,15.9176 62.0938,15.9176 Z M39.0867,0 C37.2435,0 34.1929,0.905881 33.1125,2.26471 L33.1125,0.388235 L26.884,0.388235 L26.884,21.5471 L33.3031,21.5471 L33.3031,8.15295 C34.0022,7.24706 35.6547,6.01765 37.6249,6.01765 C38.7053,6.01765 39.6587,6.34117 40.2307,6.98824 C40.7391,7.50588 41.2475,8.28235 41.2475,10.3529 L41.2475,21.5471 L47.6667,21.5471 L47.6667,7.95883 C47.6667,2.65294 43.8533,0 39.0867,0 Z M11.1222,15.9176 C8.38933,15.9176 6.41911,13.8471 6.41911,11 C6.41911,8.15294 8.45288,6.01765 11.1222,6.01765 C13.7915,6.01765 15.8253,8.15294 15.8253,11 C15.8253,13.8471 13.8551,15.9176 11.1222,15.9176 Z M15.5711,2.26471 C14.2365,0.647059 12.2662,0 10.296,0 C4.51245,0 0,4.85294 0,11 C0,13.7824 0.953333,16.3706 2.66933,18.3765 C4.576,20.6412 7.56311,21.9353 10.6138,21.9353 C12.4569,21.9353 14.5542,21.1588 15.6347,19.7353 L15.6347,21.5471 L21.8631,21.5471 L21.8631,0.388235 L15.6347,0.388235 L15.6347,2.26471 L15.5711,2.26471 Z" id="Shape"/>
                                            </g>
                                        </g>
                                    </svg>`
                                }
                            ]
                        ]
                    }
                }
            ],
        }),
    };

    window.pdfMake.createPdf(docDefinition).download(`Driver-Plus-(i)-Employee-${respondent.lastName}.pdf`);
}

export const downloadDriverPlusIpsativeClientPdf = (respondent, data) => {
    const docDefinition = {
        styles: {
            font: 'Geometria Light'
        },
        pageOrientation: 'landscape',
        pageMargins: [0, 55, 0, 50],
        header: currentPage => ({
            columns: [
                (currentPage === 1 ? { text: '' } : {
                    width: 148,
                    layout: 'noBorders',
                    table: {
                        layout: {
                            hLineWidth: () => 0,
                            vLineWidth: () => 0,
                            paddingTop: () => 0,
                            paddingBottom: () =>  0,
                            paddingLeft: () => 0,
                            paddingRight: () => 0
                        },
                        widths: [148],
                        heights: [51],
                        body: [
                            [
                                {
                                    text: [
                                        { text: 'Driver+ (i)\n', bold: true, fontSize: currentPage === 1 ? 28 : 18, },
                                        { text: ` ${respondent.firstName} ${respondent.lastName} `, fontSize: 11 }
                                    ],
                                    margin: [15, 5, 0, 0],
                                    fillColor: '#2c3d4f',
                                    style: {
                                        color: '#fff'
                                    },
                                }
                            ]
                        ]
                    },
                }),
                {
                    svg: currentPage === 1 ? `<svg width="2000" height="224" viewBox="0 0 2000 224" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="2000" height="190" fill="#2B3D4F"/>
                        <path d="M264.271 106.828C257.908 106.828 253.281 102.005 253.281 95.4508C253.281 88.8945 257.908 84.0755 264.271 84.0755C270.441 84.0755 275.26 88.8961 275.26 95.4508C275.26 102.007 270.633 106.826 264.271 106.826V106.828ZM264.271 70C249.81 70 238.434 81.1837 238.434 95.4508C238.434 109.719 249.81 120.71 264.271 120.71C278.732 120.71 290.107 109.526 290.107 95.4508C290.107 81.1821 278.732 70 264.271 70V70ZM98.8372 106.828C92.4741 106.828 87.8467 102.005 87.8467 95.4508C87.8467 88.8945 92.4741 84.0755 98.8372 84.0755C105.007 84.0755 109.826 88.8961 109.826 95.4508C109.826 102.007 105.199 106.826 98.8372 106.826V106.828ZM109.248 75.2054C105.97 71.5425 101.537 70 96.9083 70C83.6041 70 73 81.1837 73 95.4508C73 101.814 75.1205 107.791 79.1699 112.418C83.6041 117.625 90.5452 120.708 97.6795 120.708C101.922 120.708 106.741 118.781 109.248 115.696V119.746H123.71V70.9645H109.248V75.2054V75.2054ZM338.886 70.9645H321.34L313.049 82.7262V70.9645H298.203V119.746H313.049V88.5097H326.738L338.886 70.9645ZM217.418 106.828C210.668 106.828 205.849 102.199 205.849 95.4508C205.849 88.8945 210.668 84.0755 217.031 84.0755C220.352 84.0915 223.535 85.4062 225.9 87.7384L233.998 76.1699C229.237 72.1946 223.234 70.0117 217.031 70C202.378 70 190.809 81.1837 190.809 95.4508C190.809 109.913 201.993 120.71 216.838 120.71C223.972 120.71 229.757 118.588 234.77 114.347L226.672 102.585C224.55 104.706 221.272 106.826 217.418 106.826V106.828ZM163.813 70C159.57 70 152.436 72.1205 149.93 75.2054V70.9645H135.468V119.746H150.315V88.8961C152.049 86.774 155.907 84.0738 160.534 84.0738C163.041 84.0738 165.162 84.8467 166.704 86.196C167.86 87.352 169.016 89.2809 169.016 93.9083V119.746H183.863V88.5097C183.863 76.1699 174.805 70 163.814 70H163.813Z" fill="white"/>
                        <path d="M0 190H719.5L696 224H0V190Z" fill="#F54D2E"/>
                    </svg>
                    ` : `<svg width="80px" height="55px" viewBox="0 0 80 55">
                        <polygon stroke="#2c3d4f" stroke-width="1px" fill="#2c3d4f" points="0,0 80,0 0,55" />
                    </svg>`,
                    width: currentPage === 1 ? 900 : undefined,
                    margin: [0, 0, 0, 0],
                    alignment: 'left',
                }
            ],
        }),
        content: [
            {
                text: 'Мотивационный опросник\nDriver+ (i)',
                alignment: 'left',
                color: '#F54D2E',
                margin: [20, 150, 20, 0],
                style: {
                    fontSize: 40,
                    bold: true
                }
            },
            {
                text: 'Отчет для руководителя',
                alignment: 'left',
                color: '#a2a2a2',
                margin: [20, 20, 20, 0],
                style: {
                    fontSize: 24,
                    bold: true
                }
            },
            {
                svg: `<svg width="800" height="1" viewBox="0 0 800 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="800" height="1" fill="#2B3D4F"/>
                </svg>`,
                width: 780,
                margin: [20, 30, 20, 30]
            },
            {
                text: [
                    { text: 'Имя: ', color: '#2B3D4F', style: { fontSize: 16, bold: true }},
                    { text: `${respondent.lastName} ${respondent.firstName} ${respondent.middleName || ''}`, color: '#2B3D4F', style: { fontSize: 16 }}
                ],
                color: '#2B3D4F',
                margin: [20, 0, 20, 0],
            },
            {
                text: [
                    { text: 'Email: ', color: '#2B3D4F', style: { fontSize: 16, bold: true }},
                    { text: respondent.email, color: '#2B3D4F', style: { fontSize: 16 }}
                ],
                color: '#2B3D4F',
                margin: [20, 5, 20, 0],
            },
            {
                text: [
                    { text: 'Дата прохождения опросника: ', color: '#2B3D4F', style: { fontSize: 16, bold: true }},
                    { text: moment(path(['response', 'finishedAt'], respondent)).format('DD.MM.YYYY'), color: '#2B3D4F', style: { fontSize: 16 }}
                ],
                color: '#2B3D4F',
                margin: [20, 5, 20, 0],
            },
            {
                pageBreak: 'before',
                text: 'Что показывает DRIVER+',
                margin: [20, 50, 20, 0],
                color: '#2B3D4F',
                style: {
                    bold: true,
                    fontSize: 25
                }
            },
            {
                text: 'Опросник DRIVER\u002B определяет структуру мотивации человека, выявляет факторы, которые стимулируют работать с полной отдачей. Оценка мотивационных аспектов может решить проблемы, связанные с высокой текучестью кадров и низкой вовлеченностью персонала. ',
                alignment: 'justify',
                margin: [20, 30, 20, 0],
                color: '#2B3D4F'
            },
            {
                text: 'Данный отчёт описывает факторы, влияющие на рабочую мотивацию. В нём представлена детальная информация о факторах, оказывающих на Вашу работу наибольшее и наименьшее влияние. ',
                alignment: 'justify',
                margin: [20, 20, 20, 0],
                color: '#2B3D4F'
            },
            {
                svg: `<svg width="800" height="1" viewBox="0 0 800 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="800" height="1" fill="#2B3D4F"/>
                </svg>`,
                width: 780,
                margin: [20, 20, 20, 20]
            },
            {
                text: 'Данные, представленные в отчёте, полностью конфиденциальны и сохраняют свою актуальность, как правило, в течение года со дня заполнения опросника. Если за это время в личной жизни или работе произошли значительные перемены, мотивационный опросник необходимо будет заполнить заново для получения более актуальной и достоверной информации о мотивации. ',
                alignment: 'justify',
                margin: [20, 0, 20, 0],
                color: '#666'
            },
            {
                pageBreak: 'before',
                svg: window.driverChart.chart.getSVG(),
                width: 400,
                alignment: 'center',
                absolutePosition: {
                    x: 0,
                    y: 58
                }
            },
            {
                svg: `
                <svg width="1000" height="1000" viewBox="0 0 1000 1000" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_501_7321)">
                    <mask id="path-1-inside-1_501_7321" fill="white">
                    <path d="M1000 500C1000 776.142 776.142 1000 500 1000C223.858 1000 0 776.142 0 500C0 223.858 223.858 0 500 0C776.142 0 1000 223.858 1000 500ZM70 500C70 737.482 262.518 930 500 930C737.482 930 930 737.482 930 500C930 262.518 737.482 70 500 70C262.518 70 70 262.518 70 500Z"/>
                    </mask>
                    <path d="M1000 500C1000 776.142 776.142 1000 500 1000C223.858 1000 0 776.142 0 500C0 223.858 223.858 0 500 0C776.142 0 1000 223.858 1000 500ZM70 500C70 737.482 262.518 930 500 930C737.482 930 930 737.482 930 500C930 262.518 737.482 70 500 70C262.518 70 70 262.518 70 500Z" stroke="white" stroke-width="80" mask="url(#path-1-inside-1_501_7321)"/>
                    <path d="M817.92 121.896C819.874 119.568 823.344 119.267 825.643 121.243C910.659 194.336 968.616 293.915 990.176 403.939C990.759 406.914 988.783 409.783 985.794 410.332L928.747 420.802C925.761 421.35 922.893 419.371 922.302 416.384C903.646 322.16 854.009 236.874 781.297 174.113L780.97 174.491L781.297 174.113C778.991 172.123 778.687 168.651 780.639 166.326L817.92 121.896Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M863.399 242.561L864.903 244.672L869.639 241.3L868.046 249.086L869.967 251.784L871.89 242.724L879.698 243.727L877.86 241.146L871.12 240.245L875.166 237.364L873.663 235.252L863.399 242.561Z" fill="white"/>
                    <path d="M875.394 255.933C874.159 254.096 874.674 251.732 876.646 250.407C878.617 249.081 881 249.496 882.236 251.333C883.471 253.17 882.956 255.534 880.984 256.86C879.012 258.185 876.63 257.77 875.394 255.933ZM873.378 257.289C875.487 260.426 879.458 261.074 882.461 259.055C885.478 257.027 886.361 253.114 884.252 249.977C882.143 246.84 878.187 246.182 875.169 248.211C872.167 250.229 871.269 254.152 873.378 257.289Z" fill="white"/>
                    <path d="M878.109 263.696C878.156 264.007 878.356 264.392 878.538 264.681C880.13 267.21 882.277 267.006 887.314 264.026L889.516 262.726L891.549 265.955L882.851 271.431L884.222 273.609L894.885 266.896L890.148 259.371L886.996 261.291C882.799 263.848 881.214 264.506 880.456 263.303C880.246 262.968 880.138 262.695 880.089 262.449L878.109 263.696Z" fill="white"/>
                    <path d="M884.898 274.44C884.936 274.752 885.125 275.142 885.3 275.436C886.823 278.006 888.976 277.86 894.091 275.017L896.326 273.776L898.272 277.059L889.43 282.299L890.742 284.513L901.582 278.089L897.048 270.44L893.846 272.275C889.582 274.718 887.98 275.333 887.255 274.11C887.053 273.769 886.953 273.494 886.911 273.247L884.898 274.44Z" fill="white"/>
                    <path d="M892.656 287.832L897.253 296.088L899.282 294.959L895.945 288.967L898.776 287.391L901.386 292.077L903.383 290.965L900.773 286.279L902.896 285.096L906.136 290.915L908.165 289.785L903.664 281.702L892.656 287.832Z" fill="white"/>
                    <path d="M897.991 297.452L899.201 299.743L908.289 294.943L911.324 300.689L913.378 299.604L909.132 291.566L897.991 297.452Z" fill="white"/>
                    <path d="M828.33 159.314C826.735 157.779 826.739 155.36 828.386 153.648C830.034 151.936 832.451 151.838 834.046 153.373C835.642 154.908 835.637 157.327 833.99 159.039C832.343 160.752 829.926 160.849 828.33 159.314ZM826.646 161.065C829.369 163.686 833.388 163.481 835.897 160.874C838.418 158.254 838.455 154.243 835.731 151.622C833.007 149.001 829.001 149.193 826.48 151.813C823.971 154.421 823.922 158.444 826.646 161.065Z" fill="white"/>
                    <path d="M840.965 157.046L839.313 158.678L842.108 161.508L834.795 168.731L836.616 170.575L843.929 163.353L846.737 166.196L848.389 164.564L840.965 157.046Z" fill="white"/>
                    <path d="M840.299 174.32L842.072 176.21L846.102 172.428L849.994 176.576L845.965 180.358L847.726 182.234L856.914 173.612L855.152 171.735L851.714 174.962L847.821 170.815L851.26 167.587L849.486 165.697L840.299 174.32Z" fill="white"/>
                    <path d="M854.996 187.079C853.527 185.422 853.724 183.011 855.502 181.435C857.281 179.86 859.698 179.955 861.166 181.612C862.634 183.269 862.438 185.68 860.659 187.255C858.881 188.831 856.464 188.736 854.996 187.079ZM853.177 188.691C855.684 191.52 859.706 191.635 862.414 189.236C865.135 186.825 865.492 182.829 862.985 180C860.478 177.171 856.469 177.044 853.748 179.455C851.04 181.854 850.67 185.861 853.177 188.691Z" fill="white"/>
                    <path d="M858.55 194.35L868.63 206.522L878.334 198.485L876.681 196.489L868.765 203.045L866.193 199.939L874.109 193.383L872.467 191.401L864.552 197.957L861.991 194.865L869.907 188.31L868.254 186.313L858.55 194.35Z" fill="white"/>
                    <path d="M870.63 209.079L876.424 216.545L878.258 215.121L874.053 209.703L876.613 207.717L879.902 211.954L881.708 210.553L878.419 206.315L880.339 204.825L884.422 210.087L886.256 208.663L880.584 201.354L870.63 209.079Z" fill="white"/>
                    <path d="M877.403 217.813L878.94 219.9L883.39 216.624L886.763 221.205L882.313 224.481L883.839 226.554L893.985 219.083L892.459 217.01L888.661 219.806L885.289 215.226L889.087 212.43L887.55 210.343L877.403 217.813Z" fill="white"/>
                    <path d="M885.63 229.038L886.871 230.828L897.179 231.195L890.389 235.902L891.855 238.018L902.21 230.839L900.939 229.005L890.717 228.666L897.462 223.99L895.986 221.86L885.63 229.038Z" fill="white"/>
                    <path d="M892.928 239.571L894.56 242.074L900.852 242.399L902.051 244.239L897.784 247.02L899.199 249.192L909.755 242.311L906.57 237.425C905.096 235.164 902.904 234.637 901.08 235.826C899.677 236.74 899.098 238.257 899.589 239.935L892.928 239.571ZM902.185 240.456C901.546 239.475 901.615 238.593 902.445 238.052C903.259 237.521 904.11 237.804 904.749 238.785L906.439 241.378L903.876 243.049L902.185 240.456Z" fill="white"/>
                    <path d="M903.414 255.899L904.533 257.767L914.794 258.818L907.706 263.064L909.029 265.272L919.838 258.797L918.691 256.882L908.514 255.866L915.555 251.647L914.223 249.424L903.414 255.899Z" fill="white"/>
                    <path d="M912.717 271.685L913.964 273.958L922.975 269.014L925.564 273.732L916.553 278.676L917.791 280.933L928.838 274.872L923.764 265.625L912.717 271.685Z" fill="white"/>
                    <path d="M923.741 287.646C922.732 285.675 923.523 283.389 925.638 282.306C927.752 281.223 930.069 281.917 931.079 283.888C932.088 285.858 931.297 288.144 929.182 289.227C927.067 290.31 924.75 289.616 923.741 287.646ZM921.578 288.753C923.301 292.118 927.168 293.232 930.388 291.582C933.625 289.925 934.965 286.144 933.242 282.78C931.518 279.416 927.668 278.294 924.431 279.951C921.211 281.6 919.855 285.389 921.578 288.753Z" fill="white"/>
                    <path d="M922.845 296.311L923.887 298.507L926.212 297.403L929.91 305.191L927.585 306.295L928.627 308.49L933.05 306.391L932.309 304.83L941.594 300.421L937.795 292.421L935.99 293.278C932.137 295.108 929.84 295.601 927.8 295.334L927.268 294.212L922.845 296.311ZM929.028 297.919C931.813 297.951 934.444 296.862 936.802 295.742L938.384 299.076L931.197 302.488L929.028 297.919Z" fill="white"/>
                    <path d="M929.057 309.39L930.033 311.615L932.391 310.582L935.853 318.478L933.495 319.512L934.471 321.737L938.955 319.771L938.261 318.189L947.674 314.062L944.118 305.951L942.288 306.753C938.381 308.466 936.071 308.89 934.04 308.561L933.541 307.424L929.057 309.39ZM935.189 311.183C937.972 311.299 940.635 310.289 943.025 309.241L944.507 312.62L937.22 315.815L935.189 311.183Z" fill="white"/>
                    <path d="M937.623 322.633L941.183 331.387L943.334 330.512L940.751 324.159L943.752 322.938L945.773 327.907L947.89 327.046L945.87 322.077L948.12 321.162L950.629 327.331L952.78 326.456L949.295 317.886L937.623 322.633Z" fill="white"/>
                    <path d="M941.919 333.257L942.838 335.68L947.5 333.912L948.534 336.639C949.511 339.214 951.59 340.177 953.66 339.392C955.73 338.607 956.63 336.514 955.654 333.939L953.701 328.789L941.919 333.257ZM949.571 333.127L952.499 332.017L953.469 334.575C953.903 335.719 953.666 336.618 952.707 336.981C951.765 337.339 950.975 336.83 950.541 335.685L949.571 333.127Z" fill="white"/>
                    <path d="M945.764 343.238L946.857 346.422L953.579 348.872L948.319 350.678L949.16 353.129L954.421 351.323L950.621 357.385L951.72 360.586L956.164 353.408L963.462 355.984L962.428 352.971L956.055 350.762L961.077 349.038L960.236 346.586L955.214 348.311L958.891 342.671L957.857 339.657L953.674 346.156L945.764 343.238Z" fill="white"/>
                    <path d="M952.057 361.812L952.821 364.289L958.377 362.577L954.416 369.467L955.391 372.632L960.066 364.637L967.162 368.044L966.23 365.017L960.114 362.042L964.862 360.579L964.099 358.102L952.057 361.812Z" fill="white"/>
                    <path d="M955.448 372.796L956.178 375.414L959.32 375.828L960.751 380.96L958.275 382.939L959.015 385.592L969.772 376.988L969.105 374.596L955.448 372.796ZM961.601 376.033L966.312 376.644L962.592 379.587L961.601 376.033Z" fill="white"/>
                    <path d="M986.582 414.71C989.575 414.183 992.427 416.183 992.918 419.175C1011.06 529.814 991.45 643.35 937.243 741.492C935.778 744.146 932.42 745.074 929.778 743.573L879.347 714.924C876.707 713.425 875.782 710.065 877.249 707.397C923.524 623.226 940.321 525.987 924.962 431.169C924.475 428.164 926.474 425.309 929.463 424.782L986.582 414.71Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M930.529 657.596L931.356 655.139L923.184 652.39L927.964 650.883L928.423 649.519L925.509 645.48L933.68 648.23L934.495 645.807L922.553 641.789L921.738 644.212L925.52 649.34L919.408 651.138L918.587 653.578L930.529 657.596Z" fill="white"/>
                    <path d="M935.228 643.57L936.017 640.969L933.587 638.934L935.133 633.835L938.284 633.493L939.083 630.858L925.389 632.347L924.669 634.724L935.228 643.57ZM931.772 637.537L928.124 634.494L932.843 634.006L931.772 637.537Z" fill="white"/>
                    <path d="M927.204 626.516L929.442 627.134L930.5 623.299L940.408 626.032L941.097 623.533L931.189 620.8L932.252 616.948L930.013 616.33L927.204 626.516Z" fill="white"/>
                    <path d="M942.311 619.072L944.621 609.908L942.37 609.341L940.693 615.991L937.551 615.198L938.863 609.997L936.646 609.438L935.335 614.64L932.978 614.045L934.607 607.588L932.355 607.02L930.093 615.991L942.311 619.072Z" fill="white"/>
                    <path d="M945.146 607.717L945.72 605.189L940.858 604.085L941.504 601.241C942.113 598.556 940.914 596.604 938.755 596.113C936.596 595.623 934.688 596.87 934.079 599.555L932.859 604.927L945.146 607.717ZM938.699 603.595L935.645 602.901L936.25 600.233C936.521 599.039 937.215 598.422 938.216 598.649C939.199 598.872 939.576 599.733 939.305 600.927L938.699 603.595Z" fill="white"/>
                    <path d="M947.861 595.642L948.286 593.506L941.387 585.838L949.49 587.45L949.993 584.926L937.635 582.467L937.199 584.656L944.059 592.243L936.009 590.641L935.503 593.183L947.861 595.642Z" fill="white"/>
                    <path d="M950.227 583.773L950.685 581.094L948.021 579.378L948.92 574.126L952.003 573.394L952.468 570.679L939.067 573.864L938.647 576.312L950.227 583.773ZM946.046 578.218L942.048 575.653L946.669 574.581L946.046 578.218Z" fill="white"/>
                    <path d="M952.471 570.006C952.637 569.739 952.733 569.316 952.781 568.977C953.201 566.019 951.431 564.786 945.662 563.803L943.141 563.372L943.678 559.594L953.854 561.039L954.216 558.491L941.741 556.719L940.491 565.523L944.136 566.095C948.992 566.857 950.626 567.38 950.426 568.788C950.371 569.18 950.277 569.458 950.155 569.677L952.471 570.006Z" fill="white"/>
                    <path d="M954.656 555.111L955.267 549.782C955.613 546.759 954.07 544.734 951.638 544.456C949.206 544.177 947.226 545.798 946.88 548.82L946.564 551.574L942.433 551.1L942.138 553.675L954.656 555.111ZM952.68 552.275L948.746 551.824L949.045 549.213C949.215 547.729 950.035 546.899 951.305 547.044C952.557 547.188 953.149 548.18 952.979 549.664L952.68 552.275Z" fill="white"/>
                    <path d="M956.088 542.387L956.314 539.805L950.809 539.323L951.305 533.657L956.81 534.138L957.034 531.574L944.482 530.476L944.257 533.04L948.956 533.451L948.46 539.118L943.762 538.707L943.536 541.289L956.088 542.387Z" fill="white"/>
                    <path d="M955.479 521.815C955.352 524.026 953.513 525.597 951.141 525.461C948.769 525.326 947.122 523.554 947.248 521.344C947.375 519.134 949.213 517.562 951.586 517.698C953.958 517.834 955.605 519.605 955.479 521.815ZM957.905 521.954C958.121 518.18 955.349 515.263 951.737 515.056C948.107 514.849 945.038 517.431 944.822 521.205C944.606 524.979 947.36 527.895 950.99 528.103C954.602 528.31 957.689 525.728 957.905 521.954Z" fill="white"/>
                    <path d="M958.096 513.238L958.381 503.793L956.06 503.722L955.853 510.577L952.615 510.479L952.777 505.118L950.492 505.049L950.33 510.41L947.901 510.337L948.102 503.68L945.781 503.61L945.501 512.857L958.096 513.238Z" fill="white"/>
                    <path d="M950.56 678.045L952.77 672.107C953.705 669.593 952.929 667.499 950.938 666.758C949.117 666.08 947.585 666.893 946.892 667.98C946.869 667.164 946.382 666.099 945.1 665.622C943.143 664.894 941.589 666.025 940.817 668.1L938.751 673.65L950.56 678.045ZM949.372 674.837L946.285 673.688L947.421 670.635C947.861 669.454 948.694 668.919 949.689 669.289C950.684 669.66 950.948 670.603 950.508 671.784L949.372 674.837ZM944.277 672.941L941.68 671.974L942.697 669.241C943.073 668.229 943.772 667.798 944.615 668.112C945.425 668.413 945.671 669.196 945.295 670.208L944.277 672.941Z" fill="white"/>
                    <path d="M955.045 659.186C954.332 661.282 952.14 662.305 949.89 661.54C947.641 660.775 946.527 658.628 947.239 656.532C947.952 654.436 950.144 653.413 952.394 654.178C954.643 654.943 955.757 657.09 955.045 659.186ZM957.345 659.968C958.562 656.389 956.671 652.838 953.245 651.673C949.803 650.502 946.156 652.171 944.939 655.75C943.722 659.329 945.596 662.875 949.039 664.045C952.464 665.21 956.128 663.547 957.345 659.968Z" fill="white"/>
                    <path d="M961.225 648.078C962.228 644.846 961.09 642.439 959.01 641.793C957.411 641.297 956.174 642.063 955.482 642.96C955.408 642.165 954.922 640.997 953.581 640.581C951.57 639.957 949.611 641.29 948.742 644.092C948.267 645.622 948.193 647.258 948.404 648.341L950.69 649.05C950.514 647.978 950.505 646.486 950.991 644.922C951.423 643.529 952.154 642.814 952.962 643.064C953.787 643.32 954.093 644.094 953.683 645.418L953.123 647.223L955.203 647.868L955.672 646.355C956.163 644.774 956.983 643.954 957.963 644.258C959.063 644.599 959.38 645.885 958.815 647.707C958.399 649.048 957.422 650.498 956.352 651.334L958.758 652.081C959.799 651.216 960.623 650.021 961.225 648.078Z" fill="white"/>
                    <path d="M963.237 640.703L963.942 638.209L958.624 636.706L960.172 631.232L965.489 632.735L966.189 630.258L954.064 626.831L953.364 629.308L957.902 630.591L956.355 636.064L951.817 634.781L951.112 637.276L963.237 640.703Z" fill="white"/>
                    <path d="M966.78 628.146L967.445 625.51L964.921 623.593L966.223 618.427L969.355 617.935L970.028 615.265L956.42 617.403L955.813 619.811L966.78 628.146ZM963.042 622.284L959.254 619.417L963.944 618.706L963.042 622.284Z" fill="white"/>
                    <path d="M970.432 613.45L971.002 610.921L960.975 608.661L962.404 602.322L960.139 601.812L958.14 610.679L970.432 613.45Z" fill="white"/>
                    <path d="M972.696 603.334L973.21 600.793L968.322 599.805L968.901 596.947C969.446 594.247 968.201 592.324 966.031 591.885C963.861 591.446 961.984 592.738 961.438 595.437L960.346 600.836L972.696 603.334ZM966.152 599.366L963.083 598.745L963.625 596.063C963.868 594.864 964.547 594.23 965.552 594.433C966.54 594.633 966.937 595.484 966.695 596.684L966.152 599.366Z" fill="white"/>
                    <path d="M974.933 592.287L975.4 589.61L972.741 587.885L973.657 582.636L976.743 581.914L977.216 579.201L963.805 582.343L963.378 584.79L974.933 592.287ZM970.77 586.719L966.78 584.141L971.404 583.084L970.77 586.719Z" fill="white"/>
                    <path d="M977.336 578.747L977.804 575.413L972.921 570.184L978.429 570.957L978.79 568.39L973.282 567.617L979.415 563.934L979.886 560.583L972.672 564.968L967.333 559.366L966.89 562.521L971.571 567.377L966.312 566.638L965.952 569.205L971.21 569.943L965.376 573.305L964.933 576.46L971.606 572.562L977.336 578.747Z" fill="white"/>
                    <path d="M982.45 560.593L982.707 558.177L980.148 557.904L981.062 549.33L983.622 549.603L983.88 547.187L979.011 546.668L978.828 548.386L968.608 547.296L967.669 556.102L969.655 556.314C973.897 556.766 976.113 557.546 977.713 558.839L977.581 560.074L982.45 560.593ZM978.017 555.993C975.658 554.511 972.846 554.066 970.25 553.789L970.642 550.12L978.553 550.964L978.017 555.993Z" fill="white"/>
                    <path d="M981.52 544.534L982.269 535.114L979.955 534.93L979.411 541.766L976.181 541.509L976.606 536.162L974.328 535.981L973.902 541.328L971.48 541.135L972.008 534.496L969.693 534.312L968.96 543.535L981.52 544.534Z" fill="white"/>
                    <path d="M982.471 532.233L982.606 529.644L977.088 529.355L977.386 523.675L982.905 523.964L983.039 521.394L970.457 520.734L970.322 523.304L975.031 523.551L974.733 529.231L970.024 528.984L969.888 531.573L982.471 532.233Z" fill="white"/>
                    <path d="M983.14 518.933L983.196 516.756L975.093 510.373L983.353 510.584L983.418 508.011L970.822 507.69L970.766 509.921L978.816 516.23L970.61 516.021L970.544 518.612L983.14 518.933Z" fill="white"/>
                    <path d="M983.45 504.597L983.436 495.147L981.114 495.15L981.124 502.008L977.884 502.013L977.876 496.649L975.59 496.653L975.598 502.017L973.168 502.02L973.158 495.36L970.836 495.364L970.85 504.616L983.45 504.597Z" fill="white"/>
                    <path d="M927.566 747.433C930.198 748.952 931.097 752.318 929.55 754.925C872.331 851.342 784.329 925.71 679.72 966.048C676.891 967.139 673.722 965.691 672.663 962.843L652.446 908.481C651.388 905.635 652.839 902.467 655.678 901.366C745.231 866.632 820.601 802.939 869.783 720.433C871.342 717.817 874.708 716.915 877.337 718.433L927.566 747.433Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M686.431 937.109C687.636 936.585 688.679 935.779 689.351 934.84L688.311 932.446C687.702 933.574 686.751 934.32 685.578 934.829C683.333 935.804 680.958 934.991 679.991 932.762C679.03 930.549 680.016 928.257 682.196 927.311C683.484 926.751 684.535 926.687 685.621 926.883L684.581 924.489C683.482 924.397 682.351 924.594 681.211 925.089C677.629 926.644 676.029 930.282 677.563 933.816C679.098 937.349 682.881 938.65 686.431 937.109Z" fill="white"/>
                    <path d="M697.478 929.451C695.473 930.391 693.216 929.521 692.208 927.37C691.199 925.218 691.974 922.927 693.978 921.987C695.983 921.047 698.24 921.918 699.248 924.069C700.257 926.22 699.482 928.512 697.478 929.451ZM698.509 931.652C701.932 930.047 703.18 926.222 701.644 922.946C700.101 919.654 696.369 918.182 692.947 919.787C689.524 921.391 688.269 925.201 689.812 928.493C691.348 931.769 695.087 933.256 698.509 931.652Z" fill="white"/>
                    <path d="M716.383 925.47L718.567 924.364L716.355 919.996L714.797 920.785L710.155 911.615L707.842 912.786L712.485 921.956L707.78 924.338L703.137 915.168L700.825 916.339L706.516 927.58L715.22 923.174L716.383 925.47Z" fill="white"/>
                    <path d="M719.422 921.011L721.336 919.972L722.821 909.765L726.763 917.026L729.025 915.798L723.014 904.725L721.052 905.789L719.605 915.914L715.689 908.7L713.411 909.937L719.422 921.011Z" fill="white"/>
                    <path d="M731.094 914.667L733.445 913.302L733.055 910.157L737.664 907.482L740.202 909.381L742.583 907.998L731.552 899.749L729.404 900.996L731.094 914.667ZM732.681 907.898L732.089 903.184L735.872 906.045L732.681 907.898Z" fill="white"/>
                    <path d="M742.051 908.206C742.363 908.162 742.749 907.965 743.04 907.786C745.583 906.217 745.399 904.067 742.465 899.004L741.184 896.791L744.431 894.787L749.829 903.534L752.019 902.182L745.403 891.459L737.835 896.128L739.727 899.297C742.246 903.517 742.89 905.108 741.679 905.855C741.342 906.063 741.069 906.168 740.823 906.214L742.051 908.206Z" fill="white"/>
                    <path d="M754.905 900.37L759.392 897.431C761.937 895.765 762.507 893.283 761.165 891.235C759.824 889.187 757.312 888.702 754.767 890.369L752.448 891.888L750.17 888.409L748.002 889.829L754.905 900.37ZM755.821 897.037L753.651 893.725L755.85 892.285C757.1 891.466 758.262 891.566 758.962 892.635C759.652 893.689 759.269 894.779 758.019 895.598L755.821 897.037Z" fill="white"/>
                    <path d="M765.585 893.36L767.715 891.882L764.564 887.343L769.236 884.099L772.387 888.639L774.502 887.171L767.318 876.82L765.203 878.288L767.892 882.162L763.219 885.405L760.53 881.531L758.401 883.009L765.585 893.36Z" fill="white"/>
                    <path d="M776.414 885.845L778.602 884.233L777.872 881.148L782.161 877.987L784.891 879.597L787.107 877.963L775.242 870.967L773.242 872.441L776.414 885.845ZM777.253 878.944L776.15 874.323L780.223 876.754L777.253 878.944Z" fill="white"/>
                    <path d="M787.904 877.37L790.258 875.529L790.041 869.232L791.771 867.879L794.909 871.892L796.951 870.295L789.189 860.37L784.595 863.962C782.468 865.626 782.131 867.854 783.473 869.57C784.504 870.888 786.065 871.336 787.694 870.702L787.904 877.37ZM787.99 868.071C787.069 868.791 786.183 868.799 785.573 868.019C784.974 867.253 785.184 866.381 786.105 865.66L788.544 863.753L790.429 866.164L787.99 868.071Z" fill="white"/>
                    <path d="M803.606 868.277L805.462 866.709L803.801 864.743L810.386 859.178L812.048 861.144L813.904 859.575L810.744 855.836L809.424 856.951L802.79 849.101L796.026 854.817L797.315 856.343C800.069 859.601 801.132 861.696 801.395 863.736L800.446 864.538L803.606 868.277ZM803.581 861.889C802.901 859.188 801.175 856.922 799.49 854.929L802.309 852.547L807.444 858.624L803.581 861.889Z" fill="white"/>
                    <path d="M815.024 855.168L822.065 848.866L820.517 847.136L815.407 851.709L813.246 849.295L817.243 845.718L815.718 844.014L811.721 847.592L810.101 845.781L815.063 841.339L813.514 839.609L806.621 845.78L815.024 855.168Z" fill="white"/>
                    <path d="M822.613 848.4L824.788 846.351L823.995 840.101L825.594 838.595L829.086 842.304L830.973 840.527L822.335 831.354L818.089 835.352C816.124 837.203 815.992 839.453 817.486 841.038C818.633 842.257 820.229 842.56 821.793 841.779L822.613 848.4ZM821.847 839.132C820.995 839.934 820.114 840.022 819.435 839.301C818.769 838.594 818.897 837.706 819.749 836.904L822.003 834.782L824.101 837.009L821.847 839.132Z" fill="white"/>
                    <path d="M824.177 829.584L825.811 831.234L828.637 828.434L835.871 835.736L837.712 833.912L830.479 826.61L833.318 823.798L831.683 822.148L824.177 829.584Z" fill="white"/>
                    <path d="M840.856 830.785L847.42 823.987L845.75 822.374L840.986 827.307L838.655 825.057L842.381 821.198L840.737 819.61L837.011 823.469L835.263 821.781L839.889 816.99L838.219 815.377L831.792 822.032L840.856 830.785Z" fill="white"/>
                    <path d="M848.677 822.542C848.964 822.415 849.283 822.121 849.513 821.868C851.53 819.664 850.766 817.646 846.559 813.578L844.722 811.799L847.298 808.984L854.881 815.922L856.618 814.023L847.322 805.518L841.32 812.078L844.006 814.609C847.583 817.979 848.636 819.334 847.677 820.383C847.409 820.675 847.175 820.851 846.951 820.963L848.677 822.542Z" fill="white"/>
                    <path d="M858.893 811.48L862.406 807.427C864.398 805.128 864.267 802.585 862.417 800.982C860.568 799.379 858.018 799.599 856.026 801.898L854.211 803.993L851.069 801.27L849.371 803.228L858.893 811.48ZM858.863 808.025L855.87 805.431L857.591 803.445C858.57 802.316 859.715 802.094 860.681 802.931C861.633 803.756 861.562 804.91 860.584 806.039L858.863 808.025Z" fill="white"/>
                    <path d="M867.271 801.775L868.915 799.771L864.643 796.266L868.251 791.869L872.523 795.374L874.155 793.384L864.414 785.392L862.782 787.382L866.428 790.373L862.82 794.771L859.174 791.779L857.53 793.783L867.271 801.775Z" fill="white"/>
                    <path d="M877.834 785.161C876.48 786.912 874.074 787.168 872.195 785.714C870.316 784.26 869.959 781.867 871.314 780.116C872.669 778.365 875.074 778.109 876.953 779.563C878.833 781.017 879.189 783.41 877.834 785.161ZM879.756 786.648C882.069 783.658 881.434 779.685 878.572 777.471C875.697 775.246 871.705 775.64 869.392 778.629C867.079 781.619 867.7 785.581 870.576 787.806C873.437 790.02 877.443 789.637 879.756 786.648Z" fill="white"/>
                    <path d="M889.137 774.134C889.911 773.072 890.402 771.849 890.505 770.699L888.396 769.162C888.442 770.443 888 771.568 887.248 772.601C885.806 774.579 883.346 775.081 881.383 773.65C879.433 772.229 879.122 769.753 880.522 767.833C881.349 766.698 882.223 766.11 883.258 765.729L881.149 764.191C880.155 764.669 879.279 765.412 878.548 766.416C876.247 769.572 876.711 773.519 879.824 775.788C882.937 778.057 886.858 777.262 889.137 774.134Z" fill="white"/>
                    <path d="M881.826 762.316L883.735 763.638L886 760.368L894.448 766.222L895.925 764.091L887.476 758.238L889.752 754.953L887.844 753.631L881.826 762.316Z" fill="white"/>
                    <path d="M898.809 759.899L901.758 755.418C903.43 752.876 902.966 750.373 900.921 749.027C898.876 747.682 896.378 748.236 894.706 750.777L893.182 753.093L889.708 750.807L888.284 752.972L898.809 759.899ZM898.324 756.477L895.016 754.3L896.461 752.105C897.282 750.857 898.388 750.485 899.456 751.188C900.508 751.881 900.59 753.033 899.769 754.281L898.324 756.477Z" fill="white"/>
                    <path d="M330.572 964.037C329.533 966.893 326.374 968.363 323.538 967.292C218.65 927.684 130.131 853.933 72.2401 757.918C70.6748 755.322 71.5504 751.95 74.1715 750.413L124.197 721.063C126.815 719.526 130.188 720.405 131.765 723.01C181.522 805.17 257.335 868.335 347.128 902.443C349.975 903.524 351.447 906.682 350.409 909.535L330.572 964.037Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M128.501 790.677C127.12 788.946 127.441 786.549 129.299 785.067C131.156 783.585 133.565 783.805 134.946 785.535C136.326 787.266 136.005 789.663 134.148 791.145C132.291 792.627 129.882 792.407 128.501 790.677ZM126.602 792.192C128.959 795.147 132.97 795.47 135.798 793.214C138.641 790.946 139.202 786.975 136.845 784.02C134.488 781.065 130.491 780.731 127.648 782.998C124.82 785.255 124.244 789.237 126.602 792.192Z" fill="white"/>
                    <path d="M131.757 798.199L135.26 802.355C137.186 804.64 139.642 804.9 141.486 803.345C143.317 801.802 143.503 799.315 141.577 797.03L139.744 794.856L141.285 793.556L145.508 798.566L147.284 797.069L141.39 790.078L131.757 798.199ZM135.175 798.707L138.093 796.248L139.798 798.271C140.749 799.399 140.781 800.526 139.845 801.315C138.909 802.104 137.832 801.859 136.88 800.731L135.175 798.707Z" fill="white"/>
                    <path d="M148.348 821.126L149.988 822.945L153.624 819.666L152.455 818.369L160.088 811.486L158.364 809.575L150.731 816.458L148.031 813.463L155.664 806.58L153.94 804.669L146.307 811.551L143.607 808.557L151.24 801.674L149.505 799.749L140.147 808.187L150.26 819.403L148.348 821.126Z" fill="white"/>
                    <path d="M153.294 822.635L159.841 829.449L161.515 827.84L156.764 822.895L159.1 820.65L162.817 824.518L164.465 822.934L160.749 819.066L162.501 817.383L167.115 822.185L168.79 820.576L162.379 813.905L153.294 822.635Z" fill="white"/>
                    <path d="M162.047 831.709L163.893 833.529L167.773 829.594L171.823 833.588L167.943 837.523L169.775 839.33L178.623 830.359L176.79 828.552L173.479 831.91L169.429 827.916L172.74 824.558L170.895 822.738L162.047 831.709Z" fill="white"/>
                    <path d="M171.229 840.745L172.82 842.232L182.972 840.411L177.331 846.447L179.212 848.205L187.815 838.999L186.184 837.475L176.122 839.306L181.726 833.309L179.832 831.539L171.229 840.745Z" fill="white"/>
                    <path d="M181.932 850.679L189.007 856.943L190.546 855.204L185.411 850.658L187.559 848.233L191.575 851.788L193.091 850.077L189.075 846.521L190.685 844.702L195.672 849.116L197.211 847.378L190.284 841.245L181.932 850.679Z" fill="white"/>
                    <path d="M199.201 865.859C200.215 866.696 201.407 867.259 202.548 867.431L204.209 865.418C202.928 865.387 201.831 864.879 200.845 864.065C198.957 862.507 198.604 860.022 200.15 858.148C201.685 856.288 204.176 856.126 206.009 857.638C207.091 858.532 207.626 859.439 207.945 860.496L209.606 858.482C209.188 857.461 208.499 856.543 207.541 855.752C204.529 853.266 200.561 853.493 198.109 856.464C195.658 859.435 196.216 863.396 199.201 865.859Z" fill="white"/>
                    <path d="M206.838 871.62C206.99 871.895 207.311 872.187 207.583 872.395C209.955 874.211 211.898 873.273 215.583 868.727L217.194 866.742L220.224 869.062L213.975 877.222L216.019 878.787L223.679 868.783L216.62 863.377L214.333 866.274C211.288 870.131 210.031 871.3 208.902 870.435C208.588 870.194 208.392 869.976 208.261 869.763L206.838 871.62Z" fill="white"/>
                    <path d="M234.852 876.608C232.303 874.788 229.031 875.281 226.877 877.459L225.383 876.392L228.334 872.262L226.196 870.734L218.871 880.987L221.01 882.514L224.013 878.311L225.492 879.368C224.157 882.196 224.598 885.387 227.234 887.271C230.12 889.332 233.905 888.431 236.008 885.487C238.111 882.543 237.737 878.67 234.852 876.608ZM228.647 885.294C227.036 884.143 226.766 881.981 228.231 879.931C229.706 877.865 231.828 877.435 233.439 878.586C235.065 879.747 235.345 881.894 233.87 883.959C232.405 886.01 230.273 886.455 228.647 885.294Z" fill="white"/>
                    <path d="M232.507 893.833L234.532 895.176L235.955 893.031L243.14 897.796L241.718 899.941L243.743 901.284L246.449 897.203L245.009 896.248L250.688 887.682L243.308 882.788L242.203 884.453C239.846 888.009 238.133 889.616 236.248 890.439L235.212 889.753L232.507 893.833ZM238.633 892.021C241.036 890.612 242.727 888.321 244.17 886.146L247.245 888.185L242.848 894.816L238.633 892.021Z" fill="white"/>
                    <path d="M246.37 899.906L250.919 902.749C253.498 904.361 255.99 903.838 257.288 901.762C258.585 899.687 257.972 897.202 255.393 895.59L253.042 894.121L255.246 890.595L253.048 889.221L246.37 899.906ZM249.78 899.341L251.878 895.983L254.107 897.376C255.374 898.168 255.771 899.265 255.093 900.349C254.426 901.417 253.275 901.526 252.008 900.734L249.78 899.341Z" fill="white"/>
                    <path d="M258.135 907.233L260.373 908.539L264.72 901.093L265.228 906.079L266.471 906.805L271.016 904.769L266.669 912.215L268.876 913.504L275.229 902.622L273.021 901.334L267.234 903.999L266.71 897.649L264.487 896.351L258.135 907.233Z" fill="white"/>
                    <path d="M271.954 915.238L273.868 916.279L283.239 911.972L279.291 919.229L281.552 920.459L287.574 909.391L285.613 908.325L276.33 912.619L280.253 905.409L277.976 904.17L271.954 915.238Z" fill="white"/>
                    <path d="M71.9334 746.567C69.3017 748.086 65.9374 747.182 64.4532 744.539C9.56334 646.777 -10.8398 533.381 6.53039 422.618C7.00007 419.623 9.83824 417.602 12.8346 418.109L70.0223 427.782C73.0154 428.288 75.0338 431.129 74.5679 434.138C59.8718 529.06 77.3463 626.179 124.208 710.025C125.693 712.683 124.792 716.049 122.163 717.567L71.9334 746.567Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M28.2933 532.878L28.5739 535.454L38.7915 534.342L39.374 539.692L29.1564 540.805L29.435 543.364L41.961 542L40.8193 531.514L28.2933 532.878Z" fill="white"/>
                    <path d="M29.7893 546.251L30.135 548.82L35.0764 548.155L35.4653 551.045C35.8326 553.774 37.6387 555.184 39.8329 554.888C42.0271 554.593 43.3786 552.759 43.0113 550.029L42.2768 544.57L29.7893 546.251ZM37.2707 547.859L40.3747 547.442L40.7395 550.153C40.9028 551.366 40.4684 552.188 39.4516 552.324C38.4526 552.459 37.7987 551.784 37.6355 550.571L37.2707 547.859Z" fill="white"/>
                    <path d="M31.3277 557.582L31.6726 559.733L40.8082 564.52L32.6505 565.829L33.0581 568.371L45.4991 566.375L45.1456 564.171L36.0747 559.446L44.1791 558.146L43.7686 555.587L31.3277 557.582Z" fill="white"/>
                    <path d="M34.1057 575.514C34.7313 578.84 36.8629 580.435 39.0034 580.033C40.6485 579.723 41.3831 578.468 41.576 577.351C42.0131 578.019 42.9889 578.825 44.3687 578.565C46.4384 578.176 47.5462 576.081 47.0038 573.198C46.7077 571.623 46.008 570.143 45.3153 569.285L42.9626 569.727C43.6199 570.592 44.325 571.907 44.6278 573.517C44.8973 574.949 44.5861 575.924 43.7546 576.08C42.9055 576.24 42.2727 575.7 42.0165 574.337L41.6671 572.48L39.5267 572.883L39.8195 574.439C40.1256 576.067 39.7846 577.175 38.7763 577.365C37.6441 577.578 36.7623 576.59 36.4096 574.714C36.15 573.335 36.3359 571.596 36.8903 570.356L34.4137 570.822C33.898 572.073 33.7297 573.515 34.1057 575.514Z" fill="white"/>
                    <path d="M35.7749 582.955L36.3183 585.489L41.7215 584.331L42.914 589.892L37.5108 591.051L38.0505 593.568L50.3704 590.926L49.8308 588.409L45.2196 589.398L44.0271 583.836L48.6383 582.848L48.0948 580.313L35.7749 582.955Z" fill="white"/>
                    <path d="M38.508 595.67L39.1532 598.31L42.2807 598.825L43.5456 604L41.0078 605.899L41.6616 608.574L52.6894 600.32L52.0997 597.907L38.508 595.67ZM44.5538 599.103L49.2429 599.865L45.4298 602.687L44.5538 599.103Z" fill="white"/>
                    <path d="M42.1599 610.447L42.8467 612.947L48.1752 611.482L49.6823 616.967L44.3538 618.431L45.0359 620.913L57.1855 617.575L56.5035 615.093L51.9561 616.342L50.4489 610.858L54.9964 609.608L54.3096 607.109L42.1599 610.447Z" fill="white"/>
                    <path d="M45.6618 623.107L46.2952 625.191L55.9956 628.695L48.0907 631.098L48.8393 633.561L60.8947 629.897L60.2456 627.761L50.6178 624.31L58.471 621.923L57.7172 619.443L45.6618 623.107Z" fill="white"/>
                    <path d="M49.9111 636.905L52.9019 645.869L55.1046 645.134L52.9341 638.629L56.0076 637.603L57.7052 642.691L59.8737 641.968L58.1761 636.88L60.4812 636.111L62.589 642.428L64.7916 641.693L61.8635 632.917L49.9111 636.905Z" fill="white"/>
                    <path d="M13.5919 413.725C10.5992 413.198 8.6031 410.342 9.16531 407.363C29.9574 297.191 87.2172 197.21 171.721 123.525C174.006 121.533 177.479 121.81 179.448 124.124L217.039 168.293C219.007 170.605 218.727 174.078 216.436 176.084C144.163 239.352 95.1223 324.982 77.1251 419.334C76.5545 422.325 73.7001 424.324 70.7107 423.797L13.5919 413.725Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M53.6279 393.123C53.9536 391.85 53.9565 390.531 53.6264 389.425L51.0979 388.778C51.6153 389.951 51.6217 391.16 51.305 392.398C50.6982 394.77 48.5994 396.147 46.2452 395.544C43.9085 394.946 42.7025 392.761 43.2915 390.46C43.6395 389.099 44.2336 388.229 45.0541 387.492L42.5256 386.845C41.7789 387.657 41.2407 388.671 40.9328 389.875C39.9646 393.659 41.8576 397.153 45.5894 398.108C49.3211 399.063 52.6686 396.872 53.6279 393.123Z" fill="white"/>
                    <path d="M42.5868 384.517L44.8219 385.146L45.8998 381.317L55.7933 384.102L56.4957 381.607L46.6022 378.822L47.685 374.975L45.4499 374.346L42.5868 384.517Z" fill="white"/>
                    <path d="M57.2934 378.67L58.0922 376.072L55.67 374.028L57.2358 368.936L60.388 368.605L61.1974 365.973L47.498 367.411L46.768 369.785L57.2934 378.67ZM53.8606 372.625L50.2246 369.567L54.945 369.098L53.8606 372.625Z" fill="white"/>
                    <path d="M61.625 364.691L63.3624 359.54C64.3174 356.709 63.2667 354.473 60.9812 353.703C58.7128 352.937 56.4888 354.068 55.5338 356.899L54.6248 359.594L52.7146 358.95L54.8087 352.741L52.6085 351.999L49.6859 360.664L61.625 364.691ZM60.2874 361.504L56.6715 360.284L57.5172 357.777C57.989 356.379 58.936 355.767 60.0958 356.158C61.2556 356.55 61.6048 357.598 61.1331 358.997L60.2874 361.504Z" fill="white"/>
                    <path d="M65.6421 352.837L66.3936 350.793L60.7696 342.147L68.5241 344.998L69.4123 342.582L57.5863 338.234L56.8161 340.329L62.4143 348.889L54.7104 346.057L53.816 348.489L65.6421 352.837Z" fill="white"/>
                    <path d="M69.8886 340.961C70.1126 340.74 70.3066 340.353 70.4337 340.035C71.5444 337.261 70.1178 335.643 64.7472 333.318L62.4011 332.301L63.8196 328.759L73.3611 332.579L74.3179 330.19L62.6208 325.506L59.3154 333.761L62.7209 335.182C67.2561 337.076 68.7198 337.972 68.1912 339.292C68.044 339.66 67.8868 339.907 67.7163 340.091L69.8886 340.961Z" fill="white"/>
                    <path d="M75.4998 327.325L77.6272 322.401C78.8336 319.609 77.9424 317.224 75.6951 316.253C73.4479 315.282 71.0839 316.261 69.8774 319.053L68.7781 321.598L64.9611 319.949L63.9331 322.328L75.4998 327.325ZM74.4293 324.039L70.794 322.469L71.8362 320.056C72.4287 318.685 73.4538 318.128 74.627 318.635C75.7836 319.134 76.064 320.255 75.4715 321.627L74.4293 324.039Z" fill="white"/>
                    <path d="M80.4611 315.886L81.5527 313.535L76.5406 311.208L78.9361 306.049L83.9482 308.376L85.0322 306.041L73.6041 300.735L72.5201 303.069L76.7975 305.055L74.402 310.214L70.1246 308.228L69.033 310.579L80.4611 315.886Z" fill="white"/>
                    <path d="M86.7783 297.552C85.789 299.533 83.4791 300.251 81.3534 299.189C79.2278 298.127 78.4137 295.85 79.4029 293.869C80.3921 291.888 82.7021 291.171 84.8278 292.232C86.9534 293.294 87.7675 295.572 86.7783 297.552ZM88.9522 298.638C90.6411 295.256 89.2468 291.482 86.01 289.865C82.7571 288.241 78.9179 289.402 77.229 292.783C75.54 296.165 76.9183 299.932 80.1712 301.556C83.408 303.173 87.2633 302.02 88.9522 298.638Z" fill="white"/>
                    <path d="M95.9083 285.212C96.5286 284.053 96.8463 282.774 96.79 281.621L94.4892 280.388C94.7115 281.651 94.4289 282.827 93.8256 283.953C92.6699 286.111 90.3031 286.947 88.1609 285.8C86.0346 284.661 85.3854 282.251 86.5071 280.157C87.1699 278.919 87.9546 278.216 88.9275 277.696L86.6266 276.464C85.7076 277.074 84.9427 277.931 84.3564 279.025C82.5125 282.469 83.5161 286.314 86.9118 288.133C90.3076 289.951 94.0813 288.623 95.9083 285.212Z" fill="white"/>
                    <path d="M87.1586 274.356L89.179 275.5L91.1396 272.039L100.083 277.105L101.36 274.849L92.417 269.784L94.3865 266.307L92.3661 265.162L87.1586 274.356Z" fill="white"/>
                    <path d="M104.248 269.801L107.009 265.202C108.575 262.594 108.007 260.112 105.908 258.852C103.81 257.592 101.337 258.249 99.7711 260.857L98.3443 263.233L94.7794 261.093L93.4453 263.316L104.248 269.801ZM103.622 266.402L100.227 264.364L101.58 262.111C102.349 260.83 103.439 260.413 104.534 261.071C105.615 261.72 105.744 262.868 104.975 264.149L103.622 266.402Z" fill="white"/>
                    <path d="M113.038 255.369L114.226 253.543L110.67 243.862L117.595 248.369L118.999 246.212L108.439 239.338L107.221 241.209L110.771 250.8L103.892 246.323L102.478 248.495L113.038 255.369Z" fill="white"/>
                    <path d="M122.9 240.438L124.392 238.319L119.638 234.972L127.511 233.889L129.417 231.18L120.233 232.375L118.586 224.678L116.762 227.268L118.151 233.925L114.089 231.065L112.597 233.185L122.9 240.438Z" fill="white"/>
                    <path d="M132.249 223.705C130.923 225.479 128.522 225.774 126.619 224.352C124.716 222.929 124.32 220.543 125.646 218.769C126.971 216.996 129.372 216.701 131.275 218.123C133.178 219.546 133.574 221.932 132.249 223.705ZM134.195 225.16C136.458 222.133 135.757 218.17 132.859 216.004C129.947 213.827 125.962 214.287 123.699 217.315C121.436 220.342 122.123 224.294 125.035 226.471C127.933 228.637 131.932 228.188 134.195 225.16Z" fill="white"/>
                    <path d="M139.357 217.887L140.974 215.862L134.235 210.483L139.242 210.702L140.14 209.577L138.783 204.785L145.521 210.164L147.116 208.166L137.268 200.306L135.674 202.304L137.474 208.416L131.115 208.016L129.509 210.027L139.357 217.887Z" fill="white"/>
                    <path d="M152.387 202.633L154.08 200.646L153.024 199.747C155.562 196.517 154.844 193.306 152.775 191.544C150.706 189.782 147.407 189.574 144.637 192.605L143.76 191.858L142.068 193.846L142.945 194.592C140.394 197.81 141.125 201.033 143.194 202.795C145.264 204.558 148.548 204.754 151.332 201.734L152.387 202.633ZM149.551 200.217C148.062 201.882 146.319 201.957 144.921 200.767C143.523 199.577 143.307 197.832 144.726 196.109L149.551 200.217ZM151.243 198.23L146.419 194.122C147.893 192.446 149.65 192.382 151.048 193.572C152.446 194.762 152.649 196.496 151.243 198.23Z" fill="white"/>
                    <path d="M162.288 187.313C160.797 188.95 158.379 189.012 156.622 187.412C154.866 185.812 154.702 183.399 156.193 181.762C157.684 180.125 160.102 180.064 161.858 181.663C163.615 183.263 163.779 185.677 162.288 187.313ZM164.084 188.95C166.63 186.155 166.315 182.143 163.64 179.707C160.952 177.259 156.942 177.331 154.396 180.126C151.851 182.921 152.152 186.92 154.841 189.369C157.515 191.805 161.539 191.744 164.084 188.95Z" fill="white"/>
                    <path d="M168.743 183.498L170.536 181.627L166.937 178.177L168.954 176.072C170.86 174.083 170.837 171.792 169.239 170.26C167.64 168.728 165.363 168.815 163.458 170.803L159.646 174.779L168.743 183.498ZM165.338 176.645L163.077 174.477L164.97 172.502C165.817 171.619 166.73 171.446 167.471 172.156C168.199 172.854 168.078 173.786 167.231 174.669L165.338 176.645Z" fill="white"/>
                    <path d="M167.834 166.263L169.471 167.91L172.292 165.105L179.539 172.392L181.377 170.564L174.13 163.277L176.963 160.459L175.325 158.812L167.834 166.263Z" fill="white"/>
                    <path d="M183.846 121.253C181.893 118.925 182.199 115.454 184.544 113.534C271.289 42.5019 379.419 2.71785 491.515 0.589987C494.546 0.532451 497.029 2.97671 497.05 6.0155L497.455 64.0141C497.476 67.0496 495.029 69.5306 491.985 69.5943C395.953 71.6048 303.344 105.678 228.91 166.387C226.55 168.312 223.079 168.009 221.128 165.683L183.846 121.253Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M266.631 121.483C267.737 120.773 268.637 119.81 269.148 118.775L267.737 116.579C267.318 117.79 266.499 118.68 265.424 119.371C263.364 120.695 260.89 120.273 259.576 118.229C258.272 116.2 258.877 113.779 260.876 112.494C262.057 111.735 263.084 111.503 264.187 111.521L262.776 109.326C261.677 109.412 260.592 109.788 259.547 110.46C256.261 112.571 255.268 116.419 257.35 119.66C259.433 122.9 263.376 123.576 266.631 121.483Z" fill="white"/>
                    <path d="M275.781 115.439L278.009 114.115L275.462 109.829L277.969 108.339C280.336 106.932 280.926 104.718 279.795 102.815C278.663 100.911 276.446 100.387 274.079 101.794L269.344 104.608L275.781 115.439ZM274.331 107.925L272.731 105.233L275.083 103.835C276.135 103.21 277.061 103.288 277.585 104.17C278.1 105.036 277.735 105.902 276.683 106.528L274.331 107.925Z" fill="white"/>
                    <path d="M287.283 108.96C288.287 108.395 289.476 107.168 289.269 104.414L288.48 93.54L286.08 94.8903L286.684 101.655L280.872 97.8202L278.377 99.2234L286.85 104.556C286.942 106.115 286.655 106.669 285.761 107.172C285.431 107.358 284.854 107.579 284.322 107.589L285.443 109.581C285.927 109.598 286.733 109.269 287.283 108.96Z" fill="white"/>
                    <path d="M295.972 103.797L298.263 102.584L295.542 97.4461L303.051 100.048L305.978 98.4983L297.249 95.4012L299.269 87.793L296.47 89.2757L294.691 95.8395L292.366 91.4492L290.075 92.6623L295.972 103.797Z" fill="white"/>
                    <path d="M311.97 93.0741C309.984 94.0541 307.71 93.2295 306.659 91.0989C305.607 88.9683 306.335 86.6617 308.32 85.6817C310.306 84.7017 312.58 85.5264 313.631 87.657C314.683 89.7875 313.955 92.0942 311.97 93.0741ZM313.045 95.2531C316.435 93.58 317.606 89.73 316.004 86.4858C314.395 83.2254 310.634 81.8296 307.245 83.5027C303.855 85.1759 302.677 89.0097 304.286 92.2701C305.887 95.5144 309.656 96.9263 313.045 95.2531Z" fill="white"/>
                    <path d="M320.917 91.1406L326.676 88.4991C329.114 87.3809 330.044 85.3503 329.159 83.4197C328.348 81.6527 326.69 81.1459 325.432 81.4258C325.992 80.8325 326.399 79.7346 325.829 78.4912C324.958 76.5933 323.059 76.2959 321.047 77.2189L315.664 79.6879L320.917 91.1406ZM322.343 88.0312L320.969 85.0372L323.931 83.6789C325.076 83.1536 326.043 83.3633 326.486 84.3286C326.929 85.2939 326.449 86.1476 325.304 86.6729L322.343 88.0312ZM320.076 83.0902L318.921 80.5706L321.571 79.3549C322.553 78.9046 323.352 79.0926 323.727 79.9106C324.087 80.6959 323.708 81.4242 322.727 81.8745L320.076 83.0902Z" fill="white"/>
                    <path d="M336.109 81.9513C334.073 82.8199 331.848 81.8706 330.916 79.6851C329.984 77.4996 330.839 75.2368 332.875 74.3682C334.912 73.4996 337.136 74.4489 338.069 76.6344C339.001 78.8199 338.146 81.0827 336.109 81.9513ZM337.063 84.1864C340.54 82.7035 341.922 78.9243 340.502 75.5963C339.076 72.2518 335.399 70.65 331.922 72.133C328.445 73.616 327.056 77.3786 328.482 80.7231C329.901 84.0511 333.586 85.6694 337.063 84.1864Z" fill="white"/>
                    <path d="M344.672 83.4484L346.935 82.5626L345.997 80.1657L354.025 77.0229L354.964 79.4198L357.226 78.534L355.442 73.9749L353.833 74.6048L350.086 65.0339L341.84 68.262L342.568 70.1226C344.123 74.0951 344.453 76.4206 344.044 78.4365L342.887 78.8893L344.672 83.4484ZM346.709 77.3933C346.937 74.6173 346.034 71.9165 345.083 69.486L348.519 68.141L351.419 75.5496L346.709 77.3933Z" fill="white"/>
                    <path d="M359.171 75.0597L361.222 74.3271L364.257 64.4697L367.036 72.2503L369.46 71.3844L365.222 59.5187L363.12 60.2695L360.134 70.0518L357.373 62.3221L354.932 63.194L359.171 75.0597Z" fill="white"/>
                    <path d="M366.048 59.2288L366.777 61.4334L370.554 60.1845L373.781 69.9428L376.242 69.129L373.015 59.3707L376.809 58.1161L376.08 55.9115L366.048 59.2288Z" fill="white"/>
                    <path d="M381.542 67.3958L390.583 64.6425L389.906 62.4213L383.346 64.4193L382.402 61.3199L387.533 59.7571L386.867 57.5703L381.736 59.1331L381.028 56.8085L387.399 54.8681L386.722 52.6468L377.871 55.3424L381.542 67.3958Z" fill="white"/>
                    <path d="M390.721 64.4495C391.032 64.494 391.458 64.4133 391.787 64.322C394.667 63.5241 395.089 61.4085 393.683 55.7276L393.07 53.2452L396.747 52.2262L399.492 62.1309L401.973 61.4436L398.608 49.3011L390.039 51.6757L390.972 55.2461C392.215 60.0009 392.389 61.7084 391.019 62.0881C390.637 62.1938 390.345 62.2187 390.096 62.1945L390.721 64.4495Z" fill="white"/>
                    <path d="M405.887 60.4053L415.058 58.1276L414.499 55.8741L407.843 57.527L407.062 54.3825L412.268 53.0897L411.717 50.8711L406.511 52.1639L405.925 49.8055L412.389 48.2004L411.829 45.9468L402.85 48.1767L405.887 60.4053Z" fill="white"/>
                    <path d="M416.056 57.8552L418.587 57.2984L416.735 48.8777L420.489 52.1991L421.895 51.8897L423.855 47.3118L425.707 55.7326L428.204 55.1835L425.497 42.8776L423.001 43.4266L420.553 49.3097L415.863 44.9964L413.35 45.5493L416.056 57.8552Z" fill="white"/>
                    <path d="M272.73 85.6806C270.794 86.7538 268.483 86.0381 267.332 83.9599C266.18 81.8817 266.798 79.5431 268.734 78.4699C270.671 77.3967 272.981 78.1124 274.133 80.1905C275.285 82.2687 274.667 84.6073 272.73 85.6806ZM273.908 87.806C277.215 85.9736 278.201 82.0724 276.447 78.9079C274.685 75.7277 270.862 74.5122 267.556 76.3445C264.25 78.1768 263.255 82.0623 265.017 85.2426C266.771 88.4071 270.602 89.6383 273.908 87.806Z" fill="white"/>
                    <path d="M274.107 73.0298L275.181 75.0887L278.708 73.2492L283.461 82.3624L285.759 81.1638L281.006 72.0506L284.549 70.2029L283.476 68.144L274.107 73.0298Z" fill="white"/>
                    <path d="M290.341 78.7825L292.669 77.6426L290.239 72.6797L295.347 70.1782L297.778 75.1411L300.089 74.009L294.548 62.693L292.236 63.825L294.31 68.0604L289.202 70.562L287.128 66.3265L284.8 67.4665L290.341 78.7825Z" fill="white"/>
                    <path d="M306.696 68.5529C304.681 69.4696 302.434 68.5733 301.451 66.4105C300.467 64.2478 301.268 61.9654 303.283 61.0487C305.299 60.132 307.545 61.0283 308.529 63.191C309.513 65.3538 308.712 67.6362 306.696 68.5529ZM307.703 70.7648C311.143 69.1998 312.435 65.3888 310.937 62.0955C309.432 58.7858 305.718 57.2717 302.277 58.8368C298.836 60.4018 297.537 64.1964 299.042 67.5061C300.54 70.7994 304.262 72.3299 307.703 70.7648Z" fill="white"/>
                    <path d="M315.86 66.7861L330.464 60.7471L325.65 49.1033L323.255 50.0937L327.182 59.5918L323.456 61.1325L319.529 51.6344L317.15 52.618L321.077 62.116L317.368 63.6498L313.44 54.1518L311.045 55.1422L315.86 66.7861Z" fill="white"/>
                    <path d="M332.116 60.1324L340.954 56.7854L340.131 54.6139L333.718 57.0429L332.57 54.0129L337.586 52.1131L336.777 49.9753L331.76 51.8751L330.9 49.6026L337.128 47.2438L336.306 45.0723L327.653 48.3492L332.116 60.1324Z" fill="white"/>
                    <path d="M343.715 55.7615L346.163 54.9089L344.345 49.6904L349.717 47.8194L351.534 53.038L353.965 52.1913L349.821 40.2924L347.39 41.1391L348.941 45.5927L343.57 47.4636L342.018 43.01L339.571 43.8626L343.715 55.7615Z" fill="white"/>
                    <path d="M355.477 51.6707L357.553 51.0098L360.929 41.264L363.436 49.1364L365.888 48.3554L362.065 36.3494L359.938 37.0267L356.615 46.6992L354.124 38.8782L351.654 39.6647L355.477 51.6707Z" fill="white"/>
                    <path d="M367.764 47.751L370.636 46.9258L372.793 41.0058L374.903 40.3993L376.31 45.2952L378.801 44.5793L375.322 32.4694L369.716 34.0801C367.121 34.8258 365.975 36.7659 366.576 38.8592C367.038 40.4681 368.318 41.4675 370.066 41.4896L367.764 47.751ZM371.326 39.1611C370.202 39.4842 369.378 39.1591 369.105 38.2076C368.836 37.2734 369.357 36.5432 370.481 36.2201L373.457 35.365L374.302 38.306L371.326 39.1611Z" fill="white"/>
                    <path d="M500.5 5.99995C500.5 2.96109 502.965 0.499554 505.997 0.535921C618.106 1.88089 726.511 40.9091 813.75 111.334C816.109 113.238 816.439 116.706 814.502 119.048L777.532 163.737C775.597 166.076 772.127 166.404 769.754 164.496C694.897 104.308 602.052 70.8816 506.009 69.5419C502.964 69.4994 500.5 67.0355 500.5 64L500.5 5.99995Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M512.179 50.6733L514.352 50.8178L521.06 42.9835L520.512 51.2273L523.081 51.398L523.917 38.8258L521.689 38.6777L515.056 46.4629L515.601 38.273L513.015 38.1011L512.179 50.6733Z" fill="white"/>
                    <path d="M531.098 52.0869L533.676 52.3536L534.189 47.394L537.09 47.694C539.829 47.9773 541.623 46.5523 541.851 44.3501C542.079 42.1478 540.613 40.4037 537.873 40.1204L532.394 39.5538L531.098 52.0869ZM534.417 45.1918L534.739 42.0764L537.461 42.3578C538.678 42.4838 539.375 43.0987 539.269 44.1192C539.165 45.1219 538.356 45.5991 537.139 45.4732L534.417 45.1918Z" fill="white"/>
                    <path d="M542.677 53.3146L552.051 54.5109L552.345 52.2076L545.542 51.3394L545.953 48.1255L551.273 48.8045L551.563 46.5369L546.242 45.8579L546.55 43.4474L553.156 44.2906L553.45 41.9872L544.272 40.816L542.677 53.3146Z" fill="white"/>
                    <path d="M552.338 54.5239L555.024 54.9365L556.694 52.2428L561.961 53.0515L562.746 56.1222L565.468 56.5402L562.054 43.1954L559.599 42.8184L552.338 54.5239ZM557.82 50.2485L560.317 46.2066L561.467 50.8086L557.82 50.2485Z" fill="white"/>
                    <path d="M566.448 56.5904C566.708 56.7662 567.127 56.8793 567.463 56.9409C570.403 57.4786 571.705 55.759 572.917 50.0337L573.448 47.5324L577.202 48.2191L575.352 58.3293L577.884 58.7925L580.152 46.3983L571.405 44.798L570.688 48.418C569.733 53.2388 569.145 54.8514 567.746 54.5955C567.357 54.5242 567.083 54.4192 566.869 54.2886L566.448 56.5904Z" fill="white"/>
                    <path d="M579.712 59.1374L581.843 59.587L589.59 52.7774L587.884 60.8615L590.403 61.3928L593.004 49.0641L590.82 48.6034L583.155 55.3751L584.849 47.3438L582.313 46.8088L579.712 59.1374Z" fill="white"/>
                    <path d="M597.68 63.3465C600.971 64.134 603.298 62.8399 603.805 60.7217C604.194 59.0937 603.349 57.9103 602.408 57.2781C603.197 57.1522 604.331 56.5906 604.657 55.2252C605.147 53.177 603.688 51.3101 600.834 50.6274C599.276 50.2546 597.639 50.2886 596.573 50.5701L596.016 52.8984C597.074 52.6519 598.562 52.5452 600.155 52.9263C601.573 53.2656 602.335 53.9477 602.138 54.7705C601.937 55.6107 601.185 55.9676 599.837 55.6451L597.999 55.2053L597.492 57.3235L599.033 57.6921C600.643 58.0775 601.516 58.8414 601.277 59.8392C601.009 60.9596 599.747 61.3609 597.891 60.917C596.526 60.5903 595.015 59.7106 594.11 58.6981L593.523 61.1489C594.454 62.1306 595.702 62.8732 597.68 63.3465Z" fill="white"/>
                    <path d="M603.338 64.414L605.964 65.1132L607.914 62.6148L613.063 63.9855L613.513 67.1228L616.175 67.8313L614.216 54.1966L611.816 53.5576L603.338 64.414ZM609.248 60.7533L612.165 57.0035L612.814 61.7026L609.248 60.7533Z" fill="white"/>
                    <path d="M625.953 73.3977L628.299 74.0945L629.693 69.4011L628.019 68.9041L630.945 59.0513L628.46 58.3135L625.535 68.1662L620.479 66.6649L623.405 56.8122L620.92 56.0743L617.333 68.153L626.685 70.9302L625.953 73.3977Z" fill="white"/>
                    <path d="M631.904 72.5285L633.972 73.211L642.424 67.2995L639.836 75.1455L642.28 75.952L646.228 63.9865L644.108 63.2871L635.742 69.1699L638.314 61.3752L635.852 60.563L631.904 72.5285Z" fill="white"/>
                    <path d="M644.381 76.6619L647.191 77.6785L652.411 74.1499L654.476 74.8971L652.743 79.6872L655.18 80.569L659.467 68.7207L653.983 66.7365C651.444 65.8179 649.377 66.7163 648.636 68.7643C648.067 70.3385 648.517 71.8986 649.919 72.9421L644.381 76.6619ZM652.306 71.7956C651.206 71.3976 650.729 70.651 651.066 69.72C651.397 68.806 652.247 68.5201 653.347 68.9182L656.258 69.9715L655.217 72.849L652.306 71.7956Z" fill="white"/>
                    <path d="M662.498 83.3713L664.9 84.345L668.761 74.8197L673.749 76.8415L669.888 86.3667L672.274 87.3336L677.007 75.6565L667.232 71.6941L662.498 83.3713Z" fill="white"/>
                    <path d="M680.721 88.6104C678.693 87.7207 677.862 85.4491 678.817 83.2734C679.772 81.0977 682.007 80.1717 684.034 81.0615C686.061 81.9512 686.892 84.2228 685.938 86.3984C684.983 88.5741 682.748 89.5002 680.721 88.6104ZM679.744 90.8356C683.206 92.3547 686.907 90.7748 688.361 87.4618C689.822 84.1324 688.472 80.3554 685.011 78.8363C681.549 77.3172 677.855 78.8806 676.394 82.2101C674.94 85.523 676.283 89.3165 679.744 90.8356Z" fill="white"/>
                    <path d="M690.824 81.6597L689.837 83.7616L693.438 85.452L689.071 94.7559L691.417 95.8573L695.785 86.5534L699.402 88.2514L700.389 86.1495L690.824 81.6597Z" fill="white"/>
                    <path d="M697.435 98.7117L705.892 102.929L706.928 100.851L700.791 97.7906L702.237 94.8912L707.037 97.2852L708.057 95.2395L703.257 92.8455L704.342 90.6709L710.302 93.6433L711.338 91.5654L703.059 87.4362L697.435 98.7117Z" fill="white"/>
                    <path d="M707.186 103.579L709.476 104.794L712.067 99.9133L717.091 102.58L714.5 107.461L716.773 108.668L722.681 97.5386L720.407 96.3318L718.196 100.497L713.172 97.8305L715.383 93.665L713.094 92.4497L707.186 103.579Z" fill="white"/>
                    <path d="M727.462 117.641L729.589 118.854L732.013 114.6L730.496 113.736L735.587 104.807L733.335 103.523L728.245 112.452L723.663 109.84L728.753 100.911L726.501 99.6273L720.261 110.573L728.737 115.405L727.462 117.641Z" fill="white"/>
                    <path d="M732.252 117.449L734.112 118.581L743.681 114.733L739.386 121.791L741.585 123.129L748.136 112.366L746.229 111.206L736.749 115.045L741.016 108.033L738.802 106.686L732.252 117.449Z" fill="white"/>
                    <path d="M742.074 123.404L744.357 124.879L746.983 123.104L751.458 125.996L750.918 129.119L753.231 130.614L755.574 117.04L753.488 115.692L742.074 123.404ZM748.826 121.745L752.757 119.078L751.925 123.748L748.826 121.745Z" fill="white"/>
                    <path d="M754.149 131.083C754.314 131.35 754.65 131.625 754.932 131.818C757.394 133.512 759.286 132.475 762.734 127.747L764.242 125.682L767.386 127.844L761.562 136.313L763.683 137.771L770.823 127.39L763.496 122.351L761.361 125.361C758.517 129.369 757.321 130.6 756.149 129.794C755.823 129.57 755.616 129.362 755.475 129.155L754.149 131.083Z" fill="white"/>
                    <path d="M765.617 139.111L767.811 140.715L770.535 139.094L774.836 142.238L774.118 145.325L776.342 146.95L779.46 133.533L777.454 132.067L765.617 139.111ZM772.453 137.842L776.53 135.405L775.432 140.02L772.453 137.842Z" fill="white"/>
                    <path d="M531.466 29.1285L534.05 29.3375L534.879 19.093L540.243 19.5271L539.414 29.7716L541.98 29.9792L542.996 17.4202L532.483 16.5695L531.466 29.1285Z" fill="white"/>
                    <path d="M544.227 30.1897L546.804 30.4646L547.333 25.5067L550.233 25.8159C552.971 26.108 554.77 24.6887 555.004 22.4872C555.239 20.2857 553.778 18.5369 551.04 18.2449L545.563 17.6608L544.227 30.1897ZM547.568 23.3052L547.9 20.1909L550.621 20.481C551.838 20.6108 552.532 21.2279 552.423 22.2481C552.316 23.2504 551.505 23.7251 550.288 23.5953L547.568 23.3052Z" fill="white"/>
                    <path d="M562.035 30.0646C559.84 29.7703 558.413 27.8173 558.729 25.4624C559.045 23.1075 560.936 21.5995 563.13 21.8937C565.325 22.188 566.752 24.141 566.436 26.4959C566.12 28.8508 564.229 30.3588 562.035 30.0646ZM561.712 32.473C565.458 32.9754 568.578 30.4335 569.058 26.8476C569.542 23.2438 567.2 19.9877 563.453 19.4853C559.707 18.9829 556.59 21.507 556.106 25.1107C555.625 28.6967 557.965 31.9706 561.712 32.473Z" fill="white"/>
                    <path d="M575.449 34.7373L578.024 35.1662L578.252 33.799C582.327 34.3137 584.711 32.0468 585.158 29.3657C585.605 26.6847 584.087 23.7493 580.062 22.9328L580.251 21.7965L577.677 21.3676L577.487 22.5039C573.416 21.9715 571.028 24.2562 570.581 26.9372C570.134 29.6183 571.656 32.5359 575.677 33.3701L575.449 34.7373ZM576.062 31.0619C573.869 30.6419 572.907 29.186 573.209 27.375C573.511 25.564 574.895 24.4808 577.103 24.8121L576.062 31.0619ZM578.636 31.4909L579.677 25.241C581.873 25.6433 582.832 27.1169 582.53 28.9279C582.229 30.739 580.847 31.8044 578.636 31.4909Z" fill="white"/>
                    <path d="M587.406 36.3259L596.676 38.1591L597.127 35.8812L590.399 34.5508L591.028 31.3724L596.29 32.4129L596.733 30.1704L591.471 29.1298L591.942 26.7459L598.476 28.0379L598.926 25.7601L589.85 23.9652L587.406 36.3259Z" fill="white"/>
                    <path d="M603.937 40.0166C605.219 40.302 606.537 40.2634 607.633 39.8987L608.2 37.3511C607.044 37.9051 605.835 37.9496 604.588 37.672C602.198 37.1401 600.756 35.0857 601.284 32.7138C601.808 30.3594 603.954 29.0852 606.273 29.6014C607.644 29.9065 608.532 30.473 609.295 31.2698L609.862 28.7221C609.027 28.0014 607.996 27.4954 606.784 27.2256C602.971 26.3769 599.538 28.3789 598.701 32.1389C597.864 35.8989 600.159 39.1757 603.937 40.0166Z" fill="white"/>
                    <path d="M617.015 43.1494C618.289 43.4693 619.607 43.4662 620.712 43.1311L621.348 40.5996C620.177 41.1224 618.968 41.1343 617.728 40.8232C615.354 40.2272 613.968 38.1347 614.559 35.7778C615.147 33.4384 617.326 32.2225 619.631 32.8009C620.993 33.1427 621.865 33.7329 622.606 34.55L623.242 32.0186C622.426 31.2756 621.41 30.742 620.205 30.4397C616.416 29.4887 612.931 31.3975 611.993 35.1336C611.055 38.8697 613.261 42.2072 617.015 43.1494Z" fill="white"/>
                    <path d="M622.692 44.338L624.79 44.9249L632.962 38.6322L630.735 46.5886L633.214 47.2822L636.609 35.1483L634.46 34.5468L626.372 40.807L628.584 32.9026L626.088 32.2041L622.692 44.338Z" fill="white"/>
                    <path d="M641.559 47.5466C639.445 46.8895 638.364 44.7254 639.069 42.4564C639.775 40.1875 641.891 39.0169 644.006 39.674C646.12 40.331 647.2 42.4951 646.495 44.7641C645.79 47.0331 643.673 48.2036 641.559 47.5466ZM640.838 49.8671C644.448 50.9889 647.948 49.0043 649.022 45.5493C650.101 42.0771 648.336 38.4752 644.727 37.3534C641.117 36.2316 637.622 38.199 636.543 41.6712C635.469 45.1262 637.228 48.7453 640.838 49.8671Z" fill="white"/>
                    <path d="M648.61 52.0406L651.062 52.8799L652.852 47.6517L658.233 49.4936L656.444 54.7218L658.879 55.5554L662.959 43.6343L660.524 42.8008L658.997 47.2626L653.615 45.4207L655.143 40.9589L652.69 40.1195L648.61 52.0406Z" fill="white"/>
                    <path d="M660.693 56.1694L663.239 57.1224L665.424 54.827L670.414 56.695L670.555 59.8614L673.134 60.8269L672.52 47.0661L670.193 46.1951L660.693 56.1694ZM666.934 53.1049L670.204 49.6586L670.39 54.3987L666.934 53.1049Z" fill="white"/>
                    <path d="M673.977 61.0324C674.196 61.2577 674.582 61.454 674.899 61.5829C677.666 62.7098 679.293 61.2925 681.649 55.9356L682.68 53.5954L686.214 55.0345L682.338 64.5536L684.722 65.5243L689.474 53.8546L681.238 50.5013L679.797 53.8985C677.877 58.4225 676.972 59.881 675.655 59.3447C675.288 59.1954 675.042 59.0368 674.859 58.8652L673.977 61.0324Z" fill="white"/>
                    <path d="M687.529 66.7107L692.441 68.8664C695.227 70.0889 697.617 69.2115 698.601 66.9699C699.584 64.7283 698.619 62.3586 695.834 61.1361L693.296 60.0221L694.967 56.2147L692.593 55.173L687.529 66.7107ZM690.822 65.6591L692.413 62.033L694.819 63.0891C696.188 63.6895 696.739 64.7177 696.225 65.888C695.719 67.0418 694.596 67.3157 693.228 66.7153L690.822 65.6591Z" fill="white"/>
                    <path d="M698.724 71.645L701.068 72.7502L703.424 67.7516L708.57 70.1768L706.213 75.1753L708.542 76.2728L713.914 64.8754L711.586 63.778L709.575 68.0438L704.43 65.6187L706.441 61.3528L704.096 60.2477L698.724 71.645Z" fill="white"/>
                    <path d="M721.528 82.8136L723.837 83.9925L729.567 72.7709L727.259 71.5921L721.528 82.8136ZM711.445 77.6644L715.837 79.9075C718.563 81.2992 720.984 80.555 722.097 78.3748C723.211 76.1946 722.402 73.7807 719.677 72.3891L717.593 71.3249L719.484 67.6218L717.175 66.4429L711.445 77.6644ZM714.793 76.8074L716.594 73.2806L718.55 74.2793C719.88 74.9588 720.37 76.0175 719.789 77.1557C719.216 78.2779 718.079 78.4856 716.749 77.8061L714.793 76.8074Z" fill="white"/>
                    <path d="M725.368 84.8208L727.277 85.8693L736.665 81.6L732.688 88.8416L734.944 90.0807L741.01 79.0369L739.054 77.9624L729.754 82.2191L733.705 75.0248L731.433 73.777L725.368 84.8208ZM736.543 75.8031C738.152 76.6869 739.96 76.3862 740.912 74.691L739.24 73.7724C738.843 74.458 738.118 74.6555 737.376 74.2483C736.635 73.841 736.429 73.1322 736.81 72.438L735.138 71.5195C734.233 73.2407 734.918 74.9105 736.543 75.8031Z" fill="white"/>
                    <path d="M741.755 93.9915L743.98 95.3214L746.538 91.0417L749.041 92.5379C751.405 93.9509 753.633 93.4163 754.769 91.516C755.905 89.6156 755.311 87.4156 752.948 86.0025L748.22 83.1764L741.755 93.9915ZM747.674 89.1414L749.281 86.4531L751.629 87.8569C752.68 88.4849 753.052 89.3363 752.525 90.2169C752.008 91.0821 751.073 91.1732 750.022 90.5452L747.674 89.1414Z" fill="white"/>
                    <path d="M757.948 101.335C756.079 100.148 755.602 97.7763 756.876 95.7706C758.149 93.7649 760.499 93.1884 762.368 94.3753C764.236 95.5622 764.714 97.9335 763.44 99.9393C762.166 101.945 759.817 102.521 757.948 101.335ZM756.645 103.386C759.836 105.412 763.734 104.412 765.673 101.358C767.623 98.2884 766.861 94.3504 763.67 92.324C760.479 90.2976 756.591 91.2827 754.642 94.3521C752.702 97.4062 753.454 101.359 756.645 103.386Z" fill="white"/>
                    <path d="M768.657 111.259C769.747 111.993 770.988 112.439 772.141 112.499L773.599 110.335C772.321 110.428 771.179 110.028 770.119 109.314C768.089 107.946 767.497 105.507 768.855 103.492C770.202 101.492 772.666 101.089 774.636 102.417C775.8 103.201 776.42 104.053 776.84 105.073L778.298 102.909C777.784 101.933 777.009 101.085 775.979 100.391C772.74 98.209 768.813 98.8189 766.66 102.014C764.508 105.208 765.448 109.097 768.657 111.259Z" fill="white"/>
                    <path d="M779.456 103.025L778.113 104.919L781.358 107.22L775.413 115.604L777.528 117.103L783.473 108.719L786.732 111.031L788.075 109.136L779.456 103.025Z" fill="white"/>
                    <path d="M641.579 906.553L641.414 906.081L641.579 906.553C644.454 905.552 647.613 907.024 648.651 909.877L668.488 964.379C669.528 967.235 668.053 970.391 665.192 971.394C559.384 1008.47 444.168 1008.88 338.103 972.535C335.235 971.553 333.739 968.407 334.758 965.544L354.214 910.905C355.232 908.045 358.38 906.551 361.263 907.532L361.424 907.059L361.263 907.532C452.191 938.488 550.869 938.143 641.579 906.553Z" fill="#2B3D4F" stroke="white"/>
                    <path d="M453.316 970.183L459.634 970.661C462.308 970.864 464.106 969.537 464.266 967.42C464.413 965.481 463.208 964.235 461.972 963.87C462.749 963.622 463.637 962.859 463.74 961.495C463.898 959.413 462.38 958.233 460.173 958.066L454.268 957.619L453.316 970.183ZM456.069 968.153L456.318 964.868L459.567 965.114C460.823 965.209 461.568 965.861 461.488 966.92C461.408 967.979 460.574 968.494 459.318 968.399L456.069 968.153ZM456.48 962.732L456.689 959.968L459.597 960.188C460.674 960.27 461.282 960.821 461.214 961.719C461.149 962.58 460.464 963.034 459.387 962.952L456.48 962.732Z" fill="white"/>
                    <path d="M464.762 970.956C465.044 971.096 465.474 971.153 465.815 971.17C468.8 971.315 469.864 969.439 470.311 963.604L470.507 961.054L474.318 961.24L473.818 971.506L476.389 971.631L477.002 959.046L468.121 958.613L467.887 962.296C467.576 967.201 467.206 968.877 465.786 968.808C465.391 968.788 465.105 968.72 464.876 968.619L464.762 970.956Z" fill="white"/>
                    <path d="M480.297 971.784L482.475 971.828L488.815 963.692L488.648 971.952L491.221 972.004L491.476 959.407L489.244 959.362L482.978 967.445L483.144 959.239L480.552 959.186L480.297 971.784Z" fill="white"/>
                    <path d="M491.936 972.038L494.924 972.019L498.598 966.9L500.794 966.885L500.828 971.979L503.42 971.962L503.336 959.362L497.504 959.401C494.804 959.419 493.177 960.978 493.191 963.156C493.202 964.83 494.165 966.138 495.842 966.63L491.936 972.038ZM497.684 964.728C496.514 964.736 495.808 964.201 495.802 963.211C495.795 962.239 496.493 961.676 497.663 961.668L500.759 961.648L500.78 964.707L497.684 964.728Z" fill="white"/>
                    <path d="M507.31 971.908L509.9 971.817L509.706 966.294L515.39 966.095L515.584 971.617L518.157 971.527L517.715 958.935L515.142 959.025L515.308 963.738L509.623 963.938L509.458 959.225L506.867 959.316L507.31 971.908Z" fill="white"/>
                    <path d="M521.599 971.371L523.773 971.232L529.409 962.595L529.934 970.84L532.503 970.677L531.702 958.102L529.475 958.244L523.907 966.823L523.385 958.631L520.798 958.796L521.599 971.371Z" fill="white"/>
                    <path d="M534.737 970.506L544.15 969.668L543.944 967.355L537.113 967.964L536.825 964.736L542.168 964.26L541.965 961.983L536.622 962.459L536.407 960.039L543.04 959.448L542.834 957.135L533.619 957.956L534.737 970.506Z" fill="white"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_501_7321">
                    <rect width="1000" height="1000" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
                `,
                width: 390,
                alignment: 'center',
                absolutePosition: {
                    x: 0,
                    y: 60
                }
            },
            {
                text: 'На диаграмме мотивационного профиля представлены 9 универсальных факторов, наиболее полным образом отвечающих реалиям рабочей среды: чем выше балл, тем более значим этот фактор.',
                alignment: 'justify',
                margin: [20, 430, 20, 0],
                color: '#2B3D4F'
            },
            {
                pageBreak: 'before',
                margin: [0, 20, 0, 0],
                table: {
                    headerRows: 1,
                    widths: ['*'],
                    body: dropLast(5, DRIVER_PLUS_RESULTS).map(item => ([{
                        layout: 'noBorders',
                        table: {
                            headerRows: 0,
                            width: [400, '*'],
                            body: [
                                [{
                                    columns: [
                                        { text: item.title, fontSize: 16, bold: true, color: '#2B3D4F' },
                                        (pathOr(0, ['integralScores', 'blocks', item.id], data) ? {
                                            svg: DRIVER_PLUS_RESULT_IMG[pathOr(0, ['integralScores', 'blocks', item.id], data)],
                                            width: 280
                                        } : { text: '' })
                                    ],
                                    margin: [10, 5, 10, 5]
                                }],
                                [{ text: item.description, margin: [10, 0, 10, 5], fontSize: 11, color: '#666', alignment: 'justify', }]
                            ]
                        }
                    }]))
                }
            },
            {
                pageBreak: 'before',
                margin: [0, 20, 0, 0],
                table: {
                    headerRows: 1,
                    widths: ['*'],
                    body: drop(4, DRIVER_PLUS_RESULTS).map(item => ([{
                        layout: 'noBorders',
                        table: {
                            headerRows: 0,
                            width: [400, '*'],
                            body: [
                                [{
                                    columns: [
                                        { text: item.title, fontSize: 16, bold: true, color: '#2B3D4F' },
                                        (pathOr(0, ['integralScores', 'blocks', item.id], data) ? {
                                            svg: DRIVER_PLUS_RESULT_IMG[pathOr(0, ['integralScores', 'blocks', item.id], data)],
                                            width: 280
                                        } : { text: '' })
                                    ],
                                    margin: [10, 5, 10, 5]
                                }],
                                [{ text: item.description, margin: [10, 0, 10, 5], fontSize: 11, color: '#666', alignment: 'justify', }]
                            ]
                        }
                    }]))
                }
            },
            ...(DRIVER_PLUS_IPSATIVE_CLIENT_RESULTS.filter(item => pathOr(0, ['integralScores', 'blocks', item.id], data) >= 8).map((item, index) => [
                {
                    pageBreak: 'before',
                    text: 'Результаты',
                    margin: [20, 20, 20, 0],
                    color: '#2B3D4F',
                    style: {
                        bold: true,
                        fontSize: 25
                    }
                },
                !index ? {
                    text: 'В отчёте описаны факторы, оказывающие наибольшее (мотиваторы) и наименьшее воздействие на Вашу рабочую мотивацию.​',
                    alignment: 'justify',
                    margin: [20, 10, 20, 0],
                    color: '#2B3D4F'
                } : '',
                {
                    text: item.title,
                    alignment: 'justify',
                    bold: true,
                    margin: [20, 10, 20, 0],
                    color: '#2B3D4F'
                },
                {
                    text: item.description,
                    alignment: 'justify',
                    margin: [20, 10, 20, 0],
                    color: '#2B3D4F'
                },
                {
                    margin: [20, 10, 20, 0],
                    layout: 'noBorders',
                    table: {
                        headerRows: 1,
                        widths: ['50%', '50%'],
                        body: [
                            [
                                { text: 'Рекомендации', fontSize: 16, bold: true, color: '#2B3D4F', margin: [5, 5, 5, 5], padding: [5, 5, 5, 5] },
                                { text: 'Предостережения', fontSize: 16, bold: true, color: '#2B3D4F', margin: [5, 5, 5, 5], padding: [5, 5, 5, 5] },
                            ],
                            [
                                { text: item.recommendation, alignment: 'justify', fontSize: 11, color: '#666', margin: [5, 5, 5, 5], padding: [5, 5, 5, 5] },
                                { text: item.warning, alignment: 'justify', fontSize: 11, color: '#666', margin: [5, 5, 5, 5], padding: [5, 5, 5, 5] },
                            ]
                        ]
                    }
                },
            ])),
        ],
        footer: currentPage => currentPage === 1 ? undefined : ({
            columns: [
                {
                    svg: `<svg width="80px" height="50px" viewBox="0 0 80 50">
                        <polygon stroke="#E71D32" stroke-width="1px" fill="#E71D32" points="0,50 80,0 80,50" />
                    </svg>`,
                    margin: [0, 0, 0, 0],
                    alignment: 'right',
                },
                {
                    width: 140,
                    layout: 'noBorders',
                    table: {
                        widths: [140],
                        heights: [50],
                        body: [
                            [
                                {
                                    alignment: 'right',
                                    fillColor: '#E71D32',
                                    margin: [0, 15, 5, 0],
                                    svg: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="115px" height="22px" viewBox="0 0 115 22" version="1.1">
                                        <title>logo</title>
                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g id="logo" fill="#FFFFFF" fill-rule="nonzero">
                                                <path d="M106.773,0.388235 L103.214,5.5 L103.214,0.388235 L96.7954,0.388235 L96.7954,21.5471 L103.214,21.5471 L103.214,7.95883 L109.061,7.95883 L114.4,0.388235 L106.773,0.388235 Z M82.2408,15.9176 C79.508,15.9176 77.5378,13.8471 77.5378,11 C77.5378,8.15294 79.5715,6.01765 82.2408,6.01765 C84.9102,6.01765 86.944,8.15294 86.944,11 C87.0072,13.8471 84.9738,15.9176 82.2408,15.9176 Z M82.2408,0 C76.0125,0 71.1187,4.85294 71.1187,11 C71.1187,17.1471 76.0125,22 82.2408,22 C88.4693,22 93.3634,17.1471 93.3634,11 C93.4266,4.85294 88.5326,0 82.2408,0 Z M62.0938,15.9176 C59.1702,15.9176 57.1365,13.9117 57.1365,11 C57.1365,8.15294 59.2338,6.01765 61.9667,6.01765 C63.3648,6.01765 64.7631,6.6 65.78,7.63529 L69.2755,2.65294 C67.3053,0.970587 64.6995,0 61.9667,0 C55.6747,0 50.7173,4.85294 50.7173,11 C50.7173,17.2765 55.5475,22 61.9667,22 C65.0173,22 67.5595,21.0941 69.6568,19.2176 L66.1613,14.1706 C65.208,15.0765 63.8098,15.9176 62.0938,15.9176 Z M39.0867,0 C37.2435,0 34.1929,0.905881 33.1125,2.26471 L33.1125,0.388235 L26.884,0.388235 L26.884,21.5471 L33.3031,21.5471 L33.3031,8.15295 C34.0022,7.24706 35.6547,6.01765 37.6249,6.01765 C38.7053,6.01765 39.6587,6.34117 40.2307,6.98824 C40.7391,7.50588 41.2475,8.28235 41.2475,10.3529 L41.2475,21.5471 L47.6667,21.5471 L47.6667,7.95883 C47.6667,2.65294 43.8533,0 39.0867,0 Z M11.1222,15.9176 C8.38933,15.9176 6.41911,13.8471 6.41911,11 C6.41911,8.15294 8.45288,6.01765 11.1222,6.01765 C13.7915,6.01765 15.8253,8.15294 15.8253,11 C15.8253,13.8471 13.8551,15.9176 11.1222,15.9176 Z M15.5711,2.26471 C14.2365,0.647059 12.2662,0 10.296,0 C4.51245,0 0,4.85294 0,11 C0,13.7824 0.953333,16.3706 2.66933,18.3765 C4.576,20.6412 7.56311,21.9353 10.6138,21.9353 C12.4569,21.9353 14.5542,21.1588 15.6347,19.7353 L15.6347,21.5471 L21.8631,21.5471 L21.8631,0.388235 L15.6347,0.388235 L15.6347,2.26471 L15.5711,2.26471 Z" id="Shape"/>
                                            </g>
                                        </g>
                                    </svg>`
                                }
                            ]
                        ]
                    }
                }
            ],
        }),
    };

    window.pdfMake.createPdf(docDefinition).download(`Driver-Plus-(i)-Manager-${respondent.lastName}.pdf`);
}
