import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';
import styled from 'styled-components';

import { patchTestTemplate } from '../../../actions/asyncActions';
import TestTemplateSettingsForm from '../../forms/TestTemplateSettingsForm';

const Wrapper = styled.div`
    padding: 15px;
`;

class TestSettingsTab extends Component {
    render() {
        const { patchTestTemplate, test } = this.props;

        return <Wrapper className='wrap-bg'>
            <TestTemplateSettingsForm
                formAction={patchTestTemplate}
                item={{
                    id: test.id,
                    status: test.status,
                    currentSettings: test.currentSettings
                }} />
        </Wrapper>;
    }
}

export default withAsyncActions({
    patchTestTemplate: patchTestTemplate
        .withSuccessHandler(() => message.success('Настройки были успешно сохранены'))
        .withErrorHandler(() => message.error('Не удалось сохранить настройки'))
        .withOptions({ resetOnUnmount: true })
})(TestSettingsTab);
