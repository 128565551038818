import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { withAsyncActions } from 'react-async-client';
import { pathOr, equals, filter } from 'ramda';
import * as yup from 'yup';

import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import Input from './formComponents/Input';
import { getTestGroups } from '../../actions/asyncActions';
import Select from './formComponents/Select';

const FIELDS = ['name', 'industry', 'testGroup'];

class TestTemplateForm extends Component {
    render() {
        const { getTestGroups } = this.props;

        return <Fragment>
            <Field
                name='name'
                component={Input}
                label='Название' />
            <Field
                name='industry'
                component={Input}
                label='Функция' />
            <Field
                name='testGroup'
                component={Select}
                label='Группа'
                options={pathOr([], ['data', 'items'], getTestGroups)}
                loading={getTestGroups.meta.pending}
                namePath='name'
                allowClear />
            <SubmitButton type='primary'>Сохранить</SubmitButton>
        </Fragment>
    }
}

const validationSchema = yup.object().shape({
    name: yup.string().required(),
    industry: yup.string().required()
});

export default withAsyncActions({
    getTestGroups: getTestGroups
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})(
    withFormWrapper(TestTemplateForm, {
        validationSchema,
        mapPropsToValues: ({ item }) => item || ({
            status: 'unformed'
        }),
        mapBeforeSubmit: (values, { item }) => values.id ? ({
            id: values.id,
            data: filter(key => !equals(values[key], item[key]), FIELDS).map(key => ({
                op: 'replace',
                path: `/${key}`,
                value: values[key]
            }))
        }) : values
    })
);
