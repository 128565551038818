import { createAction } from 'react-async-client';

import * as actions from '../constants/actionTypes';

export const closeModal = createAction(actions.CLOSE_MODAL);
export const hideModal = createAction(actions.HIDE_MODAL);
export const showModal = createAction(actions.SHOW_MODAL);
export const removeModal = createAction(actions.REMOVE_MODAL);

export const openSelectCategoryModal = createAction(actions.OPEN_SELECT_CATEGORY_MODAL);
export const openQuestionPreviewModal = createAction(actions.OPEN_QUESTION_PREVIEW_MODAL);
export const openAdminModal = createAction(actions.OPEN_ADMIN_MODAL);
export const openCompanyModal = createAction(actions.OPEN_COMPANY_MODAL);
export const openCompanyDesignPageModal = createAction(actions.OPEN_COMPANY_DESIGN_PAGE_MODAL);
export const openCompanyClientModal = createAction(actions.OPEN_COMPANY_CLIENT_MODAL);
export const openCompanyEmployeeModal = createAction(actions.OPEN_COMPANY_EMPLOYEE_MODAL);
export const openAddClientToProjectModal = createAction(actions.OPEN_ADD_CLIENT_TO_PROJECT_MODAL);
export const openCompanyTemplateModal = createAction(actions.OPEN_COMPANY_TEMPLATE_MODAL);
export const openSettingsTemplateModal = createAction(actions.OPEN_SETTINGS_TEMPLATE_MODAL);
export const openSelectProjectTypeModal = createAction(actions.OPEN_SELECT_PROJECT_TYPE_MODAL);
export const openCategoriesSettingsInfoModal = createAction(actions.OPEN_CATEGORIES_SETTINGS_INFO_MODAL);
export const openProjectCategorySelectModal = createAction(actions.OPEN_PROJECT_CATEGORY_SELECT_MODAL);
export const openComplexityDistributionSettingsModal = createAction(actions.OPEN_COMPLEXITY_DISTRIBUTION_SETTINGS_MODAL);
export const openCompanyRespondentModal = createAction(actions.OPEN_COMPANY_RESPONDENT_MODAL);
export const openRespondentLetterModal = createAction(actions.OPEN_RESPONDENT_LETTER_MODAL);
export const openQuestionsSettingsModal = createAction(actions.OPEN_QUESTIONS_SETTINGS_MODAL);
export const openQuestionsImportModal = createAction(actions.OPEN_QUESTIONS_IMPORT_MODAL);
export const openTestModal = createAction(actions.OPEN_TEST_MODAL);
export const openProjectNameModal = createAction(actions.OPEN_PROJECT_NAME_MODAL);
export const openImportRespondentsModal = createAction(actions.OPEN_IMPORT_RESPONDENTS_MODAL);
export const openCommandModal = createAction(actions.OPEN_COMMAND_MODAL);
export const openCommandDebugModal = createAction(actions.OPEN_COMMAND_DEBUG_MODAL);
export const openTestSettingsModal = createAction(actions.OPEN_TEST_SETTINGS_MODAL);
export const openQuestionPageModal = createAction(actions.OPEN_QUESTION_PAGE_MODAL);
export const openQuestionDetailModal = createAction(actions.OPEN_QUESTION_DETAIL_MODAL);
export const openExpertModal = createAction(actions.OPEN_EXPERT_MODAL);
export const openRespondentAnalyticsModal = createAction(actions.OPEN_RESPONDENT_ANALYTICS_MODAL);
export const openTestTemplateModal = createAction(actions.OPEN_TEST_TEMPLATE_MODAL);
export const openPdfModal = createAction(actions.OPEN_PDF_MODAL);
export const openProjectQuestionsSettingsModal = createAction(actions.OPEN_PROJECT_QUESTIONS_SETTINGS_MODAL);
export const openPaymentModal = createAction(actions.OPEN_PAYMENT_MODAL);
export const openCompanyPaymentTypeModal = createAction(actions.OPEN_COMPANY_PAYMENT_TYPE_MODAL);
export const openTestTemplateRespondentMessagesModal = createAction(actions.OPEN_TEST_TEMPLATE_RESPONDENT_MESSAGES_MODAL);
export const openTestTemplateAuditMessagesModal = createAction(actions.OPEN_TEST_TEMPLATE_AUDIT_MESSAGES_MODAL);
export const openTestRespondentModal = createAction(actions.OPEN_TEST_RESPONDENT_MODAL);
export const openTestAuditModal = createAction(actions.OPEN_TEST_AUDIT_MODAL);
export const openRespondentFormFieldsModal = createAction(actions.OPEN_RESPONDENT_FORM_FIELDS_MODAL);
export const openCompanyBanketailsModal = createAction(actions.OPEN_COMPANY_BANK_DETAILS_MODAL);
export const openPaymentBankDetailsModal = createAction(actions.OPEN_PAYMENT_BANK_DETAILS_MODAL);
export const openAddRespondentModal = createAction(actions.OPEN_ADD_RESPONDENT_MODAL);
export const openProfileModal = createAction(actions.OPEN_PROFILE_MODAL);
export const openQuestionModal = createAction(actions.OPEN_QUESTION_MODAL);
export const openReplaceTestQuestionModal = createAction(actions.OPEN_REPLACE_TEST_QUESTION_MODAL);
export const openTestGroupsModal = createAction(actions.OPEN_TEST_GROUPS_MODAL);
export const openDistributionModal = createAction(actions.OPEN_DISTRIBUTION_MODAL);
export const openQuestionsDistributionModal = createAction(actions.OPEN_QUESTIONS_DISTRIBUTION_MODAL);
export const openCategoryRecommendationModal = createAction(actions.OPEN_CATEGORY_RECOMMENDATION_MODAL);
export const openCategoryErrorModal = createAction(actions.OPEN_CATEGORY_ERROR_MODAL);
export const openCompanyIntegralResultScaleModal = createAction(actions.OPEN_COMPANY_INTEGRAL_RESULT_SCALE_MODAL);
export const openExternalContractorModal = createAction(actions.OPEN_EXTERNAL_CONTRACTOR_MODAL);
export const openAcceptorModal = createAction(actions.OPEN_ACCEPTOR_MODAL);
export const openPaymentMessageHistoryModal = createAction(actions.OPEN_PAYMENT_MESSAGE_HISTORY_MODAL);
export const openQuestionnaryModal = createAction(actions.OPEN_QUESTIONNARY_MODAL);
export const openDriverModal = createAction(actions.OPEN_DRIVER_MODAL);
export const openDriverSettingsModal = createAction(actions.OPEN_DRIVER_SETTINGS_MODAL);
export const openImportQuestionsModal = createAction(actions.OPEN_IMPORT_QUESTIONS_MODAL);
export const openQuestionnaryPageElementsModal = createAction(actions.OPEN_QUESTIONNARY_PAGE_ELEMENTS_MODAL);
export const openSurveyModal = createAction(actions.OPEN_SURVEY_MODAL);
export const openImportSurveyModal = createAction(actions.OPEN_IMPORT_SURVEY_MODAL);
export const openSurveyPageElementsModal = createAction(actions.OPEN_SURVEY_PAGE_ELEMENTS_MODAL);
export const openGroupModal = createAction(actions.OPEN_GROUP_MODAL);
export const openGroupVariablesModal = createAction(actions.OPEN_GROUP_VARIABLES_MODAL);
export const openSurveyRespondentLetterModal = createAction(actions.OPEN_SURVEY_RESPONDENT_LETTER_MODAL);
export const openAddSurveyRespondentModal = createAction(actions.OPEN_ADD_SURVEY_RESPONDENT_MODAL);
export const openSurveyRespondentModal = createAction(actions.OPEN_SURVEY_RESPONDENT_MODAL);
export const openSurveyResponseFieldFormModal = createAction(actions.OPEN_SURVEY_RESPONSE_FIELD_FORM_MODAL);
export const openLanguagesSelectorModal = createAction(actions.OPEN_LANGUAGES_SELECTOR_MODAL);
export const openSurveyPreviewModal = createAction(actions.OPEN_SURVEY_PREVIEW_MODAL);
export const openEmployeesImportModal = createAction(actions.OPEN_EMPLOYEES_IMPORT_MODAL);
export const openRespondentReportsLetterModal = createAction(actions.OPEN_RESPONDENT_REPORTS_LETTER_MODAL);
