import React, { Component, Fragment } from 'react';
import { asyncConnect, toSuccess } from 'react-async-client';
import { Button, Tag, Row, Col, Spin } from 'antd';
import { takeEvery } from 'redux-saga/effects';
import { propEq, find } from 'ramda';
import { Link } from 'react-router-dom';
import { MailOutlined, PhoneOutlined, EditOutlined } from '@ant-design/icons';

import { PUT_EXPERT } from '../../constants/actionTypes';
import { getExpert, getExpertAudits } from '../../actions/asyncActions';
import { openExpertModal } from '../../actions/modalActions';
import { PROJECT_RESPONDENT_STATUSES } from '../../constants/companies';
import TableList from './table/TableList';
import Username from './table/Username';
import DateFormat from './table/DateFormat';
import DetailToolbar, { ToolbarTitle } from './DetailToolbar';
import { styledComponents } from './projects/ProjectRespondent';

const {
    Wrapper,
    Field,
    OtherInfo,
} = styledComponents;

class Expert extends Component {
    getAuditsColumns = () => [
        {
            title: 'Название',
            key: 'name',
            dataIndex: ['_embedded', 'testTemplate'],
            render: ({ id, name }) => <Link to={`/tests/${id}/info`}>{ name }</Link>
        },
        {
            dataIndex: 'status',
            key: 'statuses',
            title: 'Статус',
            width: 200,
            render: (status, { finishedAt, startedAt, createdAt }) => {
                const data = find(propEq('id', status), PROJECT_RESPONDENT_STATUSES);

                return <Fragment>
                    { data ? <Tag style={{ marginBottom: 5 }} color={data.color}>{ data.value }</Tag> : null }
                    <div><DateFormat date={finishedAt || startedAt || createdAt} /></div>
                </Fragment>;
            }
        }
    ]

    edit = () => {
        this.props.openExpertModal(
            this.props.getExpert.data
        );
    }

    render() {
        const { match } = this.props;
        const { meta, data } = this.props.getExpert;

        return meta.pending && !meta.lastSucceedAt ?
            <Spin/> :
            <Fragment>
                <DetailToolbar
                    title={
                        <ToolbarTitle>
                            <h1><Username user={data} /></h1>
                            <Button type='primary' icon={<EditOutlined />} onClick={this.edit}>
                                <span className='hide-mobile'>Редактировать</span>
                            </Button>
                        </ToolbarTitle>
                    }
                    backUrl='/experts'
                />
                <Wrapper>
                    <Row>
                        <Col span={8}>
                            <OtherInfo>
                                <Field><MailOutlined /> {data.email}</Field>
                                {data.phone && <Field><PhoneOutlined /> {data.phone}</Field>}
                            </OtherInfo>
                        </Col>
                    </Row>
                </Wrapper>
                <br />
                <DetailToolbar title='Тесты' hideBack />
                <TableList
                    action={getExpertAudits}
                    payload={match.params}
                    columns={this.getAuditsColumns()}
                />
            </Fragment>
        ;
    }
}

export default asyncConnect({
    getExpert: getExpert
        .withPayload(({ match: { params: { id } } }) => ({ id }))
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_EXPERT)], function() {
                const { getExpert } = getProps();

                getExpert.refresh();
            })
        })
        .withOptions({ resetOnUnmount: true, dispatchOnMount: true })
}, null, { openExpertModal })(Expert);
