import React, { Component } from 'react';
import { message } from 'antd';
import { asyncConnect } from 'react-async-client';
import { pluralize } from 'numeralize-ru';
import { pathOr, path } from 'ramda';

import { openPaymentModal } from '../../actions/modalActions';
import { postCompanyMessages } from '../../actions/asyncActions';
import LetterForm from '../forms/LetterForm';
import Modal from './Modal';
import { COMPANY_TEMPLATE_LETTER_VARIABLES } from '../../constants/companies';

class RespondentLetterModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            count: pathOr([], ['params', 'item', 'respondentsFilter', 'respondents'], props).length
        };
    }

    setCount = count => this.setState({ count });

    render() {
        const { modal, postCompanyMessages, params: { item, filtered, count }} = this.props;
        const amount = filtered ? count : this.state.count;

        return <Modal
            {...modal}
            title={`Отправить письмо ${amount} ${pluralize(amount, 'респонденту', 'респондентам', 'респондентам')}`}
            footer={null}>
            <LetterForm
                formAction={postCompanyMessages}
                filtered={filtered}
                item={item}
                setCount={this.setCount}
                amount={amount}
                contentOptions={COMPANY_TEMPLATE_LETTER_VARIABLES}
            />
        </Modal>;
    }
}

export default asyncConnect({
    postCompanyMessages: postCompanyMessages
        .withSuccessHandler(({ close, params: { clearSelected } }) => {
            message.success('Письмо было успешно отправлено');
            clearSelected();
            close();
        })
        .withErrorHandler(({ postCompanyMessages: { meta }, openPaymentModal, params }) => {
            const neededBalance = path(['error', 'data', 'errors', 0, 'attributes', 'neededBalance'], meta);

            if (neededBalance) {
                message.error('Недостаточно юнитов на балансе компании');
                openPaymentModal({ company: path(['item', 'company'], params) });
            } else {
                message.error('Не удалось отправить письмо');
            }
        })
        .withOptions({ resetOnUnmount: true })
}, null, { openPaymentModal })(RespondentLetterModal);
