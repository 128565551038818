import React, { Component } from 'react';
import { message } from 'antd';
import qs from 'qs';
import { withAsyncActions, toSuccess } from 'react-async-client';
import { takeEvery } from 'redux-saga/effects';
import { withStateHandlers } from 'recompose';
import { filter, pathOr } from 'ramda';

import { postCompanyProject, putCompanyProject, getCompanyProject, putCompanyTests, getCompany, postCompanyPayment, getCompanyTests } from '../../../actions/asyncActions';
import { PUT_COMPANY_PROJECT, PUT_COMPANY_TESTS, PUT_COMPANY_TEST, POST_COMPANY_TEST, DELETE_COMPANY_TEST, PUT_COMPANY_TEST_STATUS, PUT_COMPANY_PROJECT_TIME_SETTINGS, POST_BANK_DETAILS } from '../../../constants/actionTypes';
import ProjectRespondentsStep from '../../forms/project/ProjectRespondentsStep';
import ProjectSettingsStep from '../../forms/project/ProjectSettingsStep';
import ProjectPaymentStep from '../../forms/project/ProjectPaymentStep';
import AddProjectTestsStep from '../../forms/project/AddProjectTestsStep';

const getType = location => qs.parse(location.search, { ignoreQueryPrefix: true }).type;
const getTestTemplate = location => qs.parse(location.search, { ignoreQueryPrefix: true }).testTemplate === 'true';

const getSteps = props => {
    const type = props.getCompanyProject.data.type || getType(props.location);
    const testTemplate = !!pathOr(getTestTemplate(props.location), ['items', 0, 'testTemplate'], props.getCompanyTests);
    const paymentType = pathOr(props.getCompany.data.paymentType, ['data', '_embedded', 'company', 'paymentType'], props.getCompanyProject);

    const steps = [
        { title: 'Тесты', key: 'tests', component: AddProjectTestsStep, hide: testTemplate },
        { title: 'Настройки', key: 'settings', component: ProjectSettingsStep },
        { title: 'Респонденты', key: 'respondents', component: ProjectRespondentsStep, withoutRequest: true },
        { title: 'Оплата', key: 'payment', component: ProjectPaymentStep, hide: paymentType !== 'pre' || type === 'custom', formAction: props.postCompanyPayment }
    ];

    return filter(i => !i.hide, steps);
}

class AddProject extends Component {
    goNext = () => {
        const { match, history, location, getCompanyProject: { data }, setNext } = this.props;
        const step = Number(match.params.step);

        if (step + 1 > getSteps(this.props).length - 1) {
            message.success('Тест успешно сохранен');
            history.push(`/company/${match.params.company}/projects/${data.id}/respondents`);
        } else {
            setNext(true);
            history.replace(`/company/${match.params.company}/projects/wizard/${step + 1}/${data.id}${location.search}`);
        }
    }

    goPrev = () => {
        const { match, history, location, getCompanyProject: { data }} = this.props;
        const step = Number(match.params.step);

        history.replace(step === 0 ? `/?company=${match.params.company}` : `/company/${match.params.company}/projects/wizard/${step - 1}/${data.id}${location.search}`);
    }

    render() {
        const {
            match: { params: { company, step, id }},
            postCompanyProject,
            putCompanyProject,
            getCompanyProject,
            setNext,
            location,
            getCompanyTests,
        } = this.props;
        const current = Number(step);
        const steps = getSteps(this.props);
        const CurrentStep = steps[current] ? steps[current].component : null;
        const testTemplate = !!pathOr(getTestTemplate(location), ['items', 0, 'testTemplate'], getCompanyTests.data);
        const type = getType(location);

        return !!CurrentStep ?
            <CurrentStep
                company={company}
                formAction={steps[current].withoutRequest ? null : (steps[current].formAction || (id ? putCompanyProject : postCompanyProject))}
                itemMeta={getCompanyProject.meta}
                testSuitesMeta={getCompanyTests.meta}
                setNext={setNext}
                goPrev={this.goPrev}
                goNext={this.goNext}
                testTemplate={testTemplate}
                refreshItem={() => getCompanyProject.refresh()}
                current={current}
                steps={steps}
                item={id ? getCompanyProject.data : {
                    company,
                    status: 'active',
                    type,
                    profileType: type === 'competence' ? (testTemplate ? 'predefined' : 'custom') : null
                }}
                testSuites={getCompanyTests.data.items || []} /> : null;
    }
}

const successHandler = props => {
    const { history, match, location, getCompanyProject: { data }, next } = props;
    const step = Number(match.params.step);

    if (step + 1 > getSteps(props).length - 1 && next) {
        history.push(`/company/${match.params.company}/projects/${data.id}/respondents`);
    } else {
        history.replace(`/company/${match.params.company}/projects/wizard/${next ? step + 1 : step - 1}/${data.id}${location.search}`);
    }

    message.success('Тест успешно сохранен');
};

export default withStateHandlers(props => ({
    next: true
}), {
    setNext: () => next => ({ next })
})(
    withAsyncActions(({ match }) => ({
        getCompany: getCompany
            .withPayload(({ match: { params: { company }}}) => company)
            .withOptions({ dispatchOnMount: !match.params.id, resetOnUnmount: true }),
        getCompanyTests: getCompanyTests
            .withPayload(({ match: { params: { id, company }}}) => ({ project: id, company }))
            .withSaga(function* (getProps) {
                yield takeEvery([
                    toSuccess(PUT_COMPANY_TESTS),
                    toSuccess(PUT_COMPANY_TEST),
                    toSuccess(POST_COMPANY_TEST),
                    toSuccess(DELETE_COMPANY_TEST),
                    toSuccess(PUT_COMPANY_TEST_STATUS),
                ], function() {
                    const { getCompanyTests } = getProps();

                    getCompanyTests.refresh();
                })
            })
            .withOptions({ resetOnUnmount: true, dispatchOnMount: !!match.params.id, dispatchOnUpdate: true }),
        getCompanyProject: getCompanyProject
            .withPayload(({ match: { params: { id, company }}}) => ({ id, company }))
            .withSaga(function* (getProps) {
                yield takeEvery([
                    toSuccess(PUT_COMPANY_PROJECT),
                    toSuccess(PUT_COMPANY_PROJECT_TIME_SETTINGS),
                    toSuccess(POST_BANK_DETAILS)
                ], function() {
                    const { getCompanyProject } = getProps();

                    getCompanyProject.refresh();
                })
            })
            .withOptions({ resetOnUnmount: true, dispatchOnMount: !!match.params.id, dispatchOnUpdate: true }),
        postCompanyProject: postCompanyProject
            .withSuccessHandler(({ postCompanyProject: { data }, location, history, match: { params: { company }}}) => {
                history.replace(`/company/${company}/projects/wizard/1/${data.project || data.id}${location.search}`)
                message.success('Тест успешно сохранен');
            })
            .withErrorHandler(() =>
                message.error('Не удалось сохранить тест')
            )
            .withOptions({ resetOnUnmount: true }),
        putCompanyProject: putCompanyProject
            .withSuccessHandler(successHandler)
            .withErrorHandler(() => message.error('Не удалось сохранить тест'))
            .withOptions({ resetOnUnmount: true }),
        putCompanyTests: putCompanyTests
            .withSuccessHandler(successHandler)
            .withErrorHandler(() => message.error('Не удалось сохранить тест'))
            .withOptions({ resetOnUnmount: true }),
        postCompanyPayment: postCompanyPayment
            .withSuccessHandler(({ getCompanyProject, match, history }) => {
                getCompanyProject.refresh();
                message.success('Баланс компании успешно пополнен');
                history.push(`/company/${match.params.company}/projects/${match.params.id}/respondents`);
            })
            .withErrorHandler(() => message.error('Не удалось сохранить тест'))
            .withOptions({ resetOnUnmount: true })
    }))(AddProject)
);
