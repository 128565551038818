import React, { Component } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsMore from 'highcharts/highcharts-more';
import { pathOr } from 'ramda';

HighchartsExporting(Highcharts);
HighchartsMore(Highcharts);

const types = ['potential', 'relations', 'payment', 'social', 'influence', 'communication', 'acceptance', 'stability', 'supervisor'];

const scales = [
    [1, 2, 3, 4, 5, 6, 7, 8, 9],
    [2, 3, 4, 5, 6, 7, 8, 9, 1],
    [3, 4, 5, 6, 7, 8, 9, 1, 2],
    [4, 5, 6, 7, 8, 9, 1, 2, 3],
    [5, 6, 7, 8, 9, 1, 2, 3, 4],
    [6, 7, 8, 9, 1, 2, 3, 4, 5],
    [7, 8, 9, 1, 2, 3, 4, 5, 6],
    [8, 9, 1, 2, 3, 4, 5, 6, 7],
    [9, 1, 2, 3, 4, 5, 6, 7, 8],
    [10, 10, 10, 10, 10, 10, 10, 10, 10]
];

export default class DriverPlusChart extends Component {
    componentDidMount() {
        window.driverChart = this.chart;
    }

    componentWillUnmount() {
        window.driverChart = null;
    }

    getOptions = () => {
        return {
            chart: {
                polar: true,
                height: 600,
                backgroundColor: null
            },
            pane: {
                startAngle: 20,
                endAngle: 380
            },
            credits: {
                enabled: false
            },
            title: {
                text: undefined
            },
            xAxis: {
                categories: types,
                tickmarkPlacement: 'on',
                labels: {
                    enabled: false
                },
                visible: false
            },
            yAxis: {
                min: -2,
                max: 11,
                tickInterval: 1,
                visible: false,
                labels: {
                    enabled: false
                }
            },
            series: [
                ...(scales.map(scale => ({
                    type: 'line',
                    color: 'rgba(0,0,0,0)',
                    data: scale,
                    pointPlacement: 'on',
                    showInLegend: false,
                    enableMouseTracking: false,
                    dataLabels: {
                        enabled: true,
                        y: 6,
                        padding: 0,
                        style: {
                            fontSize: 10,
                            fontWeight: 'normal'
                        }
                    }
                }))),
                {
                    type: 'line',
                    color: '#aaa',
                    data: types.map(type => ({ name: type, y: pathOr(0, ['integralScores', 'blocks', type], this.props.data) })),
                    pointPlacement: 'on',
                    showInLegend: false,
                    enableMouseTracking: false,
                    dataLabels: {
                        enabled: false
                    },
                    marker: {
                        enabled: false
                    }
                }
            ]
        };
    }

    render() {
        return <div className='driver-plus-chart'>
            <HighchartsReact
                highcharts={Highcharts}
                options={this.getOptions()}
                ref={node => this.chart = node}
            />
        </div>;
    }
}
