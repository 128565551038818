import React, { Component, Fragment } from 'react';
import { message, Button, Popconfirm, Tag } from 'antd';
import { withState } from 'recompose';
import { asyncConnect } from 'react-async-client';
import { find, propEq } from 'ramda';
import { Link } from 'react-router-dom';
import { PhoneOutlined, MailOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import TableList from '../table/TableList';
import Username from '../table/Username';
import DateFormat from '../table/DateFormat';
import { getTestTemplateAudits, deleteTestTemplateAudits } from '../../../actions/asyncActions';
import { openTestTemplateAuditMessagesModal } from '../../../actions/modalActions';
import { DEFAULT_TEST_TEMPLATE_AUDIT_MESSAGE_CONTENT } from '../../../constants/tests';
import { PROJECT_RESPONDENT_STATUSES } from '../../../constants/companies';
import { DELETE_TEST_TEMPLATE_AUDITS, POST_TEST_TEMPLATE_AUDIT, POST_TEST_TEMPLATE_AUDIT_MESSAGES } from '../../../constants/actionTypes';
import TestTemplateAuditsFilter from '../../forms/filters/TestTemplateAuditsFilter';

export class TestAuditsTabButtons extends Component {
    render() {
        const { history, match: { params: { id }}} = this.props;

        return <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => history.push(`/tests/${id}/audits/add`)}>
            <span className='hide-mobile'>Добавить</span>
        </Button>;
    }
}

class TestAuditsTab extends Component {
    getColumns = () => {
        return [
            {
                title: 'Эксперт',
                dataIndex: ['_embedded', 'expert'],
                key: 'expert',
                render: (item, { id }) =>
                    <Link to={`/tests/${this.props.id}/audits/${id}`}>
                        <Username user={item} />
                    </Link>
            },
            {
                title: 'Контакты',
                dataIndex: ['_embedded', 'expert'],
                key: 'contacts',
                render: ({ email, phone }) =>
                    <Fragment>
                        <div><MailOutlined /> { email }</div>
                        { phone && <div><PhoneOutlined /> { phone }</div> }
                    </Fragment>
            },
            {
                dataIndex: 'status',
                key: 'statuses',
                title: 'Статус',
                filters: PROJECT_RESPONDENT_STATUSES.map(i => ({ text: i.value, value: i.id })),
                render: (status, { finishedAt, startedAt, createdAt }) => {
                    const data = find(propEq('id', status), PROJECT_RESPONDENT_STATUSES);

                    return <Fragment>
                        { data ? <Tag style={{ marginBottom: 5 }} color={data.color}>{ data.value }</Tag> : null }
                        <div><DateFormat date={finishedAt || startedAt || createdAt} /></div>
                    </Fragment>;
                }
            }
        ];
    }

    setSelected = selected => this.props.setSelected(selected);

    clearSelected = () => this.setSelected([]);

    delete = () => {
        this.props.deleteTestTemplateAudits.dispatch({
            items: this.props.selected,
            test: this.props.id
        });
    }

    openSendLetterModal = () => {
        const { selected, id, openTestTemplateAuditMessagesModal } = this.props;

        openTestTemplateAuditMessagesModal({
            item: {
                audits: selected,
                subject: 'Приглашение на оценку',
                content: DEFAULT_TEST_TEMPLATE_AUDIT_MESSAGE_CONTENT,
                testTemplate: id
            },
            clearSelected: this.clearSelected
        });
    }

    renderButtons = () => {
        const { selected } = this.props;

        return <Button.Group>
            <Button
                icon={<MailOutlined />}
                disabled={!selected.length}
                onClick={() => this.openSendLetterModal()}>
                <span className='hide-mobile'>Отправить приглашение</span>
            </Button>
            {selected.length ?
                <Popconfirm
                    title='Вы уверены, что хотите удалить выбранных экспертов?'
                    onConfirm={this.delete}>
                    <Button
                        type='danger'
                        icon={<DeleteOutlined />}>
                        <span className='hide-mobile'>Удалить</span>
                    </Button>
                </Popconfirm> :
                <Button
                    type='danger'
                    icon={<DeleteOutlined />}
                    disabled>
                    <span className='hide-mobile'>Удалить</span>
                </Button>
            }
        </Button.Group>
    }

    render() {
        return <TableList
            action={getTestTemplateAudits}
            payload={{ test: this.props.id }}
            columns={this.getColumns()}
            buttons={this.renderButtons()}
            filterForm={TestTemplateAuditsFilter}
            refreshActions={[DELETE_TEST_TEMPLATE_AUDITS, POST_TEST_TEMPLATE_AUDIT, POST_TEST_TEMPLATE_AUDIT_MESSAGES]}
            defaultSort={{
                sort_by: 'createdAt',
                sort_order: 'desc'
            }}
            rowSelection={{
                selectedRowKeys: this.props.selected,
                onChange: this.setSelected,
                type: 'checkbox'
            }} />;
    }
}

export default withState('selected', 'setSelected', [])(
    asyncConnect({
        deleteTestTemplateAudits: deleteTestTemplateAudits
            .withSuccessHandler(({ setSelected }) => {
                setSelected([]);
                message.success('Эксперты успешно удалены');
            })
            .withErrorHandler(() => message.error('Не удалось удалить экспертов'))
            .withOptions({ resetOnUnmount: true })
    }, null, {
        openTestTemplateAuditMessagesModal,
    })(TestAuditsTab)
);
