import React, { Component, Fragment } from 'react';
import { Button, Tabs, Tooltip } from 'antd';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { path, propEq, find } from 'ramda';
import { pluralize } from 'numeralize-ru';
import { WalletOutlined, EditOutlined } from '@ant-design/icons';

import { openPaymentModal, openCompanyPaymentTypeModal } from '../../../actions/modalActions';
import CompanyAccountOperations from './CompanyAccountOperations';
import CompanyPayments from './CompanyPayments';
import Route from '../../Route';
import { PAYMENT_TYPES } from '../../../constants/companies';
import { ADMIN } from '../../../constants/roles';
import { getUser } from '../../../actions/asyncActions';

const Balance = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #e8e8e8;
`;

class CompanyBilling extends Component {
    openPaymentModal = () => {
        const { openPaymentModal, id } = this.props;

        openPaymentModal({ company: id });
    }

    onChange = key => {
        const { history } = this.props;

        history.replace(this.getRoute(key));
    }

    openCompanyPaymentTypeModal = () => {
        this.props.openCompanyPaymentTypeModal(this.props.company);
    }

    getRoute = type => {
        return `/companies/${this.props.id}/info/billing/${type}`;
    }

    render() {
        const { company, id, isAdmin, match: { params: { type }}} = this.props;
        const paymentType = path(['value'], find(propEq('id', company.paymentType), PAYMENT_TYPES));

        return company ?
            <Fragment>
                <Balance>
                    <div>
                        { company.countRespondentsInProgress ?
                            <Tooltip title={`${company.countRespondentsInProgress} ${pluralize(company.countRespondentsInProgress, 'респондент', 'респондента', 'респондентов')} не завершили тестирование`}>
                                <div>Доступно {company.balance - company.countRespondentsInProgress} из {company.balance}</div>
                            </Tooltip> :
                            <div>Доступно {company.balance}</div>
                        }
                        <div>Пополните баланс юнитов для выставления счета</div>
                        <div>Курс юнита 1 = 500 &#8381;</div>
                    </div>
                    <Button
                        icon={<WalletOutlined />}
                        type='primary'
                        onClick={this.openPaymentModal}>
                        Пополнить
                    </Button>
                </Balance>
                { isAdmin &&
                    <Balance>
                        <span><strong>Тип оплаты:</strong> { paymentType }</span>
                        <Button
                            icon={<EditOutlined />}
                            onClick={this.openCompanyPaymentTypeModal}>
                            Изменить
                        </Button>
                    </Balance>
                }
                <Tabs
                    activeKey={type}
                    onChange={this.onChange}
                    animated={{ tabPane: false }}>
                    <Tabs.TabPane tab='Операции с балансом' key='operations'>
                        <Route path={this.getRoute('operations')} render={props => <CompanyAccountOperations {...props} id={id} />} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab='Пополнения баланса' key='payments'>
                        <Route path={this.getRoute('payments')} render={props => <CompanyPayments {...props} id={id} />} />
                    </Tabs.TabPane>
                </Tabs>
            </Fragment> :
            null;
    }
}

const stateToProps = state => ({
    isAdmin: getUser.selectData(state).role === ADMIN
});

export default connect(stateToProps, { openPaymentModal, openCompanyPaymentTypeModal })(CompanyBilling);
