import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import SurveyExtendedForm from '../../../forms/survey/SurveyExtendedForm';
import { putSurvey } from '../../../../actions/asyncActions';

class SurveyExtended extends Component {
    render() {
        const { survey, putSurvey } = this.props;

        return <div style={{ padding: 15 }}>
            <SurveyExtendedForm
                formAction={putSurvey}
                data={survey} />
        </div>;
    }
}

export default withAsyncActions({
    putSurvey: putSurvey
        .withSuccessHandler(() => message.success('Настройки успешно сохранены'))
        .withErrorHandler(() => message.error('Не удалось сохранить настройки'))
        .withOptions({ resetOnUnmount: true })
})(SurveyExtended);
