import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { asyncConnect } from 'react-async-client';
import { message } from 'antd';
import qs from 'qs';

import { getUser, postCompanyProject } from '../../actions/asyncActions';
import Modal from './Modal';
import CreateProjectForm from '../forms/project/CreateProjectForm';

class SelectProjectTypeModal extends Component {
    render() {
        const { modal, user, close, postCompanyProject, params } = this.props;

        return <Modal
            {...modal}
            width={880}
            footer={null}
            title='Создание теста'>
            <CreateProjectForm
                formAction={postCompanyProject}
                user={user}
                close={close}
                item={{
                    company: params
                }} />
        </Modal>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default withRouter(asyncConnect({
    postCompanyProject: postCompanyProject
        .withSuccessHandler(({ postCompanyProject: { data }, history, params }, action) => {
            const searchPath = {
                type: data.type,
                testTemplate: !!action.requestAction.payload.testTemplate
            };

            history.push(`/company/${params}/projects/wizard/0/${data.id}?${qs.stringify(searchPath)}`);
        })
        .withErrorHandler(() => message.error('Не удалось создать тест'))
        .withOptions({ resetOnUnmount: true })
}, stateToProps)(SelectProjectTypeModal));
