import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { pathOr } from 'ramda';
import styled from 'styled-components';

import { openProjectCategorySelectModal } from '../../../actions/modalActions';
import SpanAsLink from '../../user/table/SpanAsLink';

const Wrapper = styled.div`
    margin-top: 15px;
`;

class ProjectCategorySelect extends Component {
    openModal = () => {
        const { openProjectCategorySelectModal, item, lastLevel, complete, onlyOwn } = this.props;

        openProjectCategorySelectModal({
            item,
            company: item.company,
            onlyOwn,
            complete,
            lastLevel,
            allowSelectSecondLevel: item.type === 'custom',
        });
    }

    render() {
        const { item } = this.props;

        return <Wrapper>
            { !pathOr([], ['categories'], item).length ?
                <div>Вам нужно <SpanAsLink onClick={this.openModal}>выбрать компетенции</SpanAsLink> относящиеся к должности тестируемого.</div> :
                <Button onClick={this.openModal}>Редактировать профиль должности</Button>
            }
        </Wrapper>;
    }
}

export default connect(null, { openProjectCategorySelectModal })(ProjectCategorySelect);
