import React, { Component, Fragment } from 'react';
import { asyncConnect, toSuccess } from 'react-async-client';
import { Tree, Badge, Input, Spin, Select, Button, Tooltip } from 'antd';
import { pathOr, path, isNil, omit, contains, prepend, filter, append, without, pathEq, includes } from 'ramda';
import { takeEvery } from 'redux-saga/effects';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { withState } from 'recompose';
import { connect } from 'react-redux';
import { DownOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

import { getCategories, getCompanies, getUser } from '../../../actions/asyncActions';
import CategoryTitle from './CategoryTitle';
import { DELETE_CATEGORY, POST_CATEGORY, PUT_CATEGORY, PATCH_CATEGORY, QUESTION_IMPORT_SUCCESS } from '../../../constants/actionTypes';
import CategoryAdd from './CategoryAdd';
import { extendSearchPath, getUrlParams, getFilters } from '../../../utils/urlParams';
import { groupCategories } from '../../../utils/categories';
import { ADMIN_MODERATOR } from '../../../constants/roles';

const OverflowWrap = styled.div`
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        display: ${({ dark }) => dark ? 'block' : 'none'};
    }
}`;

const Container = styled.div`
    height: calc(100vh - 40px - 64px);
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    @media (max-width: 896px) {
        height: calc(100vh - 20px - 64px);
    }
    .ant-tree-node-selected {
        .category-controls {
            .anticon {
                display: inline;
            }

            .category-code {
                display: none;
            }

            .category-label + span .anticon-edit {
                right: 1px;
            }
            .category-label + span + .anticon-link {
                right: 25px;
            }
        }
    }
    .add-category-btn {
        .ant-tree-switcher {
            display: none;
        }
        .ant-tree-node-content-wrapper {
            padding: 1px;
            margin-top: 1px;
            border-top: 3px solid #fff;
        }
        .ant-tree-node-selected,
        .ant-tree-node-content-wrapper:hover {
            background: transparent !important;
        }
    }
    &:before {
        content: '';
        position: absolute;
        background: rgba(0, 0, 0, .5);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        display: ${({ dark }) => dark ? 'block' : 'none'};
    }
`;

export const Header = styled.h3`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    background: #fafafa;
    border-bottom: 1px solid #eaeaea;
    padding: 10px 15px;
    .ant-badge-count {
        margin-left: 8px;
        background: #1890ff;
    }
`;

export const SearchWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    background: #fafafa;
    border-bottom: 1px solid #eaeaea;
    .ant-select {
        margin-top: 5px;
    }
`;

const CenterWrapper = styled.div`
    text-align: center;
    padding: 30px 0;
    .ant-spin-spinning{
        height: calc(100vh - 400px);
    }
`;

class Categories extends Component {
    constructor(props) {
        super(props);

        this.nodes = {};
        this.state = {
            expandedKeys: getUrlParams(props.location).expandedKeys || [],
            visibleAdd: false,
            visibleEdit: null,
            search: getUrlParams(props.location).category || ''
        };
    }

    onExpand = (expandedKeys, { expanded, node }) => {
        if (expanded && !this.isSearch()) {
            const action = this.getAction(node.key);
            !action.meta.pending && !action.meta.lastSucceedAt && action.refresh();
        }

        this.setState({ expandedKeys });
    }

    isSearch = () => !!getUrlParams(this.props.location).category;

    onVisibleAddChange = visibleAdd => this.setState({ visibleAdd });

    onVisibleEditChange = visibleEdit => this.setState({ visibleEdit });

    renderNodes = (node, level, parents) => {
        if (!isNil(this.props.maxLevel) && level > this.props.maxLevel) {
            return [];
        }

        let items = pathOr([], ['items'], this.isSearch() ? node : this.props.getCategoryData(node.id));

        if (!this.props.selectOnly) {
            items = append({ addBtn: true, id: node.id }, items);
        } else if (node.childrenCount > 0) {
            items = append({ empty: true }, items);
        }

        return items.map(item => this.renderNode(item, level, parents, node));
    }

    getAction = id => {
        return path([id, 'props', 'getCategories'], this.nodes);
    }

    renderNode = (item, level, parents = [], node) => {
        const isAdmin = contains(this.props.user.role, ADMIN_MODERATOR);
        const action = this.getAction(item.id);
        const notLoaded = action ? !action.meta.pending && !action.meta.lastSucceedAt : true;
        const itemParents = item.parent ? prepend(item.parent, parents) : parents;
        const { all } = getUrlParams(this.props.location);

        return item.empty ?
            <Tree.TreeNode key={`empty-node-${node.id}`} style={{ height: 0, overflow: 'hidden', padding: 0 }} /> : item.addBtn ?
            (level < 3) && (isAdmin || item.company) &&
                <Tree.TreeNode
                    key={`${item.id}-add`}
                    title={<CategoryAdd
                        parent={item.id}
                        common={this.isSearch()}
                        visible={this.state.visibleAdd === item.id}
                        onVisibleChange={this.onVisibleAddChange} />
                    }
                    selectable={false}
                    className='add-category-btn' /> :
            <Tree.TreeNode
                className={this.props.treeClassName && this.props.treeClassName(item, level)}
                key={item.id}
                title={<CategoryTitle
                    item={item}
                    isAdmin={isAdmin}
                    getRef={node => this.nodes[item.id] = node}
                    visible={this.state.visibleEdit === item.id}
                    onVisibleChange={this.onVisibleEditChange}
                    common={this.isSearch()}
                    company={this.getCompany()}
                    allowChangeValid
                    dispatchOnMount={contains(item.id, getUrlParams(this.props.location).expandedKeys || []) && !this.isSearch() && notLoaded}
                    checked={pathEq([0], item.id, this.getFilter().categories)}
                    editButtons={this.props.editButtons}
                    renderComplexity={this.props.renderComplexity}
                    all={all ? null : true}
                    expandedKeys={this.state.expandedKeys} />
                }
                parents={itemParents}>
                { this.renderNodes(item, level + 1, itemParents) }
            </Tree.TreeNode>;
    }

    onSearch = value => {
        const { history, location, setSearching } = this.props;

        setSearching(true);
        history.replace(extendSearchPath(location, { category: value }));
        this.setState({ expandedKeys: [] });
    }

    getFilter = () => getFilters(this.props.location) || {};

    onSelect = (categories, e) => {
        const { location, history, selectOnly, selectableLevels } = this.props;
        const currentFilter = this.getFilter();
        const level = e.node.pos.split('-').length - 2;
        const isSelectable = (selectOnly || e.node.pos.split('-').length === 4) && (selectableLevels ? includes(level, selectableLevels) : true);

        if (isSelectable) {
            history.replace(extendSearchPath(location, {
                expandedKeys: e.node.parents,
                filter: JSON.stringify(categories.length ? { ...currentFilter, categories } : omit(['categories'], currentFilter))
            }));
        } else {
            history.replace(extendSearchPath(location, {
                expandedKeys: [],
                filter: JSON.stringify(categories.length ? { ...currentFilter, categories } : omit(['categories'], currentFilter)),
            }));

            const expanded = !e.node.expanded;
            const id = e.node.key;

            this.onExpand(expanded ? append(id, this.state.expandedKeys) : without([id], this.state.expandedKeys), { expanded, node: e.node });
        }
    }

    getCompany = () => {
        const { user } = this.props;

        return user.ownQuestions ? user.company : this.getFilter().company;
    }

    setCompany = company => {
        const { location, history } = this.props;
        const currentFilter = this.getFilter();

        history.replace(extendSearchPath(location, {
            filter: JSON.stringify(company ? { ...currentFilter, company } : omit(['company', 'categories'], currentFilter))
        }));
    }

    toggleHideCompetencies = () => {
        const { location, history } = this.props;
        const currentFilter = this.getFilter();

        history.replace(extendSearchPath(location, {
            all: getUrlParams(location).all ? null : true,
            filter: JSON.stringify(omit(['categories'], currentFilter))
        }));
    }

    render() {
        const { getCategories: { data, meta }, searching, getCompanies, user, selectOnly } = this.props;
        const isAdmin = contains(user.role, ADMIN_MODERATOR);
        const { expandedKeys, search, visibleAdd, visibleEdit } = this.state;
        let items = pathOr([], ['items'], data);
        items = this.isSearch() ? groupCategories(items) : items;
        const showAll = !!getUrlParams(this.props.location).all;

        return <Container dark={visibleEdit || visibleAdd !== false}>
            <Header>
                <div>
                    Компетенции
                    <Badge style={{ backgroundColor: '#f54d2e' }} count={items.length} />
                </div>
                <Tooltip title={showAll ? 'Скрыть скрытые компетенции' : 'Показать скрытые компетенции'}>
                    <Button
                        style={{ marginLeft: 10 }}
                        shape='circle'
                        icon={showAll ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                        onClick={this.toggleHideCompetencies} />
                </Tooltip>
            </Header>
            <SearchWrapper>
                <Input.Search
                    placeholder='Поиск'
                    value={search}
                    onChange={e => this.setState({ search: e.target.value })}
                    onSearch={this.onSearch} />
                { isAdmin &&
                    <Select
                        value={this.getCompany()}
                        onChange={this.setCompany}
                        placeholder='Компания'
                        allowClear
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                            (option.children || '').toLowerCase().includes(input.toLowerCase())
                        }
                    >
                        { (getCompanies.data.items || []).map(company =>
                            <Select.Option key={company.id} value={company.id}>
                                { company.name }
                            </Select.Option>
                        )}
                    </Select>
                }
            </SearchWrapper>
            <OverflowWrap dark={visibleEdit || visibleAdd !== false}>
                { ((meta.pending && !meta.lastSucceedAt) || searching) ?
                    <CenterWrapper><Spin /></CenterWrapper> :
                    <Fragment>
                        { !items.length && <CenterWrapper>Компетенции не найдены</CenterWrapper> }
                        <Tree
                            showLine
                            switcherIcon={<DownOutlined />}
                            ref={node => this.node = node}
                            expandedKeys={this.node ? filter(i => contains(i, (this.node.state.flattenNodes || []).map(({ key }) => key)), expandedKeys) : expandedKeys}
                            onExpand={this.onExpand}
                            onSelect={this.onSelect}
                            defaultSelectedKeys={this.getFilter().categories}>
                            { items.map(item => this.renderNode(item, 0))}
                            { !selectOnly &&
                                <Tree.TreeNode
                                    key='add'
                                    selectable={false}
                                    title={<CategoryAdd
                                        parent={null}
                                        visible={isNil(visibleAdd)}
                                        onVisibleChange={this.onVisibleAddChange} />
                                    }
                                    className='add-category-btn' />
                            }
                        </Tree>
                    </Fragment>
                }
            </OverflowWrap>
        </Container>;
    }
}

const stateToProps = state => ({
    getCategoryData: parent => getCategories.withParams({ parent }).selectData(state),
    user: getUser.selectData(state)
});

export default withState('searching', 'setSearching', false)(
    withRouter(
        connect(stateToProps)(
            asyncConnect(props => ({
                getCompanies: getCompanies
                    .withPayload(() => ({
                        q: {
                            enabled: true
                        },
                        limit: 0
                    }))
                    .withOptions({ dispatchOnMount: contains(props.user.role, ADMIN_MODERATOR), resetOnUnmount: true }),
                getCategories: getCategories
                    .withPayload(({ location, user }) => {
                        const { category: text, all } = getUrlParams(location);
                        const { company } = user.ownQuestions ? user : (getFilters(location) || {});
                        const isAdmin = contains(user.role, ADMIN_MODERATOR);
                        let q = { visible: all ? null : true };
                        q = text ? { ...q, text } : { ...q, byParent: true };

                        if (company && isAdmin) {
                            q = { ...q, company };
                        }

                        return { q };
                    })
                    .withSaga(function* (getProps) {
                        yield takeEvery([
                            toSuccess(DELETE_CATEGORY),
                            toSuccess(POST_CATEGORY),
                            toSuccess(PUT_CATEGORY),
                            toSuccess(PATCH_CATEGORY)
                        ], function(action) {
                            const { getCategories } = getProps();

                            if (!path(['requestAction', 'params', 'parent'], action)) {
                                getCategories.refresh();
                            }
                        });

                        yield takeEvery([QUESTION_IMPORT_SUCCESS], function() {
                            const { getCategories } = getProps();

                            getCategories.refresh();
                        });
                    })
                    .withSuccessHandler(({ searching, setSearching }) => searching && setSearching(false))
                    .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
            }))(Categories)
        )
    )
);
