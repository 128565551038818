import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { Button, Popconfirm } from 'antd';
import * as yup from 'yup';
import { prop } from 'ramda';
import styled from 'styled-components';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import Input from './formComponents/Input';

const Wrapper = styled.div`
    display: flex;
    .ant-btn-group {
        display: flex;
        margin-left: 15px;
    }
    .ant-form-item {
        width: 100%;
        margin-bottom: 0 !important;
    }
`;

class TestGroupForm extends Component {
    render() {
        const { onClose } = this.props;

        return <Wrapper>
            <Field
                name='name'
                component={Input}
                showTextError={false} />
            <Button.Group>
                <Popconfirm
                    title='Вы уверены, что хотите закрыть? Изменения не будут сохранены'
                    okText='Да'
                    cancelText='Нет'
                    onConfirm={onClose}
                    placement='left'>
                    <Button
                        icon={<CloseOutlined />} />
                </Popconfirm>
                <SubmitButton type='primary' icon={<CheckOutlined />} />
            </Button.Group>
        </Wrapper>
    }
}

const validationSchema = yup.object().shape({
    name: yup.string().required()
});

export default withFormWrapper(TestGroupForm, {
    validationSchema,
    mapPropsToValues: prop('item')
});
