import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { find, propEq, path, prop } from 'ramda';
import { FieldArray } from 'react-final-form-arrays';
import { Form, Button } from 'antd';
import styled from 'styled-components';
import * as yup from 'yup';
import uniqid from 'uniqid';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import withFormWrapper from '../hocs/withFormWrapper';
import { RESPONDENT_FORM_FIELD_TYPES, DEFAULT_RESPONDENT_FORM_FIELDS, RESPONDENT_FORM_FIELD_CHOICE_TYPES } from '../../constants/tests';
import Input from './formComponents/Input';
import Select from './formComponents/Select';
import SubmitButton from './formComponents/SubmitButton';
import ListenerField from './ListenerField';

const RespondentChoiceOption = styled.div`
    display: flex;
    margin-bottom: 10px;
    .ant-form-item {
        width: 100%;
    }
    .ant-btn-danger {
        margin-left: 10px;
    }
`;

class RespondentFormFieldForm extends Component {
    static defaultProps = {
        fields: []
    };

    renderRespondentChoiceOptionsFields = fields => {
        return <div>
            { fields.map((name, index) =>
                <RespondentChoiceOption key={`respondent-choice-option-${index}`}>
                    <Field
                        name={name}
                        component={Input} />
                    { fields.length > 2 &&
                        <Button icon={<DeleteOutlined />} type='danger' onClick={() => fields.remove(index)} />
                    }
                </RespondentChoiceOption>
            )}
            <Button icon={<PlusOutlined />} onClick={() => fields.push('')}><span className='hide-mobile'>Добавить</span></Button>
        </div>
    }

    onChangeRespondentFieldType = value => {
        const { form } = this.props;
        const isChoice = value === 'choice';

        form.batch(() => {
            form.change(`choices`, isChoice ? ['', ''] : null);
            form.change('settings.choiceType', isChoice ? 'radio' : null);
        });
    }

    onChangeField = field => {
        this.props.form.initialize(field === 'other' ? ({ field, code: uniqid() }) : ({
            ...find(propEq('code', field), DEFAULT_RESPONDENT_FORM_FIELDS),
            field
        }));
    }

    render() {
        const code = path(['item', 'code'], this.props);

        return <Fragment>
            { !code && this.props.fields.length > 1 &&
                <Field
                    name='field'
                    component={Select}
                    label='Поле'
                    options={this.props.fields}
                    valuePath='code'
                    namePath='name'
                    onChange={this.onChangeField} />
            }
            <ListenerField listenFieldName='field'>
                { ({ field }) => (field === 'other' || code) &&
                    <Fragment>
                        <Field
                            name='name'
                            component={Input}
                            label='Название' />
                        <Field
                            name='type'
                            component={Select}
                            label='Тип'
                            options={RESPONDENT_FORM_FIELD_TYPES}
                            onChange={this.onChangeRespondentFieldType} />
                        <ListenerField listenFieldName='type'>
                            { ({ type }) => type === 'choice' ?
                                <Fragment>
                                    <Field
                                        name='settings.choiceType'
                                        component={Select}
                                        label='Тип выбора'
                                        options={RESPONDENT_FORM_FIELD_CHOICE_TYPES}
                                        hideOptional />
                                    <Form.Item wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} label='Варианты'>
                                        <FieldArray name='choices'>
                                            { ({ fields }) => this.renderRespondentChoiceOptionsFields(fields) }
                                        </FieldArray>
                                    </Form.Item>
                                </Fragment> :
                                <Fragment>
                                    <Field name='choices' component={() => null} />
                                    <Field name='settings.choiceType' component={() => null} />
                                </Fragment>
                            }
                        </ListenerField>
                    </Fragment>
                }
            </ListenerField>
            <SubmitButton type='primary'>{ code ? 'Сохранить' : 'Добавить' }</SubmitButton>
        </Fragment>
    }
}

const validationSchema = props => yup.object().shape({
    field: props.item.code ? yup.string().nullable() : yup.string().required(),
    type: yup.string().required(),
    name: yup.string().required(),
    choices: yup.array().when('type', (type, schema) => type === 'choice' ? schema.of(yup.string().required()) : schema.nullable())
});

export default withFormWrapper(RespondentFormFieldForm, {
    validationSchema,
    mapPropsToValues: prop('item')
});
