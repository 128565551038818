import React, { Component, Fragment } from 'react';
import { Row, Col, Tag, Checkbox, Button } from 'antd';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import * as yup from 'yup';

import withFormWrapper from '../../hocs/withFormWrapper';
import Input from '../formComponents/Input';
import Select from '../formComponents/Select';
import SubmitButton from '../formComponents/SubmitButton';
import { getTestTemplates } from '../../../actions/asyncActions';
import ListenerField from '../ListenerField';
import withFieldWrapper from '../../hocs/withFieldWrapper';

const Card = styled.div`
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    cursor: pointer;
    border-color: ${({ checked }) => checked ? '#f54d2e' : '#e8e8e8'};
    .ant-tag {
        position: absolute;
        right: 0;
        top: -25px;
        margin: 4px;
    }
`;

const CardHeader = styled.div`
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #e8e8e8;
    background: #fafafa;
    color: #2B3D4F;
    min-height: 64px;
    font-weight: 600;
    .ant-checkbox-wrapper {
        margin-right: 10px;
    }
`;

const CardBody = styled.div`
    padding: 10px;
    min-height: 125px;
`;

const Title = styled.div`
    font-size: 16px;
    color: #000;
    margin-bottom: 24px;
`;

class TypeFieldComponent extends Component {
    render() {
        const { ableCustom, input: { value, onChange }} = this.props;

        return <Row gutter={8}>
            <Col span={ableCustom ? 8 : 12}>
                <Card onClick={() => onChange('competence_test')} checked={value === 'competence_test'}>
                    <div>
                        <CardHeader>
                            <Checkbox checked={value === 'competence_test'} onChange={() => onChange('competence_test')} /> <span>Выбор предустановленного профиля должности</span>
                        </CardHeader>
                        <CardBody>При выборе должности автоматически подбирается рекомендуемый список компетенций, который можно отредактировать.</CardBody>
                    </div>
                    <Tag color='#549E38'>рекомендовано</Tag>
                </Card>
            </Col>
            <Col span={ableCustom ? 8 : 12}>
                <Card onClick={() => onChange('competence')} checked={value === 'competence'}>
                    <div>
                        <CardHeader>
                            <Checkbox checked={value === 'competence'} onChange={() => onChange('competence')} /> <span>Создание нового профиля должности</span>
                        </CardHeader>
                        <CardBody>Создавая новую должность вам самостоятельно нужно выбрать заранее сформированные блоки вопросов по компетенциям.</CardBody>
                    </div>
                </Card>
            </Col>
            { ableCustom &&
                <Col span={8}>
                    <Card onClick={() => onChange('custom')} checked={value === 'custom'}>
                        <div>
                            <CardHeader>
                                <Checkbox checked={value === 'custom'} onChange={() => onChange('custom')} /> <span>Ручной режим</span>
                            </CardHeader>
                            <CardBody>Подбор вопросов из любых компетенций в любых пропорциях.</CardBody>
                        </div>
                    </Card>
                </Col>
            }
        </Row>;
    }
}

const TypeField = withFieldWrapper(TypeFieldComponent);

class CreateProjectForm extends Component {
    render() {
        const { user, close } = this.props;
        const ableCustom = (user.role === 'ROLE_ADMIN' || user.ownQuestions);

        return <Fragment>
            <Title>Название теста</Title>
            <Field
                component={Input}
                name='name'
                placeholder='Введите название теста' />
            <Title>Тип теста</Title>
            <Field
                name='type'
                component={TypeField}
                ableCustom={ableCustom} />
            <ListenerField listenFieldName='type'>
                { ({ type }) =>
                    <Fragment>
                        { type === 'competence_test' &&
                            <Row>
                                <Col span={12}>
                                    <Title>Готовый тест</Title>
                                    <Field
                                        name='testTemplate'
                                        component={Select}
                                        placeholder='Выберите готовый тест'
                                        action={getTestTemplates}
                                        filter={{ status: 'published' }}
                                        namePath='name' />
                                </Col>
                            </Row>
                        }
                    </Fragment>
                }
            </ListenerField>
            <div style={{ marginTop: 15 }}>
                <SubmitButton type='primary'>Сохранить</SubmitButton>
                <Button style={{ marginLeft: 15 }} onClick={close}>Отмена</Button>
            </div>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    name: yup.string().required(),
    type: yup.string().required('Укажите тип теста'),
    testTemplate: yup.string().when('type', {
        is: 'competence_test',
        then: schema => schema.required(),
        otherwise: schema => schema
    })
});

export default withFormWrapper(CreateProjectForm, {
    mapPropsToValues: ({ item }) => item,
    validationSchema,
    mapBeforeSubmit: values => ({
        ...values,
        type: values.type === 'competence_test' ? 'competence' : values.type,
        status: 'active',
        profileType: values.type === 'competence_test' ? 'predefined' : values.type === 'competence' ? 'custom' : null
    })
});
