import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import styled from 'styled-components';
import { message } from 'antd';

import { ProjectSettings } from '../../forms/project/ProjectSettingsStep';
import { putCompanyProject } from '../../../actions/asyncActions';
import SubmitButton from '../../forms/formComponents/SubmitButton';

const Wrapper = styled.div`
    padding: 15px;
    background: #fff;
`;

class ProjectSettingsTab extends Component {
    render() {
        const { project, putCompanyProject } = this.props;

        return <Wrapper>
            <ProjectSettings
                item={project}
                formAction={putCompanyProject}
                submitBtn={<SubmitButton type='primary'>Сохранить</SubmitButton>} />
        </Wrapper>;
    }
}

export default withAsyncActions({
    putCompanyProject: putCompanyProject
        .withSuccessHandler(() => message.success('Настройки успешно сохранены'))
        .withErrorHandler(() => message.error('Не удалось сохранить настройки'))
        .withOptions({ resetOnUnmount: true })
})(ProjectSettingsTab);
