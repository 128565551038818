import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withAsyncActions, toSuccess } from 'react-async-client';
import { takeEvery, call } from 'redux-saga/effects';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'ramda';

import Table from './Table';
import Toolbar from './Toolbar';
import { getFilters, getUrlPagination, getSorting } from '../../../utils/urlParams';
import { intervalChannel } from '../../../utils/saga';
import { withState } from 'recompose';
import { LINES_PER_PAGE } from '../../../constants/table';

class TableListComponent extends Component {
    static propTypes = {
        action: PropTypes.func,
        tableColumns: PropTypes.array,
        className: PropTypes.string,
        additionalButtons: PropTypes.element,
        location: PropTypes.object,
        filterForm: PropTypes.elementType,
        buttons: PropTypes.element,
        title: PropTypes.element,
        initFilters: PropTypes.object,
        onRow: PropTypes.func,
        param: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        asyncActions: PropTypes.object,
        expandedRowRender: PropTypes.func,
        staticFilter: PropTypes.object,
        urlPrefix: PropTypes.string,
        history: PropTypes.object
    };

    constructor(props) {
        super(props);

        const action = this.props.action
            .withDefaultPayload(({ location, initFilters, staticFilter = {}, urlPrefix, payload, defaultSort = {}, filters }) => ({
                ...(payload || {}),
                ...getUrlPagination(location, urlPrefix),
                ...(isEmpty(getSorting(location)) ? defaultSort : getSorting(location)),
                q: {
                    ...staticFilter,
                    ...(getFilters(location, urlPrefix) || filters || initFilters || {})
                }
            }))
            .withParams(this.props.param)
            .withOptions({
                dispatchOnMount: true,
                dispatchOnUpdate: true,
                resetOnUnmount: true
            })
            .withSuccessHandler(({ intervalRefreshing, setIntervalRefreshing }) => intervalRefreshing && setIntervalRefreshing(false))
            .withSaga(function* (getProps) {
                const { refreshActions = [], refreshInterval } = getProps();
                const successActions = refreshActions.map(toSuccess);

                if (refreshInterval) {
                    const chan = yield call(intervalChannel);

                    yield takeEvery(chan, () => {
                        getProps().setIntervalRefreshing(true);
                        getProps().action.refresh();
                    });
                }

                yield takeEvery(successActions, () => {
                    getProps().action.refresh();
                });
            });
        this.component = withAsyncActions({
            action,
            ...this.props.asyncActions
        })(ListTableStateless);
    }

    render() {
        const Component = this.component;

        return (
            <Component
                {...this.props}
                setOffset={this.props.setOffset}
                setLimit={this.props.setLimit}
                setFilters={this.props.setFilters}
            />
        );
    }
}

const TableList = withState('intervalRefreshing', 'setIntervalRefreshing', false)(TableListComponent);

class ListTableStateless extends Component {
    render() {
        const {
            action,
            setOffset,
            columns,
            className,
            buttons,
            title,
            filterForm : FilterForm,
            scroll,
            location,
            initFilters,
            onRow,
            rowSelection,
            expandedRowRender,
            staticFilter,
            urlPrefix,
            buttonsTop,
            parseItems,
            rowClassName,
            getExpanded,
            filterProps,
            intervalRefreshing,
            setLimit,
            setFilters,
        } = this.props;

        const filterForm = FilterForm && (
            <FilterForm
                initFilters={{
                    ...initFilters,
                    ...getFilters(location, urlPrefix)
                }}
                staticFilter={staticFilter}
                urlPrefix={urlPrefix}
                filterProps={filterProps}
                setFilters={setFilters}
            />
        );

        const toolbar = (filterForm || buttons) && (
            <Toolbar
                title={title}
                filterForm={filterForm}
                buttons={buttons}
                buttonsTop={buttonsTop}
            />
        );

        return (
            <div className={className}>
                {toolbar}
                <Table
                    action={action}
                    setOffset={setOffset}
                    setLimit={setLimit}
                    columns={columns}
                    scroll={scroll}
                    onRow={onRow}
                    location={location}
                    rowClassName={rowClassName}
                    rowSelection={rowSelection}
                    expandedRowRender={expandedRowRender}
                    urlPrefix={urlPrefix}
                    parseItems={parseItems}
                    getExpanded={getExpanded}
                    intervalRefreshing={intervalRefreshing}
                    pagination={{
                        limit: this.props.limit,
                        offset: this.props.offset,
                    }}
                />
            </div>
        );
    }
}

class TableListState extends Component {
    state = {
        offset: 0,
        limit: LINES_PER_PAGE,
    }

    setOffset = (offset, limit) => {
        this.setState({ offset, limit });
    }

    setLimit = (limit = LINES_PER_PAGE) => {
        this.setState({ limit });
    }

    setFilters = (filters) => {
        this.setState({ filters, offset: 0 });
    }

    render() {
        return (
            <TableList
                {...this.props}
                {...this.state}
                setOffset={this.setOffset}
                setLimit={this.setLimit}
                setFilters={this.setFilters}
            />
        );
    }
}

export default withRouter(TableListState);
