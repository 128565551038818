import React, { Component, Fragment } from 'react';
import { Alert, Button, Table, Select, message } from 'antd';
import styled from 'styled-components';
import { find, propEq, flatten, prop, contains, filter, path, findIndex, head, pathOr } from 'ramda';

import { openProjectCategorySelectModal } from '../../../actions/modalActions';
import { patchTestTemplate, getComplexity } from '../../../actions/asyncActions';
import withFormWrapper from '../../hocs/withFormWrapper';
import ListenerField from '../../forms/ListenerField';
import { getCategoriesTree } from '../../../utils/categories';
import { getExecutionTime } from '../../../utils/time';
import { TEST_QUESTIONS_TIME } from '../../../constants/tests';
import Complexity from '../table/Complexity';
import { asyncConnect } from 'react-async-client';
import SubmitButton from '../../forms/formComponents/SubmitButton';

const Wrapper = styled.div`
    padding: 15px;
`;

class TestCategoriesTab extends Component {
    mapPropsToValues = props => {
        return {
            ...props.item,
            categories: (props.item.categorySelections || []).map(({ category }) => category)
        };
    }

    mapBeforeSubmit = (values, props) => {
        const { test: { categorySelections = [] }} = this.props;
        const data = [];

        values.categories.forEach(category => {
            if (!find(propEq('category', category), categorySelections)) {
                data.push({
                    op: 'add',
                    path: '/categorySelections/-',
                    value: {
                        category,
                        level: head(pathOr([], ['availableCompetenceLevels'], find(propEq('id', category), props.getCategories.data.items)))
                    }
                });
            }
        });

        categorySelections.forEach(({ category }, index) => {
            if (!find(id => id === category, values.categories)) {
                data.push({ op: 'remove', path: `/categorySelections/${index}`});
            }
        });

        return {
            id: values.id,
            data
        };
    }

    disableSaveCategories = (values, props) => {
        const { data } = this.mapBeforeSubmit(values, props);

        return !data.length;
    }

    openModal = () => {
        const { openProjectCategorySelectModal, test } = this.props;

        openProjectCategorySelectModal({
            item: test,
            formAction: patchTestTemplate,
            mapPropsToValues: this.mapPropsToValues,
            mapBeforeSubmit: this.mapBeforeSubmit,
            complete: true,
            ignoreValid: true,
            disableSave: this.disableSaveCategories
        });
    }

    getLevel = (categorySelections, category) => {
        const level = path(['level'], find(propEq('category', category), categorySelections));
        return level ? String(level) : undefined;
    }

    onChange = (level, category) => {
        const { form } = this.props;
        const { categorySelections } = form.getState().values;
        const categoryIndex = findIndex(propEq('category', category), categorySelections);
        const categories = categorySelections.map((item, i) => i === categoryIndex ? ({ ...item, level }) : item);

        form.change('categorySelections', categories);
    }

    getColumns = categorySelections => {
        return [
            {
                key: 'name',
                dataIndex: 'name',
                render: (name, { parent, result }) => result ? <strong>Итоговое время</strong> : parent ? name : <strong>{ name }</strong>
            },
            {
                key: 'levels',
                title: 'Сложность',
                render: item => {
                    if (item.result) {
                        const time = (categorySelections || []).reduce((res, cur) => {
                            const settings = this.props.getComplexity.data.items || [];
                            const time = settings.reduce((res, set) => cur.level ? TEST_QUESTIONS_TIME[cur.level][set.level] * set.executionTime + res : res, 0)

                            return res + time;
                        }, 0);

                        return getExecutionTime(time);
                    }

                    const levels = filter(l => contains(l, item.availableCompetenceLevels), [1, 2, 3]);
                    const value = this.getLevel(categorySelections, item.id);
                    const notExistedValue = !contains(Number(value), levels) && value;

                    return item.parent &&
                        <Select
                            style={{ width: 60 }}
                            value={notExistedValue ? <Complexity level={Number(value)} /> : value}
                            dropdownMatchSelectWidth={!!levels.length}
                            onChange={value => this.onChange(value, item.id)}
                            allowClear>
                            { levels.map(level =>
                                <Select.Option value={level.toString()} key={`${item.id}-level-${level}`}>
                                    <Complexity level={level} />
                                </Select.Option>
                            )}
                        </Select>;
                }
            }
        ];
    }

    renderCategories = categories => {
        const grouped = flatten(getCategoriesTree((categories || []).map(({ _embedded }) => _embedded.category)).map(item => ([item, ...item.items])));

        return <Fragment>
            <ListenerField listenFieldName='categorySelections'>
                { ({ categorySelections }) =>
                    <Table
                        style={{ marginTop: 15, marginBottom: 15 }}
                        columns={this.getColumns(categorySelections)}
                        dataSource={[...grouped, { result: true, id: 'result' }]}
                        pagination={false}
                        rowKey='id'
                        size='small' />
                }
            </ListenerField>
            <SubmitButton type='primary'>Сохранить</SubmitButton>
        </Fragment>;
    }

    render() {
        const { test } = this.props;

        return <Wrapper className='wrap-bg'>
            <Alert
                style={{ marginBottom: 15 }}
                message='Вы можете изменить набор компетенций и изменить тип проверки компетенций до тех пор, пока проект не запущен, т. е. до отправки ссылки на прохождение теста первому респонденту. В случае если хотя бы одному респонденту была отправлена ссылка из этого проекта, то редактирование набора компетенций и настроек теста становится не доступно. Это ограничение необходимо для соблюдения единых условий проведения тестирования. В случае, если требуется изменить тест или настройки рекомендуется создать новый проект'
                type='info' />
            <Button onClick={this.openModal}>Редактировать профиль должности</Button>
            { !!test.categorySelections.length && this.renderCategories(test.categorySelections) }
        </Wrapper>
    }
}

export default asyncConnect({
    getComplexity: getComplexity
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    formAction: patchTestTemplate
        .withSuccessHandler(() => message.success('Компетенции успешно сохранены'))
        .withErrorHandler(() => message.error('Не удалось сохранить компетенции'))
        .withOptions({ resetOnUnmount: true })
}, null, { openProjectCategorySelectModal })(
    withFormWrapper(
        TestCategoriesTab,
        {
            mapPropsToValues: prop('test'),
            mapBeforeSubmit: ({ categorySelections, id }) => ({
                id,
                data: [
                    { op: 'replace', path: '/categorySelections', value: categorySelections }
                ]
            })
        }
    )
);
