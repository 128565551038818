import React, { Component, Fragment } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { equals, filter } from 'ramda';
import { Row, Col } from 'antd';
import * as yup from 'yup';

import SubmitButton from './formComponents/SubmitButton';
import Select from './formComponents/Select';
import withFormWrapper from '../hocs/withFormWrapper';
import { TEST_STATUSES } from '../../constants/tests';
import ProjectSettingsFields from './project/ProjectSettingsFields';

class TestTemplateSettingsForm extends Component {
    render() {
        return <Fragment>
            <Row>
                <Col span={8}>
                    <Field
                        name='status'
                        component={Select}
                        label='Статус'
                        options={TEST_STATUSES} />
                </Col>
            </Row>
            <ProjectSettingsFields {...this.props} prefix='currentSettings' />
            <FormSpy subscription={{ pristine: true }}>
                { ({ pristine }) => <SubmitButton type='primary' disabled={pristine}>Сохранить</SubmitButton> }
            </FormSpy>
        </Fragment>
    }
}

const validationSchema = yup.object().shape({
    status: yup.string().required()
});

const FIELDS = ['formRequired', 'infoScheme', 'pauseAllowed', 'shuffle', 'skipQuestionsAllowed', 'timeLimited', 'timeLimitedBy'];

export default withFormWrapper(TestTemplateSettingsForm, {
    mapPropsToValues: ({ item }) => item,
    validationSchema,
    mapBeforeSubmit: (values, { item: { id, status, currentSettings }}) => {
        let data = filter(key => !equals(currentSettings[key], values.currentSettings[key]), FIELDS).map(key => ({
            op: 'replace',
            path: `/currentSettings/${key}`,
            value: values.currentSettings[key]
        }));

        if (status !== values.status) {
            data.push({ op: 'replace', path: '/status', value: values.status });
        }

        return ({
            id,
            data
        });
    }
});
