import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { prop } from 'ramda';

import { getUser } from '../../../actions/asyncActions';
import LogoImg from '../../../assets/img/logo_red.svg';

const Wrapper = styled.div`
    font-family: ${prop('fontFamily')};
    .answer {
        border: 3px solid #2c3d4e;
    }
`;

const Header = styled.div`
    height: 80px;
    background-color: ${({ colors }) => colors.header || '#fff'};
    color: ${({ colors }) => colors.headerText || '#000'};
    border-bottom: 1px solid #ccc;
    padding: 0 15px;
`;

const HeaderContent = styled.div`
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    @media (min-width: 992px) {
        width: 970px;
    }
`;

const Logo = styled.div`
    background-image: url(${({ logo }) => logo ? `/api/images/${logo}` : LogoImg});
    width: 220px;
    height: 55px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center left;
`;

const Content = styled.div`
    margin: 20px auto;
    flex: 1 1 auto;
    width: 100%;
    @media (min-width: 992px) {
        width: 970px;
    }
`;

const UserName = styled.div`
    font-size: 16px;
    font-weight: 100;
`;

class PreviewPageWrapper extends Component {
    render() {
        const { showName, logo, userName, fontFamily, colors } = this.props;

        return <Wrapper fontFamily={fontFamily}>
            <Header colors={colors}>
                <HeaderContent>
                    <Logo logo={logo} />
                    { showName && <UserName>{ userName }</UserName> }
                </HeaderContent>
            </Header>
            <Content>
                { this.props.children }
            </Content>
        </Wrapper>;
    }
}

const stateToProps = state => ({
    userName: getUser.selectData(state).fullName
});

export default connect(stateToProps)(PreviewPageWrapper);
