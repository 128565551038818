import React, { Component } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import { pathOr, find, propEq, filter } from 'ramda';
import HighchartsExporting from 'highcharts/modules/exporting';

HighchartsExporting(Highcharts);

export default class ProjectRespondentStat extends Component {
    getFactLevelData = diff => {
        const { respondent, testTemplate } = this.props;
        const categories = pathOr([], ['respondentStatistic', 'categories'], respondent);
        const competencies = pathOr([], ['respondentStatistic', 'competencies'], respondent);
        const categorySelections = pathOr([], ['_embedded', testTemplate ? 'testTemplate' : 'testSuite', 'categorySelections'], respondent);

        return filter(comp => {
            const goalLevel = pathOr(0, ['level'], find(propEq('category', comp.category), categorySelections));
            return Math.abs(goalLevel - pathOr(0, ['receivedLevel'], find(propEq('category', comp.category), competencies))) === diff;
        }, categories.map((item, index) => ({...item, index}))).map(comp => ({
            y: pathOr(0, ['receivedLevel'], find(propEq('category', comp.category), competencies)),
            x: comp.index
        }));
    }

    componentDidMount() {
        window.statChart = this.chart;
    }

    componentWillUnmount() {
        window.statChart = null;
    }

    getCategoriesChartData = () => {
        const { respondent, testTemplate, isIntegralResultText } = this.props;
        const competencies = pathOr([], ['respondentStatistic', 'categories'], respondent);
        const categorySelections = pathOr([], ['_embedded', testTemplate ? 'testTemplate' : 'testSuite', 'categorySelections'], respondent);

        return {
            credits: {
                enabled: false
            },
            title: {
                text: undefined
            },
            size: {
                height: 400
            },
            plotOptions: {
                column: {
                    stacking: 'normal'
                },
                spline: {
                    color: '#F06F6F',
                    marker: {
                        symbol: 'square',
                        lineColor: '#C10505',
                        lineWidth: 4,
                        radius: 5,
                        states: {
                            hover: {
                                enabled: false
                            },
                            select: {
                                enabled: false
                            }
                        }
                    }
                }
            },
            tooltip: {
                enabled: false
            },
            yAxis: {
                min: 0,
                max: 3,
                allowDecimals: false,
                title: {
                    text: 'Уровень выраженности компетенции',
                    enabled: true
                },
                plotBands: [
                    { label: { text: '0', x: -85 }, from: 0, to: 1 },
                    { label: { text: '1', x: -85 }, from: 1, to: 2 },
                    { label: { text: '2', x: -85 }, from: 2, to: 3 },
                    { label: { text: '3', x: -85 }, from: 3, to: 4 }
                ]
            },
            xAxis: {
                min: 0,
                max: competencies.length - 1,
                categories: competencies.map(comp => comp.name)
            },
            series: [
                {
                    name: 'Соответствие',
                    type: 'column',
                    color: '#D5DBE4',
                    data: this.getFactLevelData(0),
                    stack: 'profile'
                },
                {
                    name: 'Незначительное расхождение',
                    type: 'column',
                    color: '#F07074',
                    data: this.getFactLevelData(1),
                    stack: 'profile'
                },
                {
                    name: 'Значительное расхождение',
                    type: 'column',
                    color: '#990017',
                    data: this.getFactLevelData(2),
                    stack: 'profile'
                },
                {
                    name: 'Целевой уровень',
                    type: isIntegralResultText ? 'column' : 'spline',
                    data: competencies.map(({ category }) => pathOr(0, ['level'], find(propEq('category', category), categorySelections))),
                    stack: 'level'
                }
            ]
        };
    }

    render() {
        return <HighchartsReact
            highcharts={Highcharts}
            options={this.getCategoriesChartData()}
            ref={node => this.chart = node}
        />;
    }
}
