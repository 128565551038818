import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';

const TextBlock = styled.span`
    white-space: pre-wrap;
    p {
        margin-bottom: 0;
    }
`;

const RowWrapper = styled(Row)`
    padding: 0 0 15px;
    @media (max-width: 767px) {
        padding: 0;
        & > .ant-col.ant-col-sm-24{
            margin-bottom: 15px;
        }
    }
    .ant-row.ant-form-item{
        display: flex;
        align-items: center;
    }
    .ant-col-md-24,.ant-col-md-12{
        h3{
            border: 1px solid #e8e8e8;
            padding-bottom: 10px;
            padding: 10px;
            background: #fafafa;
            margin: -1px 0px;
        }
    }
    .ant-col-md-12{
        &:last-child{
            padding-left: 7px;
            @media (max-width: 767px) {
                padding-left: 0;
            }
        }
        &:first-child{
            padding: 0 7px 0 0;
        }
    }
    .label-block{
        display: block;
    }
`;

const ColBody = styled.div`
    border: 1px solid #e8e8e8;
    padding: 10px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    .collaborators .ant-form-item-control {
        width: 100%;
    }
`;

const QuestionnaryInfo = ({ data }) =>
    <div style={{ padding: 15 }}>
        <div>
            <strong>Название: </strong>
            { data.name }
        </div>
        <div>
            <strong>Описание: </strong>
            <TextBlock dangerouslySetInnerHTML={{ __html: data.description || '-' }} />
        </div>
        <p>
            <strong>Отчеты: </strong>
            { data.availableReports && !!data.availableReports.length ?
                data.availableReports.map((report, index) =>
                    <span key={`report-${index}`}>{ report } </span>
                ) : '-'
            }
        </p>
        <RowWrapper className='ant-form-inline'>
            <Col md={24} sm={24}>
                <h3 className='title-categories'>Заголовок</h3>
                <ColBody>
                    <TextBlock dangerouslySetInnerHTML={{ __html: data.title || '-' }} />
                </ColBody>
            </Col>
        </RowWrapper>
        <RowWrapper className='ant-form-inline'>
            <Col md={24} sm={24}>
                <h3 className='title-categories'>Текст приветствия</h3>
                <ColBody>
                    <TextBlock dangerouslySetInnerHTML={{ __html: data.welcomeText || '-' }} />
                </ColBody>
            </Col>
        </RowWrapper>
        <RowWrapper className='ant-form-inline'>
            <Col md={24} sm={24}>
                <h3 className='title-categories'>Шкала оценки</h3>
                <ColBody>
                    <ul>
                        { data.scale && !!data.scale.length ?
                            data.scale.map((scale, index) =>
                                <li key={`scale-${index}`}>{scale}: {(data.answerMap || {})[scale] || '-'}</li>
                            ) : '-'
                        }
                    </ul>
                </ColBody>
            </Col>
        </RowWrapper>
    </div>;

export default QuestionnaryInfo;
