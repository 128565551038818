import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import SurveyExtendedForm from '../forms/survey/SurveyExtendedForm';
import { putQuestionnary } from '../../actions/asyncActions';

class QuestionnaryExtended extends Component {
    render() {
        const { data, putQuestionnary } = this.props;

        return <div style={{ padding: 15 }}>
            <SurveyExtendedForm
                formAction={putQuestionnary}
                data={data} />
        </div>;
    }
}

export default withAsyncActions({
    putQuestionnary: putQuestionnary
        .withSuccessHandler(() => message.success('Настройки успешно сохранены'))
        .withErrorHandler(() => message.error('Не удалось сохранить настройки'))
        .withOptions({ resetOnUnmount: true })
})(QuestionnaryExtended);
