import React, { Component } from 'react';
import ProjectRespondentsTab, { ProjectRespondentsTabButtons } from './ProjectRespondentsTab';

export const ProjectExpertsTabButtons = props =>
    <ProjectRespondentsTabButtons {...props} auditEnabled />;

export default class ProjectExpertsTab extends Component {
    render() {
        return <ProjectRespondentsTab {...this.props} auditEnabled />;
    }
}
