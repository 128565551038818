import React, { Component, Fragment } from 'react';
import { Alert, Button, Table, Tooltip } from 'antd';
import { append, assocPath, compose, endsWith, find, findIndex, keys, mergeAll, path, propEq, pickBy, sum, values } from 'ramda';
import styled from 'styled-components';
import * as yup from 'yup';
import { Field } from 'react-final-form';

import withFormWrapper from '../hocs/withFormWrapper';
import Select from './formComponents/Select';
import InputNumber from './formComponents/InputNumber';
import SubmitButton from './formComponents/SubmitButton';
import ListenerField from './ListenerField';
import Complexity from '../user/table/Complexity';
import { QUESTION_LEVELS } from '../../constants/questions';

const TableStyled = styled(Table)`
    z-index: 2;

    .ant-table-thead > tr > th {
        padding: 10px;
        background: #fafafa;
        font-size: 1.17em;

        .ant-form-item {
            display: inline;

            label {
                font-size: 18px;
                font-weight: bold;
            }

            .ant-col {
                display: inline;
            }

            .ant-form-item-control-input {
                display: inline-block;

                .ant-form-item-control-input-content > div {
                    display: inline-block;
                    margin-left: 8px;
                }
            }

            .search-input {
                width: 60px;
            }
        }
    }

    .ant-form-item {
        margin-bottom: 0;
    }
    .ant-alert-info {
        margin: 40px 10px;
    }
`;

const ComplexityBlock = styled.div`
    display: inline-flex;
    align-items: baseline;
    margin: 0 6px 0 2px;
    font-size: 12px;
    color: #B4B4B4;
`;

const ComplexityTitle = styled.small`
    color: ${({ invalid }) => invalid ? '#f54d2e' : '#000'};
`;

const CountTitle = styled.b`
    color: ${({ invalid }) => invalid ? '#f54d2e' : '#000'};
`;

const ComplexityWrapper = styled.div`
    display: flex;
`;

const Footer = styled.div`
    z-index: 1;
    padding: 15px;

    .ant-btn + .ant-btn {
        margin-left: 15px;
    }

    .ant-btn:last-child {
        float: right;
    }
`;

class CategoryDistributionSettingsForm extends Component {
    renderComplexity = (item) => {
        return (
            <ComplexityWrapper>
                { !!item.questionsCount['1'] &&
                <ComplexityBlock>
                    <Complexity level={1} /> { item.questionsCount['1'] }
                </ComplexityBlock>
                }
                { !!item.questionsCount['2'] &&
                <ComplexityBlock>
                    <Complexity level={2} /> { item.questionsCount['2'] }
                </ComplexityBlock>
                }
                { !!item.questionsCount['3'] &&
                <ComplexityBlock>
                    <Complexity level={3} /> { item.questionsCount['3'] }
                </ComplexityBlock>
                }
            </ComplexityWrapper>
        );
    }

    getColumns = (complexityLevel, counts, countsMeta) => {
        const { category, levels, proportions } = this.props;

        const levelColumns = QUESTION_LEVELS.map(level => {
            const levelError = path(['error', `level-min-${level.id}`], countsMeta) || path(['error', `level-max-${level.id}`], countsMeta);
            return ({
                key: `level-${level.id}`,
                align: 'center',
                title: () => {
                    const count = path([complexityLevel, level.id, 'count'], proportions || []) || 0;
                    const notEnoughQuestions = category.questionsCount[level.id] < count;
                    const levelCounts = sum(values(pickBy((val, key) => endsWith(`-${level.id}`, key), counts)));
                    const levelValue = count - levelCounts;

                    return complexityLevel ? (
                        <Tooltip title={
                            notEnoughQuestions ?
                                `В данном индикаторе недостаточно вопросов (${category.questionsCount[level.id]})`
                                : levelError || null
                        }>
                            <CountTitle invalid={levelValue < 0}>{ levelValue }</CountTitle> <Complexity level={level.id} /><br />
                            <ComplexityTitle invalid={notEnoughQuestions}>{ path(['value'], level) }</ComplexityTitle>
                        </Tooltip>
                    ) : '';
                },
                render: item => {
                    return (
                        <Field
                            name={`counts.${item.id}-${level.id}`}
                            component={InputNumber}
                            min={0}
                            max={item.questionsCount[level.id]}
                            disabled={!complexityLevel}
                            parentError={levelError}
                            disableClear
                            showTextError={false}
                        />
                    );
                }
            });
        });

        return [
            {
                key: 'name',
                title: <Fragment>
                    <Field
                        name='complexityLevel'
                        label={category.name}
                        component={Select}
                        labelCol={{ xs: { span: 24 } }}
                        wrapperCol={{ xs: { span: 4 } }}
                        options={levels.map(level => ({ id: level.toString(), value: path(['icon'], find(propEq('id', level), QUESTION_LEVELS)) }))}
                        required
                    />
                </Fragment>,
                dataIndex: 'name',
            },
            {
                key: 'complexity',
                width: '140px',
                render: category => this.renderComplexity(category)
            },
            ...levelColumns,
        ];
    }
    render() {
        const loading = this.props.formAction.meta.pending;

        return <Field name={`counts`}>{ ({ input: { value: counts }, meta: countsMeta }) => (
            <Fragment>
                <ListenerField listenFieldName='complexityLevel'>
                    {({ complexityLevel }) => (
                        <TableStyled
                            dataSource={this.props.categories}
                            columns={this.getColumns(complexityLevel, counts, countsMeta)}
                            pagination={false}
                            loading={loading}
                            rowKey='id'
                            size='small'
                            locale={{
                                emptyText: <Alert
                                    type='info'
                                    message={
                                        <div style={{ textAlign: 'center' }}>
                                            <div>Компетенция неполная</div>
                                        </div>
                                    }
                                />
                            }}
                        />
                    )}
                </ListenerField>
                { !this.props.disabled && (
                    <Footer>
                        <SubmitButton type='primary'>Сохранить</SubmitButton>
                        <Button onClick={this.props.onCancel} disabled={loading}>Отмена</Button>
                        <Button onClick={this.props.onClear} disabled={loading || !this.props.onClear}>Отключить распределение по индикаторам</Button>
                    </Footer>
                )}
            </Fragment>
        )}</Field>;
    }
}

const validationSchema = ({ categories = [], proportions }) => yup.object().shape({
    complexityLevel: yup.string().nullable().required(),
    counts: yup.object().shape({
        ...mergeAll(
            categories.map(category =>
                mergeAll(QUESTION_LEVELS.map(level => ({
                    [`${category.id}-${level.id}`]: yup
                        .number()
                        .required()
                        .max(path(['questionsCount', level.id], category))
                })))
            )
        ),
    }).when('complexityLevel', (complexityLevel, schema) => {
        if (proportions) {
            QUESTION_LEVELS.forEach(level => {
                schema = schema.test({
                    name: `complexityLevelCountMax-${level.id}`,
                    params: { additionalPath: [`level-max-${level.id}`] },
                    message: () => `Выбрано вопросов больше чем заложено уровнем сложности для данной компетенции`,
                    test: (counts) => {
                        const levelValues = sum(values(pickBy((val, key) => endsWith(`-${level.id}`, key), counts)));
                        const min = path([complexityLevel, level.id, 'count'], proportions);
                        return min ? min > levelValues || min === levelValues: true;
                    },
                }).test({
                    name: `complexityLevelCountMin-${level.id}`,
                    params: { additionalPath: [`level-min-${level.id}`] },
                    message: () => `Выбрано вопросов меньше чем заложено уровнем сложности для данной компетенции`,
                    test: (counts) => {
                        const levelValues = sum(values(pickBy((val, key) => endsWith(`-${level.id}`, key), counts)));
                        const min = path([complexityLevel, level.id, 'count'], proportions);
                        return min ? min < levelValues || min === levelValues : true;
                    },
                });
            });
            return schema;
        } else {
            return schema;
        }
    })
});

export default withFormWrapper(CategoryDistributionSettingsForm, {
    mapPropsToValues: ({ test, item, value, category, categories = [] }) => ({
        complexityLevel: value,
        category: category.id,
        test,
        categories,
        counts: {
            ...mergeAll(
                categories.map(category =>
                    mergeAll(QUESTION_LEVELS.map(level => ({
                        [`${category.id}-${level.id}`]: path(['count'], find(cat => propEq('category', category.id, cat) && propEq('level', level.id, cat), path(['categoryDistributionSettings'], item) || [])) || 0
                    })))
                )
            )
        },
    }),
    validationSchema,
    mapBeforeSubmit: ({ test, complexityLevel, category, counts: values  }) => {
        const categoryIndex = findIndex(propEq('category', category), test.categorySelections);
        const categoryDistributionSettings = keys(values).map(key => {
            let [ category, level ] = key.split('-');
            return { category, level, count: values[key] };
        }).filter(path(['count']));

        return ({
            ...(categoryIndex === -1 ? {
                ...test,
                categorySelections: append({
                    category,
                    level: complexityLevel,
                    categoryDistributionSettings,
                }, test.categorySelections)
            } : compose(
                    assocPath(['categorySelections', categoryIndex, 'level'], complexityLevel),
                    assocPath(['categorySelections', categoryIndex, 'categoryDistributionSettings'], categoryDistributionSettings)
            )(test)),
        });
    },
});
