import styled from 'styled-components';

export default styled.span`
    color: #1890ff;
    transition: color 0.3s;
    cursor: pointer;
    &:hover {
        color: #40a9ff;
    }
`;
