import React from 'react';
import { Field } from 'react-final-form';
import { Col } from 'antd';

import withFilterForm from '../../hocs/withFilterForm';
import Rangepicker from '../formComponents/Rangepicker';
import Select from '../formComponents/Select';
import { getCompanies, getCompanyPayments } from '../../../actions/asyncActions';
import BaseFilter from './BaseFilter';
import { PAYMENTS_STATUSES_FILTER } from '../../../constants/companies';

export default withFilterForm(props =>
    <BaseFilter
        action={getCompanyPayments}
        searchFieldName='customerName'
        searchFieldPlaceholder='Покупатель'
        span={6}>
        <Col span={6}>
            <Field
                name='company'
                component={Select}
                action={getCompanies}
                filter={{ enabled: true }}
                placeholder='Компания'
                namePath='name'
                searchable
                allowClear />
        </Col>
        <Col span={6}>
            <Field
                name='statuses'
                component={Select}
                options={PAYMENTS_STATUSES_FILTER}
                placeholder='Статус'
                isMulti
                allowClear
            />
        </Col>
        <Col span={6}>
            <Field
                name='updatedAfter'
                component={Rangepicker}
                toPath='updatedBefore'
                change={props.form.change}
            />
        </Col>
    </BaseFilter>
);
