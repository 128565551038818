import React, { Component, Fragment } from 'react';
import { asyncConnect, toSuccess } from 'react-async-client';
import styled from 'styled-components';
import { takeEvery } from 'redux-saga/effects';
import { DeleteOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';

import { getSettingsTemplates, deleteSettingsTemplate } from '../../../actions/asyncActions';
import { Button, Spin, List, Card, Popconfirm, message } from 'antd';
import { openSettingsTemplateModal } from '../../../actions/modalActions';
import { POST_SETTINGS_TEMPLATE, PUT_SETTINGS_TEMPLATE, DELETE_SETTINGS_TEMPLATE } from '../../../constants/actionTypes';

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
`;

const StyledCard = styled(Card)`
    width: 100%;

    p {
        margin: 0;
    }
    .variable-text {
        color: #1890ff;
    }
`;

const StyledList = styled(List)`
    &.ant-list{
        margin-top: 20px;
    }
    .ant-list-item{
        padding: 0;
    }
    .ant-card{
        border-radius: 6px;
    }
    .ant-card-head{
        border-top: 1px solid #e8e8e8;
        padding: 10px 25px;
        background: #fafafa;
        margin: -1px 0px;
        .ant-card-head-title,.ant-card-extra{
            padding: 0;
        }
    }
`;

class SettingsTemplates extends Component {
    addSettingsTemplate = () => this.props.openSettingsTemplateModal({});

    deleteSettingsTemplate = id => this.props.deleteSettingsTemplate.dispatch({ id });

    renderExtra = item => {
        const { openSettingsTemplateModal } = this.props;

        return <Button.Group>
            <Button icon={<EditOutlined />} onClick={() => openSettingsTemplateModal(item)} />
            <Popconfirm
                title='Вы уверены, что хотите удалить шаблон?'
                onConfirm={() => this.deleteSettingsTemplate(item.id)}
                okText='Да'
                cancelText='Нет'>
                <Button icon={<DeleteOutlined />} type='danger' />
            </Popconfirm>
        </Button.Group>;
    }

    render() {
        const { data: { items = [] }, meta } = this.props.getSettingsTemplates;

        return (
            <Fragment>
                <Header>
                    <h1>Шаблоны писем { meta.pending && <Spin /> }</h1>
                    <Button icon={<PlusOutlined />} type='primary' onClick={this.addSettingsTemplate}>
                        <span className='hide-mobile'>Добавить</span>
                    </Button>
                </Header>
                <StyledList
                    dataSource={items}
                    loading={meta.pending}
                    renderItem={item => (
                        <List.Item>
                            <StyledCard
                                title={item.title}
                                extra={this.renderExtra(item)}>
                                <div style={{ marginBottom: 10 }}><b>Тема письма:</b> { item.subject }</div>
                                <div dangerouslySetInnerHTML={{ __html: item.content }} />
                            </StyledCard>
                        </List.Item>
                    )} />
            </Fragment>
        );
    }
}

export default asyncConnect({
    getSettingsTemplates: getSettingsTemplates
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(POST_SETTINGS_TEMPLATE), toSuccess(PUT_SETTINGS_TEMPLATE), toSuccess(DELETE_SETTINGS_TEMPLATE)], function() {
                const { getSettingsTemplates } = getProps();

                getSettingsTemplates.refresh();
            })
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    deleteSettingsTemplate: deleteSettingsTemplate
        .withSuccessHandler(() => message.success('Шаблон успешно удален'))
        .withErrorHandler(() => message.error('Не удалось удалить шаблон'))
        .withOptions({ resetOnUnmount: true })
}, null, { openSettingsTemplateModal })(SettingsTemplates);
