import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import Modal from './Modal';
import { putSurvey } from '../../actions/asyncActions';
import SurveyPageElementsForm from '../forms/survey/SurveyPageElementsForm';

class SurveyPageElementsModal extends Component {
    render() {
        const { modal, params, putSurvey } = this.props;

        return <Modal
            {...modal}
            title='Редактировать элементы страницы'
            footer={null}>
            <SurveyPageElementsForm
                formAction={putSurvey}
                item={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    putSurvey: putSurvey
        .withSuccessHandler(({ close }) => {
            message.success('Элементы страницы успешно изменены');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось изменить элементы страницы'))
        .withOptions({ resetOnUnmount: true })
})(SurveyPageElementsModal);
