import React, { Component } from 'react';
import { Form } from 'antd';
import { contains } from 'ramda';
import styled, { css } from 'styled-components';

import RequiredFieldsContext from '../contexts/RequiredFieldsContext';
import FormServerErrorsContext from '../contexts/FormServerErrorsContext';

const ErrorWrapper = styled.div`
    margin-top: 4px;
    color: #f5222d;
    font-size: 10px;
`;

const Item = styled(Form.Item)`
    .ant-form-item-control {
        line-height: unset;
    }
    .search-input div.search-input__control {
        ${({ validateStatus }) => validateStatus === 'error' &&
            css`
                border-color: #f5222d;
            `
        }
    }
`;

export default WrappedComponent =>
    class FieldWrapper extends Component {
        static defaultProps = {
            displayError: true,
            showTextError: true,
            wrapperCol: { span: 24 },
            labelCol: { span: 24 }
        };

        componentWillUnmount() {
            if (!this.props.disableClear) {
                this.onChange();
            }
        }

        onChange = (value, data) => {
            const { input, onChange, parse } = this.props;
            const val = parse ? parse(value) : value;

            input.onChange(val);
            onChange && onChange(val, data);
        }

        renderField = serverError => {
            const { input: { name }, label, parentError, displayError, required, wrapperClassName, extra, meta, highlightError, wrapperCol, labelCol, showTextError, hideOptional, small } = this.props;
            const error = serverError && !meta.dirtySinceLastSubmit
                ? serverError
                : meta.invalid && (meta.submitFailed || highlightError)
                    ? meta.error
                    : parentError && (meta.submitFailed || highlightError) ? parentError : null;

            return <RequiredFieldsContext.Consumer>
                { fields =>
                    <Item
                        className={wrapperClassName}
                        label={
                            label ?
                                <div>
                                    { label && ((contains(name, fields) || required || hideOptional) ? <div>{ label }</div> : <div>{ label } <small>(опционально)</small></div>) }
                                    { small && <div><small>{ small }</small></div> }
                                </div> :
                                null
                        }
                        extra={extra}
                        validateStatus={error && displayError ? 'error' : undefined}
                        wrapperCol={wrapperCol}
                        labelCol={labelCol}
                    >
                        <WrappedComponent
                            {...this.props}
                            onChange={this.onChange}
                        />
                        { error  && displayError && showTextError && (
                            <ErrorWrapper>
                                {error}
                            </ErrorWrapper>
                        )}
                    </Item>
                }
            </RequiredFieldsContext.Consumer>;
        }

        render() {
            const { name } = this.props.input;

            return <FormServerErrorsContext.Consumer>
                { serverErrors => this.renderField(serverErrors[name]) }
            </FormServerErrorsContext.Consumer>;
        }
    }
