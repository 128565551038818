import moment from 'moment';
import jwtDecode from 'jwt-decode';

import { addDefaultHeader, removeDefaultHeader } from './http';

export function handleTokenChange(payload) {
    if (payload) {
        localStorage.setItem('token', payload.token);
        localStorage.setItem('refresh_token', payload.refresh_token);
        localStorage.setItem('expired_date', getExpiredDate(payload.token));
        addDefaultHeader('Authorization', `Bearer ${payload.token}`);
    } else {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('expired_date');
        removeDefaultHeader('Authorization');
    }
}

export function getToken() {
    return localStorage.getItem('token');
}

export function getRefreshToken() {
    const token = localStorage.getItem('refresh_token');
    return token === 'null' ? null : token;
}

export function isTokenExpired() {
    const expiredDate = localStorage.getItem('expired_date');

    return expiredDate ? moment().isAfter(moment(new Date(expiredDate))) : false;
}

function getExpiredDate(token) {
    try {
        const { exp } = jwtDecode(token);

        return new Date(exp * 1000);
    } catch (e) {
        return null;
    }
}
