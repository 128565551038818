import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import styled from 'styled-components';
import { Modal as AntdModal, Table, Button, message } from 'antd';
import { pathOr } from 'ramda';
import { withStateHandlers } from 'recompose';

import Modal from './Modal';
import { getQuestions, patchCompanyTestQuestions } from '../../actions/asyncActions';
import { LINES_PER_PAGE } from '../../constants/table';
import StringWithoutStyles from '../user/table/StringWithoutStyles';

const StyledModal = styled(AntdModal)`
    .ant-modal-body {
        padding: 0;
    }
`;

const Footer = styled.div`
    padding: 15px;
    text-align: right;
`;

class ReplaceTestQuestionModal extends Component {
    state = {
        selected: []
    }

    getColumns = () => {
        return [
            {
                title: 'Вопрос',
                dataIndex: 'title',
                key: 'title',
                render: title => <StringWithoutStyles text={title} />
            }
        ];
    }

    setSelected = selected => this.props.setSelected(selected);

    onChangeOffset = (page, pageSize) => this.props.setOffset((page - 1) * pageSize);

    replaceQuestion = () => {
        const { selected, params: { company, project, test, index }, patchCompanyTestQuestions } = this.props;

        patchCompanyTestQuestions.dispatch({
            company,
            project,
            id: test,
            data: [{
                op: 'replace',
                path: `/questions/${index}`,
                value: selected[0]
            }]
        });
    }

    render() {
        const { modal, getQuestions, selected, offset, patchCompanyTestQuestions } = this.props;
        const count = pathOr(0, ['data', '_meta', 'count'], getQuestions);

        return <Modal
            {...modal}
            title='Заменить вопрос'
            footer={null}
            ModalComponent={StyledModal}>
            <Table
                columns={this.getColumns()}
                dataSource={getQuestions.data.items}
                rowKey='id'
                loading={getQuestions.meta.pending}
                rowSelection={{
                    selectedRowKeys: selected,
                    onChange: this.setSelected,
                    type: 'radio'
                }}
                pagination={count <= LINES_PER_PAGE ? false : ({
                    total: count,
                    current: (offset / LINES_PER_PAGE) + 1,
                    pageSize: LINES_PER_PAGE,
                    showSizeChanger: false,
                    onChange: this.onChangeOffset
                })}
                />
            <Footer>
                <Button
                    type='primary'
                    disabled={!selected.length}
                    loading={patchCompanyTestQuestions.meta.pending}
                    onClick={this.replaceQuestion}>
                    Заменить
                </Button>
            </Footer>
        </Modal>;
    }
}

export default withStateHandlers({
    offset: 0,
    selected: []
}, {
    setSelected: () => selected => ({ selected }),
    setOffset: () => offset => ({ offset, selected: [] })
})(
    withAsyncActions({
        getQuestions: getQuestions
            .withPayload(({ params: { categories, levels, excludeIds, questionCompany }, offset }) => ({
                offset,
                limit: LINES_PER_PAGE,
                q: {
                    categories, levels, excludeIds, company: questionCompany
                }
            }))
            .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true }),
        patchCompanyTestQuestions: patchCompanyTestQuestions
            .withSuccessHandler(({ close }) => {
                message.success('Вопрос успешно заменен');
                close();
            })
            .withErrorHandler(() => message.error('Не удалось заменить вопрос'))
            .withOptions({ resetOnUnmount: true })
    })(ReplaceTestQuestionModal)
);
