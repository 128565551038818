import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { prop } from 'ramda';

import Select from './formComponents/Select';
import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import { getDistributions } from '../../actions/asyncActions';

class QuestionsDistributionForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='questionsDistribution'
                component={Select}
                label='Распределение вопросов'
                action={getDistributions}
                namePath='description'
                filter={{
                    company: this.props.item.company
                }}
                allowClear />
            <SubmitButton type='primary'>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

export default withFormWrapper(QuestionsDistributionForm, {
    mapPropsToValues: prop('item'),
    mapBeforeSubmit: (values, props) => ({
        id: props.item.id,
        data: [{ op: 'replace', path: '/questionsDistribution', value: values.questionsDistribution }]
    })
});
