import React, { Component, Fragment } from 'react';
import { prop, pathEq } from 'ramda';
import { connect } from 'react-redux';
import * as yup from 'yup';

import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import { Field } from 'react-final-form';
import Input from './formComponents/Input';
import { getUser } from '../../actions/asyncActions';
import Select from './formComponents/Select';
import { PhoneInput } from './formComponents/MaskedInput';
import { ADMIN_ROLES } from '../../constants/admins';
import ImageUpload from './formComponents/ImageUpload';

const labelCol = {
    xs: { span: 24 },
    sm: { span: 6 },
};

const wrapperCol = {
    xs: { span: 24 },
    sm: { span: 18 },
};

class AdminForm extends Component {
    render() {
        const { user, item } = this.props;

        return <Fragment>
            <h3>Информация</h3>
            <Field
                name='lastName'
                component={Input}
                label='Фамилия'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='firstName'
                component={Input}
                label='Имя'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='middleName'
                component={Input}
                label='Отчество'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <h3>Контакты</h3>
            <Field
                name='email'
                component={Input}
                label='Email'
                htmlType='email'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='phone'
                component={PhoneInput}
                label='Телефон'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <h3>Прочее</h3>
            { pathEq(['id'], user.id, item) ?
                <Field
                    name='password'
                    component={Input}
                    label='Пароль'
                    htmlType='password'
                    wrapperCol={wrapperCol}
                    labelCol={labelCol} /> :
                <Field
                    name='role'
                    component={Select}
                    label='Роль'
                    options={ADMIN_ROLES}
                    wrapperCol={wrapperCol}
                    labelCol={labelCol} />
            }
            <Field
                name='photo'
                component={ImageUpload}
                label='Фотография'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <SubmitButton type='primary'>Сохранить</SubmitButton>
        </Fragment>;
    }
}

const validationSchema = ({ item, user }) => yup.object().shape({
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    email: yup.string().email().required(),
    ...(pathEq(['id'], user.id, item) ? {
        password: yup.string().required()
    } : {
        role: yup.string().required()
    })
});

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(
    withFormWrapper(AdminForm, {
        mapPropsToValues: prop('item'),
        validationSchema
    })
);
