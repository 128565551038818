import React, { Component } from 'react';
import styled from 'styled-components';
import { Row, Col, Button, message, Popconfirm } from 'antd';
import { asyncConnect } from 'react-async-client';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { path } from 'ramda';

import { openSurveyModal } from '../../../actions/modalActions';
import { deleteSurvey } from '../../../actions/asyncActions';

const Wrapper = styled.div`
    padding: 15px;
`;

const Field = styled.div`
    margin-bottom: 8px;
    &:last-child{
        border: 0;
    }
    strong{
        min-width: 150px;
        display: inline-block;
    }
`;

export const Title = styled.h3`
    margin: 0 15px;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Block = styled.div`
    padding: 15px 15px 30px;
`;

const TextBlock = styled.span`
    white-space: pre-wrap;
    p {
        margin-bottom: 0;
    }
`;

const RowWrapper = styled(Row)`
    padding: 0 0 15px;
    @media (max-width: 767px) {
        padding: 0;
        & > .ant-col.ant-col-sm-24{
            margin-bottom: 15px;
        }
    }
    .ant-row.ant-form-item{
        display: flex;
        align-items: center;
    }
    .ant-col-md-24,.ant-col-md-12{
        h3{
            border: 1px solid #e8e8e8;
            padding-bottom: 10px;
            padding: 10px;
            background: #fafafa;
            margin: -1px 0px;
        }
    }
    .ant-col-md-12{
        &:last-child{
            padding-left: 7px;
            @media (max-width: 767px) {
                padding-left: 0;
            }
        }
        &:first-child{
            padding: 0 7px 0 0;
        }
    }
    .label-block{
        display: block;
    }
`;

const ColBody = styled.div`
    border: 1px solid #e8e8e8;
    padding: 10px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    .collaborators .ant-form-item-control {
        width: 100%;
    }
`;

class SurveyInfoTabButtonsComponent extends Component {
    render() {
        const { getSurvey: { data }, deleteSurvey, match, openSurveyModal } = this.props;

        return <Button.Group>
            <Button icon={<EditOutlined />} type='primary' onClick={() => openSurveyModal(data)}>
                <span className='hide-mobile'>Редактировать</span>
            </Button>
            <Popconfirm
                title='Вы уверены, что хотите удалить опрос?'
                okText='Да'
                cancelText='Нет'
                placement='left'
                onConfirm={() => deleteSurvey.dispatch(match.params.id)}
                disabled={data.status === 'deleted'}
            >
                <Button
                    icon={<DeleteOutlined />}
                    type='danger'
                    disabled={data.status === 'deleted'}
                    loading={deleteSurvey.meta.pending}
                >
                    <span className='hide-mobile'>Удалить</span>
                </Button>
            </Popconfirm>
        </Button.Group>;
    }
}

export const SurveyInfoTabButtons = asyncConnect({
    deleteSurvey: deleteSurvey
        .withSuccessHandler(({ history }) => {
            message.error('Опрос удален');
            history.push('/surveys')
        })
        .withErrorHandler(() => message.error('Не удалось удалить опрос'))
        .withOptions({ resetOnUnmount: true })
}, null, { openSurveyModal })(SurveyInfoTabButtonsComponent);

export default class SurveyNameTab extends Component {
    render() {
        const { survey } = this.props;
        return <Wrapper className='wrap-bg'>
            <Field><strong>Название: </strong>{ survey.name }</Field>
            <Field>
                <strong>Опросник: </strong>
                <Link className='link' to={`/questionnaries/${survey.questionnaire}/info`}>{ path(['_embedded', 'questionnaire', 'name'], survey) || '-' }</Link>
            </Field>
            <RowWrapper className='ant-form-inline'>
                <Col md={24} sm={24}>
                    <h3 className='title-categories'>Заголовок</h3>
                    <ColBody>
                        <TextBlock dangerouslySetInnerHTML={{ __html: survey.title || '-' }} />
                    </ColBody>
                </Col>
            </RowWrapper>
            <RowWrapper className='ant-form-inline'>
                <Col md={24} sm={24}>
                    <h3 className='title-categories'>Текст приветствия</h3>
                    <ColBody>
                        <TextBlock dangerouslySetInnerHTML={{ __html: survey.welcomeText || '-' }} />
                    </ColBody>
                </Col>
            </RowWrapper>
            <RowWrapper className='ant-form-inline'>
                <Col md={24} sm={24}>
                    <h3 className='title-categories'>Шкала оценки</h3>
                    <ColBody>
                        <ul>
                            { survey.scale && !!survey.scale.length ?
                                survey.scale.map((scale, index) =>
                                    <li key={`scale-${index}`}>{scale}: {(survey.answerMap || {})[scale] || '-'}</li>
                                ) : '-'
                            }
                        </ul>
                    </ColBody>
                </Col>
            </RowWrapper>
        </Wrapper>;
    }
}
