export const POST_LOGIN = 'POST_LOGIN';
export const POST_PDA = 'POST_PDA';
export const GET_USER = 'GET_USER';

export const LOGOUT = 'LOGOUT';
export const POST_REFRESH_TOKEN = 'POST_REFRESH_TOKEN';

export const POST_PASSWORD_RECOVERY = 'POST_PASSWORD_RECOVERY';
export const POST_RESET_PASSWORD = 'POST_RESET_PASSWORD';

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_MODAL = 'SHOW_MODAL';
export const REMOVE_MODAL = 'REMOVE_MODAL';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const POST_CATEGORY = 'POST_CATEGORY';
export const PUT_CATEGORY = 'PUT_CATEGORY';
export const PATCH_CATEGORY = 'PATCH_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';

export const GET_QUESTIONS = 'GET_QUESTIONS';
export const GET_QUESTION = 'GET_QUESTION';
export const PUT_QUESTION = 'PUT_QUESTION';
export const PATCH_QUESTION = 'PATCH_QUESTION';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const POST_QUESTION = 'POST_QUESTION';

export const OPEN_SELECT_CATEGORY_MODAL = 'OPEN_SELECT_CATEGORY_MODAL';
export const OPEN_QUESTION_PREVIEW_MODAL = 'OPEN_QUESTION_PREVIEW_MODAL';
export const OPEN_ADMIN_MODAL = 'OPEN_ADMIN_MODAL';
export const OPEN_COMPANY_MODAL = 'OPEN_COMPANY_MODAL';
export const OPEN_COMPANY_DESIGN_PAGE_MODAL = 'OPEN_COMPANY_DESIGN_PAGE_MODAL';
export const OPEN_COMPANY_CLIENT_MODAL = 'OPEN_COMPANY_CLIENT_MODAL';
export const OPEN_COMPANY_EMPLOYEE_MODAL = 'OPEN_COMPANY_EMPLOYEE_MODAL';
export const OPEN_ADD_CLIENT_TO_PROJECT_MODAL = 'OPEN_ADD_CLIENT_TO_PROJECT_MODAL';
export const OPEN_COMPANY_TEMPLATE_MODAL = 'OPEN_COMPANY_TEMPLATE_MODAL';
export const OPEN_SETTINGS_TEMPLATE_MODAL = 'OPEN_SETTINGS_TEMPLATE_MODAL';
export const OPEN_SELECT_PROJECT_TYPE_MODAL = 'OPEN_SELECT_PROJECT_TYPE_MODAL';
export const OPEN_CATEGORIES_SETTINGS_INFO_MODAL = 'OPEN_CATEGORIES_SETTINGS_INFO_MODAL';
export const OPEN_PROJECT_CATEGORY_SELECT_MODAL = 'OPEN_PROJECT_CATEGORY_SELECT_MODAL';
export const OPEN_COMPLEXITY_DISTRIBUTION_SETTINGS_MODAL = 'OPEN_COMPLEXITY_DISTRIBUTION_SETTINGS_MODAL';
export const OPEN_COMPANY_RESPONDENT_MODAL = 'OPEN_COMPANY_RESPONDENTS_MODAL';
export const OPEN_RESPONDENT_LETTER_MODAL = 'OPEN_RESPONDENT_LETTER_MODAL';
export const OPEN_QUESTIONS_SETTINGS_MODAL = 'OPEN_QUESTIONS_SETTINGS_MODAL';
export const OPEN_QUESTIONS_IMPORT_MODAL = 'OPEN_QUESTIONS_IMPORT_MODAL';
export const OPEN_TEST_MODAL = 'OPEN_TEST_MODAL';
export const OPEN_PROJECT_NAME_MODAL = 'OPEN_PROJECT_NAME_MODAL';
export const OPEN_IMPORT_RESPONDENTS_MODAL = 'OPEN_IMPORT_RESPONDENTS_MODAL';
export const OPEN_COMMAND_MODAL = 'OPEN_COMMAND_MODAL';
export const OPEN_COMMAND_DEBUG_MODAL = 'OPEN_COMMAND_DEBUG_MODAL';
export const OPEN_TEST_SETTINGS_MODAL = 'OPEN_TEST_SETTINGS_MODAL';
export const OPEN_QUESTION_PAGE_MODAL = 'OPEN_QUESTION_PAGE_MODAL';
export const OPEN_QUESTION_DETAIL_MODAL = 'OPEN_QUESTION_DETAIL_MODAL';
export const OPEN_EXPERT_MODAL = 'OPEN_EXPERT_MODAL';
export const OPEN_RESPONDENT_ANALYTICS_MODAL = 'OPEN_RESPONDENT_ANALYTICS_MODAL';
export const OPEN_TEST_TEMPLATE_MODAL = 'OPEN_TEST_TEMPATE_MODAL';
export const OPEN_PDF_MODAL = 'OPEN_PDF_MODAL';
export const OPEN_PROJECT_QUESTIONS_SETTINGS_MODAL = 'OPEN_PROJECT_QUESTIONS_SETTINGS_MODAL';
export const OPEN_PAYMENT_MODAL = 'OPEN_PAYMENT_MODAL';
export const OPEN_COMPANY_PAYMENT_TYPE_MODAL = 'OPEN_COMPANY_PAYMENT_TYPE_MODAL';
export const OPEN_TEST_TEMPLATE_RESPONDENT_MESSAGES_MODAL = 'OPEN_TEST_TEMPLATE_RESPONDENT_MESSAGES_MODAL';
export const OPEN_TEST_TEMPLATE_AUDIT_MESSAGES_MODAL = 'OPEN_TEST_TEMPLATE_AUDIT_MESSAGES_MODAL';
export const OPEN_TEST_RESPONDENT_MODAL = 'OPEN_TEST_RESPONDENT_MODAL';
export const OPEN_TEST_AUDIT_MODAL = 'OPEN_TEST_AUDIT_MODAL';
export const OPEN_RESPONDENT_FORM_FIELDS_MODAL = 'OPEN_RESPONDENT_FORM_FIELDS_MODAL';
export const OPEN_COMPANY_BANK_DETAILS_MODAL = 'OPEN_COMPANY_BANK_DETAILS_MODAL';
export const OPEN_PAYMENT_BANK_DETAILS_MODAL = 'OPEN_PAYMENT_BANK_DETAILS_MODAL';
export const OPEN_ADD_RESPONDENT_MODAL = 'OPEN_ADD_RESPONDENT_MODAL';
export const OPEN_PROFILE_MODAL = 'OPEN_PROFILE_MODAL';
export const OPEN_QUESTION_MODAL = 'OPEN_QUESTION_MODAL';
export const OPEN_REPLACE_TEST_QUESTION_MODAL = 'OPEN_REPLACE_TEST_QUESTION_MODAL';
export const OPEN_TEST_GROUPS_MODAL = 'OPEN_TEST_GROUPS_MODAL';
export const OPEN_DISTRIBUTION_MODAL = 'OPEN_DISTRIBUTION_MODAL';
export const OPEN_QUESTIONS_DISTRIBUTION_MODAL = 'OPEN_QUESTIONS_DISTRIBUTION_MODAL';
export const OPEN_CATEGORY_RECOMMENDATION_MODAL = 'OPEN_CATEGORY_RECOMMENDATION_MODAL';
export const OPEN_CATEGORY_ERROR_MODAL = 'OPEN_CATEGORY_ERROR_MODAL';
export const OPEN_COMPANY_INTEGRAL_RESULT_SCALE_MODAL = 'OPEN_COMPANY_INTEGRAL_RESULT_SCALE_MODAL';
export const OPEN_EXTERNAL_CONTRACTOR_MODAL = 'OPEN_EXTERNAL_CONTRACTOR_MODAL';
export const OPEN_ACCEPTOR_MODAL = 'OPEN_ACCEPTOR_MODAL';
export const OPEN_PAYMENT_MESSAGE_HISTORY_MODAL = 'OPEN_PAYMENT_MESSAGE_HISTORY_MODAL';
export const OPEN_QUESTIONNARY_MODAL = 'OPEN_QUESTIONNARY_MODAL';
export const OPEN_DRIVER_MODAL = 'OPEN_DRIVER_MODAL';
export const OPEN_DRIVER_SETTINGS_MODAL = 'OPEN_DRIVER_SETTINGS_MODAL';
export const OPEN_IMPORT_QUESTIONS_MODAL = 'OPEN_IMPORT_QUESTIONS_MODAL';
export const OPEN_SURVEY_MODAL = 'OPEN_SURVEY_MODAL';
export const OPEN_IMPORT_SURVEY_MODAL = 'OPEN_IMPORT_SURVEY_MODAL';
export const OPEN_SURVEY_PAGE_ELEMENTS_MODAL = 'OPEN_SURVEY_PAGE_ELEMENTS_MODAL';
export const OPEN_GROUP_MODAL = 'OPEN_GROUP_MODAL';
export const OPEN_GROUP_VARIABLES_MODAL = 'OPEN_GROUP_VARIABLES_MODAL';
export const OPEN_SURVEY_RESPONDENT_LETTER_MODAL = 'OPEN_SURVEY_RESPONDENT_LETTER_MODAL';
export const OPEN_ADD_SURVEY_RESPONDENT_MODAL = 'OPEN_ADD_SURVEY_RESPONDENT_MODAL';
export const OPEN_SURVEY_RESPONDENT_MODAL = 'OPEN_SURVEY_RESPONDENT_MODAL';
export const OPEN_SURVEY_RESPONSE_FIELD_FORM_MODAL = 'OPEN_SURVEY_RESPONSE_FIELD_FORM_MODAL';
export const OPEN_LANGUAGES_SELECTOR_MODAL = 'OPEN_LANGUAGES_SELECTOR_MODAL';
export const OPEN_QUESTIONNARY_PAGE_ELEMENTS_MODAL = 'OPEN_QUESTIONNARY_PAGE_ELEMENTS_MODAL';
export const OPEN_SURVEY_PREVIEW_MODAL = 'OPEN_SURVEY_PREVIEW_MODAL';
export const OPEN_EMPLOYEES_IMPORT_MODAL = 'OPEN_EMPLOYEES_IMPORT_MODAL';
export const OPEN_RESPONDENT_REPORTS_LETTER_MODAL = 'OPEN_RESPONDENT_REPORTS_LETTER_MODAL';

export const GET_ADMINS = 'GET_ADMINS';
export const POST_ADMIN = 'POST_ADMIN';
export const PUT_ADMIN = 'PUT_ADMIN';
export const PATCH_ADMINS = 'PATCH_ADMINS';

export const GET_COMPANIES = 'GET_COMPANIES';
export const PATCH_COMPANIES = 'PATCH_COMPANIES';
export const PATCH_COMPANY = 'PATCH_COMPANY';
export const POST_COMPANY = 'POST_COMPANY';
export const GET_COMPANY = 'GET_COMPANY';
export const PUT_COMPANY = 'PUT_COMPANY';
export const PUT_COMPANY_FAVORITE = 'PUT_COMPANY_FAVORITE';

export const POST_BANK_DETAILS = 'POST_BANK_DETAILS';
export const GET_BANK_DETAILS = 'GET_BANK_DETAILS';

export const GET_COMPANY_CLIENTS = 'GET_COMPANY_CLIENTS';
export const PATCH_CLIENTS = 'PATCH_CLIENTS';
export const PUT_CLIENT = 'PUT_CLIENT';
export const POST_CLIENT = 'POST_CLIENT';

export const GET_COMPANY_EMPLOYEES = 'GET_COMPANY_EMPLOYEES';
export const DELETE_COMPANY_EMPLOYEES = 'DELETE_COMPANY_EMPLOYEES';
export const POST_COMPANY_EMPLOYEE = 'POST_COMPANY_EMPLOYEE';
export const PUT_COMPANY_EMPLOYEE = 'PUT_COMPANY_EMPLOYEE';
export const GET_COMPANY_EMPLOYEE = 'GET_COMPANY_EMPLOYEE';

export const GET_COMPANY_PROJECTS = 'GET_COMPANY_PROJECTS';
export const PATCH_COMPANY_PROJECTS = 'PATCH_COMPANY_PROJECTS';
export const POST_COMPANY_PROJECT = 'POST_COMPANY_PROJECT';
export const PUT_COMPANY_PROJECT = 'PUT_COMPANY_PROJECT';
export const GET_COMPANY_PROJECT = 'GET_COMPANY_PROJECT';

export const GET_BUSINESS_UNITS = 'GET_BUSINESS_UNITS';

export const GET_SETTINGS_TEMPLATES = 'GET_SETTINGS_TEMPLATES';
export const POST_SETTINGS_TEMPLATE = 'POST_SETTINGS_TEMPLATE';
export const PUT_SETTINGS_TEMPLATE = 'PUT_SETTINGS_TEMPLATE';
export const DELETE_SETTINGS_TEMPLATE = 'DELETE_SETTINGS_TEMPLATE';

export const GET_COMPANY_TEMPLATES = 'GET_COMPANY_TEMPLATES';
export const POST_COMPANY_TEMPLATE = 'POST_COMPANY_TEMPLATE';
export const PUT_COMPANY_TEMPLATE = 'PUT_COMPANY_TEMPLATE';
export const DELETE_COMPANY_TEMPLATE = 'DELETE_COMPANY_TEMPLATE';

export const GET_SEARCH = 'GET_SEARCH';

export const POST_COMPANY_TEST = 'POST_COMPANY_TEST';
export const PUT_COMPANY_TEST = 'PUT_COMPANY_TEST';
export const PUT_COMPANY_TESTS = 'PUT_COMPANY_TESTS';
export const DELETE_COMPANY_TEST = 'DELETE_COMPANY_TEST';
export const GET_COMPANY_TESTS = 'GET_COMPANY_TESTS';
export const PATCH_COMPANY_TEST = 'PATCH_COMPANY_TEST';

export const POST_COMPANY_RESPONDENT = 'POST_COMPANY_RESPONDENT';
export const DELETE_COMPANY_RESPONDENT = 'DELETE_COMPANY_RESPONDENT';
export const DELETE_COMPANY_RESPONDENTS = 'DELETE_COMPANY_RESPONDENTS';
export const PUT_COMPANY_RESPONDENT = 'PUT_COMPANY_RESPONDENT';
export const GET_COMPANY_RESPONDENT = 'GET_COMPANY_RESPONDENT';
export const GET_COMPANY_RESPONDENT_PDF = 'GET_COMPANY_RESPONDENT_PDF';
export const GET_COMPANY_RESPONDENTS_IMPORT = 'GET_COMPANY_RESPONDENTS_IMPORT';
export const POST_COMPANY_RESPONDENTS_IMPORT = 'POST_COMPANY_RESPONDENTS_IMPORT';
export const PATCH_COMPANY_RESPONDENT = 'PATCH_COMPANY_RESPONDENT';
export const PATCH_COMPANY_RESPONDENTS = 'PATCH_COMPANY_RESPONDENTS';
export const POST_COMPANY_RESPONDENTS = 'POST_COMPANY_RESPONDENTS';

export const POST_COMPANY_MESSAGES = 'POST_COMPANY_MESSAGES';

export const GET_COMPLEXITY = 'GET_COMPLEXITY';
export const PUT_COMPLEXITY = 'PUT_COMPLEXITY';

export const QUESTION_IMPORT_SUCCESS = 'QUESTION_IMPORT_SUCCESS';
export const EMPLOYEES_IMPORT_SUCCESS = 'EMPLOYEES_IMPORT_SUCCESS';

export const GET_RESPONDENTS = 'GET_RESPONDENTS';

export const GET_PROJECT_ANALITYCS_PERCENTS = 'GET_PROJECT_ANALYTICS_PERCENTS';
export const GET_PROJECT_ANALYTICS_DISTRIBUTION = 'GET_PROJECT_ANALYTICS_DISTRIBUTION';
export const GET_PROJECT_ANALYTICS_STATUS = 'GET_PROJECT_ANALYTICS_STATUS';

export const GET_COMMANDS = 'GET_COMMANDS';
export const POST_COMMAND = 'POST_COMMAND';
export const CANCEL_COMMAND = 'CANCEL_COMMAND';

export const GET_COMPANY_RESPONDENT_STAT = 'GET_COMPANY_RESPONDENT_STAT';

export const GET_TEST_TEMPLATES = 'GET_TEST_TEMPLATES';
export const GET_TEST_TEMPLATE = 'GET_TEST_TEMPLATE';
export const DELETE_TEST_TEMPLATES = 'DELETE_TEST_TEMPLATES';
export const POST_TEST_TEMPLATE = 'POST_TEST_TEMPLATE';
export const POST_TEST_TEMPLATE_WITH_ID = 'POST_TEST_TEMPLATE_WITH_ID';
export const PATCH_TEST_TEMPLATE = 'PATCH_TEST_TEMPLATE';
export const DELETE_TEST_TEMPLATE = 'DELETE_TEST_TEMPLATE';

export const PUT_COMPANY_TEST_STATUS = 'PUT_COMPANY_TEST_STATUS';

export const GET_EXPERTS = 'GET_EXPERTS';
export const GET_EXPERT = 'GET_EXPERT';
export const GET_EXPERT_AUDITS = 'GET_EXPERT_AUDITS';
export const POST_EXPERT = 'POST_EXPERT';
export const PUT_EXPERT = 'PUT_EXPERT';

export const PUT_COMPANY_PROJECT_TIME_SETTINGS = 'PUT_COMPANY_PROJECT_TIME_SETTINGS';

export const GET_TEST_GROUPS = 'GET_TEST_GROUPS';
export const POST_TEST_GROUP = 'POST_TEST_GROUP';
export const PUT_TEST_GROUP = 'PUT_TEST_GROUP';
export const DELETE_TEST_GROUP = 'DELETE_TEST_GROUP';

export const GET_TEST_TEMPLATE_CHANGES = 'GET_TEST_TEMPLATE_CHANGES';

export const GET_TEST_TEMPLATE_VERSIONS = 'GET_TEST_TEMPLATE_VERSIONS';

export const GET_PAYMENTS = 'GET_PAYMENTS';
export const PUT_PAYMENT = 'PUT_PAYMENT';
export const DELETE_PAYMENT = 'DELETE_PAYMENT';
export const PUT_PAYMENT_BANK_DETAILS = 'PUT_PAYMENT_BANK_DETAILS';

export const GET_COMPANY_PAYMENTS = 'GET_COMPANY_PAYMENTS';
export const POST_COMPANY_PAYMENT = 'POST_COMPANY_PAYMENT';
export const DELETE_COMPANY_PAYMENT = 'DELETE_COMPANY_PAYMENT';

export const GET_COMPANY_ACCOUNT_OPERATIONS = 'GET_COMPANY_ACCOUNT_OPERATIONS';

export const GET_RESPONDENTS_SCREENING = 'GET_RESPONDENTS_SCREENING';
export const DELETE_RESPONDENTS_SCREENING = 'DELETE_RESPONDENTS_SCREENING';
export const POST_RESPONDENTS_SCREENING = 'POST_RESPONDENTS_SCREENING';
export const GET_RESPONDENT_SCREENING = 'GET_RESPONDENT_SCREENING';
export const PUT_RESPONDENT_SCREENING = 'PUST_RESPONDENT_SCREENING';
export const DELETE_RESPONDENT_SCREENING = 'DELETE_RESPONDENT_SCREENING';

export const POST_TEST_TEMPLATE_RESPONDENT_MESSAGES = 'POST_TEST_TEMPLATE_RESPONDENT_MESSAGES';

export const POST_TEST_TEMPLATE_AUDIT_MESSAGES = 'POST_TEST_TEMPLATE_AUDIT_MESSAGES';

export const GET_TEST_TEMPLATE_AUDITS = 'GET_TEST_TEMPLATE_AUDITS';
export const GET_TEST_TEMPLATE_AUDIT = 'GET_TEST_TEMPLATE_AUDIT';
export const POST_TEST_TEMPLATE_AUDIT = 'POST_TEST_TEMPLATE_AUDIT';
export const DELETE_TEST_TEMPLATE_AUDITS = 'DELETE_TEST_TEMPLATE_AUDITS';
export const DELETE_TEST_TEMPLATE_AUDIT = 'DELETE_TEST_TEMPLATE_AUDIT';

export const GET_PROJECT_STAT_COMPETENCIES = 'GET_PROJECT_STAT_COMPETENCIES';
export const GET_PROJECT_STAT_INTEGRAL = 'GET_PROJECT_STAT_INTEGRAL';

export const POST_COMPANY_TEST_RESTART = 'POST_COMPANY_TEST_RESTART';
export const POST_COMPANY_TESTS_RESTART = 'POST_COMPANY_TESTS_RESTART';
export const POST_COMPANY_TEST_FINISH = 'POST_COMPANY_TEST_FINISH';

export const PUT_COMPANY_PAYMENT_TYPE = 'PUT_COMPANY_PAYMENT_TYPE';
export const PUT_COMPANY_ENABLED = 'PUT_COMPANY_ENABLED';
export const PUT_COMPANIES_ENABLED = 'PUT_COMPANIES_ENABLED';

export const GET_PROJECTS = 'GET_PROJECTS';

export const GET_COMPANY_TEST_QUESTIONS = 'GET_COMPANY_TEST_QUESTIONS';
export const PUT_COMPANY_TEST_QUESTIONS = 'PUT_COMPANY_TEST_QUESTIONS';
export const PATCH_COMPANY_TEST_QUESTIONS = 'PATCH_COMPANY_TEST_QUESTIONS';

export const GET_DISTRIBUTIONS = 'GET_DISTRIBUTIONS';
export const GET_DISTRIBUTION = 'GET_DISTRIBUTION';
export const POST_DISTRIBUTION = 'POST_DISTRIBUTION';
export const PUT_DISTRIBUTION = 'PUT_DISTRIBUTION';
export const DELETE_DISTRIBUTION = 'DELETE_DISTRIBUTION';
export const GET_DEFAULT_DISTRIBUTION = 'GET_DEFAULT_DISTRIBUTION';

export const GET_COMPANY_CATEGORY_DISTRIBUTION = 'GET_COMPANY_CATEGORY_DISTRIBUTION';
export const GET_CATEGORY_DISTRIBUTION = 'GET_CATEGORY_DISTRIBUTION';
export const PUT_CATEGORY_RECOMMENDATIONS = 'PUT_CATEGORY_RECOMMENDATIONS';
export const GET_CATEGORY_RECOMMENDATIONS = 'GET_CATEGORY_RECOMMENDATIONS';

export const GET_EXTERNAL_CONTRACTOR = 'GET_EXTERNAL_CONTRACTOR';

export const POST_ENSI_CONTRACTS = 'POST_ENSI_CONTRACTS';

export const GET_SETTINGS = 'GET_SETTINGS';
export const PUT_SETTINGS = 'PUT_SETTINGS';

export const GET_ACCEPTORS = 'GET_ACCEPTORS';
export const POST_ACCEPTOR = 'POST_ACCEPTOR';
export const PUT_ACCEPTOR = 'PUT_ACCEPTOR';
export const DELETE_ACCEPTOR = 'DELETE_ACCEPTOR';

export const GET_MESSAGE_HISTORY_CONTRACTOR = 'GET_MESSAGE_HISTORY_CONTRACTOR';
export const GET_MESSAGE_HISTORY_PAYMENT = 'GET_MESSAGE_HISTORY_PAYMENT';

export const GET_QUESTIONNARIES = 'GET_QUESTIONARIES';
export const GET_QUESTIONNARIES_REPORT_TYPES = 'GET_QUESTIONNARIES_REPORT_TYPES';
export const POST_QUESTIONNARY = 'POST_QUESTIONNARY';
export const GET_QUESTIONNARY = 'GET_QUESTIONNARY';
export const PUT_QUESTIONNARY = 'PUT_QUESTIONNARY';
export const DELETE_QUESTIONNARY = 'DELETE_QUESTIONNARY';
export const PATCH_QUESTIONNARY = 'PATCH_QUESTIONNARY';

export const GET_SURVEYS = 'GET_SURVEYS';
export const GET_COMPANY_SURVEYS = 'GET_COMPANY_SURVEYS';
export const PATCH_COMPANY_SURVEYS = 'PATCH_COMPANY_SURVEYS';
export const POST_SURVEY = 'POST_SURVEY';
export const PUT_SURVEY = 'PUT_SURVEY';
export const PATCH_SURVEY = 'PATCH_SURVEY';
export const DELETE_SURVEY = 'DELETE_SURVEY';
export const GET_SURVEY = 'GET_SURVEY';
export const GET_SURVEY_RESPONDENT = 'GET_SURVEY_RESPONDENT';
export const GET_SURVEY_RESPONDENT_CUSTOMER_FOCUS = 'GET_SURVEY_RESPONDENT_CUSTOMER_FOCUS';
export const GET_SURVEY_RESPONDENTS = 'GET_SURVEY_RESPONDENTS';
export const POST_SURVEY_RESPONDENT = 'POST_SURVEY_RESPONDENT';
export const POST_SURVEY_RESPONDENTS = 'POST_SURVEY_RESPONDENTS';
export const PATCH_SURVEY_RESPONDENT = 'PATCH_SURVEY_RESPONDENT';
export const PATCH_SURVEY_RESPONDENTS = 'PATCH_SURVEY_RESPONDENTS';
export const PUT_SURVEY_RESPONDENT = 'PUT_SURVEY_RESPONDENT';
export const DELETE_SURVEY_RESPONDENT = 'DELETE_SURVEY_RESPONDENT';
export const DELETE_SURVEY_RESPONDENTS = 'DELETE_SURVEY_RESPONDENTS';
export const POST_SURVEY_COMPANY_MESSAGES = 'POST_SURVEY_COMPANY_MESSAGES';
export const GET_SURVEY_RESPONDENT_DRIVER_PLUS = 'GET_SURVEY_RESPONDENT_DRIVER_PLUS';
export const GET_SURVEY_RESPONDENT_DRIVER_PLUS_IPSATIVE = 'GET_SURVEY_RESPONDENT_DRIVER_PLUS_IPSATIVE';

export const PUT_COMPANY_SETTINGS = 'PUT_COMPANY_SETTINGS';

export const DELETE_PROJECT = 'DELETE_PROJECT';
export const DELETE_COMPANY_PROJECT = 'DELETE_COMPANY_PROJECT';
export const DELETE_PROJECTS = 'DELETE_PROJECTS';
export const DELETE_COMPANY_PROJECTS = 'DELETE_COMPANY_PROJECTS';

export const GET_RESPONDENT_REPORT = 'GET_RESPONDENT_REPORT';
