import React, { Component } from 'react';
import styled from 'styled-components';
import { path } from 'ramda';
import { Button, message, Popconfirm } from 'antd';
import { asyncConnect } from 'react-async-client';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import { openTestTemplateModal } from '../../../actions/modalActions';
import { deleteTestTemplate } from '../../../actions/asyncActions';

const Wrapper = styled.div`
    padding: 15px;
`;

const Field = styled.div`
    margin-bottom: 5px;
`;

class TestInfoTabButtonsComponent extends Component {
    edit = () => {
        const { getTestTemplate: { data }, openTestTemplateModal } = this.props;

        openTestTemplateModal(data);
    }

    delete = () => {
        const { deleteTestTemplate, match: { params: { id }}} = this.props;

        deleteTestTemplate.dispatch(id);
    }

    render() {
        return <Button.Group>
            <Button
                type='primary'
                icon={<EditOutlined />}
                onClick={this.edit}>
                <span className='hide-mobile'>Редактировать</span>
            </Button>
            <Popconfirm
                title='Вы уверены, что хотите удалить тест?'
                onConfirm={this.delete}>
                <Button
                    type='danger'
                    icon={<DeleteOutlined />}>
                    <span className='hide-mobile'>Удалить</span>
                </Button>
            </Popconfirm>
        </Button.Group>
    }
}

export const TestInfoTabButtons = asyncConnect({
    deleteTestTemplate: deleteTestTemplate
        .withSuccessHandler(({ history }) => {
            message.success('Тест был успешно удален');
            history.goBack();
        })
        .withErrorHandler(() => message.error('Не удалось удалить тест'))
        .withOptions({ resetonUnmount: true })
}, null, { openTestTemplateModal })(TestInfoTabButtonsComponent);

export default class TestInfoTab extends Component {
    renderField = (label, value) => {
        return value &&
            <Field>
                <strong>{ label }:</strong> { value }
            </Field>;
    }

    render() {
        const { test } = this.props;

        return <Wrapper className='wrap-bg'>
            { this.renderField('Название', test.name) }
            { this.renderField('Функция', test.industry) }
            { this.renderField('Группа', path(['_embedded', 'testGroup', 'name'], test)) }
        </Wrapper>;
    }
}
