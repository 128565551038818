import React, { Component, Fragment } from 'react';
import { asyncConnect } from 'react-async-client';
import { Badge, Tag, Menu, Button, Dropdown, message, Switch, Tooltip, Popconfirm } from 'antd';
import { pathOr, equals, contains, find, propEq, any } from 'ramda';
import styled from 'styled-components';
import { withState } from 'recompose';
import { Link } from 'react-router-dom';
import { StepForwardOutlined, PlusOutlined, EditOutlined, SnippetsOutlined, FileOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons';

import { patchCompanyProjects, getUser, getProjects, deleteProjects } from '../../../actions/asyncActions';
import { openSelectProjectTypeModal } from '../../../actions/modalActions';
import { getUrlParams } from '../../../utils/urlParams';
import TableList from '../table/TableList';
import DateFormat from '../table/DateFormat';
import ProjectsFilter from '../../forms/filters/ProjectsFilter';
import { COMPANY_PROJECT_STATUSES } from '../../../constants/companies';
import { DELETE_PROJECTS, PATCH_COMPANY_PROJECTS } from '../../../constants/actionTypes';
import CompanySelector from './CompanySelector';
import { MANAGERS, SENIOR_MANAGER } from '../../../constants/roles';

const Wrapper = styled.div`
    display: flex;
    align-items: baseline;
    .ant-badge-count {
        background: #1890ff;
    }
    .link {
      font-weight: bold;
    }
`;

const ProjectsWrapper = styled.div`
    width: ${({ isManager }) => isManager ? 100 : 70}%;
    background: #fff;
    margin-left: ${({ isManager }) => isManager ? 0 : 30}px;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    div > .ant-row{
        border-radius: 0;
        border: 0;
        padding: 10px 15px;
        .ant-row{
            padding: 0;
        }
    }
    div .ant-table-wrapper{
        border: 0;
    }
    @media (max-width: 767px) {
        margin-left: 10px;
        .ant-btn {
            span:not(.anticon) {
              display: none;
            }
        }
    }
`;

const ProjectsHeader = styled.h1`
    background: #fafafa;
    border-bottom: 1px solid #eaeaea;
    padding: 6px 15px;
    min-height: 45px;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > div{
        width: 100%;
        position: relative;
    }
    .ant-btn {
      @media (max-width: 767px) {
        span:not(.anticon) {
          display: none;
        }
      }
    }
`;

const BoxStatus = styled.span`
    margin-bottom: 5px;
`;

const projectTypes = {
    custom: {
        title: 'Ручной режим',
        icon: <EditOutlined />
    },
    predefined: {
        title: 'Выбор предустановленного профиля должности',
        icon: <SnippetsOutlined />
    },
    competenceCustom: {
        title: 'Создание нового профиля должности',
        icon: <FileOutlined />
    }
};

class Projects extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showMyProjects: props.isClientManager ? JSON.parse(localStorage.getItem('showMyProjects')) : props.isManager
        };
    }

    componentDidUpdate(prev) {
        if (!equals(getUrlParams(this.props.location), getUrlParams(prev.location))) {
            this.props.setSelected([]);
        }
    }

    getColumns = () => {
        return [
            {
                title: 'Название',
                key: 'name',
                dataIndex: 'name',
                sorter: true,
                render: (name, { id, company }) => <Link className='link' to={`/company/${company}/projects/${id}/respondents`}>{ name }</Link>
            },
            {
                key: 'statuses',
                dataIndex: 'status',
                filters: COMPANY_PROJECT_STATUSES.map(i => ({ text: i.value, value: i.id })),
                className: 'right-column',
                render: (status, project) => {
                    const type = project.type === 'competence' ? (project.profileType === 'predefined' ? 'predefined' : 'competenceCustom') : 'custom';
                    const data = projectTypes[type];
                    const icon = <Tooltip title={data.title} placement='left'>{ data.icon }</Tooltip>;

                    return <Fragment>
                        <BoxStatus>
                            { status === 'active' ?
                                <Tag color='green'>{ icon } Активен</Tag> : status === 'archived' ?
                                <Tag color='orange'>{ icon } В архиве</Tag> : <Tag color='red'>{ icon } Скрытый</Tag> }
                        </BoxStatus>
                        <div><DateFormat date={project.createdAt} /></div>
                    </Fragment>
                }
            }
        ]
    }

    setSelected = selected => this.props.setSelected(selected);

    changeStatus = value => {
        const { patchCompanyProjects, selected } = this.props;

        patchCompanyProjects.dispatch({
            items: selected.map(id => find(propEq('id', id), this.props.projects)),
            data: [{ op: 'replace', path: '/status', value }]
        });
    }

    renderChangeStatusMenu = () => {
        return <Menu>
            { COMPANY_PROJECT_STATUSES.map(status =>
                <Menu.Item key={status.id} onClick={() => this.changeStatus(status.id)}>
                    { status.single }
                </Menu.Item>
            )}
        </Menu>;
    }

    deleteProjects = () => {
        this.props.deleteProjects.dispatch(this.props.selected);
    }

    hasNoDeleteable = () => {
        return any(project => !project.deletable, this.props.selected.map(id => find(propEq('id', id), this.props.getProjects.data.items || [])));
    }

    renderButtons = () => {
        const disableDelete = !this.props.selected.length || this.hasNoDeleteable() || this.props.deleteProjects.meta.pending;

        return <Button.Group>
            <Dropdown
                overlay={this.renderChangeStatusMenu()}
                disabled={!this.props.selected.length}
                trigger={['click']}>
                <Button icon={<StepForwardOutlined />}>
                    Изменить статус
                </Button>
            </Dropdown>
            <Popconfirm
                title='Вы уверены, что хотите удалить тесты?'
                okText='Да'
                cancelText='Нет'
                placement='left'
                onConfirm={this.deleteProjects}
                disabled={disableDelete}
            >
                <Button
                    danger
                    icon={this.props.deleteProjects.meta.pending ? <LoadingOutlined /> : <DeleteOutlined />}
                    disabled={disableDelete}>
                    Удалить
                </Button>
            </Popconfirm>
        </Button.Group>
    }

    getSelectedCompany = () => {
        return this.props.isManager ? this.props.user.company : getUrlParams(this.props.location).company;
    }

    onChangeAll = all => {
        const my = !all;

        localStorage.setItem('showMyProjects', my);
        this.setState({ showMyProjects: my });
    }

    renderProjects = () => {
        const { isManager, projectsCount, selected, openSelectProjectTypeModal, user, isClientManager } = this.props;
        const company = this.getSelectedCompany();

        return <ProjectsWrapper isManager={isManager} className='project-list'>
            <ProjectsHeader>
                <div>
                    Тесты <Badge count={projectsCount} style={{ backgroundColor: '#f54d2e', marginRight: 10 }} />
                    { isClientManager &&
                        <span>
                            <Switch
                                checkedChildren='все'
                                unCheckedChildren='мои'
                                checked={!this.state.showMyProjects && isManager}
                                onChange={this.onChangeAll} />
                        </span>
                    }
                </div>
                <Button
                    type='primary'
                    icon={<PlusOutlined />}
                    disabled={!company}
                    onClick={() => openSelectProjectTypeModal(company)}>
                    <span className='hide-mobile'>Добавить</span>
                </Button>
            </ProjectsHeader>
            <TableList
                payload={{ company, collaborators: isManager && this.state.showMyProjects ? [user.id] : null }}
                action={getProjects}
                columns={this.getColumns()}
                filterForm={ProjectsFilter}
                staticFilter={{ company }}
                initFilters={{ statuses: ['active'] }}
                defaultSort={{
                    sort_by: 'lastActivityDate',
                    sort_order: 'desc'
                }}
                rowSelection={{
                    selectedRowKeys: selected,
                    onChange: this.setSelected,
                    type: 'checkbox'
                }}
                buttons={this.renderButtons()}
                refreshActions={[PATCH_COMPANY_PROJECTS, DELETE_PROJECTS]} />
        </ProjectsWrapper>
    }

    render() {
        return <Wrapper>
            { !this.props.isManager && <CompanySelector /> }
            { this.renderProjects() }
        </Wrapper>;
    }
}

const stateToProps = state => {
    const role = getUser.selectData(state).role;

    return {
        projectsCount: pathOr(0, ['_meta', 'count'], getProjects.selectData(state)),
        isManager: contains(getUser.selectData(state).role, MANAGERS),
        isClientManager: role === SENIOR_MANAGER,
        user: getUser.selectData(state),
        projects: pathOr([], ['items'], getProjects.selectData(state))
    };
};

export default withState('selected', 'setSelected', [])(
    asyncConnect({
        getProjects,
        deleteProjects: deleteProjects
            .withSuccessHandler(({ setSelected }) => {
                message.success('Тесты успешно удалены');
                setSelected([]);
            })
            .withErrorHandler(() => message.error('Не удалось удалить тесты'))
            .withOptions({ resetOonUnmount: true }),
        patchCompanyProjects: patchCompanyProjects
            .withSuccessHandler(({ setSelected }) => {
                message.success('Статус успешно обновлен');
                setSelected([]);
            })
            .withErrorHandler(() => message.error('Не удалось изменить статус'))
            .withOptions({ resetOnUnmount: true })
    }, stateToProps, { openSelectProjectTypeModal })(Projects)
);
