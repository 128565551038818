import React, { Component, Fragment } from 'react';
import { Spin, Tag, Col, Row } from 'antd';
import { find, propEq, path, pathOr } from 'ramda';
import { SolutionOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';

import { PROJECT_RESPONDENT_STATUSES } from '../../../constants/companies';
import { styledComponents } from '../projects/ProjectRespondent';
import TestSolutionInfo from './TestSolutionInfo';
import AnswersResult from './AnswersResult';

const {
    Wrapper,
    Field,
    OtherInfo,
    Name,
    ColStatus,
} = styledComponents;

export default class Audit extends Component {
    render() {
        const { getTestTemplateAudit: { data, meta }, testTemplate } = this.props;
        const businessUnit = path(['_embedded', 'employee', '_embedded', 'businessUnit', 'name'], data);
        const expert = pathOr({}, ['_embedded', 'expert'], data);
        const comments = pathOr([], ['_embedded', 'comments'], data);
        const answers = pathOr([], ['_embedded', 'answers'], data);

        const respondentStatus = find(propEq('id', data.status), PROJECT_RESPONDENT_STATUSES);

        return meta.pending && !meta.lastSucceedAt ?
            <Spin/> :
            <Fragment>
                <Wrapper>
                    <Row>
                        <Col span={10}>
                            <Name>
                                <Field>{expert.lastName} {expert.firstName} {expert.middleName}</Field>
                            </Name>
                            <OtherInfo>
                                {businessUnit && <Field><SolutionOutlined />{businessUnit}</Field>}
                            </OtherInfo>
                        </Col>
                        <Col span={8}>
                            <OtherInfo>
                                <Field><MailOutlined /> {expert.email}</Field>
                                {expert.phone && <Field><PhoneOutlined /> {expert.phone}</Field>}
                            </OtherInfo>
                        </Col>
                        <ColStatus span={6}>
                            <Tag color={path(['color'], respondentStatus)}>
                                <Field>{path(['value'], respondentStatus)}</Field>
                            </Tag>
                        </ColStatus>
                    </Row>
                </Wrapper>
                <TestSolutionInfo data={data} testTemplate={testTemplate} />
                { !!answers.length &&
                    <AnswersResult test={data} answers={answers} comments={comments} audit />
                }
            </Fragment>;
    }
}
