import React, { Component, Fragment } from 'react';
import { asyncConnect, toSuccess } from 'react-async-client';
import { Spin, Tabs } from 'antd';
import styled from 'styled-components';
import { takeEvery } from 'redux-saga/effects';
import { path, find, propEq, filter, contains } from 'ramda';

import Route from '../../Route';
import { getCompanyProject, getUser, getCompanyTests } from '../../../actions/asyncActions';
import DetailToolbar from '../DetailToolbar';
import ProjectNameTab, { ProjectNameTabButtons } from './ProjectNameTab';
import ProjectCategoriesTab, { ProjectCategoriesTabButtons } from './ProjectCategoriesTab';
import { PUT_COMPANY_PROJECT, PUT_COMPANY_TESTS, PATCH_COMPANY_PROJECTS, POST_COMPANY_TEST, PUT_COMPANY_TEST, DELETE_COMPANY_TEST, PATCH_COMPANY_TEST, PUT_COMPANY_TEST_STATUS, PUT_COMPANY_PROJECT_TIME_SETTINGS, PUT_QUESTION } from '../../../constants/actionTypes';
import SubmitButton from '../../forms/formComponents/SubmitButton';
import ProjectRespondentsTab, { ProjectRespondentsTabButtons } from './ProjectRespondentsTab';
import ProjectAnalyticsTab from './ProjectAnalyticsTab';
import withErrorHandler from '../../hocs/withErrorHandler';
import ProjectTestsTab, { ProjectTestsTabButtons } from './ProjectTestsTab';
import ProjectSettingsTab from './ProjectSettingsTab';
import ProjectRespondent, { ProjectRespondentButtons } from './ProjectRespondent';
import ProjectSequenceTab from './ProjectSequenceTab';
import ProjectAnalyticsTabButtons from './ProjectAnalyticsTabButtons';
import { ADMIN } from '../../../constants/roles';
import ProjectExpertsTab, { ProjectExpertsTabButtons } from './ProjectExpertsTab';

const StyledTabs = styled(Tabs)`
    .ant-tabs-bar {
        margin: 0;
    }
`;

const ToolbarTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: calc(100% - 32px);
    h1 {
        padding-right: 10px;
    }
`;

const tabs = [
    { key: 'name', title: 'Название', component: ProjectNameTab, buttons: ProjectNameTabButtons },
    { key: 'tests', title: 'Тесты', component: ProjectTestsTab, buttons: ProjectTestsTabButtons },
    { key: 'categories', title: 'Компетенции', component: ProjectCategoriesTab, buttons: ProjectCategoriesTabButtons, hide: ({ getCompanyTests }) => !!path(['items', 0, 'testTemplate'], getCompanyTests.data) },
    { key: 'settings', title: 'Настройки', component: ProjectSettingsTab },
    { key: 'sequence', title: 'Вопросы', component: ProjectSequenceTab, hide: ({ isAdmin, getCompanyTests }) => !isAdmin || !!path(['items', 0, 'testTemplate'], getCompanyTests.data) },
    { key: 'respondents', title: 'Респонденты', component: ProjectRespondentsTab, buttons: ProjectRespondentsTabButtons, routes: [
        { path: '/company/:company/projects/:id/test/:test/respondents/:respondent', component: ProjectRespondent }
    ]},
    { key: 'experts', title: 'Эксперты', component: ProjectExpertsTab, buttons: ProjectExpertsTabButtons, routes: [
        { path: '/company/:company/projects/:id/test/:test/experts/:respondent', component: ProjectRespondent }
    ]},
    { key: 'analytics', title: 'Аналитика', component: ProjectAnalyticsTab, buttons: ProjectAnalyticsTabButtons }
];

class Project extends Component  {
    state = {
        tabSubmit: false
    };

    tabs = {};

    setTabSubmit = tabSubmit => this.setState({ tabSubmit });

    onChange = key => {
        const { history, match: { params: { id, company, type }}} = this.props;
        const formRef = this.tabs[type] && this.tabs[type]();

        if (formRef && !formRef.props.form.getState().pristine) {
            const formState = formRef.props.form.getState();

            !formState.invalid && this.setTabSubmit(true);
            formRef.props.form.submit();
            !formState.invalid && history.replace(`/company/${company}/projects/${id}/${key}`);
        } else {
            history.replace(`/company/${company}/projects/${id}/${key}`);
        }
    }

    renderTitle = () => {
        const { getCompanyProject: { data, meta }, match: { params: { type, itemId }}, getCompanyTests } = this.props;
        const Buttons = (!itemId && path(['buttons'], find(propEq('key', type), tabs))) || this.getButtons();

        return <ToolbarTitle>
            <h1>{ data.name }</h1>
            { meta.lastSucceedAt && Buttons && <Buttons {...this.props} testSuites={getCompanyTests.data.items || []} /> }
        </ToolbarTitle>;
    }

    getButtons = () => {
        const { match: { params: { type, itemId }}} = this.props;

        if (itemId && contains(type, ['experts', 'respondents'])) {
            return ProjectRespondentButtons;
        }

        return null;
    }

    getSubmitButton = () => {
        return <SubmitButton type='primary' onClick={() => this.setTabSubmit(false)}>
            Сохранить
        </SubmitButton>;
    }

    getTabs = () => {
        return filter(tab => tab.hide ? !tab.hide(this.props) : true, tabs);
    }

    render() {
        const { getCompanyProject: { data, meta }, match: { params: { type, id, company, itemId }}, getCompanyTests } = this.props;

        return <Fragment>
            <DetailToolbar
                title={this.renderTitle()}
                onlyBackUrl
                backUrl={itemId && type === 'respondents' ? `/company/${company}/projects/${id}/respondents` :
                    itemId && type === 'experts' ? `/company/${company}/projects/${id}/experts` : '/'
                } />
            { ((meta.pending && !meta.lastSucceedAt) || (getCompanyTests.meta.pending && !getCompanyTests.meta.lastSucceedAt)) ?
                <Spin /> :
                <StyledTabs
                    onChange={this.onChange}
                    activeKey={type || ''}
                    animated={{ tabPane: false }}>
                    { this.getTabs().map(tab =>
                        <Tabs.TabPane key={tab.key} tab={tab.title}>
                            { (tab.routes || []).map(route =>
                                <Route key={path} path={route.path} render={props =>
                                    <route.component {...props} testSuites={getCompanyTests.data.items || []} />
                                } />
                            )}
                            <Route path={`/company/${company}/projects/${id}/${tab.key}`} exact render={props =>
                                <tab.component
                                    {...props}
                                    getRef={node => this.tabs[tab.key] = node}
                                    project={data}
                                    itemMeta={meta}
                                    testSuites={getCompanyTests.data.items || []}
                                    testSuitesMeta={getCompanyTests.meta}
                                    submitBtn={this.getSubmitButton()}
                                    tabSubmit={this.state.tabSubmit}
                                    id={id}
                                    company={company} />
                            } />
                        </Tabs.TabPane>
                    )}
                </StyledTabs>
            }
        </Fragment>;
    }
}

const stateToProps = state => ({
    isAdmin: getUser.selectData(state).role === ADMIN
});

export default asyncConnect({
    getCompanyTests: getCompanyTests
        .withSaga(function* (getProps) {
            yield takeEvery([
                toSuccess(PUT_COMPANY_TEST),
                toSuccess(PUT_COMPANY_TESTS),
                toSuccess(PUT_COMPANY_TEST_STATUS)
            ], function() {
                const { getCompanyTests } = getProps();

                getCompanyTests.refresh();
            })
        })
        .withPayload(({ match: { params: { id, company }}}) => ({ project: id, company }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    getCompanyProject: getCompanyProject
        .withPayload(({ match: { params: { id, company }}}) => ({ id, company }))
        .withSaga(function* (getProps) {
            yield takeEvery([
                toSuccess(PUT_COMPANY_PROJECT),
                toSuccess(PUT_COMPANY_TESTS),
                toSuccess(PATCH_COMPANY_PROJECTS),
                toSuccess(POST_COMPANY_TEST),
                toSuccess(PUT_COMPANY_TEST),
                toSuccess(DELETE_COMPANY_TEST),
                toSuccess(PATCH_COMPANY_TEST),
                toSuccess(PUT_COMPANY_TEST_STATUS),
                toSuccess(PUT_COMPANY_PROJECT_TIME_SETTINGS),
                toSuccess(PUT_QUESTION)
            ], function() {
                const { getCompanyProject } = getProps();

                getCompanyProject.refresh();
            })
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
}, stateToProps)(withErrorHandler('getCompanyProject')(Project));
