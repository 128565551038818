import React, { Component } from 'react';
import { Input } from 'antd';

import withFieldWrapper from '../../hocs/withFieldWrapper';

class InputComponent extends Component {
    static defaultProps = {
        htmlType: 'text'
    };

    onChange = e => this.props.onChange(e.target.value);

    render() {
        const { icon, htmlType, size, placeholder, disabled, input: { value }, addonBefore, addonAfter, fakeValue } = this.props;

        return <Input
            value={fakeValue || value}
            disabled={disabled}
            onChange={this.onChange}
            prefix={icon || null}
            type={htmlType}
            size={size}
            placeholder={placeholder}
            addonBefore={addonBefore}
            addonAfter={addonAfter} />;
    }
}

export default withFieldWrapper(InputComponent);
