import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'
import { pluralize } from 'numeralize-ru';
import { includes, path } from 'ramda';
import { Button, message, Popconfirm, Tooltip } from 'antd';
import { asyncConnect } from 'react-async-client';

import TableList from './table/TableList';
import { getPayments, putPayment, deletePayment } from '../../actions/asyncActions';
import { openPaymentModal, openPaymentBankDetailsModal, openPaymentMessageHistoryModal } from '../../actions/modalActions';
import DateFormat from './table/DateFormat';
import { PUT_PAYMENT, DELETE_PAYMENT, POST_COMPANY_PAYMENT, PUT_PAYMENT_BANK_DETAILS } from '../../constants/actionTypes';
import PaymentsFilter from '../forms/filters/PaymentsFilter';
import { PAYMENT_OPERATION_STATUSES } from '../../constants/payments';
import { getFilters } from '../../utils/urlParams';
import { CalendarOutlined, CreditCardOutlined, DeleteOutlined, FileExcelOutlined, HistoryOutlined, PlusOutlined } from '@ant-design/icons';
import { PAYMENTS_STATUSES, PAYMENT_STATUSES_LINK } from '../../constants/companies';
import { EnsiLoadStatus } from './companies/CompanyPayments';
import { getToken } from '../../utils/token';

class Payments extends Component {
    getDownloadUrl = id => {
        return `/api/payments/${id}.xlsx?access_token=${getToken()}`;
    }

    getColumns = () => {
        return [
            {
                title: 'Операция',
                key: 'statuses',
                filters: PAYMENT_OPERATION_STATUSES,
                render: item => {
                    const { pending } = this.props.putPayment.meta;
                    const companyName = path(['_embedded', 'company', 'name'], item);

                    return <div>
                        <span>
                            { item.status === 'paid' ? 'Пополнение баланса компании ' : 'Запрос на пополнение от компании ' }
                            <Link to={`/companies/${item.company}/billing/payments`}>{ companyName }</Link> на { item.value } { pluralize(item.value, 'юнит', 'юнита', 'юнитов') }
                        </span>
                        { item.status !== 'paid' &&
                            <div style={{ marginTop: 5, position: 'relative', zIndex: 1 }}>
                                <Button.Group size='small'>
                                    { item.status === 'credit' && <Button disabled={pending} onClick={() => this.changeStatus(item, 'paid')}>Закрыть кредит</Button> }
                                    { item.status === 'request' &&
                                        <Fragment>
                                            <Button disabled={pending} onClick={() => this.changeStatus(item, 'credit')}>Пополнить в кредит</Button>
                                            <Button disabled={pending} onClick={() => this.changeStatus(item, 'paid')}>Пополнить</Button>
                                        </Fragment>
                                    }
                                </Button.Group>
                            </div>
                        }
                    </div>;
                }
            },
            {
                title: '№ счета',
                key: 'paymentAccount',
                dataIndex: ['_embedded', 'ensiInvoice', 'accountId']
            },
            {
                title: 'Статус',
                key: 'status',
                dataIndex: 'status',
                width: 170,
                render: (status, item) =>
                    status === 'request' ? <EnsiLoadStatus payment={item} /> :
                    <div>
                        { PAYMENTS_STATUSES[status] }
                        { includes(item.status, PAYMENT_STATUSES_LINK) &&
                            <Button
                                style={{ marginLeft: 10 }}
                                icon={<FileExcelOutlined />}
                                href={this.getDownloadUrl(item.id)}
                                target='_blank'
                                rel='noopener noreferrer'
                                download />
                        }
                    </div>
            },
            {
                title: 'Поставщик',
                key: 'ensiContractorName',
                dataIndex: ['_embedded', 'ensiInvoice', 'organizationName']
            },
            {
                title: 'Покупатель',
                key: 'name',
                dataIndex: ['_embedded', 'ensiInvoice', 'customerName']
            },
            {
                title: 'Дата',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: date => <DateFormat date={date} icon={<CalendarOutlined />} withTime />
            },
            {
                key: 'delete',
                width: 130,
                align: 'right',
                render: item =>
                    <Button.Group>
                        <Tooltip title='Служебная информация'>
                            <Button
                                icon={<HistoryOutlined />}
                                onClick={() => this.props.openPaymentMessageHistoryModal({ id: item.id })} />
                        </Tooltip>
                        <Tooltip title='Банковские реквизиты'>
                            <Button
                                icon={<CreditCardOutlined />}
                                onClick={() => this.props.openPaymentBankDetailsModal({ id: item.id, bankDetails: path(['_embedded', 'bankDetails'], item), company: item.company })} />
                        </Tooltip>
                        <Popconfirm
                            title='Вы уверены, что хотите удалить платеж?'
                            onConfirm={() => this.props.deletePayment.dispatch(item.id)}>
                            <Button type='danger' icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </Button.Group>
            }
        ];
    }

    changeStatus = (item, status) => {
        this.props.putPayment.dispatch({
            ...item,
            status
        });
    }

    renderButtons = () => {
        const company = path(['company'], getFilters(this.props.location));

        return <Button
            icon={<PlusOutlined />}
            type='primary'
            onClick={() => this.props.openPaymentModal({
                company
            })}>
            <span className='hide-mobile'>Добавить</span>
        </Button>;
    }

    render() {
        return <TableList
            action={getPayments}
            columns={this.getColumns()}
            refreshActions={[PUT_PAYMENT, DELETE_PAYMENT, POST_COMPANY_PAYMENT, PUT_PAYMENT_BANK_DETAILS]}
            filterForm={PaymentsFilter}
            buttons={this.renderButtons()}
            defaultSort={{
                sort_by: 'createdAt',
                sort_order: 'desc'
            }} />;
    }
}

export default asyncConnect({
    putPayment: putPayment
        .withErrorHandler(() => message.error('Не удалось изменить статус платежа'))
        .withOptions({ resetOnUnmount: true }),
    deletePayment: deletePayment
        .withSuccessHandler(() => message.success('Платеж был успешно удален'))
        .withErrorHandler(() => message.error('Не удалось удалить платеж'))
        .withOptions({ resetOnUnmount: true })
}, null, { openPaymentModal, openPaymentBankDetailsModal, openPaymentMessageHistoryModal })(Payments);
