import React, { Component } from 'react';
import { Tree, Button } from 'antd';
import { asyncConnect } from 'react-async-client';
import { pathOr, last, path, filter, contains } from 'ramda';
import { DownOutlined } from '@ant-design/icons';

import { getCategories, patchQuestion } from '../../actions/asyncActions';
import CategoryTitle from '../user/questions/CategoryTitle';
import Modal from './Modal';

class SelectCategoryModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checkedKeys: props.params.checked ? [props.params.checked] : [],
            expandedKeys: props.params.expanded || [],
            expandedChecked: props.params.expanded || [],
            string: props.params.string
        };

        this.nodes = {};
    }

    getCheckedString = event => {
        if (!event.checked) {
            return null;
        }

        const item = path(['title', 'props', 'item'], last(event.checkedNodes));
        const parentName = path(['_embedded', 'parent', 'name'], item);
        const parentOfParentName = path(['_embedded', 'parent', '_embedded', 'parent', 'name'], item);

        return `${parentOfParentName ? `${parentOfParentName} / ` : ''}${parentName ? `${parentName} / ` : ''}${item.name}`;
    }

    getCheckedParents = event => {
        if (!event.checked) {
            return [];
        }

        const item = path(['title', 'props', 'item'], last(event.checkedNodes));
        const parent = path(['_embedded', 'parent', 'id'], item);
        const parentOfParent = path(['_embedded', 'parent', '_embedded', 'parent', 'id'], item);

        return filter(i => i, [parent, parentOfParent]);
    }

    onCheck = ({ checked }, event) => {
        const checkedKey = last(checked);

        this.setState({
            checkedKeys: checkedKey ? [checkedKey] : [],
            string: this.getCheckedString(event),
            expandedChecked: this.getCheckedParents(event)
        });
    }

    onExpand = (expandedKeys, { expanded, node }) => {
        if (expanded) {
            const action = this.getAction(node.key);
            !action.meta.pending && !action.meta.lastSucceedAt && action.refresh();
        }

        this.setState({ expandedKeys });
    }

    changeCategory = () => {
        this.props.params.onSelect(last(this.state.checkedKeys), this.state.expandedChecked, this.state.string, this.props);
    }

    renderFooter = () => {
        return <Button
            type='primary'
            disabled={!this.state.checkedKeys.length}
            onClick={this.changeCategory}>
            Сохранить
        </Button>;
    }

    renderNodes = (node, level) => {
        const items = pathOr(node.childrenCount > 0 ? [{ empty: true }] : [], ['items'], this.props.getCategoryData(node.id));

        return items.map(item => item.empty ?
            <Tree.TreeNode key={`empty-node-${node.id}`} style={{ height: 0, overflow: 'hidden', padding: 0 }} disableCheckbox />
            : this.renderNode(item, level));
    }

    getAction = id => {
        return path([id, 'props', 'getCategories'], this.nodes);
    }

    renderNode = (item, level) => {
        const meta = this.props.getCategoryMeta(item.id);
        const notLoaded = !meta.pending && !meta.lastSucceedAt;

        return <Tree.TreeNode
                key={item.id}
                checkable={level === 2}
                title={<CategoryTitle
                    item={item}
                    getRef={node => this.nodes[item.id] = node}
                    dispatchOnMount={contains(item.id, this.state.expandedChecked) && notLoaded}
                    viewMode />
                }>
                { this.renderNodes(item, level + 1) }
            </Tree.TreeNode>;
    }

    render() {
        const { modal, params: { title }, getCategories: { meta }} = this.props;
        const { expandedKeys, checkedKeys } = this.state;
        const items = pathOr([], ['getCategories', 'data', 'items'], this.props);

        return <Modal
            {...modal}
            title={title || 'Выберите компетенцию'}
            footer={this.renderFooter()}>
            { !meta.pending && meta.lastSucceedAt &&
                <Tree
                    showLine
                    switcherIcon={<DownOutlined />}
                    checkable
                    ref={node => this.node = node}
                    onCheck={this.onCheck}
                    onExpand={this.onExpand}
                    checkedKeys={checkedKeys}
                    expandedKeys={this.node ? filter(i => contains(i, (this.node.state.flattenNodes || []).map(({ key }) => key)), expandedKeys) : expandedKeys}
                    checkStrictly>
                    { items.map(item => this.renderNode(item, 0))}
                </Tree>
            }
        </Modal>
    }
}

const stateToProps = state => ({
    getCategoryData: parent => getCategories.withParams({ parent }).selectData(state),
    getCategoryMeta: parent => getCategories.withParams({ parent }).selectMeta(state)
});

export default asyncConnect({
    getCategories: getCategories
        .withParams({ type: 'modal' })
        .withPayload(({ params: { company }}) => {
            return {
                q: { byParent: true, company, onlyOwn: company ? true : undefined }
            }
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    patchQuestion: patchQuestion
        .withSuccessHandler(({ close }) => close())
}, stateToProps)(SelectCategoryModal);
