import React, { Component, Fragment } from 'react';
import { Tree, Alert, Spin } from 'antd';
import { asyncConnect } from 'react-async-client';
import { filter, contains, pathOr, path, append, flatten, without, equals, is, find, propEq, pathEq, any } from 'ramda';
import styled, { css } from 'styled-components';
import * as yup from 'yup';
import { FormSpy } from 'react-final-form';
import { DownOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Droppable, DragDropContext, Draggable } from 'react-beautiful-dnd';
import cx from 'classnames';

import { getCategories } from '../../../actions/asyncActions';
import withFormWrapper from '../../hocs/withFormWrapper';
import SubmitButton from '../formComponents/SubmitButton';
import Complexity from '../../user/table/Complexity';
import { getCategoriesTree } from '../../../utils/categories';

const ActionButton = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    color: #a7a7a7;
    transition: color .3s;
    height: 100%;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        color: ${({ add }) => add ? '#5CB85C' : '#f54d2e'};
    }
    .anticon {
        cursor: pointer;
    }
    ${props => props.disabled && css`
        opacity: .5;
        &:hover {
            color: #a7a7a7;
            cursor: not-allowed;
        }
        i.anticon[tabindex] {
            cursor: not-allowed;
        }
    `}
`;

const Container = styled.div`
    display: flex;
    margin: 15px 0;
    .ant-tree-title{
        padding-right: 25px;
        display: inline-block;
    }
    .ant-tree-node-content-wrapper {
        cursor: default;
    }
`;

const Categories = styled.div`
    width: 50%;
    h3 {
        border: 1px solid #e8e8e8;
        padding-bottom: 10px;
        padding: 10px;
        background: #fafafa;
        margin: -1px 0px;

    }
    padding-right: ${({ left }) => left ? 5 : 0}px;
    padding-left: ${({ left }) => left ? 0 : 5}px;
    ${({ left }) => !left && css`
        .ant-tree li span.ant-tree-switcher {
            display: none;
        }
        .ant-tree li .ant-tree-node-content-wrapper {
            width: 100%;
        }
    `}
    .ant-tree{
        border: 1px solid #e8e8e8;
        padding: 10px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
`;

const Empty = styled.div`
    text-align: center;
    color: #a9a9a9;
    border: 1px solid #e8e8e8;
    padding: 25px 10px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
`;

const CenterWrapper = styled.div`
    text-align: center;
    padding: 30px 0;
    text-align: center;
    border: 1px solid #e8e8e8;
    padding: 25px 10px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    .ant-spin-spinning{
        height: 22px;
    }
`;

const SelectedNodeTitle = styled.span`
    margin: 0 5px;
`;

const AvailableComplexity = styled.div`
    & > div {
        margin-right: 5px;
    }
`;

const ComplexityBlock = styled.div`
    display: inline-flex;
    align-items: baseline;
    margin: 0 6px 0 2px;
    line-height: 12px;
    font-size: 12px;
    color: #B4B4B4;
`;

const ComplexityWrapper = styled.div`
    display: flex;
`;

const DraggableTreeNode = styled.div`
    .ant-tree-node-content-wrapper {
        cursor: ${({ disabled, zeroLevel }) => zeroLevel ? 'default' : disabled ? 'not-allowed' : 'grab'};
        .anticon-plus {
            cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
        }
    }
    ${({ isDragging }) => isDragging && css`
        .ant-tree-switcher,
        .ant-tree-indent,
        ${ActionButton} {
            display: none;
        }
    `}
`;

const DroppableBackground = styled.div`
    position: absolute;
    inset: 0px;
    background: rgb(204, 204, 204);
    opacity: 0.5;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
`;

const CategoryLabel = styled.span`
    margin: 0;
    position: absolute;
    bottom: -5px;
    right: 4px;
    opacity: 0.2;
    transition: all 0.4s ease-out;
    svg path {
        fill: #2B3D4F;
    }
`;

const CategoryCode = styled.span`
    color: #a7a7a7;
    font-size: 12px;
`;

class DraggableNode extends Component {
    state = {
        expanded: true
    }

    renderNodes = (node, level) => {
        const items = level === 1 && this.props.allowSelectSecondLevel ?
            filter(i => !contains(i.id, this.props.categories), node.items) :
            node.items;

        return items.map((item, index) =>
            <DraggableNode
                {...this.props}
                key={item.id}
                item={item}
                level={level}
                hideChildren={!this.props.lastLevel}
                index={index}
                draggable />
        );
    }

    toggleExpanded = () => this.setState(prev => ({ expanded: !prev.expanded }));

    renderContent = (snapshot = {}) => {
        const { item, level, hideChildren, disabledAdd } = this.props;
        const disabled = disabledAdd(item, this.props.allowSelectSecondLevel);

        return <Fragment>
            <DraggableTreeNode className={cx('ant-tree-treenode', { 'ant-tree-treenode-switcher-open': this.state.expanded, 'ant-tree-treenode-switcher-close': !this.state.expanded })} style={{ paddingLeft: level * 24 }} isDragging={snapshot.isDragging} disabled={disabled} zeroLevel={level === 0}>
                <span aria-hidden="true" className="ant-tree-indent"><span className="ant-tree-indent-unit ant-tree-indent-unit-start" style={{ width: 5 }}></span></span>
                { !hideChildren && !!(item.items || []).length ?
                    <span className={cx("ant-tree-switcher", { 'ant-tree-switcher_open': this.state.expanded, 'ant-tree-switcher_close': !this.state.expanded })} onClick={this.toggleExpanded}>
                        <DownOutlined className='ant-tree-switcher-icon' />
                    </span> :
                    <span className="ant-tree-switcher ant-tree-switcher-noop">
                        <span className="anticon anticon-file ant-tree-switcher-line-icon">
                            <svg viewBox="64 64 896 896" focusable="false" className="" data-icon="file" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                <path d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0042 42h216v494z"></path>
                            </svg>
                        </span>
                    </span>
                }
                <div className={cx('ant-tree-node-content-wrapper', { 'ant-tree-node-content-wrapper-open': this.state.expanded, 'ant-tree-node-content-wrapper-close': !this.state.expanded })}>
                    { this.props.renderNodeTitle(item, level) }
                </div>
            </DraggableTreeNode>
            { this.state.expanded && <Droppable droppableId={this.props.item.id} isDropDisabled>
                {provided => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}>
                        { !hideChildren && !!(item.items || []).length && this.renderNodes(item, level + 1) }
                    </div>
                )}
            </Droppable>}
        </Fragment>;
    }

    render() {
        const { item, level, disabledAdd, index, draggable } = this.props;
        const disabled = disabledAdd(item, this.props.allowSelectSecondLevel);

        return !draggable ?
            this.renderContent() :
            <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={level === 0 || disabled}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={provided.draggableProps.style}>
                        { this.renderContent(snapshot) }
                    </div>
                )}
            </Draggable>;
    }
}

class ProjectCategories extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expandedKeys: [],
            isDragged: false
        };

        this.nodes = {};
    }

    onExpand = expandedKeys => {
        this.setState({ expandedKeys });
    }

    onSelect = (item, data) => {
        const expandable = !!(data.node.children || []).length;
        const id = data.node.key;

        if (expandable) {
            const expanded = !data.node.expanded;
            this.onExpand(expanded ? append(id, this.state.expandedKeys) : without([id], this.state.expandedKeys), { expanded, node: data.node });
        }
    }

    getCategories = () => pathOr([], ['values', 'categories'], this.props);

    renderNodes = (node, level) => {
        const items = level === 1 && this.props.allowSelectSecondLevel ?
            filter(i => !contains(i.id, this.getCategories()), node.items) :
            node.items;

        return items.map(item => this.renderNode(item, level, !this.props.lastLevel));
    }

    addCategory = item => {
        const { allowSelectSecondLevel, getCategories } = this.props;
        let categories = this.getCategories();

        if (allowSelectSecondLevel && !path(['_embedded', 'parent', 'parent'], item)) {
            const children = filter(propEq('parent', item.id), getCategories.data.items || []).map(({ id }) => id);
            categories = filter(id => !contains(id, children), categories);
        }

        this.props.form.change('categories', append(item.id, categories));
    }

    disabledAdd = (item, allowSelectSecondLevel) => {
        return allowSelectSecondLevel && any(id => pathEq(['parent'], item.id, find(propEq('id', id), this.props.getCategories.data.items || [])), this.getCategories());
    }

    renderAddButton = (item, allowSelectSecondLevel) => {
        const disabled = this.disabledAdd(item, allowSelectSecondLevel);

        return <ActionButton add onClick={e => e.stopPropagation()} disabled={disabled}>
            <PlusOutlined onClick={() => !disabled && this.addCategory(item)} />
        </ActionButton>;
    }

    renderComplexity = (item, level) => {
        return level > 0 &&
            <AvailableComplexity>
                { item.availableCompetenceLevels.map(level => <Complexity key={`available-level-${level}`} level={level} />) }
            </AvailableComplexity>;
    }

    renderComplexityBlock = (item, level) => {
        return !this.props.lastLevel ?
            this.renderComplexity(item, level) :
            <ComplexityWrapper>
                { !!item.questionsCount['1'] &&
                <ComplexityBlock>
                    <Complexity level={1} /> { item.questionsCount['1'] }
                </ComplexityBlock>
                }
                { !!item.questionsCount['2'] &&
                <ComplexityBlock>
                    <Complexity level={2} /> { item.questionsCount['2'] }
                </ComplexityBlock>
                }
                { !!item.questionsCount['3'] &&
                <ComplexityBlock>
                    <Complexity level={3} /> { item.questionsCount['3'] }
                </ComplexityBlock>
                }
            </ComplexityWrapper>;
    }

    renderNodeTitle = (item, level) => {
        const { lastLevel, disabled, allowSelectSecondLevel } = this.props;

        return <div>
            <div>{ !item.company && <CategoryLabel className='category-label'>
                <svg width="33" height="6" viewBox="0 0 33 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_605_7221)">
                    <path d="M23.7323 4.16572C22.9755 4.16572 22.4251 3.66515 22.4251 2.98475C22.4251 2.30418 22.9755 1.80395 23.7323 1.80395C24.4662 1.80395 25.0394 2.30435 25.0394 2.98475C25.0394 3.66532 24.489 4.16572 23.7323 4.16572ZM23.7323 0.342865C22.0122 0.342865 20.6592 1.50378 20.6592 2.98475C20.6592 4.46589 22.0122 5.60675 23.7323 5.60675C25.4523 5.60675 26.8053 4.44584 26.8053 2.98475C26.8053 1.50361 25.4523 0.342865 23.7323 0.342865ZM4.0553 4.16572C3.29846 4.16572 2.74807 3.66515 2.74807 2.98475C2.74807 2.30418 3.29846 1.80395 4.0553 1.80395C4.78916 1.80395 5.36234 2.30435 5.36234 2.98475C5.36234 3.66532 4.81195 4.16572 4.0553 4.16572ZM5.29359 0.883208C4.90368 0.502979 4.37646 0.342865 3.82587 0.342865C2.24345 0.342865 0.982178 1.50378 0.982178 2.98475C0.982178 3.64526 1.23439 4.26566 1.71603 4.74601C2.24345 5.28652 3.06903 5.60658 3.91761 5.60658C4.42223 5.60658 4.99541 5.40652 5.29359 5.08629V5.50664H7.01371V0.442979H5.29359V0.883208ZM32.6072 0.442979H30.5201L29.5341 1.66389V0.442979H27.7682V5.50664H29.5341V2.26424H31.1622L32.6072 0.442979ZM18.1595 4.16572C17.3567 4.16572 16.7835 3.68521 16.7835 2.98475C16.7835 2.30418 17.3567 1.80395 18.1135 1.80395C18.5085 1.80562 18.8871 1.94209 19.1683 2.18418L20.1316 0.983322C19.5653 0.570669 18.8513 0.344077 18.1135 0.342865C16.3706 0.342865 14.9946 1.50378 14.9946 2.98475C14.9946 4.48595 16.3248 5.60675 18.0905 5.60675C18.9391 5.60675 19.6272 5.38646 20.2233 4.94624L19.2602 3.72532C19.0078 3.94544 18.6179 4.16572 18.1595 4.16572ZM11.7836 0.342865C11.279 0.342865 10.4304 0.562979 10.1324 0.883208V0.442979H8.41228V5.50664H10.1782V2.30435C10.3844 2.08406 10.8433 1.80378 11.3937 1.80378C11.6919 1.80378 11.9441 1.88401 12.1275 2.02406C12.265 2.14406 12.4025 2.34429 12.4025 2.82464V5.50664H14.1684V2.26424C14.1684 0.983322 13.091 0.342865 11.7838 0.342865H11.7836Z" fill="#F54D2E"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_605_7221">
                    <rect width="33" height="6" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>
            </CategoryLabel> } { item.name } <CategoryCode>{ item.code ? ` [${item.code}]` : '' }</CategoryCode></div>
            { this.renderComplexityBlock(item, level) }
            { (level === (lastLevel ? 2 : 1) || (allowSelectSecondLevel && level === 1)) && !disabled && this.renderAddButton(item, allowSelectSecondLevel && level === 1) }
        </div>
    }

    renderNode = (item, level, hideChildren) => {
        return <Tree.TreeNode
            key={item.id}
            title={this.renderNodeTitle(item, level)}>
            { !hideChildren && !!(item.items || []).length && this.renderNodes(item, level + 1) }
        </Tree.TreeNode>;
    }

    getSelectedTree = () => {
        const { getCategories, lastLevel, allowSelectSecondLevel } = this.props;
        let selectedCategories = this.getCategories();

        if (allowSelectSecondLevel) {
            selectedCategories = flatten(selectedCategories.map(id => {
                const item = find(propEq('id', id), getCategories.data.items || []);

                if (item && !path(['_embedded', 'parent', 'parent'], item)) {
                    return filter(propEq('parent', id), getCategories.data.items || []).map(({ id }) => id);
                }

                return id;
            }));
        }

        return getCategoriesTree(filter(i => contains(i.id, selectedCategories), getCategories.data.items || []), lastLevel);
    }

    deleteNode = ids => {
        this.props.form.change('categories', filter(id => !contains(id, ids), this.getCategories()));
    }

    renderSelectedNodeTitle = (item, parent, level) => {
        return <span>
            <SelectedNodeTitle>{ !item.company && <CategoryLabel className='category-label'>
                <svg width="33" height="6" viewBox="0 0 33 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_605_7221)">
                    <path d="M23.7323 4.16572C22.9755 4.16572 22.4251 3.66515 22.4251 2.98475C22.4251 2.30418 22.9755 1.80395 23.7323 1.80395C24.4662 1.80395 25.0394 2.30435 25.0394 2.98475C25.0394 3.66532 24.489 4.16572 23.7323 4.16572ZM23.7323 0.342865C22.0122 0.342865 20.6592 1.50378 20.6592 2.98475C20.6592 4.46589 22.0122 5.60675 23.7323 5.60675C25.4523 5.60675 26.8053 4.44584 26.8053 2.98475C26.8053 1.50361 25.4523 0.342865 23.7323 0.342865ZM4.0553 4.16572C3.29846 4.16572 2.74807 3.66515 2.74807 2.98475C2.74807 2.30418 3.29846 1.80395 4.0553 1.80395C4.78916 1.80395 5.36234 2.30435 5.36234 2.98475C5.36234 3.66532 4.81195 4.16572 4.0553 4.16572ZM5.29359 0.883208C4.90368 0.502979 4.37646 0.342865 3.82587 0.342865C2.24345 0.342865 0.982178 1.50378 0.982178 2.98475C0.982178 3.64526 1.23439 4.26566 1.71603 4.74601C2.24345 5.28652 3.06903 5.60658 3.91761 5.60658C4.42223 5.60658 4.99541 5.40652 5.29359 5.08629V5.50664H7.01371V0.442979H5.29359V0.883208ZM32.6072 0.442979H30.5201L29.5341 1.66389V0.442979H27.7682V5.50664H29.5341V2.26424H31.1622L32.6072 0.442979ZM18.1595 4.16572C17.3567 4.16572 16.7835 3.68521 16.7835 2.98475C16.7835 2.30418 17.3567 1.80395 18.1135 1.80395C18.5085 1.80562 18.8871 1.94209 19.1683 2.18418L20.1316 0.983322C19.5653 0.570669 18.8513 0.344077 18.1135 0.342865C16.3706 0.342865 14.9946 1.50378 14.9946 2.98475C14.9946 4.48595 16.3248 5.60675 18.0905 5.60675C18.9391 5.60675 19.6272 5.38646 20.2233 4.94624L19.2602 3.72532C19.0078 3.94544 18.6179 4.16572 18.1595 4.16572ZM11.7836 0.342865C11.279 0.342865 10.4304 0.562979 10.1324 0.883208V0.442979H8.41228V5.50664H10.1782V2.30435C10.3844 2.08406 10.8433 1.80378 11.3937 1.80378C11.6919 1.80378 11.9441 1.88401 12.1275 2.02406C12.265 2.14406 12.4025 2.34429 12.4025 2.82464V5.50664H14.1684V2.26424C14.1684 0.983322 13.091 0.342865 11.7838 0.342865H11.7836Z" fill="#F54D2E"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_605_7221">
                    <rect width="33" height="6" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>
            </CategoryLabel> } { item.name }</SelectedNodeTitle>
            { this.renderComplexityBlock(item, level) }
            { !this.props.disabled &&
                <ActionButton>
                    <DeleteOutlined
                        onClick={() =>
                            this.deleteNode(parent ? flatten(item.items.map(i => [item.id, i.id, ...(i.items ? i.items.map(c => c.id) : [])])) : [item.id])
                        } />
                </ActionButton>
            }
        </span>;
    }

    renderSelectedNode = item => {
        const { allowSelectSecondLevel } = this.props;

        return <Tree.TreeNode
            key={`selected-${item.id}`}
            title={this.renderSelectedNodeTitle(item, true)}
            selectable={false}>
            { (item.items || []).map(child =>
                <Tree.TreeNode
                    key={`selected-${child.id}`}
                    title={this.renderSelectedNodeTitle(child, this.props.lastLevel, 1)}
                    selectable={false}>
                    { (allowSelectSecondLevel && contains(child.id, this.getCategories()) ? [] : (child.items || [])).map(i =>
                        <Tree.TreeNode
                            key={`selected-${i.id}`}
                            title={this.renderSelectedNodeTitle(i)}
                            selectable={false} />
                    )}
                </Tree.TreeNode>
            )}
        </Tree.TreeNode>
    }

    getSelectedExpandedKeys = () => {
        return flatten(this.getSelectedTree().map(
            item => this.props.lastLevel ? [`selected-${item.id}`, ...item.items.map(i => `selected-${i.id}`)] : `selected-${item.id}`)
        );
    }

    disabledSave = values => {
        const { mapPropsToValues, item, disableSave } = this.props;
        const categories = (mapPropsToValues ? mapPropsToValues(this.props) : item).categories;

        return equals(values.categories, categories) || !values.categories.length || (disableSave && disableSave(values, this.props));
    }

    getItems = () => {
        const { getCategories, lastLevel, allowSelectSecondLevel } = this.props;
        let categories = this.getCategories();

        if (allowSelectSecondLevel) {
            categories = flatten(this.getCategories().map(id => {
                const item = find(propEq('id', id), getCategories.data.items || []);

                if (item && !path(['_embedded', 'parent', 'parent'], item)) {
                    return filter(propEq('parent', id), getCategories.data.items || []).map(({ id }) => id);
                }

                return id
            }));
        }

        return getCategoriesTree(filter(i => !contains(i.id, categories), pathOr([], ['data', 'items'], getCategories)), lastLevel)
    }

    setIsDragged = isDragged => {
        this.setState({ isDragged });
    }

    onDragEnd = result => {
        const { destination } = result;

        if (!destination) {
            this.setIsDragged(false);
            return;
        }

        if (destination.droppableId === 'selected') {
            const item = find(propEq('id', result.draggableId), this.props.getCategories.data.items || []);

            this.addCategory(item);
        }

        this.setIsDragged(false);
    }

    render() {
        const { buttons, errors, submitFailed, getCategories: { meta } } = this.props;
        const items = this.getItems();
        const selectedItems = this.getSelectedTree();
        const errorMsg = path(['_arrays', 'categories'], errors);

        return <Fragment>
            <Container>
                <DragDropContext
                    onDragEnd={this.onDragEnd}
                    onBeforeDragStart={() => this.setIsDragged(true)}>
                <Categories left>
                    <h3 className='title-categories'>Доступные компетенции</h3>
                    { items.length ?
                        <div className='ant-tree ant-tree-show-line'>
                            { items.map((item, index) =>
                                <DraggableNode
                                    {...this.props}
                                    key={item.id}
                                    item={item}
                                    level={0}
                                    renderNodeTitle={this.renderNodeTitle}
                                    categories={this.getCategories()}
                                    disabledAdd={this.disabledAdd}
                                    index={index}
                                    setIsDragged ={this.setIsDragged} />)
                            }
                        </div> :
                        meta.pending ?
                            <CenterWrapper><Spin /></CenterWrapper> :
                            <Empty>Нет доступных категорий</Empty>
                    }
                </Categories>
                <Categories>
                    <h3 className='title-categories'>Выбранные компетенции</h3>
                        <Droppable droppableId ='selected'>
                            {provided => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{ position: 'relative' }}>
                                    { selectedItems.length ?
                                        <Tree key={this.getSelectedExpandedKeys().reduce((cur, res) => cur + res, '')} showLine switcherIcon={<DownOutlined />} onExpand={this.onExpand} defaultExpandedKeys={this.getSelectedExpandedKeys()}>
                                            { selectedItems.map(this.renderSelectedNode) }
                                        </Tree> :
                                        meta.pending ?
                                            <CenterWrapper><Spin /></CenterWrapper> :
                                            <Empty>Компетенции еще не выбраны</Empty>
                                    }
                                    { this.state.isDragged &&
                                        <DroppableBackground>
                                            <PlusOutlined />
                                        </DroppableBackground>
                                    }
                                </div>
                            )}
                        </Droppable>
                </Categories>
                </DragDropContext>
            </Container>
            { submitFailed && errorMsg && <Alert style={{ marginTop: 15, marginBottom: 15 }} type='error' message={errorMsg} /> }
            { buttons ||
                <FormSpy subscription={{ values: true }}>
                    { ({ values }) =>
                        <SubmitButton
                            type='primary'
                            disabled={this.disabledSave(values)}>
                            Сохранить
                        </SubmitButton>
                    }
                </FormSpy>
            }
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    categories: yup.array().min(1, 'Необходимо добавить как минимум одну компетенцию')
});

export default asyncConnect({
    getCategories: getCategories
        .withPayload(({ complete, lastLevel, ignoreValid, onlyOwn, item: { company }}) => ({
            q: {
                complete,
                ...(ignoreValid ? {} : { valid: !lastLevel }),
                ...(is(Boolean, onlyOwn) ? { onlyOwn } : {}),
                company
            }
        }))
        .withOptions({ resetOnUnmount: true, dispatchOnMount: true })
})(
    withFormWrapper(ProjectCategories, {
        subscriptions: { values: true, errors: true },
        validationSchema,
        mapPropsToValues: props => props.mapPropsToValues ? props.mapPropsToValues(props) : props.item,
        mapBeforeSubmit: (values, props) => props.mapBeforeSubmit ?
            props.mapBeforeSubmit(values, props) : values
    })
);
