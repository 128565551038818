import { forEach, path, findIndex, propEq, assocPath, append, filter, pathOr, any } from 'ramda';

const getUpdatedCategories = (categories, parent, item) => {
    const index = findIndex(propEq('id', parent.id), categories);
    const items = pathOr([], [index, 'items'], categories);

    return index > -1 ?
        assocPath([index, 'items'], any(propEq('id', item.id), items) ? items : append(item, items), categories) :
        append({...parent, items: [item]}, categories);
}

export const groupCategories = items => {
    let categories = filter(i => !i.parent, items);

    forEach(item => {
        let parent = path(['_embedded', 'parent'], item);

        if (parent.parent) {
            const parentOfParent = path(['_embedded', 'parent'], parent);
            parent.items = any(propEq('id', item.id), parent.items || []) ? parent.items : append(item, parent.items);
            categories = getUpdatedCategories(categories, parentOfParent, parent);
        } else {
            categories = getUpdatedCategories(categories, parent, item);
        }
    }, filter(i => i.parent, items));

    return categories;
}

const getLevelItems = (items, lastLevel) => {
    let levelItems = [];

    forEach(item => {
        if ((item.parent && !lastLevel) || (lastLevel && path(['_embedded', 'parent', 'parent'], item))) {
            const index = findIndex(propEq('id', item.parent), levelItems);

            if (index > -1) {
                levelItems[index].items = append(item, levelItems[index].items);
            } else {
                levelItems = append({
                    ...item._embedded.parent,
                    items: [item]
                }, levelItems);
            }
        } else if (lastLevel && !path(['_embedded', 'parent', 'parent'], item) && item.parent) {
            levelItems = append({
                ...item,
                items: []
            }, levelItems);
        }
    }, items);

    return levelItems;
}

export const getCategoriesTree = (items, lastLevel) => {
    return lastLevel ? getLevelItems(getLevelItems(items, lastLevel)) : getLevelItems(items);
}
