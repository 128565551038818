import React, { Component, Fragment } from 'react';
import { prop } from 'ramda';
import { Field } from 'react-final-form';
import * as yup from 'yup';

import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import Input from './formComponents/Input';
import { PhoneInput } from './formComponents/MaskedInput';
import InputNumber from './formComponents/InputNumber';

const labelCol = {
    xs: { span: 24 },
    sm: { span: 6 },
};

const wrapperCol = {
    xs: { span: 24 },
    sm: { span: 18 },
};

class TestRespondentForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='lastName'
                component={Input}
                label='Фамилия'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='firstName'
                component={Input}
                label='Имя'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='middleName'
                component={Input}
                label='Отчество'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='email'
                component={Input}
                label='Email'
                htmlType='email'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='phone'
                component={PhoneInput}
                label='Телефон'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='rating'
                component={InputNumber}
                label='Рейтинг (от 1 до 5)'
                min={1}
                max={5}
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <SubmitButton type='primary'>Добавить</SubmitButton>
        </Fragment>
    }
}

const validationSchema = yup.object().shape({
    lastName: yup.string().nullable().required(),
    firstName: yup.string().nullable().required(),
    email: yup.string().nullable().email().required(),
    rating: yup.number().nullable().required(),
    phone: yup.string().nullable().phone()
});

export default withFormWrapper(TestRespondentForm, {
    mapPropsToValues: prop('item'),
    validationSchema
});
