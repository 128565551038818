import React, { Component } from 'react';
import { Upload, message } from 'antd';
import styled from 'styled-components';
import { prop } from 'ramda';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import { getToken } from '../../../utils/token';

const Wrapper = styled.div`
    .ant-upload-select-picture-card {
        height: ${({ height }) => height + 4}px;
        width: ${({ width }) => width + 4}px;
    }
`;

const Image = styled.div`
    width: ${prop('width')}px;
    height: ${prop('height')}px;
    background-position: 50%;
    background-size: contain;
    background-image: ${({ base64, value, asUrl }) => asUrl ? `url('${value}')` : base64 ? `url('${value}')` : `url('/api/images/${value}')`};
    background-repeat: no-repeat;
`;

const Hover = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,.5);
    opacity: 0;
    transition: all .3s;
    &:hover {
        opacity: 1;
    }
`;

const DeleteButton = styled.button`
    background: none;
    border: none;
    color: #fff;
    opacity: .8;
    cursor: pointer;
    transition: all .3s;
    font-size: 18px;
    &:hover {
        opacity: 1;
    }
    &:focus {
        outline: none;
    }
`;

const AddBlock = styled.div`
    width: ${prop('width')}px;
    height: ${prop('height')}px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

class ImageUpload extends Component {
    static defaultProps = {
        height: 100,
        width: 100
    };

    reader = new FileReader();

    beforeUpload = file => {
        this.reader.readAsDataURL(file);
        this.reader.onload = () => this.props.onChange(this.reader.result);

        return false;
    }

    onChangeUpload = info => {
        switch (info.file.status) {
            case 'done':
                this.props.onChange(this.props.asUrl ? `${window.location.origin}/api/images/${info.file.response.id}` : info.file.response.id);
                break;
            case 'error':
                message.error('Не удалось загрузить фотографию');
                break;
            default:
                break;
        }
    }

    removeFile = () => {
        this.props.onChange(null);
    }

    render() {
        const { input: { value }, height, width, base64, asUrl } = this.props;
        const props = base64 ? {
            beforeUpload: this.beforeUpload
        } : {
            action: `/api/images?access_token=${getToken()}`,
            onChange: this.onChangeUpload
        };

        return <Wrapper height={height} width={width}>
            <Upload
                name='file'
                listType='picture-card'
                accept='image/*'
                showUploadList={false}
                disabled={!!value}
                {...props}>
                { value ?
                    <Image base64={base64} value={value} height={height} width={width} asUrl={asUrl}>
                        <Hover>
                            <DeleteButton type='button' onClick={this.removeFile}>
                                <DeleteOutlined />
                            </DeleteButton>
                        </Hover>
                    </Image> :
                    <AddBlock width={width} height={height}>
                        <PlusOutlined />
                        <div><span className='hide-mobile'>Добавить</span></div>
                    </AddBlock>
                }
            </Upload>
        </Wrapper>;
    }
}

export default withFieldWrapper(ImageUpload);
