import React from 'react';

import BaseFilter from './BaseFilter';
import {  getCompanyEmployees } from '../../../actions/asyncActions';
import withFilterForm from '../../hocs/withFilterForm';

export default withFilterForm(() =>
    <BaseFilter
        action={getCompanyEmployees}
        searchFieldName='text'
        searchFieldPlaceholder='Поиск'
        span={12}>
    </BaseFilter>
);
