import React, { Component } from 'react';
import { Button } from 'antd';
import PptxGenJS from 'pptxgenjs';
import { withAsyncActions } from 'react-async-client';
import { find, propEq, pathOr, filter, path, prop, groupWith, toPairs, times, forEachObjIndexed, flatten } from 'ramda';
import { pluralize } from 'numeralize-ru';
import gaussian from 'gaussian';
import { FilePptOutlined } from '@ant-design/icons';

import { PROJECT_RESPONDENT_STATUSES, INTEGRAL_RESULT } from '../../../constants/companies';
import { getProjectAnalyticsPercents, getProjectAnalyticsDistribution, getProjectAnalyticsStatus, getProjectStatCompetencies, getProjectStatIntegral } from '../../../actions/asyncActions';
import whiteLogo from '../../../assets/img/presentation/white.png';
import redLogo from '../../../assets/img/presentation/red.png';

const chartTitleOpts = { x: .3, y: .85, fontSize: 15 };

class ProjectAnalyticsTabButtons extends Component {
    generateAnalyticsStatusPpt = pptx => {
        const { data } = this.props.getProjectAnalyticsStatus;
        const statuses = filter(({ id }) => !!path(['count'], find(propEq('status', id), data)), PROJECT_RESPONDENT_STATUSES);
        const respondents = data.reduce((res, cur) => res + cur.count, 0);
        const title = `По статусам (${respondents} ${pluralize(respondents, 'респондент', 'респондента', 'респондентов')})`;

        pptx.addSlide('CHART')
            .addText(title, chartTitleOpts)
            .addChart(
                pptx.charts.PIE,
                [{
                    labels: statuses.map(prop('value')),
                    values: statuses.map(({ id }) => pathOr(0, ['count'], find(propEq('status', id), data)))
                }],
                {
                    x: 0,
                    y: 1.1,
                    w: '100%',
                    h: '80%',
                    chartColors: statuses.map(prop('ppxColor')),
                    showPercent: true,
                    showLegend: true,
                    legendPos: 'b'
                }
            );
    }

    countDistributionRespondents = () => {
        const { data } = this.props.getProjectAnalyticsDistribution;
        let count = 0

        forEachObjIndexed(value => {
            count = count + value;
        }, data);

        return count;
    }

    getNormalDistributionData = () => {
        let data = [];
        var countDistributionRespondents = this.countDistributionRespondents();

        if (!countDistributionRespondents) {
            return data;
        }

        var left = Math.round(this.props.getProjectAnalyticsPercents.data.percents / 10 * 2) / 2;
        times(function(x) {
            data.push(gaussian(left, 1).pdf(x / 2) * countDistributionRespondents);
        }, 20);

        return data;
    }

    generateAnalyticsDistributionPpt = pptx => {
        const { getProjectAnalyticsDistribution } = this.props;

        pptx.addSlide('CHART')
            .addText('Распределение процентов прохождения', chartTitleOpts)
            .addChart([
                {
                    type: pptx.charts.BAR,
                    data: [{
                        name: 'Количество респондентов',
                        labels: flatten(toPairs(getProjectAnalyticsDistribution.data).map(([key]) => [`${key.replace('_', '-')}%`, null])),
                        values: flatten(toPairs(getProjectAnalyticsDistribution.data).map(([_, value]) => [value, null]))
                    }],
                    options: {
                        chartColors: ['7BB5EC'],
                        showValue: true
                    }
                }, {
                    type: pptx.charts.LINE,
                    data: [{
                        name: 'Нормальное распределение',
                        labels: [],
                        values: this.getNormalDistributionData()
                    }],
                    options: {
                        chartColors: ['000000'],
                        lineSmooth: true,
                        lineDataSymbol: 'none'
                    }
                }
            ], {
                x: 0,
                y: 1.2,
                w: '100%',
                h: '75%',
                legendPos: 'b',
                showLegend: true,
                showLabel: true,
                valAxisTitle: 'Респонденты',
                showValAxisTitle: true,
                catAxisTitle: 'Проценты',
                showCatAxisTitle: true,
                valAxisMajorUnit: 1,
                valAxisMinVal: 0,
                catAxisLabelFontSize: 8
            });
    }

    generateStatCompetenciesPpt = pptx => {
        const data = groupWith((a, b) => a.category.name === b.category.name, this.props.getProjectStatCompetencies.data).map(i => i.reduce((res, cur) => {
            return {
                category: cur.category.name,
                items: i
            };
        }, {}));

        pptx.addSlide('CHART')
            .addText('По компетенциям', chartTitleOpts)
            .addChart(
                pptx.charts.BAR,
                [{
                    name: '0',
                    labels: data.map(prop('category')),
                    values: data.map(item => path(['count'], find(propEq('level', 0), item.items)))
                }, {
                    name: '1',
                    labels: data.map(prop('category')),
                    values: data.map(item => path(['count'], find(propEq('level', 1), item.items)))
                }, {
                    name: '2',
                    labels: data.map(prop('category')),
                    values: data.map(item => path(['count'], find(propEq('level', 2), item.items)))
                }, {
                    name: '3',
                    labels: data.map(prop('category')),
                    values: data.map(item => path(['count'], find(propEq('level', 3), item.items)))
                }],
                {
                    x: 0,
                    y: 1.2,
                    w: '100%',
                    h: '75%',
                    chartColors: ['B4B4B4', '5cb85c', 'ED9D29', 'd9534f'],
                    barGrouping: 'stacked',
                    legendPos: 'b',
                    showLegend: true,
                    showValue: true,
                    valAxisTitle: 'Количество респондентов',
                    showValAxisTitle: true,
                    valAxisMajorUnit: 1,
                    catAxisLabelFontSize: 10
                }
            );
    }

    generateStatIntegralPpt = pptx => {
        const { data } = this.props.getProjectStatIntegral;

        pptx.addSlide('CHART')
            .addText('Общие результаты', chartTitleOpts)
            .addChart(
                pptx.charts.PIE,
                [{
                    labels: data.map(item => find(propEq('from', item._id), INTEGRAL_RESULT).tooltip),
                    values: data.map(prop('count'))
                }],
                {
                    x: 0,
                    y: 1.1,
                    w: '100%',
                    h: '80%',
                    chartColors: data.map(item => find(propEq('from', item._id), INTEGRAL_RESULT).color.replace(/#/g, '')),
                    showLegend: true,
                    showPercent: true,
                    legendPos: 'b'
                }
            );
    }

    generatePpt = () => {
        const { getProjectAnalyticsStatus, getCompanyProject, getProjectStatIntegral, getProjectStatCompetencies } = this.props;
        const pptx = new PptxGenJS();

        pptx.defineSlideMaster({
            title: 'MAIN',
            bkgd: 'ffffff',
            objects: [
                { rect: { x: 0, y: 0, w: '100%', h: '50%', fill: 'f54d2e' }},
                { image: { data: whiteLogo, w: 2.7, h: .8, y: '17%', x: '36.5%' }},
                { text: { text: getCompanyProject.data.name, options: { align: 'center', valign: 'middle', color: 'f54d2e', x: 0, y: '50%', w: '100%', h: '50%', fontSize: 40 }}}
            ]
        });
        pptx.defineSlideMaster({
            title: 'CHART',
            bkgd: 'ffffff',
            objects: [
                { rect: { x: .4, y: .2, w: .5, h: .08, fill: 'f54d2e' }},
                { image: { data: redLogo, w: 1.8, h: 0.54, y: .3, x: '80%' }},
                { text: { text: getCompanyProject.data.name, options: { x: .29, y: .4, fontSize: 36 }}}
            ]
        });

        pptx.addSlide('MAIN');

        if (getProjectAnalyticsStatus.data.length) {
            this.generateAnalyticsStatusPpt(pptx);
            this.generateAnalyticsDistributionPpt(pptx);
        }
        getProjectStatCompetencies.data.length && this.generateStatCompetenciesPpt(pptx);
        getProjectStatIntegral.data.length && this.generateStatIntegralPpt(pptx);

        pptx.writeFile(getCompanyProject.data.name);
    }

    disableDownload = () => {
        const { getProjectAnalyticsStatus, getProjectStatIntegral, getProjectStatCompetencies } = this.props;

        return !getProjectAnalyticsStatus.data.length && !getProjectStatIntegral.data.length && !getProjectStatCompetencies.data.length;
    }

    render() {
        const { getProjectAnalyticsStatus, getProjectStatIntegral, getProjectAnalyticsDistribution, getProjectStatCompetencies } = this.props;

        return <Button
            type='primary'
            icon={<FilePptOutlined />}
            onClick={this.generatePpt}
            disabled={this.disableDownload()}
            loading={
                getProjectAnalyticsStatus.meta.pending ||
                getProjectStatIntegral.meta.pending ||
                getProjectAnalyticsDistribution.meta.pending ||
                getProjectStatCompetencies.meta.pending
            }>
            Скачать
        </Button>;
    }
}

export default withAsyncActions({
    getProjectAnalyticsPercents,
    getProjectAnalyticsDistribution,
    getProjectAnalyticsStatus,
    getProjectStatCompetencies,
    getProjectStatIntegral
})(ProjectAnalyticsTabButtons);
