import React, { Component } from 'react';

import Modal from './Modal';
import CompanyDetailsForm from '../forms/CompanyDetailsForm';
import { withAsyncActions } from 'react-async-client';
import { postBankDetails } from '../../actions/asyncActions';
import { message } from 'antd';

class CompanyBankDetailsModal extends Component {
    render() {
        const { modal, postBankDetails, params } = this.props;

        return <Modal
            {...modal}
            title='Реквизиты компании'
            footer={null}>
            <CompanyDetailsForm
                formAction={postBankDetails}
                item={params.data}
                company={{ id: params.company }}
                hideCancel />
        </Modal>;
    }
}

export default withAsyncActions({
    postBankDetails: postBankDetails
        .withSuccessHandler(({ close }) => {
            message.success('Реквизиты успешно сохранены');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось сохранить реквизиты компании'))
        .withOptions({ resetOnUnmount: true })
})(CompanyBankDetailsModal);
