import React, { Component, Fragment } from 'react';
import { Button, Alert, Table, Select, Tooltip } from 'antd';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { pathOr, flatten, path, find, propEq, findIndex, update, append, remove, filter, contains, forEach, fromPairs } from 'ramda';
import * as yup from 'yup';
import { FormSpy } from 'react-final-form';
import { SettingOutlined } from '@ant-design/icons';

import withFormWrapper from '../../hocs/withFormWrapper';
import SubmitButton from '../formComponents/SubmitButton';
import SpanAsLink from '../../user/table/SpanAsLink';
import ProjectCategorySelect from './ProjectCategorySelect';
import { openCategoriesSettingsInfoModal, openProjectQuestionsSettingsModal, openComplexityDistributionSettingsModal } from '../../../actions/modalActions';
import { getCategoriesTree } from '../../../utils/categories';
import Complexity from '../../user/table/Complexity';
import ListenerField from '../ListenerField';
import { getExecutionTime } from '../../../utils/time';
import { withAsyncActions } from 'react-async-client';
import { getComplexity, getUser, getCategoryDistribution } from '../../../actions/asyncActions';
import { ADMIN } from '../../../constants/roles';
import { TEST_QUESTIONS_TIME } from '../../../constants/tests';

const Content = styled.div`
    margin: 15px 0;
`;

const ComplexityButtonWrapper = styled.div`
    text-align: right;
`;

const TestTitle = styled.span`
    color: ${({ error }) => error ? '#f54d2e' : '#000'};
`;

const SettingsButton = styled(Button)`
    color: ${({ selected }) => selected ? '#f54d2e' : '#dcdcdc'};

    &:hover, &:active, &:focus {
        color: #000;
    }
`;

class TimeComponent extends Component {
    getDistribution = distributions => {
        const data = {};

        forEach(item => {
            data[item.level] = fromPairs(item.proportions.map(({ level, count }) => ([ level, count ])));
        }, distributions);

        return data;
    }

    getTime = () => {
        const { test, getComplexity, item } = this.props;

        const time = (test.categorySelections || []).reduce((res, cur, index) => {
            const distributions = pathOr([], [`getCategoryDistribution${index}`, 'data', 'distributions'], this.props);
            const projectComplexitySettings = item.testsTimeSettings.complexitiesSettings || [];
            const settings = (projectComplexitySettings.length ? projectComplexitySettings : getComplexity.data.items) || [];
            const time = settings.reduce((res, set) => pathOr(0, [cur.level, set.level], this.getDistribution(distributions)) * set.executionTime + res, 0)

            return res + time;
        }, 0);

        return time;
    }

    render() {
        const { pristine, test } = this.props;
        const time = this.getTime();

        return getExecutionTime(pristine ? test.executionTime : time);
    }
}

const Time = withAsyncActions(props =>
    fromPairs(pathOr([], ['_embedded', 'categories'], props.item).map((category, index) => ([
        `getCategoryDistribution${index}`,
        getCategoryDistribution
            .withParams(() => ({ category: category.id }))
            .withPayload(() => category.id)
            .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
    ])))
)(TimeComponent);

class ProjectCategoryLevelStep extends Component {
    componentDidMount() {
        this.props.getRef && this.props.getRef(() => this);
    }

    getLevel = (category) => {
        const level = path(['level'], category);
        return level ? String(level) : undefined;
    }

    getCategory = (categorySelections, category) => {
        return find(propEq('category', category), categorySelections);
    }

    onChange = (level, category, index) => {
        const { form, item } = this.props;
        const { items } = form.getState().values;
        const { categorySelections } = items[index];
        const categoryIndex = findIndex(propEq('category', category), categorySelections);

        const categories = categoryIndex > -1 ? (
            level ? categorySelections.map((item, i) => i === categoryIndex ? ({ ...item, level }) : item) : remove(categoryIndex, 1, categorySelections)
        ) : (
            level ? append({
                category,
                level,
                id: item.id
            }, categorySelections) : categorySelections
        );

        form.change('items', update(index, {...items[index], categorySelections: categories}, items));
    }

    getColumns = items => {
        const { testTemplate, submitFailed, getComplexity, openComplexityDistributionSettingsModal } = this.props;

        const tests = items.map((test, index) => ({
            key: `${test.id}-level`,
            title: <FormSpy subscription={{ errors: true }}>
                { ({ errors }) =>
                    <TestTitle
                        error={path(['_arrays', 'items', index, 'categorySelections'], errors) && submitFailed}>
                        { test.name }
                    </TestTitle>
                }
            </FormSpy>,
            render: item => {
                if (item.result) {
                    const time = (test.categorySelections || []).reduce((res, cur) => {
                        const projectComplexitySettings = this.props.item.testsTimeSettings.complexitiesSettings || [];
                        const settings = (projectComplexitySettings.length ? projectComplexitySettings : getComplexity.data.items) || [];
                        const time = settings.reduce((res, set) => pathOr(0, [cur.level, set.level], TEST_QUESTIONS_TIME) * set.executionTime + res, 0)

                        return res + time;
                    }, 0);

                    return <FormSpy subscription={{ pristine: true }}>
                        { ({ pristine }) => this.props.item.type === 'competence' ?
                            <Time item={this.props.item} test={test} pristine={pristine} getComplexity={getComplexity} /> :
                            getExecutionTime(pristine ? test.executionTime : time)
                        }
                    </FormSpy>;
                }

                const levels = filter(l => contains(l, item.availableCompetenceLevels), [1, 2, 3]);
                const category = this.getCategory(test.categorySelections, item.id);
                const value = this.getLevel(category);
                const isSent = test.status === 'sent';
                const notExistedValue = !contains(Number(value), levels) && value;

                return item.parent && (
                    <Fragment>
                        <Tooltip title={isSent ?
                                'Тест уже выслан респондентам' : testTemplate ?
                                'Готовый тест нельзя редактировать' : null}>
                            <Select
                                style={{ width: 60 }}
                                value={notExistedValue ? <Complexity level={Number(value)} /> : value}
                                dropdownMatchSelectWidth={!!levels.length}
                                onChange={value => this.onChange(value, item.id, index)}
                                disabled={isSent || testTemplate}
                                allowClear>
                                { levels.map(level =>
                                    <Select.Option value={level.toString()} key={`${item.id}-level-${level}`}>
                                        <Complexity level={level} />
                                    </Select.Option>
                                )}
                            </Select>
                        </Tooltip>
                        <SettingsButton
                            type='link'
                            selected={!!path(['categoryDistributionSettings', 'length'], category)}
                            icon={<SettingOutlined />}
                            onClick={() => openComplexityDistributionSettingsModal({
                                item: category,
                                category: item,
                                project: this.props.item,
                                levels,
                                test,
                                value,
                            })}
                        />
                    </Fragment>
                );
            }
        }));

        return [
            {
                key: 'name',
                dataIndex: 'name',
                render: (name, { code, parent, result }) => result
                    ? <strong>Итоговое время</strong>
                    : parent
                        ? `${name}${code ? ` [${code}]`: ''}`
                        : <strong>{ name }</strong>
            },
            ...tests
        ];
    }

    renderCategories = categories => {
        const { testSuitesMeta, itemMeta } = this.props;
        const grouped = flatten(getCategoriesTree(categories).map(item => ([item, ...item.items])));

        return <ListenerField listenFieldName='items'>
            { ({ items }) =>
                <Table
                    style={{ marginTop: 15 }}
                    columns={this.getColumns(items)}
                    loading={testSuitesMeta.pending || itemMeta.pending}
                    dataSource={[...grouped, { result: true, id: 'result' }]}
                    pagination={false}
                    rowKey='id'
                    size='small' />
            }
        </ListenerField>;
    }

    render() {
        const { openCategoriesSettingsInfoModal, item, setNext, submitBtn, edit, testTemplate, goPrev, goNext, openProjectQuestionsSettingsModal, isAdmin, submitFailed } = this.props;
        const categories = pathOr([], ['_embedded', 'categories'], item);

        return <Fragment>
            <FormSpy subscription={{ errors: true }}>
                { ({ errors }) =>
                    !!pathOr([], ['_arrays', 'items'], errors).length && submitFailed &&
                        <Alert type='error' message={'Необходимо выбрать как минимум одну сложность для компетенции'} style={{ marginBottom: 15 }} />
                }
            </FormSpy>
            { edit && !testTemplate &&
                <Alert type='info' message={<span>Выберите компетенции из межотраслевой модели профессиональных компетенций, необходимые для оценки респондентов. В момент выбора помните о временном критерии: чем больше компетенций, тем больше времени требуется на их проверку. Рекомендуемая длительность процедуры одного сеанса тестирования составляет не более 1,5 часов. <SpanAsLink onClick={() => openCategoriesSettingsInfoModal()}>Подробнее</SpanAsLink></span>} />
            }
            { !edit && isAdmin &&
                <ComplexityButtonWrapper>
                    <Button icon={<SettingOutlined />} onClick={() => openProjectQuestionsSettingsModal(item)}>
                        Настройки сложностей
                    </Button>
                </ComplexityButtonWrapper>
            }
            <Content>
                { edit && !testTemplate && <ProjectCategorySelect item={item} complete onlyOwn={false} /> }
                { !!categories.length && this.renderCategories(categories) }
            </Content>
            { submitBtn ||
                ( testTemplate ?
                    <Button.Group>
                        <Button onClick={goPrev}>Назад</Button>
                        <Button type='primary' onClick={goNext}>Далее</Button>
                    </Button.Group> :
                    <Button.Group>
                        <Button onClick={goPrev}>Назад</Button>
                        <SubmitButton type='primary' onClick={() => setNext(true)}>Далее</SubmitButton>
                    </Button.Group>
                )
            }
        </Fragment>;
    }
}

const stateToProps = state => ({
    isAdmin: getUser.selectData(state).role === ADMIN
});

const validationSchema = yup.object().shape({
    items: yup.array().of(yup.object().shape({
        categorySelections: yup.array().required().min(1)
    }))
});

export default withAsyncActions(({
    getComplexity: getComplexity
        .withOptions({ dispatchOnMount: true }),
}))(
    withFormWrapper(
        connect(stateToProps, {
            openCategoriesSettingsInfoModal,
            openProjectQuestionsSettingsModal,
            openComplexityDistributionSettingsModal
        })(ProjectCategoryLevelStep),
        {
            mapPropsToValues: ({ item, testSuites }) => ({
                company: item.company,
                project: item.id,
                items: testSuites
            }),
            validationSchema,
            mapBeforeSubmit: values => ({
                ...values,
                items: filter(({ status }) => status !== 'sent', values.items)
            })
        }
    )
);
