import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { pathOr } from 'ramda';

import withFormWrapper from '../../hocs/withFormWrapper';
import SubmitButton from '../formComponents/SubmitButton';
import CssEditor from '../formComponents/CssEditor';

class SurveyExtendedForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='settings.theme.css'
                component={CssEditor}
                label='Стили' />
            <SubmitButton type='primary' style={{ marginTop: 15 }}>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

export default withFormWrapper(SurveyExtendedForm, {
    mapPropsToValues: ({ data }) => ({
        ...data,
        settings: {
            ...data.settings,
            theme: {
                ...pathOr({}, ['settings', 'theme'], data),
            }
        }
    }),
});
