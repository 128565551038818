import React, { Component, Fragment } from 'react';
import { prop } from 'ramda';
import * as yup from 'yup';
import { Field } from 'react-final-form';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import { PAYMENT_TYPES } from '../../constants/companies';
import Select from './formComponents/Select';

class CompanyPaymentTypeForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='paymentType'
                component={Select}
                label='Тип оплаты'
                options={PAYMENT_TYPES} />
            <SubmitButton type='primary'>Сохранить</SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    paymentType: yup.string().required()
});

export default withFormWrapper(CompanyPaymentTypeForm, {
    validationSchema,
    mapPropsToValues: prop('item')
});
