export default {
    mixed: {
        required: 'Это поле обязательно'
    },
    string: {
        email: 'Неверный формат email'
    },
    number: {
        // eslint-disable-next-line
        min: 'Минимум - ${min}',
        // eslint-disable-next-line
        max: 'Максимум - ${max}'
    }
};
