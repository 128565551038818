import React, { Component } from 'react';
import { Row, Col, Checkbox } from 'antd';
import styled from 'styled-components';

import withFieldWrapper from '../../hocs/withFieldWrapper';

const Card = styled.div`
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    border-color: ${({ checked }) => checked ? '#f54d2e' : '#e8e8e8'};
    .ant-tag {
        position: absolute;
        right: 0;
        top: -25px;
        margin: 4px;
    }
    margin-bottom: 10px;
`;

const CardHeader = styled.div`
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #e8e8e8;
    background: #fafafa;
    color: #2B3D4F;
    min-height: 32px;
    font-weight: 600;
    .ant-checkbox-wrapper {
        margin-right: 10px;
    }
`;

const CardBody = styled.div`
    padding: 10px;
    min-height: 125px;
`;

class TypeFieldComponent extends Component {
    render() {
        const { input: { value, onChange }, options, getOptionsDescription } = this.props;

        return <Row gutter={8}>
            { options && options.length ? options.map(option => (
                <Col key={option.id} span={6}>
                    <Card onClick={() => onChange(option.id)} checked={value === option.id}>
                        <div>
                            <CardHeader>
                                <Checkbox checked={value === option.id} onChange={() => onChange(option.id)} /> <span>{option.name}</span>
                            </CardHeader>
                            <CardBody>{ (getOptionsDescription && getOptionsDescription(option.id, option)) || option.value }</CardBody>
                        </div>
                    </Card>
                </Col>
            )) : null}
        </Row>;
    }
}

const TypeField = withFieldWrapper(TypeFieldComponent);

export default TypeField;
