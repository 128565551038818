import React, { Component } from 'react';
import { Button, Spin } from 'antd';
import styled from 'styled-components';
import { findIndex, path, propEq } from 'ramda';
import { withRouter } from 'react-router';

import QuestionPreviewPage from './companyDesignPageModal/QuestionPreviewPage';
import Modal from './Modal';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { getFilters } from '../../utils/urlParams';
import { withAsyncActions } from 'react-async-client';
import { getQuestions } from '../../actions/asyncActions';
import { withStateHandlers } from 'recompose';

const StyledModal = styled(Modal)`
    top: 0;
    padding-bottom: 0;
    .ant-modal-body {
        padding: 0;
        height: calc(100vh - 53px);
        overflow-y: auto;
        background: #FCFCFC;
    }
    .ant-modal-content {
        border-radius: 0;
    }
`;

const PrevButton = styled(Button)`
    position: absolute;
    left: 15px;
    top: calc(50% - 16px);
    z-index: 1;
`;

const NextButton = styled(Button)`
    position: absolute;
    right: 15px;
    top: calc(50% - 16px);
    z-index: 1;
`;

const QuestionsPaginationComponent = ({ children, question, prev, next, count, offset, getQuestions }) => {
    return <div>
        { offset > 0 &&
            <PrevButton
                icon={<ArrowLeftOutlined />}
                onClick={prev} />
        }
        <Spin spinning={getQuestions.meta.pending}>
            { children(question) }
        </Spin>
        { offset < count - 1 &&
            <NextButton
                icon={<ArrowRightOutlined />}
                onClick={next} />
        }
    </div>;
}

const QuestionsPagination = withStateHandlers(props => ({
    offset: props.page,
    question: props.question
}), {
    next: ({ offset }) => () => ({ offset: offset + 1 }),
    prev: ({ offset }) => () => ({ offset: offset - 1 }),
    setQuestion: () => question => ({ question })
})(withAsyncActions({
    getQuestions: getQuestions
        .withParams({ type: 'pagination' })
        .withPayload(({ offset, filters }) => ({
            limit: 1,
            offset,
            q: filters,
        }))
        .withSuccessHandler(({ setQuestion, getQuestions }) => {
            setQuestion(path(['data', 'items', 0], getQuestions))
        })
        .withOptions({ resetOnUnmount: true, dispatchOnUpdate: true })
})(QuestionsPaginationComponent));

class QuestionPreviewModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            question: props.params.item
        };
    }

    onChangePage = num => {
        this.setState({ question: this.getQuestions()[num - 1] });
    }

    getQuestions = question => question ? [question] : (this.props.params.questions || [this.props.params.item]);

    renderQuestionPreviewPage = item => {
        const question = item || this.state.question;
        const questions = this.getQuestions(question);
        const num = findIndex(propEq('id', question.id), questions);

        return <QuestionPreviewPage
            question={question}
            onChangePage={this.onChangePage}
            num={num}
            questionsCount={questions.length}
            key={question.id} />;
    }

    render() {
        const { modal, close, params, location } = this.props;
        const { question } = this.state;
        const filters = getFilters(location) || {};

        return <Modal
            {...modal}
            footer={<Button onClick={close}>Закрыть</Button>}
            width='100%'
            closable={false}
            ModalComponent={StyledModal}>
            { params.pagination ?
                <QuestionsPagination
                    question={question}
                    page={params.offset}
                    count={params.count}
                    filters={filters}>
                    { item => this.renderQuestionPreviewPage(item) }
                </QuestionsPagination> :
                this.renderQuestionPreviewPage()
            }
        </Modal>;
    }
}

export default withRouter(QuestionPreviewModal);
