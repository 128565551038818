import React, { Component, Fragment } from 'react';
import { find, propEq, equals, pathOr, keys } from 'ramda';
import { Tag, message, Button, Tooltip, Dropdown, Menu } from 'antd';
import { asyncConnect } from 'react-async-client';
import { withState } from 'recompose';
import styled from 'styled-components';
import qs from 'qs';
import { Link } from 'react-router-dom';
import { DownOutlined, PlusOutlined, MailOutlined, FileExcelOutlined } from '@ant-design/icons';

import TableList from '../table/TableList';
import { deleteSurveyRespondents, getSurveyRespondents, getUser } from '../../../actions/asyncActions';
import { openSurveyRespondentLetterModal, openAddSurveyRespondentModal } from '../../../actions/modalActions';
import DateFormat from '../table/DateFormat';
import { PROJECT_RESPONDENT_STATUSES, PROJECT_RESPONDENT_EMPLOYEE } from '../../../constants/companies';
import SurveyRespondentsFilter from '../../forms/filters/SurveyRespondentsFilter';
import { getUrlParams, getFilters, getSorting } from '../../../utils/urlParams';
import {
    POST_SURVEY_RESPONDENT,
    POST_SURVEY_RESPONDENTS,
    PUT_SURVEY_RESPONDENT,
    PATCH_SURVEY_RESPONDENT,
    PATCH_SURVEY_RESPONDENTS,
    DELETE_SURVEY_RESPONDENT,
    DELETE_SURVEY_RESPONDENTS,
    POST_SURVEY_COMPANY_MESSAGES,
} from '../../../constants/actionTypes';
import { getToken } from '../../../utils/token';
import { getTreeRespondents } from '../../../utils/companies';
import { ADMIN } from '../../../constants/roles';
import Contacts from '../table/Contacts';

const TableWrapper = styled.div`
    .ant-table-row-expand-icon {
        display: none;
    }
    .table-subtitle {
        background: rgba(0, 0, 0, 0.01);
        .ant-table-selection-column {
            display: none;
        }
        td {
            padding: 5px 16px;
        }
    }
    .ant-table-row-indent {
        padding: 0!important;
    }
`;

const scoreLinks = {
    'driver_plus_ipsative': 'reports/driver_plus_ipsative_raw_answers',
};

class SurveyRespondentsTabButtonsComponent extends Component {
    getSearchPath = () => {
        const { location } = this.props;

        return qs.stringify({
            ...getSorting(location),
            q: JSON.stringify({
                ...getFilters(location),
            }),
            access_token: getToken()
        }, { addQueryPrefix: true, strictNullHandling: true });
    }

    getGroups = () => {
        return keys(this.props.survey.groups || {}).map(value => ({ id: value, value }));
    }

    getReportTypes = () => {
        return this.props.survey.availableReports || [];
    }

    getDownloadResponseLink = (reportType) => {
        const { match: { params: { id } } } = this.props;

        return `/api/survey/${scoreLinks[reportType] || 'surveys'}/${id}.xlsx${this.getSearchPath()}`;
    }

    getDownloadScoreLink = (reportType) => {
        const { match: { params: { id } } } = this.props;

        return `/api/survey/reports/${reportType}_raw/${id}.xlsx${this.getSearchPath()}`;
    }

    getDownloadReportLink = (reportType) => {
        const { match: { params: { id } } } = this.props;

        return `/api/survey/reports/${reportType}/${id}.xlsx${this.getSearchPath()}`;
    }

    addRespondents = () => {
        const { openAddSurveyRespondentModal, survey } = this.props;

        openAddSurveyRespondentModal({
            item: { id: survey.id },
            company: survey.company,
            groups: this.getGroups(),
        });
    }

    renderSendOverlay = (reportTypes) => {
        return <Menu>
            { reportTypes.map(reportType => (
                <Menu.Item key={reportType}>
                    <a href={this.getDownloadScoreLink(reportType)} target="_blank" rel="noopener noreferrer" download>Матрица сырых баллов</a>
                </Menu.Item>
            ))}
            { reportTypes.length ? reportTypes.map(reportType => (
                <Menu.Item>
                    <a href={this.getDownloadResponseLink(reportType)} target="_blank" rel="noopener noreferrer" download>Матрица сырых ответов</a>
                </Menu.Item>
            )) : (
                <Menu.Item>
                    <a href={this.getDownloadResponseLink()} target="_blank" rel="noopener noreferrer" download>Матрица сырых ответов</a>
                </Menu.Item>
            )}
        </Menu>;
    }

    render() {
        const reportTypes = this.getReportTypes();

        return <Button.Group>
            <Button type='primary' icon={<PlusOutlined />} onClick={this.addRespondents}><span className='hide-mobile'>Добавить</span></Button>
            { reportTypes.map(reportType => (
                <Button key={reportType} icon={<FileExcelOutlined />} href={this.getDownloadReportLink(reportType)} target="_blank" rel="noopener noreferrer" download>
                    Выгрузить сводный отчет
                </Button>
            ))}
            { this.props.isAdmin && (
                <Dropdown overlay={this.renderSendOverlay(reportTypes)} trigger={['click']}>
                    <Button>Дополнительные отчеты <DownOutlined /></Button>
                </Dropdown>
            )}
        </Button.Group>
    }
}

export const SurveyRespondentsTabButtons = asyncConnect({}, null, { openSurveyRespondentLetterModal, openAddSurveyRespondentModal })(SurveyRespondentsTabButtonsComponent);

class SurveyRespondentsTab extends Component {
    componentDidUpdate(prev) {
        if (!equals(getUrlParams(this.props.location), getUrlParams(prev.location))) {
            this.clearSelected();
        }
    }

    getColumns = () => {
        const { survey } = this.props;

        return [
            {
                title: 'Респондент',
                key: 'lastName',
                sorter: true,
                filters: PROJECT_RESPONDENT_EMPLOYEE.map(i => ({ text: i.value, value: i.id })),
                filterMultiple: false,
                render: item => item.isSubtitle ?
                    ({ children: <strong>{ item.name }</strong>, props: { colSpan: 7 } }) :
                    <Fragment>
                        <div>
                            <Link to={`/surveys/${survey.id}/respondents/${item.id}`}>
                                { item.lastName } { item.firstName } { item.middleName }
                            </Link>
                        </div>
                        <div>{ item.employee ? 'Сотрудник' : '' }</div>
                    </Fragment>
            },
            {
                title: 'Контакты',
                dataIndex: 'email',
                key: 'email',
                render: (email, { phone, isSubtitle }) => isSubtitle ? ({ children: null, props: { colSpan: 0 }}) :
                    <Contacts email={email} phone={phone} />
            },
            {
                dataIndex: 'status',
                key: 'status',
                filters: PROJECT_RESPONDENT_STATUSES.map(i => ({ text: i.value, value: i.id })),
                filterMultiple: false,
                className: 'right-column',
                render: (status, { isSubtitle, createdAt, canceled }) => {
                    if (isSubtitle) {
                        return { children: null, props: { colSpan: 0 }};
                    }

                    const data = find(propEq('id', status), PROJECT_RESPONDENT_STATUSES);

                    return <Fragment>
                        { canceled ?
                            <Tooltip title={data.value}>
                                <Tag style={{ marginBottom: 5 }} color='red'>Отменен</Tag>
                            </Tooltip> :
                            (data ? <Tag style={{ marginBottom: 5 }} color={data.color}>{ data.value }</Tag> : null)
                        }
                        <div><DateFormat date={createdAt} /></div>
                    </Fragment>;
                }
            }
        ];
    }

    setSelected = selected => this.props.setSelected(selected);

    delete = () => {
        const { deleteSurveyRespondents, selected, survey } = this.props;

        deleteSurveyRespondents.dispatch({
            items: selected,
            survey: survey.id,
            company: survey.company,
        });
    }

    clearSelected = () => this.setSelected([]);

    openSendLetterModal = filtered => {
        const { selected, survey, openSurveyRespondentLetterModal, location, count } = this.props;

        openSurveyRespondentLetterModal({
            item: {
                respondentsFilter: filtered ? getFilters(location) || {} : ({
                    respondents: selected
                }),
                survey: survey.id,
                company: survey.company,
                type: "selected"
            },
            filtered,
            count,
            clearSelected: this.clearSelected
        });
    }

    getButtonsMenu = () => {
        return <Menu>
            <Menu.Item key='delete' onClick={this.delete}>
                Удалить
            </Menu.Item>
        </Menu>;
    }

    renderButtons = () => {
        const { selected, survey, count } = this.props;
        const respondentsCount = selected.length || count;

        return survey.id ?
            <Button.Group>
                <Button
                    icon={<MailOutlined />}
                    type='primary'
                    onClick={() => this.openSendLetterModal(!selected.length)}
                    disabled={!respondentsCount}>
                    <span className='hide-mobile'>Отправить письмо</span>&nbsp;{ respondentsCount ? `(${respondentsCount})` : '' }
                </Button>
                <Dropdown overlay={this.getButtonsMenu()} trigger={['click']} disabled={!selected.length}>
                    <Button icon={<DownOutlined />} />
                </Dropdown>
            </Button.Group> : null;
    }

    getExpanded = items => (items || []).map(({ id }) => id);

    showTests = () => pathOr([], ['testSuites'], this.props).length > 1;

    render() {
        const { survey, getCompanyTests } = this.props;
        const showTests = this.showTests();
        const props = showTests ? {
            parseItems: getTreeRespondents(getCompanyTests.data.items || []),
            getExpanded: this.getExpanded
        } : {};

        return survey.id ?
            <TableWrapper className='wrap-bg'>
                <TableList
                    action={getSurveyRespondents}
                    columns={this.getColumns()}
                    param={{ type: 'table' }}
                    payload={{ relations: ['employee', 'employee.businessUnit'] }}
                    filterForm={SurveyRespondentsFilter}
                    filterProps={{ showTests }}
                    rowClassName={item => item.isSubtitle && 'table-subtitle'}
                    staticFilter={{
                        survey: survey.id,
                    }}
                    refreshActions={[
                        POST_SURVEY_RESPONDENT,
                        POST_SURVEY_RESPONDENTS,
                        PATCH_SURVEY_RESPONDENT,
                        PATCH_SURVEY_RESPONDENTS,
                        PUT_SURVEY_RESPONDENT,
                        DELETE_SURVEY_RESPONDENT,
                        DELETE_SURVEY_RESPONDENTS,
                        POST_SURVEY_COMPANY_MESSAGES,
                    ]}
                    buttons={this.renderButtons()}
                    defaultSort={{
                        sort_by: 'createdAt',
                        sort_order: 'desc'
                    }}
                    rowSelection={{
                        selectedRowKeys: this.props.selected,
                        onChange: this.setSelected,
                        type: 'checkbox',
                        getCheckboxProps: item => ({
                            disabled: item.isSubtitle
                        })
                    }}
                    {...props} />
            </TableWrapper> : null;
    }
}

const stateToProps = state => ({
    items: getSurveyRespondents.withParams({ type: 'table' }).selectData(state).items || [],
    count: pathOr(0, ['_meta', 'count'], getSurveyRespondents.withParams({ type: 'table' }).selectData(state)),
    isAdmin: getUser.selectData(state).role === ADMIN
});

export default withState('selected', 'setSelected', [])(
    asyncConnect({
        deleteSurveyRespondents: deleteSurveyRespondents
            .withSuccessHandler(({ setSelected }) => {
                message.success('Респонденты успешно удалены');
                setSelected([]);
            })
            .withErrorHandler(() => message.error('Не удалось удалить респондентов'))
            .withOptions({ resetOnUnmount: true }),
    }, stateToProps, { openSurveyRespondentLetterModal })(SurveyRespondentsTab)
);
