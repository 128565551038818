import React from 'react';
import { Field } from 'react-final-form';
import { Col } from 'antd';

import BaseFilter from './BaseFilter';
import { getCompanyClients } from '../../../actions/asyncActions';
import withFilterForm from '../../hocs/withFilterForm';
import Select from '../formComponents/Select';
import { ENABLED } from '../../../constants/table';
import { COMPANY_CLIENT_ROLES } from '../../../constants/companies';

export default withFilterForm(() =>
    <BaseFilter
        action={getCompanyClients}
        searchFieldName='text'
        searchFieldPlaceholder='Поиск'
        span={8}>
        <Col span={8}>
            <Field
                name='roles'
                component={Select}
                options={COMPANY_CLIENT_ROLES}
                placeholder='Роль'
                isMulti />
        </Col>
        <Col span={8}>
            <Field
                name='enabled'
                component={Select}
                options={ENABLED}
                allowFalseValue />
        </Col>
    </BaseFilter>
);
