import React, { Component, Fragment } from 'react';
import { Tag, message, Button, Popconfirm } from 'antd';
import { find, propEq, equals } from 'ramda';
import { asyncConnect } from 'react-async-client';
import { withState } from 'recompose';
import { Link } from 'react-router-dom';
import styled, {css} from "styled-components";
import { QuestionCircleOutlined, ClockCircleOutlined, DeleteOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';

import TableList from '../table/TableList';
import { getTestTemplates, deleteTestTemplates } from '../../../actions/asyncActions';
import { openTestTemplateModal, openTestGroupsModal } from '../../../actions/modalActions';
import { TEST_STATUSES } from '../../../constants/tests';
import { getExecutionTime } from '../../../utils/time';
import TestsFilter from '../../forms/filters/TestsFilter';
import { DELETE_TEST_TEMPLATES, POST_TEST_TEMPLATE } from '../../../constants/actionTypes';
import { getUrlParams } from '../../../utils/urlParams';

const InfoBox = styled.span`
    font-weight: bold;
    ${p => p.purple && css`
        color: #2b3d4f;
    `}
    ${p => p.green && css`
        color: #2b3d4f;
    `}
    .anticon{
        margin-right: 5px;
    }
`;

class Tests extends Component {
    componentDidUpdate(prev) {
        if (!equals(getUrlParams(this.props.location), getUrlParams(prev.location))) {
            this.props.setSelected([]);
        }
    }

    getColumns = () => {
        return [
            {
                title: 'Название',
                key: 'name',
                dataIndex: 'name',
                render: (name, { id }) => <Link to={`/tests/${id}/info`}>{ name }</Link>
            },
            {
                key: 'industry',
                dataIndex: 'industry',
                render: (industry, item) =>
                    <Fragment>
                        <div>{ industry }</div>
                        <div>
                            <InfoBox purple>
                                <QuestionCircleOutlined /> { item.questions.length }
                            </InfoBox>
                            <InfoBox green>
                                <ClockCircleOutlined style={{ marginLeft: 8 }} /> { getExecutionTime(item.executionTime) }
                            </InfoBox>
                        </div>
                    </Fragment>
            },
            {
                key: 'status',
                dataIndex: 'status',
                className: 'right-column',
                filters: TEST_STATUSES.map(i => ({ text: i.value, value: i.id })),
                filterMultiple: false,
                render: status => {
                    const data = find(propEq('id', status), TEST_STATUSES);

                    return <Tag color={data.color}>{ data.value }</Tag>;
                }
            }
        ]
    };

    deleteTests = () => {
        this.props.deleteTestTemplates.dispatch(this.props.selected);
    }

    renderButtons = () => {
        const { selected, openTestTemplateModal, openTestGroupsModal } = this.props;

        return <Fragment>
            <Button.Group>
                <Button
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() => openTestTemplateModal()}>
                    <span className='hide-mobile'>Добавить</span>
                </Button>
                { selected.length ?
                    <Popconfirm
                        onConfirm={this.deleteTests}
                        title={`Вы уверены, что хотите удалить ${selected.length > 1 ? 'выбранные тесты' : 'выбранный тест'}?`}>
                        <Button
                            type='danger'
                            icon={<DeleteOutlined />}>
                            <span className='hide-mobile'>Удалить</span>
                        </Button>
                    </Popconfirm> :
                    <Button
                        type='danger'
                        icon={<DeleteOutlined />}
                        disabled>
                        <span className='hide-mobile'>Удалить</span>
                    </Button>
                }
            </Button.Group>
            <Button icon={<SettingOutlined />} shape='circle' onClick={() => openTestGroupsModal()} style={{ marginLeft: 10, paddingTop: 1 }} />
        </Fragment>;
    }

    setSelected = selected => this.props.setSelected(selected);

    render() {
        return <TableList
            action={getTestTemplates}
            columns={this.getColumns()}
            filterForm={TestsFilter}
            refreshActions={[DELETE_TEST_TEMPLATES, POST_TEST_TEMPLATE]}
            buttons={this.renderButtons()}
            rowSelection={{
                selectedRowKeys: this.props.selected,
                onChange: this.setSelected,
                type: 'checkbox'
            }} />
    }
}

export default withState('selected', 'setSelected', [])(
    asyncConnect({
        deleteTestTemplates: deleteTestTemplates
            .withSuccessHandler(({ setSelected, selected }) => {
                message.success(selected.length > 1 ? 'Тесты были успешно удалены' : 'Тест был успешно удален');
                setSelected([]);
            })
            .withErrorHandler(({ selected }) => message.error(selected.length > 1 ? 'Не удалось удалить тесты' : 'Не удалось удалить тест'))
            .withOptions({ resetOnUnmount: true })
    }, null, { openTestTemplateModal, openTestGroupsModal })(Tests)
);
