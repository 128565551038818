import React, { Component, Fragment } from 'react';
import { Tag, Button, message, Avatar } from 'antd';
import { find, propEq, has, equals } from 'ramda';
import { withState } from 'recompose';
import { asyncConnect } from 'react-async-client';
import { UserOutlined, CloseCircleOutlined, CheckCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { getCompanyClients } from '../../../actions/asyncActions';
import TableList from '../table/TableList';
import { COMPANY_CLIENT_ROLES } from '../../../constants/companies';
import DateFormat from '../table/DateFormat';
import SpanAsLink from '../table/SpanAsLink';
import TooltipParagraph from '../table/TooltipParagraph';
import CompanyClientsFilter from '../../forms/filters/CompanyClientsFilter';
import { PATCH_CLIENTS, POST_CLIENT, PUT_CLIENT } from '../../../constants/actionTypes';
import { getFilters, getUrlParams } from '../../../utils/urlParams';
import { patchClients } from '../../../actions/asyncActions';
import { openCompanyClientModal } from '../../../actions/modalActions';
import { getAvatar } from '../../../constants/urls';

class CompanyUsers extends Component {
    componentDidUpdate(prev) {
        if (!equals(getUrlParams(this.props.location), getUrlParams(prev.location))) {
            this.props.setSelected([]);
        }
    }

    getColumns = () => {
        return [
            {
                dataIndex: 'photo',
                key: 'photo',
                width: 50,
                render: photo => <Avatar src={getAvatar(photo)} icon={<UserOutlined />} />
            },
            {
                title: 'ФИО',
                dataIndex: 'fullName',
                key: 'lastName',
                sorter: true,
                render: (fullName, item) => <SpanAsLink onClick={() => this.props.openCompanyClientModal(item)}>{ fullName }</SpanAsLink>
            },
            {
                title: 'Контакты',
                dataIndex: 'email',
                key: 'email',
                render: (email, { phone }) =>
                    <Fragment>
                        <TooltipParagraph ellipsis>{ email }</TooltipParagraph>
                        { phone && <TooltipParagraph>{ phone }</TooltipParagraph> }
                    </Fragment>
            },
            {
                title: 'Должность',
                dataIndex: 'position',
                key: 'position'
            },
            {
                title: 'Дата добавления',
                dataIndex: 'createdAt',
                key: 'createdAt',
                sorter: true,
                render: date => <DateFormat date={date} />
            },
            {
                title: 'Дата отключения',
                dataIndex: 'expiredAt',
                key: 'expiredAt',
                sorter: true,
                render: (date, item) => (
                    <SpanAsLink onClick={() => this.props.openCompanyClientModal(item)}>
                        <DateFormat date={date} />
                    </SpanAsLink>
                )
            },
            {
                dataIndex: 'role',
                key: 'role',
                className: 'right-column',
                render: role => <Tag color="blue">{ find(propEq('id', role), COMPANY_CLIENT_ROLES).value }</Tag>
            }
        ];
    }

    renderButtons = () => {
        const { patchClients, location, selected, openCompanyClientModal, id } = this.props;
        const filters = getFilters(location) || {};
        const enabled = has('enabled', filters) ? filters.enabled : true;

        return <Button.Group>
            <Button
                icon={enabled ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                type={enabled ? 'danger' : 'default'}
                onClick={() => this.setEnabled(!enabled)}
                loading={patchClients.meta.pending}
                disabled={!selected.length}>
                    <span className='hide-mobile'>{ enabled ? 'Заблокировать' : 'Разблокировать'}</span>
            </Button>
            <Button icon={<PlusOutlined />} type='primary' onClick={() => openCompanyClientModal({ company: id })}>
                <span className='hide-mobile'>Добавить</span>
            </Button>
        </Button.Group>;
    }

    setEnabled = (enabled = true) => {
        this.props.patchClients.dispatch({
            items: this.props.selected,
            data: [{ op: 'replace', path: '/enabled', value: enabled }]
        });
    }

    setSelected = selected => this.props.setSelected(selected);

    render() {
        const { id } = this.props;

        return <TableList
            className='company-user'
            action={getCompanyClients}
            columns={this.getColumns()}
            payload={{ company: id }}
            filterForm={CompanyClientsFilter}
            initFilters={{ enabled: true }}
            buttons={this.renderButtons()}
            refreshActions={[PATCH_CLIENTS, POST_CLIENT, PUT_CLIENT]}
            rowSelection={{
                selectedRowKeys: this.props.selected,
                onChange: this.setSelected,
                type: 'checkbox'
            }} />;
    }
}

export default withState('selected', 'setSelected', [])(
    asyncConnect({
        patchClients: patchClients
            .withSuccessHandler(({ setSelected }) => {
                message.success('Состояние успешно изменено');
                setSelected([]);
            })
            .withErrorHandler(() => message.error('Не удалось изменить состояние'))
            .withOptions({ resetOnUnmount: true })
    }, null, { openCompanyClientModal })(CompanyUsers)
);
