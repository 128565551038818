import React, { Component } from 'react';
import { Switch } from 'antd';

import withFieldWrapper from '../../hocs/withFieldWrapper';

class SwitchComponent extends Component {
    renderSwitch() {
        const { onChange, input: { value }, disabled, text } = this.props;

        return <div style={{ display: 'flex', alignItems: 'center' }}>
            <Switch
                checked={!!value}
                onChange={onChange}
                disabled={disabled}
            /> <span style={{ marginLeft: 10 }}>{ text }</span>
        </div>;
    }

    render() {
        const { Wrapper } = this.props;

        return Wrapper ? <Wrapper {...this.props}>{ this.renderSwitch() }</Wrapper> : this.renderSwitch();
    }
}

export default withFieldWrapper(SwitchComponent);
