import React, { Component, Fragment } from 'react';
import { withAsyncActions } from 'react-async-client';
import { withState } from 'recompose';
import { path, pathOr, toPairs, find, propEq, times, forEachObjIndexed, groupWith } from 'ramda';
import { Select, Row, Col, Spin } from 'antd';
import styled from 'styled-components';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import gaussian from 'gaussian';

import { getProjectAnalyticsPercents, getProjectAnalyticsDistribution, getProjectAnalyticsStatus, getProjectStatCompetencies, getProjectStatIntegral } from '../../../actions/asyncActions';
import { PROJECT_RESPONDENT_STATUSES, INTEGRAL_RESULT } from '../../../constants/companies';
import { pluralize } from 'numeralize-ru';

const Wrapper = styled.div`
    padding: 15px 0;
`;

const Empty = styled.div`
    margin-top: 15px;
    text-align: center;
`;

const Title = styled.h3`
    border: 1px solid #eaeaea;
    background: #fafafa;
    margin: 15px 0 0;
    padding: 10px 15px;
`;

const RespondentsCount = styled.div`
    float: right;
    text-align: center;
    color: #a2a2a2;
`;

const HighchartsWrapper = styled.div`
    padding: 15px 0;
    border-left: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    background: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
`;

class ProjectAnalyticsTab extends Component {
    getPieOptions = data => {
        return {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: null,
            credits: {
                enabled: false
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                    },
                    showInLegend: true,
                    colors: data.map(item => path(['pieColor'], find(propEq('id', item.status), PROJECT_RESPONDENT_STATUSES)))
                }
            },
            series: [{
                name: 'Количество респондентов',
                colorByPoint: true,
                data: data.map(item => ({
                    name: path(['value'], find(propEq('id', item.status), PROJECT_RESPONDENT_STATUSES)),
                    y: item.count
                }))
            }]
        }
    }

    countDistributionRespondents = () => {
        const { data } = this.props.getProjectAnalyticsDistribution;
        let count = 0

        forEachObjIndexed(value => {
            count = count + value;
        }, data);

        return count;
    }

    getNormalDistributionData = () => {
        let data = [];
        var countDistributionRespondents = this.countDistributionRespondents();

        if (!countDistributionRespondents) {
            return data;
        }

        var left = Math.round(this.props.getProjectAnalyticsPercents.data.percents / 10 * 2) / 2;
        times(function(x) {
            data.push({
                x: x / 2,
                y: gaussian(left, 1).pdf(x / 2) * countDistributionRespondents
            });
        }, 20);

        return data;
    }

    getPercentsChartOptions = () => {
        const distribution = this.props.getProjectAnalyticsDistribution.data;

        return {
            chart: {
                type: 'column'
            },
            credits: {
                enabled: false
            },
            title: null,
            plotOptions: {
                column: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true
                    }
                },
                spline: {
                    marker: {
                        enabled: false,
                        states: {
                            hover: {
                                enabled: false
                            },
                            select: {
                                enabled: false
                            }
                        }
                    }
                }
            },
            tooltip: {
                formatter: function() {
                    if (this.series.name === 'Количество респондентов') {
                        return this.point.name + '%, ' + this.series.name + ': ' + this.point.y;
                    }

                    return false;
                }
            },
            series: [
                {
                    name: 'Количество респондентов',
                    type: 'column',
                    data: toPairs(distribution).map(([key, value], index) => ({
                        x: index,
                        y: value,
                        name: key.replace('_', '-')
                    }))
                },
                {
                    name: 'Нормальное распределение',
                    type: 'spline',
                    data: this.getNormalDistributionData()
                }
            ],
            xAxis: {
                title: {
                    text: 'Проценты'
                },
                labels: {
                    formatter: function() {
                        return distribution[this.value] ? distribution[this.value].name : '';
                    }
                }
            },
            yAxis: {
                title: {
                    text: 'Респонденты'
                },
                allowDecimals: false,
                labels: {
                    formatter: function() {
                        return this.value;
                    }
                }
            }
        }
    }

    getStatIntegralOptions = () => {
        const { data } = this.props.getProjectStatIntegral;

        return {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: null,
            credits: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true,
                    colors: data.map(item => path(['color'], find(propEq('from', item._id), INTEGRAL_RESULT)))
                }
            },
            series: [{
                name: 'Количество респондентов',
                colorByPoint: true,
                data: data.map(item => ({
                    name: path(['tooltip'], find(propEq('from', item._id), INTEGRAL_RESULT)),
                    y: item.count
                }))
            }]
        }
    }

    getStatCompetenciesOptions = () => {
        const data = groupWith((a, b) => a.category.name === b.category.name, this.props.getProjectStatCompetencies.data).map(i => i.reduce((res, cur) => {
            return {
                category: cur.category.name,
                items: i
            };
        }, {}));

        return {
            chart: {
                type: 'column'
            },
            credits: {
                enabled: false
            },
            title: null,
            xAxis: {
                categories: data.map(i => i.category)
            },
            yAxis: {
                allowDecimals: false,
                min: 0,
                title: {
                    text: 'Количество респондентов'
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: 'gray'
                    }
                }
            },
            tooltip: {
                pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
                shared: true
            },
            plotOptions: {
                column: {
                    stacking: 'normal'
                }
            },
            series: [{
                name: '3',
                data: data.map(i => pathOr(0, ['count'], find(propEq('level', 3), i.items))),
                color: '#f54d2e'
            },
            {
                name: '2',
                data: data.map(i => pathOr(0, ['count'], find(propEq('level', 2), i.items))),
                color: '#ED9D29'
            },
            {
                name: '1',
                data: data.map(i => pathOr(0, ['count'], find(propEq('level', 1), i.items))),
                color: '#5cb85c'
            },
            {
                name: '0',
                data: data.map(i => pathOr(0, ['count'], find(propEq('level', 0), i.items))),
                color: '#B4B4B4'
            }]
        };
    }

    getRespondentsCount = data => {
        return data.reduce((res, cur) => res + cur.count, 0);
    }

    renderPie = data => {
        const count = this.getRespondentsCount(data);

        return <Fragment>
            <Title>По статусам <RespondentsCount>{ count } { pluralize(count, 'респондент', 'респондента', 'респондентов') }</RespondentsCount></Title>
            <HighchartsWrapper>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={this.getPieOptions(data)}
                />
            </HighchartsWrapper>
        </Fragment>
    }

    renderPercentsChart = () => {
        return <Fragment>
            <Title>Распределение процентов прохождения</Title>
            <HighchartsWrapper>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={this.getPercentsChartOptions()}
                />
            </HighchartsWrapper>
        </Fragment>;
    }

    renderAnalytics = () => {
        const { getProjectAnalyticsStatus: { data, meta }} = this.props;

        return meta.pending ?
            <Spin /> : meta.lastSucceedAt && (
            !data.length ?
                <Empty>Ни один из респондентов не прошел тест</Empty> :
                <Row gutter={16}>
                    <Col span={12}>
                        { this.renderPie(data) }
                    </Col>
                    <Col span={12}>
                        { this.renderPercentsChart() }
                    </Col>
                </Row>
            );
    }

    renderStatCompetencies = () => {
        return <Fragment>
            <Title>По компетенциям</Title>
            <HighchartsWrapper>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={this.getStatCompetenciesOptions()}
                />
            </HighchartsWrapper>
        </Fragment>;
    }

    renderStatIntegral = () => {
        return <Fragment>
            <Title>Общие результаты</Title>
            <HighchartsWrapper>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={this.getStatIntegralOptions()}
                />
            </HighchartsWrapper>
        </Fragment>;
    }

    renderStats = () => {
        const { getProjectStatCompetencies, getProjectStatIntegral, project } = this.props;

        return <Row gutter={16}>
            { !!getProjectStatCompetencies.data.length && project.type === 'competence' &&
                <Col span={12}>
                    { this.renderStatCompetencies() }
                </Col>
            }
            { !!getProjectStatIntegral.data.length &&
                <Col span={12}>
                    { this.renderStatIntegral() }
                </Col>
            }
        </Row>;
    }

    setTest = value => this.props.setTest(value);

    render() {
        const { test, testSuites } = this.props;
        const tests = testSuites || [];

        return <Wrapper>
            { tests.length > 1 &&
                <Row>
                    <Col span={8}>
                        <Select
                            value={test}
                            onChange={this.setTest}
                            placeholder='Тест'
                            style={{ width: '100%' }}
                            allowClear>
                            { tests.map(test =>
                                <Select.Option value={test.id} key={test.id}>
                                    { test.name }
                                </Select.Option>
                            )}
                        </Select>
                    </Col>
                </Row>
            }
            { this.renderAnalytics() }
            { this.renderStats() }
        </Wrapper>;
    }
}

const getAnalyticsPayload = ({ test, id, company }) => ({
    company,
    id,
    q: {
        testSuite: test
    }
});

export default withState('test', 'setTest', undefined)(
    withAsyncActions({
        getProjectAnalyticsPercents: getProjectAnalyticsPercents
            .withPayload(getAnalyticsPayload)
            .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true }),
        getProjectAnalyticsDistribution: getProjectAnalyticsDistribution
            .withPayload(getAnalyticsPayload)
            .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true }),
        getProjectAnalyticsStatus: getProjectAnalyticsStatus
            .withPayload(getAnalyticsPayload)
            .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true }),
        getProjectStatCompetencies: getProjectStatCompetencies
            .withPayload(getAnalyticsPayload)
            .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true }),
        getProjectStatIntegral: getProjectStatIntegral
            .withPayload(getAnalyticsPayload)
            .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
    })(ProjectAnalyticsTab)
);
