import React from 'react';

import BaseFilter from './BaseFilter';
import { getTestTemplateAudits } from '../../../actions/asyncActions';
import withFilterForm from '../../hocs/withFilterForm';

export default withFilterForm(() =>
    <BaseFilter
        action={getTestTemplateAudits}
        searchFieldName='text'
        searchFieldPlaceholder='Поиск'
        span={12} />
);
