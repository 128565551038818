import React, {Component} from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

import Modal from './Modal';
import {prop} from "ramda";

const CategoryLevelBlock = styled.p`
    padding: 10px;
    background: ${prop('color')};
`;

export default class RespondentAnalyticsModal extends Component {
    render() {
        const { modal, close } = this.props;

        return <Modal
            {...modal}
            title='Описание сложностей'
            footer={<Button onClick={close}>Закрыть</Button>}
            closable={false}>
            <p>Результаты теста оцениваются по пятибальной шкале, где 5-й уровень – максимально высокая оценка. На шкале указаны уровни профессиональной квалификации с учетом различных типов проверки компетенции. Тесты для ключевой проверки позволяют определить уровень профессиональной квалификации по шкале от 0 до 5. Тесты для базовой проверки позволяют определить уровень экспертизы по шкале от 0 до 2, т.е. выявляют наличие или отсутствие базовых знаний в рамках данной компетенции:</p>
            <CategoryLevelBlock color='rgba(203,247,203,.66)'>Экспертный уровень: Участник владеет самыми сложными понятиями в данной области. Способен выполнять задачи любого уровня сложности.</CategoryLevelBlock>
            <CategoryLevelBlock color='rgba(252,255,197,.66)'>Продвинутый уровень: Респондент демонстрирует продвинутый уровень знаний, способен координировать и консультировать других по сложным вопросам в данной области.</CategoryLevelBlock>
            <CategoryLevelBlock color='rgba(255,101,0,.16)'>Средний уровень: Респондент демонстрирует четкое понимание темы, способен выполнять большинство задач/проектов в выбранной области. На начальном этапе работы ему может потребоваться практическое руководство и консультация по вопросам более продвинутого уровня.</CategoryLevelBlock>
            <CategoryLevelBlock color='rgba(251,155,155,.28)'>Базовый уровень: Респондент владеет основными понятиями темы, уровень знаний соответствует ознакомительному курсу. Возможно, ему потребуется дополнительное обучение и практика под руководством опытного специалиста для получения опыта и самостоятельного выполнения задач.</CategoryLevelBlock>
            <CategoryLevelBlock color='rgba(237,26,40,.2)'>Начальный уровень: Респондент демонстрирует только общее понимание темы. Скорее всего, ему потребуется пройти дополнительный курс или самостоятельно изучить область знаний, прежде чем он сможет выполнять какие-либо задачи.</CategoryLevelBlock>
        </Modal>;
    }
}
