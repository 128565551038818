import React from 'react';
import moment from 'moment';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import { CalendarOutlined } from '@ant-design/icons';

const DateBox = styled.span`
    font-size: 0.8rem;
`;

const DateFormat = ({ date, icon, tooltip, withTime }) =>
    date ?
        <Tooltip title={tooltip}>
            <DateBox className='date-box'>
                {icon || <CalendarOutlined />} &nbsp;{ moment(date).format('DD.MM.YYYY') }
                { withTime && <span> в { moment(date).format('HH:mm:ss') }</span> }
            </DateBox>
        </Tooltip> : null;

export default DateFormat;
