import React, { Component } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/theme-tomorrow';

import withFieldWrapper from '../../hocs/withFieldWrapper';

class CssEditor extends Component {
    render() {
        const { input: { name, value }, onChange } = this.props;

        return <AceEditor
            mode='css'
            theme='tomorrow'
            name={name}
            value={value}
            onChange={onChange}
            editorProps={{ $blockScrolling: true }}
            width='100%'
            height='300px'
            style={{
                border: '1px solid #d9d9d9',
                borderRadius: 4
            }}
            setOptions={{
                useWorker: false,
                tabSize: 4
            }} />
    }
}

export default withFieldWrapper(CssEditor);
