import React, { Component, Fragment } from 'react';
import { prop } from 'ramda';
import * as yup from 'yup';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import { Field } from 'react-final-form';
import Input from './formComponents/Input';
import TemplateEditor from './formComponents/TemplateEditor';

class CompanyTemplateForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='title'
                component={Input}
                label='Название шаблона' />
            <Field
                name='subject'
                component={Input}
                label='Тема письма' />
            <Field
                name='content'
                component={TemplateEditor}
                label='Текст письма' />
            <SubmitButton type='primary'>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    title: yup.string().required(),
    subject: yup.string().required(),
    content: yup.string().required()
});

export default withFormWrapper(CompanyTemplateForm, {
    mapPropsToValues: prop('item'),
    validationSchema
});
