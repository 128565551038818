import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { path, forEach, findIndex, propEq, assocPath, pathOr, reduce, contains, filter, is, find, sort, isNil } from 'ramda';
import { Tooltip, Badge, Alert, Tree } from 'antd';
import { QuestionCircleOutlined, CheckOutlined, CloseOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import Complexity from '../table/Complexity';
import { INCORRECT_ANSWER_OPTIONS } from '../../../constants/tests';
import { getExecutionTime } from '../../../utils/time';

const Answers = styled.div`
    background: #fff;
    overflow: hidden;
    h2 {
        padding: 10px 15px;
        color: #2b3d4f;
        background: #fafafa;
    }
`;

const AnswersWrapper = styled.div`
    padding: 0 10px 10px;
    .ant-tree li .ant-tree-node-content-wrapper {
        padding: 10px;
    }
`;

const Category = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const CategoryInfo = styled.div`
    display: flex;
    align-items: center;
    .anticon-question-circle {
        margin-right: 5px;
    }
`;

const QuestionVariants = styled.ol`
    color: #888;
    margin: 10px 0 0 35px !important;
    li {
        list-style: decimal !important;
        position: relative;
        &:before {
            content: '';
            display: none;
        }
        .anticon-check,
        .anticon-check-circle,
        .anticon-close {
            position: absolute;
            left: -36px;
            top: 5px;
        }
        .anticon-check-circle,
        .anticon-check {
            color: #7bc944;
        }
        .anticon-close {
            color: #f54553;
        }
    }
`;

const Question = styled.div`
    .ant-alert {
        margin-top: 10px;
    }
`;

const AnswerTypeTitle = styled.span`
    color: #31708f;
    margin-right: 10px;
    font-weight: 600;
`;

const QuestionTitle = styled.div`
    display: flex;
    justify-content: space-between;
    & > div:first-of-type {
        display: flex;
        & > span {
            margin-right: 5px;
        }
    }
`;

const QuestionTitleText = styled.div`
    display: inline-block;
    p {
        margin: 0;
    }
`;

const QuestionAnswer = styled.div`
    margin-bottom: 5px;
`;

export default class AnswersResult extends Component {
    getQuestions = () => {
        let questions = [];

        forEach(item => {
            const category = path(['_embedded', 'category'], item);
            const parent = path(['_embedded', 'parent'], category);
            const parentOfParent = path(['_embedded', 'parent'], parent);

            const parentOfParentInQuestions = findIndex(propEq('id', parentOfParent.id), questions);
            const parentInQuestions = parentOfParentInQuestions < 0 ? -1 : findIndex(propEq('id', parent.id), questions[parentOfParentInQuestions].items);
            const categoryInQuestions = parentInQuestions < 0 ? -1 : findIndex(propEq('id', category.id), questions[parentOfParentInQuestions].items[parentInQuestions].items);

            if (parentOfParentInQuestions < 0) {
                questions.push({
                    ...parentOfParent,
                    items: [{
                        ...parent,
                        items: [{
                            ...category,
                            items: [item]
                        }]
                    }]
                });
            } else if (parentInQuestions < 0) {
                questions = assocPath([parentOfParentInQuestions, 'items'], [...questions[parentOfParentInQuestions].items, {
                    ...parent,
                    items: [{
                        ...category,
                        items: [item]
                    }]
                }], questions);
            } else if (categoryInQuestions < 0) {
                questions = assocPath(
                    [parentOfParentInQuestions, 'items', parentInQuestions, 'items'],
                    [...questions[parentOfParentInQuestions].items[parentInQuestions].items, {
                        ...category,
                        items: [item]
                    }],
                    questions
                );
            } else {
                questions = assocPath(
                    [parentOfParentInQuestions, 'items', parentInQuestions, 'items', categoryInQuestions, 'items'],
                    [...questions[parentOfParentInQuestions].items[parentInQuestions].items[categoryInQuestions].items, item],
                    questions
                );
            }
        }, pathOr([], ['_embedded', 'questions'], this.props.test));

        return questions;
    }

    countQuestions = (items, level, onlyCorrect) => {
        if (level < 2) {
            return reduce((res, cur) =>  res + this.countQuestions(cur.items, level + 1, onlyCorrect), 0, items);
        } else {
            const { answers } = this.props;
            return onlyCorrect ? filter(question => path(['correct'], find(propEq('questionId', question.id), answers)), items).length : items.length;
        }
    }

    renderCategoryTitle = (category, level) => {
        const questionAmount = this.countQuestions(category.items, level);

        return <Category>
            { category.name }
            { category.code ? ` [${category.code}]` : '' }
            <CategoryInfo>
                { !category.valid &&
                    <Tooltip title='Компетенция ненаполнена'>
                        <Badge status='error' />
                    </Tooltip>
                }
                <QuestionCircleOutlined />
                { this.props.audit ? questionAmount : `${this.countQuestions(category.items, level, true)}/${questionAmount}` }
            </CategoryInfo>
        </Category>;
    }

    getIncorrectAnswerOptions = item => {
        const options = filter(({ id }) => item[id], INCORRECT_ANSWER_OPTIONS);

        return options.length ? options.map(({ name }, i) => `${name}${i < options.length - 1 ? '; ' : ''}`) : null;
    }

    isAnswered = (id, item) => {
        return item.answerVariantId === id || contains(id, item.answerVariantIds || []);
    }

    renderQuestion = (question, category, competence, indicator, i) => {
        const { answers, test, audit, comments } = this.props;
        const index = findIndex(propEq('id', question.id), path(['_embedded', 'questions'], test));
        const variants = question.answerVariants || (question.type === 'ranging' ? sort((a, b) => a.rank - b.rank, question.rangingAnswerVariants) : question.rangingAnswerVariants);
        const item = answers[index] || {};
        const commentItem = audit ? comments[index] : null;
        const incorrectAnswerOptions = commentItem ? this.getIncorrectAnswerOptions(commentItem) : null;
        const correctNil = isNil(item.correct);

        return <Question>
            <QuestionTitle>
                <div><span>{ i + 1 })</span> <QuestionTitleText dangerouslySetInnerHTML={{ __html: question.title }}></QuestionTitleText></div>
                <Complexity level={question.level} />
            </QuestionTitle>
            { variants && variants.length &&
                <QuestionVariants>
                    { variants.map(variant =>
                        <li key={variant.id}>
                            { item.answeredAt && question.type === 'choice' && (
                                this.isAnswered(variant.id, item)
                                    ? (variant.correct ? <CheckOutlined /> : <CloseOutlined />)
                                    : variant.correct && <CheckOutlined />
                            )}
                            <div>
                                { this.isAnswered(variant.id, item) && question.type === 'choice' ? (
                                    <b dangerouslySetInnerHTML={{ __html: variant.title }} />
                                ) : (
                                    <span dangerouslySetInnerHTML={{ __html: variant.title }} />
                                )}
                            </div>
                        </li>
                    )}
                </QuestionVariants>
            }
            { item.answeredAt &&
                <Alert type={audit && commentItem.incorrectQuestion? 'error' : 'info'} message={
                    <Fragment>
                        { item.answer && <QuestionAnswer><AnswerTypeTitle>Ответ:</AnswerTypeTitle> { item.answer }</QuestionAnswer> }
                        { item.answeredAt && question.type === 'ranging' &&
                            <QuestionAnswer>
                                <AnswerTypeTitle>Ответ:</AnswerTypeTitle>
                                <div>
                                    { (item.answerVariantIds || []).map((id, i) => {
                                        const item = find(propEq('id', id), variants);
                                        const index = findIndex(propEq('id', id), variants);

                                        return <div key={`answer-${id}`}>
                                            { index === i ? <CheckOutlined style={{ color: '#7bc944', marginRight: 5 }} /> : <CloseOutlined style={{ color: '#f54553', marginRight: 5 }} /> } {index + 1}. {<span dangerouslySetInnerHTML={{ __html: item.title }} />}
                                        </div>;
                                    })}
                                </div>
                            </QuestionAnswer>
                        }
                        { audit ? (
                            <Fragment>
                                <div style={{ marginTop: 5 }}><AnswerTypeTitle>Информация о вопросе</AnswerTypeTitle></div>
                                <div><AnswerTypeTitle>Сложность:</AnswerTypeTitle>{ is(Number, question.level) ? <Complexity level={question.level} /> : '' }</div>
                                <div><AnswerTypeTitle>Источник информации:</AnswerTypeTitle>{ question.informationSource || '' }</div>
                                <div><AnswerTypeTitle>Компетенции и индикатор:</AnswerTypeTitle>{
                                    `${category}/${competence}/${indicator}`
                                }</div>
                                <div style={{ marginTop: 5 }}><AnswerTypeTitle>Оценка эксперта</AnswerTypeTitle></div>
                                <div><AnswerTypeTitle>Сложность:</AnswerTypeTitle>{ is(Number, commentItem.complexity) ? <Complexity level={commentItem.complexity} /> : 'не установлена' }</div>
                                <div><AnswerTypeTitle>Источник информации:</AnswerTypeTitle>{ commentItem.informationSource || 'не указаны' }</div>
                                <div><AnswerTypeTitle>Оценка задания:</AnswerTypeTitle>{ !commentItem.incorrectQuestion ? 'Задание корректное' : 'Задание некорректное' }</div>
                                { !!commentItem.incorrectQuestion && <div><AnswerTypeTitle>Критерии:</AnswerTypeTitle>{ incorrectAnswerOptions || 'не указаны' }</div> }
                                <div><AnswerTypeTitle>Комментарий:</AnswerTypeTitle>{ commentItem.message || 'не указан' }</div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                { correctNil ? <QuestionCircleOutlined style={{ color: '#aaa', fontSize: 18, marginRight: 8 }} /> :
                                    item.correct ? <CheckCircleOutlined style={{ color: '#7bc944', fontSize: 18, marginRight: 8 }} /> :
                                    <CloseCircleOutlined style={{ color: '#f54553', fontSize: 18, marginRight: 8 }} />
                                } { correctNil ?
                                    'Ответу не дана оценка' :
                                    `Респондент ${item.correct ? 'верно' : 'неверно'} ответил на вопрос`
                                }, время ответа: { getExecutionTime(item.responseTime || 0) }
                            </Fragment>
                        )}
                    </Fragment>
                } />
            }
        </Question>;
    }

    renderAnswers = () => {
        const questions = this.getQuestions();

        return <Tree
            selectable={false}
            showLine
            defaultExpandAll>
            { questions.map(parentOfParent =>
                <Tree.TreeNode key={parentOfParent.id} title={this.renderCategoryTitle(parentOfParent, 0)}>
                    { parentOfParent.items.map(parent =>
                        <Tree.TreeNode key={parent.id} title={this.renderCategoryTitle(parent, 1)}>
                            { parent.items.map(category =>
                                <Tree.TreeNode key={category.id} title={this.renderCategoryTitle(category, 2)}>
                                    { category.items.map((question, i) =>
                                        <Tree.TreeNode
                                            key={question.id}
                                            title={this.renderQuestion(
                                                question,
                                                parentOfParent.name,
                                                parent.name,
                                                category.name,
                                                i
                                            )}
                                        />
                                    )}
                                </Tree.TreeNode>
                            )}
                        </Tree.TreeNode>
                    )}
                </Tree.TreeNode>
            )}
        </Tree>;
    }

    render() {
        return <Answers>
            <h2>Ответы</h2>
            <AnswersWrapper>
                { this.renderAnswers() }
            </AnswersWrapper>
        </Answers>;
    }
}
