import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';
import styled from 'styled-components';

import CompanyDesignPageForm from '../../forms/CompanyDesignPageForm';
import { putCompany } from '../../../actions/asyncActions';

const Wrapper = styled.div`
    padding: 15px;
`;

class CompanyDesignPage extends Component {
    render() {
        const { company, putCompany } = this.props;

        return <Wrapper>
            <CompanyDesignPageForm
                formAction={putCompany}
                item={company} />
        </Wrapper>;
    }
}

export default withAsyncActions({
    putCompany: putCompany
        .withSuccessHandler(() => message.success('Публичная страница компании успешно сохранена'))
        .withErrorHandler(() => message.error('Не удалось сохранить публичную страницу компании'))
        .withOptions({ resetOnUnmount: true })
})(CompanyDesignPage);
