import React, { Component, Fragment } from 'react';
import { asyncConnect } from 'react-async-client';
import { Button, Alert, Table, InputNumber, Switch, Tooltip } from 'antd';
import { pathOr, flatten, path, find, findIndex, remove, update, append, filter, propEq } from 'ramda';
import styled from 'styled-components';
import * as yup from 'yup';
import { SettingOutlined } from '@ant-design/icons';

import withFormWrapper from '../../hocs/withFormWrapper';
import SubmitButton from '../formComponents/SubmitButton';
import SpanAsLink from '../../user/table/SpanAsLink';
import { openCategoriesSettingsInfoModal, openProjectQuestionsSettingsModal } from '../../../actions/modalActions';
import ProjectCategorySelect from './ProjectCategorySelect';
import { getCategoriesTree } from '../../../utils/categories';
import Complexity from '../../user/table/Complexity';
import ListenerField from '../ListenerField';
import { getComplexity, getUser } from '../../../actions/asyncActions';
import { getExecutionTime } from '../../../utils/time';
import { ADMIN } from '../../../constants/roles';
import { FormSpy } from 'react-final-form';

const TableWrapper = styled.div`
    margin: 15px 0;
`;

const Category = styled.div`
    padding-left: ${({ secondLevel, thirdLevel }) => secondLevel ? 15 : thirdLevel ? 30 : 0}px;
`;

const LevelSelectors = styled.div`
    display: flex;
`;

const LevelSelector = styled.div`
    display: flex;
    align-items: center;
    margin-right: 7px;
    .ant-input-number {
        width: 50px;
        margin-left: 2px;
    }
`;

const ComplexityButtonWrapper = styled.div`
    text-align: right;
`;

const TestTitle = styled.span`
    color: ${({ error }) => error ? '#f54d2e' : '#000'};
`;

class CustomProjectCategoryLevelStep extends Component {
    componentDidMount() {
        this.props.getRef && this.props.getRef(() => this);
    }

    onChange = (questionsCount, test, category, level, testIndex) => {
        const index = findIndex(i => i.category === category && i.level === level, test.categorySelections);
        const categorySelections = index > -1 ?
            (questionsCount === 0 ? remove(index, 1, test.categorySelections) : update(index, {...test.categorySelections[index], questionsCount}, test.categorySelections)) :
            (questionsCount === 0 ? test.categorySelections : append({ category, level, questionsCount }, test.categorySelections));

        this.props.form.change(`items.${testIndex}.categorySelections`, categorySelections);
    }

    getValue = (test = {}, category, level) => {
        return path(['questionsCount'], find(i => i.category === category && i.level === level, test.categorySelections || [])) || 0;
    }

    getComplexitySettings = () => {
        const complexitySettings = pathOr([], ['testsTimeSettings', 'complexitiesSettings'], this.props.item);

        return complexitySettings.length ? complexitySettings : (this.props.getComplexity.data.items || []);
    }

    renderLevelSelectors = (item, test = {}, index) => {
        return <LevelSelectors>
            { [1, 2, 3].map(i =>
                <LevelSelector key={`complexity-${i}`}>
                    <Complexity level={i} />
                    <InputNumber
                        size='small'
                        value={this.getValue(test, item.id, i)}
                        onChange={number => this.onChange(number, test, item.id, i, index)}
                        min={0}
                        max={item.questionsCount[String(i)]}
                        disabled={!item.questionsCount[String(i)] || !test || test.status === 'sent'} />
                </LevelSelector>
            )}
        </LevelSelectors>;
    }

    getAllQuestionsValue = (test = {}, category) => {
        return !!find(propEq('category', category), test.categorySelections);
    }

    onChangeAllQuestions = (value, test, category, index) => {
        const categorySelections = value ?
            append({ allQuestions: true, category }, test.categorySelections) :
            remove(findIndex(propEq('category', category), test.categorySelections), 1, test.categorySelections);

        this.props.form.change(`items.${index}.categorySelections`, categorySelections);
    }

    renderCheckAllQuestions = (item, test = {}, index) => {
        const disabled = !filter(({ status }) => status !== 'sent', this.props.testSuites || []).length;
        const btn = <Switch
            checked={this.getAllQuestionsValue(test, item.id)}
            onChange={value => this.onChangeAllQuestions(value, test, item.id, index)}
            disabled={disabled} />;

        return <span>
            { disabled ?
                <Tooltip title='Тест заблокирован'>
                    { btn }
                </Tooltip> :
                btn
            }
            <span style={{ marginLeft: 10 }}>Все вопросы</span>
        </span>;
    }

    getTime = (item, test) => {
        const complexitySettings = this.getComplexitySettings();

        return (test.categorySelections || []).reduce((res, cur) => {
            const settings = find(i => i.level === cur.level && item.id === cur.category, complexitySettings) || {};
            return (settings.executionTime || 0) * (cur.questionsCount || 0) + res;
        }, 0);
    }

    getResultTime = (items, test, pristine) => {
        const result = items.reduce((res, cur) => path(['_embedded', 'parent', 'parent'], cur) && cur.parent ? res + this.getTime(cur, test) : res, 0);

        return getExecutionTime(pristine ? test.executionTime : result);
    }

    getColumns = (tests, items) => {
        const testColumns = tests.map((test, index) => ({
            title: <FormSpy subscription={{ errors: true }}>
                { ({ errors }) =>
                    <TestTitle
                        error={path(['_arrays', 'items', index, 'categorySelections'], errors) && this.props.submitFailed}>
                        { test.name }
                    </TestTitle>
                }
            </FormSpy>,
            key: test.id,
            render: item => item.result ?
                <div style={{ textAlign: 'right' }}><strong>Итоговое время:</strong> <FormSpy subscription={{ pristine: true }}>
                    { ({ pristine }) => this.getResultTime(items, test, pristine) }
                </FormSpy></div> :
                !path(['_embedded', 'parent', 'parent'], item) && !(item.items || []).length ? this.renderCheckAllQuestions(item, test, index) :
                path(['_embedded', 'parent', 'parent'], item) && item.parent && this.renderLevelSelectors(item, test, index)
        }));

        return [
            {
                title: 'Компетенция',
                dataIndex: 'name',
                key: 'name',
                render: (name, item) => {
                    if (item.result) {
                        return;
                    }

                    const secondLevel = !path(['_embedded', 'parent', 'parent'], item) && !!item.parent;

                    return <Category secondLevel={secondLevel} thirdLevel={!!item.parent}>
                        { item.parent && !secondLevel ? name : <strong>{ name }</strong> }
                    </Category>;
                }
            },
            ...testColumns
        ];
    }

    render() {
        const { openCategoriesSettingsInfoModal, item, setNext, submitBtn, edit, openProjectQuestionsSettingsModal, isAdmin, submitFailed, goPrev } = this.props;
        const categories = pathOr([], ['_embedded', 'categories'], item);
        const grouped = flatten(getCategoriesTree(categories, true).map(item => ([item, ...item.items.map(i => [i, ...i.items])])));

        return <div>
            <FormSpy subscription={{ errors: true }}>
                { ({ errors }) =>
                    !!pathOr([], ['_arrays', 'items'], errors).length && submitFailed &&
                        <Alert type='error' message={'Необходимо выбрать как минимум один вопрос для каждого теста'} style={{ marginBottom: 15 }} />
                }
            </FormSpy>
            { edit ?
                <Fragment>
                    <Alert type='info' message={<span>Выберите компетенции из межотраслевой модели профессиональных компетенций, необходимые для оценки респондентов. В момент выбора помните о временном критерии: чем больше компетенций, тем больше времени требуется на их проверку. Рекомендуемая длительность процедуры одного сеанса тестирования составляет не более 1,5 часов. <SpanAsLink onClick={() => openCategoriesSettingsInfoModal()}>Подробнее</SpanAsLink></span>} />
                    <ProjectCategorySelect item={item} lastLevel onlyOwn={false} />
                </Fragment> :
                ( isAdmin &&
                    <ComplexityButtonWrapper>
                        <Button icon={<SettingOutlined />} onClick={() => openProjectQuestionsSettingsModal(item)}>
                            Настройки сложностей
                        </Button>
                    </ComplexityButtonWrapper>
                )
            }
            <TableWrapper>
                { !!categories.length &&
                    <ListenerField listenFieldName='items'>
                        { ({ items }) =>
                            <Table
                                dataSource={[...grouped, { result: true, id: 'result' }]}
                                columns={this.getColumns(items, grouped)}
                                size='small'
                                rowKey='id'
                                pagination={false} />
                        }
                    </ListenerField>
                }
            </TableWrapper>
            { submitBtn ||
                <Button.Group>
                    <Button onClick={goPrev}>Назад</Button>
                    <SubmitButton type='primary' onClick={() => setNext(true)}>Далее</SubmitButton>
                </Button.Group>
            }
        </div>
    }
}

const stateToProps = state => ({
    isAdmin: getUser.selectData(state).role === ADMIN
});

const validationSchema = yup.object().shape({
    items: yup.array().of(yup.object().shape({
        categorySelections: yup.array().required().min(1)
    }))
});

export default asyncConnect({
    getComplexity: getComplexity
        .withOptions({ dispatchOnMount: true })
}, stateToProps, { openCategoriesSettingsInfoModal, openProjectQuestionsSettingsModal })(
    withFormWrapper(
        CustomProjectCategoryLevelStep,
        {
            mapPropsToValues: ({ item, testSuites }) => ({
                company: item.company,
                project: item.id,
                items: testSuites
            }),
            validationSchema,
            mapBeforeSubmit: values => ({
                ...values,
                items: filter(({ status }) => status !== 'sent', values.items)
            })
        }
    )
);
