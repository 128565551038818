import React from 'react';
import { contains, isEmpty, path } from 'ramda';
import { Spin } from 'antd';
import { withAsyncActions } from 'react-async-client';

import UserLayout from './user/UserLayout';
import PdaLayout from './user/PdaLayout';
import GuestLayout from './guest/GuestLayout';
import { getUser } from '../../actions/asyncActions';
import { MANAGERS } from '../../constants/roles';

const checkUser = (user, meta) => {
    if (meta.pending && !meta.lastSucceedAt) {
        return null;
    }

    return !isEmpty(user);
}

const checkUserPda = (user) => {
    return !isEmpty(user) && contains(user.role, MANAGERS) && !path(['pda', 'agreement'], user);
}

const SecureLayout = ({ getUser, getUser: { data, meta }, ...props }) => {
    const isAuthenticated = checkUser(data, meta);

    if (isAuthenticated === null) {
        return (
            <div className='auth-spin-wrapper'>
                <Spin />
            </div>
        );
    }

    const isPdaRequired = checkUserPda(data, meta);
    if (isPdaRequired) {
        return <PdaLayout {...props} getUser={getUser} />;
    }

    return isAuthenticated ? <UserLayout {...props} /> : <GuestLayout {...props} />;
}

export default withAsyncActions({
    getUser
})(SecureLayout);
