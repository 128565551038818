import { put, takeEvery, race, take } from 'redux-saga/effects';
import { toSuccess, toError } from 'react-async-client';

import { REQUEST_ERROR } from '../constants/regExp';
import { logout, postRefreshToken } from '../actions/appActions';
import { getRefreshToken } from '../utils/token';

export default function* error() {
    yield takeEvery((action) => REQUEST_ERROR.test(action.type), function* (action) {
        const error = action.payload;
        const sessionIsOver = error.status === 401;

        if (sessionIsOver) {
            const refreshToken = getRefreshToken();

            if (refreshToken) {
                yield put(postRefreshToken({
                    refresh_token: refreshToken
                }));

                const { success, error } = yield race({
                    success: take(toSuccess(postRefreshToken.type)),
                    error: take(toError(postRefreshToken.type))
                });

                if (success) {
                    return yield put(action.requestAction);
                }

                if (error) {
                    yield put(logout());
                }
            }

            yield put(logout());
        }
    });
}
