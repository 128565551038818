import React, { Component } from 'react';
import styled from 'styled-components';
import { IMaskInput, IMask } from 'react-imask';

import Complexity from '../../user/table/Complexity';
import withFieldWrapper from '../../hocs/withFieldWrapper';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

class LevelTime extends Component {
    constructor(props) {
        super(props);

        this.state = this.getMinutesSeconds(props);
    }

    getTimeString = () => {
        const { minutes, seconds } = this.getMinutesSeconds();

        return `${minutes ? (minutes < 10 ? `0${minutes}` : minutes) : '00'}:${seconds ? (seconds < 10 ? `0${seconds}` : seconds) : '00'}`;
    }

    getMinutesSeconds = (props = this.props) => {
        const { value } = props.input;
        const minutes = value ? Math.floor(value / 60) : 0;
        const seconds = value ? value - minutes * 60 : 0;

        return { minutes, seconds };
    }

    onAcceptMask = (value, mask) => {
        if (value) {
            const { min } = this.props;
            const [minutes, seconds] = value.split(':');

            const time = parseInt(seconds, 10) + parseInt(minutes, 10) * 60;

            this.setState(time < min ? { minutes: 0, seconds: min } : { minutes, seconds });
            this.props.input.onChange(time < min ? min : time);
        }
    }

    render() {
        const { level, hideComplexity } = this.props;

        return <Wrapper>
            { !hideComplexity && <Complexity level={level} />}
            <IMaskInput
                mask='MM:SS'
                blocks={{
                    MM: {
                        mask: IMask.MaskedRange,
                        from: 0,
                        to: 59,
                        maxLength: 2,
                        placeholderChar: '_',
                    },
                    SS: {
                        mask: IMask.MaskedRange,
                        from: 0,
                        to: 59,
                        maxLength: 2,
                        placeholderChar: '_',
                    }
                }}
                lazy={false}
                overwrite={true}
                autofix={true}
                unmask={false}
                className='ant-input ant-input-sm'
                style={{ width: 60 }}
                value={this.getTimeString()}
                onAccept={this.onAcceptMask}
            />
        </Wrapper>;
    }
}

export default withFieldWrapper(LevelTime);
