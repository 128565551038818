import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';

const Wrapper = styled.div`
    position: relative;
    padding-right: 20px;
    .anticon-question-circle {
        position: absolute;
        bottom: 9px;
        right: 0;
        cursor: pointer;
        color: #aaa;
    }
`;

export default props =>
    <Wrapper>
        { props.children }
        <Tooltip title={props.text} placement='left'>
            <QuestionCircleFilled />
        </Tooltip>
    </Wrapper>;
