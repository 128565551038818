import React, { Component } from 'react';
import { withAsyncActions, toSuccess } from 'react-async-client';
import { Tooltip, Badge } from 'antd';
import styled from 'styled-components';
import { takeEvery } from 'redux-saga/effects';
import { pathEq, includes } from 'ramda';
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';

import { getCategories, patchCategory } from '../../../actions/asyncActions';
import { DELETE_CATEGORY, POST_CATEGORY, PUT_CATEGORY, PATCH_CATEGORY, QUESTION_IMPORT_SUCCESS } from '../../../constants/actionTypes';
import CategoryEdit from './CategoryEdit';
import Complexity from '../table/Complexity';

const TitleName = styled.span`
    display: inline;
`;
const Title = styled.span`
    color: ${({ visible }) => visible ? 'inherit' : '#a7a7a7'};
    ${TitleName}{
        text-decoration: ${({ visible }) => visible ? 'inherit' : 'line-through'};
    }
    .anticon {
        margin-right: 5px;
        .anticon-spin {
            fill: #1890ff;
        }
        &.anticon-check svg {
            fill: ${({ valid }) => valid ? 'rgb(82, 196, 26)' : 'rgba(0, 0, 0, 0.65)'};
        }
    }

    .category-controls {
        display: inline;
        .anticon-edit,
        .anticon-link,
        .anticon-file-done {
            display: none;
            margin: 0;
            position: absolute;
            padding: 5px;
            top: 5px;
            color: #696e75;
            border-radius: 3px;
            border: 1px solid transparent;
            &:hover{
                color: #000;
                background-color: transparent;
                border-color: transparent;
            }
        }
        .anticon-edit{
            right: 2px;
        }
        .anticon-link{
            right: 27px;
        }
        .anticon-file-done {
            right: 52px;
        }
        form {
            display: inline;
        }
    }
`;

const ComplexityBlock = styled.div`
    display: inline-flex;
    align-items: baseline;
    margin: 0 6px 0 2px;
    line-height: 12px;
    font-size: 12px;
    color: #B4B4B4;
`;

const ComplexityWrapper = styled.div`
    display: flex;
`;

const Loader = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
`;

const Check = styled.span`
    .anticon.anticon-check svg {
        fill: rgb(92, 184, 92);
    }
`;

const TitleCode = styled.span`
    color: #a7a7a7;
    font-size: 12px;
`;

const CategoryLabel = styled.span`
    margin: 0;
    position: absolute;
    bottom: -5px;
    right: 4px;
    opacity: 0.2;
    transition: all 0.4s ease-out;
    svg path {
        fill: #2B3D4F;
    }
`;

class CategoryTitle extends Component {
    componentDidMount() {
        this.props.getRef(this);
    }

    changeValid = e => {
        e.stopPropagation()
        this.props.patchValidCategory.dispatch({
            id: this.props.item.id,
            data: [{ op: 'replace', path: '/valid', value: !this.props.item.valid }]
        });
    }

    render() {
        const { isAdmin, item, getCategories, visible, onVisibleChange, viewMode, common, buttons, renderComplexity, allowChangeValid, checked, editButtons } = this.props;

        return <Title valid={item.valid} visible={item.visible} ref={node => this.container = node}>
            { checked &&
                <Check>
                    <CheckOutlined />
                </Check>
            }
            <Tooltip title={item.valid ? 'Доступна для создания проектов' : 'Недоступна для создания проектов'}>
                <span style={{ width: 10, height: 10 }} onClick={allowChangeValid && this.changeValid}>
                    <Badge color={item.valid ? 'green' : '#ccc'} />
                </span>
            </Tooltip>
            <TitleName>
                { item.name }
                { item.code ? (
                    <TitleCode>
                        {' '}[{item.code}]
                    </TitleCode>
                ) : null}
            </TitleName>
            { renderComplexity ?
                renderComplexity(item) :
                <ComplexityWrapper>
                    { !!item.questionsCount['1'] &&
                    <ComplexityBlock>
                        <Complexity level={1} /> { item.questionsCount['1'] }
                    </ComplexityBlock>
                    }
                    { !!item.questionsCount['2'] &&
                    <ComplexityBlock>
                        <Complexity level={2} /> { item.questionsCount['2'] }
                    </ComplexityBlock>
                    }
                    { !!item.questionsCount['3'] &&
                    <ComplexityBlock>
                        <Complexity level={3} /> { item.questionsCount['3'] }
                    </ComplexityBlock>
                    }
                </ComplexityWrapper>
            }
            { buttons }
            { !viewMode && <span className='category-controls' onClick={e => e.stopPropagation()}>
                    { !item.company && (
                        <CategoryLabel className='category-label'>
                            <svg width="33" height="6" viewBox="0 0 33 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_605_7221)">
                                <path d="M23.7323 4.16572C22.9755 4.16572 22.4251 3.66515 22.4251 2.98475C22.4251 2.30418 22.9755 1.80395 23.7323 1.80395C24.4662 1.80395 25.0394 2.30435 25.0394 2.98475C25.0394 3.66532 24.489 4.16572 23.7323 4.16572ZM23.7323 0.342865C22.0122 0.342865 20.6592 1.50378 20.6592 2.98475C20.6592 4.46589 22.0122 5.60675 23.7323 5.60675C25.4523 5.60675 26.8053 4.44584 26.8053 2.98475C26.8053 1.50361 25.4523 0.342865 23.7323 0.342865ZM4.0553 4.16572C3.29846 4.16572 2.74807 3.66515 2.74807 2.98475C2.74807 2.30418 3.29846 1.80395 4.0553 1.80395C4.78916 1.80395 5.36234 2.30435 5.36234 2.98475C5.36234 3.66532 4.81195 4.16572 4.0553 4.16572ZM5.29359 0.883208C4.90368 0.502979 4.37646 0.342865 3.82587 0.342865C2.24345 0.342865 0.982178 1.50378 0.982178 2.98475C0.982178 3.64526 1.23439 4.26566 1.71603 4.74601C2.24345 5.28652 3.06903 5.60658 3.91761 5.60658C4.42223 5.60658 4.99541 5.40652 5.29359 5.08629V5.50664H7.01371V0.442979H5.29359V0.883208ZM32.6072 0.442979H30.5201L29.5341 1.66389V0.442979H27.7682V5.50664H29.5341V2.26424H31.1622L32.6072 0.442979ZM18.1595 4.16572C17.3567 4.16572 16.7835 3.68521 16.7835 2.98475C16.7835 2.30418 17.3567 1.80395 18.1135 1.80395C18.5085 1.80562 18.8871 1.94209 19.1683 2.18418L20.1316 0.983322C19.5653 0.570669 18.8513 0.344077 18.1135 0.342865C16.3706 0.342865 14.9946 1.50378 14.9946 2.98475C14.9946 4.48595 16.3248 5.60675 18.0905 5.60675C18.9391 5.60675 19.6272 5.38646 20.2233 4.94624L19.2602 3.72532C19.0078 3.94544 18.6179 4.16572 18.1595 4.16572ZM11.7836 0.342865C11.279 0.342865 10.4304 0.562979 10.1324 0.883208V0.442979H8.41228V5.50664H10.1782V2.30435C10.3844 2.08406 10.8433 1.80378 11.3937 1.80378C11.6919 1.80378 11.9441 1.88401 12.1275 2.02406C12.265 2.14406 12.4025 2.34429 12.4025 2.82464V5.50664H14.1684V2.26424C14.1684 0.983322 13.091 0.342865 11.7838 0.342865H11.7836Z" fill="#F54D2E"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_605_7221">
                                <rect width="33" height="6" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>
                        </CategoryLabel>
                    )}
                    { (isAdmin || item.company) && (
                        editButtons ?
                            editButtons(item) :
                            <CategoryEdit
                                elementRef={this.container}
                                item={item}
                                visible={visible}
                                onVisibleChange={onVisibleChange}
                                common={common} />
                    )}
                </span>
            }
            { getCategories.meta.pending &&
                <Loader>
                    <LoadingOutlined />
                </Loader>
            }
        </Title>;
    }
}

export default withAsyncActions(({ dispatchOnMount }) => ({
    getCategories: getCategories
        .withParams(({ item }) => ({ parent: item.id }))
        .withPayload(({ item, complete, valid, company, all }) => ({
            q: {
                byParent: true,
                parent: item.id,
                complete,
                valid,
                company,
                onlyOwn: item.company && company ? true : null,
                visible: all || null
            }
        }))
        .withSaga(function* (getProps) {
            yield takeEvery([
                toSuccess(DELETE_CATEGORY),
                toSuccess(POST_CATEGORY),
                toSuccess(PUT_CATEGORY),
                toSuccess(PATCH_CATEGORY)
            ], function(action) {
                const { item, getCategories } = getProps();

                if (pathEq(['requestAction', 'params', 'parent'], item.id, action)) {
                    getCategories.refresh();
                }
            });

            yield takeEvery([QUESTION_IMPORT_SUCCESS], function() {
                const { getCategories } = getProps();

                getCategories.refresh();
            });
        })
        .withShouldUpdate((prev, next) => prev.all !== next.all && includes(next.item.id, (next.expandedKeys || [])))
        .withOptions({ dispatchOnMount, dispatchOnUpdate: true }),
    patchValidCategory: patchCategory
        .withParams(({ item, common }) => ({ parent: common ? null : item.parent, id: item.id, type: 'valid' }))
        .withOptions({ resetOnUnmount: true }),
}))(CategoryTitle);
