import React, { Component } from 'react';
import { Input } from 'antd';

import withFieldWrapper from '../../hocs/withFieldWrapper';

class Textarea extends Component {
    onChange = e => this.props.onChange(e.target.value);

    render() {
        const { input: { value }, disabled, minRows = 3 } = this.props;

        return <Input.TextArea
            value={value}
            disabled={disabled}
            onChange={this.onChange}
            autoSize={{ minRows }} />;
    }
}

export default withFieldWrapper(Textarea);
