import React, { Component } from 'react';
import { message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import QuestionPageForm from '../forms/QuestionPageForm';
import { putCompanyTestQuestions } from '../../actions/asyncActions';
import Modal from './Modal';

class QuestionPageModal extends Component {
    render() {
        const { modal, params: { setPages, questions, pages, ...data }, putCompanyTestQuestions } = this.props;

        return <Modal
            {...modal}
            title='Добавить страницу'
            footer={null}>
            <QuestionPageForm
                formAction={putCompanyTestQuestions}
                pages={pages}
                questions={questions}
                item={data} />
        </Modal>;
    }
}

export default withAsyncActions({
    putCompanyTestQuestions: putCompanyTestQuestions
        .withSuccessHandler(({ close, putCompanyTestQuestions: { data }, params }) => {
            message.success('Страницы была успешно сохранена');
            params.setPages(data.pages);
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(QuestionPageModal);
