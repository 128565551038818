import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Badge, Progress, Tooltip, message } from 'antd';
import { find, propEq, path, pathOr, contains, omit, pick } from 'ramda';
import styled from 'styled-components';
import { UploadOutlined, PlusOutlined, InfoCircleOutlined, RetweetOutlined } from '@ant-design/icons';
import { withAsyncActions } from 'react-async-client';
import moment from 'moment';

import TableList from './table/TableList';
import { getCommands, cancelCommand, getUser } from '../../actions/asyncActions';
import { openCommandModal, openCommandDebugModal } from '../../actions/modalActions';
import { POST_COMMAND } from '../../constants/actionTypes';
import { COMMAND_TYPES } from '../../constants/commands';
import { ADMIN } from '../../constants/roles';
import DateFormat from './table/DateFormat';
import { BASE_URL } from '../../constants/urls';
import { getToken } from '../../utils/token';
import Complexity from './table/Complexity';
import { Color, Swatch } from '../forms/formComponents/ColorPicker';
import CommandsFilter from '../forms/filters/CommandsFilter';

const TestsCount = styled.div`
    display: inline-flex;
`;

const TestsCountItem = styled.div`
    display: flex;
    margin-right: 8px;
    font-size: 13px;
`;

const CommandDebugButtonContainer = styled.div`
    float: right;
`;

const renderCancelAction = (command, handler) => {
    if (['running', 'created'].indexOf(command.status) < 0) {
        return null;
    }

    return <Button type='link' onClick={() => handler.dispatch({ id: command.id })}>отменить</Button>
}

const getBadgeStatus = (status) => {
  const statuses = {
    running: 'processing',
    success: 'success',
    fail: 'error',
    canceled: 'warning',
  }

  if (status in statuses) {
    return statuses[status];
  }

  return 'default';
}

class Commands extends Component {
    getColumns = () => {
        const { isAdmin } = this.props;

        return [
            {
                title: 'Команда',
                dataIndex: 'name',
                key: 'name',
                render: (name, command) =>
                    <div>
                      <span>
                          <Badge status={getBadgeStatus(command.status)}/>
                          { pathOr(name, ['value'], find(propEq('id', name), COMMAND_TYPES)) }
                      </span>
                      <div>
                        {renderCancelAction(command, this.props.cancelCommand)}
                      </div>
                    </div>
            },
            {
                title: 'Пользователь',
                dataIndex: 'createdBy',
                key: 'user',
                render: (createdBy, command) => pathOr('', ['_embedded', 'createdBy', 'email'], command)
            },
            isAdmin ? {
                title: 'Компания',
                dataIndex: 'inputArguments',
                key: 'company',
                render: inputArguments => inputArguments ? inputArguments.company || '' : ''
            } : null,
            {
                title: 'Дата добавления',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: date => <DateFormat date={date} withTime/>
            },
            {
                title: '',
                dataIndex: 'status',
                key: 'commit',
                align: 'right',
                width: 60,
                render: (progress, command) => {
                    if (command.status !== 'success' || !path(['inputOptions', 'dry-run'], command)) {
                        return null;
                    }

                    return <Button
                        title='Применить'
                        type='primary'
                        onClick={() => this.applyCommand(command)}
                        shape='circle'
                        icon={<UploadOutlined />}
                        size='large'
                    />;
                }
            },
            {
                title: '',
                key: 'repeat',
                align: 'right',
                width: 60,
                render: command =>
                    <Tooltip title='Повторить команду'>
                        <Button
                            type='primary'
                            onClick={() => this.props.openCommandModal({
                                command: pick(['name', 'inputArguments', 'inputData', 'inputFiles', 'inputFilesRoutes', 'inputOptions'], command),
                                inputFilesRoutes: command.inputFilesRoutes
                            })}
                            shape='circle'
                            icon={<RetweetOutlined />}
                            size='large'
                        />
                    </Tooltip>
            },
            {
                title: '',
                dataIndex: 'progressPercent',
                key: 'progress',
                align: 'right',
                width: 60,
                render: (progress, item) => {
                    if (['created', 'canceled'].indexOf(item.status) >= 0) {
                        return null;
                    }

                    progress = Number.isInteger(progress) ? progress : 0;

                    const props = {
                        width: 40,
                        type: 'circle',
                        percent: item.status === 'success' ? 100 : progress,
                    };

                    if (item.status === 'fail') {
                        props.status = 'exception';
                        if (!progress) {
                            props.percent = 100;
                        }
                    }

                    return <Progress {...props}/>;
                }
            }
        ].filter(Boolean);
    }

    applyCommand = (dryRunCommand) => {
        const command = pick(['name', 'inputArguments', 'inputFiles', 'inputOptions', 'inputData'], dryRunCommand);
        command.inputOptions = omit(['dry-run'], command.inputOptions);

        this.props.openCommandModal({ command, inputFilesRoutes: dryRunCommand.inputFilesRoutes });
    }

    renderButtons = () => {
        return <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => this.props.openCommandModal()}>
            <span className='hide-mobile'>Добавить</span>
        </Button>;
    }

    renderExpandedRowValue = (label, value, props) => {
        return value ? <div {...props}><strong>{label}:</strong> {value}</div> : null;
    }

    renderInputFiles = (item) => {
        return Object.keys(item.inputFiles).map((fileCode) => {
            const label = fileCode === 'pdfTail' ? 'PDF-приложение' : 'Файл';
            const downloadUrl = `${BASE_URL}${item.inputFilesRoutes[fileCode]}?access_token=${getToken()}`;
            const fileName = pathOr('file', ['inputData', fileCode], item);

            return this.renderExpandedRowValue(
                label,
                <a href={downloadUrl} target='_blank' rel='noopener noreferrer' download>{fileName}</a>, { key: `file-${fileCode}` })
        });
    }

    renderOutputFiles = (item) => {
        const fileNames = Object.keys(item.outputFiles);
        if (fileNames.length <= 0) {
            return null;
        }

        return <Fragment>
            <h3 style={{ marginTop: 15, marginBottom: 15 }}>Сгенерированные файлы:</h3>
            {fileNames.map((fileName) => {
                const downloadUrl = `${BASE_URL}${item.outputFilesRoutes[fileName]}?access_token=${getToken()}`;

                return <div key={`file-${fileName}`}>
                    <a href={downloadUrl} target='_blank' rel='noopener noreferrer' download>{fileName}</a>
                </div>
            })}
        </Fragment>
    }

    renderCountTests = item => {
        const countTests = path(['inputOptions', 'count-tests'], item).split(',');

        return <TestsCount>
            {countTests.map((count, index) =>
                <TestsCountItem key={`count-${index}-${item.id}`}>
                    <Complexity level={index + 1}/> {count}
                </TestsCountItem>
            )}
        </TestsCount>;
    }

    renderExpandedRowRender = item => {
        const type = find(propEq('id', item.name), COMMAND_TYPES);

        return type ? <div>
            {item.status === 'fail' &&
            <CommandDebugButtonContainer>
                <Button
                    shape='circle'
                    icon={<InfoCircleOutlined />}
                    onClick={() => this.props.openCommandDebugModal({ command: item })}
                />
            </CommandDebugButtonContainer>
            }
            <h3>Параметры команды</h3>
            {this.renderExpandedRowValue('Компания', path(['inputArguments', 'company'], item))}
            {this.renderExpandedRowValue('Корневая категория', pathOr(path(['inputData', 'rootCompetence'], item), ['inputData', 'category'], item))}
            {this.renderExpandedRowValue('Компетенция', path(['inputData', 'competence'], item))}
            {contains('count-tests', type.fields) && path(['inputOptions', 'count-tests'], item) && this.renderExpandedRowValue('Количество вариантов тестов', this.renderCountTests(item))}
            {contains('clear', type.fields) && this.renderExpandedRowValue('Удалить старые вопросы', path(['inputOptions', 'clear'], item) ? 'да' : 'нет')}
            {contains('full-list', type.fields) && this.renderExpandedRowValue('Добавлять статус прохождения тестов в отчет', path(['inputOptions', 'full-list'], item) ? 'да' : 'нет')}
            {contains('shuffle-answers', type.fields) && this.renderExpandedRowValue('Перемешивать варианты ответов', path(['inputOptions', 'shuffle-answers'], item) ? 'да' : 'нет')}
            {contains('with-answers', type.fields) && this.renderExpandedRowValue('Показывать варианты ответов', path(['inputOptions', 'with-answers'], item) ? 'да' : 'нет')}
            {contains('dry-run', type.fields) && this.renderExpandedRowValue('Тестовый прогон', path(['inputOptions', 'dry-run'], item) ? 'да' : 'нет')}
            {contains('date', type.fields) && this.renderExpandedRowValue('Период', `${moment(path(['inputOptions', 'min-date'], item)).format('DD.MM.YYYY')}-${moment(path(['inputOptions', 'max-date'], item)).format('DD.MM.YYYY')}`)}
            { contains('pdf', type.optionalFields || []) &&
                <Fragment>
                    {this.renderExpandedRowValue('Страница с программой развивающих действий', path(['inputOptions', 'with-recommendations'], item) ? 'да' : 'нет')}
                    {this.renderExpandedRowValue('Страница с зонами развития', path(['inputOptions', 'with-competence-conclusion'], item) ? 'да' : 'нет')}
                    {this.renderExpandedRowValue('Блок с текстовым описанием результата', path(['inputOptions', 'with-text-result'], item) ? 'да' : 'нет')}
                    {this.renderExpandedRowValue('Таблица результатов на отдельной странице', path(['inputOptions', 'with-full-page-text-result'], item) ? 'да' : 'нет')}
                    {this.renderExpandedRowValue('Таблица компетенций на отдельной странице', path(['inputOptions', 'with-full-page-competences'], item) ? 'да' : 'нет')}
                    {this.renderExpandedRowValue('Блок с числовым описанием результата', path(['inputOptions', 'with-number-result'], item) ? 'да' : 'нет')}
                    {this.renderExpandedRowValue('Скрыть диаграмму кометенций', path(['inputOptions', 'without-competences-chart'], item) ? 'да' : 'нет')}
                    {this.renderExpandedRowValue('Включить вывод в отчет', path(['inputOptions', 'with-integral-result-text'], item) ? 'да' : 'нет')}
                    {this.renderExpandedRowValue('Логотип в заголовке детальных страниц', path(['inputOptions', 'with-logo'], item) ? 'да' : 'нет')}
                    {this.renderExpandedRowValue('Логотип', path(['inputOptions', 'logo'], item) ? <img src={item.inputOptions.logo} alt='logo' height={100} /> : null)}
                    {this.renderExpandedRowValue('Основной цвет отчета', path(['inputOptions', 'color'], item) ? <Swatch><Color color={item.inputOptions.color} /></Swatch> : null)}
                    {this.renderExpandedRowValue('Язык отчета', path(['inputOptions', 'language'], item))}
                </Fragment>
            }
            {contains('with-page-numbers', type.fields) && this.renderExpandedRowValue('Отображать номера страниц', path(['inputOptions', 'with-page-numbers'], item) ? 'да' : 'нет')}
            {this.renderInputFiles(item)}
            {item.output &&
            <Fragment>
                <h3 style={{ marginTop: 15, marginBottom: 15 }}>Детальный вывод</h3>
                <code dangerouslySetInnerHTML={{ __html: item.output.replace(/\n/g, '<br />') }}/>
            </Fragment>
            }

            {this.renderOutputFiles(item)}
        </div> : null;
    }

    render() {
        return <TableList
            action={getCommands}
            columns={this.getColumns()}
            buttons={this.renderButtons()}
            expandedRowRender={this.renderExpandedRowRender}
            refreshActions={[POST_COMMAND]}
            filterForm={CommandsFilter}
            refreshInterval />;
    }
}

const stateToProps = state => ({
    isAdmin: getUser.selectData(state).role === ADMIN,
});

export default connect(stateToProps, { openCommandModal, openCommandDebugModal })(
    withAsyncActions({
        cancelCommand: cancelCommand
            .withSuccessHandler(() => {
                message.success('Команда успешно отменена');
            })
            .withErrorHandler(() => message.error('Не удалось отменить комманду'))
            .withOptions({ resetOnUnmount: true }),
    })(Commands)
);
