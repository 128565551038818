import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';
import { path } from 'ramda';

import { postCategory } from '../../../actions/asyncActions';
import CategoryForm from '../../forms/CategoryForm';
import { CATEGORIES_ERRORS } from '../../../constants/errors';

class AddCategory extends Component {
    onVisibleChange = visible => {
        this.props.onVisibleChange(visible ? this.props.parent : false);
    }

    render() {
        const { parent, postCategory } = this.props;

        return <CategoryForm
            item={{ parent }}
            formAction={postCategory}
            visible={this.props.visible}
            onVisibleChange={this.onVisibleChange} />;
    }
}

export default withAsyncActions({
    postCategory: postCategory
        .withParams(({ parent, common }) => ({ parent: common ? null : parent }))
        .withSuccessHandler(({ onVisibleChange }) => onVisibleChange(false))
        .withErrorHandler(({ postCategory: { meta }}) => {
            const error = path(['error', 'data', 'errors', 'name', 0, 'message'], meta);
            message.error(CATEGORIES_ERRORS[error] || 'Не удалось добавить компетенцию')
        })
        .withOptions({ resetOnUnmount: true })
})(AddCategory);
