import React, { Component } from 'react';
import { Alert, Form, Select, Upload, Button, Table, message } from 'antd';
import { path } from 'ramda';
import qs from 'qs';
import { withStateHandlers } from 'recompose';
import { withAsyncActions } from 'react-async-client';
import { InboxOutlined, ImportOutlined, FileOutlined } from '@ant-design/icons';

import { getToken } from '../../utils/token';
import { getCompanyRespondentsImport, postCompanyRespondentsImport } from '../../actions/asyncActions';
import Modal from './Modal';
import { getImportErrorMessage } from '../../utils/import';

class ImportRespondentsModal extends Component {
    getSearchPath = () => {
        return qs.stringify({
            access_token: getToken()
        }, { addQueryPrefix: true });
    }

    getDownloadLink = () => {
        const { test, params: { company, project }} = this.props;

        return `/api/companies/${company}/projects/${project}/tests/${test}/respondents/import/headers.xlsx${this.getSearchPath()}`;
    }

    getUploadUrl = () => {
        const { test, params: { company, project }} = this.props;

        return `/api/companies/${company}/projects/${project}/tests/${test}/respondents/import?access_token=${getToken()}`;
    }

    onChangeTest = test => this.props.setTest(test);

    onChange = info => {
        const { status } = info.file;

        switch(status) {
            case 'done':
                this.props.setFile(info.file.response.id);
                this.props.getCompanyRespondentsImport.refresh();
                break;
            default:
                return;
        }
    }

    renderError = () => {
        const { error } = this.props.getCompanyRespondentsImport.meta;
        const err = path(['data', 'errors', 0], error);

        if (!err) {
            return null;
        }

        return <Alert type='error' message={<div dangerouslySetInnerHTML={{ __html: getImportErrorMessage(err) }} />} style={{ marginBottom: 10 }} />;
    }

    getColumns = () => {
        return [
            {
                title: '#',
                key: 'number',
                render: (_, __, index) => index + 1
            },
            {
                title: 'Фамилия',
                key: 'lastName',
                dataIndex: 'lastName'
            },
            {
                title: 'Имя',
                key: 'firstName',
                dataIndex: 'firstName'
            },
            {
                title: 'Отчество',
                key: 'middleName',
                dataIndex: 'middleName'
            },
            {
                title: 'Email',
                key: 'email',
                dataIndex: 'email'
            },
            {
                title: 'Телефон',
                key: 'phone',
                dataIndex: 'middlphoneeName'
            }
        ];
    }

    reset = () => {
        const { setFile, getCompanyRespondentsImport } = this.props;

        setFile(null);
        getCompanyRespondentsImport.reset();
    }

    import = () => {
        const { test, params: { company, project, auditEnabled }, file, postCompanyRespondentsImport } = this.props;

        postCompanyRespondentsImport.dispatch({
            test,
            company,
            project,
            id: file,
            params: {
                auditEnabled
            }
        });
    }

    render() {
        const { modal, params: { tests }, file, getCompanyRespondentsImport, test } = this.props;
        const items = getCompanyRespondentsImport.data.items || [];

        return <Modal
            {...modal}
            title='Импортирование респондентов'
            footer={null}>
            <Alert type='info' style={{ marginBottom: 15 }} message={
                <span>Для того чтобы загрузить респондентов вы можете использовать систему массовой загрузки, для этого <a href={this.getDownloadLink()} target='_blank' rel='noopener noreferrer' download>скачайте шаблон</a>, заполните его и загрузите в эту форму.</span>
            } />
            { tests.length > 1 && !file &&
                <Form.Item wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} label='Тест'>
                    <Select value={test} onChange={this.onChangeTest} style={{ width: '100%' }}>
                        { tests.map(test =>
                            <Select.Option
                                key={`test-${test.id}`}
                                value={test.id}>
                                { test.name }
                            </Select.Option>
                        )}
                    </Select>
                </Form.Item>
            }
            { file &&
                <Button.Group style={{ marginBottom: 15, marginTop: 15 }}>
                    { getCompanyRespondentsImport.success &&
                        <Button
                            disabled={!items.length}
                            onClick={this.import}
                            icon={<ImportOutlined />}
                            type='primary'>
                            Импортировать
                        </Button>
                    }
                    <Button icon={<FileOutlined />} onClick={this.reset}>
                        Выбрать другой файл
                    </Button>
                </Button.Group>
            }
            { this.renderError() }
            { file ?
                <Table
                    dataSource={items}
                    loading={getCompanyRespondentsImport.meta.pending}
                    columns={this.getColumns()}
                    pagination={false}
                    size='small'
                    rowKey='id' /> :
                <Upload.Dragger
                    action={this.getUploadUrl()}
                    onChange={this.onChange}
                    showUploadList={false}
                    accept='.xlsx'>
                    <p className='ant-upload-drag-icon'>
                        <InboxOutlined />
                    </p>
                    <p className='ant-upload-text'>Чтобы загрузить файл просто перетащите его сюда</p>
                </Upload.Dragger>
            }
        </Modal>;
    }
}

export default withStateHandlers(props => ({
    test: props.params.tests[0].id,
    file: null
}), {
    setFile: () => file => ({ file }),
    setTest: () => test => ({ test })
})(
    withAsyncActions({
        postCompanyRespondentsImport: postCompanyRespondentsImport
            .withSuccessHandler(({ close, params: { onSuccess }, postCompanyRespondentsImport }) => {
                message.success('Респонденты были успешно импортированы');
                onSuccess && onSuccess(postCompanyRespondentsImport.data.items || []);
                close();
            })
            .withErrorHandler(() => message.error('Не удалось импортировать респондентов'))
            .withOptions({ resetOnUnmount: true }),
        getCompanyRespondentsImport: getCompanyRespondentsImport
            .withPayload(({ test, params: { company, project }, file }) => ({
                test,
                company,
                project,
                id: file
            }))
            .withOptions({ resetOnUnmount: true })
    })(ImportRespondentsModal)
);
