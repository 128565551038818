import React, { Component, Fragment } from 'react';
import { withAsyncActions } from 'react-async-client';
import { Spin } from 'antd';
import styled from 'styled-components';

import DetailToolbar from '../DetailToolbar';
import ProjectRespondentsStep from '../../forms/project/ProjectRespondentsStep';
import { getCompanyProject } from '../../../actions/asyncActions';

const Wrapper = styled.div`
    padding :15px;
    background: #fff;
`;

class ProjectRespondentsAdd extends Component {
    render() {
        const { getCompanyProject: { data, meta }, match: { params: { company, id }}} = this.props;

        return <Fragment>
            <DetailToolbar title={<h1>Добавить респондентов</h1>} backUrl={`/company/${company}/projects/${id}/respondents/add`} />
            <Wrapper>
                { meta.pending ?
                    <Spin /> :
                    <ProjectRespondentsStep
                        item={data}
                        company={data.company}
                        submitBtn={<div />}
                        hideAll />
                }
            </Wrapper>
        </Fragment>;
    }
}

export default withAsyncActions({
    getCompanyProject: getCompanyProject
        .withPayload(({ match: { params: { id, company }}}) => ({ id, company }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})(ProjectRespondentsAdd);
