import React, { Component } from 'react';
import styled from 'styled-components';
import { pathOr, path } from 'ramda';
import { Button, message, Popconfirm, Tooltip } from 'antd';
import { asyncConnect } from 'react-async-client';
import { EditOutlined, RollbackOutlined, DeleteOutlined, EyeInvisibleOutlined, LoadingOutlined } from '@ant-design/icons';

import { openProjectNameModal } from '../../../actions/modalActions';
import { deleteProject, patchCompanyProjects } from '../../../actions/asyncActions';
import { withRouter } from 'react-router';

const Wrapper = styled.div`
    padding: 15px;
`;

const NameInfo = styled.div`
    margin-bottom: 5px;
    border-bottom: 1px solid #e8e8e8;
    padding: 16px 0;
    &:last-child{
        border: 0;
    }
    strong{
        min-width: 150px;
        display: inline-block;
    }
`;
const TeamInfo = styled.div`
    margin-bottom: 5px;
    padding: 16px 0;
    strong{
        min-width: 150px;
        display: inline-block;
    }
    ul{
        display: inline-block;
        padding: 0;
        list-style: none;
    }
`;

class ProjectNameTabButtonsComponent extends Component {
    changeStatus = value => {
        const { patchCompanyProjects, match: { params: { id, company }}} = this.props;

        patchCompanyProjects.dispatch({
            items: [{id, company}],
            data: [{ op: 'replace', path: '/status', value }]
        });
    }

    render() {
        const { getCompanyProject: { data }, openProjectNameModal, deleteProject, match } = this.props;

        return <Button.Group>
            <Button icon={<EditOutlined />} type='primary' onClick={() => openProjectNameModal(data)}><span className='hide-mobile'>Редактировать</span></Button>
            { data.status !== 'active' &&
                <Button icon={<RollbackOutlined />} onClick={() => this.changeStatus('active')}>
                    <span className='hide-mobile'>Вернуть в работу</span>
                </Button>
            }
            { data.status !== 'hidden' &&
                <Button icon={<EyeInvisibleOutlined />} onClick={() => this.changeStatus('hidden')} type='danger'>
                    <span className='hide-mobile'>Скрыть</span>
                </Button>
            }
            <Popconfirm
                title='Вы уверены, что хотите удалить тест?'
                okText='Да'
                cancelText='Нет'
                placement='left'
                onConfirm={() => deleteProject.dispatch(match.params.id)}
                disabled={!data.deletable}
            >
                <Tooltip
                    placement="left"
                    title={data.deletable ? false : <span>Невозможно удалить тест с респондентами<br /> в статусе в работе/пройден</span>}
                >
                    <Button
                        danger
                        disabled={!data.deletable}
                        icon={deleteProject.meta.pending ? <LoadingOutlined /> : <DeleteOutlined />}
                        loading={deleteProject.meta.pending}
                    >Удалить</Button>
                </Tooltip>
            </Popconfirm>
        </Button.Group>;
    }
}

export const ProjectNameTabButtons = withRouter(asyncConnect({
    deleteProject: deleteProject
    .withSuccessHandler(({ history }) => {
            message.success('Тест успешно удален');
            history.push('/');
        })
        .withErrorHandler(() => message.error('Не удалось удалить тест'))
        .withOptions({ resetOonUnmount: true }),
    patchCompanyProjects: patchCompanyProjects
        .withSuccessHandler(({ patchCompanyProjects }) => {
            const status = path(['data', 0, 'status'], patchCompanyProjects);

            message.success(status === 'active' ? 'Проект возвращен в работу' : status === 'hidden' ? 'Проект скрыт' : 'Проект удален');
        })
        .withErrorHandler(() => message.error('Не удалось изменить статус'))
        .withOptions({ resetOnUnmount: true })
}, null, { openProjectNameModal })(ProjectNameTabButtonsComponent));

export default class ProjectNameTab extends Component {
    render() {
        const { project } = this.props;
        const collaborators = pathOr([], ['_embedded', 'collaborators'], project);

        return <Wrapper className='wrap-bg'>
            <NameInfo><strong>Название:</strong> { project.name }</NameInfo>
            { !!collaborators.length &&
                <TeamInfo>
                    <strong>Команда проекта:</strong>
                    <ul>
                        { collaborators.map(({ fullName, id }) =>
                            <li key={id}>{ fullName }</li>
                        )}
                    </ul>
                </TeamInfo>
            }
        </Wrapper>;
    }
}
