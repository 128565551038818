import React, { Component } from 'react';
import { withAsyncActions, toSuccess } from 'react-async-client';
import { List, Modal as AntdModal, Button, message, Popconfirm } from 'antd';
import styled from 'styled-components';
import { append, without, contains } from 'ramda';
import { withStateHandlers } from 'recompose';
import { takeEvery } from 'redux-saga/effects';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

import Modal from './Modal';
import { getTestGroups, postTestGroup, putTestGroup, deleteTestGroup } from '../../actions/asyncActions';
import TestGroupForm from '../forms/TestGroupForm';
import { POST_TEST_GROUP, PUT_TEST_GROUP, DELETE_TEST_GROUP } from '../../constants/actionTypes';

const StyledModal = styled(AntdModal)`
    .ant-modal-body {
        padding: 0;
    }
    .ant-list-item > div {
        width: 100%;
    }
`;

const AddButtonWrapper = styled.div`
    padding: 15px;
    text-align: right;
    border-bottom: 1px solid #e8e8e8;
`;

const AddTestGroup = styled.div`
    padding: 15px;
    border-top: 1px solid #e8e8e8;
`;

const Item = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

class TestGroupsModal extends Component {
    renderItem = item => {
        const { editForms, putTestGroup, addEditForm, deleteTestGroup, removeEditForm } = this.props;

        return <List.Item>
            { contains(item.id, editForms) ?
                <TestGroupForm
                    formAction={putTestGroup}
                    item={item}
                    onClose={() => removeEditForm(item.id)} /> :
                <Item>
                    { item.name }
                    <Button.Group>
                        <Button icon={<EditOutlined />} onClick={() => addEditForm(item.id)} />
                        <Popconfirm
                            title='Вы уверены, что хотите удалить тест группу?'
                            okText='Да'
                            cancelText='Нет'
                            placement='left'
                            onConfirm={() => deleteTestGroup.dispatch(item.id)}>
                            <Button type='danger' icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </Button.Group>
                </Item> }
        </List.Item>;
    }

    openAddForm = () => this.props.setAddForm(true);

    closeAddForm = () => this.props.setAddForm(false);

    render() {
        const { modal, getTestGroups: { data, meta }, addForm, postTestGroup } = this.props;

        return <Modal
            {...modal}
            title='Редактировать тест группы'
            footer={null}
            ModalComponent={StyledModal}>
            <AddButtonWrapper>
                <Button
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={this.openAddForm}>
                    <span className='hide-mobile'>Добавить</span>
                </Button>
            </AddButtonWrapper>
            <List
                dataSource={data.items}
                renderItem={this.renderItem}
                loading={meta.pending} />
            { addForm &&
                <AddTestGroup>
                    <TestGroupForm
                        formAction={postTestGroup}
                        onClose={this.closeAddForm} />
                </AddTestGroup>
            }
        </Modal>;
    }
}

export default withStateHandlers({
    addForm: false,
    editForms: []
}, {
    setAddForm: () => addForm => ({ addForm }),
    addEditForm: ({ editForms }) => id => ({ editForms: append(id, editForms) }),
    removeEditForm: ({ editForms }) => id => ({ editForms: without([id], editForms) })
})(
    withAsyncActions({
        getTestGroups: getTestGroups
            .withSaga(function* (getProps) {
                yield takeEvery([toSuccess(POST_TEST_GROUP), toSuccess(PUT_TEST_GROUP), toSuccess(DELETE_TEST_GROUP)], () => {
                    getProps().getTestGroups.refresh();
                });
            })
            .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
        postTestGroup: postTestGroup
            .withSuccessHandler(({ setAddForm }) => {
                message.success('Тест группа успешно добавлена');
                setAddForm(false);
            })
            .withErrorHandler(() => message.error('Не удалось добавить тест группу'))
            .withOptions({ resetOnUnmount: true }),
        putTestGroup: putTestGroup
            .withSuccessHandler(({ removeEditForm, putTestGroup }) => {
                message.success('Тест группа успешно изменена');
                removeEditForm(putTestGroup.data.id);
            })
            .withErrorHandler(() => message.error('Не удалось изменить тест группу'))
            .withOptions({ resetOnUnmount: true }),
        deleteTestGroup: deleteTestGroup
            .withSuccessHandler(() => message.success('Тест группа была успешно удалена'))
            .withErrorHandler(() => message.error('Не удалось удалить тест группу'))
            .withOptions({ resetOnUnmount: true })
    })(TestGroupsModal)
);
