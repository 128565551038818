import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';

import Modal from './Modal';
import CompanyDetailsForm from '../forms/CompanyDetailsForm';
import { putPaymentBankDetails } from '../../actions/asyncActions';
import { message } from 'antd';

class PaymentBankDetailsModal extends Component {
    mapBeforeSubmit = data => {
        return {
            id: this.props.params.id,
            data
        };
    }

    render() {
        const { modal, putPaymentBankDetails, params } = this.props;

        return <Modal
            {...modal}
            title='Банковский реквизиты'
            footer={null}>
            <CompanyDetailsForm
                formAction={putPaymentBankDetails}
                item={params.bankDetails}
                mapBeforeSubmit={this.mapBeforeSubmit}
                company={{ id: params.company }}
                hideCancel />
        </Modal>
    }
}

export default withAsyncActions({
    putPaymentBankDetails: putPaymentBankDetails
        .withParams(() => ({ type: 'modal' }))
        .withSuccessHandler(({ close }) => {
            message.success('Банковские реквизиты успешно сохранены');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось сохранить банковские реквизиты'))
        .withOptions({ resetOnUnmount: true })
})(PaymentBankDetailsModal);
