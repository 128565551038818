import React, { Component } from 'react';
import { CalendarOutlined, LoginOutlined, LogoutOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Modal as AntdModal } from 'antd';

import Modal from './Modal';
import { messageHistoryPayment } from '../../actions/asyncActions';
import DateFormat from '../user/table/DateFormat';
import MessageHistoryFilter from '../forms/filters/MessageHistoryFilter';
import TableStateList from '../user/table/TableStateList';

const StyledModal = styled(AntdModal)`
    .ant-modal-body {
        padding: 0;
    }
`;

class PaymentsMessageHistoryModal extends Component {
    getColumns = () => {
        return [
            {
                dataIndex: 'type',
                key: 'type',
                render: type => type === 'consumer' ? <LoginOutlined /> : <LogoutOutlined />
            },
            {
                title: 'Сообщение',
                key: 'data',
                dataIndex: 'data'
            },
            {
                title: 'Дата создания',
                key: 'createdAt',
                dataIndex: 'createdAt',
                render: date => <DateFormat date={date} icon={<CalendarOutlined />} withTime />
            },
            {
                title: 'Дата обновления',
                key: 'updatedAt',
                dataIndex: 'updatedAt',
                render: date => <DateFormat date={date} icon={<CalendarOutlined />} withTime />
            }
        ]
    }

    render() {
        const { modal, params } = this.props;

        return <Modal
            {...modal}
            title='Служебная информация'
            ModalComponent={StyledModal}
            footer={null}>
            <TableStateList
                action={messageHistoryPayment}
                payload={{ payment: params.id }}
                columns={this.getColumns()}
                filterForm={MessageHistoryFilter}
                defaultSort={{
                    sort_by: 'createdAt',
                    sort_order: 'desc'
                }} />
        </Modal>
    }
}

export default PaymentsMessageHistoryModal;
