import React, { Component } from 'react';
import PreviewPageWrapper from './PreviewPageWrapper';

import { Question } from '@planka/common';

export default class QuestionPreviewPage extends Component {
    static defaultProps = {
        colors: {},
        num: 0,
        questionsCount: 1
    };

    render() {
        const {
            num,
            colors,
            fontFamily,
            showName,
            logo,
            onChangePage,
            questionsCount
        } = this.props;
        const question = this.props.question || {};

        return <PreviewPageWrapper logo={logo} showName={showName} fontFamily={fontFamily} colors={colors}>
            <Question
                question={{
                    title: question.title,
                    answerVariants: ((question.type === 'choice' ? question.answerVariants : question.rangingAnswerVariants) || []).map((item, index) => item.id ? item : {...item, id: `item-${index}`}),
                    type: question.type,
                    num,
                    multipleAnswers: question.multipleAnswers
                }}
                colors={colors}
                fontFamily={fontFamily}
                questionsCount={questionsCount}
                onChangePage={onChangePage}
                questionNum={num + 1} />
        </PreviewPageWrapper>;
    }
}
