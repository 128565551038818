import React, { Component, Fragment } from 'react';
import { asyncConnect, toSuccess } from 'react-async-client';
import { contains, path, keys, pathOr } from 'ramda';
import moment from 'moment';
import { Row, Col, Button, Dropdown, Menu, Popconfirm, Spin, message } from 'antd';
import { withRouter } from 'react-router-dom';
import { takeEvery } from 'redux-saga/effects';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { DownOutlined, LinkOutlined, DeleteOutlined, EllipsisOutlined, EditOutlined, FilePdfOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { getSurveyRespondent, getSurveyRespondentCustomerFocus, getSurvey, deleteSurveyRespondent, getSurveyRespondentDriverPlus, getSurveyRespondentDriverPlusIpsative } from '../../../actions/asyncActions';
import { openSurveyRespondentModal } from '../../../actions/modalActions';
import { PUT_SURVEY_RESPONDENT } from '../../../constants/actionTypes';
import { RespondentHeader } from '../projects/ProjectRespondent';
import SurveyRespondentStat from './SurveyRespondentStat';
import { downloadCustomerFocusRespondentPdf, downloadDriverPlusRespondentPdf, downloadDriverPlusIpsativeRespondentPdf, downloadDriverPlusIpsativeClientPdf, DRIVER_PLUS_RESULTS } from '../../../utils/survey';
import { getResponseHost } from '../../../utils/urlParams';
import DriverPlusChart from './DriverPlusChart';
import driverPlusChartSvg from '../../../assets/img/driverPlusChart.svg';
import driverPlusResult1 from '../../../assets/img/driverPlusResults/1.svg';
import driverPlusResult2 from '../../../assets/img/driverPlusResults/2.svg';
import driverPlusResult3 from '../../../assets/img/driverPlusResults/3.svg';
import driverPlusResult4 from '../../../assets/img/driverPlusResults/4.svg';
import driverPlusResult5 from '../../../assets/img/driverPlusResults/5.svg';
import driverPlusResult6 from '../../../assets/img/driverPlusResults/6.svg';
import driverPlusResult7 from '../../../assets/img/driverPlusResults/7.svg';
import driverPlusResult8 from '../../../assets/img/driverPlusResults/8.svg';
import driverPlusResult9 from '../../../assets/img/driverPlusResults/9.svg';
import driverPlusResult10 from '../../../assets/img/driverPlusResults/10.svg';

export const MenuIcon = styled.div`
    min-width: 12px;
    margin-right: 8px;
    display: inline-block;
`;

const Categories = styled.div`
    margin-top: 30px;
    background: #fff;
    padding: 15px;
    border-left: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    h1{
        border: 1px solid #eaeaea;
        background: #fafafa;
        margin: -30px -16px 15px;
        padding: 10px 15px;
    }
`;

const DriverPlusChartWrapper = styled.div`
    text-align: center;
    position: relative;
    height: 620px;
    img {
        position: absolute;
        top: 10px;
        width: 570px;
        left: calc(50% - 285px);
    }
    .driver-plus-chart {
        position: absolute;
        top: 0;
        left: calc(50% - 300px);
    }
`;

const DRIVER_PLUS_RESULT_IMG = {
    1: driverPlusResult1,
    2: driverPlusResult2,
    3: driverPlusResult3,
    4: driverPlusResult4,
    5: driverPlusResult5,
    6: driverPlusResult6,
    7: driverPlusResult7,
    8: driverPlusResult8,
    9: driverPlusResult9,
    10: driverPlusResult10
};

const isCustomerFocusReport = survey => survey ? contains('customer_focus', survey.availableReports || []) : false;
const isDriverPlusReport = survey => survey ? contains('driver_plus', survey.availableReports || []) : false;
const isDriverPlusIpsativeReport = survey => survey ? contains('driver_plus_ipsative', survey.availableReports || []) : false;

class Actions extends Component {
    state = {
        visible: false,
        visibleIpsative: false,
    };

    onCopyResponseLink = () => message.success('Ссылка успешно скопирована в буфер обмена');

    getResponseLink = id => `${getResponseHost()}/survey_respondents/${id}`;

    getIpsativeActionsMenu = () => {
        return <Menu
            onClick={this.handleMenuClick}>
                <Menu.Item style={{ padding: 0 }} key='respondent' disabled={!this.props.getSurveyRespondentDriverPlusIpsative.meta.success} onClick={this.downloadDriverPlusIpsativePdf}>
                    <div style={{ padding: '5px 12px' }}>
                        <MenuIcon>
                            <FilePdfOutlined />
                        </MenuIcon>
                        Индивидуальный отчет для респондента
                    </div>
                </Menu.Item>
                <Menu.Item style={{ padding: 0 }} key='client' disabled={!this.props.getSurveyRespondentDriverPlusIpsative.meta.success} onClick={this.downloadDriverPlusIpsativeClientPdf}>
                    <div style={{ padding: '5px 12px' }}>
                        <MenuIcon>
                            <FilePdfOutlined />
                        </MenuIcon>
                        Индивидуальный отчет для руководителя
                    </div>
                </Menu.Item>
        </Menu>;
    }

    getActionsMenu = () => {
        const { item } = this.props;

        return <Menu
            onClick={this.handleMenuClick}>
            <Menu.Item style={{ padding: 0 }} key='link'>
                <CopyToClipboard text={this.getResponseLink(item.id)} onCopy={this.onCopyResponseLink}>
                    <div style={{ padding: '5px 12px' }}>
                        <MenuIcon>
                            <LinkOutlined />
                        </MenuIcon>
                        Скопировать ссылку
                    </div>
                </CopyToClipboard>
            </Menu.Item>
            <Menu.Item style={{ padding: 0 }} key='delete'>
                <Popconfirm
                    okText='Да'
                    cancelText='Нет'
                    title='Вы уверены, что хотите удалить респондента?'
                    onConfirm={() => this.props.delete(item.id)}
                    placement='left'>
                    <div style={{ color: '#f5222d', padding: '5px 12px' }}>
                        <MenuIcon>
                            <DeleteOutlined />
                        </MenuIcon>
                        Удалить
                    </div>
                </Popconfirm>
            </Menu.Item>
        </Menu>;
    }

    handleMenuClick = e => {
        if (e.key !== 'delete') {
            this.setState({ visible: false });
        }
    }

    handleMenuVisibleFlag = visible => this.setState({ visible });

    handleMenuVisibleIpsativeFlag = visibleIpsative => this.setState({ visibleIpsative });

    downloadPdf = () => {
        const { item, getSurveyRespondentCustomerFocus: { data } } = this.props;
        downloadCustomerFocusRespondentPdf(item, data);
    }

    downloadDriverPlusPdf = () => {
        const { item, getSurveyRespondentDriverPlus: { data }} = this.props;
        downloadDriverPlusRespondentPdf(item, data);
    }

    downloadDriverPlusIpsativePdf = () => {
        const { item, getSurveyRespondentDriverPlusIpsative: { data }} = this.props;
        downloadDriverPlusIpsativeRespondentPdf(item, data);
    }

    downloadDriverPlusIpsativeClientPdf = () => {
        const { item, getSurveyRespondentDriverPlusIpsative: { data }} = this.props;
        downloadDriverPlusIpsativeClientPdf(item, data);
    }

    render() {
        return <Fragment>
            <Button.Group>
                <Button
                    icon={<EditOutlined />}
                    onClick={this.props.edit}>
                    { this.props.single && 'Редактировать' }
                </Button>
                { path(['response', 'finishedAt'], this.props.item) && this.props.hasDriverPlusReport && (
                    <Button
                        icon={<FilePdfOutlined />}
                        disabled={!this.props.getSurveyRespondentDriverPlus.meta.success}
                        onClick={this.downloadDriverPlusPdf}>
                        <span className='hide-mobile'>Скачать отчет</span>
                    </Button>
                )}
                { path(['response', 'finishedAt'], this.props.item) && this.props.hasCustomerFocusReport && (
                    <Button
                        icon={<FilePdfOutlined />}
                        disabled={!this.props.getSurveyRespondentCustomerFocus.meta.success}
                        onClick={this.downloadPdf}>
                        <span className='hide-mobile'>Скачать отчет</span>
                    </Button>
                )}
                { path(['response', 'finishedAt'], this.props.item) && this.props.hasDriverPlusIpsativeReport && (
                    <Dropdown
                        trigger={['click']}
                        overlay={this.getIpsativeActionsMenu()}
                        visible={this.state.visibleIpsative}
                        onVisibleChange={this.handleMenuVisibleIpsativeFlag}>
                        <Button icon={<FilePdfOutlined />}>Скачать отчет <DownOutlined /></Button>
                    </Dropdown>
                )}
                <Dropdown
                    trigger={['click']}
                    overlay={this.getActionsMenu()}
                    visible={this.state.visible}
                    onVisibleChange={this.handleMenuVisibleFlag}>
                    <Button icon={<EllipsisOutlined />} />
                </Dropdown>
            </Button.Group>
        </Fragment>;
    }
}

const ActionsComponent = asyncConnect(({ hasCustomerFocusReport, hasDriverPlusReport, hasDriverPlusIpsativeReport }) => ({
    getSurveyRespondentCustomerFocus: getSurveyRespondentCustomerFocus
        .withPayload(({ item }) => ({ id: item.id, survey: item.survey }))
        .withOptions({ dispatchOnMount: hasCustomerFocusReport, resetOnUnmount: true, dispatchOnUpdate: hasCustomerFocusReport }),
    getSurveyRespondentDriverPlus: getSurveyRespondentDriverPlus
        .withPayload(({ item }) => ({ id: item.id, survey: item.survey }))
        .withOptions({ dispatchOnMount: hasDriverPlusReport, resetOnUnmount: true, dispatchOnUpdate: hasDriverPlusReport }),
    getSurveyRespondentDriverPlusIpsative: getSurveyRespondentDriverPlusIpsative
        .withPayload(({ item }) => ({ id: item.id, survey: item.survey }))
        .withOptions({ dispatchOnMount: hasDriverPlusIpsativeReport, resetOnUnmount: true, dispatchOnUpdate: hasDriverPlusReport })
}))(Actions);

class SurveyRespondentButtonsComponent extends Component {
    getGroups = () => {
        return keys(this.props.getSurvey.data.groups || {}).map(value => ({ id: value, value }));
    }

    delete = id => {
        this.props.deleteSurveyRespondent.dispatch(id);
    }

    render() {
        const { getSurveyRespondent: { data, meta }, getSurvey: { data : survey } } = this.props;

        const hasCustomerFocusReport = isCustomerFocusReport(survey);
        const hasDriverPlusReport = isDriverPlusReport(survey);
        const hasDriverPlusIpsativeReport = isDriverPlusIpsativeReport(survey);

        return meta.lastSucceedAt &&
            <ActionsComponent
                single
                item={data}
                survey={survey}
                hasCustomerFocusReport={hasCustomerFocusReport}
                hasDriverPlusReport={hasDriverPlusReport}
                hasDriverPlusIpsativeReport={hasDriverPlusIpsativeReport}
                edit={() => this.props.openSurveyRespondentModal({
                    item: data,
                    groups: this.getGroups(),
                })}
                delete={this.delete} />;
    }
}

export const SurveyRespondentButtons = withRouter(
    asyncConnect({
        getSurveyRespondent,
        getSurvey,
        deleteSurveyRespondent: deleteSurveyRespondent
            .withSuccessHandler(({ history, getSurvey }) => {
                message.success('Респондент успешно удален');
                history.replace(`/surveys/${getSurvey.data.id}/respondents`)
            })
            .withErrorHandler(() => message.error('Не удалось удалить респондента'))
            .withOptions({ resetOnUnmount: true })
    }, null, { openSurveyRespondentModal })(SurveyRespondentButtonsComponent)
);

class ProjectRespondent extends Component {
    render() {
        const { survey, getSurveyRespondent: { data, meta }, getSurveyRespondentCustomerFocus: { data : result }, getSurveyRespondentDriverPlus, getSurveyRespondentDriverPlusIpsative } = this.props;
        const sections = path(['_embedded', 'survey', 'sections'], data) || [];
        const startedAt = path(['response', 'startedAt'], data);
        const finishedAt = path(['response', 'finishedAt'], data);

        return meta.pending && !meta.lastSucceedAt ?
            <Spin /> :
            <Fragment>
                <RespondentHeader data={data} />
                { startedAt && <div><strong>Дата начала прохождения: </strong>{ moment(startedAt).format('DD.MM.YYYY') }</div> }
                { finishedAt && <div><strong>Дата завершения прохождения: </strong>{ moment(finishedAt).format('DD.MM.YYYY') }</div> }
                { finishedAt && !!sections.length && (
                    <Categories>
                        <h1>Форма</h1>
                        { sections.map((section, index) =>
                            <Row key={`additional-data-${index}`}>
                                <Col span={8} dangerouslySetInnerHTML={{ __html: section.title || '-' }} />
                                <Col span={16}>{
                                    section.type === 'checkbox' ? (path(['sections', section.key], data) ? <CheckOutlined /> : <CloseOutlined />)
                                    : path(['sections', section.key], data)
                                }</Col>
                            </Row>
                        )}
                    </Categories>
                )}
                { finishedAt && isCustomerFocusReport(survey) && (
                    <Categories>
                        <h1>Customer Focus</h1>
                        <SurveyRespondentStat respondent={data} data={result} />
                    </Categories>
                )}
                { finishedAt && isDriverPlusIpsativeReport(survey) && (
                    <Categories>
                        <h1>Driver+ (i)</h1>
                        <DriverPlusChartWrapper>
                            <img src={driverPlusChartSvg} alt='driver-plus' />
                            <DriverPlusChart data={getSurveyRespondentDriverPlusIpsative.data} />
                        </DriverPlusChartWrapper>
                        { DRIVER_PLUS_RESULTS.map(result =>
                            <div key={`driver-plus-${result.id}`} style={{ borderTop: '1px solid #ccc', paddingTop: 10, marginTop: 10 }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3><strong>{ result.title }</strong></h3>
                                    { !!pathOr(0, ['integralScores', 'blocks', result.id], data) && <img src={DRIVER_PLUS_RESULT_IMG[pathOr(0, ['integralScores', 'blocks', result.id], getSurveyRespondentDriverPlus.data)]} alt={`driver-plus-${result.id}`} />}
                                </div>
                                <div>{ result.description }</div>
                            </div>
                        )}
                    </Categories>
                )}
                { finishedAt && isDriverPlusReport(survey) && (
                    <Categories>
                        <h1>Driver+ (n)</h1>
                        <DriverPlusChartWrapper>
                            <img src={driverPlusChartSvg} alt='driver-plus' />
                            <DriverPlusChart data={getSurveyRespondentDriverPlus.data} />
                        </DriverPlusChartWrapper>
                        { DRIVER_PLUS_RESULTS.map(result =>
                            <div key={`driver-plus-${result.id}`} style={{ borderTop: '1px solid #ccc', paddingTop: 10, marginTop: 10 }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3><strong>{ result.title }</strong></h3>
                                    { !!pathOr(0, ['integralScores', 'blocks', result.id], data) && <img src={DRIVER_PLUS_RESULT_IMG[pathOr(0, ['integralScores', 'blocks', result.id], getSurveyRespondentDriverPlus.data)]} alt={`driver-plus-${result.id}`} />}
                                </div>
                                <div>{ result.description }</div>
                            </div>
                        )}
                    </Categories>
                )}
            </Fragment>;
    }
}

export default asyncConnect({
    getSurveyRespondentCustomerFocus: getSurveyRespondentCustomerFocus
        .withPayload(({ match, survey }) => ({ id: match.params.itemId, survey: survey.id })),
    getSurveyRespondentDriverPlus: getSurveyRespondentDriverPlus
        .withPayload(({ match, survey }) => ({ id: match.params.itemId, survey: survey.id })),
    getSurveyRespondentDriverPlusIpsative: getSurveyRespondentDriverPlusIpsative
        .withPayload(({ match, survey }) => ({ id: match.params.itemId, survey: survey.id })),
    getSurveyRespondent: getSurveyRespondent
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_SURVEY_RESPONDENT)], () => {
                getProps().getSurveyRespondent.refresh();
            })
        })
        .withPayload(({ match }) => match.params.itemId)
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
})(ProjectRespondent);
