import React, { Component } from 'react';
import styled from 'styled-components';
import { QuestionMark } from '@styled-icons/boxicons-regular/QuestionMark';
import { Timer } from '@styled-icons/material/Timer';
import { ArrowForward } from '@styled-icons/typicons/ArrowForward';
import { Pause } from '@styled-icons/material/Pause';

import PreviewPageWrapper from './PreviewPageWrapper';

const Title = styled.h1`
    color: ${({ color }) => color || '#2B3D4F'};
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 15px;
`;

const Description = styled.div`
    color: ${({ color }) => color || '#2B3D4F'};
    font-size: 16px;
    line-height: 1.7em;
    font-weight: normal;
`;

const Left = styled.div`
    @media (min-width: 720px) {
        width: 49%;
        float: left;
        padding-right: 30px;
    }
`;

const Right = styled.div`
    @media (min-width: 720px) {
        width: 49%;
        float: right;
        padding-left: 30px;
    }
`;

const InfoBlock = styled.div`
    color: ${({ color }) => color || '#2B3D4F'};
    border-bottom: 1px solid ${({ border }) => border || '#F3F3F3'};
    &:after {
        clear: both;
        content: " ";
        display: table;
    }
`;

const InfoItem = styled.div`
    margin-bottom: 10px;
    float: left;
    width: 50%;
`;

const InfoValue = styled.span`
    font-weight: 400;
`;

const InfoTitle = styled.span`
    font-size: 14px;
    margin: 0 10px;
    font-weight: normal;
`;

const IconWrapper = styled.div`
    color: #646464;
    background-color: ${({ background }) => background || '#F3F3F3'};
    width: 34px;
    height: 34px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    padding-top: 5px;
`;

const Input = styled.input`
    outline: 0;
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.4;
    background-color: #ededed;
    font-weight: bold;
    color: #2b3d4f;
    background-image: none;
    border: 1px solid ${({ color }) => color || 'transparent'};
    appearance: none;
    transition: border-color 580ms cubic-bezier(0.25,.1,.25,1);
`;

const Label = styled.div`
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 15px;
    font-weight: bold;
    color: ${({ color }) => color || '#2B3D4F'};
`;

const SubmitButton = styled.button`
    background-color: ${({ color }) => color || '#F54D2E'};
    color: #fff;
    line-height: 1.33;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    touch-action: manipulation;
    cursor: pointer;
    border: 1px solid transparent;
    white-space: nowrap;
    user-select: none;
    &:hover {
        opacity: 0.8;
    }
    &:focus {
        outline: none;
    }
    &[disabled] {
        box-shadow: none;
        opacity: .55;
        pointer-events: none;
        cursor: not-allowed;
    }
    margin: 15px 0 15px;
    padding: 10px 16px;
    font-size: 14px;
`;

const StyledRadio = styled.div`
    display: ${({ inline }) => inline ? 'inline-block' : 'block'};
    text-align: left;
    margin-right: 10px;
    input {
        position: absolute;
        margin-left: -15px;
        opacity: 0;
        :checked+label {
            color: ${({ activeColor }) => activeColor || 'rgb(43 61 79)'};
            &:before {
                box-shadow: 0 0 0 2px ${({ activeColor }) => activeColor || 'rgb(43 61 79)'} inset, 0 0 0 6px #ffffff inset, 0 0 0 12px ${({ activeColor }) => activeColor || 'rgb(43 61 79)'} inset, 0 0 0 24px transparent;
            }
        }
    }
    label {
        position: relative;
        display: inline-block;
        margin: 7px 20px 7px 36px;
        line-height: 26px;
        font-weight: normal;
        cursor: pointer;
        transition: color 80ms cubic-bezier(0.82,.14,.44,.41);
        font-size: ${({ fontSize }) => fontSize || 16}px;
        color: ${({ labelColor }) => labelColor || '#2B3D4F'};
        &:before {
            position: absolute;
            top: 0;
            margin-top: 1px;
            left: -36px;
            content: " ";
            display: inline-block;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: #ededed;
            border: 0;
            box-shadow: none;
            transition: box-shadow 280ms cubic-bezier(0.82,.14,.44,.41);
        }
    }
`;

export default class WelcomePreviewPage extends Component {
    state = {
        gender: null
    };

    onChangeGender = e => this.setState({ gender: e.target.value });

    render() {
        const { logo, showName, fontFamily, colors } = this.props;

        return <PreviewPageWrapper logo={logo} showName={showName} fontFamily={fontFamily} colors={colors}>
            <Left>
                <Title color={colors.text}>Название теста</Title>
                <Description color={colors.text}>Описание теста</Description>
            </Left>
            <Right>
                <InfoBlock color={colors.text} border={colors.elementStatistic}>
                    <InfoItem>
                        <IconWrapper background={colors.elementStatistic}><QuestionMark size={15} /></IconWrapper>
                        <InfoTitle>Вопросы:</InfoTitle>
                        <InfoValue>3</InfoValue>
                    </InfoItem>
                    <InfoItem>
                        <IconWrapper background={colors.elementStatistic}><Timer size={15} /></IconWrapper>
                        <InfoTitle>Время:</InfoTitle>
                        <InfoValue>00:20</InfoValue>
                    </InfoItem>
                    <InfoItem>
                        <IconWrapper background={colors.elementStatistic}><ArrowForward size={15} /></IconWrapper>
                        <InfoTitle>Пропуск вопросов:</InfoTitle>
                        <InfoValue>да</InfoValue>
                    </InfoItem>
                    <InfoItem>
                        <IconWrapper background={colors.elementStatistic}><Pause size={15} /></IconWrapper>
                        <InfoTitle>Пауза:</InfoTitle>
                        <InfoValue>да</InfoValue>
                    </InfoItem>
                </InfoBlock>
                <Label color={colors.textInput}>Возраст</Label>
                <Input type='number' color={colors.elementStatistic} />
                <Label color={colors.textInput}>Пол</Label>
                <div>
                    <StyledRadio inline fontSize={14} activeColor={colors.input} labelColor={colors.textInput}>
                        <input
                            id='male'
                            type='radio'
                            value='male'
                            onChange={this.onChangeGender}
                            checked={this.state.gender === 'male'} />
                        <label htmlFor='male'>Мужской</label>
                    </StyledRadio>
                    <StyledRadio inline fontSize={14} activeColor={colors.input} labelColor={colors.textInput}>
                        <input
                            id='female'
                            type='radio'
                            value='female'
                            onChange={this.onChangeGender}
                            checked={this.state.gender === 'female'} />
                        <label htmlFor='female'>Женский</label>
                    </StyledRadio>
                </div>
                <Label color={colors.textInput}>Регион</Label>
                <Input color={colors.elementStatistic} />
                <Label color={colors.textInput}>Опыт работы в должности</Label>
                <Input type='number' color={colors.elementStatistic} />
                <Label color={colors.textInput}>Уровень образования</Label>
                <Input color={colors.elementStatistic} />
                <SubmitButton color={colors.input}>
                    Начать тестирование
                </SubmitButton>
            </Right>
        </PreviewPageWrapper>;
    }
}
