import React, { Component } from 'react';
import { message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import ProjectCategories from '../forms/project/ProjectCategories';
import { putCompanyProject } from '../../actions/asyncActions';
import Modal from './Modal';

class ProjectCategorySelectModal extends Component {
    render() {
        const { modal, params: { lastLevel, item, complete, mapBeforeSubmit, mapPropsToValues, ignoreValid, onlyOwn, disableSave, allowSelectSecondLevel }, formAction } = this.props;

        return <Modal
            {...modal}
            title='Выбор компетенций'
            footer={null}>
            <ProjectCategories
                item={item}
                lastLevel={lastLevel}
                complete={complete}
                formAction={formAction}
                mapBeforeSubmit={mapBeforeSubmit}
                mapPropsToValues={mapPropsToValues}
                ignoreValid={ignoreValid}
                onlyOwn={onlyOwn}
                disableSave={disableSave}
                allowSelectSecondLevel={allowSelectSecondLevel} />
        </Modal>;
    }
}

export default withAsyncActions(({ params }) => ({
    formAction: (params.formAction || putCompanyProject)
        .withParams({ type: 'modal' })
        .withSuccessHandler(({ close }) => {
            message.success('Профиль должности успешно изменен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось изменить профиль должности'))
        .withOptions({ resetOnUnmount: true })
}))(ProjectCategorySelectModal);
