import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { prop } from 'ramda';
import * as yup from 'yup';
import { withAsyncActions } from 'react-async-client';

import SubmitButton from './formComponents/SubmitButton';
import Input from './formComponents/Input';
import { PhoneInput } from './formComponents/MaskedInput';
import { getBusinessUnits } from '../../actions/asyncActions';
import withFormWrapper from '../hocs/withFormWrapper';

const labelCol = {
    xs: { span: 24 },
    sm: { span: 6 },
};

const wrapperCol = {
    xs: { span: 24 },
    sm: { span: 18 },
};

class CompanyEmployeeForm extends Component {
    render() {
        return <Fragment>
            <h3>Информация</h3>
            <Field
                name='lastName'
                component={Input}
                label='Фамилия'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='firstName'
                component={Input}
                label='Имя'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='middleName'
                component={Input}
                label='Отчество'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <h3>Контакты</h3>
            <Field
                name='email'
                htmlType='email'
                component={Input}
                label='Email'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='phone'
                component={PhoneInput}
                label='Телефон'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <h3>Прочее</h3>
            <Field
                name='position'
                component={Input}
                label='Должность'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <SubmitButton type='primary'>Сохранить</SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    email: yup.string().email().required()
})

export default withAsyncActions({
    getBusinessUnits: getBusinessUnits
        .withPayload(({ item }) => item.company)
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})(
    withFormWrapper(CompanyEmployeeForm, {
        mapPropsToValues: prop('item'),
        validationSchema
    })
);
