import { take, put, select, takeEvery, all } from 'redux-saga/effects';
import { toSuccess, toError } from 'react-async-client';

import { POST_LOGIN, GET_USER, LOGOUT, POST_REFRESH_TOKEN } from '../constants/actionTypes';
import { getUser } from '../actions/asyncActions';
import { handleTokenChange } from '../utils/token';

function* loginFlow() {
    while (true) {
        yield take([toSuccess(POST_LOGIN)]);
        yield put(getUser());

        yield take([LOGOUT, toError(GET_USER)]);
        const meta = yield select(getUser.selectMeta);
        if (!meta.error) {
            yield put(getUser.reset());
        }
        handleTokenChange(null);
    }
}

export default function* login() {
    yield all([
        loginFlow(),
        takeEvery([toSuccess(POST_LOGIN), toSuccess(POST_REFRESH_TOKEN)], function({ payload }) {
            handleTokenChange(payload);
        })
    ]);
}
