import { append, both, remove, assocPath, startsWith, endsWith } from 'ramda';
import { LOCATION_CHANGE } from 'connected-react-router';

import {
    CLOSE_MODAL,
    HIDE_MODAL,
    SHOW_MODAL,
    REMOVE_MODAL,
    LOGOUT,
} from '../constants/actionTypes';

const isModalOpenAction = both(
    startsWith('OPEN_'),
    endsWith('_MODAL'),
);

export default (state = [], action) => {
    if (isModalOpenAction(action.type)) {
        const modal = {
            type: action.type,
            params: action.payload,
            visible: true
        };

        return append(modal, state);
    }

    switch (action.type) {
        case CLOSE_MODAL:
            return assocPath([action.payload, 'close'], true, state);
        case HIDE_MODAL:
            return assocPath([action.payload, 'visible'], false, state);
        case SHOW_MODAL:
            return assocPath([action.payload, 'visible'], true, state);
        case REMOVE_MODAL:
            return remove(action.payload, 1, state)
        case LOGOUT:
        case LOCATION_CHANGE:
            return [];
        default:
            return state;
    }
};
