import React, { Component } from 'react';
import styled from 'styled-components';
import { Modal as AntdModal } from 'antd';

import { getToken } from '../../utils/token';
import { getResponseHost } from '../../utils/urlParams';
import Modal from './Modal';

const StyledModal = styled(AntdModal)`
    top: 0;
    padding-bottom: 0;
    .ant-modal-content {
        height: 100vh;
        border-radius: 0;
    }
    .ant-modal-body {
        padding: 0;
        height: calc(100% - 55px);
    }
`;

export default class SurveyPreviewModal extends Component {
    render() {
        const { modal, params } = this.props;
        const urlParams = `?demo=1&token=${getToken()}`;
        const uri = `survey_respondents/${params.survey}${params.main ? '' : `/driver/${params.index}`}${urlParams}`;

        return <Modal
            {...modal}
            ModalComponent={StyledModal}
            width='100%'
            title={params.title}
            footer={null}>
                <iframe
                    title='preview'
                    src={`${getResponseHost()}/${uri}`}
                    width='100%'
                    height='100%'
                    frameBorder='0'
                />
        </Modal>;
    }
}
