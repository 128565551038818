import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import { fromPairs } from 'ramda';
import { LoadingOutlined, CloseOutlined, CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';

import Modal from './Modal';
import { getCompanyRespondentPdf } from '../../actions/asyncActions';
import { withAsyncActions } from 'react-async-client';

const Item = styled.div`
    margin-bottom: 5px;
    .anticon-close-circle {
        display: none;
        color: #f54d2e;
        margin-left: 10px;
    }
    &:hover {
        .anticon-close-circle {
            display: inline-block;
        }
    }
`;

class PdfModal extends Component {
    removePdf = (id, reset) => {
        const { close, pdf } = this.props;

        reset();
        pdf.length < 2 && close();
    }

    renderPdf = (file, index) => {
        const { data, meta, reset } = this.props[`getCompanyRespondentPdf_${file.id}`];

        return <Item key={`pdf-file-${index}`}>
            <Tooltip title={meta.error && 'Не удалось загрузить отчет'}>
                { meta.pending ? <LoadingOutlined style={{ marginRight: 10, color: '#1890ff' }} /> :
                    meta.error ? <CloseOutlined style={{ marginRight: 10, color: '#f5222d' }} /> :
                    <CheckOutlined style={{ marginRight: 10, color: '#86c54e' }} />
                }
            </Tooltip>
            { meta.success ? <a href={data.fileUrl} target='_blank' rel='noopener noreferrer'>{ file.name }</a> : file.name }
            <CloseCircleOutlined onClick={() => this.removePdf(index, reset)} />
        </Item>;
    }

    render() {
        const { modal, pdf } = this.props;

        return <Modal
            {...modal}
            title='Отчеты'
            footer={null}>
            { pdf.map(this.renderPdf)}
        </Modal>;
    }
}

const stateToProps = state => ({
    pdf: state.pdf
});

export default connect(stateToProps)(
    withAsyncActions(({ pdf }) => fromPairs(pdf.map(item => ([
        `getCompanyRespondentPdf_${item.id}`,
        getCompanyRespondentPdf.withParams(item)
    ]))))(PdfModal)
);
