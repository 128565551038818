import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import { Button } from 'antd';
import { LayoutOutlined, EditOutlined } from '@ant-design/icons';

import { Title, Block } from '../SurveyInfoTab';
import { openSurveyPageElementsModal } from '../../../../actions/modalActions';

class SurveyElements extends Component {
    render() {
        const { survey, openSurveyPageElementsModal } = this.props;
        const pageElements = pathOr({}, ['translations', 'pageElements'], survey);

        return <div style={{ marginTop: 10 }}>
            <Title>
                <span><LayoutOutlined /></span>
                <Button shape='circle' icon={<EditOutlined />} onClick={() => openSurveyPageElementsModal(survey)} />
            </Title>
            <Block>
                <div><strong>Кнопка "Начать":</strong> { pageElements.startButton || 'Начать опрос' }</div>
                <div><strong>Кнопка "Продолжить":</strong> { pageElements.continueButton || 'Продолжить' }</div>
                <div><strong>Кнопка "Ответить":</strong> { pageElements.submitButton || 'Ответить' }</div>
                <div><strong>Заголовок на завершение:</strong> { pageElements.finishTitle || 'Опрос завершен' }</div>
                <div><strong>Текст на завершение:</strong> { pageElements.finishText ||  'Спасибо за участие!' }</div>
                <div><strong>Текст выбора:</strong> { pageElements.chooseText || 'Ниже представлен ряд утверждений, которые Вам предлагается оценить. Выберите насколько Вы согласны со следующими утверждениями' }</div>
                <div><strong>Текст выбора языка:</strong> { pageElements.chooseLanguage || 'Выберите язык' }</div>
                <div><strong>Плейсхолдер текстовых полей:</strong> { pageElements.placeholder || 'Ответ' }</div>
                <div><strong>Кнопка "Комментарий":</strong> { pageElements.commentButton || 'Комментарий' }</div>
                <div><strong>Чекбокс "Не знаю":</strong> { pageElements.knowNothing || 'Не знаю' }</div>
            </Block>
        </div>;
    }
}

export default connect(null, { openSurveyPageElementsModal })(SurveyElements);
