import React , { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import Modal from './Modal';
import { patchSurvey } from '../../actions/asyncActions';
import GroupVariablesForm from '../forms/survey/GroupVariablesForm';

class GroupVariablesModal extends Component {
    render() {
        const { modal, params, patchSurvey } = this.props;

        return <Modal
            {...modal}
            title='Редактировать переменные'
            footer={null}>
            <GroupVariablesForm
                item={params}
                formAction={patchSurvey} />
        </Modal>;
    }
}

export default withAsyncActions({
    patchSurvey: patchSurvey
        .withSuccessHandler(({ close }) => {
            message.success('Переменные опроса успешно сохранены');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось сохранить переменные опроса'))
        .withOptions({ resetOnUnmount: true })
})(GroupVariablesModal);
