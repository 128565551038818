import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { prop, update } from 'ramda';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import Editor from './formComponents/Editor';

class QuestionPageForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='title'
                component={Input}
                label='Заголовок' />
            <Field
                name='content'
                component={Editor}
                label='Содержание' />
            <SubmitButton type='primary'>Сохранить</SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    title: yup.string().required()
});

export default withFormWrapper(QuestionPageForm, {
    validationSchema,
    mapPropsToValues: prop('item'),
    mapBeforeSubmit: ({ company, project, test, index, ...value }, { pages, questions }) => value.id ? ({
        company,
        project,
        id: test,
        data: {
            questions,
            pages: update(index, value, pages)
        }
    }) : ({
        company,
        project,
        id: test,
        data: {
            questions,
            pages: [...pages, value]
        }
    })
});
