import { setLocale } from 'yup';
import { isMobilePhone } from 'class-validator';
import * as yup from 'yup';
import { uniqWith, eqBy, prop, all, is } from 'ramda';

import validationLocale from '../constants/validationLocale';

setLocale(validationLocale);

yup.addMethod(yup.string, 'phone', function phone(ref, msg) {
    return this.test({
        name: 'phone',
        exclusive: false,
        message: msg || 'Введите телефон в формате: +79123456789',
        test: (value) => value ? isMobilePhone(value, 'ru-RU') : true
    });
});

yup.addMethod(yup.mixed, 'requiredFile', function requiredFile(ref) {
    return this.test({
        name: 'requiredFile',
        exclusive: false,
        message:'Это поле обязательно',
        test: value => !!value
    });
});

yup.addMethod(yup.array, 'countTests', function countTests() {
    return this.test({
        name: 'countTests',
        exclusive: false,
        message: 'Введите количество для всех уровней',
        test: value => value ? value.length === 3 && all(n => is(Number, n), value) : true
    })
})

yup.addMethod(yup.array, 'uniq', function uniq(path, msg) {
    return this.test({
        name: 'uniq',
        exclusive: false,
        message: msg,
        test: (value = []) => {
            return uniqWith(eqBy(prop(path)), value).length === value.length;
        }
    });
});
