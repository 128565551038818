import React, { Component, Fragment } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { connect } from 'react-redux';

import Toolbar from '../table/Toolbar';
import { openCompanyIntegralResultScaleModal } from '../../../actions/modalActions';
import { pathOr, toPairs } from 'ramda';

class CompanyIntegralResultScale extends Component {
    getColumns = () => {
        return [
            {
                title: 'Текст вывода',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: 'Разброс баллов',
                dataIndex: 'points',
                key: 'points'
            }
        ];
    }

    editIntegralResultScale = () => {
        this.props.openCompanyIntegralResultScaleModal(this.props.company);
    }

    getButtons = () => {
        return <Button
            icon={<EditOutlined />}
            type='primary'
            onClick={this.editIntegralResultScale}>
            Редактировать
        </Button>;
    }

    render() {
        const data = toPairs(pathOr({}, ['reportsSettings', 'integralResultScale'], this.props.company)).map(([ points, name ], index) => ({ points, name, index }));

        return <Fragment>
            <Toolbar buttons={this.getButtons()} />
            <Table
                columns={this.getColumns()}
                dataSource={data}
                pagination={false}
                rowKey='index' />
        </Fragment>;
    }
}

export default connect(null, { openCompanyIntegralResultScaleModal })(CompanyIntegralResultScale);
