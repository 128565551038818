import React, { Component } from 'react';
import { Badge } from 'antd';
import styled from 'styled-components';
import { find, propEq, path, contains } from 'ramda';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { QUESTION_TYPES, QUESTION_LEVELS } from '../../constants/questions';
import DateFormat from '../user/table/DateFormat';
import Modal from './Modal';

const Title = styled.div`
    display: inline-block;
`;

const Description = styled.div`
    margin-bottom: 5px;
`;

const AnswerVariants = styled.div`
    margin-top: 15px;
`;

export default class QuestionDetailModal extends Component {
    renderField = (label, value) => {
        return value &&
            <Description>
                <strong>{ label }:</strong> { value }
            </Description>;
    }

    getCategoryStr = () => {
        const { category } = this.props.params._embedded;
        const parentName = path(['_embedded', 'parent', 'name'], category);
        const parentOfParentName = path(['_embedded', 'parent', '_embedded', 'parent', 'name'], category);

        return `${parentOfParentName ? `${parentOfParentName} / ` : ''}${parentName ? `${parentName} / ` : ''}${category.name}`;
    }

    render() {
        const { modal, params } = this.props;
        const title = <Title dangerouslySetInnerHTML={{ __html: params.title }} />;

        return <Modal
            {...modal}
            title={title}
            footer={null}>
            { this.renderField('Вопрос', title) }
            { this.renderField('Тип', find(propEq('id', params.type), QUESTION_TYPES).value) }
            { this.renderField('Компетенция', this.getCategoryStr()) }
            { this.renderField('Сложность', find(propEq('id', params.level), QUESTION_LEVELS).value) }
            { this.renderField('Дата создания', <DateFormat date={params.createdAt} />) }
            { params.updatedAt && this.renderField('Дата обновления', <DateFormat date={params.updatedAt} />) }
            { params.type === 'choice' && this.renderField('Множественный выбор', params.multipleAnswers ? 'да' : 'Нет') }
            { contains(params.type, ['choice', 'ranging']) &&
                <AnswerVariants>
                    <h3>Варианты ответов</h3>
                    { params.type === 'choice' ?
                        params.answerVariants.map(answer =>
                            <div key={answer.id}>
                                { answer.correct ? <CheckOutlined style={{ color: '#549E38' }} /> : <CloseOutlined style={{ color: '#f54d2e' }} /> } { answer.title }
                            </div>
                        ) :
                        params.rangingAnswerVariants.map(answer =>
                            <div key={answer.id}>
                                <Badge count={answer.rank} /> { answer.title }
                            </div>
                        )
                    }
                </AnswerVariants>
            }
        </Modal>;
    }
}
