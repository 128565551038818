import React from 'react';

import BaseFilter from './BaseFilter';
import { getCompanyProjects } from '../../../actions/asyncActions';
import withFilterForm from '../../hocs/withFilterForm';

export default withFilterForm(() =>
    <BaseFilter
        action={getCompanyProjects}
        searchFieldName='text'
        searchFieldPlaceholder='Поиск'
        span={24}>
    </BaseFilter>
);
