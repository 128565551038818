import React, { Component, Fragment } from 'react';
import { Tag, Button, Dropdown, Menu, message } from 'antd';
import { find, propEq, contains, filter, any, equals } from 'ramda';
import { Link } from 'react-router-dom';
import { withState } from 'recompose';
import { asyncConnect } from 'react-async-client';
import { DownOutlined } from '@ant-design/icons';

import TableList from './table/TableList';
import { getRespondents, getUser, deleteCompanyRespondents, patchCompanyRespondents, postCompanyTestsRestart } from '../../actions/asyncActions';
import { PROJECT_RESPONDENT_EMPLOYEE, PROJECT_RESPONDENT_STATUSES } from '../../constants/companies';
import DateFormat from './table/DateFormat';
import RespondentsFilter from '../forms/filters/RespondentsFilter';
import { MANAGERS, ADMIN } from '../../constants/roles';
import Contacts from './table/Contacts';
import { getUrlParams } from '../../utils/urlParams';
import { DELETE_COMPANY_RESPONDENTS, POST_COMPANY_TESTS_RESTART, PATCH_COMPANY_RESPONDENTS } from '../../constants/actionTypes';

class Respondents extends Component {
    componentDidUpdate(prev) {
        if (!equals(getUrlParams(this.props.location), getUrlParams(prev.location))) {
            this.clearSelected();
        }
    }

    getColumns = () => {
        const columns = [
            {
                title: 'Респондент',
                key: 'lastName',
                sorter: true,
                filters: PROJECT_RESPONDENT_EMPLOYEE.map(i => ({ text: i.value, value: i.id })),
                filterMultiple: false,
                render: item =>
                    <Fragment>
                        <div>
                            <Link to={`/company/${item.company}/projects/${item.project.id}/test/${item.testSuite}/respondents/${item.id}`}>
                                { item.lastName } { item.firstName } { item.middleName }
                            </Link>
                        </div>
                        <div>{ item.employee ? 'Сотрудник' : '' }</div>
                    </Fragment>
            },
            {
                title: 'Компания',
                key: 'company',
                dataIndex: ['_embedded', 'company'],
                render: ({ name, id }) => <Link to={`/companies/${id}/info/details`}>{ name }</Link>,
                hide: this.props.isManager
            },
            {
                title: 'Проект',
                key: 'project',
                dataIndex: 'project',
                render: ({ name, id, company }) => <Link to={`/company/${company}/projects/${id}/respondents`}>{ name }</Link>
            },
            {
                title: 'Тест',
                key: 'testSuite',
                dataIndex: ['_embedded', 'testSuite', 'name']
            },
            {
                title: 'Контакты',
                dataIndex: 'email',
                key: 'email',
                render: (email, { phone }) => <Contacts email={email} phone={phone} />
            },
            {
                dataIndex: 'status',
                key: 'statuses',
                className: 'right-column',
                filters: PROJECT_RESPONDENT_STATUSES.map(i => ({ text: i.value, value: i.id })),
                render: (status, { createdAt }) => {
                    const data = find(propEq('id', status), PROJECT_RESPONDENT_STATUSES);

                    return <Fragment>
                        <Tag color={data.color}>{ data.value }</Tag>
                        <div style={{ marginTop: 5 }}><DateFormat date={createdAt} /></div>
                    </Fragment>;
                }
            }
        ];

        return filter(col => !col.hide, columns);
    }

    isDisallowRestart = () => {
        return any(id => find(propEq('id', id), this.props.items).status !== 'started', this.props.selected);
    }

    clearSelected = () => this.setSelected([]);

    cancelSelected = () => {
        const { selected, items, patchCompanyRespondents } = this.props;

        patchCompanyRespondents.dispatch({
            items: selected.map(id => {
                const respondent = find(propEq('id', id), items);

                return {
                    id,
                    test: respondent.testSuite,
                    data: [{ op: 'replace', path: '/canceled', value: true }],
                    project: respondent.project,
                    company: respondent.company
                };
            })
        });
    }

    restartSelected = () => {
        const { selected, postCompanyTestsRestart, items } = this.props;

        postCompanyTestsRestart.dispatch({
            items: selected.map(id => {
                const respondent = find(propEq('id', id), items);

                return {
                    respondent: id,
                    id: respondent.testSuite,
                    project: respondent.project,
                    company: respondent.company
                };
            })
        });
    }

    delete = () => {
        const { deleteCompanyRespondents, selected, items } = this.props;

        deleteCompanyRespondents.dispatch({
            items: filter(item => contains(item.id, selected), items),
        });
    }

    getButtonsMenu = () => {
        return <Menu>
            <Menu.Item key='delete' onClick={this.delete}>
                Удалить
            </Menu.Item>
            <Menu.Item key='cancel' onClick={this.cancelSelected}>
                Отменить
            </Menu.Item>
            { this.props.isAdmin &&
                <Menu.Item key='reset' onClick={this.restartSelected} disabled={this.isDisallowRestart()}>
                    Сбросить
                </Menu.Item>
            }
        </Menu>;
    }

    renderButtons = () => {
        const { selected } = this.props;

        return <Button.Group>
            <Dropdown overlay={this.getButtonsMenu()} trigger={['click']} disabled={!selected.length}>
                <Button icon={<DownOutlined />} />
            </Dropdown>
        </Button.Group>;
    }

    setSelected = selected => this.props.setSelected(selected);

    render() {
        return <TableList
            action={getRespondents}
            columns={this.getColumns()}
            filterForm={RespondentsFilter}
            buttons={this.renderButtons()}
            refreshActions={[
                DELETE_COMPANY_RESPONDENTS,
                POST_COMPANY_TESTS_RESTART,
                PATCH_COMPANY_RESPONDENTS
            ]}
            rowSelection={{
                selectedRowKeys: this.props.selected,
                onChange: this.setSelected,
                type: 'checkbox'
            }} />
    }
}

const stateToProps = state => ({
    items: getRespondents.selectData(state).items || [],
    isManager: contains(getUser.selectData(state).role, MANAGERS),
    isAdmin: getUser.selectData(state).role === ADMIN
});

export default withState('selected', 'setSelected', [])(
    asyncConnect({
        deleteCompanyRespondents: deleteCompanyRespondents
            .withSuccessHandler(({ setSelected }) => {
                message.success('Респонденты успешно удалены');
                setSelected([]);
            })
            .withErrorHandler(() => message.error('Не удалось удалить респондентов'))
            .withOptions({ resetOnUnmount: true }),
        patchCompanyRespondents: patchCompanyRespondents
            .withSuccessHandler(({ setSelected }) => {
                message.success('Тест успешно отменен');
                setSelected([]);
            })
            .withErrorHandler(() => message.error('Не удалось отменить тест'))
            .withOptions({ resetOnUnmount: true }),
        postCompanyTestsRestart: postCompanyTestsRestart
            .withSuccessHandler(({ setSelected }) => {
                message.success('Прохождение теста успешно сброшено');
                setSelected([]);
            })
            .withErrorHandler(() => message.error('Не удалось сбросить прохождение теста'))
            .withOptions({ resetOnUnmount: true }),
    }, stateToProps)(Respondents)
);
