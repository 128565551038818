import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import * as yup from 'yup';
import { prop } from 'ramda';
import { Button, Row, Col } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import ListenerField from './ListenerField';
import Input from './formComponents/Input';
import Select from './formComponents/Select';
import ImageUpload from './formComponents/ImageUpload';
import Switch from './formComponents/Switch';
import { COMPANY_SIZE, COMPANY_INDUSTRY, REPORT_TYPES_OPTIONS } from '../../constants/companies';
import RadioButtons from './formComponents/RadioButtons';

const labelCol = {
    xs: { span: 24 },
    sm: { span: 8 },
};

const wrapperCol = {
    xs: { span: 24 },
    sm: { span: 16 },
};

class CompanyForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='name'
                component={Input}
                label='Название'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='industry'
                component={Select}
                label='Отрасль'
                options={COMPANY_INDUSTRY}
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='size'
                component={Select}
                label='Кол-во сотрудников'
                options={COMPANY_SIZE}
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='logo'
                component={ImageUpload}
                label='Логотип'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='ownQuestions'
                component={Switch}
                label='Своя база вопросов'
                wrapperCol={wrapperCol}
                labelCol={labelCol}
                hideOptional />
            <Field
                name='showRespondentAnswers'
                component={Switch}
                label='Доступ к ответам респондента'
                wrapperCol={wrapperCol}
                labelCol={labelCol}
                hideOptional />
            <Field
                name='respondentFinishNotification'
                component={Switch}
                label='Уведомление о прохождении'
                wrapperCol={wrapperCol}
                labelCol={labelCol}
                hideOptional
                onChange={(value) => this.props.form.change('notificationEmails', value ? [''] : null)} />
            <ListenerField listenFieldName='respondentFinishNotification'>
                { ({ respondentFinishNotification }) =>
                    <FieldArray name='notificationEmails'>
                        { ({ fields }) => <div style={{ marginBottom: 20, display: respondentFinishNotification ? 'block' : 'none' }}>
                            { fields.map((name, index) =>
                                <div key={index}>
                                    <Field
                                        name={`${name}`}
                                        component={Input}
                                        required
                                        label='Email'
                                        addonAfter={fields.length > 1 ? (
                                            <DeleteOutlined onClick={() => fields.remove(index)} />
                                        ) : null}
                                        wrapperCol={wrapperCol}
                                        labelCol={labelCol} />
                                </div>
                            )}
                            <Row>
                                <Col xs={24} sm={16} offset={8}>
                                    <Button icon={<PlusOutlined />} onClick={() => fields.push('')} style={{ marginBottom: 10 }}>
                                        Добавить
                                    </Button>
                                </Col>
                            </Row>
                        </div>}
                    </FieldArray>
                }
            </ListenerField>
            <Field
                name='reportType'
                component={RadioButtons}
                label='Тип отчета'
                options={REPORT_TYPES_OPTIONS}
                wrapperCol={wrapperCol}
                labelCol={labelCol}
                hideOptional />
            <SubmitButton type='primary'>Сохранить</SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    name: yup.string().required(),
    notificationEmails: yup.array().nullable()
        .when('respondentFinishNotification', (value, schema) => !value
            ? schema
            : schema.of(yup.string().email().required())
        ),
});

export default withFormWrapper(CompanyForm, {
    validationSchema,
    mapPropsToValues: prop('item'),
});
