import React from 'react';
import { Field } from 'react-final-form';
import { contains } from 'ramda';
import { Col } from 'antd';

import BaseFilter from './BaseFilter';
import withFilterForm from '../../hocs/withFilterForm';
import Select from '../formComponents/Select';
import { COMMAND_TYPES, SENIOR_MANAGER_COMMAND_TYPES } from '../../../constants/commands';
import { filter, includes } from 'ramda';
import { SENIOR_MANAGER, ADMIN_MODERATOR } from '../../../constants/roles';
import { connect } from 'react-redux';
import { getUser, getCompanies } from '../../../actions/asyncActions';

const CommandsFilterForm = withFilterForm(({ user }) => {
    const isAdmin = contains(user.role, ADMIN_MODERATOR);
    const isManager = user.role === SENIOR_MANAGER;
    const options = filter(command => isManager ? includes(command.id, SENIOR_MANAGER_COMMAND_TYPES) : true, COMMAND_TYPES);

    return <BaseFilter
        component={Select}
        options={options}
        searchFieldName='names'
        searchFieldPlaceholder='Команда'
        isMulti
        allowClear
        span={isAdmin ? 14 : 24}
        useReactWindow={false}>
        { isAdmin && (
            <Col span={10}>
                <Field
                    name='company'
                    component={Select}
                    action={getCompanies}
                    filter={{ enabled: true }}
                    placeholder='Компания'
                    namePath='name'
                    searchable
                    useReactWindow={false}
                    allowClear />
            </Col>
        )}
    </BaseFilter>;
});

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(CommandsFilterForm);
