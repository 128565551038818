import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { contains, find, propEq, path, forEach, pathOr, reduce, addIndex, filter, isNil, includes, is } from 'ramda';
import * as yup from 'yup';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import Select from './formComponents/Select';
import { COMMAND_TYPES, SENIOR_MANAGER_COMMAND_TYPES, COMMAND_LANGUAGES } from '../../constants/commands';
import ListenerField from './ListenerField';
import { getCategories, getCompanies, getUser } from '../../actions/asyncActions';
import Switch from './formComponents/Switch';
import CommandUpload from './formComponents/CommandUpload';
import CountTestsField from './formComponents/CountTestsField';
import ImageUpload from './formComponents/ImageUpload';
import ColorPicker from './formComponents/ColorPicker';
import { asyncConnect } from 'react-async-client';
import { SENIOR_MANAGER } from '../../constants/roles';
import { connect } from 'react-redux';
import Rangepicker from './formComponents/Rangepicker';

const labelCol = {
    xs: { span: 24 },
    sm: { span: 10 },
};

const wrapperCol = {
    xs: { span: 24 },
    sm: { span: 14 },
};

class CommandForm extends Component {
    onChangeCategory = (data, field) => {
        const { form } = this.props;

        form.batch(() => {
            form.change(`inputData.${field}`, path(['label'], data));
            form.change('inputOptions.competence', null);
        });
    }

    onChangeCompetence = (id, data) => {
        this.props.form.change('inputData.competence', id ? data.label : null);
    }

    getCompany = (name) => {
        const { getCompanies: { data: { items = [] } }} = this.props;

        return name ? path(['id'], find(propEq('name', name), items)) : null;
    }

    getCategories() {
        const { getCategories: { data: { items = [] } } } = this.props;

        return items;
    }

    renderFields = name => {
        const { getCompanies, command, inputFilesRoutes, user } = this.props;
        const { fields = [], fileType, zip, optionalFields = [], id, multiFiles } = find(propEq('id', name), COMMAND_TYPES) || {};
        const companyRequired = !contains('company', optionalFields);
        const filePath = path(['inputData', 'path'], command);
        const fileProps = filePath ? {
            label: 'Файл',
            uploaded: filePath,
            command,
            inputFilesRoutes,
            wrapperCol,
            labelCol,
            hideOptional: true
        } : {};
        const pdfTail = path(['inputData', 'pdfTail'], command);
        const pdfProps = pdfTail ? {
            label: 'PDF-приложение',
            uploaded: !!pdfTail,
            command,
            inputFilesRoutes,
            wrapperCol,
            labelCol,
            hideOptional: true
        } : {};

        return <Fragment>
            { (contains('company', fields) || contains('company', optionalFields)) &&
                ( user.role !== SENIOR_MANAGER ?
                    <Field
                        name='inputArguments.company'
                        component={Select}
                        label='Компания'
                        options={getCompanies.data.items || []}
                        loading={getCompanies.meta.pending}
                        wrapperCol={wrapperCol}
                        labelCol={labelCol}
                        namePath='name'
                        valuePath='name'
                        required={companyRequired}
                        allowClear={!companyRequired}
                        searchable /> :
                        null
                )
            }
            <ListenerField listenFieldName='inputArguments.company'>
                { ({ inputArguments: { company }}) => contains('category', fields) &&
                <Fragment>
                    <Field
                        name='q.category'
                        component={Select}
                        label='Корневая категория'
                        action={getCategories}
                        filter={{ byParent: true, company: this.getCompany(company), onlyOwn: true }}
                        onChange={(id, data) => this.onChangeCategory(data, 'category')}
                        namePath='name'
                        wrapperCol={wrapperCol}
                        labelCol={labelCol}
                        searchable
                        allowClear />
                    <ListenerField listenFieldName={`inputArguments.category`}>
                        { ({ inputArguments }) => contains('clear', fields) && inputArguments.category &&
                            <Field
                                name='inputOptions.clear'
                                component={Switch}
                                wrapperCol={wrapperCol}
                                labelCol={labelCol}
                                label='Удалить старые вопросы'
                                hideOptional />
                        }
                    </ListenerField>
                </Fragment>
            }
            </ListenerField>
            <ListenerField listenFieldName='inputArguments.company'>
                { ({ inputArguments: { company }}) => contains('rootCompetence', fields) &&
                    <Field
                        name='inputArguments.rootCompetence'
                        component={Select}
                        label='Корневая категория'
                        action={getCategories}
                        filter={{
                            byParent: true,
                            onlyOwn: true,
                            company: this.getCompany(company)
                        }}
                        onChange={(id, data) => this.onChangeCategory(data, 'rootCompetence')}
                        namePath='name'
                        wrapperCol={wrapperCol}
                        labelCol={labelCol}
                        disabled={!company && contains(id, ['app:import:tests', 'app:import:respondents', 'app:import:experts'])}
                        required
                        searchable />
                }
            </ListenerField>
            <ListenerField listenFieldName={['inputArguments.rootCompetence', 'inputArguments.company']}>
                { ({ inputArguments: { rootCompetence, company }}) => contains('competence', fields) && rootCompetence &&
                    <Field
                        name='inputOptions.competence'
                        component={Select}
                        label='Компетенция'
                        action={getCategories}
                        onChange={this.onChangeCompetence}
                        filter={{
                            byParent: true,
                            onlyOwn: true,
                            company: this.getCompany(company),
                            parent: rootCompetence
                        }}
                        namePath='name'
                        wrapperCol={wrapperCol}
                        labelCol={labelCol}
                        allowClear
                        searchable />
                }
            </ListenerField>
            { contains('date', fields) &&
                <Field
                    name='inputOptions.min-date'
                    toPath='inputOptions.max-date'
                    component={Rangepicker}
                    wrapperCol={wrapperCol}
                    labelCol={labelCol}
                    label='Период'
                    change={this.props.form.change}
                    hideOptional />
            }
            { contains('full-list', fields) &&
                <Field
                    name='inputOptions.full-list'
                    component={Switch}
                    wrapperCol={wrapperCol}
                    labelCol={labelCol}
                    label='Добавлять статус прохождения тестов в отчет'
                    hideOptional />
            }
            { contains('count-tests', fields) &&
                <Field
                    name='inputOptions.count-tests'
                    component={CountTestsField}
                    label='Количество вариантов тестов'
                    wrapperCol={wrapperCol}
                    labelCol={labelCol}
                    hideOptional />
            }
            { contains('shuffle-answers', fields) &&
                <Field
                    name='inputOptions.shuffle-answers'
                    component={Switch}
                    wrapperCol={wrapperCol}
                    labelCol={labelCol}
                    label='Перемешивать варианты ответов'
                    hideOptional />
            }
            { contains('with-answers', fields) &&
                <Field
                    name='inputOptions.with-answers'
                    component={Switch}
                    wrapperCol={wrapperCol}
                    labelCol={labelCol}
                    label='Показывать варианты ответов'
                    hideOptional />
            }
            { contains('dry-run', fields) &&
            <Field
                name='inputOptions.dry-run'
                component={Switch}
                wrapperCol={wrapperCol}
                labelCol={labelCol}
                label='Тестовый прогон'
                hideOptional />
            }
            { contains('pdf', optionalFields) &&
                <Fragment>
                    <Field
                        name='inputOptions.with-recommendations'
                        component={Switch}
                        wrapperCol={wrapperCol}
                        labelCol={labelCol}
                        label='Страница с программой развивающих действий'
                        hideOptional />
                    <Field
                        name='inputOptions.with-competence-conclusion'
                        component={Switch}
                        wrapperCol={wrapperCol}
                        labelCol={labelCol}
                        label='Страница с зонами развития'
                        hideOptional />
                    <Field
                        name='inputOptions.without-competences-chart'
                        component={Switch}
                        wrapperCol={wrapperCol}
                        labelCol={labelCol}
                        label='Скрыть диаграмму компетенций'
                        hideOptional />
                    <Field
                        name='inputOptions.with-text-result'
                        component={Switch}
                        wrapperCol={wrapperCol}
                        labelCol={labelCol}
                        label='Блок с текстовым описанием результата'
                        hideOptional />
                    <Field
                        name='inputOptions.with-full-page-text-result'
                        component={Switch}
                        wrapperCol={wrapperCol}
                        labelCol={labelCol}
                        label='Таблица результатов на отдельной странице'
                        hideOptional />
                    <Field
                        name='inputOptions.with-full-page-competences'
                        component={Switch}
                        wrapperCol={wrapperCol}
                        labelCol={labelCol}
                        label='Таблица компетенций на отдельной странице'
                        hideOptional />
                    <Field
                        name='inputOptions.with-number-result'
                        component={Switch}
                        wrapperCol={wrapperCol}
                        labelCol={labelCol}
                        label='Блок с числовым описанием результата'
                        hideOptional />
                    <Field
                        name='inputOptions.with-integral-result-text'
                        component={Switch}
                        wrapperCol={wrapperCol}
                        labelCol={labelCol}
                        label='Включить вывод в отчет'
                        hideOptional />
                    <Field
                        name='inputOptions.with-logo'
                        component={Switch}
                        wrapperCol={wrapperCol}
                        labelCol={labelCol}
                        label='Логотип в заголовке детальных страниц'
                        hideOptional />
                    <Field
                        name='inputOptions.logo'
                        component={ImageUpload}
                        wrapperCol={wrapperCol}
                        labelCol={labelCol}
                        label='Логотип'
                        small='Лучше всего будет выглядеть логотип размером 350x240'
                        hideOptional
                        asUrl />
                    <Field
                        name='inputOptions.color'
                        component={ColorPicker}
                        wrapperCol={wrapperCol}
                        labelCol={labelCol}
                        label='Основной цвет отчета'
                        hideOptional />
                    <Field
                        name='inputOptions.language'
                        component={Select}
                        options={COMMAND_LANGUAGES}
                        wrapperCol={wrapperCol}
                        labelCol={labelCol}
                        label='Язык отчета'
                        hideOptional />
                </Fragment>
            }
            { contains('with-page-numbers', fields) &&
                <Field
                    name='inputOptions.with-page-numbers'
                    component={Switch}
                    wrapperCol={wrapperCol}
                    labelCol={labelCol}
                    label='Отображать номера страниц'
                    hideOptional />
            }
            { contains('file', fields) &&
                <Field
                    name='file'
                    component={CommandUpload}
                    fileType={fileType}
                    zip={zip}
                    multi={multiFiles}
                    {...fileProps} />
            }
            { contains('pdf', optionalFields) &&
                <Field
                    name='pdf'
                    component={CommandUpload}
                    {...pdfProps} />
            }
            <Field name='inputOptions.dry-run' component={() => null} />
        </Fragment>
    }

    onChangeName = name => {
        const fields = pathOr([], ['fields'], find(propEq('id', name), COMMAND_TYPES));

        this.props.form.change('inputOptions.dry-run', contains('dry-run', fields) ? true : undefined);
    }

    render() {
        const options = filter(command => this.props.user.role === SENIOR_MANAGER ? includes(command.id, SENIOR_MANAGER_COMMAND_TYPES) : true, COMMAND_TYPES);

        return <Fragment>
            { !this.props.command &&
                <Field
                    name='name'
                    component={Select}
                    label='Команда'
                    options={options}
                    wrapperCol={wrapperCol}
                    labelCol={labelCol}
                    onChange={this.onChangeName}
                    searchable />
            }
            <ListenerField listenFieldName='name'>
                { ({ name }) => this.renderFields(name) }
            </ListenerField>
            <SubmitButton type='primary'>Сохранить</SubmitButton>
        </Fragment>;
    }
}

const getRequiredObj = (name, required) => {
    let obj = {};
    const { fields } = find(propEq('id', name), COMMAND_TYPES);

    forEach(field => {
        if (contains(field, fields)) {
            obj[field] = yup.string().nullable().required();
        }
    }, required);

    return obj;
}

const validationSchema = props => yup.object().shape({
    name: yup.string().required(),
    inputOptions: yup.object().when('name', (name, schema) => schema.shape({
        ...getRequiredObj(name, ['encoding','delimiter', 'enclosure']),
        'count-tests': find(propEq('id', name), COMMAND_TYPES) ? yup.array().nullable().countTests() : yup.array().nullable()
    })),
    inputArguments: yup.object().when('name', (name, schema) => schema.shape(getRequiredObj(name, ['rootCompetence','company']))),
    file: yup.mixed().nullable().when('name', (name, schema) => !path(['inputData', 'path'], props.command) && contains('file', pathOr([], ['fields'], find(propEq('id', name), COMMAND_TYPES))) ? schema.requiredFile() : schema),
    pdf: yup.mixed().nullable().when('name', (name, schema) => !path(['inputData', 'pdfTail'], props.command) && contains('pdf', pathOr([], ['fields'], find(propEq('id', name), COMMAND_TYPES))) ? schema.requiredFile() : schema)
});

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(asyncConnect(props => ({
    getCategories,
    getCompanies: getCompanies
        .withPayload(() => ({
            q: {
                enabled: true
            },
            limit: 0
        }))
        .withOptions({ dispatchOnMount: props.user.role !== SENIOR_MANAGER, resetOnUnmount: true })
}))(
    withFormWrapper(CommandForm, {
        validationSchema,
        mapPropsToValues: ({ command, user }) => {
            return command ? {
                ...command,
                inputArguments: is(Array, path(['inputArguments'], command)) ? {} : path(['inputArguments'], command),
                inputOptions: path(['inputOptions', 'count-tests'], command) ? {
                    'count-tests': command.inputOptions['count-tests'].split(',').map(n => Number(n))
                } : (is(Array, path(['inputOptions'], command)) ? path(['inputOptions'], command).reduce((acc, option) => {
                    acc[option] = true;
                    return acc;
                }, {}) : path(['inputOptions'], command) || { 'dry-run': true }),
            } : {
                name: COMMAND_TYPES[0].id,
                inputOptions: {
                    'dry-run': true,
                },
                inputArguments: {
                    company: user.role === SENIOR_MANAGER ? user.company : null
                },
                inputData: {},
            };
        },
        mapBeforeSubmit: values => ({
            ...values,
            inputData: filter(v => !isNil(v), {
                ...values.inputData,
                ...(values.file ? {
                    path: values.file.length > 1 ? 'files.zip' : values.file[0].name,
                    ...(values.pdf ? { pdfTail: values.pdf[0].name } : {})
                } : {})
            }),
            inputOptions: filter(v => !isNil(v), {
                ...(values.inputOptions || {}),
                'count-tests': (values.inputOptions || {})['count-tests'] ?
                    addIndex(reduce)((res, cur, index) => `${res}${cur}${index < 2 ? ',' : ''}`, '', values.inputOptions['count-tests']) : null
            })
        })
    })
));
