import React, { Component } from 'react';
import { message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import CompanyTemplateForm from '../forms/CompanyTemplateForm';
import { putSettingsTemplate, postSettingsTemplate } from '../../actions/asyncActions';
import Modal from './Modal';

class SettingsTemplateModal extends Component {
    render() {
        const { modal, params, putSettingsTemplate, postSettingsTemplate } = this.props;

        return <Modal
            {...modal}
            title={params.id ? 'Редактировать шаблон' : 'Добавить шаблон'}
            footer={null}>
            <CompanyTemplateForm
                formAction={params.id ? putSettingsTemplate : postSettingsTemplate}
                item={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    postSettingsTemplate: postSettingsTemplate
        .withSuccessHandler(({ close }) => {
            message.success('Шаблон успешно добавлен');
            close();
        })
        .withErrorHandler(({ postSettingsTemplate: { meta }}) =>
            message.error('Не удалось добавить шаблон')
        )
        .withOptions({ resetOnUnmount: true }),
    putSettingsTemplate: putSettingsTemplate
        .withSuccessHandler(({ close }) => {
            message.success('Шаблон успешно изменен');
            close();
        })
        .withErrorHandler(({ putSettingsTemplate: { meta }}) =>
            message.error('Не удалось изменить шаблон')
        )
        .withOptions({ resetOnUnmount: true })
})(SettingsTemplateModal);
