import React, { Component, Fragment } from 'react';
import { prop, path, pick } from 'ramda';
import * as yup from 'yup';
import { Field } from 'react-final-form';
import styled from 'styled-components';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { FieldArray } from 'react-final-form-arrays';
import { Form, Switch } from 'antd';

import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import Input from './formComponents/Input';
import { PhoneInput } from './formComponents/MaskedInput';
import Select from './formComponents/Select';
import ListenerField from './ListenerField';
import InputNumber from './formComponents/InputNumber';
import RadioButtons from './formComponents/RadioButtons';
import { withState } from 'recompose';

const inlineLabelCol = {
    xs: { span: 24 },
    sm: { span: 6 },
};

const inlineWrapperCol = {
    xs: { span: 24 },
    sm: { span: 18 },
};

const RESPONDENT_FORM_FIELDS = {
    string: Input,
    number: InputNumber,
    choice: {
        radio: RadioButtons,
        select: Select
    }
};

const AdditionalFieldsTitle = styled.h3`
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

class CompanyRespondentForm extends Component {
    state = {
        hideAdditional: true
    };

    componentDidMount() {
        const { getRef } = this.props;

        getRef && getRef(this);
    }

    reset = () => {
        if (this.props.addToEmployees) {
            this.props.setAddToEmployees(false);
        }
        this.props.form.reset();
    }

    toggleAddToEmployees = () => this.props.setAddToEmployees(!this.props.addToEmployees);

    toggleAdditional = () => this.setState(prev => ({ hideAdditional: !prev.hideAdditional }));

    getRespondentFormFieldProps = item => {
        return {
            key: item.code,
            label: item.name,
            component: item.type === 'choice' ? RESPONDENT_FORM_FIELDS[item.type][item.settings.choiceType] : RESPONDENT_FORM_FIELDS[item.type],
            options: item.choices ? item.choices.map(value => ({ id: value, value })) : null
        };
    }

    render() {
        const { item, tests, block, addToEmployees } = this.props;

        const showTestsField = tests && tests.length > 1;
        const showEmployeeField = !item.id;
        const showPositionField = addToEmployees || (item.id && item.position);
        const wrapperCol = block ? {} : inlineWrapperCol;
        const labelCol = block ? {} : inlineLabelCol;

        return <Fragment>
            <h3>Информация</h3>
            <Field
                name='lastName'
                component={Input}
                label='Фамилия'
                labelCol={labelCol}
                wrapperCol={wrapperCol} />
            <Field
                name='firstName'
                component={Input}
                label='Имя'
                labelCol={labelCol}
                wrapperCol={wrapperCol} />
            <Field
                name='middleName'
                component={Input}
                label='Отчество'
                labelCol={labelCol}
                wrapperCol={wrapperCol} />
            <ListenerField listenFieldName='email'>
                { ({ email }) =>
                    <Field
                        name='code'
                        component={Input}
                        label='Код'
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                        hideOptional={!(email || '').length} />
                }
            </ListenerField>
            <h3>Контакты</h3>
            <ListenerField listenFieldName='code'>
                { ({ code }) =>
                    <Field
                        name='email'
                        component={Input}
                        label='Email'
                        type='email'
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                        hideOptional={!(code || '').length} />
                }
            </ListenerField>
            <Field
                name='phone'
                component={PhoneInput}
                label='Телефон'
                labelCol={labelCol}
                wrapperCol={wrapperCol} />
            { (showTestsField || showEmployeeField || showPositionField) && (
                <h3>Прочее</h3>
            )}
            { showTestsField &&
                <Field
                    name='testSuite'
                    component={Select}
                    label='Тест'
                    options={tests}
                    labelCol={labelCol}
                    wrapperCol={wrapperCol}
                    namePath='name' />
            }
            { showEmployeeField &&
                <Form.Item
                    label='Добавить в сотрудники'
                    labelCol={labelCol}
                    wrapperCol={wrapperCol}>
                    <Switch
                        checked={addToEmployees}
                        onChange={this.toggleAddToEmployees} />
                </Form.Item>
            }
            { showPositionField &&
                <Field
                    name='position'
                    component={Input}
                    label='Должность'
                    wrapperCol={wrapperCol}
                    labelCol={labelCol} />
            }
            <ListenerField listenFieldName='respondentFormFields'>
                { ({ respondentFormFields }) => !!(respondentFormFields && respondentFormFields.length) &&
                    <Fragment>
                        <AdditionalFieldsTitle onClick={this.toggleAdditional}>
                            <span>Дополнительные поля</span>
                            {this.state.hideAdditional ? <DownOutlined /> : <UpOutlined />}
                        </AdditionalFieldsTitle>
                        { !this.state.hideAdditional &&
                            <FieldArray name='respondentFormFields'>
                                { ({ fields }) =>
                                    fields.map((name, index) =>
                                    <Field
                                        name={`${name}.answer`}
                                        {...this.getRespondentFormFieldProps(respondentFormFields[index])}
                                        disableClear />
                                    )
                                }
                            </FieldArray>
                        }
                    </Fragment>
                }
            </ListenerField>
            <SubmitButton type='primary'>Сохранить</SubmitButton>
        </Fragment>;
    }
}

const validationSchema = ({ testSuite, survey }) => yup.object().shape({
    firstName: yup.string().nullable().required(),
    lastName: yup.string().nullable().required(),
    email: yup.string().email().nullable().when('code', (value, schema) => value ? schema : schema.required('Заполните одно из полей: email, код')),
    code: yup.string().nullable(),
    phone: yup.string().nullable().phone(),
    testSuite: testSuite || survey ? yup.string().nullable() : yup.string().nullable().required()
});

export default withState('addToEmployees', 'setAddToEmployees', false)(withFormWrapper(CompanyRespondentForm, {
    validationSchema,
    mapPropsToValues: prop('item'),
    mapBeforeSubmit: (values, { testSuite, addToEmployees }) => values.id && values.employee ? ({
        ...values,
        testSuite: testSuite || values.testSuite,
        employeeData: {
            ...path(['_embedded', 'employee'], values),
            ...pick(['lastName', 'firstName', 'middleName', 'phone', 'email', 'position'], values)
        }
    }) : ({
        ...values,
        testSuite: testSuite || values.testSuite,
        addToEmployees
    })
}));
