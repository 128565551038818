import { DeleteOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm } from 'antd';
import React, { Component, Fragment } from 'react';
import { asyncConnect } from 'react-async-client';
import { equals } from 'ramda';
import { withState } from 'recompose';
import { Link } from 'react-router-dom';

import { openQuestionnaryModal } from '../../actions/modalActions';
import { deleteQuestionnary, getQuestionnaries } from '../../actions/asyncActions';
import TableList from './table/TableList';
import { DELETE_QUESTIONNARY, POST_QUESTIONNARY } from '../../constants/actionTypes';
import QuestionnariesFilter from '../forms/filters/QuestionnariesFilter';
import { getUrlParams } from '../../utils/urlParams';

class Questionnaries extends Component {
    componentDidUpdate(prev) {
        if (!equals(getUrlParams(this.props.location), getUrlParams(prev.location))) {
            this.clearSelected();
        }
    }

    getColumns = () => {
        return [
            {
                title: 'Название',
                key: 'name',
                dataIndex: 'name',
                render: (name, item) => <Link to={`/questionnaries/${item.id}/info`}>{ name }</Link>
            },
            {
                title: 'Кол-во вопросов',
                dataIndex: 'drivers',
                key: 'amount',
                render: drivers => {
                    const questionsAmount = (drivers || []).reduce((res, cur) => res + (cur.questions || []).length, 0);

                    return <span><QuestionCircleOutlined /> { questionsAmount }</span>;
                }
            }
        ];
    }

    deleteSelected = () => {
        this.props.deleteQuestionnary.dispatch(this.props.selected);
    }

    renderButtons = () => {
        return <Fragment>
            <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => this.props.openQuestionnaryModal()}>
                Добавить
            </Button>
            { this.props.selected.length ?
                <Popconfirm
                    title='Вы уверены, что хотите удалить выбранные опросники?'
                    placement='left'
                    okText='Да'
                    cancelText='Нет'
                    onConfirm={this.deleteSelected}>
                    <Button
                        icon={<DeleteOutlined />}
                        danger>
                        Удалить
                    </Button>
                </Popconfirm> :
                <Button
                    icon={<DeleteOutlined />}
                    danger
                    disabled>
                    Удалить
                </Button>
            }
        </Fragment>;
    }

    setSelected = selected => this.props.setSelected(selected);

    clearSelected = () => this.setSelected([]);

    render() {
        return <TableList
            action={getQuestionnaries}
            columns={this.getColumns()}
            buttons={this.renderButtons()}
            filterForm={QuestionnariesFilter}
            refreshActions={[POST_QUESTIONNARY, DELETE_QUESTIONNARY]}
            rowSelection={{
                selectedRowKeys: this.props.selected,
                onChange: this.setSelected,
                type: 'checkbox'
            }} />;
    }
}

export default withState('selected', 'setSelected', [])(
    asyncConnect({
        deleteQuestionnary: deleteQuestionnary
            .withSuccessHandler(({ setSelected }) => {
                message.success('Выбранные опросники успешно удалены');
                setSelected([]);
            })
            .withErrorHandler(() => message.error('Не удалось удалить выбранные опросники'))
            .withOptions({ resetOnUnmount: true })
    }, null, { openQuestionnaryModal })(Questionnaries)
);
