import React, { Component } from 'react';
import { message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import { postClient, putClient } from '../../actions/asyncActions';
import CompanyClientForm from '../forms/CompanyClientForm';
import Modal from './Modal';

class CompanyClientModal extends Component {
    render() {
        const { modal, params, putClient, postClient } = this.props;

        return <Modal
            {...modal}
            title={params.id ? 'Редактировать пользователя' : 'Добавить пользователя'}
            footer={null}>
            <CompanyClientForm
                formAction={params.id ? putClient : postClient}
                item={params} />
        </Modal>
    }
}

export default withAsyncActions({
    postClient: postClient
        .withSuccessHandler(({ close }) => {
            message.success('Пользователь успешно добавлен');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
    putClient: putClient
        .withSuccessHandler(({ close }) => {
            message.success('Пользователь успешно изменен');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(CompanyClientModal);
