import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { prop, pathOr, find, propEq, path, filter, all, isNil } from 'ramda';
import { withAsyncActions, toSuccess } from 'react-async-client';
import { Button, message, Spin } from 'antd';
import * as yup from 'yup';
import { takeEvery } from 'redux-saga/effects';
import { ExclamationCircleOutlined, CheckCircleOutlined, MailOutlined } from '@ant-design/icons';
import moment from 'moment';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import TemplateEditor from './formComponents/TemplateEditor';
import Select from './formComponents/Select';
import { getCompanyTemplates, postCompanyTemplate, getRespondents, getRespondentsScreening } from '../../actions/asyncActions';
import Input from './formComponents/Input';
import { POST_COMPANY_TEMPLATE } from '../../constants/actionTypes';
import { PROJECT_RESPONDENT_STATUSES } from '../../constants/companies';
import Username from '../user/table/Username';
import Rangepicker from './formComponents/Rangepicker';
import ListenerField from './ListenerField';

const getFiltredRespondents = (respondents, from, to) => {
    return filter(item => {
        const answers = pathOr([], ['_embedded', 'solution', '_embedded', 'answers'], item);
        const fullAnswers = all(i => !isNil(i.correct), answers);

        return answers.length && fullAnswers && moment(path(['_embedded', 'solution', 'finishedAt'], item)).isBetween(moment(from).startOf('D'), moment(to).endOf('D'));
    }, respondents).map(({ id }) => id);
}

class LetterForm extends Component {
    static defaultProps = {
        respondentsField: 'respondentsFilter.respondents',
        getOptionItem: item => item,
    };

    onChangeTemplate = id => {
        const { getCompanyTemplates, form } = this.props;
        const template = find(propEq('id', id), getCompanyTemplates.data.items || []);

        if (template) {
            form.batch(() => {
                form.change('content', template.content);
                form.change('subject', template.subject);
            });
        }
    }

    saveTemplate = e => {
        const { handleSubmit, form, postCompanyTemplate } = this.props;
        const formState = form.getState();

        if (formState.invalid) {
            handleSubmit(e);
        } else {
            const { company, content, subject } = formState.values;

            postCompanyTemplate.dispatch({
                company,
                content,
                subject,
                title: subject
            });
        }
    }

    renderRespondentSelectLabel = ({ data: { option }}) => <Username user={this.props.getOptionItem(option)} />;

    renderRespondentSelectSmall = item => {
        return <span>
            {item.status === 'created' ? <ExclamationCircleOutlined /> : <CheckCircleOutlined />} { find(propEq('id', item.status), PROJECT_RESPONDENT_STATUSES).value }
            <MailOutlined style={{ marginLeft: 8 }} /> { this.props.getOptionItem(item).email }
        </span>;
    }

    onChangePeriod = (from, to) => {
        const { setCount, getRespondents, form } = this.props;
        const respondents = getFiltredRespondents(pathOr([], ['data', 'items'], getRespondents), from, to);

        setCount(respondents.length);
        form.change('respondentsFilter.respondents', respondents);
    }

    render() {
        const { getCompanyTemplates, getRespondents, postCompanyTemplate, contentOptions, item: { company }, amount, reportLetter, form } = this.props;

        return <Spin spinning={getCompanyTemplates.meta.pending}>
            { reportLetter &&
                <Field
                    name='updateAfter'
                    component={Rangepicker}
                    label='Период'
                    toPath='updateBefore'
                    change={form.change}
                    allowClear={false}
                    onChange={this.onChangePeriod}
                    hideOptional />
            }
            <ListenerField listenFieldName={['updateAfter', 'updateBefore']}>
                { ({ updateAfter, updateBefore }) => (reportLetter ? updateAfter && updateBefore : true) && (
                    (reportLetter && updateAfter && updateBefore && !getFiltredRespondents(pathOr([], ['data', 'items'], getRespondents), updateAfter, updateBefore).length) ?
                        <div style={{ textAlign: 'center' }}>Респондетнов за данный период не найдено</div> : <div>
                            { company &&
                                <Field
                                    name='template'
                                    component={Select}
                                    label='Шаблон'
                                    options={pathOr([], ['data', 'items'], getCompanyTemplates)}
                                    namePath='title'
                                    allowClear
                                    onChange={this.onChangeTemplate} />
                            }
                            <Field
                                name='subject'
                                component={Input}
                                label='Тема письма' />
                            <Field
                                name='content'
                                component={TemplateEditor}
                                options={contentOptions}
                                label='Текст письма' />
                            <SubmitButton
                                disabled={getRespondents.meta.pending || (reportLetter && !getFiltredRespondents(pathOr([], ['data', 'items'], getRespondents), updateAfter, updateBefore).length)}
                                type='primary'>
                                Отправить {amount ? ` (${amount})` : ''}
                            </SubmitButton>
                            { company &&
                                <Button
                                    style={{ marginLeft: 10 }}
                                    onClick={this.saveTemplate}
                                    loading={postCompanyTemplate.meta.pending}
                                >
                                    Сохранить как шаблон
                                </Button>
                            }
                        </div>
                    )
                }
            </ListenerField>
        </Spin>;
    }
}

const validationSchema = ({ filtered, requiredAudits, item: { company }, reportLetter }) => yup.object().shape({
    subject: yup.string().required(),
    content: yup.string().matches(
        reportLetter ? /data-var="ReportLink"/ : /data-var="TestLink"/,
        reportLetter ? 'Необходимо в текст письма добавить ссылку на скачивание отчета' : 'Необходимо в текст письма добавить ссылку на прохождение теста'
    ).required(),
    ...((filtered || !company || reportLetter)
        ? {}
        : requiredAudits
            ? {
                audits: yup.array().nullable().required('Выберите экспертов')
            }
            : {
                respondentsFilter: yup.object().shape({
                    respondents: yup.array().nullable().required('Выберите респондентов')
                })
            }
    )
});

export default withFormWrapper(
    withAsyncActions(props => ({
        getRespondents: (props.respondentsAction || (props.item.company ? getRespondents : getRespondentsScreening))
            .withPayload(({ item: { company, survey, project, testTemplate }}) => ({
                q: survey ? ({
                    survey,
                }) : company ? ({
                    company,
                    projects: [project],
                }) : ({ testTemplate }),
                limit: 0
            }))
            .withParams({ type: 'modal' })
            .withOptions({ resetOnUnmount: true, dispatchOnMount: true }),
        getCompanyTemplates: getCompanyTemplates
            .withPayload(({ item }) => ({
                company: item.company,
            }))
            .withSaga(function* (getProps) {
                yield takeEvery([toSuccess(POST_COMPANY_TEMPLATE)], function() {
                    const { getCompanyTemplates } = getProps();

                    getCompanyTemplates.refresh();
                })
            })
            .withSuccessHandler(({ form, getCompanyTemplates, postCompanyTemplate: { data }, reportLetter }, { payload }) => {
                const template = data.id ? payload.content : path(['data', 'items', 0], getCompanyTemplates);

                if (template && !reportLetter) {
                    form.batch(() => {
                        form.change('template', template.id);
                        form.change('content', template.content);
                        form.change('subject', template.subject);
                    });
                }
            })
            .withOptions({ dispatchOnMount: props.item.company, resetOnUnmount: true }),
        postCompanyTemplate: postCompanyTemplate
            .withSuccessHandler(({ form, postCompanyTemplate, setServerErrors }) => {
                form.change('template', path(['data', 'id'], postCompanyTemplate));
                setServerErrors({});
                message.success('Шаблон успешно сохранен')
            })
            .withErrorHandler(({ updateServerErrors, postCompanyTemplate }, action) => {
                updateServerErrors({ formAction: postCompanyTemplate }, action, errors => ({
                    subject: path(['title'], errors),
                }));
                message.error('Не удалось сохранить шаблон');
            })
            .withOptions({ resetOnUnmount: true })
    }))(LetterForm), {
    mapPropsToValues: prop('item'),
    validationSchema
});
