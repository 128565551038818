import React, { Component, Fragment } from 'react';
import { Row, Col, Form, Switch as AntdSwitch, Button, Tooltip } from 'antd';
import { Field, FormSpy } from 'react-final-form';
import { path, find, propEq, filter, any, pathOr } from 'ramda';
import styled from 'styled-components';
import { FieldArray } from 'react-final-form-arrays';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import uniqid from 'uniqid';
import { MenuOutlined, EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import Switch from '../formComponents/Switch';
import ListenerField from '../ListenerField';
import RadioButtons from '../formComponents/RadioButtons';
import { TIME_LIMITED_BY, PROJECT_DEFAULT_DESIGN_DESCRIPTION } from '../../../constants/companies';
import { openCompanyDesignPageModal, openRespondentFormFieldsModal, openCompanyClientModal } from '../../../actions/modalActions';
import ImageUpload from '../formComponents/ImageUpload';
import Editor from '../formComponents/Editor';
import { RESPONDENT_FORM_FIELD_TYPES, DEFAULT_RESPONDENT_FORM_FIELDS } from '../../../constants/tests';
import { reorder } from '../../../utils/dnd';
import { asyncConnect, toSuccess } from 'react-async-client';
import { getCompanyClients } from '../../../actions/asyncActions';
import { takeEvery } from 'redux-saga/effects';
import { POST_CLIENT } from '../../../constants/actionTypes';
import SpanAsLink from '../../user/table/SpanAsLink';
import Select from '../formComponents/Select';

const RowWrapper = styled(Row)`
    padding: 0 0 15px;
    @media (max-width: 767px) {
        padding: 0;
        & > .ant-col.ant-col-sm-24{
            margin-bottom: 15px;
        }
    }
    .ant-row.ant-form-item{
        display: flex;
        align-items: center;
    }
    .ant-col-md-24,.ant-col-md-12{
        h3{
            border: 1px solid #e8e8e8;
            padding-bottom: 10px;
            padding: 10px;
            background: #fafafa;
            margin: -1px 0px;
        }
    }
    .ant-col-md-12{
        &:last-child{
            padding-left: 7px;
            @media (max-width: 767px) {
                padding-left: 0;
            }
        }
        &:first-child{
            padding: 0 7px 0 0;
        }
    }
    .label-block{
        display: block;
    }
`;

const MultiItem = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    .ant-row.ant-form-item{
        vertical-align: middle;
    }
    .ant-form-item-label {
        padding: 0;
    }
`;

const ColBody = styled.div`
    border: 1px solid #e8e8e8;
    padding: 10px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    .collaborators .ant-form-item-control {
        width: 100%;
    }
`;

const LabelBlock = styled.div`
    margin-top: 10px;
    .ant-row{
        .ant-form-item-label.ant-col,.ant-form-item-control-wrapper.ant-col{
            display: block;
            text-align: left;
            float: none;
            order: 1;
        }
        &.ant-row.ant-form-item{
            display: block;
            margin-right: 0;
        }
    }
    .ant-form-item-label {
        line-height: normal;
        white-space: normal;
        margin: 10px 0;
        padding: 0;
    }
    .ant-form-item-control {
        width: 100%;
    }
`;

const RespondentFormFieldsWrapper = styled(LabelBlock)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    .ant-row .ant-col.ant-form-item-control-wrapper {
        padding-right: 0;
    }
`;

const RespondentFormFieldsContent = styled.div`
    display: flex;
    align-items: center;
    .ant-row.ant-form-item {
        margin-bottom: 0;
    }
`;

const RespondentFieldDragHandler = styled.div`
    padding: 5px 7px 5px 0;
    cursor: grab;
`;

const LabelSpace = styled.div`
    margin-top: 10px;
    .ant-form-item-label {
        line-height: normal;
        white-space: normal;
        text-align: left;
    }
`;

const PreviewButton = styled(Button)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
`;

class ProjectSettingsFields extends Component {
    static defaultProps = {
        prefix: 'testsSettings',
        settings: {}
    };

    onChangeSkipQuestionsAllowed = value => {
        if (value) {
            this.props.form.change(`${this.props.prefix}.pauseAllowed`, false);
        }
    }

    onChangeTimeLimited = value => {
        this.props.form.change(`${this.props.prefix}.timeLimitedBy`, value ? 'test' : null);
    }

    toggleDesign = show => {
        this.props.form.change(`${this.props.prefix}.infoScheme`, show ? {
            designScheme: {
                colors: []
            },
            description: PROJECT_DEFAULT_DESIGN_DESCRIPTION
        } : null);
    }

    onChangeColors = ({ colors, fontFamily }) => {
        const { form } = this.props;

        form.batch(() => {
            form.change(`${this.props.prefix}.infoScheme.designScheme.fontFamily`, fontFamily);
            form.change(`${this.props.prefix}.infoScheme.designScheme.colors`, colors);
        });
    }

    openColorSettings = () => {
        const { openCompanyDesignPageModal, form, prefix, disabled } = this.props;
        const designScheme = path([prefix, 'infoScheme', 'designScheme'], form.getState().values);

        openCompanyDesignPageModal({
            onChange: this.onChangeColors,
            designScheme,
            disabled,
            closeOnChange: true
        });
    }

    onChangeFormRequired = value => {
        const { form } = this.props;

        form.change('respondentFormFields', []);
    }

    renderDesignFields = () => {
        const { prefix, disabled } = this.props;

        return <Fragment>
            <LabelBlock>
                <Field
                    className='label-block'
                    name={`${prefix}.infoScheme.designScheme.logo`}
                    component={ImageUpload}
                    disabled={disabled}
                    height={55}
                    label='Логотип'
                    disableClear />
            </LabelBlock>
            <LabelSpace>
                <Field
                    name={`${prefix}.infoScheme.showRespondentName`}
                    component={Switch}
                    disabled={disabled}
                    label='Показывать фамилию и имя респондента'
                    disableClear
                    hideOptional />
            </LabelSpace>
            <LabelBlock>
                <Field
                    name={`${prefix}.infoScheme.description`}
                    component={Editor}
                    disabled={disabled}
                    label='Информация приветственной страницы тестирования'
                    disableClear />
            </LabelBlock>
            <LabelBlock>
                <Field
                    name={`${prefix}.infoScheme.designScheme.footerText`}
                    component={Editor}
                    disabled={disabled}
                    label='Футер'
                    disableClear />
            </LabelBlock>
            <div style={{ marginBottom: 15, marginTop: 15 }}>
                <PreviewButton onClick={this.openColorSettings}>
                    { disabled ? 'Предварительный просмотр цветовой схемы' : 'Предварительный просмотр и настройка цветовой схемы' }
                </PreviewButton>
            </div>
        </Fragment>;
    }

    onDragEnd = (result, fields) => {
        if (!result.destination) {
            return;
        }

        this.props.form.change('respondentFormFields', reorder(fields.value, result.source.index, result.destination.index));
    }

    renderRespondentFormFields = fields => {
        const { openRespondentFormFieldsModal } = this.props;
        const availableFields = filter(f => !any(propEq('code', f.code), fields.value || []), DEFAULT_RESPONDENT_FORM_FIELDS);

        return <DragDropContext onDragEnd={result => this.onDragEnd(result, fields)}>
            <Droppable droppableId='droppable'>
                { provided =>
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        { fields.value.map((field, index) => {
                            const staticField = path(['value', index, 'settings', 'static'], fields);
                            const type = find(propEq('id', field.type), RESPONDENT_FORM_FIELD_TYPES);
                            const IconComponent = type.icon;

                            return <Draggable key={field.code} draggableId={field.code} index={index}>
                                { provided =>
                                    <div ref={provided.innerRef} {...provided.draggableProps} style={{ marginTop: 5, ...provided.draggableProps.style }}>
                                        <RespondentFormFieldsWrapper>
                                            <RespondentFormFieldsContent>
                                                <RespondentFieldDragHandler {...provided.dragHandleProps}>
                                                    <MenuOutlined />
                                                </RespondentFieldDragHandler>
                                                <Tooltip title={type.value}>
                                                    <IconComponent style={{ marginRight: 5, color: '#f54d2e' }} />
                                                </Tooltip>
                                                { field.name }
                                            </RespondentFormFieldsContent>
                                            <Button.Group>
                                                { !staticField &&
                                                    <Button
                                                        icon={<EditOutlined />}
                                                        onClick={() => openRespondentFormFieldsModal({ field, onSubmit: item => fields.update(index, item) })} />
                                                }
                                                <Button
                                                    icon={<DeleteOutlined />}
                                                    type='danger'
                                                    onClick={() => fields.remove(index)} />
                                            </Button.Group>
                                        </RespondentFormFieldsWrapper>
                                    </div>
                                }
                            </Draggable>;
                        })}
                        {provided.placeholder}
                    </div>
                }
            </Droppable>
            <Button
                icon={<PlusOutlined />}
                onClick={() => openRespondentFormFieldsModal({
                    onSubmit: item => fields.push(item),
                    field: availableFields.length > 1 ? {} : { field: 'other', code: uniqid() },
                    fields: availableFields
                })}
                style={{ marginTop: 15 }}>
                <span className='hide-mobile'>Добавить</span>
            </Button>
        </DragDropContext>;
    }

    getPauseAllowedSwitchWrapper = props => {
        return <Tooltip title={props.disabled ? 'Нельзя разрешить паузу пока разрешен пропуск вопросов' : null}>
            { props.children }
        </Tooltip>;
    }

    render() {
        const { prefix, disabled, settings, showRespondentFormFields, getCompanyClients: { data, meta }, openCompanyClientModal, company } = this.props;
        const collaborators = pathOr([], ['items'], data);

        return <Fragment>
            <RowWrapper className='ant-form-inline'>
                <Col md={24} sm={24}>
                    <h3 className='title-categories'>Команда проекта</h3>
                    <ColBody>
                        { collaborators.length ?
                            <Row className='collaborators'>
                                <Col sm={24}>
                                    <Field
                                        name='collaborators'
                                        component={Select}
                                        options={collaborators}
                                        loading={meta.pending}
                                        namePath='fullName'
                                        placeholder='Выберите участников'
                                        isMulti />
                                </Col>
                            </Row> :
                            <div>
                                Участники компании не найдены. <SpanAsLink onClick={() => openCompanyClientModal({ company })}>Создать?</SpanAsLink>
                            </div>
                        }
                    </ColBody>
                </Col>
            </RowWrapper>
            <RowWrapper className='ant-form-inline'>
                <Col md={24} sm={24}>
                    <h3 className='title-categories'>Настройки теста</h3>
                    <ColBody>
                        <Row>
                            <Col md={12} sm={24}>
                                <Field
                                    name={`${prefix}.skipQuestionsAllowed`}
                                    component={Switch}
                                    disabled={disabled}
                                    label='Разрешить пропускать вопросы'
                                    hideOptional
                                    onChange={this.onChangeSkipQuestionsAllowed}
                                    disableClear />
                                <Field
                                    name={`${prefix}.reAnswerQuestionAllowed`}
                                    component={Switch}
                                    label='Разрешить изменение ответа на вопрос'
                                    hideOptional
                                    disableClear />
                                <ListenerField listenFieldName={`${prefix}.skipQuestionsAllowed`}>
                                    { fields =>
                                        <Field
                                            name={`${prefix}.pauseAllowed`}
                                            component={Switch}
                                            disabled={disabled || fields[prefix].skipQuestionsAllowed || settings.skipQuestionsAllowed}
                                            label='Разрешить паузу во время прохождения теста'
                                            hideOptional
                                            disableClear
                                            Wrapper={this.getPauseAllowedSwitchWrapper} />
                                    }
                                </ListenerField>
                            </Col>
                            <Col md={12} sm={24}>
                                <MultiItem>
                                    <Field
                                        name={`${prefix}.timeLimited`}
                                        component={Switch}
                                        disabled={disabled}
                                        label='Ограничить время'
                                        onChange={this.onChangeTimeLimited}
                                        hideOptional
                                        disableClear />
                                    <ListenerField listenFieldName={`${prefix}.timeLimited`}>
                                        { fields =>
                                            <Field
                                                size='small'
                                                name={`${prefix}.timeLimitedBy`}
                                                disabled={disabled}
                                                component={fields[prefix].timeLimited ? RadioButtons : () => null}
                                                options={TIME_LIMITED_BY}
                                                hideOptional
                                                disableClear />
                                        }
                                    </ListenerField>
                                </MultiItem>
                                <Field
                                    name={`${prefix}.shuffle`}
                                    component={Switch}
                                    disabled={disabled}
                                    label='Перемешивать варианты ответа'
                                    hideOptional
                                    disableClear />
                            </Col>
                        </Row>
                    </ColBody>
                </Col>
            </RowWrapper>
            <RowWrapper className='ant-form-inline'>
                <Col md={12} sm={24}>
                    <h3 className='title-categories'>Настройки дизайна</h3>
                    <ColBody>
                        <LabelSpace>
                            <ListenerField listenFieldName={`${prefix}.infoScheme`}>
                                { fields =>
                                    <Form.Item label='Дизайн страницы' wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
                                        <AntdSwitch checked={!!fields[prefix].infoScheme} onChange={this.toggleDesign} disabled={disabled} />
                                    </Form.Item>
                                }
                            </ListenerField>
                        </LabelSpace>
                        <FormSpy subscription={{ values: true }}>
                            { ({ values }) =>
                                path([prefix, 'infoScheme'], values) ?
                                    this.renderDesignFields() :
                                    <Field name={`${prefix}.infoScheme`} component={() => null} />
                            }
                        </FormSpy>
                    </ColBody>
                </Col>
                { showRespondentFormFields &&
                    <Col md={12} sm={24}>
                        <h3 className='title-categories'>Настройки формы</h3>
                        <ColBody>
                            <LabelSpace>
                                <Field
                                    name={`${prefix}.formRequired`}
                                    component={Switch}
                                    disabled={disabled}
                                    label='Показывать форму информации по респонденту'
                                    onChange={this.onChangeFormRequired}
                                    hideOptional
                                    disableClear />
                            </LabelSpace>
                            <ListenerField listenFieldName={`${prefix}.formRequired`}>
                                { formFields => formFields[prefix].formRequired || settings.formRequired ?
                                    <FieldArray name='respondentFormFields'>
                                        { ({ fields }) => this.renderRespondentFormFields(fields) }
                                    </FieldArray> :
                                    <Field name='respondentFormFields' component={() => null} />
                                }
                            </ListenerField>
                        </ColBody>
                    </Col>
                }
            </RowWrapper>
        </Fragment>
    }
}

export default asyncConnect({
    getCompanyClients: getCompanyClients
            .withPayload(({ company, item }) => ({
                company: company || item.company,
                q: {
                    enabled: true
                }
            }))
            .withSaga(function* (getProps) {
                yield takeEvery([toSuccess(POST_CLIENT)], function() {
                    const { getCompanyClients } = getProps();

                    getCompanyClients.refresh();
                })
            })
            .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
}, null, { openCompanyDesignPageModal, openRespondentFormFieldsModal, openCompanyClientModal })(ProjectSettingsFields);
