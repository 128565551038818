import React, { Component, Fragment } from 'react';
import { Input, Spin, Tabs, Badge, Avatar, Tag, Button } from 'antd';
import { withAsyncActions } from 'react-async-client';
import styled from 'styled-components';
import debounce from 'debounce';
import { toPairs, all, path, find, propEq, contains } from 'ramda';
import { withRouter } from 'react-router-dom';
import ClickOutside from 'react-click-outside';
import { withState } from 'recompose';
import qs from 'qs';
import { MailOutlined, GlobalOutlined, FileTextOutlined, PhoneOutlined, DatabaseOutlined, FileOutlined, TeamOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { getSearch } from '../../../actions/asyncActions';
import { getAvatar } from '../../../constants/urls';
import { PROJECT_RESPONDENT_STATUSES } from '../../../constants/companies';
import SpanAsLink from '../../user/table/SpanAsLink';
import DateFormat from '../../user/table/DateFormat';
import { pushRollbarError } from '../../../utils/rollbar';

const Wrapper = styled.div`
    .ant-input-search {
        width: auto;
        input {
            width: ${({ opened }) => opened ? 400 : 200}px;
        }
        input:focus {
            width: 400px;
        }
    }
    position: relative;
    line-height: 0;
`;

const Content = styled.div`
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    min-width: 400px;
    position: absolute;
    z-index: 90;
    top: 52px;
    left: 0;
    .ant-badge-count {
        background: #1890ff;
    }
    .ant-tabs-bar {
        margin: 0;
    }
    .ant-tabs-tabpane{
        padding: 15px;
    }
    .ant-spin {
        height: auto;
        padding: 22px;
    }
    .ant-tabs-tabpane {
        max-height: 300px;
        overflow-y: auto;
    }
`;

const CenterWrapper = styled.div`
    text-align: center;
`;

const RightWrapper = styled.div`
    text-align: right;
`;

const Item = styled.div`
    padding: 10px;
    display: flex;
    border-bottom: 1px solid #e8e8e8;
    align-items: center;
    h3 {
        margin: 0;
    }
`;

const EmployeeItem = styled(Item)`
    justify-content: space-between;
`;

const CompanyInfoBlock = styled.div`
    padding-left: 10px;
`;

const StyledTabsTabPane = styled(Tabs.TabPane)`
    ${Item},${EmployeeItem}{
        &:nth-last-child(2){
            border-bottom: 0;
        }
    }
`;

const ResultButton = styled.div`
    position: absolute;
    bottom: -40px;
    left: 0;
    width: 100%;
    button {
        width: 100%;
    }
`;

const getNameString = item => `${item.lastName || ''} ${item.firstName || ''} ${item.middleName || ''}`;

const Error = styled(Content)`
    color: #f54d2e;
    text-align: center;
    line-height: 20px;
    padding: 15px;
    .anticon-exclamation-circle {
        font-size: 30px;
        margin-bottom: 10px;
    }
`;

const NotFound = styled(CenterWrapper)`
    padding: 32px;
`;

class SearchContent extends Component {
    goToResult = path => {
        this.props.history.push(path);

        if (path === this.props.location.pathname) {
            this.props.resetData();
        }
    }

    showResultButton = () => {
        return !!(this.props.getSearch.data[this.props.tab] || []).length && contains(this.props.tab, ['respondents', 'companies']);
    }

    highlightSearch = string => {
        return (string || '').replace(
            new RegExp(this.props.search.replace(/\[|\\|\^|\$|\.|\||\?|\*|\+|\(|\)/gi, sym => `\\${sym}`), 'gi'),
            str => `<u>${str}</u>`
        );
    }

    renderTabWithCount = (title, items = []) => {
        return <span>{ title } <Badge count={items.length} style={{ backgroundColor: '#f54d2e' }} /></span>;
    }

    onChangeTab = tab => this.setState({ tab });

    renderRespondents = (items = []) => {
        return items.length ?
            items.map(item => {
                const status = find(propEq('id', item.status), PROJECT_RESPONDENT_STATUSES);

                return <EmployeeItem key={item.id}>
                    <div>
                        <strong>
                            <SpanAsLink
                                onClick={() => this.goToResult(`/company/${item.company}/projects/${item.project.id}/test/${item.testSuite}/respondents/${item.id}`)}
                                dangerouslySetInnerHTML={{ __html: this.highlightSearch(getNameString(item)) }} />
                        </strong>
                        <div>
                            <span><MailOutlined /> <span dangerouslySetInnerHTML={{ __html: this.highlightSearch(item.email) }} /></span>
                            { item.phone && <span style={{ marginLeft: 5 }}><PhoneOutlined /> { item.phone }</span> }
                        </div>
                        <div>
                            <span><GlobalOutlined /> { path(['_embedded', 'company', 'name'], item) }</span>
                            <span style={{ marginLeft: 5 }}><DatabaseOutlined /> { path(['project', 'name'], item) }</span>
                        </div>
                        <div>
                            <FileTextOutlined /> { path(['_embedded', 'testSuite', 'name'], item) }
                        </div>
                    </div>
                    <Tag color={status.color}>{ status.value }</Tag>
                </EmployeeItem>;
            }) :
            <CenterWrapper>Респонденты не найдены</CenterWrapper>;
    }

    renderEmployees = (items = []) => {
        return items.length ?
            items.map(item =>
                <EmployeeItem key={item.id}>
                    <div>
                        <strong>
                            <SpanAsLink
                                onClick={() => this.goToResult(`/companies/${item.company}/employees/${item.id}`)}
                                dangerouslySetInnerHTML={{ __html: this.highlightSearch(getNameString(item)) }} />
                        </strong>
                        <div>{ path(['_embedded', 'businessUnit', 'name'], item) }</div>
                        <div><GlobalOutlined /> { path(['_embedded', 'company', 'name'], item) }</div>
                    </div>
                    <RightWrapper>
                        <div><MailOutlined /> <span dangerouslySetInnerHTML={{ __html: this.highlightSearch(item.email) }} /></div>
                        { item.phone && <div><PhoneOutlined /> { item.phone }</div> }
                    </RightWrapper>
                </EmployeeItem>
            ) :
            <CenterWrapper>Сотрудники не найдены</CenterWrapper>;
    }

    renderProjects = (items = []) => {
        return items.length ?
            items.map(item =>
                <EmployeeItem key={item.id}>
                    <div>
                        <strong>
                            <SpanAsLink
                                onClick={() => this.goToResult(`/company/${item.company}/projects/${item.id}/name`)}
                                dangerouslySetInnerHTML={{ __html: this.highlightSearch(item.name)  }} />
                        </strong>
                        <div><GlobalOutlined /> { path(['_embedded', 'company', 'name'], item) }</div>
                    </div>
                    <div>
                        <div style={{ marginBottom: 2 }}>
                            { item.status === 'active' ?
                                <Tag color='green'>Активен</Tag> : item.status === 'archived' ?
                                <Tag color='orange'>В архиве</Tag> : <Tag color='red'>Скрытый</Tag>
                            }
                        </div>
                        <div>
                            <DateFormat date={item.createdAt} />
                        </div>
                    </div>
                </EmployeeItem>
            ) :
            <CenterWrapper>Проекты не найдены</CenterWrapper>;
    }

    renderCompanies = (items = []) => {
        return items.length ?
            items.map(item =>
                <Item key={item.id}>
                    <Avatar src={getAvatar(item.logo)} icon={<GlobalOutlined />} />
                    <CompanyInfoBlock>
                        <strong>
                            <SpanAsLink
                                onClick={() => this.goToResult(`/companies/${item.id}`)}
                                dangerouslySetInnerHTML={{ __html:  this.highlightSearch(item.name) }} />
                        </strong>
                        <div><FileOutlined /> { item.industry }</div>
                        <div>
                            <TeamOutlined /> { item.size }
                        </div>
                    </CompanyInfoBlock>
                </Item>
            ) :
            <CenterWrapper>Компании не найдены</CenterWrapper>;
    }

    getResultLink = () => {
        const { tab, search } = this.props;
        const searchQuery = qs.stringify({ filter: JSON.stringify({ text: search })});

        switch (tab) {
            case 'respondents':
                return `/respondents?${searchQuery}`;
            case 'companies':
                return `/companies?${searchQuery}`;
            default:
                return '/';
        }
    }

    goToSearchResult = () => {
        this.props.history.push(this.getResultLink());
    }

    render() {
        const { getSearch: { data, meta }, onChangeTab, tab } = this.props;
        const empty = all(([ _, items ]) => !items.length, toPairs(data));

        return <Content>
            { meta.pending ?
                <CenterWrapper><Spin /></CenterWrapper> : empty ?
                <NotFound>Ничего не найдено</NotFound> :
                <Fragment>
                    <Tabs size='small' activeKey={tab} onChange={onChangeTab}>
                        <StyledTabsTabPane tab={this.renderTabWithCount('Респонденты', data.respondents)} key='respondents'>
                            { this.renderRespondents(data.respondents) }
                        </StyledTabsTabPane>
                        <StyledTabsTabPane tab={this.renderTabWithCount('Сотрудники', data.employees)} key='employees'>
                            { this.renderEmployees(data.employees) }
                        </StyledTabsTabPane>
                        <StyledTabsTabPane tab={this.renderTabWithCount('Проекты', data.projects)} key='projects'>
                            { this.renderProjects(data.projects) }
                        </StyledTabsTabPane>
                        <StyledTabsTabPane tab={this.renderTabWithCount('Компании', data.companies)} key='companies'>
                            { this.renderCompanies(data.companies) }
                        </StyledTabsTabPane>
                    </Tabs>
                    { this.showResultButton() &&
                        <ResultButton onClick={this.goToSearchResult}>
                            <Button>Перейти к результатам поиска</Button>
                        </ResultButton>
                    }
                </Fragment>
            }
        </Content>;
    }
}

class Search extends Component {
    state = {
        search: '',
        opened: false,
        focused: false,
        error: false
    };

    componentDidCatch(error) {
        pushRollbarError(error);
        this.setState({ error: true });
    }

    componentDidUpdate(prev) {
        if (this.props.location.pathname !== prev.location.pathname) {
            this.resetData();
        }
    }

    search = debounce(value => {
        if (value.length > 2) {
            this.props.getSearch.refresh(value);
        }
    }, 400);

    onChange = e => {
        const { value } = e.target;

        this.setState({ search: value });
        this.search(value);
    }

    resetData = () => {
        this.setState({ opened: false, search: '' });
        this.props.setTab('respondents');
        this.props.getSearch.reset();
    }

    close = (e) => {
        if (!this.state.focused && this.state.opened) {
            this.resetData();
        }
    }

    render() {
        return <Wrapper opened={this.state.opened}>
            <Input.Search
                onFocus={() => this.setState({ opened: true, focused: true })}
                onBlur={() => this.setState({ focused: false })}
                value={this.state.search}
                onChange={this.onChange}
                placeholder='Быстрый поиск' />
            <ClickOutside onClickOutside={this.close}>
                { this.state.error ?
                    <Error>
                        <ExclamationCircleOutlined />
                        <div>Не удалось отобразить данные</div>
                    </Error> :
                    this.state.search.length > 2 && this.state.opened &&
                        <SearchContent
                            {...this.props}
                            onChangeTab={this.props.setTab}
                            resetData={this.resetData}
                            tab={this.props.tab}
                            search={this.state.search} />
                }
            </ClickOutside>
        </Wrapper>;
    }
}

export default withRouter(
    withState('tab', 'setTab', 'respondents')(
        withAsyncActions({
            getSearch: getSearch
                .withSuccessHandler(({ getSearch: { data }, setTab, tab }) => {
                    const tabs = ['respondents', 'employees', 'projects', 'companies'];
                    const nextTab = find(item => (data[item] || []).length, tabs) || 'respondents';

                    if (tab !== nextTab && !(data[tab] || []).length) {
                        setTab(nextTab);
                    }
                })
                .withOptions({ resetOnUnmount: true })
        })(Search)
    )
);
