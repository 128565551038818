import React, { Component, Fragment } from 'react';
import * as yup from 'yup';
import { prop, fromPairs, keys } from 'ramda';
import { Field } from 'react-final-form';

import withFormWrapper from '../../hocs/withFormWrapper';
import SubmitButton from '../formComponents/SubmitButton';
import Input from '../formComponents/Input';

class GroupForm extends Component {
    render() {
        const { item, dirty } = this.props;

        return <Fragment>
            { item ?
                keys(item || {}).map((name, index) =>
                    <Field
                        key={`variable-field-${index}`}
                        name={name}
                        label={name}
                        component={Input} />
                ) :
                <Field
                    name='name'
                    component={Input}
                    label='Название' />
            }
            <SubmitButton type='primary' disabled={!dirty}>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

const validationSchema = props => yup.object().shape({
    name: props.item ? yup.string().nullable() : yup.string().required()
});

export default withFormWrapper(GroupForm, {
    validationSchema,
    subscriptions: { dirty: true },
    mapPropsToValues: prop('item'),
    mapBeforeSubmit: (values, props) => ({
        id: props.id,
        data: props.item ?
            keys(values).map(key => ({
                op: 'replace',
                path: `/groups/${props.group}/${key}`,
                value: values[key]
            })) : ([{
                op: 'add',
                path: `/groups/${values.name}`,
                value: fromPairs((props.variables || []).map(variable => ([variable, ''])))
            }])
    })
});
