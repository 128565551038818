import React, { Component, Fragment } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import * as yup from 'yup';
import styled from 'styled-components';

import withFormWrapper from '../hocs/withFormWrapper';
import InputNumber from './formComponents/InputNumber';
import Input from './formComponents/Input';
import SubmitButton from './formComponents/SubmitButton';
import { fromPairs, pathOr, toPairs } from 'ramda';

const PointsField = styled.div`
    display: flex;
    button {
        margin-left: 8px;
        width: 38px;
    }
    .ant-form-item,
    .ant-input-number {
        width: 100%;
    }
`;

class CompanyIntegralResultScaleForm extends Component {
    renderFields = ({ fields }) => {
        return <div>
            { !!fields.length &&
                <Row gutter={8} style={{ marginBottom: 5 }}>
                    <Col span={16}>Текст вывода</Col>
                    <Col span={8}>Разброс баллов</Col>
                </Row>
            }
            { fields.map((name, index) =>
                <Row key={name} gutter={8}>
                    <Col span={16}>
                        <Field
                            name={`${name}.name`}
                            component={Input} />
                    </Col>
                    <Col span={8}>
                        <PointsField>
                            <Field
                                name={`${name}.points`}
                                component={InputNumber} />
                            <Button icon={<DeleteOutlined />} type='danger' onClick={() => fields.remove(index)} />
                        </PointsField>
                    </Col>
                </Row>
            )}
            <Button icon={<PlusOutlined />} onClick={() => fields.push({})} style={{ marginBottom: 10 }}>
                Добавить
            </Button>
        </div>;
    }

    render() {
        return <Fragment>
            <FieldArray name='integralResultScale'>
                { this.renderFields }
            </FieldArray>
            <SubmitButton type='primary'>Сохранить</SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    integralResultScale: yup.array().of(yup.object().shape({
        name: yup.string().nullable().required(),
        points: yup.number().nullable().required()
    }))
});

export default withFormWrapper(CompanyIntegralResultScaleForm, {
    mapPropsToValues: ({ item }) => ({
        id: item.id,
        integralResultScale: toPairs(pathOr({}, ['reportsSettings', 'integralResultScale'], item)).map(([ points, name ]) => ({ points, name }))
    }),
    mapBeforeSubmit: (values, { item }) => ({
        id: values.id,
        data: [{ op:
            'replace',
            path: '/reportsSettings',
            value: {
                ...(item.reportSettings || {}),
                integralResultScale: fromPairs((values.integralResultScale || []).map(({ points, name }) => ([ points, name ])))
            }
        }]
    }),
    validationSchema
});
