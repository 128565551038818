import React, { Component, Fragment } from 'react';
import { asyncConnect, toSuccess } from 'react-async-client';
import { Tabs, Spin } from 'antd';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import { find, propEq, path } from 'ramda';
import { takeEvery } from 'redux-saga/effects';

import { getTestTemplate } from '../../../actions/asyncActions';
import DetailToolbar from '../DetailToolbar';
import TestInfoTab, { TestInfoTabButtons } from './TestInfoTab';
import { PATCH_TEST_TEMPLATE, DELETE_TEST_TEMPLATE, POST_TEST_TEMPLATE_WITH_ID } from '../../../constants/actionTypes';
import TestChangesTab, { TestChangesTabButtons } from './TestChangesTab';
import TestHistoryTab from './TestHistoryTab';
import TestCategoriesTab from './TestCategoriesTab';
import TestSettingsTab from './TestSettingsTab';
import TestRespondentsTab, { TestRespondentsTabButtons } from './TestRespondentsTab';
import TestRespondent, { TestRespondentButtons } from './TestRespondent';
import TestAuditsTab, { TestAuditsTabButtons } from './TestAuditsTab';
import TestAudit, { TestAuditButtons } from './TestAudit';

const tabs = [
    { key: 'info', title: 'Информация', component: TestInfoTab, buttons: TestInfoTabButtons },
    { key: 'categories', title: 'Компетенции', component: TestCategoriesTab },
    { key: 'settings', title: 'Настройки', component: TestSettingsTab },
    { key: 'audits', title: 'Эксперты', component: TestAuditsTab, buttons: TestAuditsTabButtons, routes: [
        { path: '/tests/:test/audits/:id', component: TestAudit }
    ] },
    { key: 'respondents', title: 'Респонденты', component: TestRespondentsTab, buttons: TestRespondentsTabButtons, routes: [
        { path: '/tests/:id/respondents/:itemId', component: TestRespondent }
    ] },
    { key: 'changes', title: 'Изменения', component: TestChangesTab, buttons: TestChangesTabButtons },
    { key: 'history', title: 'История', component: TestHistoryTab }
];

const StyledTabs = styled(Tabs)`
    .ant-tabs-bar {
        margin: 0;
    }
`;

const ToolbarTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

class Test extends Component {
    renderTitle = () => {
        const { getTestTemplate: { data, meta }, match: { params: { type, itemId }}} = this.props;
        const Buttons = (!itemId && path(['buttons'], find(propEq('key', type), tabs))) || this.getButtons();

        return <ToolbarTitle>
            <h1>{ data.name }</h1>
            { meta.lastSucceedAt && Buttons && <Buttons {...this.props} /> }
        </ToolbarTitle>;
    }

    getButtons = () => {
        const { match: { params: { type, itemId }}} = this.props;

        if (itemId && type === 'respondents') {
            return TestRespondentButtons;
        }

        if (itemId && type === 'audits') {
            return TestAuditButtons;
        }

        return null;
    }

    onChange = key => {
        const { match: { params: { id }}, history } = this.props;

        history.replace(`/tests/${id}/${key}`);
    }

    render() {
        const { getTestTemplate: { data, meta }, match: { params: { id, type }}} = this.props;

        return <Fragment>
            <DetailToolbar title={this.renderTitle()} backUrl='/tests' />
            { meta.pending && !meta.lastSucceedAt ?
                <Spin /> :
                (meta.lastSucceedAt ?
                    <StyledTabs
                        onChange={this.onChange}
                        activeKey={type}
                        animated={{ tabPane: false }}>
                        { tabs.map(tab =>
                            <Tabs.TabPane key={tab.key} tab={tab.title}>
                                { (tab.routes || []).map(route =>
                                    <Route key={path} path={route.path} render={props =>
                                        <route.component {...props} test={data} />
                                    } />
                                )}
                                <Route path={`/tests/${id}/${tab.key}`} exact render={props =>
                                    <tab.component
                                        {...props}
                                        id={id}
                                        test={data} />
                                } />
                            </Tabs.TabPane>
                        )}
                    </StyledTabs> : null)
            }
        </Fragment>
    }
}

export default asyncConnect({
    getTestTemplate: getTestTemplate
        .withPayload(({ match: { params: { id }}}) => id)
        .withSaga(function* (getProps) {
            yield takeEvery([
                toSuccess(PATCH_TEST_TEMPLATE),
                toSuccess(DELETE_TEST_TEMPLATE),
                toSuccess(POST_TEST_TEMPLATE_WITH_ID)
            ], function() {
                const { getTestTemplate } = getProps();

                getTestTemplate.refresh();
            })
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})(Test);
