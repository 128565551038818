import { values, forEach } from 'ramda';

export const getImportErrorMessage = err => {
    let message = '';

    if (err.message === 'Unable to parse file') {
        forEach(attrs => {
            forEach(attr => {
                if (attr.attributes.required) {
                    message += `Строка ${attr.line}${attr.column ? `, столбец ${attr.column}` : ''}. Не найдены обязательные заголовки: ${attr.attributes.required}</br>`;
                }
                if (attr.attributes.missed) {
                    message += `Строка ${attr.line}${attr.column ? `, столбец ${attr.column}` : ''}. Значения обязательны: ${attr.attributes.missed}</br>`;
                }
            }, attrs || []);
        }, values(err.attributes || {}) || []);
    } else {
        message = 'Не удалось импортировать данные';
    }

    return message;
}
