import React, { Component, Fragment } from 'react';
import { head, find, propEq, equals, pathOr, isNil, filter, any, path } from 'ramda';
import { Tag, message, Button, Tooltip, Dropdown, Menu } from 'antd';
import { asyncConnect } from 'react-async-client';
import { withState } from 'recompose';
import styled from 'styled-components';
import qs from 'qs';
import { Link } from 'react-router-dom';
import {
    TrophyOutlined,
    QuestionCircleOutlined,
    ClockCircleOutlined,
    DownOutlined,
    PlusOutlined,
    MailOutlined
} from '@ant-design/icons';

import TableList from '../table/TableList';
import { deleteCompanyRespondents, getRespondents, getUser, patchCompanyRespondents, postCompanyTestsRestart, getCompanyTests } from '../../../actions/asyncActions';
import { openRespondentLetterModal, openImportRespondentsModal, openAddRespondentModal, openRespondentReportsLetterModal } from '../../../actions/modalActions';
import DateFormat from '../table/DateFormat';
import { PROJECT_RESPONDENT_STATUSES, PROJECT_RESPONDENT_EMPLOYEE, INTEGRAL_RESULT } from '../../../constants/companies';
import ProjectRespondentsFilter from '../../forms/filters/ProjectRespondentsFilter';
import { getUrlParams, getFilters, getSorting } from '../../../utils/urlParams';
import {
    DELETE_COMPANY_RESPONDENTS,
    POST_COMPANY_MESSAGES,
    POST_COMPANY_RESPONDENTS_IMPORT,
    PATCH_COMPANY_RESPONDENT,
    PATCH_COMPANY_RESPONDENTS,
    POST_COMPANY_RESPONDENT,
    POST_COMPANY_RESPONDENTS,
    DELETE_COMPANY_RESPONDENT,
    POST_COMPANY_TESTS_RESTART
} from '../../../constants/actionTypes';
import { getToken } from '../../../utils/token';
import { getTreeRespondents } from '../../../utils/companies';
import { getExecutionTime } from '../../../utils/time';
import { ADMIN } from '../../../constants/roles';
import Contacts from '../table/Contacts';

const TableWrapper = styled.div`
    .ant-table-row-expand-icon {
        display: none;
    }
    .table-subtitle {
        background: rgba(0, 0, 0, 0.01);
        .ant-table-selection-column {
            display: none;
        }
        td {
            padding: 5px 16px;
        }
    }
    .ant-table-row-indent {
        padding: 0!important;
    }
`;

const ResultBox = styled.span`
    font-weight: bold;
    color: ${({ color }) => color || '#000'};
    .anticon{
        margin-right: 5px;
    }
`;

class ProjectRespondentsTabButtonsComponent extends Component {
    getSearchPath = () => {
        const { location, auditEnabled } = this.props;

        return qs.stringify({
            ...getSorting(location),
            q: JSON.stringify({
                ...getFilters(location),
                auditEnabled
            }),
            access_token: getToken()
        }, { addQueryPrefix: true, strictNullHandling: true });
    }

    getDownloadLink = (extension = '.xlsx') => {
        const { testSuites, getCompanyProject: { data }, location } = this.props;
        const filters = getFilters(location);

        return `/api/companies/${data.company}/projects/${data.id}/tests/${pathOr(path([0, 'id'], testSuites), ['testSuites', 0], filters)}/respondents${extension}${this.getSearchPath()}`;
    }

    getPointsDownloadLink = (extension = '.xlsx') => {
        const { getCompanyProject: { data } } = this.props;

        return `/api/companies/${data.company}/projects/${data.id}/respondents${extension}${this.getSearchPath()}`;
    }

    getResultDownloadLink = () => {
        const { getCompanyProject: { data } } = this.props;

        return `/api/companies/${data.company}/projects/${data.id}/questions.xlsx${this.getSearchPath()}`;
    }

    getIntegralResultDownloadLink = () => {
        const { getCompanyProject: { data }, testSuites, location } = this.props;
        const filters = getFilters(location);

        return `/api/companies/${data.company}/projects/${data.id}/tests/${pathOr(path([0, 'id'], testSuites), ['testSuites', 0], filters)}/respondents/audit_solutions.xlsx${this.getSearchPath()}`;
    }

    getAuditDownloadLink = () => {
        const { getCompanyProject: { data }, testSuites, location } = this.props;
        const filters = getFilters(location);

        return `/api/companies/${data.company}/projects/${data.id}/tests/${pathOr(path([0, 'id'], testSuites), ['testSuites', 0], filters)}/respondents/audits.xlsx${this.getSearchPath()}`;
    }

    addRespondents = () => {
        const { openAddRespondentModal, getCompanyProject: { data }, auditEnabled, testSuites } = this.props;

        openAddRespondentModal({ item: data, auditEnabled, tests: testSuites });
    }

    renderSendOverlay = () => {
        const { getCompanyProject: { meta, data }, getRespondentsAudit: { data: auditCheck }, getCompanyTests, isAdmin, auditEnabled } = this.props;

        return <Menu>
            <Menu.Item disabled={meta.pending || getCompanyTests.meta.pending} onClick={() => this.props.openImportRespondentsModal({
                company: data.company,
                project: data.id,
                auditEnabled,
                tests: pathOr([], ['data', 'items'], getCompanyTests)
            })}>
                Импорт
            </Menu.Item>
            <Menu.Item>
                <Tooltip title='Выгрузка респондентов в excel' placement="leftTop">
                    <a href={this.getPointsDownloadLink()} target="_blank" rel="noopener noreferrer" download>Экспорт сырых баллов</a>
                </Tooltip>
            </Menu.Item>
            { isAdmin && data.type !== 'custom' &&
                <Menu.Item>
                    <Tooltip title='Выгрузка результатов тестов респондентов в excel' placement="leftTop">
                        <a href={this.getResultDownloadLink()} target="_blank" rel="noopener noreferrer" download>Экспорт сырых ответов</a>
                    </Tooltip>
                </Menu.Item>
            }
            { auditEnabled &&
                <Menu.Item disabled={!path(['_meta', 'count'], auditCheck)}>
                    <Tooltip title='Выгрузка экспертов в excel' placement="leftTop">
                        {!!path(['_meta', 'count'], auditCheck)
                            ? <a href={this.getAuditDownloadLink()} target="_blank" rel="noopener noreferrer" download>Выгрузка сводного отчета</a>
                            : 'Выгрузка сводного отчета'
                        }
                    </Tooltip>
                </Menu.Item>
            }
        </Menu>
    }

    render() {
        return <Button.Group>
            <Button type='primary' icon={<PlusOutlined />} onClick={this.addRespondents}><span className='hide-mobile'>Добавить</span></Button>
            <Dropdown overlay={this.renderSendOverlay()} trigger={['click']}>
                <Button icon={<DownOutlined />} />
            </Dropdown>
        </Button.Group>
    }
}

export const ProjectRespondentsTabButtons = asyncConnect({
    getRespondentsAudit: getRespondents
        .withPayload(({ getCompanyProject: { data: project } }) => ({
            limit: 1,
            q: {
                statuses: ['finished'],
                projects: [project.id],
                company: project.company,
                auditEnabled: true,
            },
        }))
        .withParams({ name: 'auditCheck' })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    getCompanyTests: getCompanyTests
        .withParams({ name: 'testSuites' })
}, null, { openImportRespondentsModal, openAddRespondentModal })(ProjectRespondentsTabButtonsComponent);

class ProjectRespondentsTab extends Component {
    componentDidUpdate(prev) {
        if (!equals(getUrlParams(this.props.location), getUrlParams(prev.location))) {
            this.clearSelected();
        }
    }

    getColumns = () => {
        const { project, auditEnabled } = this.props;

        return [
            {
                title: 'Респондент',
                key: 'lastName',
                sorter: true,
                filters: PROJECT_RESPONDENT_EMPLOYEE.map(i => ({ text: i.value, value: i.id })),
                filterMultiple: false,
                render: item => item.isSubtitle ?
                    ({ children: <strong>{ item.name }</strong>, props: { colSpan: 7 } }) :
                    <Fragment>
                        <div>
                            <Link to={`/company/${project.company}/projects/${project.id}/test/${item.testSuite}/${auditEnabled ? 'experts' : 'respondents'}/${item.id}`}>
                                { item.lastName } { item.firstName } { item.middleName }
                            </Link>
                        </div>
                        <div>{ item.employee ? 'Сотрудник' : '' }</div>
                    </Fragment>
            },
            {
                title: 'Контакты',
                dataIndex: 'email',
                key: 'email',
                render: (email, { phone, isSubtitle }) => isSubtitle ? ({ children: null, props: { colSpan: 0 }}) :
                    <Contacts email={email} phone={phone} />
            },
            {
                title: 'Время',
                dataIndex: ['_embedded', 'solution', 'solutionTime'],
                key: 'solution.solutionTime',
                sorter: true,
                width: 130,
                className: 'center-column',
                render: (time, { isSubtitle }) => isSubtitle ? ({ children: null, props: { colSpan: 0 }}) : !isNil(time) ? <ResultBox color='#2b3d4f'><ClockCircleOutlined />{getExecutionTime(time)}</ResultBox> : null
            },
            {
                title: 'Балл',
                dataIndex: ['respondentStatistic', 'integralResult'],
                key: 'respondentStatistic.integralResult',
                sorter: true,
                width: 100,
                className: 'center-column',
                render: (integralResult, { isSubtitle }) => {
                    if (isSubtitle) {
                        return ({ children: null, props: { colSpan: 0 }});
                    }

                    const result = integralResult ? (integralResult.points || 0) : 0;
                    const { tooltip } = find(res => res.from <= result && result <= res.to, INTEGRAL_RESULT) || {};

                    return integralResult ?
                        <Tooltip title={tooltip}>
                            <ResultBox color="#2b3d4f"><TrophyOutlined />
                                { result % 1 === 0 ? result : result.toFixed(1)}
                            </ResultBox>
                        </Tooltip> : null;
                }
            },
            {
                title: 'Ответы',
                dataIndex: ['_embedded', 'solution', '_embedded', 'answers'],
                key: 'solution.answers',
                sorter: true,
                width: 110,
                className: 'center-column',
                render: (answers, item) => {
                    const test = find(propEq('id', item.testSuite), this.props.getCompanyTests.data.items || []);

                    return item.isSubtitle ?
                        ({ children: null, props: { colSpan: 0 }}) :
                        answers ?
                            <ResultBox color='#2b3d4f'><QuestionCircleOutlined />
                                { (100 * filter(a => a.correct, answers).length / pathOr([], ['questions'], test).length).toFixed(1) }%
                            </ResultBox> : null
                }
            },
            {
                dataIndex: 'status',
                key: 'statuses',
                filters: PROJECT_RESPONDENT_STATUSES.map(i => ({ text: i.value, value: i.id })),
                className: 'right-column',
                render: (status, item) => {
                    const { isSubtitle, canceled } = item;

                    if (isSubtitle) {
                        return { children: null, props: { colSpan: 0 }};
                    }

                    const data = find(propEq('id', status), PROJECT_RESPONDENT_STATUSES);

                    return <Fragment>
                        { canceled ?
                            <Tooltip title={data.value}>
                                <Tag style={{ marginBottom: 5 }} color='red'>Отменен</Tag>
                            </Tooltip> :
                            (data ? <Tag style={{ marginBottom: 5 }} color={data.color}>{ data.value }</Tag> : null)
                        }
                        <div><DateFormat date={path(data.dateField, item)} /></div>
                    </Fragment>;
                }
            }
        ];
    }

    setSelected = selected => this.props.setSelected(selected);

    delete = () => {
        const { deleteCompanyRespondents, selected, project, testSuites } = this.props;

        deleteCompanyRespondents.dispatch({
            items: selected,
            project: project.id,
            company: project.company,
            test: head(testSuites)
        });
    }

    clearSelected = () => this.setSelected([]);

    openSendLetterModal = filtered => {
        const { selected, project, openRespondentLetterModal, location, count } = this.props;

        openRespondentLetterModal({
            item: {
                respondentsFilter: filtered ? getFilters(location) || {} : ({
                    respondents: selected
                }),
                project: project.id,
                company: project.company
            },
            filtered,
            count,
            clearSelected: this.clearSelected
        });
    }

    openSendPersonalReportsModal = () => {
        const { project, openRespondentReportsLetterModal } = this.props;

        openRespondentReportsLetterModal({
            item: {
                project: project.id,
                company: project.company
            },
        });
    }

    cancelSelected = () => {
        const { selected, items, project, patchCompanyRespondents } = this.props;

        patchCompanyRespondents.dispatch({
            project: project.id,
            company: project.company,
            items: selected.map(id => ({
                id,
                test: find(propEq('id', id), items).testSuite,
                data: [{ op: 'replace', path: '/canceled', value: true }]
            }))
        });
    }

    restartSelected = () => {
        const { selected, postCompanyTestsRestart, project, items } = this.props;

        postCompanyTestsRestart.dispatch({
            project: project.id,
            company: project.company,
            items: selected.map(id => ({
                respondent: id,
                id: find(propEq('id', id), items).testSuite
            }))
        });
    }

    isDisallowRestart = () => {
        return any(id => find(propEq('id', id), this.props.items).status !== 'started', this.props.selected);
    }

    getButtonsMenu = () => {
        return <Menu>
            <Menu.Item key='delete' onClick={this.delete}>
                Удалить
            </Menu.Item>
            <Menu.Item key='cancel' onClick={this.cancelSelected}>
                Отменить
            </Menu.Item>
            { this.props.isAdmin &&
                <Menu.Item key='reset' onClick={this.restartSelected} disabled={this.isDisallowRestart()}>
                    Сбросить
                </Menu.Item>
            }
        </Menu>;
    }

    renderButtons = () => {
        const { selected, project, count } = this.props;
        const respondentsCount = selected.length || count;

        return project.id ?
            <Button.Group>
                <Button
                    icon={<MailOutlined />}
                    type='primary'
                    onClick={() => this.openSendLetterModal(!selected.length)}
                    disabled={!respondentsCount}
                >
                    <span className='hide-mobile'>Отправить письмо</span>&nbsp;{ respondentsCount ? `(${respondentsCount})` : '' }
                </Button>
                <Button
                    icon={<MailOutlined />}
                    type='primary'
                    onClick={this.openSendPersonalReportsModal}
                    disabled={!respondentsCount}
                >
                    <span className='hide-mobile'>Отправить индивидуальный отчет</span>
                </Button>
                <Dropdown overlay={this.getButtonsMenu()} trigger={['click']} disabled={!selected.length}>
                    <Button icon={<DownOutlined />} />
                </Dropdown>
            </Button.Group> : null;
    }

    getExpanded = items => (items || []).map(({ id }) => id);

    showTests = () => pathOr([], ['testSuites'], this.props).length > 1;

    render() {
        const { project, auditEnabled, getCompanyTests } = this.props;
        const showTests = this.showTests();
        const props = showTests ? {
            parseItems: getTreeRespondents(getCompanyTests.data.items || []),
            getExpanded: this.getExpanded
        } : {};

        return project.id ?
            <TableWrapper className='wrap-bg'>
                <TableList
                    action={getRespondents}
                    columns={this.getColumns()}
                    param={{ type: 'table' }}
                    payload={{ relations: ['employee', 'employee.businessUnit'] }}
                    filterForm={ProjectRespondentsFilter}
                    filterProps={{ showTests }}
                    rowClassName={item => item.isSubtitle && 'table-subtitle'}
                    staticFilter={{ projects: [project.id], company: project.company, auditEnabled: !!auditEnabled }}
                    refreshActions={[
                        DELETE_COMPANY_RESPONDENTS,
                        POST_COMPANY_MESSAGES,
                        POST_COMPANY_RESPONDENTS_IMPORT,
                        POST_COMPANY_TESTS_RESTART,
                        PATCH_COMPANY_RESPONDENT,
                        PATCH_COMPANY_RESPONDENTS,
                        POST_COMPANY_RESPONDENT,
                        POST_COMPANY_RESPONDENTS,
                        DELETE_COMPANY_RESPONDENT
                    ]}
                    buttons={this.renderButtons()}
                    defaultSort={{
                        sort_by: 'createdAt',
                        sort_order: 'desc'
                    }}
                    rowSelection={{
                        selectedRowKeys: this.props.selected,
                        onChange: this.setSelected,
                        type: 'checkbox',
                        getCheckboxProps: item => ({
                            disabled: item.isSubtitle
                        })
                    }}
                    {...props} />
            </TableWrapper> : null;
    }
}

const stateToProps = state => ({
    items: getRespondents.withParams({ type: 'table' }).selectData(state).items || [],
    count: pathOr(0, ['_meta', 'count'], getRespondents.withParams({ type: 'table' }).selectData(state)),
    isAdmin: getUser.selectData(state).role === ADMIN
});

export default withState('selected', 'setSelected', [])(
    asyncConnect({
        getCompanyTests: getCompanyTests
            .withParams({ name: 'testSuites' }),
        deleteCompanyRespondents: deleteCompanyRespondents
            .withSuccessHandler(({ setSelected }) => {
                message.success('Респонденты успешно удалены');
                setSelected([]);
            })
            .withErrorHandler(() => message.error('Не удалось удалить респондентов'))
            .withOptions({ resetOnUnmount: true }),
        patchCompanyRespondents: patchCompanyRespondents
            .withSuccessHandler(({ setSelected }) => {
                message.success('Тест успешно отменен');
                setSelected([]);
            })
            .withErrorHandler(() => message.error('Не удалось отменить тест'))
            .withOptions({ resetOnUnmount: true }),
        postCompanyTestsRestart: postCompanyTestsRestart
            .withSuccessHandler(({ setSelected }) => {
                message.success('Прохождение теста успешно сброшено');
                setSelected([]);
            })
            .withErrorHandler(() => message.error('Не удалось сбросить прохождение теста'))
            .withOptions({ resetOnUnmount: true }),
    }, stateToProps, { openRespondentLetterModal, openRespondentReportsLetterModal })(ProjectRespondentsTab)
);
