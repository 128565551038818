import React from 'react';
import {
    DatabaseOutlined,
    GlobalOutlined,
    QuestionCircleOutlined,
    TeamOutlined,
    BulbOutlined,
    FileDoneOutlined,
    WalletOutlined,
    UsergroupAddOutlined,
    CodeOutlined,
    SettingOutlined,
    FormOutlined,
    FileOutlined,
    FileUnknownOutlined,
} from '@ant-design/icons';

import { ADMIN_MODERATOR, SENIOR_MANAGER, ADMIN, MANAGER } from './roles';

export default [
    { title: 'Тесты', icon: <DatabaseOutlined />, key: '/', route: '/company', roles: [ADMIN, MANAGER, SENIOR_MANAGER] },
    { title: 'Опросники', icon: <FileUnknownOutlined />, key: '/surveys', roles: [ADMIN, MANAGER, SENIOR_MANAGER] },
    { title: 'Компании', icon: <GlobalOutlined />, key: '/companies', roles: [ADMIN] },
    { title: 'Компания', icon: <GlobalOutlined />, key: ({ user }) => `/companies/${user.company}/info/details`, route: '/companies', roles: [SENIOR_MANAGER] },
    { title: 'База вопросов', icon: <QuestionCircleOutlined />, key: '/questions', roles: [ADMIN, MANAGER, SENIOR_MANAGER], cond: user => user.role !== ADMIN ? user.ownQuestions : true },
    { title: 'Рекомендации', icon: <FileDoneOutlined />, key: '/recommendations', roles: [ADMIN], cond: user => user.role !== ADMIN ? user.ownQuestions : true },
    { title: 'Респонденты', icon: <TeamOutlined />, key: '/respondents', roles: [ADMIN, MANAGER, SENIOR_MANAGER] },
    { title: 'Эксперты', icon: <BulbOutlined />, key: '/experts', roles: ADMIN_MODERATOR },
    { title: 'Подписанты', icon: <FormOutlined />, key: '/acceptors', roles: [ADMIN] },
    { title: 'Готовые тесты', icon: <FileDoneOutlined />, key: '/tests', roles: ADMIN_MODERATOR },
    { title: 'Готовые опросники', icon: <FileDoneOutlined />, key: '/questionnaries', roles: ADMIN_MODERATOR },
    { title: 'Отчеты', icon: <FileOutlined />, key: '/reports', roles: [ADMIN] },
    { title: 'Платежи', icon: <WalletOutlined />, key: '/payments', roles: [ADMIN] },
    { title: 'Админы', icon: <UsergroupAddOutlined />, key: '/admins', roles: [ADMIN] },
    { title: 'Команды', icon: <CodeOutlined />, key: '/commands', roles: [ADMIN, SENIOR_MANAGER], cond: user => user.role !== ADMIN ? user.ownQuestions : true  },
    { title: 'Настройки', icon: <SettingOutlined />, key: '/settings', roles: [ADMIN] }
];
