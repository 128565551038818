import React, { Component } from 'react';
import { message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import { postCompanyEmployee, putCompanyEmployee } from '../../actions/asyncActions';
import CompanyEmployeeForm from '../forms/CompanyEmployeeForm';
import Modal from './Modal';

class CompanyEmployeeModal extends Component {
    render() {
        const { modal, params, postCompanyEmployee, putCompanyEmployee } = this.props;

        return <Modal
            {...modal}
            footer={null}
            title={params.id ? 'Редактировать сотрудника' : 'Добавить сотрудника'}>
            <CompanyEmployeeForm
                item={params}
                formAction={params.id ? putCompanyEmployee : postCompanyEmployee} />
        </Modal>;
    }
}

export default withAsyncActions({
    postCompanyEmployee: postCompanyEmployee
        .withSuccessHandler(({ close }) => {
            message.success('Сотрудник успешно добавлен');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
    putCompanyEmployee: putCompanyEmployee
        .withSuccessHandler(({ close }) => {
            message.success('Сотрудник успешно изменен');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(CompanyEmployeeModal);
