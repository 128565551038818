import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import { message } from 'antd';
import { path } from 'ramda';

import QuestionForm, { defaultAnswerVariants } from '../../forms/QuestionForm';
import { postQuestion, getUser } from '../../../actions/asyncActions';

class QuestionAdd extends Component {
    render() {
        const { company, location } = this.props;
        const category = path(['state', 'category'], location);
        const item = {
            type: 'choice',
            answerVariants: defaultAnswerVariants,
            multipleAnswers: false,
            ...(category ? {
                category: category.id,
                _embedded: {
                    category
                }
            } : {}),
            company
        };

        return <QuestionForm
            formAction={this.props.postQuestion}
            title='Добавить вопрос'
            item={item} />;
    }
}

const stateToProps = state => {
    const user = getUser.selectData(state);

    return {
        company: user.ownQuestions ? user.company : undefined
    };
}

export default asyncConnect({
    postQuestion: postQuestion
        .withSuccessHandler(({ history }) => {
            message.success('Вопрос успешно добавлен');
            history.goBack();
        })
        .withErrorHandler(() => message.error('Не удалось добавить вопрос'))
        .withOptions({ resetOnUnmount: true })
}, stateToProps)(QuestionAdd);
