import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import Modal from './Modal';
import { postSurveyRespondent, putSurveyRespondent } from '../../actions/asyncActions';
import SurveyRespondentForm from '../forms/survey/SurveyRespondentForm';

class SurveyRespondentModal extends Component {
    render() {
        const { modal, postSurveyRespondent, putSurveyRespondent, params: { groups, item } } = this.props;

        return <Modal
            {...modal}
            title={item.id ? 'Редактировать респондента' : 'Добавить респондента'}
            footer={null}>
            <SurveyRespondentForm
                formAction={item.id ? putSurveyRespondent : postSurveyRespondent}
                groups={groups}
                item={item} />
        </Modal>;
    }
}

export default withAsyncActions({
    postSurveyRespondent: postSurveyRespondent
        .withSuccessHandler(({ close }) => {
            message.success('Респондент успешно добавлен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось добавить респондента'))
        .withOptions({ resetOnUnmount: true }),
    putSurveyRespondent: putSurveyRespondent
        .withSuccessHandler(({ close }) => {
            message.success('Респондент успешно изменен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось изменить респондента'))
        .withOptions({ resetOnUnmount: true })
})(SurveyRespondentModal);
