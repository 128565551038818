import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import Modal from './Modal';
import { putQuestionnary } from '../../actions/asyncActions';
import SurveyPageElementsForm from '../forms/survey/SurveyPageElementsForm';

class QuestionnaryPageElementsModal extends Component {
    render() {
        const { modal, params, putQuestionnary } = this.props;

        return <Modal
            {...modal}
            title='Редактировать элементы страницы'
            footer={null}>
            <SurveyPageElementsForm
                formAction={putQuestionnary}
                item={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    putQuestionnary: putQuestionnary
        .withSuccessHandler(({ close }) => {
            message.success('Элементы страницы успешно изменены');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось изменить элементы страницы'))
        .withOptions({ resetOnUnmount: true })
})(QuestionnaryPageElementsModal);
