import React, { Component, Fragment } from 'react';
import { Tabs, Spin, Button, message } from 'antd';
import { asyncConnect, toSuccess } from 'react-async-client';
import styled from 'styled-components';
import { takeEvery } from 'redux-saga/effects';
import { find, propEq, filter, path } from 'ramda';
import { CloseCircleOutlined, CheckCircleOutlined, EditOutlined } from '@ant-design/icons';

import Route from '../../Route';
import { getCompany, putCompanyEnabled, getUser } from '../../../actions/asyncActions';
import DetailToolbar from '../DetailToolbar';
import CompanyInfo from './CompanyInfo';
import { openCompanyModal } from '../../../actions/modalActions';
import { PUT_COMPANY, PATCH_COMPANY, POST_COMPANY_PAYMENT, PUT_COMPANY_PAYMENT_TYPE, PUT_COMPANY_ENABLED, PUT_COMPANY_SETTINGS } from '../../../constants/actionTypes';
import CompanyEmployees from './CompanyEmployees';
import CompanyTemplates from './CompanyTemplates';
import CompanySurveyQuestionnaires from './CompanySurveyQuestionnaires';
import CompanyCategories from './CompanyCategories';
import withErrorHandler from '../../hocs/withErrorHandler';
import { SENIOR_MANAGER, ADMIN } from '../../../constants/roles';
import CompanyDistribution from './CompanyDistribution';
import CompanyIntegralResultScale from './CompanyIntegralResultScale';
import CompanyMessageHistoryContractor from './CompanyMessageHistoryContractor';

const tabs = [
    { key: 'info', title: 'Информация о компании', component: CompanyInfo, route: 'info/details' },
    { key: 'employees', title: 'Сотрудники', component: CompanyEmployees },
    { key: 'template', title: 'Шаблоны', component: CompanyTemplates },
    { key: 'surveys', title: 'Опросники', component: CompanySurveyQuestionnaires, hide: (company, { isAdmin }) => isAdmin ? false : !path(['clientAllowQuestionnaries'], company.commonSettings) },
    { key: 'categories', title: 'Компетенции', component: CompanyCategories, hide: (company, { isAdmin }) => isAdmin ? false : !path(['clientAllowCategories'], company.commonSettings)},
    { key: 'distributions', title: 'Распределение вопросов', component: CompanyDistribution },
    { key: 'integral', title: 'Вид вывода', component: CompanyIntegralResultScale, hide: company => company.reportType !== 'integralResultScale' },
    { key: 'history', title: 'Служебная информация', component: CompanyMessageHistoryContractor, hide: (_, { isAdmin }) => !isAdmin }
];

export const getRoute = (id, key) => `/companies/${id}${key ? `/${key}` : ''}`;

const ToolbarTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const StyledTabs = styled(Tabs)`
    .ant-tabs-bar {
        margin: 0;
    }
`;

class Company extends Component {
    getTabs = () => {
        const { getCompany: { data }} = this.props;

        return filter(tab => tab.hide ? !tab.hide(data, this.props) : true, tabs);
    }

    onChange = key => {
        const { history, match: { params: { id }}} = this.props;
        const tab = find(propEq('key', key), this.getTabs());

        history.replace(getRoute(id, tab.route || key));
    }

    setEnabled = enabled => {
        const { putCompanyEnabled, match: { params: { id }}} = this.props;

        putCompanyEnabled.dispatch({ id, enabled });
    }

    edit = () => this.props.openCompanyModal(this.props.getCompany.data);

    renderTitle = () => {
        const { getCompany: { data: { name, enabled }, meta }, match: { params: { type } }, putCompanyEnabled, isAdmin } = this.props;

        return <ToolbarTitle>
            <h1>{ name }</h1>
            { (type === 'info') && meta.lastSucceedAt && isAdmin &&
                <Button.Group>
                    <Button
                        icon={enabled ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                        type={enabled ? 'danger' : 'default'}
                        onClick={() => this.setEnabled(!enabled)}
                        loading={putCompanyEnabled.meta.pending || meta.pending}>
                        <span className='hide-mobile'>{ enabled ? 'Заблокировать' : 'Разблокировать'}</span>
                    </Button>
                    <Button
                        icon={<EditOutlined />}
                        onClick={this.edit}
                        type='primary'
                        loading={putCompanyEnabled.meta.pending || meta.pending}>
                        <span className='hide-mobile'>Редактировать</span>
                    </Button>
                </Button.Group>
            }
        </ToolbarTitle>;
    }

    render() {
        const { getCompany: { data, meta }, match: { params: { id, type }}, user, isAdmin, isManager } = this.props;

        return <Fragment>
            <DetailToolbar title={this.renderTitle()} hideBack={isManager} backUrl='/companies' />
            { meta.pending && !meta.lastSucceedAt ?
                <Spin /> :
                <StyledTabs
                    className='wrap-bg'
                    onChange={this.onChange}
                    activeKey={type || ''}
                    animated={{ tabPane: false }}>
                    { this.getTabs().map(tab =>
                        <Tabs.TabPane key={tab.key} tab={tab.title}>
                            <Route path={getRoute(id, tab.key)} render={props =>
                                <tab.component
                                    {...props}
                                    company={data}
                                    companyMeta={meta}
                                    id={id}
                                    isAdmin={isAdmin}
                                    user={user} />
                            } />
                        </Tabs.TabPane>
                    )}
                </StyledTabs>
            }
        </Fragment>;
    }
}

const stateToProps = state => {
    const user = getUser.selectData(state);
    const { role } = user;

    return {
        isManager: role === SENIOR_MANAGER,
        isAdmin: role === ADMIN,
        user,
    };
};

export default asyncConnect({
    getCompany: getCompany
        .withPayload(({ match }) => match.params.id)
        .withSaga(function* (getProps) {
            yield takeEvery([
                toSuccess(PUT_COMPANY),
                toSuccess(PATCH_COMPANY),
                toSuccess(POST_COMPANY_PAYMENT),
                toSuccess(PUT_COMPANY_PAYMENT_TYPE),
                toSuccess(PUT_COMPANY_ENABLED),
                toSuccess(PUT_COMPANY_SETTINGS)
            ], function() {
                const { getCompany } = getProps();

                getCompany.refresh();
            })
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true }),
    putCompanyEnabled: putCompanyEnabled
        .withSuccessHandler(() => message.success('Состояние успешно изменено'))
        .withErrorHandler(() => message.error('Не удалось изменить состояние'))
        .withOptions({ resetOnUnmount: true })
}, stateToProps, { openCompanyModal })(withErrorHandler('getCompany')(Company));
