import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import Modal from './Modal';
import CompanyPaymentTypeForm from '../forms/CompanyPaymentTypeForm';
import { putCompanyPaymentType } from '../../actions/asyncActions';

class CompanyPaymentTypeModal extends Component {
    render() {
        const { params, modal, putCompanyPaymentType } = this.props;

        return <Modal
            {...modal}
            title='Тип оплаты'
            footer={null}>
            <CompanyPaymentTypeForm
                item={params}
                formAction={putCompanyPaymentType} />
        </Modal>;
    }
}

export default withAsyncActions({
    putCompanyPaymentType: putCompanyPaymentType
        .withSuccessHandler(({ close }) => {
            message.success('Тип оплаты был успешно изменен');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(CompanyPaymentTypeModal);
