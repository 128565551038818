export const DRIVER_TYPE = [
    { id: 'single', value: 'Single', name: 'Одиночный выбор' },
    { id: 'drag', value: 'Drag', name: 'Ранжирование' },
    { id: 'textarea', value: 'Textarea', name: 'Ввод текста' },
    { id: 'individual', value: 'Individual' },
    { id: 'matrix', value: 'Matrix' },
    { id: 'slider', value: 'Slider' },
    { id: 'select', value: 'Select' },
    { id: 'nps', value: 'eNPS' }
];

export const DRIVER_TYPES = [
    { id: 'single', value: 'Single', name: 'Одиночный выбор' },
    { id: 'textarea', value: 'Textarea', name: 'Ввод текста' },
    { id: 'drag', value: 'Drag', name: 'Ранжирование' },
    { id: 'select', value: 'Select', name: 'Выбор вопроса' },
];

export const DRIVER_OPTIONS = {
    slider: [
        { type: 'allowSkip', label: 'Разрешить пропуск', fieldType: 'checkbox' },
        { type: 'group', label: 'Группировать', fieldType: 'checkbox' },
        { type: 'digitLabel', label: 'Выводить значение', fieldType: 'checkbox' },
        { type: 'step', label: 'Шаг', fieldType: 'number' },
        { type: 'gradient', label: 'Градиент', fieldType: 'checkbox' },
        { type: 'dots', label: 'Показать границы шага', fieldType: 'checkbox' }
    ],
    select: [
        { type: 'multiple', label: 'Множественный выбор', fieldType: 'checkbox' },
        { type: 'group', label: 'Группировать', fieldType: 'checkbox' }
    ],
    drag: [
        { type: 'limitAnswers', label: 'Максимальное количество выбранных', fieldType: 'number' },
        { type: 'minAnswers', label: 'Минимальное количество выбранных', fieldType: 'number' },
        { type: 'disableSort', label: 'Отключить сортировку', fieldType: 'checkbox' }
    ],
    textarea: [
        { type: 'allowEmpty', label: 'Разрешить пустой ответ', fieldType: 'checkbox' }
    ]
};

export const SURVEY_RESPONSE_FORM_FIELD_TYPES = [
    { id: 'input', value: 'Строка' },
    { id: 'number', value: 'Число' },
    { id: 'select', value: 'Выпадающий список' },
    { id: 'radio', value: 'Выбор' },
    { id: 'checkbox', value: 'Чекбокс' }
];

export const SURVEY_LETTER_TEMPLATE_VARIABLES = [
    { var: 'FirstName', title: 'Имя' },
    { var: 'LastName', title: 'Фамилия' },
    { var: 'MiddleName', title: 'Отчество' },
    { var: 'Project', title: 'Название проекта' },
    { var: 'TestLink', title: 'Ссылка на прохождение опроса' },
];

