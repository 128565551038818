import { EditOutlined } from '@ant-design/icons';
import { Button, Spin, Tabs } from 'antd';
import React, { Component, Fragment } from 'react';
import { asyncConnect, toSuccess } from 'react-async-client';
import { Route } from 'react-router';
import styled from 'styled-components';

import { getQuestionnary } from '../../actions/asyncActions';
import DetailToolbar from './DetailToolbar';
import QuestionnaryDrivers from './QuestionnaryDrivers';
import QuestionnaryInfo from './QuestionnaryInfo';
import QuestionnaryElements from './QuestionnaryElements';
import QuestionnarySettings from './QuestionnarySettings';
import { openQuestionnaryModal } from '../../actions/modalActions';
import { PATCH_QUESTIONNARY, PUT_QUESTIONNARY } from '../../constants/actionTypes';
import { takeEvery } from 'redux-saga/effects';

const Wrapper = styled.div`
    background: #fff;
    border: 1px solid #e8e8e8;
    border-top: none;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    .ant-tabs-bar {
        margin: 0;
    }
`;

const ToolbarTitle = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
`;

const TABS = [
    { name: 'Информация', key: 'info', component: QuestionnaryInfo },
    { name: 'Элементы', key: 'elements', component: QuestionnaryElements },
    { name: 'Вопросы', key: 'drivers', component: QuestionnaryDrivers },
    { name: 'Настройки', key: 'settings', component: QuestionnarySettings }
];

class Questionnary extends Component {
    onChangeTab = key => {
        const { history, match: { params: { id } } } = this.props;

        history.replace(`/questionnaries/${id}/${key}`);
    }

    render() {
        const { getQuestionnary: { data, meta }, match: { params: { id, type } }, openQuestionnaryModal } = this.props;

        return <Fragment>
            <DetailToolbar title={
                <ToolbarTitle>
                    <h1>{ data.name }</h1>
                    { type === 'info' &&
                        <Button type='primary' icon={<EditOutlined />} loading={meta.pending} onClick={() => openQuestionnaryModal(data)}>
                            Редактировать
                        </Button>
                    }
                </ToolbarTitle>
            } />
            { meta.pending && !meta.lastSucceedAt ?
                <div><Spin /></div> :
                meta.lastSucceedAt ?
                    <Wrapper>
                        <Tabs onChange={this.onChangeTab} animated={false} activeKey={type}>
                            { TABS.map(tab =>
                                <Tabs.TabPane key={tab.key} tab={tab.name}>
                                    <Route path={`/questionnaries/${id}/${tab.key}`} render={props =>
                                        <tab.component {...props} data={data} meta={meta} getQuestionnary={this.props.getQuestionnary} />
                                    } />
                                </Tabs.TabPane>
                            )}
                        </Tabs>
                    </Wrapper> :
                    null
            }
        </Fragment>
    }
}

export default asyncConnect({
    getQuestionnary: getQuestionnary
        .withPayload(({ match }) => match.params.id)
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_QUESTIONNARY), toSuccess(PATCH_QUESTIONNARY)], () => {
                getProps().getQuestionnary.refresh();
            });
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
}, null, { openQuestionnaryModal })(Questionnary);

