import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { asyncConnect } from 'react-async-client';
import { prop } from 'ramda';
import { Form, Switch, Row, Col } from 'antd';
import * as yup from 'yup';

import Input from '../formComponents/Input';
import { getTestTemplates } from '../../../actions/asyncActions';
import Select from '../formComponents/Select';
import { openCompanyClientModal } from '../../../actions/modalActions';
import withFormWrapper from '../../hocs/withFormWrapper';
import SubmitButton from '../formComponents/SubmitButton';

class ProjectNameStep extends Component {
    componentDidMount() {
        this.props.getRef && this.props.getRef(() => this);
    }

    render() {
        const { setNext, submitBtn, item, allowSeveralTests, setAllowSeveralTests, testTemplate } = this.props;

        return <Fragment>
            <Row gutter={16}>
                <Col span={12}>
                    <Field
                        name='name'
                        component={Input}
                        label='Название теста' />
                </Col>
                { !item.id &&
                    <Col span={12}>
                        { testTemplate ?
                            <Field
                                name='testTemplate'
                                component={Select}
                                label='Готовый тест'
                                action={getTestTemplates}
                                filter={{ status: 'published' }}
                                namePath='name' /> :
                            <Form.Item wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} label='В проекте будет несколько тестов'>
                                <Switch checked={!!allowSeveralTests} onChange={setAllowSeveralTests} />
                            </Form.Item>
                        }
                    </Col>
                }
            </Row>
            { !item.id && !allowSeveralTests &&
                <Field
                    name='displayedName'
                    component={Input}
                    label={<span>Название для респондентов <small>(оставьте пустым чтобы использовать название теста)</small></span>}
                    hideOptional />
            }
            { submitBtn || <SubmitButton type='primary' onClick={() => setNext(true)}>Далее</SubmitButton> }
        </Fragment>;
    }
}

const validationSchema = ({ testTemplate, item }) => yup.object().shape({
    name: yup.string().required(),
    testTemplate: testTemplate && !item.id ? yup.string().required() : yup.string()
});

export default withFormWrapper(
    asyncConnect({}, null, { openCompanyClientModal })(ProjectNameStep),
    {
        validationSchema,
        mapPropsToValues: prop('item'),
        mapBeforeSubmit: (values, props) => ({
            ...values,
            ...(values.id ? {} : {
                allowSeveralTests: props.allowSeveralTests
            })
        })
    }
);
