export const COMMAND_TYPES = [
    { id: 'app:import:questions', value: 'Импорт вопросов', fields: ['clear', 'file', 'dry-run'], optionalFields: ['company'], fileType: 'xlsx', zip: true, multiFiles: true },
    { id: 'app:import:tests', value: 'Импорт тестов', fields: ['company', 'count-tests', 'shuffle-answers', 'file', 'rootCompetence', 'dry-run'], fileType: 'xlsx' },
    { id: 'app:import:respondents', value: 'Импорт респондентов', fields: ['company', 'file', 'rootCompetence', 'dry-run'], fileType: 'xlsx' },
    { id: 'app:import:recommendations', value: 'Импорт рекомендаций для компетенций', fields: ['file', 'company', 'dry-run'], fileType: 'xlsx' },
    { id: 'app:export:respondents:pdf', value: 'Выгрузка результатов прохождения тестов (pdf)', fields: ['company', 'file', 'rootCompetence', 'competence', 'with-page-numbers'], optionalFields: ['pdf'], fileType: 'xlsx' },
    { id: 'app:export:respondents:pdf:xlsx', value: 'Выгрузка результатов прохождения тестов из xlsx (pdf)', fields: ['file'], optionalFields: ['pdf'], fileType: 'xlsx' },
    { id: 'app:export:solution', value: 'Выгрузка статистики ответов на вопросы (xlsx)', fields: ['company', 'file', 'rootCompetence', 'competence', 'with-answers'], fileType: 'xlsx' },
    { id: 'app:export:respondents:table', value: 'Выгрузка сводного отчета прохождения тестов (xlsx)', fields: ['company', 'file', 'rootCompetence', 'competence', 'full-list'], fileType: 'xlsx' },
    { id: 'app:export:questions:statistic', value: 'Выгрузка статистики распределения вопросов (xlsx)', fields: ['company', 'rootCompetence'] },
    { id: 'app:export:solution:answer', value: 'Выгрузка ответов на открытые вопросы (xlsx)', fields: ['company'] },
    { id: 'app:import:solution:answer', value: 'Выставление оценок ответов на открытые вопросы (xlsx)', fields: ['company', 'file', 'dry-run'], fileType: 'xlsx' },
    { id: 'app:import:experts', value: 'Импорт экспертов', fields: ['company', 'file', 'dry-run'], fileType: 'xlsx' },
    { id: 'app:export:audits', value: 'Экспорт экспертной оценки', fields: ['company', 'date'] },
    { id: 'app:import:full-tests', value: 'Импорт тестов со всеми вопросами компетенции', fields: ['company', 'file', 'dry-run'], fileType: 'xlsx' },
];

export const COMMAND_LANGUAGES = [
    { id: 'ru', value: 'Русский' },
    { id: 'en', value: 'Английский' },
];

export const SENIOR_MANAGER_COMMAND_TYPES = [
    'app:import:questions',
    'app:import:tests',
    'app:import:respondents',
    'app:export:respondents:pdf',
    'app:export:respondents:pdf:xlsx',
    'app:import:experts',
    'app:export:audits',
    'app:import:full-tests',
];
