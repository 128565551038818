import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';

import ProjectTestsStep from '../../forms/project/ProjectTestsStep';
import { openTestModal } from '../../../actions/modalActions';

const Wrapper = styled.div`
    padding: 15px;
`;

class ProjectTestsTabButtonsComponent extends Component {
    addTest = () => {
        const { openTestModal, getCompanyProject: { data }} = this.props;

        openTestModal({
            project: data.id,
            company: data.company,
            type: data.type
        });
    }

    render() {
        return <Button
            icon={<PlusOutlined />}
            type='primary'
            onClick={this.addTest}>
            <span className='hide-mobile'>Добавить</span>
        </Button>;
    }
}

export const ProjectTestsTabButtons = connect(null, { openTestModal })(ProjectTestsTabButtonsComponent);

export default class ProjectTestsTab extends Component {
    render() {
        return <Wrapper className='wrap-bg'>
            <ProjectTestsStep
                item={this.props.project}
                testSuites={this.props.testSuites}
                submitBtn={<Fragment />}
                allowSettingsEdit
                hideAddBtn />
        </Wrapper>;
    }
}
