import React, { Component } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import styled from 'styled-components';

import withFieldWrapper from '../../hocs/withFieldWrapper';

const Wrapper = styled.div`
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    padding: 5px 10px;
    .mce-edit-focus {
        outline: none;
    }
    p {
        margin-bottom: 0;
    }
    .mce-content-body {
        line-height: 1.5;
        padding-top: 5px;
    }
`;

class InlineEditor extends Component {
    render() {
        const { input: { value }, onChange, disabled } = this.props;

        return <Wrapper>
            <Editor
                value={value}
                disabled={disabled}
                onEditorChange={onChange}
                inline
                init={{
                    plugins: 'link image code lists paste',
                    contextmenu: 'copy paste pastetext link',
                    language: 'ru',
                    menubar: false,
                    forced_root_block: false,
                    paste_data_images: false,
                    paste_as_text: true,
                    language_url: '/translations/ru.js',
                    toolbar: 'undo redo | bold italic underline | link image | removeformat',
                }}
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
            />
        </Wrapper>;
    }
}

export default withFieldWrapper(InlineEditor);
