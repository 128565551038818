import React from 'react';
import { Layout, Dropdown, Menu } from 'antd';
import { asyncConnect } from 'react-async-client';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { UserOutlined, LogoutOutlined, DownOutlined } from '@ant-design/icons';

import Sidebar from './Sidebar';
import { logout } from '../../../actions/appActions';
import Modals from '../../modals/Modals';
import LogoImg from '../../../assets/img/logo.svg';
import { postPda, getUser } from '../../../actions/asyncActions';
import Username from '../../user/table/Username';
import PdaForm from '../../forms/PdaForm';

const StyledLayout = styled(Layout)`
    height: 100%;
    min-height: 100vh;
    min-width: 767px;
    @media screen and (max-width: 896px) {
        overflow-y: hidden;
        max-height: 100vh;
    }

`;

const Wrapper = styled.div`
    margin: 20px;
    margin-left: 0;
    border-radius: 6px;
    .wrap-bg,.ant-table-wrapper{
        border-left: 1px solid #eaeaea;
        border-right: 1px solid #eaeaea;
        border-bottom: 1px solid #eaeaea;
        background: #fff;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        .ant-row{
            border: 0;
        }
    }
    .ant-tabs-bar{
        background: #fff;
        border-left: 1px solid #eaeaea;
        border-right: 1px solid #eaeaea;
    }
    @media (max-width: 1024px) {
        margin: 10px;
        margin-left: 0;
    }
    @media (max-width: 767px) {
        margin: 5px;
        margin-left: 0;
    }
`;

const Header = styled(Layout.Header)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 767px) {
        padding: 0 15px;
    }
`;

const HeaderButtons = styled.div`
    display: inline-block;
`;

const ProfileBtn = styled.span`
    color: hsla(0,0%,100%,.65);
    cursor: pointer;
    .anticon {
        margin-right: 8px;
        @media (max-width: 767px) {
            margin-right: 0;
        }
    }
    &:hover {
        color: #fff;
    }
    .user-name,.anticon-down {
        @media (max-width: 767px) {
            display: none!important;
        }
    }
`;

const Logo = styled.div`
    display: inline-block;
    background-image: url("${LogoImg}");
    background-repeat: no-repeat;
    width: 160px;
    height: 40px;
    margin-top: 25px;
    background-size: contain;
    @media (max-width: 767px) {
        background-size: auto 26px;
        width: 29px;
        margin-right: -7px;
        background-position-x: 0px;
        margin-top: 32px;
    }
`;

const PdaLayout = ({ logout, user, postPda }) =>
    <StyledLayout>
        <Header>
            <Link to='/'>
                <Logo />
            </Link>
            <HeaderButtons>
                <Dropdown trigger={['click']} overlay={
                    <Menu>
                        <Menu.Item onClick={logout}>
                            <LogoutOutlined /> Выйти
                        </Menu.Item>
                    </Menu>
                }>
                    <ProfileBtn>
                        <UserOutlined /><span className='user-name'><Username user={user} /></span> <DownOutlined />
                    </ProfileBtn>
                </Dropdown>
            </HeaderButtons>
        </Header>
        <Layout>
            <Sidebar />
            <Layout.Content>
                <Wrapper>
                    <PdaForm
                        formAction={postPda}
                    />
                </Wrapper>
            </Layout.Content>
        </Layout>
        <Modals />
    </StyledLayout>;

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default asyncConnect({
    postPda: postPda
        .withSuccessHandler(({ getUser }) => getUser.refresh())
        .withOptions({ resetOnUnmount: true })
}, stateToProps, { logout })(PdaLayout);
