import React, { Component } from 'react';

import Modal from './Modal';
import RespondentFormFieldForm from '../forms/RespondentFormFieldForm';

export default class RespondentFormFieldsModal extends Component {
    onSubmit = values => {
        this.props.params.onSubmit(values);
        this.props.close();
    }

    render() {
        const { modal, params: { field, fields }} = this.props;

        return <Modal
            {...modal}
            title={`${field.code ? 'Редактировать' : 'Добавить'} поле`}
            footer={null}>
            <RespondentFormFieldForm
                item={field}
                formAction={this.onSubmit}
                fields={fields} />
        </Modal>;
    }
}
