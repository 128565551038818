import React, { Component } from 'react';
import { Checkbox } from 'antd';
import { is } from 'ramda';

import withFieldWrapper from '../../hocs/withFieldWrapper';

class CheckboxComponent extends Component {
    onChange = e => {
        !this.props.disabled && this.props.onChange(e.target.checked);
    }

    render() {
        const { input: { value }, text, disabled } = this.props;

        return (
            <Checkbox
                checked={!!value}
                disabled={disabled}
                onChange={this.onChange}
            >
                { text ? (
                    is(Function, text) ? text(this.props) : text
                ) : null}
            </Checkbox>
        );
    }
}

export default withFieldWrapper(CheckboxComponent);
