import axios from 'axios';
import jszip from 'jszip';
import { forEach, mergeAll, is } from 'ramda';

import * as urls from '../../constants/urls';

const pdfRequestHandler = async (requestPromise) => {
    const task = await requestPromise;

    if (!('topic' in task)) {
        throw new Error('Unable to create task for generating respondent pdf');
    }

    return new Promise((resolve, reject) => {
        const eventSource = new EventSource(window._mercureHubUrl + '?topic=' + task.topic);

        eventSource.addEventListener('message', (event) => {
            try {
                const eventData = JSON.parse(event.data);
                if ('fileUrl' in eventData) {
                    eventSource.close();
                    return resolve(eventData);
                }

                reject('File url not found pdf generator response');
            } catch (e) {
                reject('Unable to parse pdf generator');
            } finally {
                eventSource.close();
            }
        });
    });
};

export const postLoginHandler = ({ payload }) => axios.post(urls.LOGIN.stringify(), payload);
export const getUserHandler = () => axios.get(urls.USER.stringify(), {
    params: {
        relations: ['company', 'company.surveyQuestionnaires']
    }
});
export const postPdaHandler = ({ payload }) => axios.post(urls.PDA.stringify(), payload);

export const postRefreshTokenHandler = ({ payload }) => axios.post(urls.REFRESH_TOKEN.stringify(), payload);

export const postPasswordRecoveryHandler = ({ payload }) => axios.post(urls.PASSWORD_RECOVERY.stringify(), payload);
export const postResetPasswordHandler = ({ payload: { id, ...data }}) => axios.post(urls.PASSWORD_RECOVERY.stringify({ id }), data);

export const getCategoriesHandler = ({ payload }) => axios.get(urls.CATEGORIES.stringify(), {
    params: {
        limit: 0,
        relations: ['parent', 'parent.parent'],
        ...payload
    }
});
export const postCategoryHandler = ({ payload }) => axios.post(urls.CATEGORIES.stringify(), payload);
export const putCategoryHandler = ({ payload: { id, ...data } }) => axios.put(urls.CATEGORIES.stringify({ id }), data);
export const patchCategoryHandler = ({ payload: { id, data } }) => axios.patch(urls.CATEGORIES.stringify({ id }), data);
export const deleteCategoryHandler = ({ payload }) => axios.delete(urls.CATEGORIES.stringify({ id: payload })).then(() => ({ id: payload }));

export const getQuestionsHandler = ({ payload }) => axios.get(urls.QUESTIONS.stringify(), {
    params: {
        relations: ['category'],
        ...payload
    }
});
export const deleteQuestionHandler = ({ payload }) => axios.all(payload.map(id => axios.delete(urls.QUESTIONS.stringify({ id }))));
export const putQuestionHandler = ({ payload: { id, updateRelatedTests, ...data } }) => axios.put(urls.QUESTIONS.stringify({ id }), data, { params: { updateRelatedTests } });
export const patchQuestionHandler = ({ payload: { items, data } }) => axios.all(items.map(id => axios.patch(urls.QUESTIONS.stringify({ id }), data)));
export const postQuestionHandler = ({ payload: { updateRelatedTests, ...data } }) => axios.post(urls.QUESTIONS.stringify(), data, { params: { updateRelatedTests } });
export const getQuestionHandler = ({ payload }) => axios.get(urls.QUESTIONS.stringify({ id: payload }), {
    params: {
        relations: ['category', 'category.parent', 'category.parent.parent']
    }
});

export const getAdminsHandler = ({ payload }) => axios.get(urls.ADMINS.stringify(), { params: payload });
export const postAdminHandler = ({ payload }) => axios.post(urls.ADMINS.stringify(), payload);
export const putAdminHandler = ({ payload: { id, ...data } }) => axios.put(urls.ADMINS.stringify({ id }), data);
export const patchAdminsHandler = ({ payload: { items, data } }) => axios.all(items.map(id => axios.patch(urls.ADMINS.stringify({ id }), data)));

export const getCompaniesHandler = ({ payload }) => axios.get(urls.COMPANIES.stringify(), { params: payload });
export const patchCompanyHandler = ({ payload: { id, data } }) => axios.patch(urls.COMPANIES.stringify({ id }), data);
export const patchCompaniesHandler = ({ payload: { items, data } }) => axios.all(items.map(id => axios.patch(urls.COMPANIES.stringify({ id }), data)));
export const postCompanyHandler = ({ payload }) => axios.post(urls.COMPANIES.stringify(), payload);
export const putCompanyHandler = ({ payload: { id, ...data } }) => axios.put(urls.COMPANIES.stringify({ id }), data);
export const putCompanyFavoriteHandler = ({ payload: { id, ...data } }) => axios.put(urls.COMPANIES_FAVORITE.stringify({ id }), data);
export const getCompanyHandler = ({ payload }) => axios.get(urls.COMPANIES.stringify({ id: payload }), {
    params: {
        relations: ['surveyQuestionnaires']
    }
});

export const getBankDetailsHandler = ({ payload }) => axios.get(urls.BANK_DETAILS.stringify({ company: payload }));
export const postBankDetailsHandler = ({ payload: { companyId, ...form } }) => axios.post(urls.BANK_DETAILS.stringify({ company: companyId }), form);

export const getCompanyClientsHandler = ({ payload: { company, ...params } }) => axios.get(urls.COMPANY_CLIENTS.stringify({ company }), { params });
export const postClientHandler = ({ payload }) => axios.post(urls.CLIENTS.stringify(), payload);
export const putClientHandler = ({ payload: { id, ...data } }) => axios.put(urls.CLIENTS.stringify({ id }), data);
export const patchClientsHandler = ({ payload: { items, data } }) => axios.all(items.map(id => axios.patch(urls.CLIENTS.stringify({ id }), data)));

export const getCompanyEmployeesHandler = ({ payload: { company, ...params } }) => axios.get(urls.COMPANY_EMPLOYEES.stringify({ company }), {
    params: {
        ...params,
        relations: ['businessUnit']
    }
});
export const deleteCompanyEmployeesHandler = ({ payload: { items, company } }) => axios.all(items.map(id => axios.delete(urls.COMPANY_EMPLOYEES.stringify({ id, company }))));
export const postCompanyEmployeeHandler = ({ payload }) => axios.post(urls.COMPANY_EMPLOYEES.stringify({ company: payload.company }), payload);
export const putCompanyEmploeeHandler = ({ payload }) => axios.put(urls.COMPANY_EMPLOYEES.stringify({ company: payload.company, id: payload.id }), payload);
export const getCompanyEmployeeHandler = ({ payload: { id, company } }) => axios.get(urls.COMPANY_EMPLOYEES.stringify({ id, company }), {
    params: {
        relations: ['businessUnit']
    }
});

export const getCompanyProjectsHandler = ({ payload: { company, ...params } }) => axios.get(urls.COMPANY_PROJECTS.stringify({ company }), { params });
export const patchCompanyProjectsHandler = ({ payload: { items, data } }) => axios.all(items.map(item => axios.patch(urls.COMPANY_PROJECTS.stringify({
    id: item.id,
    company: item.company
}), data)));

export const postCompanyProjectHandler = ({ payload: { displayedName, testTemplate, ...payload } }) =>
    axios.post(urls.COMPANY_PROJECTS.stringify({ company: payload.company }), payload).then(project =>
        axios.post(urls.COMPANY_TESTS.stringify({ project: project.id, company: payload.company }), {
            name: payload.name,
            project: project.id,
            company: payload.company,
            type: payload.type,
            displayedName,
            testTemplate
        }).then(() => project)
    );

export const putCompanyProjectHandler = ({ payload }) => axios.put(urls.COMPANY_PROJECTS.stringify({ company: payload.company, id: payload.id }), payload);
export const getCompanyProjectHandler = ({ payload: { id, company } }) => axios.get(urls.COMPANY_PROJECTS.stringify({ id, company }), {
    params: {
        relations: ['company', 'categories', 'categories.parent', 'categories.parent.parent', 'collaborators']
    }
});

export const getBusinessUnitsHandler = ({ payload }) => axios.get(urls.BUSINESS_UNITS.stringify({ id: payload }), {
    params: {
        q: { filterByParent: false },
        limit: 0
    }
});

export const getSettingsTemplatesHandler = ({ payload }) => axios.get(urls.SETTINGS_TEMPLATES.stringify(), {
    params: {
        limit: 0,
    }
});
export const postSettingsTemplateHandler = ({ payload }) => axios.post(urls.SETTINGS_TEMPLATES.stringify(), payload);
export const putSettingsTemplateHandler = ({ payload }) => axios.put(urls.SETTINGS_TEMPLATES.stringify({ id: payload.id }), payload);
export const deleteSettingsTemplateHandler = ({ payload: { id } }) => axios.delete(urls.SETTINGS_TEMPLATES.stringify({ id }));

export const getCompanyTemplatesHandler = ({ payload: { company, ...q } }) => axios.get(urls.COMPANY_TEMPLATES.stringify({ company }), {
    params: {
        limit: 0,
        q,
    }
});
export const postCompanyTemplateHandler = ({ payload }) => axios.post(urls.COMPANY_TEMPLATES.stringify({ company: payload.company }), payload);
export const putCompanyTemplateHandler = ({ payload }) => axios.put(urls.COMPANY_TEMPLATES.stringify({ company: payload.company, id: payload.id }), payload);
export const deleteCompanyTemplateHandler = ({ payload: { id, company } }) => axios.delete(urls.COMPANY_TEMPLATES.stringify({ id, company }));

export const getSearchHandler = ({ payload }) => axios.get(urls.SEARCH.stringify(), {
    params: {
        relations: ['respondents.company', 'respondents.testSuite', 'respondents.solution', 'respondents.project', 'employees.company', 'projects.company', 'employees.businessUnit'],
        q: {
            text: payload
        }
    }
});

export const postCompanyTestHandler = ({ payload }) => axios.post(urls.COMPANY_TESTS.stringify({ project: payload.project, company: payload.company }), payload);
export const putCompanyTestHandler = ({ payload }) => axios.put(urls.COMPANY_TESTS.stringify({ project: payload.project, company: payload.company, id: payload.id }), payload);
export const putCompanyTestsHandler = ({ payload: { company, project, items } }) => axios.all(
    items.map(item => axios.put(urls.COMPANY_TESTS.stringify({ company, project, id: item.id }), item))
);
export const deleteCompanyTestHandler = ({ payload: { project, company, id } }) => axios.delete(urls.COMPANY_TESTS.stringify({ project, company, id })).then(() => id);
export const getCompanyTestsHandler = ({ payload: { project, company } }) => axios.get(urls.COMPANY_TESTS.stringify({ project, company }), {
    params: {
        limit: 0
    }
});
export const patchCompanyTestHandler = ({ payload: { project, company, test, payload } }) => axios.patch(urls.COMPANY_TESTS.stringify({ project, company, id: test }), payload);

export const postCompanyRespondentHandler = ({ payload: { project, ...data } }) => data.addToEmployees ?
    axios.post(urls.COMPANY_EMPLOYEES.stringify({ company: data.company }), data).then(({ id }) =>
        axios.post(urls.COMPANY_RESPONDENTS.stringify({ company: data.company, test: data.testSuite, project }), { ...data, employee: id })
    ) :
    axios.post(urls.COMPANY_RESPONDENTS.stringify({ company: data.company, test: data.testSuite, project }), data);

export const postCompanyRespondentsHandler = ({ payload: { items }}) => axios.all(items.map(item => postCompanyRespondentHandler({ payload: item })));

export const putCompanyRespondentHandler = ({ payload }) => payload.employeeData ?
    axios.put(urls.COMPANY_EMPLOYEES.stringify({ company: payload.company, id: payload.employee }), payload.employeeData).then(() =>
        axios.put(urls.COMPANY_RESPONDENTS.stringify({ company: payload.company, test: payload.testSuite, project: payload.project, id: payload.id }), payload)
    ) :
    axios.put(urls.COMPANY_RESPONDENTS.stringify({ company: payload.company, test: payload.testSuite, project: payload.project, id: payload.id }), payload);

export const deleteCompanyRespondentHandler = ({ payload: { company, test, project, id } }) => axios.delete(urls.COMPANY_RESPONDENTS.stringify({
    company,
    test,
    project,
    id
})).then(() => ({ id }));
export const deleteCompanyRespondentsHandler = ({ payload: { company, test, project, items } }) => axios.all(items.map(item =>
    axios.delete(urls.COMPANY_RESPONDENTS.stringify(
        is(Object, item) ? { company: item.company, test: item.testSuite, project: item.project, id: item.id } : { company, test, project, id: item }))
));
export const getCompanyRespondentHandler = ({ payload: { company, test, project, id } }) => axios.get(urls.COMPANY_RESPONDENTS.stringify({ company, test, project, id }), {
    params: {
        relations: ['company', 'employee', 'employee.businessUnit', 'testSuite', 'solution', 'respondentStatistic.competencies.category', 'testSuite.questions.category', 'testSuite.questions.category.parent', 'testSuite.questions.category.parent.parent']
    }
});

export const getCompanyRespondentPdfHandler = ({ payload: { company, test, project, id } }) => pdfRequestHandler(axios.get(urls.COMPANY_RESPONDENTS.stringify({
    company,
    test,
    project,
    id
}) + '.pdf', {}));

export const getCompanyRespondentsImportHandler = ({ payload: { company, project, test, id, ...params } }) =>
    axios.get(urls.COMPANY_RESPONDENTS_IMPORT.stringify({ company, project, test, id }), { params });
export const postCompanyRespondentsImportHandler = ({ payload: { company, project, test, id, params } }) =>
    axios.post(urls.COMPANY_RESPONDENTS_IMPORT.stringify({ company, project, test, id }), null, { params });

export const patchCompanyRespondentHandler = ({ payload: { company, project, test, id, data } }) => axios.patch(urls.COMPANY_RESPONDENTS.stringify({
    company,
    project,
    test,
    id
}), data);
export const patchCompanyRespondentsHandler = ({ payload: { company, project, items } }) => axios.all(items.map(({ id, data, test, ...rest }) => axios.patch(urls.COMPANY_RESPONDENTS.stringify({
    company: company || rest.company,
    project: project || rest.project,
    test,
    id
}), data)));

export const postCompanyMessagesHandler = ({ payload: { company, project, ...data } }) => axios.post(urls.COMPANY_MESSAGES.stringify({ company, project }), data);

export const getComplexityHandler = () => axios.get(urls.COMPLEXITY.stringify());
export const putComplexityHandler = ({ payload: { items } }) => axios.all(items.map(item => axios.put(urls.COMPLEXITY.stringify({ id: item.id }), item)));

export const getRespondentsHandler = ({ payload }) => axios.get(urls.RESPONDENTS.stringify(), {
    params: {
        relations: ['employee', 'employee.businessUnit', 'testSuite', 'company', 'project'],
        ...payload
    }
});

export const getProjectAnalyticsPercentsHandler = ({ payload: { company, id, ...params } }) => axios.get(urls.PROJECT_ANALYTICS_PERCENTS.stringify({ company, id }), { params });
export const getProjectAnalyticsDistributionHandler = ({ payload: { company, id, ...params } }) => axios.get(urls.PROJECT_ANALYTICS_DISTRIBUTION.stringify({
    company,
    id
}), { params });
export const getProjectAnalyticsStatusHandler = ({ payload: { company, id, ...params } }) => axios.get(urls.PROJECT_ANALYTICS_STATUS.stringify({ company, id }), { params });

export const getCommandsHandler = ({ payload }) => axios.get(urls.COMMANDS.stringify(), {
    params: {
        relations: ['createdBy'],
        ...payload
    }
});

const uploadCommandFiles = async (files) => {
    if (files.length <= 0) {
        return {};
    }

    return mergeAll(await axios.all(files.map(f => axios.post(urls.COMMANDS_UPLOAD.stringify(), f))));
};

const buildCommandFilesFormData = async ({ file, pdf }) => {
    let files = [];
    if (!file) {
        return files;
    }

    if (file.length > 1) {
        const zip = new jszip();

        forEach(item => zip.file(item.name, item), file);

        await zip.generateAsync({ type: 'blob' }).then(blob => {
            const fd = new FormData();
            fd.append('path', blob);
            files.push(fd);
        });
    } else {
        const fd = new FormData();
        fd.append('path', file[0]);
        files.push(fd);
    }

    if (pdf) {
        const pdfFd = new FormData();
        pdfFd.append('pdfTail', pdf[0]);

        files.push(pdfFd);
    }

    return files;
}

export const postCommandHandler = async ({ payload: { ...data } }) => {
    data.inputFiles = {
        ...data.inputFiles,
        ...await uploadCommandFiles(await buildCommandFilesFormData(data)),
    };

    return axios.post(urls.COMMANDS.stringify(), data);
};

export const cancelCommandHandler = ({ payload }) => axios.put(urls.COMMAND_CANCEL.stringify({ id: payload.id }));

export const getCompanyRespondentStatHandler = ({ payload: { company, project, test, id } }) => axios.get(urls.COMPANY_RESPONDENTS_STAT.stringify({ company, project, test, id }));

export const getTestTemplatesHandler = ({ payload }) => axios.get(urls.TEST_TEMPLATES.stringify(), {
    params: {
        ...payload,
        relations: []
    }
});
export const getTestTemplateHandler = ({ payload }) => axios.get(urls.TEST_TEMPLATES.stringify({ id: payload }), {
    params: {
        relations: ['testGroup', 'categorySelections.category', 'categorySelections.category.parent', 'categorySelections.category.parent.parent', 'questions', 'questions.category', 'questions.category.parent', 'questions.category.parent.parent']
    }
});
export const deleteTestTemplatesHandler = ({ payload }) => axios.all(payload.map(id => axios.delete(urls.TEST_TEMPLATES.stringify({ id }))));
export const postTestTemplateHandler = ({ payload }) => axios.post(urls.TEST_TEMPLATES.stringify(), payload);
export const postTestTemplateWithIdHandler = ({ payload }) => axios.post(urls.TEST_TEMPLATES.stringify({ id: payload }));
export const patchTestTemplateHandler = ({ payload: { id, data } }) => axios.patch(urls.TEST_TEMPLATES.stringify({ id }), data);
export const deleteTestTemplateHandler = ({ payload }) => axios.delete(urls.TEST_TEMPLATES.stringify({ id: payload }));

export const putCompanyTestStatusHandler = ({ payload: { company, project, id, data }}) => axios.put(urls.COMPANY_TEST_STATUS.stringify({ company, project, id }), data);

export const getExpertsHandler = ({ payload }) => axios.get(urls.EXPERTS.stringify(), {
    params: {
        ...payload,
        relations: [],
    }
});
export const getExpertHandler = ({ payload }) => axios.get(
    urls.EXPERTS.stringify({ id: payload.id }),
);
export const getExpertAuditsHandler = ({ payload }) => axios.get(
    urls.EXPERTS_AUDITS.stringify({ id: payload.id }),
    {
        params: {
            relations: ['testTemplate']
        }
    }
);
export const postExpertHandler = ({ payload }) => axios.post(urls.EXPERTS.stringify(), payload);
export const putExpertHandler = ({ payload }) => axios.put(urls.EXPERTS.stringify({ id: payload.id }), payload);

export const putCompanyProjectTimeSettingsHandler = ({ payload: { company, project, items } }) => axios.put(urls.COMPANY_PROJECT_TIME_SETTINGS.stringify({
    company,
    project
}), items);

export const getTestGroupsHandler = () => axios.get(urls.TEST_GROUPS.stringify(), {
    params: {
        limit: 0
    }
});
export const postTestGroupHandler = ({ payload }) => axios.post(urls.TEST_GROUPS.stringify(), payload);
export const putTestGroupHandler = ({ payload }) => axios.put(urls.TEST_GROUPS.stringify({ id: payload.id }), payload);
export const deleteTestGroupHandler = ({ payload }) => axios.delete(urls.TEST_GROUPS.stringify({ id: payload }));

export const getTestTemplateChangesHandler = ({ payload: { id, ...params } }) => axios.get(urls.TEST_TEMPLATE_CHANGES.stringify({ id }), {
    params
});

export const getTestTemplateVersionsHandler = ({ payload }) => axios.get(urls.TEST_TEMPLATE_VERSIONS.stringify({ id: payload }));

export const getPaymentsHandler = ({ payload }) => axios.get(urls.PAYMENTS.stringify(), {
    params: {
        relations: ['company', 'user', 'bankDetails', 'ensiInvoice'],
        ...payload
    }
});
export const putPaymentHandler = ({ payload }) => axios.put(urls.PAYMENTS.stringify({ id: payload.id }), payload);
export const deletePaymentHandler = ({ payload }) => axios.delete(urls.PAYMENTS.stringify({ id: payload }));
export const putPaymentBankDetailsHandlers = ({ payload }) => axios.put(urls.PAYMENT_BANK_DETAILS.stringify({ id: payload.id }), payload.data);

export const getCompanyPaymentsHandler = ({ payload: { company, ...params } }) => axios.get(urls.COMPANY_PAYMENTS.stringify({ company }), { params });
export const postCompanyPaymentHandler = ({ payload }) => axios.post(urls.COMPANY_PAYMENTS.stringify({ company: payload.company }), payload);
export const deleteCompanyPaymentHandler = ({ payload: { company, id } }) => axios.delete(urls.COMPANY_PAYMENTS.stringify({ company, id }));

export const getCompanyAccountOperationsHandler = ({ payload: { id, ...params } }) => axios.get(urls.COMPANY_ACCOUNT_OPERATIONS.stringify({ id }), {
    params: {
        relations: ['user', 'testSuite', 'respondent'],
        ...params
    }
});

export const getRespondentsScreeningHandler = ({ payload }) => axios.get(urls.RESPONDENTS_SCREENING.stringify(), {
    params: {
        relations: ['solution'],
        ...payload
    }
});
export const deleteRespondentsScreeningHandler = ({ payload }) => axios.all(payload.map(id => axios.delete(urls.RESPONDENTS_SCREENING.stringify({ id }))));
export const postRespondentsScreeningHandler = ({ payload }) => axios.post(urls.RESPONDENTS_SCREENING.stringify(), payload);
export const getRespondentScreeningHandler = ({ payload }) => axios.get(urls.RESPONDENTS_SCREENING.stringify({ id: payload }), {
    params: {
        relations: ['employee', 'employee.businessUnit', 'testTemplate', 'solution', 'testTemplate.questions', 'testTemplate.questions.category', 'testTemplate.questions.category.parent', 'testTemplate.questions.category.parent.parent']
    }
});
export const putRespondentScreeningHandler = ({ payload }) => axios.put(urls.RESPONDENTS_SCREENING.stringify({ id: payload.id }), payload);
export const deleteRespondentScreeningHandler = ({ payload }) => axios.delete(urls.RESPONDENTS_SCREENING.stringify({ id: payload }));

export const postTestTemplateRespondentMessagesHandler = ({ payload }) => axios.post(urls.TEST_TEMPLATE_RESPONDENT_MESSAGES.stringify({ id: payload.testTemplate }), payload);

export const postTestTemplateAuditMessagesHandler = ({ payload }) => axios.post(urls.TEST_TEMPLATE_AUDIT_MESSAGES.stringify({ id: payload.testTemplate }), payload);

export const getTestTemplateAuditsHandler = ({ payload: { test, ...params } }) => axios.get(urls.TEST_TEMPLATE_AUDITS.stringify({ test }), {
    params: {
        relations: ['expert'],
        ...params
    }
});
export const getTestTemplateAuditHandler = ({ payload: { id, test, ...params } }) => axios.get(urls.TEST_TEMPLATE_AUDITS.stringify({ test, id }), {
    params: {
        relations: ['expert', 'employee.businessUnit', 'testTemplate', 'solution', 'questions', 'questions.category', 'questions.category.parent', 'questions.category.parent.parent'],
        ...params
    }
});
export const postTestTemplateAuditHandler = ({ payload }) => payload.expert ?
    axios.post(urls.TEST_TEMPLATE_AUDITS.stringify({ test: payload.testTemplate }), payload, {
        params: { relations: ['expert'] }
    }) :
    axios.post(urls.EXPERTS.stringify(), payload).then(data =>
        axios.post(urls.TEST_TEMPLATE_AUDITS.stringify({ test: payload.testTemplate }), { testTemplate: payload.testTemplate, expert: data.id }, {
            params: { relations: ['expert'] }
        })
    );
export const deleteTestTemplateAuditsHandler = ({ payload: { test, items } }) => axios.all(items.map(id => axios.delete(urls.TEST_TEMPLATE_AUDITS.stringify({ test, id }))));
export const deleteTestTemplateAuditHandler = ({ payload: { test, id } }) => axios.delete(urls.TEST_TEMPLATE_AUDITS.stringify({ test, id })).then(() => id);

export const getProjectStatCompetenciesHandler = ({ payload: { company, id, ...params } }) => axios.get(urls.PROJECT_STAT_COMPETENCIES.stringify({ company, id }), { params });
export const getProjectStatIntegralHandler = ({ payload: { company, id, ...params } }) => axios.get(urls.PROJECT_STAT_INTEGRAL.stringify({ company, id }), { params });

export const postCompanyTestRestartHandler = ({ payload: { company, project, respondent, id } }) => axios.post(urls.COMPANY_TEST_RESTART.stringify({
    company,
    project,
    respondent,
    id
}));
export const postCompanyTestsRestartHandler = ({ payload: { company, project, items } }) => axios.all(items.map(({ respondent, id, ...rest }) => axios.post(urls.COMPANY_TEST_RESTART.stringify({
    company: company || rest.company,
    project: project || rest.project,
    respondent,
    id
}))));
export const postCompanyTestFinishHandler = ({ payload: { company, project, respondent, id } }) => axios.post(urls.COMPANY_TEST_FINISH.stringify({
    company,
    project,
    respondent,
    id
}));

export const putCompanyPaymentTypeHandler = ({ payload: { id, paymentType } }) => axios.put(urls.COMPANY_PAYMENT_TYPE.stringify({ id }), { paymentType });
export const putCompanyEnabledHandler = ({ payload: { id, enabled } }) => axios.put(urls.COMPANY_ENABLED.stringify({ id }), { enabled });
export const putCompaniesEnabledHandler = ({ payload: { items, enabled } }) => axios.all(items.map(id => axios.put(urls.COMPANY_ENABLED.stringify({ id }), { enabled })));

export const getProjectsHandler = ({ payload }) => axios.get(urls.PROJECTS.stringify(), { params: payload });

export const getCompanyTestQuestionsHandler = ({ payload: { company, project, id }}) =>
    axios.get(urls.COMPANY_TEST_QUESTIONS.stringify({ company, project, id }), {
        params: {
            relations: ['questions.category'],
        }
    });
export const putCompanyTestQuestionsHandler = ({ payload: { company, project, id, data }}) => axios.put(urls.COMPANY_TEST_QUESTIONS.stringify({ company, project, id }), data);
export const patchCompanyTestQuestionsHandler = ({ payload: { company, project, id, data }}) => axios.patch(urls.COMPANY_TEST_QUESTIONS.stringify({ company, project, id }), data);

export const getDistributionsHandler = ({ payload }) => axios.get(urls.DISTRIBUTIONS.stringify(), { params: payload });
export const getDistributionHandler = ({ payload }) => axios.get(urls.DISTRIBUTIONS.stringify({ id: payload }));
export const postDistributionHandler = ({ payload }) => axios.post(urls.DISTRIBUTIONS.stringify(), payload);
export const putDistributionHandler = ({ payload }) => axios.put(urls.DISTRIBUTIONS.stringify({ id: payload.id }), payload);
export const deleteDistributionHandler = ({ payload }) => axios.delete(urls.DISTRIBUTIONS.stringify({ id: payload }));
export const getDefaultDistributionHandler = () => axios.get(urls.DEFAULT_DISTRIBUTION.stringify());

export const getCategoryDistributionHandler = ({ payload }) => axios.get(urls.CATEGORY_DISTRIBUTION.stringify({ id: payload }));
export const getCompanyCategoryDistributionHandler = ({ payload }) => axios.get(urls.CATEGORY_DISTRIBUTION.stringify({ id: payload.category }), {
    params: { q: payload, },
});

export const putCategoryRecommendationsHandler = ({ payload: { id, data }}) => axios.put(urls.CATEGORY_RECOMMENDATIONS.stringify({ id }), data);
export const getCategoryRecommendationsHandler = ({ payload }) => axios.get(urls.CATEGORY_RECOMMENDATIONS.stringify({ id: payload.category }), {
    params: payload.params
});

const ensiContractsHandler = async (requestPromise) => {
    const task = await requestPromise;

    if (!('topic' in task)) {
        throw new Error('Unable to create task for fetch ensi contracts');
    }

    return new Promise((resolve, reject) => {
        const eventSource = new EventSource(window._mercureHubUrl + '?topic=' + task.topic);

        eventSource.addEventListener('message', (event) => {
            try {
                const eventData = JSON.parse(event.data);

                eventSource.close();
                return resolve(eventData);
            } catch (e) {
                reject('Unable to parse data');
            } finally {
                eventSource.close();
            }
        });
    });
};

export const postEnsiContractsHandler = ({ payload: { company, ...data }}) => ensiContractsHandler(axios.post(urls.ENSI_CONTRACTS.stringify({ company }), data));

export const getSettingsHandler = () => axios.get(urls.SETTINGS.stringify());
export const putSettingsHandler = ({ payload }) => axios.put(urls.SETTINGS.stringify(), payload);

export const getAcceptorsHandler = ({ payload }) => axios.get(urls.ACCEPTORS.stringify(), {
    params: payload
});
export const postAcceptorHandler = ({ payload }) => axios.post(urls.ACCEPTORS.stringify(), payload);
export const putAcceptorHandler = ({ payload }) => axios.put(urls.ACCEPTORS.stringify({ id: payload.id }), payload);
export const deleteAcceptorHandler = ({ payload }) => axios.delete(urls.ACCEPTORS.stringify({ id: payload }));

export const getMessageHistoryContractorHandler = ({ payload }) => axios.get(urls.MESSAGE_HISTORY_CONTRACTOR.stringify(), {
    params: {
        ...payload
    }
});
export const getMessageHistoryPaymentHandler = ({ payload }) => axios.get(urls.MESSAGE_HISTORY_PAYMENT.stringify(), {
    params: {
        ...payload
    }
});

export const getQuestionnariesHandler = ({ payload }) => axios.get(urls.QUESTIONNARIES.stringify(), {
    params: {
        ...payload,
        relations: ['availableReports'],
    }
});
export const getQuestionnariesReportTypesHandler = ({ payload }) => axios.get(urls.QUESTIONNARIES_REPORT_TYPES.stringify(), {
    params: {
        ...payload
    }
});
export const postQuestionnaryHandler = ({ payload }) => axios.post(urls.QUESTIONNARIES.stringify(), payload);
export const getQuestionnaryHandler = ({ payload }) => axios.get(urls.QUESTIONNARIES.stringify({ id: payload }), {
    params: {
        relations: []
    }
});
export const putQuestionnaryHandler = ({ payload }) => axios.put(urls.QUESTIONNARIES.stringify({ id: payload.id }), payload);
export const deleteQuestionnaryHandler = ({ payload }) => axios.all(payload.map(id =>
    axios.delete(urls.QUESTIONNARIES.stringify({ id }))
));
export const patchQuestionnaryHandler = ({ payload: { id, data } }) => axios.patch(urls.QUESTIONNARIES.stringify({ id }), data);

export const getSurveysHandler = ({ payload }) => axios.get(urls.SURVEYS.stringify(), {
    params: {
        ...payload,
        relations: ['company', 'questionnaire', 'availableReports']
    }
});
export const getCompanySurveysHandler = ({ payload: { company, ...params } }) => axios.get(urls.COMPANY_PROJECTS.stringify({ company }), { params });
export const patchCompanySurveysHandler = ({ payload: { items, data } }) => axios.all(items.map(item => axios.patch(urls.COMPANY_PROJECTS.stringify({
    id: item.id,
    company: item.company
}), data)));

export const postSurveyHandler = ({ payload }) => axios.post(urls.SURVEYS.stringify(), payload);
export const putSurveyHandler = ({ payload }) => axios.put(urls.SURVEYS.stringify({ id: payload.id }), payload);
export const patchSurveyHandler = ({ payload }) => axios.patch(urls.SURVEYS.stringify({ id: payload.id }), payload.data);
export const deleteSurveyHandler = ({ payload }) => axios.delete(urls.SURVEYS.stringify({ id: payload }));
export const getSurveyHandler = ({ payload: { id } }) => axios.get(urls.SURVEYS.stringify({ id }), {
    params: {
        relations: ['company', 'questionnaire']
    }
});
export const getSurveyRespondentsHandler = ({ payload }) => axios.get(urls.SURVEY_RESPONDENT.stringify(), {
    params: payload
});
export const getSurveyRespondentHandler = ({ payload }) => axios.get(urls.SURVEY_RESPONDENT.stringify({ id: payload }), {
    params: {
        relations: ['company', 'survey']
    }
});
export const getSurveyRespondentCustomerFocusHandler = ({ payload }) => axios.get(urls.SURVEY_RESPONDENT_CUSTOMER_FOCUS.stringify(payload));
export const postSurveyRespondentHandler = ({ payload }) => payload.addToEmployees ?
    axios.post(urls.COMPANY_EMPLOYEES.stringify({ company: payload.company }), payload).then(({ id }) =>
        axios.post(urls.SURVEY_RESPONDENT.stringify(), { ...payload, employee: id })
    ) :
    axios.post(urls.SURVEY_RESPONDENT.stringify(), payload);

export const postSurveyRespondentsHandler = ({ payload: { items }}) => axios.all(items.map(item => postSurveyRespondentHandler({ payload: item })));
export const patchSurveyRespondentHandler = ({ payload: { id, data } }) => axios.patch(urls.SURVEY_RESPONDENT.stringify({ id }), data);
export const patchSurveyRespondentsHandler = ({ payload: { items } }) => axios.all(items.map(({ id, data }) => axios.patch(urls.SURVEY_RESPONDENT.stringify({ id }), data)));
export const putSurveyRespondentHandler = ({ payload }) => axios.put(urls.SURVEY_RESPONDENT.stringify({ id: payload.id }), payload);
export const deleteSurveyRespondentHandler = ({ payload }) => axios.delete(urls.SURVEY_RESPONDENT.stringify({ id: payload }));
export const deleteSurveyRespondentsHandler = ({ payload: { survey, items } }) => axios.all(items.map(item =>
    axios.delete(urls.SURVEY_RESPONDENT.stringify(
        is(Object, item) ? { survey: item.survey, id: item.id } : { survey, id: item }))
));
export const postSurveyCompanyMessagesHandler = ({ payload }) => axios.post(urls.SURVEY_COMPANY_MESSAGES.stringify(), payload);
export const getSurveyRespondentDriverPlusHandler = ({ payload }) => axios.get(urls.SURVEY_RESPONDENT_DRIVER_PLUS.stringify(payload));
export const getSurveyRespondentDriverPlusIpsativeHandler = ({ payload }) => axios.get(urls.SURVEY_RESPONDENT_DRIVER_PLUS_IPSATIVE.stringify(payload));

export const putCompanySettingsHandler = ({ payload }) => axios.put(urls.COMPANY_SETTINGS.stringify({ id: payload.id }), payload.data);

export const deleteProjectHandler = ({ payload }) => axios.delete(urls.PROJECTS.stringify({ id: payload }));
export const deleteProjectsHandler = ({ payload }) => axios.all(payload.map(id =>
    axios.delete(urls.PROJECTS.stringify({ id }))
));
export const deleteCompanyProjectHandler = ({ payload }) => axios.delete(urls.PROJECTS.stringify({ id: payload.id, company: payload.company }));
export const deleteCompanyProjectsHandler = ({ payload: { company, items } }) => axios.all(items.map(id =>
    axios.delete(urls.PROJECTS.stringify({ company, id }))
));

export const getRespondentReportHandler = ({ payload }) => axios.get(urls.RESPONDENT_REPORT.stringify({ id: payload }));
