import React, { Component } from 'react';
import styled from 'styled-components';
import { List, Alert, Button, Tooltip, Popconfirm, message, Tabs, Select, Checkbox, Row, Col, Menu, Dropdown } from 'antd';
import { withStateHandlers } from 'recompose';
import { asyncConnect, toSuccess } from 'react-async-client';
import { all, append, contains, remove, find, findIndex, includes, propEq, pathOr, any, filter, pick, path, prop, without } from 'ramda';
import { takeEvery } from 'redux-saga/effects';
import { Field } from 'react-final-form';
import { UserOutlined, MailOutlined, PhoneOutlined, PlusOutlined, DeleteOutlined, DownOutlined} from '@ant-design/icons';

import Modal from './Modal';
import { getUser, getCompanyEmployees, deleteCompanyRespondent, postCompanyRespondent, postCompanyRespondents, getBusinessUnits } from '../../actions/asyncActions';
import { PUT_COMPANY_RESPONDENT, POST_COMPANY_RESPONDENT } from '../../constants/actionTypes';
import { ADMIN_MODERATOR } from '../../constants/roles';
import InputSearch from '../forms/formComponents/InputSearch';
import CompanyRespondentForm from '../forms/CompanyRespondentForm';
import withFormWrapper from '../hocs/withFormWrapper';
import { getBusinessUnitsOptions } from '../../utils/companies';

const ListHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .ant-form-item {
        margin: 0;
        width: 70%;
    }
    .dropdown-link {
        color: #F54D2E;
        .dropdown-link-text {
            display: inline-block;
            border-bottom: 1px dashed #F54D2E;
        }
    }
`;
const TabsHeader = styled.div`
    padding: 12px 15px 10px;
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;

    form h3:first-child {
        margin: -16px -10px 15px;
    }
    form h3 {
        margin-left: -10px;
        margin-right: -10px;
    }
`;

const Lists = styled.div`
    margin: 15px 0;
    display: flex;
`;

const ListWrapper = styled.div`
    width: 50%;
    margin-right: ${({ left }) => left ? 5 : 0}px;
    margin-left: ${({ left }) => left ? 0 : 5}px;
    .ant-list-header {
        font-size: 1rem;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
    }
    .ant-list .ant-list-header{
        padding: 10px 15px;
    }
`;

const TabsWrapper = styled(ListWrapper)`
    border: 1px solid #d9d9d9;
    border-radius: 0px;
    .ant-list-header {
        background: none;
        .ant-select {
            width: 100%;
            .ant-select-selection-selected-value > div {
                padding-left: 0;
            }
        }
    }
    .ant-list.ant-list-split .ant-list-header {
        padding-top: 0;
    }
    .ant-list {
        border: none;
    }
    .ant-tabs-tabpane form h3:first-child {
        margin: 2px 0px 15px;
    }
    .ant-tabs-tabpane form {
        padding: 10px 15px;
    }
    .ant-tabs-tabpane form h3 {
        margin: 5px 0 15px;
        padding: 0px;
        background-color: transparent;
    }
    .table-footer {
        padding: 15px;
        border: 1px solid #f0f0f0;
        border-top: 0;
        .ant-btn {
            margin-right: 6px;
        }
    }
    .ant-btn-icon-only {
        line-height: 1;
    }
    form .ant-form-item,
    button[type=submit] {
        margin: 0 12px 12px;
    }
    .ant-form {
        .ant-form-item {
            margin-left: 0;
        }
        .ant-form-item-label {
            text-align: left;
            small {
                display: block;
            }
            label {
                white-space: break-spaces;
                height: auto
            }
        }
        .ant-btn.ant-btn-primary {
            margin-left: 0;
        }
    }
`;

const ListItem = styled(List.Item)`
    justify-content: space-between;
`;

const TestSelect = styled.div`
    .ant-select {
        width: 100%;
        margin-top: 15px;
    }
`;

const Filters = styled.div`
    background: #fff;
    margin: -15px;
    padding: 15px;
    margin-top: 0px;
    border-bottom: 1px solid #f0f0f0;
    .ant-form-item {
        margin-bottom: 0;
    }
`;

class AddRespondentModal extends Component {
    deleteRespondent = ({ id, project, company, testSuite }) => {
        this.props.deleteCompanyRespondent.dispatch({
            id,
            project,
            company,
            test: testSuite
        });
    }

    addRespondent = employee => {
        const { params: { item, auditEnabled }, postCompanyRespondent, test } = this.props;

        postCompanyRespondent.dispatch({
            ...pick(['firstName', 'lastName', 'middleName', 'email', 'phone', 'company'], employee),
            employee: employee.id,
            project: item.id,
            testSuite: test,
            auditEnabled,
            status: 'created'
        });
    }

    onCheck = id => this.props.setSelected(includes(id, this.props.selected) ? without([id], this.props.selected) : append(id, this.props.selected));

    checkAll = items => {
        this.props.setSelected(this.props.selected.length === items.length ? [] : items.map(prop('id')));
    }

    addSelected = () => {
        const { params: { item, auditEnabled }, test, postCompanyRespondents, getCompanyEmployees, selected } = this.props;

        postCompanyRespondents.dispatch({
            items: selected.map(id => {
                const respondent = find(propEq('id', id), pathOr([], ['data', 'items'], getCompanyEmployees));

                return {
                    ...pick(['firstName', 'lastName', 'middleName', 'email', 'phone', 'company'], respondent),
                    employee: respondent.id,
                    project: item.id,
                    testSuite: test,
                    auditEnabled,
                    status: 'created'
                };
            })
        });
    }

    onChangeBusinessUnit = id => {
        this.props.setBusinessUnit(id);
    }

    renderRow = (item, respondent) => {
        const businessUnit = pathOr(path(['employee', '_embedded', 'businessUnit', 'name'], item._embedded), ['businessUnit', 'name'], item._embedded);

        return <ListItem>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                { !respondent &&
                    <Checkbox style={{ marginRight: 20 }} checked={includes(item.id, this.props.selected)} onChange={() => this.onCheck(item.id)} />
                }
                <div>
                    <div style={{ color: '#000' }}>
                        { businessUnit &&
                            <Tooltip title={businessUnit}>
                                <UserOutlined style={{ marginRight: 5 }} />
                            </Tooltip>
                        }
                        { item.lastName } { item.firstName } { item.middleName || '' }
                    </div>
                    <div><MailOutlined /> { item.email }</div>
                    { item.phone && <div><PhoneOutlined /> { item.phone }</div> }
                </div>
            </div>
            { respondent ?
                <Popconfirm
                    title='Вы уверены, что хотите удалить респондента?'
                    okText='Да'
                    cancelText='Нет'
                    onConfirm={() => this.deleteRespondent(item)}>
                    <Button icon={<DeleteOutlined />} type='danger' />
                </Popconfirm> :
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => this.addRespondent(item)} />
            }
        </ListItem>;
    }

    onSubmitSuccess = () => {
        this.form.reset();
    }

    render() {
        const {
            modal,
            params: { item, auditEnabled, tests },
            respondents,
            postCompanyRespondent,
            getCompanyEmployees,
            isAdmin,
            test,
            fromRespondents,
            setFromRespondents,
            selected,
            setName,
            setTest
        } = this.props;
        const employees = filter(item => !any(propEq('employee', item.id), respondents), pathOr([], ['data', 'items'], getCompanyEmployees));
        const unselectedRespondents = pathOr([], ['data', 'items'], getCompanyEmployees);
        const unselectedItems = fromRespondents ? unselectedRespondents : [];
        const menu = <Menu>
            <Menu.Item key='respondents' onClick={() => setFromRespondents(true)}>из базы сотрудников</Menu.Item>
            <Menu.Item key='new' onClick={() => setFromRespondents(false)}>нового респондента</Menu.Item>
        </Menu>;

        return <Modal
            {...modal}
            title={auditEnabled ? 'Добавить эксперта' : 'Добавить респондента'}
            footer={null}>
            <Alert type='info' message={`На этом шаге определите респондентов, участвующих в процедуре тестирования. Это могут быть кандидаты и сотрудники компании. Вы также может выбрать сотрудников из ранее внесенного списка сотрудников, а также можете добавить респондентов в список сотрудников. Для этого вам потребуется создать должность в разделе ${isAdmin ? 'Компании' : 'Компания'}.`} />
            { tests.length > 1 &&
                <TestSelect>
                    <Select
                        placeholder='Тест'
                        value={test}
                        onChange={setTest}>
                        { tests.map(test =>
                            <Select.Option key={test.id} value={test.id}>{ test.name }</Select.Option>
                        )}
                    </Select>
                </TestSelect>
            }
            <Lists>
                <ListWrapper left>
                    <List
                        header={<ListHeader>Респонденты</ListHeader>}
                        dataSource={respondents}
                        renderItem={item => this.renderRow(item, true)}
                        bordered />
                </ListWrapper>
                <TabsWrapper>
                    <TabsHeader>
                        <ListHeader>
                            <div>
                                { fromRespondents &&
                                    <Checkbox style={{ marginRight: 8 }} checked={fromRespondents && all(({ id }) => includes(id, selected), unselectedItems)} onChange={() => this.checkAll(unselectedItems)} />
                                }
                                <strong>Добавить <Dropdown overlay={menu} trigger={['click']}>
                                        {/* eslint-disable-next-line */}
                                        <a className='dropdown-link' onClick={e => e.preventDefault()}>
                                            <span className='dropdown-link-text'>{ fromRespondents ? 'из базы сотрудников' : 'нового респондента' }</span> <DownOutlined />
                                        </a>
                                    </Dropdown>
                                </strong>
                            </div>
                        </ListHeader>
                    </TabsHeader>
                    <Tabs renderTabBar={() => null} activeKey={fromRespondents ? 'employees' : 'new'}>
                        <Tabs.TabPane tab='Новый респондент' key='new'>
                            <CompanyRespondentForm
                                getRef={node => this.form = node}
                                formAction={postCompanyRespondent}
                                onSubmitSuccess={this.onSubmitSuccess}
                                testSuite={test}
                                item={{
                                    company: item.company,
                                    project: item.id,
                                    status: 'created',
                                    auditEnabled,
                                    respondentFormFields: item.respondentFormFields
                                }}
                                showTestsField={false}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab='Сотрудники' key='employees'>
                            <List
                                header={
                                    <Filters>
                                        <Row gutter={6}>
                                            <Col xs={24} sm={12}>
                                                <Field
                                                    name='name'
                                                    component={InputSearch}
                                                    placeholder='Поиск'
                                                    onChange={setName} />
                                            </Col>
                                            <Col xs={24} sm={12}>
                                                <Field
                                                    name='businessUnit'
                                                    component={Select}
                                                    placeholder='Должность'
                                                    options={getBusinessUnitsOptions(pathOr([], ['data', 'items'], this.props.getBusinessUnits))}
                                                    onChange={this.onChangeBusinessUnit}
                                                    allowClear />
                                            </Col>
                                        </Row>
                                    </Filters>
                                }
                                dataSource={employees}
                                renderItem={item => this.renderRow(item)}
                                loading={getCompanyEmployees.meta.pending}
                                bordered />
                            <div className='table-footer'>
                                <Button type='primary' disabled={!selected.length} onClick={() => this.addSelected(test)}>Добавить выбранных ({ selected.length })</Button>
                                {/* <Button>Добавить всех { fromRespondents ? 'респондентов' : 'сотрудников' } ({ unselectedItems.length })</Button> */}
                            </div>
                        </Tabs.TabPane>
                    </Tabs>
                </TabsWrapper>
            </Lists>
        </Modal>;
    }
}

const stateToProps = state => ({
    isAdmin: contains(getUser.selectData(state).role, ADMIN_MODERATOR),
});

export default withStateHandlers(({ params: { item, tests }}) => ({
    businessUnit: undefined,
    name: null,
    selected: [],
    respondents: [],
    test: path([0, 'id'], tests),
    fromRespondents: true,
}), {
    setBusinessUnit: () => businessUnit => ({ businessUnit }),
    addRespondent: ({ respondents }) => respondent => ({
        respondents: append(respondent, respondents)
    }),
    setRespondents: () => respondents => ({ respondents }),
    setName: () => name => ({ name, selected: [] }),
    setSelected: () => selected => ({ selected }),
    removeRespondent: ({ respondents }) => id => ({
        respondents: remove(findIndex(propEq('id', id), respondents), 1, respondents)
    }),
    setFromRespondents: () => fromRespondents => ({ fromRespondents, name: null, project: null, businessUnit: null, selected: [] }),
    setTest: () => test => ({ test })
})(asyncConnect({
    getCompanyEmployees: getCompanyEmployees
        .withPayload(({ params: { item: { company }}, businessUnit, name }) => ({
            company,
            limit: 0,
            q: {
                businessUnit,
                text: name
            }
        }))
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(POST_COMPANY_RESPONDENT), toSuccess(PUT_COMPANY_RESPONDENT)], function(action) {
                const { getCompanyEmployees } = getProps();

                if (action.payload.employee) {
                    getCompanyEmployees.refresh();
                }
            })
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true }),
    deleteCompanyRespondent: deleteCompanyRespondent
        .withSuccessHandler(({ removeRespondent, deleteCompanyRespondent: { data: { id } }}) => {
            removeRespondent(id);
            message.success('Респондент успешно удален');
        })
        .withErrorHandler(() => message.error('Не удалось удалить респондента'))
        .withOptions({ resetOnUnmount: true }),
    postCompanyRespondent: postCompanyRespondent
        .withSuccessHandler(({ postCompanyRespondent: { data }, addRespondent }) => {
            addRespondent(data);
        })
        .withOptions({ resetOnUnmount: true }),
    postCompanyRespondents: postCompanyRespondents
        .withSuccessHandler(({ postCompanyRespondents: { data }, setRespondents, respondents }) => {
            setRespondents(respondents.concat(data));
        })
        .withOptions({ resetOnUnmount: true }),
    getBusinessUnits: getBusinessUnits
        .withPayload(({ params: { item }}) => item.company)
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
}, stateToProps)(withFormWrapper(AddRespondentModal)));
