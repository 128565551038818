import React, { Component, Fragment } from 'react';
import { Button, Popconfirm, message } from 'antd';
import { withState } from 'recompose';
import { asyncConnect } from 'react-async-client';
import { Link } from 'react-router-dom';
import { PhoneOutlined, MailOutlined, DeleteOutlined, PlusOutlined, ImportOutlined } from '@ant-design/icons';

import TableList from '../table/TableList';
import { getCompanyEmployees, deleteCompanyEmployees } from '../../../actions/asyncActions';
import { openCompanyEmployeeModal, openAddClientToProjectModal, openEmployeesImportModal } from '../../../actions/modalActions';
import CompanyEmployeesFilter from '../../forms/filters/CompanyEmployeesFilter';
import { DELETE_COMPANY_EMPLOYEES, POST_COMPANY_EMPLOYEE } from '../../../constants/actionTypes';

class CompanyEmployees extends Component {
    getColumns = () => {
        return [
            {
                title: 'ФИО',
                dataIndex: 'lastName',
                key: 'lastName',
                sorter: true,
                render: (lastName, item) => <Link to={`/companies/${item.company}/employees/${item.id}`}>
                    { `${lastName} ${item.firstName} ${item.middleName || ''}` }
                </Link>
            },
            {
                title: 'Должность',
                dataIndex: 'position',
                key: 'position'
            },
            {
                title: 'Контакты',
                dataIndex: 'email',
                key: 'email',
                render: (email, { phone }) =>
                    <Fragment>
                        <div><MailOutlined /> { email }</div>
                        { phone && <div><PhoneOutlined /> { phone }</div> }
                    </Fragment>
            }
        ];
    }

    delete = () => {
        const { id, deleteCompanyEmployees, selected } = this.props;

        deleteCompanyEmployees.dispatch({
            items: selected,
            company: id
        });
    }

    renderButtons = () => {
        const { selected, openCompanyEmployeeModal, company, openEmployeesImportModal } = this.props;

        return <Button.Group>
            {/* <Button
                icon={<FolderAddOutlined />}
                disabled={!selected.length}
                onClick={() => openAddClientToProjectModal({ company: company.id, items: selected })}>
                Добавить в проект
            </Button> */}
            { selected.length ?
                <Popconfirm
                    title={`Вы уверены, что хотите удалить ${selected.length > 1 ? 'выделенных сотрудников' : 'выделенного сотрудника'}?`}
                    onConfirm={this.delete}
                    okText='Да'
                    cancelText='Нет'>
                    <Button icon={<DeleteOutlined />} type='danger'>
                        Удалить
                    </Button>
                </Popconfirm> :
                <Button icon={<DeleteOutlined />} type='danger' disabled>
                    Удалить
                </Button>
            }
            <Button type='primary' icon={<PlusOutlined />} onClick={() => openCompanyEmployeeModal({ company: company.id })}>
                <span className='hide-mobile'>Добавить</span>
            </Button>
            <Button icon={<ImportOutlined />} onClick={() => openEmployeesImportModal({ company: company.id })}>
                <span className='hide-mobile'>Импорт</span>
            </Button>
        </Button.Group>;
    }

    setSelected = selected => this.props.setSelected(selected);

    render() {
        const { id } = this.props;

        return <TableList
            action={getCompanyEmployees}
            columns={this.getColumns()}
            payload={{ company: id }}
            filterForm={CompanyEmployeesFilter}
            buttons={this.renderButtons()}
            refreshActions={[DELETE_COMPANY_EMPLOYEES, POST_COMPANY_EMPLOYEE, 'EMPLOYEES_IMPORT']}
            rowSelection={{
                selectedRowKeys: this.props.selected,
                onChange: this.setSelected,
                type: 'checkbox'
            }} />;
    }
}

export default withState('selected', 'setSelected', [])(
    asyncConnect({
        deleteCompanyEmployees: deleteCompanyEmployees
            .withSuccessHandler(({ selected, setSelected }) => {
                message.success(selected.length > 1 ? 'Сотрудники были успешно удалены' : 'Сотрудник был успешно удален');
                setSelected([]);
            })
            .withErrorHandler(({ selected }) => {
                message.error(selected.length > 1 ? 'Не удалось удалить сотрудников' : 'Не удалось удалить сотрудника');
            })
            .withOptions({ resetOnUnmount: true })
    }, null, { openCompanyEmployeeModal, openAddClientToProjectModal, openEmployeesImportModal })(CompanyEmployees)
);
