import React, { Component, Fragment } from 'react';
import { Avatar, Tabs, Tooltip } from 'antd';
import styled from 'styled-components';
import { FileOutlined, TeamOutlined, GlobalOutlined } from '@ant-design/icons';

import { getAvatar } from '../../../constants/urls';
import { REPORT_TYPES } from '../../../constants/companies';
import CompanyDesignPage from './CompanyDesignPage';
import CompanyDetails from './CompanyDetails';
import CompanyUsers from './CompanyUsers';
import CompanyPublicPage from './CompanyPublicPage';
import CompanyBilling from './CompanyBilling';
import { StyledTabs } from './Company';
import { filter, find, includes, propEq } from 'ramda';
import { Route, withRouter } from 'react-router';

const Wrapper = styled.div`
    display: flex;
    padding: 15px;
`;

const InfoBlock = styled.div`
    padding-top: 10px;
    margin-left: 20px;
`;

const tabs = [
    { key: 'details', title: 'Реквизиты', component: CompanyDetails },
    { key: 'billing', title: 'Платежи', component: CompanyBilling, route: 'billing/:type', changeRoute: 'billing/operations' },
    { key: 'users', title: 'Пользователи', component: CompanyUsers },
    { key: 'design', title: 'Дизайн страницы тестирования', component: CompanyDesignPage },
    { key: 'public', title: 'Публичная страница', component: CompanyPublicPage },
];

class CompanyInfo extends Component {
    getTabs = () => {
        const { company } = this.props;

        return filter(tab => tab.hide ? !tab.hide(company, this.props) : true, tabs);
    }

    onChange = key => {
        const { history, match: { url }} = this.props;
        const tab = find(propEq('key', key), this.getTabs());

        history.replace(`${url}/${tab.changeRoute || key}`);
    }

    render() {
        const { company, companyMeta, id, isAdmin, user, match: { url }, location: { pathname }} = this.props;
        let type = pathname.replace(`${url}`, '').replace('/', '');
        type = includes('billing/', type) ? 'billing' : type;

        return <Fragment>
            <Wrapper>
                <Avatar size={90} src={getAvatar(company.logo, 90)} icon={<GlobalOutlined />} />
                <InfoBlock>
                    <h1>{ company.name }</h1>
                    { company.industry &&
                        <div>
                            <Tooltip title='Отрасль'><FileOutlined /> { company.industry }</Tooltip>
                        </div>
                    }
                    { company.size &&
                        <div>
                            <Tooltip title='Количество сотрудников'><TeamOutlined /> { company.size }</Tooltip>
                        </div>
                    }
                    <div style={{ marginTop: 10 }}>
                        <div>Своя база вопросов: { company.ownQuestions ? 'Да' : 'Нет' }</div>
                        <div>Доступ к ответам: { company.showRespondentAnswers ? 'Да' : 'Нет' }</div>
                        <div>Уведомление о прохождении: { company.respondentFinishNotification ? (
                            `Да (${(company.notificationEmails || []).join(', ')})`
                        ) : 'Нет' }</div>
                        <div>Тип отчета: { REPORT_TYPES[company.reportType] || 'Нестандартный' }</div>
                    </div>
                </InfoBlock>
            </Wrapper>
            <StyledTabs
                className='wrap-bg'
                onChange={this.onChange}
                activeKey={type || ''}
                animated={{ tabPane: false }}>
                { this.getTabs().map(tab =>
                    <Tabs.TabPane key={tab.key} tab={tab.title}>
                        <Route path={`${url}/${tab.route || tab.key}`} render={props =>
                            <tab.component
                                {...props}
                                company={company}
                                companyMeta={companyMeta}
                                id={id}
                                isAdmin={isAdmin}
                                user={user} />
                        } />
                    </Tabs.TabPane>
                )}
            </StyledTabs>
        </Fragment>;
    }
}

export default withRouter(CompanyInfo);
