import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { pathOr } from 'ramda';

import Modal from './Modal';
import ERRORS, { CATEGORY_IN_PROJECT_ERROR } from '../../constants/errors';

export default  class CategoryErrorModal extends Component {
    render() {
        const { modal, params } = this.props;
        const projects = pathOr([], ['projects'], params);

        return <Modal
            {...modal}
            style={{ top: 100 }}
            title={ERRORS[CATEGORY_IN_PROJECT_ERROR]}
            footer={null}>
            {projects.map(project => (
                <div>
                    <Link className='link' to={`/company/${project.companyId}/projects/${project.id}/categories`}>{ project.name } ({ project.companyName})</Link>
                </div>
            ))}
        </Modal>;
    }
}
