import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Form, Switch } from 'antd';

import withFormWrapper from '../hocs/withFormWrapper';
import ProjectSettingsFields from './project/ProjectSettingsFields';
import ListenerField from './ListenerField';
import SubmitButton from './formComponents/SubmitButton';

const Wrapper = styled.div`
    .ant-row .ant-col h3 {
        margin-top: 10px;
    }
`;

class TestSettingsForm extends Component {
    onToggleProjectSettings = value => {
        const { form } = this.props;

        form.batch(() => {
            form.change('settings', value ? null : {
                skipQuestionsAllowed: true,
                timeLimited: true,
                timeLimitedBy: 'test'
            });
        });
    }

    render() {
        return <Wrapper>
            <ListenerField listenFieldName='settings'>
                { ({ settings }) =>
                    <Fragment>
                        <div className='ant-form-inline'>
                            <Form.Item wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} label='Использовать настройки теста'>
                                <Switch checked={!settings} onChange={this.onToggleProjectSettings} />
                            </Form.Item>
                        </div>
                        { settings && <ProjectSettingsFields {...this.props} prefix='settings' settings={settings} /> }
                    </Fragment>
                }
            </ListenerField>
            <div>
                <SubmitButton type='primary'>Сохранить</SubmitButton>
            </div>
        </Wrapper>;
    }
}

export default withFormWrapper(TestSettingsForm, {
    mapPropsToValues: ({ item }) => item,
    mapBeforeSubmit: values => ({
        ...values,
        settings: values.settings || null
    })
});
