import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { Button, Popconfirm } from 'antd';
import { prop, omit } from 'ramda';
import { connect } from 'react-redux';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import ListenerField from './ListenerField';
import ImageUpload from './formComponents/ImageUpload';
import Editor from './formComponents/Editor';
import { openCompanyDesignPageModal } from '../../actions/modalActions';

class CompanyDesignPageForm extends Component {
    addDesign = () => {
        this.props.form.change('designScheme', { colors: {} });
    }

    deleteDesign = () => {
        this.props.formAction.dispatch(omit(['designScheme'], this.props.item));
    }

    onChangeColors = ({ colors, fontFamily }) => {
        const { form } = this.props;

        form.batch(() => {
            form.change('designScheme.fontFamily', fontFamily);
            form.change('designScheme.colors', colors);
        });
        form.submit();
    }

    openColorSettings = () => {
        const { openCompanyDesignPageModal, form } = this.props;
        const { designScheme } = form.getState().values;

        openCompanyDesignPageModal({
            onChange: this.onChangeColors,
            designScheme
        });
    }

    renderForm() {
        const { item, formAction } = this.props;

        return <Fragment>
            <Field
                name='designScheme.logo'
                component={ImageUpload}
                height={55}
                label='Логотип' />
            <Field
                name='designScheme.footerText'
                component={Editor}
                label='Футер' />
            <div style={{ marginBottom: 15 }}>
                <Button onClick={this.openColorSettings}>Предварительный просмотр и настройка цветовой схемы</Button>
            </div>
            <SubmitButton type='primary'>Сохранить</SubmitButton>
            { !!item.designScheme && (
                formAction.meta.pending ?
                    <Button style={{ marginLeft: 10 }} disabled>Удалить</Button> :
                    <Popconfirm
                        title='Вы уверены, что хотите удалить дизайн страницы?'
                        okText='Да'
                        cancelText='Нет'
                        onConfirm={this.deleteDesign}>
                        <Button
                            type='danger'
                            style={{ marginLeft: 10 }}>
                            Удалить
                        </Button>
                    </Popconfirm>
            )}
        </Fragment>;
    }

    render() {
        return <ListenerField listenFieldName='designScheme'>
            { ({ designScheme }) => designScheme ?
                this.renderForm() :
                <Fragment>
                    <Button type='primary' onClick={this.addDesign}>
                        Создать персональный дизайн страницы респондента
                    </Button>
                    <Field name='designScheme.footerText' component={() => null} />
                </Fragment>
            }
        </ListenerField>;

    }
}

export default withFormWrapper(connect(null, { openCompanyDesignPageModal })(CompanyDesignPageForm), {
    mapPropsToValues: prop('item')
});
