import React, { Component } from 'react';
import { groupWith, find, propEq } from 'ramda';
import moment from 'moment';
import styled from 'styled-components';
import { FileAddOutlined } from '@ant-design/icons';

import TableList from '../table/TableList';
import { getTestTemplateChanges, postTestTemplateWithId } from '../../../actions/asyncActions';
import DateFormat from '../table/DateFormat';
import { withAsyncActions } from 'react-async-client';
import { message, Button, Tag } from 'antd';
import { TEST_STATUSES } from '../../../constants/tests';
import { getExecutionTime } from '../../../utils/time';

const FIELDS = {
    name: 'Название',
    industry: 'Функция',
    testGroup: 'Группа'
};

const TableWrapper = styled.div`
    .ant-table-row-expand-icon {
        display: none;
    }
    .table-date {
        background: rgba(0, 0, 0, 0.01);
        font-weight: 700;
        .ant-table-selection-column {
            display: none;
        }
        td {
            padding: 5px 16px;
        }
    }
`;

class TestChangesTabButtonsComponent extends Component {
    addVersion = () => {
        const { postTestTemplateWithId, match: { params: { id }}} = this.props;

        postTestTemplateWithId.dispatch(id);
    }

    render() {
        return <Button
            type='primary'
            icon={<FileAddOutlined />}
            onClick={this.addVersion}>
            Зафиксировать версию
        </Button>;
    }
}

export const TestChangesTabButtons = withAsyncActions({
    postTestTemplateWithId: postTestTemplateWithId
        .withSuccessHandler(({ history, match: { params: { id }}}) => {
            message.success('Новая версия теста успешно создана');
            history.replace(`/tests/${id}/history`);
        })
        .withErrorHandler(() => message.error('Не удалось создать новую версию теста'))
        .withOptions({ resetOnUnmount: true })
})(TestChangesTabButtonsComponent);

export default class TestChangesTab extends Component {
    getColumns = () => {
        return [
            {
                title: 'Список изменений',
                key: 'actions',
                dataIndex: 'actions',
                render: (actions, { isDate, date }) => isDate ?
                    <DateFormat date={date} /> : actions.map(data => <div key={data.id}>{ this.renderChange(data) }</div>)
            }
        ];
    }

    renderChange = ({ name, data }) => {
        if (name === 'question_remove') {
            return 'Вопрос удален';
        }

        switch (data.field) {
            case 'name':
            case 'industry':
            case 'testGroup':
                return <span>
                    <strong>{ FIELDS[data.field] }</strong> изменена с <i>{data.oldName || data.oldValue}</i> на <i>{data.newName || data.newValue}</i>
                </span>;
            case 'actualVersion':
                return 'Создана новая версия теста';
            case 'defaultExecutionTime':
                return <span>Изменено время прохождения теста с <i>{getExecutionTime(data.oldValue)}</i> на <i>{getExecutionTime(data.newValue)}</i></span>;
            case 'status':
                const tag = find(propEq('id', data.newValue), TEST_STATUSES);
                return <span>Установлен статус <Tag color={tag.color}>{ tag.value }</Tag></span>;
            default:
                return null;
        }
    }

    parseItems = items => {
        const grouped = groupWith((a, b) => moment(a.date).isSame(b.date, 'day'), items || []);

        return (grouped || []).map(children => {
            const item = children[0];

            return {
                isDate: true,
                id: `group-${item.id}`,
                date: item.date,
                children
            };
        });
    }

    getExpanded = items => (items || []).map(({ id }) => id);

    render() {
        const { id } = this.props;

        return <TableWrapper>
            <TableList
                payload={{ id }}
                action={getTestTemplateChanges}
                columns={this.getColumns()}
                getExpanded={this.getExpanded}
                parseItems={this.parseItems}
                rowClassName={item => item.isDate && 'table-date'} />
        </TableWrapper>;
    }
}
