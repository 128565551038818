import React, { Component } from 'react';
import { Tabs } from 'antd';
import { Route } from 'react-router-dom';
import styled from 'styled-components';

import SurveyElements from './settings/SurveyElements';
import SurveyExtended from './settings/SurveyExtended';

const TABS = [
    { name: 'Элементы', key: '', component: SurveyElements },
    { name: 'Расширенные', key: 'extended', component: SurveyExtended }
]

const Wrapper = styled.div`
    background: #fff;
`;

class SurveySettings extends Component {
    onChangeTab = key => {
        const { history, survey: { id } } = this.props;

        history.replace(`/surveys/${id}/settings${key === 'elements' ? '' : `/${key}`}`);
    }

    render() {
        const { match: { params: { tab } }, survey } = this.props;

        return <Wrapper>
            <Tabs onChange={this.onChangeTab} animated={false} activeKey={tab || 'elements'}>
                { TABS.map(tab =>
                    <Tabs.TabPane key={tab.key || 'elements'} tab={tab.name}>
                        <Route path={`/surveys/${survey.id}/settings/${tab.key}`} render={props =>
                            <tab.component {...props} survey={survey} />
                        } />
                    </Tabs.TabPane>
                )}
            </Tabs>
        </Wrapper>;
    }
}

export default SurveySettings;
