import React, { Component } from 'react';
import styled from 'styled-components';
import { InputNumber } from 'antd';

import Complexity from '../../user/table/Complexity';
import withFieldWrapper from '../../hocs/withFieldWrapper';

const LevelSelector = styled.div`
    display: flex;
    align-items: center;
    margin-right: 7px;
    .ant-input-number {
        width: 60px;
        margin-left: 2px;
    }
`;

class LevelInputNumber extends Component {
    onChange = number => {
        this.props.input.onChange(number || this.props.min || 0);
    }

    render() {
        const { level, input: { value }, min } = this.props;

        return <LevelSelector>
            <Complexity level={level} />
            <InputNumber
                size='small'
                value={value}
                onChange={this.onChange}
                min={min} />
        </LevelSelector>;
    }
}

export default withFieldWrapper(LevelInputNumber);
