import { Button, Select, Table, DatePicker } from 'antd';
import { all, compose, eqBy, filter, find, groupWith, path, pathEq, pathOr, prop, sortBy } from 'ramda';
import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { withState } from 'recompose';
import moment from 'moment';

import { getCompanies, getProjects, getRespondents } from '../../actions/asyncActions';
import { downloadRespondentTestsReport, downloadRespondentTestsReportEN, isGrafic, isNum, isVerbal } from '../../utils/respondent';
import { extendSearchPath, getUrlParams } from '../../utils/urlParams';
import Complexity from '../user/table/Complexity';

const getHighestDateItem = items => items.sort((a,b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0];

class Reports extends Component {
    onChangeCompany = value => {
        this.props.setCompany(value);
        const { location, history, dates } = this.props;
        history.replace(extendSearchPath(location, { filter: { company: value, dates } }));
    }

    onChangeDates = value => {
        if (value) {;
            const [start, end] = value;
            const dates = {
                createdAfter: start.toISOString(),
                createdBefore: end.toISOString(),
            };
            this.props.setDates(dates);
            const { location, history, company } = this.props;
            history.replace(extendSearchPath(location, { filter: { company, dates } }));
        }
    }

    getColumns = () => {
        const { getProjects } = this.props;

        return [
            {
                title: 'Респондент',
                key: 'respondent',
                render: item => {
                    const respondent = pathOr({}, [0], item);

                    const numItem = getHighestDateItem(filter(i => isNum(i.project.name), item || []));
                    const verbalItem = getHighestDateItem(filter(i => isVerbal(i.project.name), item || []));
                    const graficItem = getHighestDateItem(filter(i => isGrafic(i.project.name), item || []));
                    const reportItem = filter(i => !!i, [numItem, verbalItem, graficItem]);

                    return <div>
                        <div>{ respondent.lastName } { respondent.firstName } { respondent.middleName || '' }</div>
                        { all(i => i.status === 'finished', reportItem) && (
                            <Button.Group>
                                <Button size='small' onClick={() => downloadRespondentTestsReport(reportItem)}>Скачать отчет</Button>
                                <Button size='small' onClick={() => downloadRespondentTestsReportEN(reportItem)}>EN</Button>
                            </Button.Group>
                        )}
                    </div>;
                }
            },
            {
                title: 'Код',
                key: 'code',
                dataIndex: [0, 'code']
            },
            ...(getProjects.data.items || []).map(project => ({
                title: project.name,
                key: project.id,
                render: item => {
                    const respondent = find(pathEq(['project', 'id'], project.id), item)
                    const testSuite = path(['_embedded', 'testSuite'], respondent);

                    return respondent && testSuite ? <div>
                        <Complexity level={testSuite.complexityLevel} />
                        { respondent.status === 'finished' ? 'Пройдено' : 'Не пройдено' }
                    </div> : null
                }
            }))
        ];
    }

    getData = () => {
        const { getRespondents } = this.props;
        const items = groupWith(eqBy(prop('code')), sortBy(prop('code'), getRespondents.data.items || []));

        return items;
    }

    render() {
        const { getCompanies, company, dates, getRespondents, getProjects } = this.props;
        const options = getCompanies.data.items || [];

        return <div>
            <div>
                <Select
                    value={options.length ? company : null}
                    loading={getCompanies.meta.pending}
                    onChange={this.onChangeCompany}
                    placeholder='Компания'
                    style={{ width: 200 }}
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, option) =>
                        (option.children || '').toLowerCase().includes(input.toLowerCase())
                    }
                >
                    { options.map(item =>
                        <Select.Option key={item.id} value={item.id}>{ item.name }</Select.Option>
                    )}
                </Select>
                <DatePicker.RangePicker
                    onChange={this.onChangeDates}
                    style={{ width: 300, marginLeft: 8 }}
                    value={dates && [
                        moment(dates.createdAfter),
                        moment(dates.createdBefore),
                    ]}
                    allowClear={false}
                />
            </div>
            { (!company || !dates) ?
                <div style={{ padding: 30, textAlign: 'center' }}>Выберите компанию и период</div> :
                <Table
                    style={{ marginTop: 15 }}
                    columns={this.getColumns()}
                    dataSource={this.getData()}
                    pagination={false}
                    loading={getRespondents.meta.pending || getProjects.meta.pending}
                    rowKey={path([0, 'code'])} />
             }
        </div>
    }
}

const getInitialCompany = ({ location }) => path(['filter', 'company'], getUrlParams(location));
const getInitialDates = ({ location }) => {
    let filter = path(['filter', 'dates'], getUrlParams(location));

    return filter && filter.createdAfter && filter.createdBefore ? filter : {
        createdAfter: moment().add(-1, 'months').startOf('day').toISOString(),
        createdBefore: moment().endOf('day').toISOString(),
    }
};

export default compose(
    withState('company', 'setCompany', getInitialCompany),
    withState('dates', 'setDates', getInitialDates),
)(withAsyncActions(({ company : initialCompany }) => ({
    getRespondents: getRespondents
        .withParams(({ company, dates }) => ({ company, dates }))
        .withPayload(({ company, dates = {} }) => ({
            offset: 0,
            limit: 0,
            sort_by: 'createdAt',
            sort_order: 'desc',
            q: {
                company,
                createdAfter: dates.createdAfter,
                createdBefore: dates.createdBefore,
            }
        }))
        .withOptions({ dispatchOnMount: !!initialCompany, resetOnUnmount: true, dispatchOnUpdate: true }),
    getProjects: getProjects
        .withPayload(({ company }) => ({
            offset: 0,
            limit: 0,
            q: {
                company,
                statuses: ['active'],
            }
        }))
        .withOptions({ dispatchOnMount: !!initialCompany, resetOnUnmount: true, dispatchOnUpdate: true }),
    getCompanies: getCompanies
        .withPayload(() => ({
            offset: 0,
            limit: 0,
            q: {
                enabled: true
            },
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
}))(Reports));
