import React from 'react';
import { filter, propEq, forEach, append, map, groupWith, flatten, findIndex, find, path } from 'ramda';
import { UserTie } from '@styled-icons/fa-solid/UserTie';
import { User } from '@styled-icons/fa-solid/User';
import { Sitemap } from '@styled-icons/fa-solid/Sitemap';
import styled from 'styled-components';
import { TEMPLATE_VARIABLES } from '../constants/companies';

const Label = styled.div`
    padding-left: ${({ level }) => level * 10}px;
`

const getItem = (item, level, items) => ({
    id: item.id,
    value: <Label level={level}>
        { item.type === 'head' ? <UserTie size={14} /> : item.type === 'division' ? <Sitemap size={14} /> : <User size={14} /> } { item.name }
    </Label>,
    children: filter(propEq('parent', item.id), items) ? filter(propEq('parent', item.id), items).map(item => getItem(item, level + 1, items)) : [],
    disabled: item.type === 'division'
});

export const getBusinessUnitsOptions = items => {
    const cascader = filter(i => !i.parent, items).map(item => getItem(item, 0, items));
    let parsed = [];

    const fn = itms => forEach(({ children, ...item }) => {
        parsed = append(item, parsed);

        if (children.length) {
            fn(children);
        }
    }, itms);

    fn(cascader);

    return parsed;
}

export const parseCompanyTemplate = (string, decode) => {
    if (decode) {
        return string.replace(/\[.*?\]/g, str => {
            const data = find(propEq('var', str.replace(/\[|\]/g, '')), TEMPLATE_VARIABLES);
            return data ? `<span class="variable-text" data-var="${data.var}" data-title="${data.title}"><span contenteditable="false">${data.title}</span></span>` : str;
        });
    } else {
        const node = new DOMParser().parseFromString(`<div>${ string.replace(/<br>/gi, '<br />') }</div>`, 'text/html');
        const variables = node.querySelectorAll('span[data-var]');

        variables.forEach(v => {
            const dataVar = v.getAttribute('data-var');
            v.parentNode.replaceChild(document.createTextNode(`[${dataVar}]`), v);
        });
        return node.body.firstChild.innerHTML;
    }
};

const groupRespondents = (items = []) => {
    let sorted = [];

    forEach(item => {
        const index = findIndex(propEq('id', item.testSuite), sorted);

        if (index > -1) {
            sorted[index].items = append(item, sorted[index].items)
        } else {
            sorted = append({ id: item.testSuite, items: [item] }, sorted)
        }
    }, items);

    return groupWith((a, b) => a.testSuite === b.testSuite, flatten(sorted.map(({ items }) => items)));
}

export const getTreeRespondents = tests => items => {
    return map(children => {
        const test = find(propEq('id', path([0, 'testSuite'], children)), tests) || {};

        return {
            isSubtitle: true,
            id: test.id,
            name: test.name,
            children
        };
    }, groupRespondents(items, tests));
}
