import React, { Component } from 'react';
import { Upload } from 'antd';
import { pathOr } from 'ramda';
import { InboxOutlined } from '@ant-design/icons';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import { BASE_URL } from '../../../constants/urls';
import { getToken } from '../../../utils/token';

class CommandUpload extends Component {
    beforeUpload = () => {
        return false;
    }

    onChange = data => {
        const { multi, onChange } = this.props;
        const files = multi ? data.fileList.map(item => item.originFileObj || item) : (data.fileList.length ? [data.file] : []);

        onChange(files.length ? files : null);
    }

    renderUploaded = () => {
        const { command, inputFilesRoutes } = this.props;

        return Object.keys(command.inputFiles).map((fileCode) => {
            const downloadUrl = `${BASE_URL}${inputFilesRoutes[fileCode]}?access_token=${getToken()}`;
            const fileName = pathOr('file', ['inputData', fileCode], command);

            return <div key={`file-${fileCode}`}>
                <a href={downloadUrl} target='_blank' rel='noopener noreferrer' download>{fileName}</a>
            </div>;
        });
    }

    render() {
        const { input: { value }, fileType, zip, multi, uploaded } = this.props;

        return uploaded ?
            <div style={{ marginTop: 9 }}>
                { this.renderUploaded() }
            </div> :
            <Upload.Dragger
                accept={fileType === 'xlsx' ? `.xlsx, .application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel${zip ? ',.zip' : ''}` : '.pdf'}
                beforeUpload={this.beforeUpload}
                fileList={value || []}
                onChange={this.onChange}
                multiple={multi}>
                <p className='ant-upload-drag-icon'>
                    <InboxOutlined />
                </p>
                <p className='ant-upload-text'>Чтобы загрузить файл просто перетащите его сюда</p>
                <p className='ant-upload-hint'>
                    {fileType === 'xlsx' ?
                        `excel-файл${zip ? ' или zip-архив с excel-файлами' : ''}` : 'pdf-файл с приложением'
                    }
                </p>
            </Upload.Dragger>;
    }
}

export default withFieldWrapper(CommandUpload);
