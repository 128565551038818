import React, { Component } from 'react';
import { Card, Tag, Avatar } from 'antd';
import { connect } from 'react-redux';
import { find, propEq } from 'ramda';
import { MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';

import { getUser } from '../../actions/asyncActions';
import Username from './table/Username';
import { ROLES } from '../../constants/users';
import { getAvatar } from '../../constants/urls';
import { openProfileModal } from '../../actions/modalActions';

class Profile extends Component {
    edit = () => {
        this.props.openProfileModal(this.props.user);
    }

    render() {
        const { user } = this.props;
        const role = find(propEq('id', user.role), ROLES) || {};

        return <Card
            // extra={<Button type='primary' icon={<EditOutlined />} onClick={this.edit}>Редактировать</Button>}
            title={
                <div style={{ display: 'flex' }}>
                    <Avatar size={50} src={getAvatar(user.photo)} icon={<UserOutlined />} />
                    <div style={{ marginLeft: 15 }}>
                        <Username user={user} />
                        <div><Tag color={role.color}>{ role.value }</Tag></div>
                    </div>
                </div>
            }>
            <div><MailOutlined /> { user.email }</div>
            { user.phone && <div><PhoneOutlined /> { user.phone }</div> }
        </Card>
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps, { openProfileModal })(Profile);
