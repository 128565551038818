import React, { Component, Fragment } from 'react';
import { prop, pathOr } from 'ramda';
import * as yup from 'yup';
import { Field } from 'react-final-form';

import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import { PhoneInput } from './formComponents/MaskedInput';
import Select from './formComponents/Select';
import { getCategories } from '../../actions/asyncActions';
import { withAsyncActions } from 'react-async-client';
import SubmitButton from './formComponents/SubmitButton';

const labelCol = {
    xs: { span: 24 },
    sm: { span: 6 },
};

const wrapperCol = {
    xs: { span: 24 },
    sm: { span: 18 },
};

class ExpertForm extends Component {
    render() {
        const { getCategories } = this.props;

        return <Fragment>
            <h3>Информация</h3>
            <Field
                name='lastName'
                component={Input}
                label='Фамилия'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='firstName'
                component={Input}
                label='Имя'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='categories'
                component={Select}
                label='Компетенции'
                wrapperCol={wrapperCol}
                labelCol={labelCol}
                options={pathOr([], ['data', 'items'], getCategories)}
                loading={getCategories.meta.pending}
                namePath='name'
                isMulti />
            <h3>Контакты</h3>
            <Field
                name='email'
                component={Input}
                label='Email'
                htmlType='email'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='phone'
                component={PhoneInput}
                label='Телефон'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <SubmitButton type='primary'>Сохранить</SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    email: yup.string().email().required()
});

export default withAsyncActions({
    getCategories: getCategories
        .withPayload(() => ({
            limit: 0,
            sort: 'asc'
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})(
    withFormWrapper(ExpertForm, {
        mapPropsToValues: prop('item'),
        validationSchema
    })
);
