import React, { Component, Fragment } from 'react';
import * as yup from 'yup';
import { Field } from 'react-final-form';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import { LockOutlined } from '@ant-design/icons';

class ResetPasswordForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='password'
                component={Input}
                htmlType='password'
                size='large'
                icon={<LockOutlined />}
                placeholder='Пароль' />
            <SubmitButton size='large' type='primary'>
                Изменить пароль
            </SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    password: yup.string().min(8, 'Пароль должен содержать по крайней мере 8 символов').required()
});

export default withFormWrapper(ResetPasswordForm, {
    mapPropsToValues: ({ id }) => ({ id }),
    validationSchema
});
