import React, { Component, Fragment } from 'react';
import { SagaProvider } from 'react-async-client';
import { ConnectedRouter } from 'connected-react-router';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { createGlobalStyle, css } from 'styled-components';
import ruRU from 'antd/lib/locale-provider/ru_RU';
import isMobile from 'ismobilejs';

import { sagaMiddleware, history } from '../store/middlewares';
import SecureLayout from './layout/SecureLayout';
import { pushRollbarError } from '../utils/rollbar';

const GlobalStyles = createGlobalStyle`
    html, body, #root {
        min-height: 100vh;
    }

    body {
        font-family: 'Geometria', sans-serif;
    }

    .highcharts-exporting-group {
        display: none;
    }

    ${isMobile().any && css`
        .ant-menu-inline-collapsed-tooltip {
            display: none;
        }
    `}
`;

export default class App extends Component {
    state = {
        error: false
    };

    componentDidCatch(error) {
        pushRollbarError(error);
        this.setState({ error: true });
    }

    render() {
        const { store } = this.props;

        return this.state.error ?
            <div>Не удалось запустить приложение</div> :
            <Fragment>
                <GlobalStyles />
                <ConfigProvider locale={ruRU}>
                    <SagaProvider sagaMiddleware={sagaMiddleware}>
                        <Provider store={store}>
                            <ConnectedRouter history={history}>
                                <SecureLayout />
                            </ConnectedRouter>
                        </Provider>
                    </SagaProvider>
                </ConfigProvider>
            </Fragment>;
    }
}
