import { createAction, createAsyncAction, asyncTakeFirst } from 'react-async-client';

import * as actions from '../constants/actionTypes';
import { postRefreshTokenHandler } from './handlers';

export const logout = createAction(actions.LOGOUT);
export const postRefreshToken = createAsyncAction(actions.POST_REFRESH_TOKEN, postRefreshTokenHandler, {}, asyncTakeFirst);

export const questionsImportSuccess = createAction(actions.QUESTION_IMPORT_SUCCESS);
export const employeesImportSuccess = createAction(actions.EMPLOYEES_IMPORT_SUCCESS);
