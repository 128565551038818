import React from 'react';
import { Field } from 'react-final-form';
import { Col } from 'antd';

import BaseFilter from './BaseFilter';
import { getCompanies } from '../../../actions/asyncActions';
import withFilterForm from '../../hocs/withFilterForm';
import Select from '../formComponents/Select';
import { ENABLED } from '../../../constants/table';

export default withFilterForm(() =>
    <BaseFilter
        action={getCompanies}
        searchFieldName='text'
        searchFieldPlaceholder='Поиск'
        span={12}>
        <Col span={12}>
            <Field
                name='enabled'
                component={Select}
                options={ENABLED}
                allowFalseValue />
        </Col>
    </BaseFilter>
);
