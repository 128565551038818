import React, { Component } from 'react';
import { pluralize } from 'numeralize-ru';
import { Button, message, Popconfirm, Spin } from 'antd';
import { withAsyncActions } from 'react-async-client';
import { CalendarOutlined, DeleteOutlined, FileExcelOutlined } from '@ant-design/icons';
import { includes } from 'ramda';

import TableList from '../table/TableList';
import { getCompanyPayments, deleteCompanyPayment, putPayment } from '../../../actions/asyncActions';
import { POST_COMPANY_PAYMENT, DELETE_COMPANY_PAYMENT, PUT_PAYMENT } from '../../../constants/actionTypes';
import DateFormat from '../table/DateFormat';
import { PAYMENTS_STATUSES, PAYMENT_STATUSES_LINK } from '../../../constants/companies';
import { getToken } from '../../../utils/token';

const operations = {
    paid: 'Пополнение',
    credit: 'Кредитное пополнение',
    request: 'Рассматривается запрос пополнения'
};

export class EnsiLoadStatus extends Component {
    state = {
        loading: true,
        status: PAYMENTS_STATUSES.request,
        confirmedByEnsi: false
    };

    componentDidMount() {
        this.eventSource = new EventSource(window._mercureHubUrl + '?topic=ensi://invoices/' + this.props.payment.id);
        this.eventSource.onmessage = this.statusHandler;
    }

    componentWillUnmount() {
        this.eventSource.close();
    }

    statusHandler = (event) => {
        try {
            const eventData = JSON.parse(event.data);

            this.setState({
                loading: false,
                status: eventData.reject ? PAYMENTS_STATUSES.rejectedByEnsi : (eventData.ensiError || PAYMENTS_STATUSES.confirmedByEnsi),
                confirmedByEnsi: !eventData.reject && !eventData.ensiError
            });
            this.eventSource.close();
        } catch (e) {
            this.setState({
                loading: false,
                status: 'Ошибка обработки данных'
            });
            this.eventSource.close();
        }
    }

    getDownloadUrl = () => {
        return `/api/payments/${this.props.payment.id}.xlsx?access_token=${getToken()}`;
    }

    render() {
        return <div>
            { this.state.loading && <Spin size='small' style={{ display: 'inline', marginRight: 5 }} /> } { this.state.status }
            { this.state.confirmedByEnsi &&
                <Button
                    style={{ marginLeft: 10 }}
                    icon={<FileExcelOutlined />}
                    href={this.getDownloadUrl()}
                    target='_blank'
                    rel='noopener noreferrer'
                    download />
            }
        </div>;
    }
}

class CompanyPayments extends Component {
    getDownloadUrl = id => {
        return `/api/payments/${id}.xlsx?access_token=${getToken()}`;
    }

    getColumns = () => {
        return [
            {
                title: 'Операция',
                key: 'operation',
                render: item =>
                    <div>
                        <div>{ operations[item.status] } на сумму { item.value } { pluralize(item.value, 'юнит', 'юнита', 'юнитов') }</div>
                        { item.status === 'credit' &&
                            <div style={{ marginTop: 5 }}>
                                <Button onClick={() => this.closeCredit(item)} size='small'>Погасить</Button>
                            </div>
                        }
                    </div>
            },
            {
                title: 'Дата',
                key: 'createdAt',
                dataIndex: 'createdAt',
                render: date => <DateFormat date={date} icon={<CalendarOutlined />} withTime />
            },
            {
                title: 'Статус',
                key: 'status',
                dataIndex: 'status',
                render: (status, item) =>
                    status === 'request' ? <EnsiLoadStatus payment={item} /> :
                    <div>
                        { PAYMENTS_STATUSES[status] }
                        { includes(item.status, PAYMENT_STATUSES_LINK) &&
                            <Button
                                style={{ marginLeft: 10 }}
                                icon={<FileExcelOutlined />}
                                href={this.getDownloadUrl(item.id)}
                                target='_blank'
                                rel='noopener noreferrer'
                                download />
                        }
                    </div>
            },
            {
                className: 'right-column',
                key: 'actions',
                render: ({ id, company, status }) =>
                    <Button.Group>
                        <Popconfirm
                            title='Вы уверены, что хотите удалить платеж?'
                            onConfirm={() => this.props.deleteCompanyPayment.dispatch({ id, company })}>
                            <Button type='danger' icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </Button.Group>
            }
        ];
    }

    closeCredit = item => {
        this.props.putPayment.dispatch({
            ...item,
            status: 'paid'
        });
    }

    render() {
        const { id } = this.props;

        return <TableList
            action={getCompanyPayments}
            payload={{ company: id }}
            columns={this.getColumns()}
            refreshActions={[POST_COMPANY_PAYMENT, DELETE_COMPANY_PAYMENT, PUT_PAYMENT]}
            defaultSort={{
                sort_by: 'createdAt',
                sort_order: 'desc'
            }} />;
    }
}

export default withAsyncActions({
    putPayment: putPayment
        .withErrorHandler(() => message.error('Не удалось погасить кредит'))
        .withOptions({ resetOnUnmount: true }),
    deleteCompanyPayment: deleteCompanyPayment
        .withSuccessHandler(() => message.success('Платеж был успешно удален'))
        .withErrorHandler(() => message.error('Не удалось удалить платеж'))
        .withOptions({ resetOnUnmount: true }),
})(CompanyPayments);
