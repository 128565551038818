import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { any } from 'ramda';
import { Badge, Tooltip } from 'antd';
import { LoadingOutlined, FilePdfOutlined } from '@ant-design/icons';

import { getCompanyRespondentPdf } from '../../../actions/asyncActions';
import { openPdfModal } from '../../../actions/modalActions';

const Wrapper = styled.span`
    margin-right: 20px;
    .anticon-file-pdf {
        font-size: 16px;
        cursor: pointer;
        color: hsla(0,0%,100%,.65);
        &:hover {
            color: #fff;
        }
    }
    .ant-badge .anticon-loading {
        color: #fff;
        top: -3px;
        right: -3px;
    }
    .ant-badge-count {
        min-width: 14px;
        height: 14px;
        line-height: 14px;
        font-size: 9px;
        padding: 0 4px;
        top: -4px;
        right: -4px;
    }
`;

class Pdf extends Component {
    isLoading = () => {
        return any(file => file.pending, this.props.files);
    }

    render() {
        const { files, openPdfModal } = this.props;
        const loading = this.isLoading();

        return files.length ?
            <Wrapper>
                <Badge count={loading ? <LoadingOutlined /> : files.length}>
                    <Tooltip title='Отчеты'>
                        <FilePdfOutlined onClick={openPdfModal} />
                    </Tooltip>
                </Badge>
            </Wrapper> : null;
    }
}

const stateToProps = state => ({
    files: state.pdf.map(params => getCompanyRespondentPdf.withParams(params).selectMeta(state))
});

export default connect(stateToProps, { openPdfModal })(Pdf);
