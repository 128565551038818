import React, { Component, Fragment } from 'react';
import { pathOr } from 'ramda';
import { Alert, Button, Form, Tooltip, Spin } from 'antd';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { pluralize } from 'numeralize-ru';
import { asyncConnect } from 'react-async-client';
import moment from 'moment';

import withFormWrapper from '../../hocs/withFormWrapper';
import SubmitButton from '../formComponents/SubmitButton';
import InputNumber from '../formComponents/InputNumber';
import { openCompanyBanketailsModal } from '../../../actions/modalActions';
import { getRespondents } from '../../../actions/asyncActions';
import { BASE_URL } from '../../../constants/urls';
import withStepsPagination from './withStepsPagination';

class ProjectPaymentStep extends Component {
    onChangeValue = value => {
        if (!value) {
            this.props.form.change('value', 1);
        }
    }

    getPaymentDownloadLink = () => {
        return `${BASE_URL}/payments/${this.props.formAction.data.id}.xlsx`;
    }

    render() {
        const { item, openCompanyBanketailsModal, formAction, getRespondents } = this.props;
        const company = pathOr({}, ['_embedded', 'company'], item);

        return <Spin spinning={getRespondents.meta.pending}>
            <Alert message={
                <Fragment>
                    { company.countRespondentsInProgress ?
                        <Tooltip title={`${company.countRespondentsInProgress} ${pluralize(company.countRespondentsInProgress, 'респондент', 'респондента', 'респондентов')} не завершили тестирование`}>
                            <div>Доступно {company.balance - company.countRespondentsInProgress} из {company.balance}</div>
                        </Tooltip> :
                        <div>Доступно {company.balance}</div>
                    }
                    <div>Пополните баланс юнитов для выставления счета</div>
                    <div>Курс юнита 1 = 500 &#8381;</div>
                </Fragment>
            } />
            <Field
                name='value'
                component={InputNumber}
                label='Юниты'
                min={1}
                onChange={this.onChangeValue} />
            { formAction.meta.success ?
                <Alert
                    message={
                        <Fragment>
                            <div>Платеж на сумму { formAction.data.value } { pluralize(formAction.data.value, 'юнит', 'юнита', 'юнитов') } от { moment(formAction.data.createdAt).format('DD.MM.YYYY') }</div>
                            <a href={this.getPaymentDownloadLink()} target='_blank' rel='noopener noreferrer' download>Скачать</a>
                        </Fragment>
                    }
                    style={{ marginBottom: 15 }} /> :
                <Form.Item>
                    <Button.Group>
                        <Button
                            onClick={() => openCompanyBanketailsModal({ company: company.id, data: company.bankDetails })}
                            style={{ marginBottom: 15 }}>
                            { company.bankDetails ? 'Проверить реквизиты' : 'Указать реквизиты' }
                        </Button>
                        <SubmitButton type='primary' disabled={!company.bankDetails}>Оплатить</SubmitButton>
                    </Button.Group>
                </Form.Item>
            }
        </Spin>;
    }
}

const validationSchema = yup.object().shape({
    value: yup.number().min(1, 'Количество юнитов должно быть больше 0').required()
});

export default withFormWrapper(asyncConnect({
    getRespondents: getRespondents
        .withPayload(({ company, item: { id }}) => ({
            q: {
                company,
                projects: [id],
            },
            limit: 0
        }))
        .withSuccessHandler(({ getRespondents, form }) => form.change('value', getRespondents.data.items.length || 1))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
}, null, { openCompanyBanketailsModal })(withStepsPagination(ProjectPaymentStep)), {
    validationSchema,
    mapPropsToValues: ({ item }) => ({
        company: item.company,
        value: 1,
        status: 'paid'
    })
});
