import React, { Component, Fragment } from 'react';
import * as yup from 'yup';
import { path, prop } from 'ramda';
import { Field } from 'react-final-form';

import { getCompanies } from '../../../actions/asyncActions';
import withFormWrapper from '../../hocs/withFormWrapper';
import SubmitButton from '../formComponents/SubmitButton';
import Input from '../formComponents/Input';
import Select from '../formComponents/Select';
import Type from '../formComponents/Type';
import Editor from '../formComponents/Editor';
import InlineEditor from '../formComponents/InlineEditor';

class SurveyForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='name'
                component={Input}
                label='Название' />
            { !this.props.item.company &&
                <Field
                    name='company'
                    component={Select}
                    label='Компания'
                    action={getCompanies}
                    namePath='name' />
            }
            { this.props.item.id ? (
                <Fragment>
                    <Field
                        name='title'
                        component={InlineEditor}
                        label='Заголовок'
                        inline
                        translations />
                    <Field
                        name='welcomeText'
                        component={Editor}
                        label='Текст приветствия' />
                </Fragment>
            ) : (
                <Field
                    name='questionnaire'
                    label='Опросник'
                    component={Type}
                    namePath='name'
                    getOptionsDescription={(id, option) => path(['description'], option) || '-'}
                    options={path(['company', '_embedded', 'surveyQuestionnaires'], this.props)}
                    allowClear />
            )}
            <SubmitButton type='primary'>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    name: yup.string().nullable().required(),
    company: yup.string().nullable().required(),
    questionnaire: yup.string().nullable().required(),
});

export default withFormWrapper(SurveyForm, {
    validationSchema,
    mapPropsToValues: prop('item')
});
