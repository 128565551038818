import React, { Component } from 'react';
import { pathOr } from 'ramda';
import { CheckOutlined, PlusOutlined, CalendarOutlined } from '@ant-design/icons';
import { pluralize } from 'numeralize-ru';

import TableList from '../table/TableList';
import { getCompanyAccountOperations } from '../../../actions/asyncActions';
import Username from '../table/Username';
import DateFormat from '../table/DateFormat';
import { POST_COMPANY_PAYMENT } from '../../../constants/actionTypes';

class CompanyAccountOperations extends Component {
    getColumns = () => {
        return [
            {
                title: 'Операция',
                key: 'operation',
                render: item => {
                    const user = pathOr({}, ['_embedded', 'user'], item);
                    const respondent = pathOr({}, ['_embedded', 'respondent'], item);
                    const test = pathOr({}, ['_embedded', 'testSuite'], item);

                    switch (item.type) {
                        case 'respondent_finish':
                            return <span>
                                <CheckOutlined style={{ color: '#5CB85C' }} /> <Username user={respondent} /> завершил тест { test.name }
                            </span>;
                        case 'refill':
                            return <span>
                                <PlusOutlined style={{ color: '#5CB85C' }} /> <Username user={user} /> пополнил баланс{ item.credit && ' (в кредит)' } на { item.value } { pluralize(item.value, 'юнит', 'юнита', 'юнитов') }
                            </span>;
                        default:
                            return item.type;
                    }
                }
            },
            {
                title: 'Дата',
                key: 'createdAt',
                dataIndex: 'createdAt',
                render: date => <DateFormat date={date} icon={<CalendarOutlined />} withTime />
            }
        ];
    }

    render() {
        const { id } = this.props;

        return <TableList
            action={getCompanyAccountOperations}
            payload={{ id }}
            columns={this.getColumns()}
            refreshActions={[POST_COMPANY_PAYMENT]}
            defaultSort={{
                sort_by: 'createdAt',
                sort_order: 'desc'
            }} />;
    }
}

export default CompanyAccountOperations;
