import React from 'react';
import { Field } from 'react-final-form';
import { Col } from 'antd';

import BaseFilter from './BaseFilter';
import { getAdmins } from '../../../actions/asyncActions';
import withFilterForm from '../../hocs/withFilterForm';
import InputSearch from '../formComponents/InputSearch';

export default withFilterForm(() =>
    <BaseFilter
        action={getAdmins}
        searchFieldName='name'
        searchFieldPlaceholder='ФИО'
        span={12}>
        <Col span={12}>
            <Field
                name='reason'
                component={InputSearch}
                placeholder='На основании' />
        </Col>
    </BaseFilter>
);
