import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Button } from 'antd';
import styled from 'styled-components';
import { LeftOutlined, ReloadOutlined } from '@ant-design/icons';

import ERRORS, { UNABLE_TO_SHOW } from '../constants/errors';

const Container = styled.div`
    padding: 25px;
    text-align: center;
    background: #fff;
    height: calc(100vh - 104px);
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Title = styled.h1`
    font-size: 7rem;
    margin-bottom: 0;
`;

const SubTitle = styled.h2`
    margin-bottom: 2rem;
`;

class Error extends Component {
    render() {
        const { history: { goBack }, refresh, statusCode } = this.props;
        const { message } = this.props.error;

        return (
            <Container>
                { statusCode && <Title>{statusCode}</Title> }
                { message && <SubTitle>{ERRORS[message] || UNABLE_TO_SHOW}</SubTitle> }
                <div>
                    <Button
                        onClick={goBack}
                        icon={<LeftOutlined />}
                        style={{ marginRight: 15 }}>
                        Назад
                    </Button>
                    <Button
                        onClick={() => refresh()}
                        type='primary'
                        icon={<ReloadOutlined />}>
                        Обновить
                    </Button>
                </div>
            </Container>
        );
    }
}

export default withRouter(Error);
