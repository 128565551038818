import React, { Component } from 'react';
import { Alert, Upload, message } from 'antd';
import qs from 'qs';
import { path } from 'ramda';
import { InboxOutlined } from '@ant-design/icons';

import { getToken } from '../../utils/token';
import { employeesImportSuccess } from '../../actions/appActions';
import Modal from './Modal';
import { getImportErrorMessage } from '../../utils/import';
import { connect } from 'react-redux';

class EmployeesImportModal extends Component {
    state = {
        error: null
    };

    getSearchPath = () => {
        return qs.stringify({
            access_token: getToken(),
        }, { addQueryPrefix: true });
    }

    onChange = info => {
        const { close, employeesImportSuccess } = this.props;
        const { status } = info.file;

        switch(status) {
            case 'done':
                employeesImportSuccess();
                message.success('Сотрудники были успешно импортированы');
                close();
                break;
            case 'error':
                this.setState({ error: path(['file', 'response', 'errors', 0], info) });
                message.error('Не удалось импортировать сотрудников');
                break;
            default:
                return;
        }
    }

    renderError = () => {
        const { error } = this.state;

        if (!error) {
            return null;
        }

        return <Alert type='error' message={<div dangerouslySetInnerHTML={{ __html: getImportErrorMessage(error) }} />} style={{ marginBottom: 10 }} />;
    }

    onChangeCompany = company => this.setState({ company });

    render() {
        const { modal, params } = this.props;

        return <Modal
            {...modal}
            title='Импортирование сотрудников'
            footer={null}>
            { this.renderError() }
            <Upload.Dragger
                action={`/api/companies/${params.company}/employees/import${this.getSearchPath()}`}
                onChange={this.onChange}
                showUploadList={false}
                accept='.xlsx'>
                <p className='ant-upload-drag-icon'>
                    <InboxOutlined />
                </p>
                <p className='ant-upload-text'>Чтобы загрузить файл просто перетащите его сюда</p>
            </Upload.Dragger>
        </Modal>;
    }
}

export default connect(null, { employeesImportSuccess })(EmployeesImportModal);
