import React from 'react';

import BaseFilter from './BaseFilter';
import { getSurveyRespondents } from '../../../actions/asyncActions';
import withFilterForm from '../../hocs/withFilterForm';

export default withFilterForm(() =>
    <BaseFilter
        action={getSurveyRespondents}
        searchFieldName='name'
        searchFieldPlaceholder='Поиск'
        span={12}>
    </BaseFilter>
);
