import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import { postExpert, putExpert } from '../../actions/asyncActions';
import ExpertForm from '../forms/ExpertForm';

class ExpertModal extends Component {
    render() {
        const { modal, params, putExpert, postExpert } = this.props;

        return <Modal
            {...modal}
            title={params ? 'Редактировать эксперта' : 'Добавить эксперта'}
            footer={null}>
            <ExpertForm
                formAction={params ? putExpert : postExpert}
                item={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    postExpert: postExpert
        .withSuccessHandler(({ close }) => {
            message.success('Эксперт успешно добавлен');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
    putExpert: putExpert
        .withSuccessHandler(({ close }) => {
            message.success('Экспер успешно изменен');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(ExpertModal);
