import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { Table, Tag } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { find, propEq } from 'ramda';

import { getTestTemplateVersions } from '../../../actions/asyncActions';
import { TEST_STATUSES } from '../../../constants/tests';

const StyledTable = styled(Table)`
    border-bottom: 1px solid #eaeaea!important;
    border-left: 1px solid #eaeaea!important;
    border-right: 1px solid #eaeaea!important;
`;

class TestHistoryTab extends Component {
    getColumns = () => {
        return [
            {
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: createdAt => `Версия от ${moment(createdAt).format('DD.MM.YYYY')}`
            },
            {
                dataIndex: 'status',
                key: 'status',
                render: status => {
                    const data = find(propEq('id', status), TEST_STATUSES);
                    return <Tag color={data.color}>{ data.value }</Tag>;
                }
            }
        ];
    }

    render() {
        const { items = [] } = this.props.getTestTemplateVersions.data;

        return <StyledTable
            dataSource={items}
            columns={this.getColumns()}
            pagination={false}
            components={{
                header: { wrapper: () => null }
            }}
            rowKey='id' />;
    }
}

export default withAsyncActions({
    getTestTemplateVersions: getTestTemplateVersions
        .withPayload(({ id }) => id)
        .withOptions({ resetOnUnmount: true, dispatchOnMount: true })
})(TestHistoryTab);
