import React, { Component, Fragment } from 'react';
import { asyncConnect, toSuccess } from 'react-async-client';
import styled from 'styled-components';
import { List, Button, Popconfirm, message } from 'antd';
import { withState } from 'recompose';
import { path, filter, all, remove, findIndex, propEq } from 'ramda';
import { takeEvery } from 'redux-saga/effects';
import { MailOutlined, PhoneOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import DetailToolbar from '../DetailToolbar';
import { getExperts, postTestTemplateAudit, deleteTestTemplateAudit } from '../../../actions/asyncActions';
import { openTestAuditModal } from '../../../actions/modalActions';
import Username from '../table/Username';
import { POST_TEST_TEMPLATE_AUDIT } from '../../../constants/actionTypes';

const Lists = styled.div`
    padding: 15px;
    background: #fff;
    display: flex;
`;

const ListHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
`;

const ListWrapper = styled.div`
    width: 50%;
    padding-right: ${({ left }) => left ? 5 : 0}px;
    padding-left: ${({ left }) => left ? 0 : 5}px;
    .ant-list-header {
        font-size: 1rem;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
    }
    .ant-list .ant-list-header{
        padding: 10px 15px;
    }
`;

const ListItem = styled(List.Item)`
    justify-content: space-between;
`;

class AddTestAudit extends Component {
    addExpert = item => {
        this.props.postTestTemplateAudit.dispatch({
            testTemplate: this.props.match.params.id,
            expert: item.id
        });
    }

    deleteExpert = item => {
        this.props.deleteTestTemplateAudit.dispatch({
            test: item.testTemplate,
            id: item.id
        });
    }

    renderItem = (item, testExperts) => {
        const expert = testExperts ? path(['_embedded', 'expert'], item) : item;

        return <ListItem>
            <div>
                <div style={{ color: '#000' }}>
                    <Username user={expert} />
                </div>
                <div><MailOutlined /> { expert.email }</div>
                { expert.phone && <div><PhoneOutlined /> { expert.phone }</div> }
            </div>
            { testExperts ?
                <Button.Group>
                    <Popconfirm
                        title='Вы уверены, что хотите удалить эксперта?'
                        okText='Да'
                        cancelText='Нет'
                        onConfirm={() => this.deleteExpert(item)}>
                        <Button icon={<DeleteOutlined />} type='danger' />
                    </Popconfirm>
                </Button.Group> :
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => this.addExpert(item)} />
            }
        </ListItem>;
    }

    getExperts = () => {
        return filter(item => all(i => i._embedded.expert.id !== item.id, this.props.experts), this.props.getExperts.data.items || [])
    }

    render() {
        const { match: { params: { id }}, getExperts: { meta }, experts, openTestAuditModal, postTestTemplateAudit } = this.props;

        return <Fragment>
            <DetailToolbar title={<h1>Добавить экспертов</h1>} backUrl={`/tests/${id}/audits`} />
            <Lists>
                <ListWrapper left>
                    <List
                        header={<ListHeader>
                            Эксперты теста
                            <Button
                                icon={<PlusOutlined />}
                                onClick={() => openTestAuditModal({
                                    item: { testTemplate: id },
                                    formAction: postTestTemplateAudit
                                })}>
                                <span className='hide-mobile'>Добавить</span>
                            </Button>
                        </ListHeader>}
                        dataSource={experts}
                        renderItem={item => this.renderItem(item, true)}
                        bordered />
                </ListWrapper>
                <ListWrapper>
                    <List
                        header={<ListHeader>Эксперты</ListHeader>}
                        loading={meta.pending}
                        dataSource={this.getExperts()}
                        renderItem={item => this.renderItem(item)}
                        bordered />
                </ListWrapper>
            </Lists>
        </Fragment>;
    }
}

export default withState('experts', 'setExperts', [])(
    asyncConnect({
        getExperts: getExperts
            .withPayload(() => ({
                limit: 0
            }))
            .withSaga(function* (getProps) {
                yield takeEvery([toSuccess(POST_TEST_TEMPLATE_AUDIT)], function() {
                    const { getExperts } = getProps();

                    getExperts.refresh();
                })
            })
            .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
        postTestTemplateAudit: postTestTemplateAudit
            .withSuccessHandler(({ postTestTemplateAudit: { data }, experts, setExperts }) => {
                message.success('Эксперт успешно добавлен');
                setExperts([...experts, data]);
            })
            .withOptions({ resetOnUnmount: true }),
        deleteTestTemplateAudit: deleteTestTemplateAudit
            .withSuccessHandler(({ deleteTestTemplateAudit: { data }, experts, setExperts }) => {
                message.success('Эксперт успешно удален');
                setExperts(remove(findIndex(propEq('id', data), experts), 1, experts))
            })
            .withErrorHandler(() => message.error('Не удалось удалить эксперта'))
            .withOptions({ resetOnUnmount: true })
    }, null, { openTestAuditModal })(AddTestAudit)
);
