import React, { Component } from 'react';
import { message } from 'antd';
import { path } from 'ramda';
import { withAsyncActions } from 'react-async-client';
import { withRouter } from 'react-router-dom';

import Modal from './Modal';
import { getCompany, postSurvey, putSurvey } from '../../actions/asyncActions';
import SurveyForm from '../forms/survey/SurveyForm';

class CreateSurveyModal extends Component {
    render() {
        const { modal, params, formAction, getCompany: { data } } = this.props;

        return <Modal
            {...modal}
            title={path(['id'], params) ? 'Редактировать опрос' : 'Создание опроса'}
            footer={null}>
            <SurveyForm
                item={params}
                company={data}
                formAction={formAction} />
        </Modal>;
    }
}

export default withRouter(withAsyncActions(({ params }) => ({
    getCompany: getCompany
        .withPayload(({ params }) => path(['company'], params) || '0')
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true }),
    formAction: (path(['id'], params) ? putSurvey: postSurvey)
        .withSuccessHandler(({ close, history, formAction }) => {
            const isEdit = path(['id'], params);
            message.success(isEdit ? 'Опрос успешно отредактирован' : 'Опрос успешно добавлен');
            close();
            if (isEdit) {
                history.push(`/surveys/${formAction.data.id}/info`);
            }
        })
        .withErrorHandler(() => message.error(path(['id'], params) ? 'Не удалось отредактировать опрос' : 'Не удалось добавить опрос'))
        .withOptions({ resetOnUnmount: true })
}))(CreateSurveyModal));
