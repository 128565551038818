import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import { Button, message } from 'antd';
import styled from 'styled-components';

import CompanyDetailsForm from '../../forms/CompanyDetailsForm';
import { postBankDetails, getBankDetails, getUser } from '../../../actions/asyncActions';
import { ADMIN } from '../../../constants/roles';

const Wrapper = styled.div`
    padding: 15px;
`;

class CompanyDesignPage extends Component {
    resetDetails = () => {
        this.props.getBankDetails.reset();
    }

    render() {
        const { company, postBankDetails, getBankDetails: { data, meta, refresh }, isAdmin } = this.props;

        return <Wrapper>
            { meta.error ? (
                isAdmin &&
                    <Button type='primary' onClick={this.resetDetails}>
                        Добавить реквизиты
                    </Button>
            ) : meta.pending ? null : (
                <CompanyDetailsForm
                    key={data.id}
                    disabled={meta.success}
                    formAction={postBankDetails}
                    refreshItem={refresh}
                    item={data}
                    company={company}
                    disableEdit={!isAdmin} />
            )}
        </Wrapper>;
    }
}

const stateToProps = state => ({
    isAdmin: getUser.selectData(state).role === ADMIN
});

export default asyncConnect({
    getBankDetails: getBankDetails
        .withPayload(({ id }) => id)
        .withOptions({ dispatchOnMount: true }),
    postBankDetails: postBankDetails
        .withSuccessHandler(({ getBankDetails }) => {
            message.success('Реквизиты успешно сохранены');
            getBankDetails.refresh();
        })
        .withErrorHandler(() => message.error('Не удалось сохранить реквизиты компании'))
        .withOptions({ resetOnUnmount: true })
}, stateToProps)(CompanyDesignPage);
