import React, { Component } from 'react';
import { equals, assocPath, path, has, forEachObjIndexed, toLower, is } from 'ramda';
import { Form } from 'react-final-form';
import { Form as FormComponent, Alert } from 'antd';
import arrayMutators from 'final-form-arrays';
import { withAsyncHandlers } from 'react-async-client';
import styled from 'styled-components';
import { withState } from 'recompose';

import { getRequiredFields } from '../../utils/getRequiredFields';
import RequiredFieldsContext from '../contexts/RequiredFieldsContext';
import FormSubmittingContext from '../contexts/FormSubmittingContext';
import ERRORS, { DEFAULT_ERROR } from '../../constants/errors';
import InitialValuesContext from '../contexts/InitialValuesContext';
import FormServerErrorsContext from '../contexts/FormServerErrorsContext';

const AlertWrapper = styled.div`
    margin: 15px 0;
    position: absolute;
    bottom: 0;
    margin: 0;
    left: 0;
    right: 0;
    .ant-alert {
        padding: 4px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .ant-alert-message {
        color: #f5222d;
        font-size: 12px;
    }
`;

const getServerError = (props, action) => {
    const fields = path(['meta', 'error', 'data', 'errors'], props.formAction);
    const values = path(['requestAction', 'payload'], action);
    const fieldErrors = {};

    if (fields) {
        forEachObjIndexed((errors, field) => {
            errors.length && errors.forEach(error => {
                const template = ERRORS[toLower(error.message_template)];
                let message = error.message;

                if (template) {
                    message = template
                        .replace('$property', field)
                        .replace('$value', values[field]);
                }

                fieldErrors[field] = message;
            });
        }, fields);
    }

    return fieldErrors;
}

export default (WrappedComponent, formOptions = {}) => {
    const enableReinitialize = has('enableReinitialize', formOptions) ? formOptions.enableReinitialize : true;

    const FormWithHandlers = withAsyncHandlers(({ formAction }) => {
        return (formAction && formAction.dispatch && ({
            formAction: {
                successHandler: props => {
                    props.onSubmitSuccess && props.onSubmitSuccess(props);
                },
                errorHandler: (props, action) => {
                    props.setServerErrors(getServerError(props, action));
                    props.onSubmitFail && props.onSubmitFail(props);
                },
            }
        })) || {};
    })(WrappedComponent);

    class FormWrapper extends Component {
        constructor(props) {
            super(props);

            this.state = {
                initialValues: this.getInitialValues(props)
            };
        }

        componentDidMount() {
            const input = this.container.querySelector('input');

            input && input.focus();
        }

        componentDidUpdate(prev) {
            const initialValues = this.getInitialValues();

            if (!equals(initialValues, this.getInitialValues(prev)) && enableReinitialize) {
                this.setState({ initialValues });
            }
        }

        getInitialValues = (props = this.props) => {
            return formOptions.mapPropsToValues ? formOptions.mapPropsToValues(props) : {};
        }

        isSubmitting = () => {
            const { formAction } = this.props;

            return formAction && formAction.dispatch && formAction.meta.pending;
        }

        onSubmit = values => {
            const { formAction, setServerErrors } = this.props;
            const mapBeforeSubmit = this.props.mapBeforeSubmit || formOptions.mapBeforeSubmit;
            const dispatch = formAction.dispatch || formAction;

            setServerErrors({});

            if (!this.isSubmitting()) {
                dispatch(mapBeforeSubmit ? mapBeforeSubmit(values, this.props) : values, this.props);
            }
        }

        handleSubmit = (e, handleSubmit) => {
            handleSubmit(e);
        }

        validate = values => {
            const schema = this.getValidationSchema(values);

            if (!schema) {
                return {};
            }

            try {
                schema.validateSync(values, { abortEarly: false });
            } catch (e) {
                return (e.inner || []).reduce((errors, error) => {
                    let path = error.path.split(/\.|\].|\[/).map(p => isNaN(Number(p.replace(/\[|\]/g, ''))) ? p : Number(p.replace(/\[|\]/g, '')));
                    path = error.params.additionalPath ? path.concat(error.params.additionalPath) : path;
                    return assocPath(is(Array, error.value) ? ['_arrays', ...path] : path, error.message, errors);
                }, {});
            }
        }

        getValidationSchema = values => {
            const schema = formOptions.validationSchema;

            return schema ? (typeof schema === 'function' ? schema(this.props, values || {}) : schema) : null;
        }

        getError = () => {
            const error = path(['meta', 'error', 'data', 'error_description'], this.props.formAction);
            const networkError = path(['meta', 'error', 'origin', 'message'], this.props.formAction);

            return error || networkError;
        }

        renderError(error) {
            const status = path(['meta', 'error', 'status'], this.props.formAction);
            const message = status < 500 ? (ERRORS[error] || this.props.defaultError) : (ERRORS[error] || DEFAULT_ERROR);

            return message &&
                <AlertWrapper>
                    <Alert message={message} type='error' />
                </AlertWrapper>;
        }

        updateServerErrors = (props, action, mapErrors) => {
            const errors = getServerError(props, action);
            this.props.setServerErrors(mapErrors ? mapErrors(errors) : errors);
        }

        render() {
            const error = this.getError();
            const isSubmitting = this.isSubmitting();

            return (
                <InitialValuesContext.Provider value={this.state.initialValues}>
                    <FormServerErrorsContext.Provider value={this.props.serverErrors}>
                        <RequiredFieldsContext.Provider value={getRequiredFields(this.getValidationSchema())}>
                            <Form
                                subscription={{ submitting: true, invalid: true, submitFailed: true, errors: true, error: true, ...(formOptions.subscriptions || {}) }}
                                onSubmit={this.onSubmit}
                                validate={this.validate}
                                mutators={arrayMutators}
                                decorators={formOptions.decorators ? [formOptions.decorators] : null}
                                initialValues={this.state.initialValues}
                                render={props =>
                                    <div ref={node => this.container = node}>
                                        <FormComponent onFinish={e => this.handleSubmit(e, props.handleSubmit)} noValidate>
                                            { error && this.renderError(error) }
                                            <FormSubmittingContext.Provider value={isSubmitting}>
                                                <FormWithHandlers
                                                    {...this.props}
                                                    {...props}
                                                    updateServerErrors={this.updateServerErrors}
                                                    isSubmitting={isSubmitting} />
                                            </FormSubmittingContext.Provider>
                                        </FormComponent>
                                    </div>
                                }
                            />
                        </RequiredFieldsContext.Provider>
                    </FormServerErrorsContext.Provider>
                </InitialValuesContext.Provider>
            );
        }
    }

    return withState('serverErrors', 'setServerErrors', {})(FormWrapper);
}
