import React, { Component } from 'react';
import { Alert, Upload, message, Select } from 'antd';
import qs from 'qs';
import { contains, path } from 'ramda';
import { asyncConnect } from 'react-async-client';
import { InboxOutlined } from '@ant-design/icons';

import { getToken } from '../../utils/token';
import { questionsImportSuccess } from '../../actions/appActions';
import Modal from './Modal';
import { getUser, getCompanies } from '../../actions/asyncActions';
import { getImportErrorMessage } from '../../utils/import';
import { ADMIN_MODERATOR } from '../../constants/roles';

class QuestionsImportModal extends Component {
    state = {
        error: null,
        company: undefined
    };

    getSearchPath = () => {
        return qs.stringify({
            access_token: getToken(),
        }, { addQueryPrefix: true });
    }

    getDownloadLink = (importPath) => {
        return `/api${importPath}questions/import/headers.xlsx${this.getSearchPath()}`;
    }

    onChange = info => {
        const { close, questionsImportSuccess } = this.props;
        const { status } = info.file;

        switch(status) {
            case 'done':
                message.success('Вопросы были успешно импортированы');
                questionsImportSuccess();
                close();
                break;
            case 'error':
                this.setState({ error: path(['file', 'response', 'errors', 0], info) });
                message.error('Не удалось импортировать вопросы');
                break;
            default:
                return;
        }
    }

    renderError = () => {
        const { error } = this.state;

        if (!error) {
            return null;
        }

        return <Alert type='error' message={<div dangerouslySetInnerHTML={{ __html: getImportErrorMessage(error) }} />} style={{ marginBottom: 10 }} />;
    }

    onChangeCompany = company => this.setState({ company });

    render() {
        const { modal, getCompanies: { data, meta }, isAdmin, userCompany } = this.props;
        const { company } = this.state;
        const importPath = isAdmin ? '/' : `/companies/${userCompany}/`;
        const importRoute = isAdmin ? (company ? `/${company}` : '') : '';

        return <Modal
            {...modal}
            title='Импортирование вопросов'
            footer={null}>
            <Alert type='info' style={{ marginBottom: 15 }} message={
                <span>Для того чтобы загрузить вопросы вы можете использовать систему массовой загрузки, для этого <a href={this.getDownloadLink(importPath)} target='_blank' rel='noopener noreferrer' download>скачайте шаблон</a>, заполните его и загрузите в эту форму.</span>
            } />
            { this.renderError() }
            { isAdmin && (
                <Select
                    style={{ width: '100%', marginBottom: 15 }}
                    value={this.state.company}
                    onChange={this.onChangeCompany}
                    loading={meta.pending}
                    placeholder='Компания'
                    allowClear>
                    { (data.items || []).map(({ id, name }) =>
                        <Select.Option key={id} value={id}>
                            { name }
                        </Select.Option>
                    )}
                </Select>
            )}
            <Upload.Dragger
                action={`/api${importPath}questions/import${importRoute}${this.getSearchPath()}`}
                onChange={this.onChange}
                showUploadList={false}
                accept='.xlsx'>
                <p className='ant-upload-drag-icon'>
                    <InboxOutlined />
                </p>
                <p className='ant-upload-text'>Чтобы загрузить файл просто перетащите его сюда</p>
            </Upload.Dragger>
        </Modal>;
    }
}

const stateToProps = state => ({
    isAdmin: contains(getUser.selectData(state).role, ADMIN_MODERATOR),
    userCompany: getUser.selectData(state).company,
});

export default asyncConnect(({ isAdmin }) => ({
    getCompanies: getCompanies
        .withParams(() => ({ type: 'modal' }))
        .withPayload(() => ({
            q: {
                enabled: true
            }
        }))
        .withOptions({ dispatchOnMount: isAdmin, resetOnUnmount: true })
}), stateToProps, { questionsImportSuccess })(QuestionsImportModal);
