import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';

import Modal from './Modal';
import AdminForm from '../forms/AdminForm';

class ProfileModal extends Component {
    render() {
        const { modal, params } = this.props;

        return <Modal
            {...modal}
            title='Редактировать профиль'
            footer={null}>
            <AdminForm
                item={params} />
        </Modal>
    }
}

export default withAsyncActions({

})(ProfileModal);
