import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { path, pathEq, filter } from 'ramda';
import { FileOutlined } from '@ant-design/icons';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import { openSelectCategoryModal } from '../../../actions/modalActions';
import { getUser } from '../../../actions/asyncActions';

const Link = styled.span`
    color: #1890ff;
    transition: color 0.3s;
    cursor: pointer;
    line-height: 2rem;
    display: inline-block;
    &:hover {
        color: #40a9ff;
    }
`;

const getInitialText = category => {
    if (!category) {
        return '';
    }

    const getCategory = category => category ? `${category.name}${category.code ? ` [${category.code}]` : ''}` : '';

    const parent = path(['_embedded', 'parent'], category);
    const parentOfParent = path(['_embedded', 'parent', '_embedded', 'parent'], category);

    return `${parentOfParent ? `${getCategory(parentOfParent)} / ` : ''}${parent ? `${getCategory(parent)} / ` : ''}${getCategory(category)}`;
}

const getExpanded = category => {
    const parent = path(['_embedded', 'parent', 'id'], category);
    const parentOfParent = path(['_embedded', 'parent', '_embedded', 'parent', 'id'], category);

    return category ? filter(i => i, [parent, parentOfParent]) : []
}

class CategorySelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            text: getInitialText(props.category),
            expanded: getExpanded(props.category)
        };
    }

    componentDidUpdate(prev) {
        if (!pathEq(['category', 'id'], path(['category', 'id'], prev), this.props)) {
            this.setState({
                text: getInitialText(this.props.category),
                expanded: getExpanded(this.props.category)
            });
        }
    }

    onSelect = (value, expanded, text, { close }) => {
        this.props.onChange(value);
        this.setState({ text, expanded });
        close();
    }

    render() {
        const { input: { value }, company, disabled} = this.props;

        return disabled ? (
            <span><FileOutlined /> {this.state.text || '-'}</span>
        ) : <Link
            onClick={() => this.props.openSelectCategoryModal({
                checked: value,
                onSelect: this.onSelect,
                expanded: this.state.expanded,
                company
            })}>
            <FileOutlined /> { this.state.text || 'Выберите компетенцию' }
        </Link>;
    }
}

const stateToProps = state => {
    const user = getUser.selectData(state);

    return {
        company: user.ownQuestions ? user.company : null
    };
};

export default connect(stateToProps, { openSelectCategoryModal })(
    withFieldWrapper(CategorySelect)
);
