import { takeEvery } from 'redux-saga/effects';
import { asyncTakeFirst } from 'react-async-client';

import createAsyncAction from '../utils/createAsyncAction';
import * as actions from '../constants/actionTypes';
import * as handlers from './handlers';

export const postLogin = createAsyncAction(actions.POST_LOGIN, handlers.postLoginHandler);
export const postPda = createAsyncAction(actions.POST_PDA, handlers.postPdaHandler);
export const getUser = createAsyncAction(actions.GET_USER, handlers.getUserHandler);

export const postPasswordRecovery = createAsyncAction(actions.POST_PASSWORD_RECOVERY, handlers.postPasswordRecoveryHandler);
export const postResetPassword = createAsyncAction(actions.POST_RESET_PASSWORD, handlers.postResetPasswordHandler);

export const getCategories = createAsyncAction(actions.GET_CATEGORIES, handlers.getCategoriesHandler, {}, takeEvery);
export const postCategory = createAsyncAction(actions.POST_CATEGORY, handlers.postCategoryHandler);
export const putCategory = createAsyncAction(actions.PUT_CATEGORY, handlers.putCategoryHandler);
export const patchCategory = createAsyncAction(actions.PATCH_CATEGORY, handlers.patchCategoryHandler);
export const deleteCategory = createAsyncAction(actions.DELETE_CATEGORY, handlers.deleteCategoryHandler);

export const getQuestions = createAsyncAction(actions.GET_QUESTIONS, handlers.getQuestionsHandler);
export const deleteQuestion = createAsyncAction(actions.DELETE_QUESTION, handlers.deleteQuestionHandler);
export const putQuestion = createAsyncAction(actions.PUT_QUESTION, handlers.putQuestionHandler);
export const patchQuestion = createAsyncAction(actions.PATCH_QUESTION, handlers.patchQuestionHandler);
export const postQuestion = createAsyncAction(actions.POST_QUESTION, handlers.postQuestionHandler);
export const getQuestion = createAsyncAction(actions.GET_QUESTION, handlers.getQuestionHandler);

export const getAdmins = createAsyncAction(actions.GET_ADMINS, handlers.getAdminsHandler);
export const postAdmin = createAsyncAction(actions.POST_ADMIN, handlers.postAdminHandler);
export const putAdmin = createAsyncAction(actions.PUT_ADMIN, handlers.putAdminHandler);
export const patchAdmins = createAsyncAction(actions.PATCH_ADMINS, handlers.patchAdminsHandler);

export const getCompanies = createAsyncAction(actions.GET_COMPANIES, handlers.getCompaniesHandler);
export const patchCompanies = createAsyncAction(actions.PATCH_COMPANIES, handlers.patchCompaniesHandler);
export const patchCompany = createAsyncAction(actions.PATCH_COMPANY, handlers.patchCompanyHandler);
export const postCompany = createAsyncAction(actions.POST_COMPANY, handlers.postCompanyHandler);
export const putCompany = createAsyncAction(actions.PUT_COMPANY, handlers.putCompanyHandler);
export const putCompanyFavorite = createAsyncAction(actions.PUT_COMPANY_FAVORITE, handlers.putCompanyFavoriteHandler);
export const getCompany = createAsyncAction(actions.GET_COMPANY, handlers.getCompanyHandler);

export const getBankDetails = createAsyncAction(actions.GET_BANK_DETAILS, handlers.getBankDetailsHandler);
export const postBankDetails = createAsyncAction(actions.POST_BANK_DETAILS, handlers.postBankDetailsHandler);

export const getCompanyClients = createAsyncAction(actions.GET_COMPANY_CLIENTS, handlers.getCompanyClientsHandler);
export const postClient = createAsyncAction(actions.POST_CLIENT, handlers.postClientHandler);
export const putClient = createAsyncAction(actions.PUT_CLIENT, handlers.putClientHandler);
export const patchClients = createAsyncAction(actions.PATCH_CLIENTS, handlers.patchClientsHandler);

export const getCompanyEmployees = createAsyncAction(actions.GET_COMPANY_EMPLOYEES, handlers.getCompanyEmployeesHandler);
export const deleteCompanyEmployees = createAsyncAction(actions.DELETE_COMPANY_EMPLOYEES, handlers.deleteCompanyEmployeesHandler);
export const postCompanyEmployee = createAsyncAction(actions.POST_COMPANY_EMPLOYEE, handlers.postCompanyEmployeeHandler);
export const putCompanyEmployee = createAsyncAction(actions.PUT_COMPANY_EMPLOYEE, handlers.putCompanyEmploeeHandler);
export const getCompanyEmployee = createAsyncAction(actions.GET_COMPANY_EMPLOYEE, handlers.getCompanyEmployeeHandler);

export const getCompanyProjects = createAsyncAction(actions.GET_COMPANY_PROJECTS, handlers.getCompanyProjectsHandler);
export const patchCompanyProjects = createAsyncAction(actions.PATCH_COMPANY_PROJECTS, handlers.patchCompanyProjectsHandler);
export const postCompanyProject = createAsyncAction(actions.POST_COMPANY_PROJECT, handlers.postCompanyProjectHandler);
export const putCompanyProject = createAsyncAction(actions.PUT_COMPANY_PROJECT, handlers.putCompanyProjectHandler);
export const getCompanyProject = createAsyncAction(actions.GET_COMPANY_PROJECT, handlers.getCompanyProjectHandler);

export const getBusinessUnits = createAsyncAction(actions.GET_BUSINESS_UNITS, handlers.getBusinessUnitsHandler);

export const getSettingsTemplates = createAsyncAction(actions.GET_SETTINGS_TEMPLATES, handlers.getSettingsTemplatesHandler);
export const postSettingsTemplate = createAsyncAction(actions.POST_SETTINGS_TEMPLATE, handlers.postSettingsTemplateHandler);
export const putSettingsTemplate = createAsyncAction(actions.PUT_SETTINGS_TEMPLATE, handlers.putSettingsTemplateHandler);
export const deleteSettingsTemplate = createAsyncAction(actions.DELETE_SETTINGS_TEMPLATE, handlers.deleteSettingsTemplateHandler);

export const getCompanyTemplates = createAsyncAction(actions.GET_COMPANY_TEMPLATES, handlers.getCompanyTemplatesHandler);
export const postCompanyTemplate = createAsyncAction(actions.POST_COMPANY_TEMPLATE, handlers.postCompanyTemplateHandler);
export const putCompanyTemplate = createAsyncAction(actions.PUT_COMPANY_TEMPLATE, handlers.putCompanyTemplateHandler);
export const deleteCompanyTemplate = createAsyncAction(actions.DELETE_COMPANY_TEMPLATE, handlers.deleteCompanyTemplateHandler);

export const getSearch = createAsyncAction(actions.GET_SEARCH, handlers.getSearchHandler);

export const postCompanyTest = createAsyncAction(actions.POST_COMPANY_TEST, handlers.postCompanyTestHandler);
export const putCompanyTest = createAsyncAction(actions.PUT_COMPANY_TEST, handlers.putCompanyTestHandler);
export const putCompanyTests = createAsyncAction(actions.PUT_COMPANY_TESTS, handlers.putCompanyTestsHandler);
export const deleteCompanyTest = createAsyncAction(actions.DELETE_COMPANY_TEST, handlers.deleteCompanyTestHandler);
export const getCompanyTests = createAsyncAction(actions.GET_COMPANY_TESTS, handlers.getCompanyTestsHandler);
export const patchCompanyTest = createAsyncAction(actions.PATCH_COMPANY_TEST, handlers.patchCompanyTestHandler);

export const postCompanyRespondent = createAsyncAction(actions.POST_COMPANY_RESPONDENT, handlers.postCompanyRespondentHandler);
export const postCompanyRespondents = createAsyncAction(actions.POST_COMPANY_RESPONDENTS, handlers.postCompanyRespondentsHandler);
export const deleteCompanyRespondent = createAsyncAction(actions.DELETE_COMPANY_RESPONDENT, handlers.deleteCompanyRespondentHandler);
export const putCompanyRespondent = createAsyncAction(actions.PUT_COMPANY_RESPONDENT, handlers.putCompanyRespondentHandler);
export const deleteCompanyRespondents = createAsyncAction(actions.DELETE_COMPANY_RESPONDENTS, handlers.deleteCompanyRespondentsHandler);
export const getCompanyRespondent = createAsyncAction(actions.GET_COMPANY_RESPONDENT, handlers.getCompanyRespondentHandler);
export const getCompanyRespondentPdf = createAsyncAction(actions.GET_COMPANY_RESPONDENT_PDF, handlers.getCompanyRespondentPdfHandler, {}, asyncTakeFirst);
export const getCompanyRespondentsImport = createAsyncAction(actions.GET_COMPANY_RESPONDENTS_IMPORT, handlers.getCompanyRespondentsImportHandler);
export const postCompanyRespondentsImport = createAsyncAction(actions.POST_COMPANY_RESPONDENTS_IMPORT, handlers.postCompanyRespondentsImportHandler);
export const patchCompanyRespondent = createAsyncAction(actions.PATCH_COMPANY_RESPONDENT, handlers.patchCompanyRespondentHandler);
export const patchCompanyRespondents = createAsyncAction(actions.PATCH_COMPANY_RESPONDENTS, handlers.patchCompanyRespondentsHandler);

export const postCompanyMessages = createAsyncAction(actions.POST_COMPANY_MESSAGES, handlers.postCompanyMessagesHandler);

export const getComplexity = createAsyncAction(actions.GET_COMPLEXITY, handlers.getComplexityHandler);
export const putComplexity = createAsyncAction(actions.PUT_COMPLEXITY, handlers.putComplexityHandler);

export const getRespondents = createAsyncAction(actions.GET_RESPONDENTS, handlers.getRespondentsHandler, {}, takeEvery);

export const getProjectAnalyticsPercents = createAsyncAction(actions.GET_PROJECT_ANALITYCS_PERCENTS, handlers.getProjectAnalyticsPercentsHandler);
export const getProjectAnalyticsDistribution = createAsyncAction(actions.GET_PROJECT_ANALYTICS_DISTRIBUTION, handlers.getProjectAnalyticsDistributionHandler);
export const getProjectAnalyticsStatus = createAsyncAction(actions.GET_PROJECT_ANALYTICS_STATUS, handlers.getProjectAnalyticsStatusHandler, []);

export const getCommands = createAsyncAction(actions.GET_COMMANDS, handlers.getCommandsHandler);
export const postCommand = createAsyncAction(actions.POST_COMMAND, handlers.postCommandHandler);
export const cancelCommand = createAsyncAction(actions.CANCEL_COMMAND, handlers.cancelCommandHandler);

export const getCompanyRespondentStat = createAsyncAction(actions.GET_COMPANY_RESPONDENT_STAT, handlers.getCompanyRespondentStatHandler);

export const getTestTemplates = createAsyncAction(actions.GET_TEST_TEMPLATES, handlers.getTestTemplatesHandler);
export const getTestTemplate = createAsyncAction(actions.GET_TEST_TEMPLATE, handlers.getTestTemplateHandler);
export const deleteTestTemplates = createAsyncAction(actions.DELETE_TEST_TEMPLATES, handlers.deleteTestTemplatesHandler);
export const postTestTemplate = createAsyncAction(actions.POST_TEST_TEMPLATE, handlers.postTestTemplateHandler);
export const postTestTemplateWithId = createAsyncAction(actions.POST_TEST_TEMPLATE_WITH_ID, handlers.postTestTemplateWithIdHandler);
export const patchTestTemplate = createAsyncAction(actions.PATCH_TEST_TEMPLATE, handlers.patchTestTemplateHandler);
export const deleteTestTemplate = createAsyncAction(actions.DELETE_TEST_TEMPLATE, handlers.deleteTestTemplateHandler);

export const putCompanyTestStatus = createAsyncAction(actions.PUT_COMPANY_TEST_STATUS, handlers.putCompanyTestStatusHandler);

export const getExperts = createAsyncAction(actions.GET_EXPERTS, handlers.getExpertsHandler);
export const getExpert = createAsyncAction(actions.GET_EXPERT, handlers.getExpertHandler);
export const getExpertAudits = createAsyncAction(actions.GET_EXPERT_AUDITS, handlers.getExpertAuditsHandler);
export const postExpert = createAsyncAction(actions.POST_EXPERT, handlers.postExpertHandler);
export const putExpert = createAsyncAction(actions.PUT_EXPERT, handlers.putExpertHandler);

export const putCompanyProjectTimeSettings = createAsyncAction(actions.PUT_COMPANY_PROJECT_TIME_SETTINGS, handlers.putCompanyProjectTimeSettingsHandler);

export const getTestGroups = createAsyncAction(actions.GET_TEST_GROUPS, handlers.getTestGroupsHandler);
export const postTestGroup = createAsyncAction(actions.POST_TEST_GROUP, handlers.postTestGroupHandler);
export const putTestGroup = createAsyncAction(actions.PUT_TEST_GROUP, handlers.putTestGroupHandler);
export const deleteTestGroup = createAsyncAction(actions.DELETE_TEST_GROUP, handlers.deleteTestGroupHandler);

export const getTestTemplateChanges = createAsyncAction(actions.GET_TEST_TEMPLATE_CHANGES, handlers.getTestTemplateChangesHandler);

export const getTestTemplateVersions = createAsyncAction(actions.GET_TEST_TEMPLATE_VERSIONS, handlers.getTestTemplateVersionsHandler);

export const getPayments = createAsyncAction(actions.GET_PAYMENTS, handlers.getPaymentsHandler);
export const putPayment = createAsyncAction(actions.PUT_PAYMENT, handlers.putPaymentHandler);
export const deletePayment = createAsyncAction(actions.DELETE_PAYMENT, handlers.deletePaymentHandler);
export const putPaymentBankDetails = createAsyncAction(actions.PUT_PAYMENT_BANK_DETAILS, handlers.putPaymentBankDetailsHandlers);

export const getCompanyPayments = createAsyncAction(actions.GET_COMPANY_PAYMENTS, handlers.getCompanyPaymentsHandler);
export const postCompanyPayment = createAsyncAction(actions.POST_COMPANY_PAYMENT, handlers.postCompanyPaymentHandler);
export const deleteCompanyPayment = createAsyncAction(actions.DELETE_COMPANY_PAYMENT, handlers.deleteCompanyPaymentHandler);

export const getCompanyAccountOperations = createAsyncAction(actions.GET_COMPANY_ACCOUNT_OPERATIONS, handlers.getCompanyAccountOperationsHandler);

export const getRespondentsScreening = createAsyncAction(actions.GET_RESPONDENTS_SCREENING, handlers.getRespondentsScreeningHandler);
export const deleteRespondentsScreening = createAsyncAction(actions.DELETE_RESPONDENTS_SCREENING, handlers.deleteRespondentsScreeningHandler);
export const postRespondentsScreening = createAsyncAction(actions.POST_RESPONDENTS_SCREENING, handlers.postRespondentsScreeningHandler);
export const getRespondentScreening = createAsyncAction(actions.GET_RESPONDENT_SCREENING, handlers.getRespondentScreeningHandler);
export const putRespondentScreening = createAsyncAction(actions.PUT_RESPONDENT_SCREENING, handlers.putRespondentScreeningHandler);
export const deleteRespondentScreening = createAsyncAction(actions.DELETE_RESPONDENT_SCREENING, handlers.deleteRespondentScreeningHandler);

export const postTestTemplateRespondentMessages = createAsyncAction(actions.POST_TEST_TEMPLATE_RESPONDENT_MESSAGES, handlers.postTestTemplateRespondentMessagesHandler);

export const postTestTemplateAuditMessages = createAsyncAction(actions.POST_TEST_TEMPLATE_AUDIT_MESSAGES, handlers.postTestTemplateAuditMessagesHandler);

export const getTestTemplateAudits = createAsyncAction(actions.GET_TEST_TEMPLATE_AUDITS, handlers.getTestTemplateAuditsHandler);
export const getTestTemplateAudit = createAsyncAction(actions.GET_TEST_TEMPLATE_AUDIT, handlers.getTestTemplateAuditHandler);
export const postTestTemplateAudit = createAsyncAction(actions.POST_TEST_TEMPLATE_AUDIT, handlers.postTestTemplateAuditHandler);
export const deleteTestTemplateAudits = createAsyncAction(actions.DELETE_TEST_TEMPLATE_AUDITS, handlers.deleteTestTemplateAuditsHandler);
export const deleteTestTemplateAudit = createAsyncAction(actions.DELETE_TEST_TEMPLATE_AUDIT, handlers.deleteTestTemplateAuditHandler);

export const getProjectStatCompetencies = createAsyncAction(actions.GET_PROJECT_STAT_COMPETENCIES, handlers.getProjectStatCompetenciesHandler, []);
export const getProjectStatIntegral = createAsyncAction(actions.GET_PROJECT_STAT_INTEGRAL, handlers.getProjectStatIntegralHandler, []);

export const postCompanyTestRestart = createAsyncAction(actions.POST_COMPANY_TEST_RESTART, handlers.postCompanyTestRestartHandler);
export const postCompanyTestsRestart = createAsyncAction(actions.POST_COMPANY_TESTS_RESTART, handlers.postCompanyTestsRestartHandler);
export const postCompanyTestFinish = createAsyncAction(actions.POST_COMPANY_TEST_FINISH, handlers.postCompanyTestFinishHandler);

export const putCompanyPaymentType = createAsyncAction(actions.PUT_COMPANY_PAYMENT_TYPE, handlers.putCompanyPaymentTypeHandler);
export const putCompanyEnabled = createAsyncAction(actions.PUT_COMPANY_ENABLED, handlers.putCompanyEnabledHandler);
export const putCompaniesEnabled = createAsyncAction(actions.PUT_COMPANIES_ENABLED, handlers.putCompaniesEnabledHandler);

export const getProjects = createAsyncAction(actions.GET_PROJECTS, handlers.getProjectsHandler);

export const getCompanyTestQuestions = createAsyncAction(actions.GET_COMPANY_TEST_QUESTIONS, handlers.getCompanyTestQuestionsHandler);
export const putCompanyTestQuestions = createAsyncAction(actions.PUT_COMPANY_TEST_QUESTIONS, handlers.putCompanyTestQuestionsHandler);
export const patchCompanyTestQuestions = createAsyncAction(actions.PATCH_COMPANY_TEST_QUESTIONS, handlers.patchCompanyTestQuestionsHandler);

export const getDistributions = createAsyncAction(actions.GET_DISTRIBUTIONS, handlers.getDistributionsHandler);
export const getDistribution = createAsyncAction(actions.GET_DISTRIBUTION, handlers.getDistributionHandler);
export const postDistribution = createAsyncAction(actions.POST_DISTRIBUTION, handlers.postDistributionHandler);
export const putDistribution = createAsyncAction(actions.PUT_DISTRIBUTION, handlers.putDistributionHandler);
export const deleteDistribution = createAsyncAction(actions.DELETE_DISTRIBUTION, handlers.deleteDistributionHandler);
export const getDefaultDistribution = createAsyncAction(actions.GET_DEFAULT_DISTRIBUTION, handlers.getDefaultDistributionHandler);

export const getCategoryDistribution = createAsyncAction(actions.GET_CATEGORY_DISTRIBUTION, handlers.getCategoryDistributionHandler, {}, takeEvery);
export const getCompanyCategoryDistribution = createAsyncAction(actions.GET_COMPANY_CATEGORY_DISTRIBUTION, handlers.getCompanyCategoryDistributionHandler, {}, takeEvery);

export const putCategoryRecommendations = createAsyncAction(actions.PUT_CATEGORY_RECOMMENDATIONS, handlers.putCategoryRecommendationsHandler);
export const getCategoryRecommendations = createAsyncAction(actions.GET_CATEGORY_RECOMMENDATIONS, handlers.getCategoryRecommendationsHandler, {}, takeEvery);

export const postEnsiContracts = createAsyncAction(actions.POST_ENSI_CONTRACTS, handlers.postEnsiContractsHandler);

export const getSettings = createAsyncAction(actions.GET_SETTINGS, handlers.getSettingsHandler);
export const putSettings = createAsyncAction(actions.PUT_SETTINGS, handlers.putSettingsHandler);

export const getAcceptors = createAsyncAction(actions.GET_ACCEPTORS, handlers.getAcceptorsHandler);
export const postAcceptor = createAsyncAction(actions.POST_ACCEPTOR, handlers.postAcceptorHandler);
export const putAcceptor = createAsyncAction(actions.PUT_ACCEPTOR, handlers.putAcceptorHandler);
export const deleteAcceptor = createAsyncAction(actions.DELETE_ACCEPTOR, handlers.deleteAcceptorHandler);

export const messageHistoryContractor = createAsyncAction(actions.GET_MESSAGE_HISTORY_CONTRACTOR, handlers.getMessageHistoryContractorHandler);
export const messageHistoryPayment = createAsyncAction(actions.GET_MESSAGE_HISTORY_PAYMENT, handlers.getMessageHistoryPaymentHandler);

export const getQuestionnaries = createAsyncAction(actions.GET_QUESTIONNARIES, handlers.getQuestionnariesHandler);
export const getQuestionnariesReportTypes = createAsyncAction(actions.GET_QUESTIONNARIES_REPORT_TYPES, handlers.getQuestionnariesReportTypesHandler, []);
export const postQuestionnary = createAsyncAction(actions.POST_QUESTIONNARY, handlers.postQuestionnaryHandler);
export const getQuestionnary = createAsyncAction(actions.GET_QUESTIONNARY, handlers.getQuestionnaryHandler);
export const putQuestionnary = createAsyncAction(actions.PUT_QUESTIONNARY, handlers.putQuestionnaryHandler);
export const deleteQuestionnary = createAsyncAction(actions.DELETE_QUESTIONNARY, handlers.deleteQuestionnaryHandler);
export const patchQuestionnary = createAsyncAction(actions.PATCH_QUESTIONNARY, handlers.patchQuestionnaryHandler);

export const getSurveys = createAsyncAction(actions.GET_SURVEYS, handlers.getSurveysHandler);
export const getCompanySurveys = createAsyncAction(actions.GET_COMPANY_SURVEYS, handlers.getCompanySurveysHandler);
export const patchCompanySurveys = createAsyncAction(actions.PATCH_COMPANY_SURVEYS, handlers.patchCompanySurveysHandler);
export const postSurvey = createAsyncAction(actions.POST_SURVEY, handlers.postSurveyHandler);
export const putSurvey = createAsyncAction(actions.PUT_SURVEY, handlers.putSurveyHandler);
export const patchSurvey = createAsyncAction(actions.PATCH_SURVEY, handlers.patchSurveyHandler);
export const deleteSurvey = createAsyncAction(actions.DELETE_SURVEY, handlers.deleteSurveyHandler);
export const getSurvey = createAsyncAction(actions.GET_SURVEY, handlers.getSurveyHandler);
export const getSurveyRespondent = createAsyncAction(actions.GET_SURVEY_RESPONDENT, handlers.getSurveyRespondentHandler);
export const getSurveyRespondentCustomerFocus = createAsyncAction(actions.GET_SURVEY_RESPONDENT_CUSTOMER_FOCUS, handlers.getSurveyRespondentCustomerFocusHandler);
export const getSurveyRespondents = createAsyncAction(actions.GET_SURVEY_RESPONDENTS, handlers.getSurveyRespondentsHandler);
export const postSurveyRespondent = createAsyncAction(actions.POST_SURVEY_RESPONDENT, handlers.postSurveyRespondentHandler);
export const postSurveyRespondents = createAsyncAction(actions.POST_SURVEY_RESPONDENTS, handlers.postSurveyRespondentsHandler);
export const patchSurveyRespondent = createAsyncAction(actions.PATCH_SURVEY_RESPONDENT, handlers.patchSurveyRespondentHandler);
export const patchSurveyRespondents = createAsyncAction(actions.PATCH_SURVEY_RESPONDENTS, handlers.patchSurveyRespondentsHandler);
export const putSurveyRespondent = createAsyncAction(actions.PUT_SURVEY_RESPONDENT, handlers.putSurveyRespondentHandler);
export const deleteSurveyRespondent = createAsyncAction(actions.DELETE_SURVEY_RESPONDENT, handlers.deleteSurveyRespondentHandler);
export const deleteSurveyRespondents = createAsyncAction(actions.DELETE_SURVEY_RESPONDENTS, handlers.deleteSurveyRespondentsHandler);
export const postSurveyCompanyMessages = createAsyncAction(actions.POST_SURVEY_COMPANY_MESSAGES, handlers.postSurveyCompanyMessagesHandler);
export const getSurveyRespondentDriverPlus = createAsyncAction(actions.GET_SURVEY_RESPONDENT_DRIVER_PLUS, handlers.getSurveyRespondentDriverPlusHandler);
export const getSurveyRespondentDriverPlusIpsative = createAsyncAction(actions.GET_SURVEY_RESPONDENT_DRIVER_PLUS_IPSATIVE, handlers.getSurveyRespondentDriverPlusIpsativeHandler);

export const putCompanySettings = createAsyncAction(actions.PUT_COMPANY_SETTINGS, handlers.putCompanySettingsHandler);

export const deleteProject = createAsyncAction(actions.DELETE_PROJECT, handlers.deleteProjectHandler);
export const deleteProjects = createAsyncAction(actions.DELETE_PROJECTS, handlers.deleteProjectsHandler);
export const deleteCompanyProject = createAsyncAction(actions.DELETE_COMPANY_PROJECT, handlers.deleteCompanyProjectHandler);
export const deleteCompanyProjects = createAsyncAction(actions.DELETE_COMPANY_PROJECTS, handlers.deleteCompanyProjectsHandler);

export const getRespondentReport = createAsyncAction(actions.GET_RESPONDENT_REPORT, handlers.getRespondentReportHandler);
