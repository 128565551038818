import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import { Badge, Button, Popconfirm, Switch, Tooltip, message } from 'antd';
import { pathOr, contains, omit } from 'ramda';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { QuestionCircleOutlined, PlusOutlined, ExportOutlined, DeleteOutlined } from '@ant-design/icons';

import { getUser, getSurveys, deleteSurvey } from '../../../actions/asyncActions';
import { openSurveyModal } from '../../../actions/modalActions';
import { getUrlParams } from '../../../utils/urlParams';
import TableList from '../table/TableList';
import ProjectsFilter from '../../forms/filters/ProjectsFilter';
import { POST_SURVEY, PUT_SURVEY, DELETE_SURVEY } from '../../../constants/actionTypes';
import CompanySelector from '../projects/CompanySelector';
import { ADMIN_MODERATOR, MANAGERS, SENIOR_MANAGER } from '../../../constants/roles';

const Wrapper = styled.div`
    display: flex;
    align-items: baseline;
    .ant-badge-count {
        background: #1890ff;
    }
    .link {
      font-weight: bold;
    }
`;

const SurveysWrapper = styled.div`
    width: ${({ isManager }) => isManager ? 100 : 70}%;
    background: #fff;
    margin-left: ${({ isManager }) => isManager ? 0 : 30}px;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    div > .ant-row{
        border-radius: 0;
        border: 0;
        padding: 10px 15px;
        .ant-row{
            padding: 0;
        }
    }
    div .ant-table-wrapper{
        border: 0;
    }
    @media (max-width: 767px) {
        margin-left: 10px;
        .ant-btn {
            span:not(.anticon) {
              display: none;
            }
        }
    }
`;

const ProjectsHeader = styled.h1`
    background: #fafafa;
    border-bottom: 1px solid #eaeaea;
    padding: 6px 15px;
    min-height: 45px;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > div{
        width: 100%;
        position: relative;
    }
    .ant-btn {
      @media (max-width: 767px) {
        span:not(.anticon) {
          display: none;
        }
      }
    }
`;

class Surveys extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showMyProjects: props.isClientManager ? JSON.parse(localStorage.getItem('showMyProjects')) : props.isManager
        };
    }

    getColumns = () => {
        return [
            {
                title: 'Название',
                key: 'name',
                dataIndex: 'name',
                sorter: true,
                render: (name, { id }) => <Link className='link' to={`/surveys/${id}/info`}>{ name }</Link>
            },
            {
                title: 'Опросник',
                key: 'questionnaire',
                dataIndex: ['_embedded', 'questionnaire', 'name'],
                render: (name, { questionnaire }) => this.props.isAdmin ? <Link className='link' to={`/questionnaries/${questionnaire}`}>{ name }</Link> : name
            },
            {
                title: 'Кол-во вопросов',
                dataIndex: 'drivers',
                key: 'amount',
                render: drivers => {
                    const questionsAmount = (drivers || []).reduce((res, cur) => res + (cur.questions || []).length, 0);

                    return <span><QuestionCircleOutlined /> { questionsAmount }</span>;
                }
            },
            {
                key: 'actions',
                align: 'right',
                render: item =>
                    <Button.Group>
                        <Tooltip title='Экспорт'>
                            <Button
                                icon={<ExportOutlined />}
                                onClick={() => this.exportSurvey(item)} />
                        </Tooltip>
                        <Popconfirm
                            title='Вы уверены, что хотите удалить опрос?'
                            okText='Да'
                            cancelText='Нет'
                            placement='left'
                            onConfirm={() => this.props.deleteSurvey.dispatch(item.id)}>
                            <Button
                                icon={<DeleteOutlined />}
                                danger />
                        </Popconfirm>
                    </Button.Group>
            }
        ]
    }

    getSelectedCompany = () => {
        return this.props.isManager ? this.props.user.company : getUrlParams(this.props.location).company;
    }

    onChangeAll = all => {
        const my = !all;

        localStorage.setItem('showMyProjects', my);
        this.setState({ showMyProjects: my });
    }

    exportSurvey = item => {
        const data = omit(['company', 'id', '_embedded'], item);
        const file = new Blob([JSON.stringify(data, null, 2)], { type: 'text/json' });

        this.link.href = URL.createObjectURL(file);
        this.link.download = `${item.name}.json`;
        this.link.click();
    }

    renderButtons = () => ' ';

    renderSurveys = () => {
        const { isManager, surveysCount, openSurveyModal, user, isClientManager } = this.props;
        const company = this.getSelectedCompany();

        return <SurveysWrapper isManager={isManager} className='project-list'>
            <ProjectsHeader>
                <div>
                    Опросники <Badge count={surveysCount} style={{ backgroundColor: '#f54d2e', marginRight: 10 }} />
                    { isClientManager &&
                        <span>
                            <Switch
                                checkedChildren='все'
                                unCheckedChildren='мои'
                                checked={!this.state.showMyProjects && isManager}
                                onChange={this.onChangeAll} />
                        </span>
                    }
                </div>
                <Button
                    type='primary'
                    icon={<PlusOutlined />}
                    disabled={!company}
                    onClick={() => openSurveyModal({ company })}>
                    <span className='hide-mobile'>Добавить</span>
                </Button>
            </ProjectsHeader>
            <TableList
                payload={{ company, collaborators: isManager && this.state.showMyProjects ? [user.id] : null }}
                action={getSurveys}
                columns={this.getColumns()}
                filterForm={ProjectsFilter}
                staticFilter={{ company }}
                defaultSort={{
                    sort_by: 'id',
                    sort_order: 'desc'
                }}
                buttons={this.renderButtons()}
                refreshActions={[
                    POST_SURVEY,
                    PUT_SURVEY,
                    DELETE_SURVEY,
                ]} />
            <a href='export' ref={node => this.link = node} download style={{ display: 'none' }}>export</a>
        </SurveysWrapper>
    }

    render() {
        return <Wrapper>
            { !this.props.isManager && <CompanySelector /> }
            { this.renderSurveys() }
        </Wrapper>;
    }
}

const stateToProps = state => {
    const role = getUser.selectData(state).role;

    return {
        surveysCount: pathOr(0, ['_meta', 'count'], getSurveys.selectData(state)),
        isManager: contains(getUser.selectData(state).role, MANAGERS),
        isAdmin: contains(getUser.selectData(state).role, ADMIN_MODERATOR),
        isClientManager: role === SENIOR_MANAGER,
        user: getUser.selectData(state),
        surveys: pathOr([], ['items'], getSurveys.selectData(state))
    };
};

export default asyncConnect({
    deleteSurvey: deleteSurvey
        .withSuccessHandler(() => message.success('Опрос успешно удален'))
        .withErrorHandler(() => message.error('Не удалось удалить опрос'))
        .withOptions({ resetOnUnmount: true })
}, stateToProps, { openSurveyModal })(Surveys);
