import React, { Component } from 'react';
import { message } from 'antd';
import { withAsyncActions } from 'react-async-client';
import { pluralize } from 'numeralize-ru';
import { pathOr } from 'ramda';

import Modal from './Modal';
import LetterForm from '../forms/LetterForm';
import { postTestTemplateAuditMessages, getTestTemplateAudits } from '../../actions/asyncActions';
import { AUDIT_TEMPLATE_VARIABLES } from '../../constants/companies';

class TestTemplateRespondentMessagesModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            count: pathOr([], ['params', 'item', 'audits'], props).length
        };
    }

    setCount = count => this.setState({ count });

    render() {
        const { modal, params: { item }, postTestTemplateAuditMessages } = this.props;
        const { count } = this.state;

        return <Modal
            {...modal}
            title={`Отправить письмо ${count} ${pluralize(count, 'эксперту', 'экспертам', 'экспертам')}`}
            footer={null}>
            <LetterForm
                formAction={postTestTemplateAuditMessages}
                respondentsAction={getTestTemplateAudits}
                item={item}
                contentOptions={AUDIT_TEMPLATE_VARIABLES}
                getOptionItem={pathOr({}, ['_embedded', 'expert'])}
                setCount={this.setCount}
                requiredAudits
                respondentsField='audits'
                respondentsFieldTitle='Эксперты' />
        </Modal>
    }
}

export default withAsyncActions({
    postTestTemplateAuditMessages: postTestTemplateAuditMessages
        .withSuccessHandler(({ close, params: { clearSelected } }) => {
            message.success('Письмо было успешно отправлено');
            clearSelected();
            close();
        })
        .withErrorHandler(() => message.error('Не удалось отправить письмо'))
        .withOptions({ resetOnUnmount: true })
})(TestTemplateRespondentMessagesModal);
