import React, { Component, Fragment } from 'react';
import { prop } from 'ramda';
import * as yup from 'yup';
import { Field } from 'react-final-form';

import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import Input from './formComponents/Input';

class AcceptorForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='name'
                component={Input}
                label='ФИО' />
            <Field
                name='reason'
                component={Input}
                label='На основании' />
            <SubmitButton type='primary'>Сохранить</SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    name: yup.string().nullable().required(),
    reason: yup.string().nullable().required()
});

export default withFormWrapper(AcceptorForm, {
    mapPropsToValues: prop('item'),
    validationSchema
});
