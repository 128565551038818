import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import { putCompanyProject } from '../../actions/asyncActions';
import ProjectNameStep from '../forms/project/ProjectNameStep';
import SubmitButton from '../forms/formComponents/SubmitButton';
import Modal from './Modal';

class ProjectNameModal extends Component {
    render() {
        const { modal, params, putCompanyProject } = this.props;

        return <Modal
            {...modal}
            footer={null}
            title='Редактировать тест'>
            <ProjectNameStep
                formAction={putCompanyProject}
                item={params}
                company={params.company}
                submitBtn={<SubmitButton type='primary'>Сохранить</SubmitButton>} />
        </Modal>;
    }
}

export default withAsyncActions({
    putCompanyProject: putCompanyProject
        .withSuccessHandler(({ close }) => {
            message.success('Тест успешно сохранен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось сохранить тест'))
        .withOptions({ resetOnUnmount: true })
})(ProjectNameModal);
