import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import { postCompanyRespondent, putCompanyRespondent } from '../../actions/asyncActions';
import CompanyRespondentForm from '../forms/CompanyRespondentForm';
import Modal from './Modal';

class CompanyRespondentModal extends Component {
    render() {
        const { modal, params: { tests, hideTests, ...data }, postCompanyRespondent, putCompanyRespondent } = this.props;

        return <Modal
            {...modal}
            title={data.id ? 'Редактировать респондента' : 'Добавить респондента'}
            footer={null}>
            <CompanyRespondentForm
                formAction={data.id ? putCompanyRespondent : postCompanyRespondent}
                item={data}
                tests={tests}
                showTestsField={!hideTests} />
        </Modal>;
    }
}

export default withAsyncActions({
    postCompanyRespondent: postCompanyRespondent
        .withSuccessHandler(({ close }) => {
            message.success('Респондент успешно добавлен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось добавить респондента'))
        .withOptions({ resetOnUnmount: true }),
    putCompanyRespondent: putCompanyRespondent
        .withSuccessHandler(({ close }) => {
            message.success('Респондент успешно изменен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось изменить респондента'))
        .withOptions({ resetOnUnmount: true })
})(CompanyRespondentModal);
