import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { asyncConnect } from 'react-async-client';
import { pathOr, path, find, propEq, contains } from 'ramda';
import { Badge, Tooltip, Button, message, Popconfirm, Dropdown, Menu } from 'antd';
import { withState } from 'recompose';
import { Link } from 'react-router-dom';
import { pluralize } from 'numeralize-ru';
import qs from 'qs';
import { FileOutlined, LeftOutlined, RightOutlined, SyncOutlined, StepForwardOutlined, DeleteOutlined, PlusOutlined, DownOutlined, SettingOutlined, EyeOutlined } from '@ant-design/icons';

import Categories, { Header } from './Categories';
import Table from '../table/TableList';
import { getQuestions, deleteQuestion, patchQuestion, getCategories, getUser } from '../../../actions/asyncActions';
import { openSelectCategoryModal, openQuestionsSettingsModal, openQuestionsImportModal, openQuestionPreviewModal } from '../../../actions/modalActions';
import DateFormat from '../table/DateFormat';
import Complexity from '../table/Complexity';
import { DELETE_QUESTION, PATCH_QUESTION, DELETE_CATEGORY } from '../../../constants/actionTypes';
import QuestionsFilter from '../../forms/filters/QuestionsFilter';
import StringWithoutStyles from '../table/StringWithoutStyles';
import { QUESTION_LEVELS } from '../../../constants/questions';
import { getFilters } from '../../../utils/urlParams';
import { getToken } from '../../../utils/token';
import { ADMIN, ADMIN_MODERATOR } from '../../../constants/roles';

export const Wrapper = styled.div`
    display: flex;
`;

const CategoryAndComplexity = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    .anticon-file {
        margin-right: 5px;
    }
`;

export const CategoriesWrapper = styled.div`
    display: flex;
    width: ${({ collapsed }) => collapsed ? 100 : 35}%;
`;

export const TableWrapper = styled.div`
    width: ${({ collapsed }) => collapsed ? 0 : 65}%;
    height: ${({ collapsed }) => collapsed ? 0 : 'auto'};
    height: calc(100vh - 40px - 64px);
    overflow: hidden;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    div .ant-table-wrapper,.ant-row{
        border: 0;
    }
    .ant-row{
        border-radius: 0;
    }
    .ant-pagination{
        border-top: 1px solid #e8e8e8;
    }
    @media (max-width: 896px) {
        height: calc(100vh - 20px - 64px);
        & > div > .ant-row {
            padding: 5px 15px;
        }
    }
`;

export const Toggler = styled.div`
    width: 30px;
    height: 100%;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        color: rgba(0, 0, 0, 0.85);
    }
`;

export const QuestionsHeader = styled(Header)`
    justify-content: space-between;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    padding: 6px 15px;
    min-height: 45px;
`;

class Questions extends Component {
    state = {
        collapsed: false
    };

    openPreview = (item, index) => {
        this.props.openQuestionPreviewModal({ item, pagination: true, offset: this.props.questionsOffset + index, count: this.props.questionsCount });
    }

    getColumns = () => {
        return [
            {
                title: 'Вопрос',
                key: 'title',
                dataIndex: 'title',
                render: (title, { id }) => <Link to={`/questions/${id}`}><StringWithoutStyles text={title || '-'} /></Link>
            },
            {
                key: 'levels',
                width: 120,
                filters: QUESTION_LEVELS.map(i => ({ text: <span><Complexity level={i.id} /> { i.value }</span>, value: i.id })),
                render: item =>
                    <Fragment>
                        <CategoryAndComplexity>
                            <Tooltip title={`${path(['_embedded', 'category', 'name'], item)}${path(['_embedded', 'category', 'name'], item) ? ` [${path(['_embedded', 'category', 'code'], item)}]`: ''}`}>
                                <FileOutlined />
                            </Tooltip>
                            <Complexity level={item.level} />
                            { !item.company && (
                                <svg width="33" height="6" viewBox="0 0 33 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_605_7221)">
                                    <path d="M23.7323 4.16572C22.9755 4.16572 22.4251 3.66515 22.4251 2.98475C22.4251 2.30418 22.9755 1.80395 23.7323 1.80395C24.4662 1.80395 25.0394 2.30435 25.0394 2.98475C25.0394 3.66532 24.489 4.16572 23.7323 4.16572ZM23.7323 0.342865C22.0122 0.342865 20.6592 1.50378 20.6592 2.98475C20.6592 4.46589 22.0122 5.60675 23.7323 5.60675C25.4523 5.60675 26.8053 4.44584 26.8053 2.98475C26.8053 1.50361 25.4523 0.342865 23.7323 0.342865ZM4.0553 4.16572C3.29846 4.16572 2.74807 3.66515 2.74807 2.98475C2.74807 2.30418 3.29846 1.80395 4.0553 1.80395C4.78916 1.80395 5.36234 2.30435 5.36234 2.98475C5.36234 3.66532 4.81195 4.16572 4.0553 4.16572ZM5.29359 0.883208C4.90368 0.502979 4.37646 0.342865 3.82587 0.342865C2.24345 0.342865 0.982178 1.50378 0.982178 2.98475C0.982178 3.64526 1.23439 4.26566 1.71603 4.74601C2.24345 5.28652 3.06903 5.60658 3.91761 5.60658C4.42223 5.60658 4.99541 5.40652 5.29359 5.08629V5.50664H7.01371V0.442979H5.29359V0.883208ZM32.6072 0.442979H30.5201L29.5341 1.66389V0.442979H27.7682V5.50664H29.5341V2.26424H31.1622L32.6072 0.442979ZM18.1595 4.16572C17.3567 4.16572 16.7835 3.68521 16.7835 2.98475C16.7835 2.30418 17.3567 1.80395 18.1135 1.80395C18.5085 1.80562 18.8871 1.94209 19.1683 2.18418L20.1316 0.983322C19.5653 0.570669 18.8513 0.344077 18.1135 0.342865C16.3706 0.342865 14.9946 1.50378 14.9946 2.98475C14.9946 4.48595 16.3248 5.60675 18.0905 5.60675C18.9391 5.60675 19.6272 5.38646 20.2233 4.94624L19.2602 3.72532C19.0078 3.94544 18.6179 4.16572 18.1595 4.16572ZM11.7836 0.342865C11.279 0.342865 10.4304 0.562979 10.1324 0.883208V0.442979H8.41228V5.50664H10.1782V2.30435C10.3844 2.08406 10.8433 1.80378 11.3937 1.80378C11.6919 1.80378 11.9441 1.88401 12.1275 2.02406C12.265 2.14406 12.4025 2.34429 12.4025 2.82464V5.50664H14.1684V2.26424C14.1684 0.983322 13.091 0.342865 11.7838 0.342865H11.7836Z" fill="#F54D2E"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_605_7221">
                                    <rect width="33" height="6" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            )}
                        </CategoryAndComplexity>
                        <Link to={`/questions/${item.id}`}>
                            <DateFormat date={item.updatedAt} tooltip='Дата последнего редактирования' />
                        </Link>
                        <DateFormat date={item.refreshDate} icon={<SyncOutlined />} tooltip='Вопрос требует обновления' />

                    </Fragment>
            },
            {
                key: 'preview',
                width: 60,
                render: (item, _, index) => {
                    return <Button onClick={() => this.openPreview(item, index)} icon={<EyeOutlined />} />;
                }
            }
        ];
    }

    onSelectCategory = value => {
        this.props.patchQuestion.dispatch({
            items: this.props.selected,
            data: [{ op: 'replace', path: '/category', value }]
        });
    }

    renderTableButtons = () => {
        const { selected, deleteQuestion, openSelectCategoryModal } = this.props;

        return <Button.Group>
            <Button
                icon={<StepForwardOutlined />}
                type='primary'
                disabled={!selected.length}
                onClick={() => openSelectCategoryModal({
                    title: `Выберите компетенцию, в которую перенести ${selected.length} ${pluralize(selected.length, 'вопрос', 'вопроса', 'вопросов')}`,
                    onSelect: this.onSelectCategory
                })}>
                <span className='hide-mobile'>Перенести</span>
            </Button>
            { selected.length ?
                <Popconfirm
                    onConfirm={() => deleteQuestion.dispatch(selected)}
                    title='Вы уверены что хотите удалить выделенные вопросы?'
                    cancelText='Нет'
                    okText='Да'>
                    <Button icon={<DeleteOutlined />} type='danger'>
                        <span className='hide-mobile'>Удалить</span>
                    </Button>
                </Popconfirm> :
                <Button icon={<DeleteOutlined />} type='danger' disabled>
                    <span className='hide-mobile'>Удалить</span>
                </Button>
            }
        </Button.Group>;
    }

    addQuestion = () => {
        const { history, category } = this.props;

        history.push('/questions/add', { category });
    }

    getDownloadLink = () => {
        const { location, user, category } = this.props;
        const filters = getFilters(location) || {};
        const searchPath = qs.stringify({
            q: JSON.stringify({
                ...filters,
                company: user.role === ADMIN ? filters.company : (category && !category.company ? undefined : user.company)
            }),
            access_token: getToken()
        }, { addQueryPrefix: true, strictNullHandling: true });

        return user.role === ADMIN ? `/api/questions.xlsx${searchPath}` : `/api/companies/${user.company}/questions.xlsx${searchPath}`;
    }

    getMenu = () => {
        const { openQuestionsImportModal } = this.props;

        return <Menu>
            <Menu.Item key='import' onClick={() => openQuestionsImportModal()}>
                Импорт
            </Menu.Item>
            <Menu.Item key='export'>
                <a href={this.getDownloadLink()} target='_blank' rel='noopener noreferrer' download>Экспорт</a>
            </Menu.Item>
        </Menu>;
    }

    renderButtons = () => {
        const { isAdmin, category } = this.props;

        return <Button.Group>
            <Button type='primary' icon={<PlusOutlined />} disabled={!isAdmin && category && !category.company} onClick={this.addQuestion}>
                <span className='hide-mobile'>Добавить</span>
            </Button>
            <Dropdown trigger={['click']} overlay={this.getMenu()}>
                <Button icon={<DownOutlined />} />
            </Dropdown>
        </Button.Group>;
    }

    setSelected = (selected, rows) => {
        this.props.setSelected(this.props.isAdmin
            ? selected
            : (rows || []).filter(item => item.company).map(item => item.id)
        );
    };

    toggleCollapsed = () => this.setState(prev => ({ collapsed: !prev.collapsed }));

    render() {
        const { isAdmin, filters, user, category, questionsCount, openQuestionsSettingsModal, selected } = this.props;
        const { collapsed } = this.state;

        return <Wrapper>
            <CategoriesWrapper collapsed={collapsed}>
                <Categories />
                <Toggler onClick={this.toggleCollapsed}>
                    {collapsed ? <LeftOutlined /> : <RightOutlined />}
                </Toggler>
            </CategoriesWrapper>
            <TableWrapper collapsed={collapsed}>
                <QuestionsHeader>
                    <span>Вопросы <Badge style={{ backgroundColor: '#f54d2e' }} count={questionsCount} overflowCount={1000000} /></span>
                    <div>
                        { this.renderButtons() }
                        { user.role === ADMIN &&
                            <Button icon={<SettingOutlined />} shape='circle' onClick={() => openQuestionsSettingsModal()} style={{ marginLeft: 10, paddingTop: 3 }} />
                        }
                    </div>
                </QuestionsHeader>
                <Table
                    scroll={ 'calc(100vh - 315px)' }
                    action={getQuestions}
                    columns={this.getColumns()}
                    refreshActions={[DELETE_QUESTION, PATCH_QUESTION, 'QUESTION_IMPORT', DELETE_CATEGORY]}
                    filterForm={QuestionsFilter}
                    buttons={this.renderTableButtons()}
                    staticFilter={{
                        company: user.ownQuestions || filters.company ? (category && !category.company ? undefined : user.company || filters.company) : undefined,
                        visible: isAdmin && !filters.company ? undefined : true,
                    }}
                    rowSelection={!isAdmin && category && !category.company ? undefined : {
                        selectedRowKeys: selected,
                        onChange: this.setSelected,
                        type: 'checkbox',
                        renderCell: (checked, record, index, originNode) => {
                            return (isAdmin || record.company) ? originNode : null;
                        }
                    }} />
            </TableWrapper>
        </Wrapper>
    }
}

const stateToProps = (state, props) => {
    const filters = getFilters(props.location) || {};
    const expandedKeys = qs.parse(props.location.search, { ignoreQueryPrefix: true }).expandedKeys || [];
    const selectedCategory = path(['categories', 0], filters);
    const category = expandedKeys.length === 2 && selectedCategory ? find(propEq('id', selectedCategory), getCategories.withParams({ parent: expandedKeys[0] }).selectData(state).items || []) : null;
    const user = getUser.selectData(state);

    return {
        questionsCount: pathOr(0, ['_meta', 'count'], getQuestions.selectData(state)),
        questionsOffset: pathOr(0, ['_meta', 'offset'], getQuestions.selectData(state)),
        category,
        user,
        filters,
        isAdmin: contains(user.role, ADMIN_MODERATOR),
    };
};

export default withState('selected', 'setSelected', [])(
    asyncConnect({
        deleteQuestion: deleteQuestion
            .withSuccessHandler(({ selected, setSelected }) => {
                message.success(selected.length < 2 ? 'Вопрос был успешно удален' : 'Вопросы были успешно удалены');
                setSelected([]);
            })
            .withErrorHandler(({ selected }) => message.error(selected.length < 2 ? 'Не удалось удалить вопрос' : 'Не удалось удалить вопросы'))
            .withOptions({ resetOnUnmount: true }),
        patchQuestion: patchQuestion
            .withSuccessHandler(({ setSelected }) => {
                message.success('Компетенция успешно изменена');
                setSelected([]);
            })
            .withErrorHandler(() => message.error('Не удалось изменить компетенцию'))
            .withOptions({ resetOnUnmount: true })
    }, stateToProps, { openSelectCategoryModal, openQuestionsSettingsModal, openQuestionsImportModal, openQuestionPreviewModal })(Questions)
);
