import React, { Component } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import { path } from 'ramda';
import HighchartsExporting from 'highcharts/modules/exporting';

HighchartsExporting(Highcharts);

export default class ProjectRespondentStat extends Component {
    componentDidMount() {
        window.statChart = this.chart;
    }

    componentWillUnmount() {
        window.statChart = null;
    }

    getChartData = () => {
        const { data } = this.props;
        const integralScores = path(['integralScores'], data);

        return {
            chart: {
                type: 'column',
                height: '520px',
                spacingLeft: 10,
                spacingRight: 10,
            },
            credits: {
                enabled: false,
            },
            title: {
                text: '',
                enabled: false,
            },
            xAxis: {
                type: 'category',
                categories: [''],
                labels: {
                    enabled: false,
                },
            },
            yAxis: {
                allowDecimals: false,
                min: 0,
                max: 10,
                title: {
                    enabled: false
                },
                tickInterval: 1,
                gridLineColor: 'transparent',
            },
            tooltip: {
                enabled: false,
            },
            legend: {
                enabled: true,
                layout: 'horizontal',
                margin: 40,
                itemMarginBottom: 15,
                symbolPadding: 10,
                symbolRadius: 0,
                itemStyle: {
                    fontWeight: 'normal',
                    fontSize: '12px',
                    textOverflow: null,
                },
            },
            plotOptions: {
                column: {
                    borderWidth: 0,
                    pointWidth: 80,
                    groupPadding: 0.02,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}'
                    }
                }
            },
            series: [
                {
                    name: 'Интегральный балл – средневзвешенная оценка по всем компетенциям​',
                    data: [path(['total'], integralScores)],
                    color: '#f54d2e'
                },
                {
                    name: 'Активное взаимодействие с клиентом - сотрудник инициативен в поисках возможностей осуществления продажи и оказания услуг.​',
                    data: [path(['blocks', 'activity'], integralScores)],
                    color: '#2c3d4f'
                },
                {
                    name: 'Вежливость по отношению к клиенту – сотрудник проявляет корректность, вежливость, и тактичность по отношению к клиентам.​',
                    data: [path(['blocks', 'politeness'], integralScores)],
                    color: '#7895b4'
                },
                {
                    name: 'Постоянная готовность помочь клиенту – сотрудник оправдывает и превосходит ожидания клиентов, проявляет высокий уровень ориентации на их потребности.​',
                    data: [path(['blocks', 'helping_behavior'], integralScores)],
                    color: '#c6d2e0'
                },
                {
                    name: 'Индивидуальный подход к клиенту – при общении с клиентами сотрудник замечает характерные особенности каждого клиента и ориентируется на них при осуществлении обслуживания.',
                    data: [path(['blocks', 'individual_approach'], integralScores)],
                    color: '#e6e6e6',
                },
            ],
        };
    }

    render() {
        return <div style={{ width: '100%', margin: '0 auto' }}>
            <HighchartsReact
                highcharts={Highcharts}
                options={this.getChartData()}
                ref={node => this.chart = node}
            />
        </div>;
    }
}
