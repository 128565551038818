import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Tag, Button, Popconfirm, message } from 'antd';
import styled from 'styled-components';
import { find, filter, pathOr, propEq } from 'ramda';
import { asyncConnect } from 'react-async-client';
import { withState } from 'recompose';
import { connect } from 'react-redux';
import {
    ClockCircleOutlined,
    QuestionCircleOutlined,
    MailOutlined,
    DeleteOutlined,
    PlusOutlined
} from '@ant-design/icons';

import TableList from '../table/TableList';
import { getRespondentsScreening, deleteRespondentsScreening } from '../../../actions/asyncActions';
import Username from '../table/Username';
import { PROJECT_RESPONDENT_EMPLOYEE, PROJECT_RESPONDENT_STATUSES } from '../../../constants/companies';
import { getExecutionTime } from '../../../utils/time';
import DateFormat from '../table/DateFormat';
import TestRespondentsFilter from '../../forms/filters/TestRespondentsFilter';
import { DELETE_RESPONDENTS_SCREENING, POST_TEST_TEMPLATE_RESPONDENT_MESSAGES, POST_RESPONDENTS_SCREENING } from '../../../constants/actionTypes';
import { openTestTemplateRespondentMessagesModal, openTestRespondentModal } from '../../../actions/modalActions';
import { DEFAULT_TEST_TEMPLATE_MESSAGE_CONTENT } from '../../../constants/tests';
import Contacts from '../table/Contacts';

const ResultBox = styled.span`
    font-weight: bold;
    color: ${({ color }) => color || '#000'};
    .anticon{
        margin-right: 5px;
    }
`;

class TestRespondentsTabButtonsComponent extends Component {
    addRespondent = () => {
        this.props.openTestRespondentModal({
            testTemplate: this.props.match.params.id
        });
    }

    render() {
        return <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={this.addRespondent}>
            <span className='hide-mobile'>Добавить</span>
        </Button>;
    }
}

export const TestRespondentsTabButtons = connect(null, { openTestRespondentModal })(TestRespondentsTabButtonsComponent);

class TestRespondentsTab extends Component {
    getColumns = () => {
        return [
            {
                title: 'Респондент',
                key: 'lastName',
                sorter: true,
                filters: PROJECT_RESPONDENT_EMPLOYEE.map(i => ({ text: i.value, value: i.id })),
                filterMultiple: false,
                render: item =>
                    <Fragment>
                        <div>
                            <Link to={`/tests/${this.props.id}/respondents/${item.id}`}>
                                <Username user={item} />
                            </Link>
                        </div>
                        <div>{ item.employee ? 'Сотрудник' : '' }</div>
                    </Fragment>
            },
            {
                title: 'Контакты',
                dataIndex: 'email',
                key: 'email',
                render: (email, { phone }) => <Contacts email={email} phone={phone} />
            },
            {
                title: 'Время',
                dataIndex: ['_embedded', 'solution', 'solutionTime'],
                key: 'solution.solutionTime',
                sorter: true,
                width: 100,
                className: 'center-column',
                render: time => <ResultBox color='#2b3d4f'><ClockCircleOutlined />{getExecutionTime(time)}</ResultBox>
            },
            {
                title: 'Ответы',
                dataIndex: ['_embedded', 'solution', '_embedded', 'answers'],
                key: 'solution.answers',
                sorter: true,
                width: 110,
                className: 'center-column',
                render: answers =>
                    answers ?
                        <ResultBox color='#2b3d4f'><QuestionCircleOutlined />
                            { (100 * filter(a => a.correct, answers).length / pathOr([], ['questions'], this.props.test).length).toFixed(1) }%
                        </ResultBox> : null
            },
            {
                dataIndex: 'status',
                key: 'statuses',
                filters: PROJECT_RESPONDENT_STATUSES.map(i => ({ text: i.value, value: i.id })),
                className: 'right-column',
                render: (status, { createdAt }) => {
                    const data = find(propEq('id', status), PROJECT_RESPONDENT_STATUSES);

                    return <Fragment>
                        { data ? <Tag style={{ marginBottom: 5 }} color={data.color}>{ data.value }</Tag> : null }
                        <div><DateFormat date={createdAt} /></div>
                    </Fragment>;
                }
            }
        ];
    }

    delete = () => {
        this.props.deleteRespondentsScreening.dispatch(this.props.selected);
    }

    openSendLetterModal = () => {
        const { selected, id, openTestTemplateRespondentMessagesModal } = this.props;

        openTestTemplateRespondentMessagesModal({
            item: {
                respondents: selected,
                subject: 'Приглашение на пробное тестирование',
                content: DEFAULT_TEST_TEMPLATE_MESSAGE_CONTENT,
                testTemplate: id
            },
            clearSelected: this.clearSelected
        });
    }

    renderButtons = () => {
        const { selected } = this.props;

        return <Button.Group>
            <Button
                icon={<MailOutlined />}
                disabled={!selected.length}
                onClick={() => this.openSendLetterModal()}>
                <span className='hide-mobile'>Отправить приглашение</span>
            </Button>
            { selected.length ?
                <Popconfirm
                    title='Вы уверены, что хотите удалить выбранных респондентов?'
                    onConfirm={this.delete}>
                    <Button type='danger' icon={<DeleteOutlined />}>
                        <span className='hide-mobile'>Удалить</span>
                    </Button>
                </Popconfirm> :
                <Button
                    type='danger'
                    icon={<DeleteOutlined />}
                    disabled={!selected.length}>
                    <span className='hide-mobile'>Удалить</span>
                </Button>
            }
        </Button.Group>
    }

    clearSelected = () => this.setSelected([]);

    setSelected = selected => this.props.setSelected(selected);

    render() {
        return <TableList
            action={getRespondentsScreening}
            columns={this.getColumns()}
            staticFilter={{ testTemplate: this.props.id }}
            buttons={this.renderButtons()}
            filterForm={TestRespondentsFilter}
            refreshActions={[DELETE_RESPONDENTS_SCREENING, POST_TEST_TEMPLATE_RESPONDENT_MESSAGES, POST_RESPONDENTS_SCREENING]}
            rowSelection={{
                selectedRowKeys: this.props.selected,
                onChange: this.setSelected,
                type: 'checkbox'
            }} />;
    }
}

export default withState('selected', 'setSelected', [])(
    asyncConnect({
        deleteRespondentsScreening: deleteRespondentsScreening
            .withSuccessHandler(({ setSelected }) => {
                message.success('Респонденты успешно удалены');
                setSelected([]);
            })
            .withErrorHandler(() => message.error('Не удалось удалить респондентов'))
            .withOptions({ resetOnUnmount: true })
    }, null, { openTestTemplateRespondentMessagesModal })(TestRespondentsTab)
);
