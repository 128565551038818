import React, { Component } from 'react';
import { isEmpty, path } from 'ramda';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import ListenerField from '../ListenerField';

class Rangepicker extends Component {
    static propTypes = {
        placeholder: PropTypes.array,
        input: PropTypes.object,
        onChange: PropTypes.func,
        className: PropTypes.string,
        toPath: PropTypes.string,
        change: PropTypes.func,
        allowClear: true
    }

    static defaultProps = {
        placeholder: ['С', 'По'],
    }

    onChange = (e, change) => {
        const { onChange, toPath } = this.props;
        const hasValue = e && !isEmpty(e);

        onChange(hasValue ? e[0].toISOString() : undefined, hasValue ? e[1].toISOString() : undefined);
        change(toPath, hasValue ? e[1].toISOString() : undefined);
    }

    renderPicker = (values) => {
        const change = this.props.change;
        const { placeholder, input: { value }, className, toPath, allowClear } = this.props;
        const pickerValue = value ? [moment(value), moment(path(toPath.split('.'), values))] : undefined;

        return <DatePicker.RangePicker
            value={pickerValue}
            className={className}
            onChange={e => this.onChange(e, change)}
            placeholder={placeholder}
            format='DD-MM-YYYY'
            allowClear={allowClear}
        />
    }

    render() {
        return (
            <ListenerField
                listenFieldName={[this.props.toPath]}
            >
                {values => this.renderPicker(values)}
            </ListenerField>
        );
    }
}

export default withFieldWrapper(Rangepicker);
