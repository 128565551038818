import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import { patchCompany } from '../../actions/asyncActions';
import Modal from './Modal';
import CompanyIntegralResultScaleForm from '../forms/CompanyIntegralResultScaleForm';

class CompanyIntegralResultScaleModal extends Component {
    render() {
        const { modal, params, patchCompany } = this.props;

        return <Modal
            {...modal}
            title='Редактировать вид вывода'
            footer={null}>
            <CompanyIntegralResultScaleForm
                formAction={patchCompany}
                item={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    patchCompany: patchCompany
        .withSuccessHandler(({ close }) => {
            message.success('Вид вывода успешно изменен');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(CompanyIntegralResultScaleModal);
