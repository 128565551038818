import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { append, update } from 'ramda';

import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import Input from './formComponents/Input';
import Textarea from './formComponents/Textarea';
import Select from './formComponents/Select';
import { QUESTION_LEVELS } from '../../constants/questions';

class CategoryRecommendationForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='category'
                component={Input}
                label='Категория'
                required />
            <Field
                name='complexityLevel'
                component={Select}
                label='Сложность'
                options={QUESTION_LEVELS.map(item => ({ id: item.id, value: item.icon }))}
                required />
            <Field
                name='text'
                component={Textarea}
                label='Описание' />
            <SubmitButton type='primary'>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    category: yup.string().required(),
    complexityLevel: yup.string().required(),
    text: yup.string().required()
});

export default withFormWrapper(CategoryRecommendationForm, {
    mapPropsToValues: ({ add, item, index }) => add ? {} : item.recommendations[index],
    validationSchema,
    mapBeforeSubmit: (value, { add, index, item, id }) => ({
        id,
        data: add ? {
            ...item,
            recommendations: append(value, item.recommendations)
        } : {
            ...item,
            recommendations: update(index, value, item.recommendations)
        }
    })
});
