import React, { Component } from 'react';
import { Button, message, Popconfirm } from 'antd';
import { asyncConnect } from 'react-async-client';
import { path, find, propEq } from 'ramda';
import { CheckOutlined, CloseOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import { getDistributions, deleteDistribution } from '../../../actions/asyncActions';
import { openDistributionModal } from '../../../actions/modalActions';
import TableList from '../table/TableList';
import SpanAsLink from '../table/SpanAsLink';
import { DISTRIBUTION } from '../../../constants/companies';
import { POST_DISTRIBUTION, PUT_DISTRIBUTION, DELETE_DISTRIBUTION } from '../../../constants/actionTypes';

class CompanyDistribution extends Component {
    getColumns = () => {
        return [
            {
                title: 'Тип',
                dataIndex: 'type',
                key: 'type',
                render: (type, item) =>
                    <SpanAsLink onClick={() => this.props.openDistributionModal(item)}>
                        { path(['value'], find(propEq('id', type), DISTRIBUTION)) }
                    </SpanAsLink>
            },
            {
                title: 'Описание',
                dataIndex: 'description',
                key: 'description'
            },
            {
                title: 'Распределение по умолчанию',
                dataIndex: 'default',
                key: 'default',
                render: value => value ? <CheckOutlined style={{ color: '#61BD4F' }} /> : <CloseOutlined style={{ color: '#f54d2e' }} />
            },
            {
                width: 100,
                align: 'right',
                key: 'actions',
                render: item =>
                    <Popconfirm
                        title='Вы уверены, что хотите удалить распределение?'
                        okText='Да'
                        cancelText='Нет'
                        onConfirm={() => this.props.deleteDistribution.dispatch(item.id)}
                        placement='left'>
                        <Button type='danger' icon={<DeleteOutlined />} />
                    </Popconfirm>
            }
        ];
    }

    getButtons = () => {
        const { company, openDistributionModal } = this.props;

        return <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => openDistributionModal({ company: company.id })}>
            <span className='hide-mobile'>Добавить</span>
        </Button>;
    }

    render() {
        const { company } = this.props;

        return <TableList
            columns={this.getColumns()}
            action={getDistributions}
            staticFilter={{ company: company.id }}
            buttons={this.getButtons()}
            refreshActions={[POST_DISTRIBUTION, PUT_DISTRIBUTION, DELETE_DISTRIBUTION]} />;
    }
}

export default asyncConnect({
    deleteDistribution: deleteDistribution
        .withSuccessHandler(() => message.success('Распределение успешно удалено'))
        .withErrorHandler(() => message.error('Не удалось удалить распределение'))
        .withOptions({ resetOnUnmount: true })
}, null, { openDistributionModal })(CompanyDistribution);
