import React, { Component } from 'react';
import { withAsyncActions, toSuccess } from 'react-async-client';
import { connect } from 'react-redux';
import { Button, message, Popconfirm } from 'antd';
import { takeEvery } from 'redux-saga/effects';
import { path } from 'ramda';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import { POST_TEST_TEMPLATE_AUDIT, PUT_EXPERT } from '../../../constants/actionTypes';
import { getTestTemplateAudit, deleteTestTemplateAudit } from '../../../actions/asyncActions';
import { openExpertModal } from '../../../actions/modalActions';
import Audit from './Audit';

class TestAuditButtonsComponent extends Component {
    delete = () => {
        const { deleteTestTemplateAudit, match: { params: { id, itemId } } } = this.props;

        deleteTestTemplateAudit.dispatch({
            test: id,
            id: itemId,
        });
    }

    edit = () => {
        this.props.openExpertModal(
            path(['_embedded', 'expert'], this.props.data)
        );
    }

    render() {
        return <Button.Group>
            <Button type='primary' icon={<EditOutlined />} onClick={this.edit} disabled={this.props.pending}>
                <span className='hide-mobile'>Редактировать</span>
            </Button>
            <Popconfirm
                okText='Да'
                cancelText='Нет'
                title='Вы уверены, что хотите удалить эксперта?'
                onConfirm={this.delete}>
                <Button type='danger' icon={<DeleteOutlined />}>
                    <span className='hide-mobile'>Удалить</span>
                </Button>
            </Popconfirm>
        </Button.Group>
    }
}

const stateToProps = state => ({
    data: getTestTemplateAudit.selectData(state),
    pending: getTestTemplateAudit.selectMeta(state).pending
});

export const TestAuditButtons = connect(stateToProps, { openExpertModal })(withAsyncActions({
    deleteTestTemplateAudit: deleteTestTemplateAudit
        .withSuccessHandler(({ history }) => {
            message.success('Эксперт успешно удален');
            history.goBack();
        })
        .withErrorHandler(() => message.error('Не удалось удалить эксперта'))
        .withOptions({ resetOnUnmount: true })
})(TestAuditButtonsComponent));

class TestAudit extends Component {
    render() {
        return <Audit {...this.props} testTemplate />;
    }
}

export default withAsyncActions({
    getTestTemplateAudit: getTestTemplateAudit
        .withPayload(({ match: { params: { id, test } } }) => ({ id, test }))
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(POST_TEST_TEMPLATE_AUDIT), toSuccess(PUT_EXPERT)], function() {
                const { getTestTemplateAudit } = getProps();

                getTestTemplateAudit.refresh();
            })
        })
        .withOptions({ resetOnUnmount: true, dispatchOnMount: true })
})(TestAudit);
