import React, { Component } from 'react';
import { Layout, Menu } from 'antd';
import { find, filter, any, is } from 'ramda';
import { connect } from 'react-redux';
import { matchPath, withRouter, Link } from 'react-router-dom';

import MENU_ITEMS from '../../../constants/menuItems';
import { getUser } from '../../../actions/asyncActions';

class Sidebar extends Component {
    state = {
        collapsed: false
    };

    onCollapse = () => this.setState(prev => ({ collapsed: !prev.collapsed }));

    getSelectedKey() {
        const { pathname } = this.props.location;

        const selected = find(item => {
            const key = this.getKey(item.key);

            return (key === '/' && key === pathname) ||
                matchPath(pathname, {
                    path: item.route || key
                });
        }, this.getItems());

        return selected ? [this.getKey(selected.key)] : null;
    }

    getItems = () => {
        const { role, user } = this.props;

        return filter(item => {
            const hasRole = !!item.roles;

            return item.cond ? (!hasRole ? item.cond(user) : item.cond(user) && any(r => role === r, item.roles)) : (!hasRole || any(r => role === r, item.roles));
        }, MENU_ITEMS);
    }

    getKey = key => {
        return is(Function, key) ? key(this.props) : key;
    }

    render() {
        return <Layout.Sider
            width={210}
            breakpoint="lg"
            collapsedWidth={50}
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}>
            <Menu
                selectable={false}
                selectedKeys={this.getSelectedKey()}
                mode='inline'>
                { this.getItems().map(item =>
                    <Menu.Item key={this.getKey(item.key)}>
                        <Link to={this.getKey(item.key)}>{item.icon}<span>{ item.title }</span></Link>
                    </Menu.Item>
                )}
            </Menu>
        </Layout.Sider>
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default withRouter(
    connect(stateToProps)(Sidebar)
);
