import React, { Component } from 'react';
import { connect } from 'react-redux';
import { map, pick, trim } from 'ramda';
import { Modal as AntdModal } from 'antd';

import { closeModal, hideModal, showModal, removeModal } from '../../actions/modalActions';

import * as modalActions from '../../constants/actionTypes';

import SelectCategoryModal from './SelectCategoryModal';
import QuestionPreviewModal from './QuestionPreviewModal';
import AdminModal from './AdminModal';
import CompanyModal from './CompanyModal';
import CompanyDesignPageModal from './companyDesignPageModal/CompanyDesignPageModal';
import CompanyClientModal from './CompanyClientModal';
import CompanyEmployeeModal from './CompanyEmployeeModal';
import AddClientToProjectModal from './AddClientToProjectModal';
import CompanyTemplateModal from './CompanyTemplateModal';
import SettingsTemplateModal from './SettingsTemplateModal';
import SelectProjectTypeModal from './SelectProjectTypeModal';
import CategoriesSettingsInfoModal from './CategoriesSettingsInfoModal';
import ProjectCategorySelectModal from './ProjectCategorySelectModal';
import CompanyRespondentModal from './CompanyRespondentModal';
import RespondentLetterModal from './RespondentLetterModal';
import QuestionsSettingsModal from './QuestionsSettingsModal';
import QuestionsImportModal from './QuestionsImportModal';
import TestModal from './TestModal';
import ProjectNameModal from './ProjectNameModal';
import ImportRespondentsModal from './ImportRespondentsModal';
import CommandModal from './CommandModal';
import TestSettingsModal from './TestSettingsModal';
import QuestionPageModal from './QuestionPageModal';
import QuestionDetailModal from './QuestionDetailModal';
import ExpertModal from './ExpertModal';
import RespondentAnalyticsModal from './RespondentAnalyticsModal';
import TestTemplateModal from './TestTemplateModal';
import PdfModal from './PdfModal';
import ProjectQuestionsSettingsModal from './ProjectQuestionsSettingsModal';
import CategoryDistributionSettingsModal from './CategoryDistributionSettingsModal';
import PaymentModal from './PaymentModal';
import CompanyPaymentTypeModal from './CompanyPaymentTypeModal';
import TestTemplateRespondentMessagesModal from './TestTemplateRespondentMessagesModal';
import TestTemplateAuditMessagesModal from './TestTemplateAuditMessagesModal';
import TestRespondentModal from './TestRespondentModal';
import TestAuditModal from './TestAuditModal';
import RespondentFormFieldsModal from './RespondentFormFieldsModal';
import CompanyBankDetailsModal from './CompanyBankDetailsModal';
import PaymentBankDetailsModal from './PaymentBankDetailsModal';
import AddRespondentModal from './AddRespondentModal';
import CommandDebugModal from './CommandDebugModal';
import ProfileModal from './ProfileModal';
import QuestionModal from './QuestionModal';
import ReplaceTestQuestionModal from './ReplaceTestQuestionModal';
import TestGroupsModal from './TestGroupsModal';
import DistributionModal from './DistributionModal';
import QuestionsDistributionModal from './QuestionsDistributionModal';
import CategoryRecommendationModal from './CategoryRecommendationModal';
import CompanyIntegralResultScaleModal from './CompanyIntegralResultScaleModal';
import ExternalContractorModal from './ExternalContractorModal';
import AcceptorModal from './AcceptorModal';
import PaymentsMessageHistoryModal from './PaymentsMessageHistoryModal';
import QuestionnaryModal from './QuestionnaryModal';
import DriverModal from './DriverModal';
import DriverSettingsModal from './DriverSettingsModal';
import ImportQuestionsModal from './ImportQuestionsModal';
import SurveyModal from './SurveyModal';
import SurveyPageElementsModal from './SurveyPageElementsModal';
import GroupModal from './GroupModal';
import GroupVariablesModal from './GroupVariablesModal';
import AddSurveyRespondentModal from './AddSurveyRespondentModal';
import SurveyRespondentLetterModal from './SurveyRespondentLetterModal';
import SurveyRespondentModal from './SurveyRespondentModal';
import SurveyResponseFieldFormModal from './SurveyResponseFieldFormModal';
import LanguagesSelectorModal from './LanguagesSelectorModal';
import QuestionnaryPageElementsModal from './QuestionnaryPageElementsModal';
import SurveyPreviewModal from './SurveyPreviewModal';
import EmployeesImportModal from './EmployeesImportModal';
import CategoryErrorModal from './CategoryErrorModal';
import RespondentReportsLetterModal from './RespondentReportsLetterModal';

const modalComponents = {
    [modalActions.OPEN_SELECT_CATEGORY_MODAL]: SelectCategoryModal,
    [modalActions.OPEN_QUESTION_PREVIEW_MODAL]: QuestionPreviewModal,
    [modalActions.OPEN_ADMIN_MODAL]: AdminModal,
    [modalActions.OPEN_COMPANY_MODAL]: CompanyModal,
    [modalActions.OPEN_COMPANY_DESIGN_PAGE_MODAL]: CompanyDesignPageModal,
    [modalActions.OPEN_COMPANY_CLIENT_MODAL]: CompanyClientModal,
    [modalActions.OPEN_COMPANY_EMPLOYEE_MODAL]: CompanyEmployeeModal,
    [modalActions.OPEN_ADD_CLIENT_TO_PROJECT_MODAL]: AddClientToProjectModal,
    [modalActions.OPEN_COMPANY_TEMPLATE_MODAL]: CompanyTemplateModal,
    [modalActions.OPEN_SETTINGS_TEMPLATE_MODAL]: SettingsTemplateModal,
    [modalActions.OPEN_SELECT_PROJECT_TYPE_MODAL]: SelectProjectTypeModal,
    [modalActions.OPEN_CATEGORIES_SETTINGS_INFO_MODAL]: CategoriesSettingsInfoModal,
    [modalActions.OPEN_PROJECT_CATEGORY_SELECT_MODAL]: ProjectCategorySelectModal,
    [modalActions.OPEN_COMPLEXITY_DISTRIBUTION_SETTINGS_MODAL]: CategoryDistributionSettingsModal,
    [modalActions.OPEN_COMPANY_RESPONDENT_MODAL]: CompanyRespondentModal,
    [modalActions.OPEN_RESPONDENT_LETTER_MODAL]: RespondentLetterModal,
    [modalActions.OPEN_QUESTIONS_SETTINGS_MODAL]: QuestionsSettingsModal,
    [modalActions.OPEN_QUESTIONS_IMPORT_MODAL]: QuestionsImportModal,
    [modalActions.OPEN_TEST_MODAL]: TestModal,
    [modalActions.OPEN_PROJECT_NAME_MODAL]: ProjectNameModal,
    [modalActions.OPEN_IMPORT_RESPONDENTS_MODAL]: ImportRespondentsModal,
    [modalActions.OPEN_COMMAND_MODAL]: CommandModal,
    [modalActions.OPEN_COMMAND_DEBUG_MODAL]: CommandDebugModal,
    [modalActions.OPEN_TEST_SETTINGS_MODAL]: TestSettingsModal,
    [modalActions.OPEN_QUESTION_PAGE_MODAL]: QuestionPageModal,
    [modalActions.OPEN_QUESTION_DETAIL_MODAL]: QuestionDetailModal,
    [modalActions.OPEN_EXPERT_MODAL]: ExpertModal,
    [modalActions.OPEN_RESPONDENT_ANALYTICS_MODAL]: RespondentAnalyticsModal,
    [modalActions.OPEN_TEST_TEMPLATE_MODAL]: TestTemplateModal,
    [modalActions.OPEN_PDF_MODAL]: PdfModal,
    [modalActions.OPEN_PROJECT_QUESTIONS_SETTINGS_MODAL]: ProjectQuestionsSettingsModal,
    [modalActions.OPEN_PAYMENT_MODAL]: PaymentModal,
    [modalActions.OPEN_COMPANY_PAYMENT_TYPE_MODAL]: CompanyPaymentTypeModal,
    [modalActions.OPEN_TEST_TEMPLATE_RESPONDENT_MESSAGES_MODAL]: TestTemplateRespondentMessagesModal,
    [modalActions.OPEN_TEST_RESPONDENT_MODAL]: TestRespondentModal,
    [modalActions.OPEN_TEST_TEMPLATE_AUDIT_MESSAGES_MODAL]: TestTemplateAuditMessagesModal,
    [modalActions.OPEN_TEST_AUDIT_MODAL]: TestAuditModal,
    [modalActions.OPEN_RESPONDENT_FORM_FIELDS_MODAL]: RespondentFormFieldsModal,
    [modalActions.OPEN_COMPANY_BANK_DETAILS_MODAL]: CompanyBankDetailsModal,
    [modalActions.OPEN_PAYMENT_BANK_DETAILS_MODAL]: PaymentBankDetailsModal,
    [modalActions.OPEN_ADD_RESPONDENT_MODAL]: AddRespondentModal,
    [modalActions.OPEN_PROFILE_MODAL]: ProfileModal,
    [modalActions.OPEN_QUESTION_MODAL]: QuestionModal,
    [modalActions.OPEN_REPLACE_TEST_QUESTION_MODAL]: ReplaceTestQuestionModal,
    [modalActions.OPEN_TEST_GROUPS_MODAL]: TestGroupsModal,
    [modalActions.OPEN_DISTRIBUTION_MODAL]: DistributionModal,
    [modalActions.OPEN_QUESTIONS_DISTRIBUTION_MODAL]: QuestionsDistributionModal,
    [modalActions.OPEN_CATEGORY_RECOMMENDATION_MODAL]: CategoryRecommendationModal,
    [modalActions.OPEN_CATEGORY_ERROR_MODAL]: CategoryErrorModal,
    [modalActions.OPEN_COMPANY_INTEGRAL_RESULT_SCALE_MODAL]: CompanyIntegralResultScaleModal,
    [modalActions.OPEN_EXTERNAL_CONTRACTOR_MODAL]: ExternalContractorModal,
    [modalActions.OPEN_ACCEPTOR_MODAL]: AcceptorModal,
    [modalActions.OPEN_PAYMENT_MESSAGE_HISTORY_MODAL]: PaymentsMessageHistoryModal,
    [modalActions.OPEN_QUESTIONNARY_MODAL]: QuestionnaryModal,
    [modalActions.OPEN_DRIVER_MODAL]: DriverModal,
    [modalActions.OPEN_DRIVER_SETTINGS_MODAL]: DriverSettingsModal,
    [modalActions.OPEN_IMPORT_QUESTIONS_MODAL]: ImportQuestionsModal,
    [modalActions.OPEN_QUESTIONNARY_PAGE_ELEMENTS_MODAL]: QuestionnaryPageElementsModal,
    [modalActions.OPEN_SURVEY_MODAL]: SurveyModal,
    [modalActions.OPEN_SURVEY_PAGE_ELEMENTS_MODAL]: SurveyPageElementsModal,
    [modalActions.OPEN_GROUP_MODAL]: GroupModal,
    [modalActions.OPEN_GROUP_VARIABLES_MODAL]: GroupVariablesModal,
    [modalActions.OPEN_SURVEY_RESPONDENT_LETTER_MODAL]: RespondentLetterModal,
    [modalActions.OPEN_ADD_SURVEY_RESPONDENT_MODAL]: AddSurveyRespondentModal,
    [modalActions.OPEN_SURVEY_RESPONDENT_LETTER_MODAL]: SurveyRespondentLetterModal,
    [modalActions.OPEN_SURVEY_RESPONDENT_MODAL]: SurveyRespondentModal,
    [modalActions.OPEN_SURVEY_RESPONSE_FIELD_FORM_MODAL]: SurveyResponseFieldFormModal,
    [modalActions.OPEN_LANGUAGES_SELECTOR_MODAL]: LanguagesSelectorModal,
    [modalActions.OPEN_SURVEY_PREVIEW_MODAL]: SurveyPreviewModal,
    [modalActions.OPEN_EMPLOYEES_IMPORT_MODAL]: EmployeesImportModal,
    [modalActions.OPEN_RESPONDENT_REPORTS_LETTER_MODAL]: RespondentReportsLetterModal
};

class Modals extends Component {
    getModalComponent = ({ type, params, visible, close }, index, length) => {
        const actions = map(action => action.bind(null, index), pick(['close', 'hide', 'show', 'remove'], this.props));
        const ModalComponent = modalComponents[type];

        const modalProps = {
            visible: index + 1 === length && visible && !close,
            width: 900,
            onCancel: (e) => {
                if (e.target && (trim(`${e.target.className}`) !== 'ant-modal-wrap')) {
                    return actions.close(e);
                } else {
                    AntdModal.confirm({
                        content: 'Закрыть окно?',
                        okText: 'Закрыть',
                        onOk: () => {
                            actions.close(e);
                        },
                    });

                    return false;
                }
            },
            afterClose: () => close && actions.remove()
        };

        return <ModalComponent {...actions} key={index} index={index} modal={modalProps} params={params} />;
    }

    render() {
        const { modals } = this.props;

        return (
            <div>
                {modals.map((modal, index) => this.getModalComponent(modal, index, modals.length))}
            </div>
        );
    }
}

const stateToProps = pick(['modals']);

export default connect(stateToProps, {
    close: closeModal,
    hide: hideModal,
    show: showModal,
    remove: removeModal
})(Modals);
