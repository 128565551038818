import React, { Component, Fragment } from 'react';
import { prop } from 'ramda';
import * as yup from 'yup';
import { Field } from 'react-final-form';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import Datepicker from './formComponents/Datepicker';
import { PhoneInput } from './formComponents/MaskedInput';
import Select from './formComponents/Select';
import { COMPANY_CLIENT_ROLES } from '../../constants/companies';
import ImageUpload from './formComponents/ImageUpload';

const labelCol = {
    xs: { span: 24 },
    sm: { span: 6 },
};

const wrapperCol = {
    xs: { span: 24 },
    sm: { span: 18 },
};

class CompanyClientForm extends Component {
    render() {
        return <Fragment>
            <h3>Информация</h3>
            <Field
                name='lastName'
                component={Input}
                label='Фамилия'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='firstName'
                component={Input}
                label='Имя'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='middleName'
                component={Input}
                label='Отчество'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <h3>Контакты</h3>
            <Field
                name='email'
                htmlType='email'
                component={Input}
                label='Email'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='phone'
                component={PhoneInput}
                label='Телефон'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <h3>Прочее</h3>
            <Field
                name='role'
                component={Select}
                label='Роль'
                options={COMPANY_CLIENT_ROLES}
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='expiredAt'
                component={Datepicker}
                label='Дата отключения'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='position'
                component={Input}
                label='Должность'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='photo'
                component={ImageUpload}
                label='Фотография'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <SubmitButton type='primary'>Сохранить</SubmitButton>
        </Fragment>
    }
}

const validationSchema = yup.object().shape({
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    email: yup.string().email().required(),
    role: yup.string().required()
});

export default withFormWrapper(CompanyClientForm, {
    mapPropsToValues: prop('item'),
    validationSchema
});
