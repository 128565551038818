import React, { Component } from 'react';

import Modal from './Modal';

export default class CategoriesSettingsInfoModal extends Component {
    render() {
        return <Modal
            {...this.props.modal}
            title='Настройка профиля должности'
            footer={null}>
            <p>Выберите компетенции из межотраслевой модели профессиональных компетенций, необходимые для оценки респондентов. В момент выбора помните о временном критерии: чем больше компетенций, тем больше времени требуется на их проверку. Рекомендуемая длительность процедуры одного сеанса тестирования составляет не более 1,5 часов. Управляйте временем тестирования добавляя/удаляя компетенции, а так же выбирая тип проверки компетенции ключевая/базовая.</p>
            <p>Выбор типа проверки компетенций позволяет гибко управлять общим временем тестирования. Время и количество вопросов в тесте на ключевую компетенцию и базовую отличается. Вопросы при ключевой проверки компетенций позволяют определить уровень экспертизы по шкале от 0 до 5. Вопросы при базовой проверки компетенций позволяют определить уровень экспертизы по шкале от 0 до 2, т. е. выявляют наличие или отсутствие базовых знаний в рамках данной компетенции.</p>
        </Modal>;
    }
}
