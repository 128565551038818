import React from 'react';
import { path } from 'ramda';

import Error from '../Error';

const withErrorHandler = (handledActionName) => (WrappedComponent) => function withError(propsFromAsyncClient) {
    const handledAction = propsFromAsyncClient[handledActionName];
    const errorObject = path(['meta', 'error', 'data'], handledAction);
    const statusCode = path(['meta', 'error', 'status'], handledAction);
    const refresh = handledAction.refresh;

    return errorObject ? <Error error={errorObject} statusCode={statusCode} refresh={refresh}/> : <WrappedComponent {...propsFromAsyncClient} />;
};

export default withErrorHandler;
