import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import Modal from './Modal';
import { patchQuestionnary } from '../../actions/asyncActions';
import DriverSettingsForm from '../forms/DriverSettingsForm';

class DriverSettingsModal extends Component {
    render() {
        const { modal, params: { question, project, index, variables }, patchQuestionnary } = this.props;

        return <Modal
            {...modal}
            title='Настройки драйвера'
            footer={null}>
            <DriverSettingsForm
                item={question}
                index={index}
                project={project}
                formAction={patchQuestionnary}
                variables={variables} />
        </Modal>;
    }
}

export default withAsyncActions(props => ({
    patchQuestionnary: patchQuestionnary
        .withSuccessHandler(({ close }) => {
            message.success('Драйвер успешно сохранен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось сохранить драйвер'))
        .withOptions({ resetOnUnmount: true })
}))(DriverSettingsModal);
