import React, { Component, Fragment } from 'react';
import { List, Button, Tooltip, message, Popconfirm, Checkbox, Row, Col, Menu, Dropdown } from 'antd';
import { asyncConnect, toSuccess } from 'react-async-client';
import { pathOr, path, pick, append, remove, findIndex, propEq, filter, any, concat, includes, without, find, all, prop } from 'ramda';
import styled, { css } from 'styled-components';
import { takeEvery } from 'redux-saga/effects';
import { Field } from 'react-final-form';
import { withStateHandlers } from 'recompose';
import { UserOutlined, MailOutlined, PhoneOutlined, EditOutlined, DeleteOutlined, PlusOutlined, FileExcelOutlined, DownOutlined } from '@ant-design/icons';

import withFormWrapper from '../../hocs/withFormWrapper';
import { getCompanyEmployees, deleteCompanyRespondent, postCompanyRespondent, getBusinessUnits, getRespondents, getProjects, postCompanyRespondents } from '../../../actions/asyncActions';
import { openCompanyRespondentModal, openImportRespondentsModal } from '../../../actions/modalActions';
import Select from '../formComponents/Select';
import InputSearch from '../formComponents/InputSearch';
import { POST_COMPANY_RESPONDENT, POST_COMPANY_RESPONDENTS, PUT_COMPANY_RESPONDENT } from '../../../constants/actionTypes';
import { getBusinessUnitsOptions } from '../../../utils/companies';
import ListenerField from '../ListenerField';
import withStepsPagination from './withStepsPagination';
import { LINES_PER_PAGE } from '../../../constants/table';

const ListHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .ant-form-item {
        margin: 0;
        width: 70%;
    }
    .dropdown-link {
        color: #F54D2E;
        .dropdown-link-text {
            display: inline-block;
            border-bottom: 1px dashed #F54D2E;
        }
    }
`;

const Lists = styled.div`
    margin: 15px 0;
    display: flex;
`;

const ListWrapper = styled.div`
    width: 50%;
    padding-right: ${({ left }) => left ? 5 : 0}px;
    padding-left: ${({ left }) => left ? 0 : 5}px;
    .ant-list-header {
        font-size: 1rem;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
    }
    .ant-list .ant-list-header{
        padding: 10px 15px;
    }
    ${({ unselected }) => unselected && css`
        .ant-list-bordered {
            border-bottom: 0;
        }
        .ant-pagination {
            text-align: center;
        }
        .table-footer {
            padding: 15px;
            border: 1px solid #f0f0f0;
            border-top: 0;
            .ant-btn {
                margin-right: 6px;
            }
        }
        .ant-list-bordered .ant-list-pagination {
            margin-bottom: 0;
        }
    `}
`;

const ListItem = styled(List.Item)`
    justify-content: space-between;
`;

const TestField = styled.div`
    margin-top: 15px;
`;

const Filters = styled.div`
    background: #fff;
    margin: -15px;
    padding: 15px;
    margin-top: 15px;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    .ant-form-item {
        margin-bottom: 0;
    }
`;

class ProjectRespondentsStep extends Component {
    deleteRespondent = ({ id, project, company, testSuite }) => {
        this.props.deleteCompanyRespondent.dispatch({
            id,
            project,
            company,
            test: testSuite
        });
    }

    editRespondent = respondent => {
        this.props.openCompanyRespondentModal({
            ...respondent,
            businessUnit: path(['_embedded', 'employee', 'businessUnit'], respondent),
        });
    }

    addRespondent = (employee, testSuite) => {
        const { item, postCompanyRespondent } = this.props;

        postCompanyRespondent.dispatch({
            ...pick(['firstName', 'lastName', 'middleName', 'email', 'phone', 'company'], employee),
            employee: this.props.fromRespondents ? null : employee.id,
            project: item.id,
            testSuite,
            status: 'created'
        });
    }

    onChangeBusinessUnit = id => {
        this.props.setBusinessUnit(id);
    }

    onCheck = id => this.props.setSelected(includes(id, this.props.selected) ? without([id], this.props.selected) : append(id, this.props.selected));

    renderRow = (item, respondent) => {
        const businessUnit = pathOr(path(['employee', '_embedded', 'businessUnit', 'name'], item._embedded), ['businessUnit', 'name'], item._embedded);

        return <ListItem>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                { !respondent &&
                    <Checkbox style={{ marginRight: 20 }} checked={includes(item.id, this.props.selected)} onChange={() => this.onCheck(item.id)} />
                }
                <div>
                    <div style={{ color: '#000' }}>
                        { businessUnit &&
                            <Tooltip title={businessUnit}>
                                <UserOutlined style={{ marginRight: 5 }} />
                            </Tooltip>
                        }
                        { item.lastName } { item.firstName } { item.middleName || '' }
                    </div>
                    <div><MailOutlined /> { item.email }</div>
                    { item.phone && <div><PhoneOutlined /> { item.phone }</div> }
                </div>
            </div>
            { respondent ?
                <Button.Group>
                    <Button icon={<EditOutlined />} onClick={() => this.editRespondent(item)} />
                    <Popconfirm
                        title='Вы уверены, что хотите удалить респондента?'
                        okText='Да'
                        cancelText='Нет'
                        onConfirm={() => this.deleteRespondent(item)}>
                        <Button icon={<DeleteOutlined />} type='danger' />
                    </Popconfirm>
                </Button.Group> :
                <ListenerField listenFieldName='test'>
                    { ({ test }) =>
                        <Button
                            icon={<PlusOutlined />}
                            onClick={() => this.addRespondent(item, test)}
                            disabled={this.props.getRespondents.meta.pending} />
                    }
                </ListenerField>
            }
        </ListItem>;
    }

    onSuccessImport = items => {
        this.props.setRespondents(concat(this.props.respondents, items));
    }

    addSelected = testSuite => {
        const { postCompanyRespondents, getAllRespondents, getCompanyEmployees, fromRespondents, item, selected } = this.props;

        postCompanyRespondents.dispatch({
            items: selected.map(id => {
                const respondent = find(propEq('id', id), pathOr([], ['data', 'items'], fromRespondents ? getAllRespondents : getCompanyEmployees));

                return {
                    ...pick(['firstName', 'lastName', 'middleName', 'email', 'phone', 'company'], respondent),
                    employee: fromRespondents ? null : respondent.id,
                    project: item.id,
                    testSuite,
                    status: 'created'
                };
            })
        });
    }

    checkAll = items => {
        this.props.setSelected(this.props.selected.length === items.length ? [] : items.map(prop('id')));
    }

    render() {
        const {
            getCompanyEmployees,
            openCompanyRespondentModal,
            openImportRespondentsModal,
            item,
            company,
            respondents,
            getRespondents,
            getAllRespondents,
            testSuites,
            fromRespondents,
            setFromRespondents,
            setName,
            setProject,
            selected,
            offset,
            setOffset
        } = this.props;
        const employees = filter(item => !any(propEq('employee', item.id), respondents), pathOr([], ['data', 'items'], getCompanyEmployees));
        const unselectedRespondents = pathOr([], ['data', 'items'], getAllRespondents);
        const unselectedItems = fromRespondents ? unselectedRespondents : employees;
        const menu = <Menu>
            <Menu.Item key='respondents' onClick={() => setFromRespondents(true)}>респондентов</Menu.Item>
            <Menu.Item key='employees' onClick={() => setFromRespondents(false)}>сотрудников</Menu.Item>
        </Menu>;

        return <div>
            { testSuites.length > 1 &&
                <TestField>
                    <Field
                        name='test'
                        component={Select}
                        placeholder='Тест'
                        options={testSuites.map(({ id, name }) => ({ id, value: name }))}
                        onChange={this.onChangeTest} />
                </TestField>
            }
            <Lists>
                <ListWrapper left>
                    <List
                        header={
                            <ListHeader>
                                <strong>Респонденты</strong>
                                <Button.Group>
                                    <Button
                                        icon={<FileExcelOutlined />}
                                        onClick={() => openImportRespondentsModal({
                                            company: item.company,
                                            project: item.id,
                                            tests: testSuites,
                                            onSuccess: this.onSuccessImport
                                        })}>
                                        Импорт
                                    </Button>
                                    <ListenerField listenFieldName='test'>
                                        { ({ test }) =>
                                            <Button
                                                icon={<PlusOutlined />}
                                                type='primary'
                                                onClick={() => openCompanyRespondentModal({
                                                    company,
                                                    project: item.id,
                                                    status: 'created',
                                                    testSuite: test,
                                                    hideTests: true,
                                                })}
                                                disabled={!item.id || getRespondents.meta.pending}>
                                                <span className='hide-mobile'>Добавить</span>
                                            </Button>
                                        }
                                </ListenerField>
                                </Button.Group>
                            </ListHeader>
                        }
                        dataSource={respondents}
                        renderItem={item => this.renderRow(item, true)}
                        loading={getRespondents.meta.pending}
                        bordered />
                </ListWrapper>
                <ListWrapper unselected>
                    <List
                        header={
                            <Fragment>
                                <ListHeader>
                                    <div>
                                        <Checkbox style={{ marginRight: 8 }} checked={fromRespondents && all(({ id }) => includes(id, selected), unselectedItems)} onChange={() => this.checkAll(unselectedItems)} />
                                        <strong>Выбрать из базы <Dropdown overlay={menu} trigger={['click']}>
                                                {/* eslint-disable-next-line */}
                                                <a className='dropdown-link' onClick={e => e.preventDefault()}>
                                                    <span className='dropdown-link-text'>{ fromRespondents ? 'респондентов' : 'сотрудников' }</span> <DownOutlined />
                                                </a>
                                            </Dropdown>
                                        </strong>
                                    </div>
                                </ListHeader>
                                <Filters>
                                    { fromRespondents ?
                                        <Row gutter={6}>
                                            <Col xs={24} sm={12}>
                                                <Field
                                                    name='name'
                                                    component={InputSearch}
                                                    placeholder='Поиск'
                                                    onChange={setName} />
                                            </Col>
                                            <Col xs={24} sm={12}>
                                                <Field
                                                    name='project'
                                                    component={Select}
                                                    placeholder='Тест'
                                                    options={pathOr([], ['data', 'items'], this.props.getProjects)}
                                                    namePath='name'
                                                    onChange={setProject}
                                                    searchable
                                                    allowClear />
                                            </Col>
                                        </Row> :
                                        <Field
                                            name='businessUnit'
                                            component={Select}
                                            placeholder='Должность'
                                            options={getBusinessUnitsOptions(pathOr([], ['data', 'items'], this.props.getBusinessUnits))}
                                            onChange={this.onChangeBusinessUnit}
                                            allowClear />
                                    }
                                </Filters>
                            </Fragment>
                        }
                        dataSource={unselectedItems}
                        renderItem={item => this.renderRow(item)}
                        loading={fromRespondents ? getAllRespondents.meta.pending : getCompanyEmployees.meta.pending}
                        pagination={(pathOr(0, ['_meta', 'count'], getAllRespondents.data) <= 10 || !fromRespondents) ? false : {
                            showSizeChanger: false,
                            defaultPageSize: LINES_PER_PAGE,
                            current: offset / LINES_PER_PAGE + 1,
                            total: pathOr(0, ['_meta', 'count'], getAllRespondents.data),
                            onChange: page => setOffset((page - 1) * LINES_PER_PAGE)
                        }}
                        key={fromRespondents ? 'respondents' : 'employees'}
                        bordered />
                    <ListenerField listenFieldName='test'>
                        { ({ test }) =>
                            <div className='table-footer'>
                                <Button type='primary' disabled={!selected.length} onClick={() => this.addSelected(test)}>Добавить выбранных ({ selected.length })</Button>
                                {/* <Button>Добавить всех { fromRespondents ? 'респондентов' : 'сотрудников' } ({ unselectedItems.length })</Button> */}
                            </div>
                        }
                    </ListenerField>
                </ListWrapper>
            </Lists>
        </div>;
    }
}

export default withStateHandlers(({ item }) => ({
    businessUnit: null,
    respondents: [],
    fromRespondents: true,
    name: null,
    project: null,
    selected: [],
    offset: 0
}), {
    setBusinessUnit: () => businessUnit => ({ businessUnit, selected: [] }),
    addRespondent: ({ respondents }) => respondent => ({
        respondents: append(respondent, respondents),
        selected: []
    }),
    setRespondents: () => respondents => ({ respondents, selected: [] }),
    removeRespondent: ({ respondents }) => id => ({
        respondents: remove(findIndex(propEq('id', id), respondents), 1, respondents)
    }),
    setFromRespondents: () => fromRespondents => ({ fromRespondents, name: null, project: null, businessUnit: null, selected: [] }),
    setName: () => name => ({ name, selected: [] }),
    setProject: () => project => ({ project, selected: [] }),
    setSelected: () => selected => ({ selected }),
    setOffset: () => offset => ({ offset, selected: [] })
})(
    asyncConnect(({ hideAll, item }) => ({
        getCompanyEmployees: getCompanyEmployees
            .withPayload(({ company, businessUnit }) => ({
                company,
                limit: 0,
                q: {
                    businessUnit
                }
            }))
            .withSaga(function* (getProps) {
                yield takeEvery([toSuccess(POST_COMPANY_RESPONDENT), toSuccess(PUT_COMPANY_RESPONDENT), toSuccess(POST_COMPANY_RESPONDENTS)], function(action) {
                    const { getCompanyEmployees } = getProps();

                    if (action.payload.employee) {
                        getCompanyEmployees.refresh();
                    }
                })
            })
            .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true }),
        getRespondents: getRespondents
            .withParams(() => ({ type: 'selected' }))
            .withPayload(({ company, item: { id }}) => ({
                q: {
                    company,
                    projects: [id],
                },
                limit: 0
            }))
            .withSuccessHandler(({ getRespondents: { data: { items = [] }}, setRespondents }) => setRespondents(items))
            .withOptions({ dispatchOnMount: !hideAll && item.id, resetOnUnmount: true, dispatchOnUpdate: true }),
        getAllRespondents: getRespondents
            .withParams(() => ({ type: 'unselected' }))
            .withPayload(({ project, company, name, offset }) => ({
                q: {
                    company,
                    projects: project ? [project] : null,
                    text: name
                },
                offset,
                limit: LINES_PER_PAGE
            }))
            .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true }),
        deleteCompanyRespondent: deleteCompanyRespondent
            .withSuccessHandler(({ removeRespondent, deleteCompanyRespondent: { data: { id } }, refreshItem }) => {
                removeRespondent(id);
                refreshItem && refreshItem();
                message.success('Респондент успешно удален');
            })
            .withErrorHandler(() => message.error('Не удалось удалить респондента'))
            .withOptions({ resetOnUnmount: true }),
        postCompanyRespondent: postCompanyRespondent
            .withSuccessHandler(({ postCompanyRespondent: { data }, addRespondent, refreshItem }) => {
                addRespondent(data);
                refreshItem && refreshItem();
            })
            .withOptions({ resetOnUnmount: true }),
        postCompanyRespondents: postCompanyRespondents
            .withSuccessHandler(({ postCompanyRespondents: { data }, setRespondents, respondents, refreshItem }) => {
                setRespondents(respondents.concat(data));
                refreshItem && refreshItem();
            })
            .withOptions({ resetOnUnmount: true }),
        getBusinessUnits: getBusinessUnits
            .withPayload(({ company }) => company)
            .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
        getProjects: getProjects
            .withPayload(() => ({
                limit: 0
            }))
            .withOptions({ dispatchOnMount: true, resetOunUnmount: true })
    }), null, { openCompanyRespondentModal, openImportRespondentsModal })(
        withFormWrapper(withStepsPagination(ProjectRespondentsStep), {
            mapPropsToValues: ({ testSuites }) => ({
                test: path([0, 'id'], testSuites)
            })
        })
    )
);
