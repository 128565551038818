import React, { Component, Fragment } from 'react';
import { Radio, Button, Select } from 'antd';
import styled from 'styled-components';
import { prop } from 'ramda';
import { SettingOutlined } from '@ant-design/icons';

import { COMPANY_PAGE_COLORS, COMPANY_PAGE_FONTS } from '../../../constants/companies';
import ColorPicker from './ColorPicker';
import QuestionPreviewPage from './QuestionPreviewPage';
import WelcomePreviewPage from './WelcomePreviewPage';
import Modal from '../Modal';

const StyledModal = styled(Modal)`
    &.ant-modal{
        top: 0;
        overflow: hidden;
        padding-bottom: 0;
    }
    .ant-modal-close-x {
        margin-top: 5px;
    }
    .ant-modal-body {
        padding: 0;
        height: calc(100vh - 65px);
        overflow-y: auto;
        overflow-x: hidden;
        background: ${({ background }) => background || '#FCFCFC'};
        position: relative;
    }
    .ant-modal-content {
        border-radius: 0;
    }
`;

const Sidebar = styled.div`
    position: fixed;
    top: 84px;
    right: ${({ opened }) => opened ? 0 : -270}px;
    background: #fff;
    height: 100%;
    border-left: 1px solid #e8e8e8;
    border-top: 1px solid #e8e8e8;
    transition: right .3s;
    width: 270px;
`;

const SidebarToggleButton = styled.div`
    position: absolute;
    top: -1px;
    left: -41px;
    border-left: 1px solid #e8e8e8;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    cursor: pointer;
    font-size: 22px;
    background: #fff;
    width: 40px;
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px 0 0 5px;
`;

const SidebarTitle = styled.h3`
    border-bottom: 1px solid #e8e8e8;
    height: 40px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
`;

const SidebarBody = styled.div`
    padding: 15px;
    overflow-y: auto;
    height: calc(100% - 105px);
`;

const ColorSetting = styled.div`
    display: flex;
    margin-bottom: 5px;
`;

const ColorSettingTitle = styled.div`
    font-size: 13px;
    margin-left: 10px;
    padding-top: 3px;
`;

const FontFamilyTitle = styled.div`
    font-size: 13px;
    margin: 10px 0 5px;
`;

const Footer = styled.div`
    background: ${({ colors }) => colors.footer || '#fff'};
    color: ${({ colors }) => colors.footerText || '#000'};
    min-height: 50px;
    border-top: 1px solid #ccc;
    padding: 15px;
    font-family: ${prop('fontFamily')};
    * {
        margin: 0;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
`;

export default class CompanyDesignPageModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: 'welcome',
            opened: false,
            colors: props.params.designScheme.colors || {},
            fontFamily: props.params.designScheme.fontFamily
        };
    }

    onChangeType = e => {
        this.setState({ type: e.target.value });
    }

    onChangeColor = (field, color) => this.setState(prev => ({
        colors: {
            ...prev.colors,
            [field]: color
        }
    }));

    onChangeFont = fontFamily => this.setState({ fontFamily });

    toggleSettings = () => this.setState(prev => ({ opened: !prev.opened }));

    save = () => {
        const { colors, fontFamily } = this.state;

        this.props.params.onChange({
            colors,
            fontFamily
        });
        this.props.params.closeOnChange && this.props.close();
    }

    renderTitle = () => {
        return <Fragment>
            <Radio.Group onChange={this.onChangeType} value={this.state.type}>
                <Radio.Button value='welcome'>Приветственная страница</Radio.Button>
                <Radio.Button value='question'>Страница вопроса</Radio.Button>
            </Radio.Group>
            { !this.props.disabled &&
                <Button type='primary' onClick={this.save} style={{ marginLeft: 15 }}>
                    Сохранить
                </Button>
            }
        </Fragment>;
    }

    renderSidebar = () => {
        return <Sidebar opened={this.state.opened}>
            <SidebarToggleButton onClick={this.toggleSettings}>
                <SettingOutlined />
            </SidebarToggleButton>
            <SidebarTitle>Параметры визуализации</SidebarTitle>
            <SidebarBody>
                { COMPANY_PAGE_COLORS.map(color =>
                    <ColorSetting key={color.field}>
                        <ColorPicker
                            value={this.state.colors[color.field] || color.default}
                            onChange={value => this.onChangeColor(color.field, value)} />
                        <ColorSettingTitle>{ color.title }</ColorSettingTitle>
                    </ColorSetting>
                )}
                <FontFamilyTitle>Шрифт</FontFamilyTitle>
                <Select
                    value={this.state.fontFamily}
                    onChange={this.onChangeFont}
                    placeholder='Выберите шрифт'
                    style={{ width: '100%' }}>
                    { COMPANY_PAGE_FONTS.map(option =>
                        <Select.Option key={option.value} value={option.value}>
                            { option.label }
                        </Select.Option>
                    )}
                </Select>
            </SidebarBody>
        </Sidebar>;
    }

    render() {
        const { modal, params: { designScheme }, disabled } = this.props;
        const { colors, fontFamily } = this.state;

        return <Modal
            {...modal}
            title={this.renderTitle()}
            width='100%'
            background={colors.background}
            footer={null}
            ModalComponent={StyledModal}>
            <Content>
                { this.state.type === 'welcome' ?
                    <WelcomePreviewPage
                        logo={designScheme.logo}
                        colors={colors}
                        fontFamily={fontFamily}
                        showName /> :
                    <QuestionPreviewPage
                        logo={designScheme.logo}
                        showName
                        title='Текст вопроса'
                        answerVariants={[{ title: 'Первый вопрос' }, { title: 'Второй вопрос' }]}
                        type='choice'
                        colors={colors}
                        fontFamily={fontFamily} />
                }
                <Footer fontFamily={fontFamily} colors={colors}>
                    <div dangerouslySetInnerHTML={{ __html: designScheme.footerText }} />
                </Footer>
            </Content>
            { !disabled && this.renderSidebar() }
        </Modal>;
    }
}
