import React from 'react';

import BaseFilter from './BaseFilter';
import withFilterForm from '../../hocs/withFilterForm';
import Select from '../formComponents/Select';
import { MESSAGE_HISTORY_TYPE } from '../../../constants/companies';

export default withFilterForm(() =>
    <BaseFilter
        span={12}
        searchFieldName='type'
        component={Select}
        options={MESSAGE_HISTORY_TYPE} />
);
