import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { prop } from 'ramda';

import Input from './formComponents/Input';
import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import { openCompanyDesignPageModal } from '../../actions/modalActions';

class TestForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='name'
                component={Input}
                label='Название' />
            <Field
                name='displayedName'
                component={Input}
                label={<span>Название для респондента <small>(оставьте пустым чтобы использовать название теста)</small></span>}
                hideOptional />
            <SubmitButton type='primary'>Сохранить</SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    name: yup.string().required()
});

export default withFormWrapper(
    connect(null, { openCompanyDesignPageModal })(TestForm),
    {
        mapPropsToValues: prop('item'),
        validationSchema
    }
);
