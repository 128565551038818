import React, { Component, Fragment } from 'react';
import { asyncConnect, toSuccess } from 'react-async-client';
import { Spin, Tabs } from 'antd';
import styled from 'styled-components';
import { takeEvery } from 'redux-saga/effects';
import { path, find, propEq, filter, contains } from 'ramda';

import Route from '../../Route';
import { getSurvey, getUser } from '../../../actions/asyncActions';
import { PUT_SURVEY, PATCH_SURVEY } from '../../../constants/actionTypes';
import SubmitButton from '../../forms/formComponents/SubmitButton';
import withErrorHandler from '../../hocs/withErrorHandler';
import { ADMIN } from '../../../constants/roles';
import DetailToolbar from '../DetailToolbar';
import SurveyInfoTab, { SurveyInfoTabButtons } from './SurveyInfoTab';
import SurveySettingsTab from './SurveySettingsTab';
import SurveyDriversTab from './SurveyDriversTab';
import SurveyFormTab from './SurveyFormTab';
import SurveyRespondentsTab, { SurveyRespondentsTabButtons } from './SurveyRespondentsTab';
import SurveyRespondent, { SurveyRespondentButtons } from './SurveyRespondent';

const StyledTabs = styled(Tabs)`
    .ant-tabs-bar {
        margin: 0;
    }
`;

const ToolbarTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: calc(100% - 32px);
    h1 {
        padding-right: 10px;
    }
`;

const tabs = [
    { title: 'Информация', key: 'info', component: SurveyInfoTab, buttons: SurveyInfoTabButtons },
    { title: 'Настройки', key: 'settings',  component: SurveySettingsTab, routes: [
        { path: '/surveys/:id/:type/:tab', component: SurveySettingsTab }
    ], hide: props => !path(['isAdmin'], props) },
    { title: 'Форма', key: 'form', component: SurveyFormTab },
    { title: 'Вопросы', key: 'drivers', component: SurveyDriversTab },
    { key: 'respondents', title: 'Респонденты', component: SurveyRespondentsTab, buttons: SurveyRespondentsTabButtons, routes: [{
        path: '/surveys/:id/respondents/:itemId', component: SurveyRespondent
    }] },
];

class Survey extends Component  {
    state = {
        tabSubmit: false
    };

    tabs = {};

    setTabSubmit = tabSubmit => this.setState({ tabSubmit });

    onChange = key => {
        const { history, match: { params: { id, type }}} = this.props;
        const formRef = this.tabs[type] && this.tabs[type]();

        if (formRef && !formRef.props.form.getState().pristine) {
            const formState = formRef.props.form.getState();

            !formState.invalid && this.setTabSubmit(true);
            formRef.props.form.submit();
            !formState.invalid && history.replace(`/surveys/${id}/${key}`);
        } else {
            history.replace(`/surveys/${id}/${key}`);
        }
    }

    renderTitle = () => {
        const { getSurvey: { data, meta }, match: { params: { type, itemId }} } = this.props;
        const Buttons = (!itemId && path(['buttons'], find(propEq('key', type), tabs))) || this.getButtons();

        return <ToolbarTitle>
            <h1>{ data.name }</h1>
            { meta.lastSucceedAt && Buttons && (
                <Buttons
                    {...this.props}
                    survey={data}
                />
            )}
        </ToolbarTitle>;
    }

    getButtons = () => {
        const { match: { params: { type, itemId }}} = this.props;

        if (itemId && contains(type, ['respondents'])) {
            return SurveyRespondentButtons;
        }

        return null;
    }

    getSubmitButton = () => {
        return <SubmitButton onClick={() => this.setTabSubmit(false)}>
            Сохранить
        </SubmitButton>;
    }

    getTabs = () => {
        return filter(tab => tab.hide ? !tab.hide(this.props) : true, tabs);
    }

    render() {
        const { getSurvey: { data, meta }, match: { params: { type, id }} } = this.props;

        return <Fragment>
            <DetailToolbar
                title={this.renderTitle()}
                onlyBackUrl
                backUrl={'/surveys'} />
            { meta.pending && !meta.lastSucceedAt ?
                <Spin /> :
                <StyledTabs
                    onChange={this.onChange}
                    activeKey={type || ''}
                    animated={{ tabPane: false }}>
                    { this.getTabs().map(tab =>
                        <Tabs.TabPane key={tab.key} tab={tab.title}>
                            { (tab.routes || []).map(route =>
                                <Route key={path} path={route.path} render={props =>
                                    <route.component
                                        {...props}
                                        survey={data}
                                    />
                                } />
                            )}
                            <Route path={`/surveys/${id}/${tab.key}`} exact render={props =>
                                <tab.component
                                    {...props}
                                    getRef={node => this.tabs[tab.key] = node}
                                    survey={data}
                                    itemMeta={meta}
                                    submitBtn={this.getSubmitButton()}
                                    tabSubmit={this.state.tabSubmit}
                                    id={id} />
                            } />
                        </Tabs.TabPane>
                    )}
                </StyledTabs>
            }
        </Fragment>;
    }
}

const stateToProps = state => ({
    isAdmin: getUser.selectData(state).role === ADMIN
});

export default asyncConnect({
    getSurvey: getSurvey
        .withPayload(({ match: { params: { id }}}) => ({ id }))
        .withSaga(function* (getProps) {
            yield takeEvery([
                toSuccess(PUT_SURVEY),
                toSuccess(PATCH_SURVEY),
            ], function() {
                const { getSurvey } = getProps();

                getSurvey.refresh();
            })
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
}, stateToProps)(withErrorHandler('getSurvey')(Survey));
