import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { withAsyncActions } from 'react-async-client';
import { message, Button, Tooltip } from 'antd';
import { path, filter } from 'ramda';
import { connect } from 'react-redux';
import { SettingOutlined } from '@ant-design/icons';

import ProjectCategoryLevelStep from '../../forms/project/ProjectCategoryLevelStep';
import CustomProjectCategoryLevelStep from '../../forms/project/CustomProjectCategoryLevelStep';
import { putCompanyTests } from '../../../actions/asyncActions';
import { openProjectQuestionsSettingsModal } from '../../../actions/modalActions';
import SubmitButton from '../../forms/formComponents/SubmitButton';

const Wrapper = styled.div`
    padding: 15px;
`;

class ProjectCategoriesTabButtonsComponent extends Component {
    openSettings = () => {
        const { getCompanyProject: { data }, openProjectQuestionsSettingsModal } = this.props;

        openProjectQuestionsSettingsModal(data);
    }

    render() {
        const { getCompanyProject: { meta }} = this.props;

        return <Button icon={<SettingOutlined />} onClick={this.openSettings} disabled={meta.pending}>
            Настройки сложностей
        </Button>;
    }
}

export const ProjectCategoriesTabButtons = connect(null, { openProjectQuestionsSettingsModal })(ProjectCategoriesTabButtonsComponent);

class ProjectCategoriesTab extends Component {
    render() {
        const { project, itemMeta, putCompanyTests, getRef, testSuites, testSuitesMeta } = this.props;
        const isCustom = project.type === 'custom';
        const CategoriesForm = isCustom ? CustomProjectCategoryLevelStep : ProjectCategoryLevelStep;
        const testTemplate = !!path([0, 'testTemplate'], testSuites);
        const disabledSave = !filter(({ status }) => status !== 'sent', testSuites || []).length;

        return <Wrapper className='wrap-bg'>
            <CategoriesForm
                getRef={getRef}
                item={project}
                itemMeta={itemMeta}
                testSuitesMeta={testSuitesMeta}
                submitBtn={!testTemplate ?
                    (disabledSave ?
                        <Tooltip title='Тест заблокирован'>
                            <Button
                                type='primary'
                                disabled>
                                Сохранить
                            </Button>
                        </Tooltip> :
                        <SubmitButton type='primary'>Сохранить</SubmitButton>
                    )
                    : <Fragment />
                }
                formAction={putCompanyTests}
                testTemplate={testTemplate}
                testSuites={testSuites}
                edit />
        </Wrapper>;
    }
}

export default withAsyncActions({
    putCompanyTests: putCompanyTests
        .withSuccessHandler(() => message.success('Тест успешно сохранен'))
        .withErrorHandler(() => message.error('Не удалось сохранить тест'))
        .withOptions({ resetOnUnmount: true })
})(ProjectCategoriesTab);
