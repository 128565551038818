import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import Modal from './Modal';
import CategoryRecommendationForm from '../forms/CategoryRecommendationForm';
import { putCategoryRecommendations, getCategoryRecommendations } from '../../actions/asyncActions';

class CategoryRecommendationModal extends Component {
    render() {
        const { modal, putCategoryRecommendations, params, getCategoryRecommendations } = this.props;

        return <Modal
            {...modal}
            title={params.add ? 'Добавить рекомендацию' : 'Редактировать рекомендацию'}
            footer={null}>
            <CategoryRecommendationForm
                formAction={putCategoryRecommendations}
                id={params.id}
                add={params.add}
                index={params.index}
                item={getCategoryRecommendations.data} />
        </Modal>;
    }
}

export default withAsyncActions({
    getCategoryRecommendations: getCategoryRecommendations
        .withParams(({ params }) => ({ category: params.id })),
    putCategoryRecommendations: putCategoryRecommendations
        .withParams(({ params }) => ({ category: params.id }))
        .withSuccessHandler(({ close }) => {
            message.success('Рекомендация успешно сохранены');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось сохранить рекомендацию'))
        .withOptions({ resetOnUnmount: true })
})(CategoryRecommendationModal);
