import React from 'react';
import { Redirect } from 'react-router-dom';
import { any } from 'ramda';
import { connect } from 'react-redux';

import { getUser } from '../actions/asyncActions';
import Route from './Route';

const SecureRoute = ({ user, roles, cond, ...props }) =>
    (cond ? (!roles ? cond(user) : cond(user) && any(r => user.role === r, roles)) : (!roles || any(r => user.role === r, roles))) ?
        <Route {...props} /> :
        <Redirect to='/' />;

const stateToProps = state => ({
    user: getUser.selectData(state),
});

export default connect(stateToProps)(SecureRoute);
