import { Button, Spin, Table } from 'antd';
import { path, filter } from 'ramda';
import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { postEnsiContracts } from '../../actions/asyncActions';

import Modal from './Modal';

class ExternalContractorModal extends Component {
    state = {
        selectedContract: null,
        selectedBankAccount: null
    };

    getContractColumns = () => {
        return [
            {
                title: 'Юридическое лицо ANCOR',
                dataIndex: 'organization',
                key: 'organization'
            },
            {
                title: 'Наименование договора',
                key: 'contractName',
                dataIndex: 'contractName'
            },
            {
                title: 'Код договора',
                dataIndex: 'code',
                key: 'code'
            }
        ];
    }

    getBankAccountsColumns = () => {
        return [
            {
                title: 'Название банка и город',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: 'Расчетный счет',
                dataIndex: 'number',
                key: 'number'
            },
            {
                title: 'Корреспондентский счет',
                dataIndex: 'bankCorrespondentAccount',
                key: 'bankCorrespondentAccount'
            },
            {
                title: 'БИК',
                dataIndex: 'bic',
                key: 'bic'
            }
        ]
    };

    saveData = () => {
        const { selectedContract, selectedBankAccount } = this.state;

        const data = filter(value => !!value, {
            name: path(['data', 'contractor'], this.props.postEnsiContracts),
            ensiContractorName: path(['organization'], selectedContract),
            ensiContractor: path(['data', 'ensiCode'], this.props.postEnsiContracts),
            ensiContractName: path(['contractName'], selectedContract),
            ensiContractCode: path(['code'], selectedContract),
            bankName: path(['name'], selectedBankAccount),
            paymentAccount: path(['number'], selectedBankAccount),
            correspondentAccount: path(['bankCorrespondentAccount'], selectedBankAccount),
            bic: path(['bic'], selectedBankAccount)
        });

        this.props.params.onSave(data);
        this.props.close();
    }

    render() {
        const { modal, postEnsiContracts: { meta, data }} = this.props;

        return <Modal
            {...modal}
            title='Контрагент'
            footer={null}>
            { meta.pending ?
                <div style={{ textAlign: 'center' }}>
                    <Spin style={{ height: 40 }} />
                    <div>Идет загрузка данных</div>
                </div> : meta.error ? <div>Ошибка загрузки данных</div> :
                meta.success ? (
                    data.ensiError ||
                        <div>
                            <h3>Договор</h3>
                            <Table
                                dataSource={data.contracts || []}
                                columns={this.getContractColumns()}
                                pagination={false}
                                rowKey='code'
                                rowSelection={{
                                    type: 'radio',
                                    onChange: (_, selectedRows) => {
                                        this.setState(prev => ({
                                            selectedContract: selectedRows[0],
                                            selectedBankAccount: prev.selectedBankAccount && prev.selectedBankAccount.organizationCode === selectedRows[0].organizationCode ? prev.selectedBankAccount : null
                                        }));
                                    },
                                    selectedRowKeys: this.state.selectedContract ? [this.state.selectedContract.code] : null
                                }} />
                            <h3 style={{ marginTop: 25 }}>Банковские реквизиты</h3>
                            <Table
                                dataSource={filter(({ organizationCode }) =>
                                    this.state.selectedContract ? organizationCode === this.state.selectedContract.organizationCode : true, data.bankAccounts || []
                                )}
                                columns={this.getBankAccountsColumns()}
                                pagination={false}
                                rowKey='number'
                                rowSelection={{
                                    type: 'radio',
                                    onChange: (_, selectedRows) => {
                                        this.setState({ selectedBankAccount: selectedRows[0] });
                                    },
                                    selectedRowKeys: this.state.selectedBankAccount ? [this.state.selectedBankAccount.number] : null
                                }} />
                            <Button
                                style={{ marginTop: 15 }}
                                type='primary'
                                disabled={!this.state.selectedBankAccount && !this.state.selectedContract}
                                onClick={this.saveData}>
                                Сохранить
                            </Button>
                        </div>
                ) : null
            }
        </Modal>;
    }
}

export default withAsyncActions({
    postEnsiContracts: postEnsiContracts
        .withPayload(({ params }) => params.data)
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})(ExternalContractorModal);
