import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { Button, Row, Col } from 'antd';
import { forEachObjIndexed, prop } from 'ramda';
import * as yup from 'yup';
import styled from 'styled-components';
import { withState } from 'recompose';
import { connect } from 'react-redux';

import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import Input from './formComponents/Input';
import Select from './formComponents/Select';
import { openExternalContractorModal } from '../../actions/modalActions';
import ListenerField from './ListenerField';
import { getAcceptors } from '../../actions/asyncActions';

const SubTitle = styled.h3`
    border-bottom: 1px solid #efefef;
    background: #fafafa;
    margin: -15px -15px 10px;
    padding: 5px 24px;
    display: block;
    clear: both;
`;
const BlockWrap = styled(Row)`
    margin-bottom: 30px;
    .ant-form-item {
        margin-bottom: 0;
    }
`;

class CompanyDetailsForm extends Component {
    renderAdressFields = (name, formDisabled) => (
        <BlockWrap gutter={5}>
            <Col span={5}>
                <Field
                    name={`${name}.index`}
                    component={Input}
                    disabled={formDisabled}
                    label='Индекс' />
            </Col>
            <Col span={7}>
                <Field
                    name={`${name}.city`}
                    component={Input}
                    disabled={formDisabled}
                    label='Город' />
            </Col>
            <Col span={12}>
                <Field
                    name={`${name}.address`}
                    component={Input}
                    disabled={formDisabled}
                    label='Улица, дом, корпус' />
            </Col>
        </BlockWrap>
    )

    onChooseContract = data => {
        this.props.form.batch(() => {
            forEachObjIndexed((value, key) => this.props.form.change(key, value), data);
        });
    }

    render() {
        const { edit, toggleEdit, refreshItem, hideCancel, disableEdit, openExternalContractorModal, company } = this.props;
        const formDisabled = !edit;

        return <Fragment>
            <SubTitle>Информация о клиенте</SubTitle>
            <BlockWrap gutter={5} align='top'>
                <Col span={8}>
                    <Field
                        name='tin'
                        component={Input}
                        disabled={formDisabled}
                        label='ИНН' />
                </Col>
                <Col span={8}>
                    <Field
                        name='kpp'
                        component={Input}
                        disabled={formDisabled}
                        label='КПП' />
                </Col>
                <ListenerField listenFieldName={['tin', 'kpp']}>
                    { ({ tin, kpp }) =>
                        <Col span={8}>
                            <Button
                                style={{ marginTop: '40px',width: '100%' }}
                                onClick={() => openExternalContractorModal({
                                    data: {
                                        company: company.id,
                                        'INN': tin,
                                        'KPP' :kpp,
                                    },
                                    onSave: this.onChooseContract
                                })}
                                disabled={!tin || !kpp || !edit}>
                                Получить данные по контрагенту
                            </Button>
                        </Col>
                    }
                </ListenerField>
                <Col span={16}>
                    <Field
                        name='name'
                        component={Input}
                        disabled={formDisabled}
                        label='Юридическое название компании' />
                </Col>
                <Col span={8}>
                    <Field
                        name='ensiContractor'
                        component={Input}
                        disabled
                        label='Код контрагента' />
                </Col>
            </BlockWrap>

            <SubTitle>Юридический адрес компании</SubTitle>
            { this.renderAdressFields('address', formDisabled) }

            <SubTitle>Информация по договору</SubTitle>
            <BlockWrap gutter={5}>
                <Col span={12}>
                    <Field
                        name='ensiContractorName'
                        component={Input}
                        disabled
                        label='Юридическое лицо Ancor' />
                </Col>
                <Col span={12}>
                    <Field
                        name='ensiContractName'
                        component={Input}
                        disabled
                        label='Наименование договора' />
                </Col>
                <Col span={12}>
                    <Field
                        name='ensiContractCode'
                        component={Input}
                        disabled
                        label='Код договора' />
                </Col>
                <Col span={12}>
                    <Field
                        name='acceptor'
                        component={Select}
                        label='Подписант'
                        action={getAcceptors}
                        namePath='name'
                        disabled={formDisabled}
                        searchable
                        allowClear />
                </Col>
            </BlockWrap>

            <SubTitle>Банковские реквизиты получателя</SubTitle>
            <BlockWrap gutter={5}>
                <Col span={24}>
                    <Field
                        name='bankName'
                        component={Input}
                        disabled={formDisabled}
                        label='Название банка и город' />
                </Col>
                <Col span={8}>
                    <Field
                        name='correspondentAccount'
                        component={Input}
                        disabled={formDisabled}
                        label='Корреспондентский счет' />
                </Col>
                <Col span={8}>
                    <Field
                        name='paymentAccount'
                        component={Input}
                        disabled={formDisabled}
                        label='Расчетный счет' />
                </Col>
                <Col span={8}>
                    <Field
                        name='bic'
                        component={Input}
                        disabled={formDisabled}
                        label='БИК' />
                </Col>
            </BlockWrap>
            { edit ? (
                <Button.Group>
                    <SubmitButton type='primary'>Сохранить</SubmitButton>
                    { !hideCancel &&
                        <Button onClick={() => {
                            toggleEdit(false);
                            refreshItem();
                        }}>Отменить</Button>
                    }
                </Button.Group>
            ) : (
                !disableEdit && <Button type='primary' onClick={() => toggleEdit(true)}>Редактировать</Button>
            )}
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    name: yup.string().required(),
    bankName: yup.string().required(),
    tin: yup.string().required(),
    correspondentAccount: yup.string().required(),
    paymentAccount: yup.string().required(),
    bic: yup.string().required(),
    kpp: yup.string().required(),
});

export default connect(null, { openExternalContractorModal })(
    withState('edit', 'toggleEdit', ({ disabled }) => !disabled)(
        withFormWrapper(CompanyDetailsForm, {
            validationSchema,
            onSubmitSuccess: ({ toggleEdit }) => {
                toggleEdit(false);
            },
            mapPropsToValues: props => ({
                companyId: prop('id', props.company),
                ...prop('item', props)
            }),
            mapBeforeSubmit: (values, { mapBeforeSubmit }) => mapBeforeSubmit ? mapBeforeSubmit(values) : values
        })
    )
);
