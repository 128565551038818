import React, { Component, Fragment } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import * as yup from 'yup';
import styled from 'styled-components';
import { Form, Col, Row, Tooltip, Input as AntdInput, Button } from 'antd';
import { toPairs, range, fromPairs, reduce, path, filter, omit, find, propEq, pathOr, findIndex } from 'ramda';
import createDecorator from 'final-form-calculate';
import { QuestionCircleOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import Switch from './formComponents/Switch';
import Select from './formComponents/Select';
import { DISTRIBUTION } from '../../constants/companies';
import Textarea from './formComponents/Textarea';
import LevelTimeMasked from './formComponents/LevelTimeMasked';
import InputNumber from './formComponents/InputNumber';
import Complexity from '../user/table/Complexity';
import ListenerField from './ListenerField';

const Distribution = styled.div`
    border-top: 1px solid #e8e8e8;
    padding: 15px 0;
    position: relative;
    .ant-form-item-control-input-content {
        & > div:last-child {
            position: static;
        }
    }
`;

const DistributionComplexity = styled.div`
    display: inline-flex;
    border: 1px solid #e8e8e8;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0px;
`;

const Proportion = styled.div`
    display: inline-flex;
    align-items: center;
    margin-right: 15px;
    .ant-row.ant-form-item {
        margin-bottom: 0;
    }
`;

const Grade = styled.div`
    display: flex;
    margin-bottom: 10px;
    .ant-row.ant-form-item {
        margin-bottom: 0;
        margin-left: 5px;
    }
`;

const TwoInputs = styled.div`
    display: flex;
    .ant-input-number {
        width: 60px;
    }
    .ant-form-item {
        .ant-input-number {
            border-right: none;
            border-radius: 6px 0 0 6px;
        }
    }
    .ant-form-item:last-of-type {
        .ant-input-number {
            border-radius: 0;
        }
    }
    .ant-btn {
        border-radius: 0 6px 6px 0;
    }
`;

const ProportionField = styled.div`
    display: flex;
    align-items: center;
`;

class DistributionForm extends Component {
    renderProportionInputs = (level, fields) => {
        const index = findIndex(propEq('level', level), fields.value);

        return <TwoInputs>
            <Field
                name={`${fields.name}[${index}].count`}
                component={InputNumber}
                min={1}
                parse={value => value || 1}
                disableClear />
            <Field
                name={`${fields.name}[${index}].cost`}
                component={InputNumber}
                min={1}
                parse={value => value || 1}
                disableClear />
            <Button icon={<DeleteOutlined />} onClick={() => fields.remove(index)} />
        </TwoInputs>;
    }

    renderProportionField = ({ fields }, isCase) => {
        return <ProportionField>
            { [1, 2, 3].map(level =>
                <Proportion key={`proportion-${level}`}>
                    <Complexity level={level} />
                    { find(propEq('level', level), fields.value) ?
                        this.renderProportionInputs(level, fields) :
                        <Button icon={<PlusOutlined />} onClick={() => fields.push({ level, count: 1, case: isCase, cost: 1 })} />
                    }
                </Proportion>
            )}
        </ProportionField>;
    }

    renderGradeField = ({ fields }, item) => {
        return fields.map((name, index) =>
            <Grade key={name}>
                <span style={{ lineHeight: '32px' }}>{ index }:</span>
                <span style={{ margin: '0 5px 0 10px', lineHeight: '32px' }}>от</span>
                <AntdInput
                    style={{ width: 90, minWidth: 90, maxHeight: 32 }}
                    value={!index ? 0 : Number(fields.value[index - 1].count || 0) + 1}
                    disabled />
                <span style={{ margin: '0 0 0 5px', lineHeight: '32px' }}> до </span>
                <Field
                    name={`${name}.count`}
                    component={InputNumber}
                    min={index === fields.length - 1 ? null : !index ? 0 : (fields.value[index - 1].count || 0) + 1}
                    max={reduce((res, cur) => res + (cur.count * cur.cost), 0, item.proportions.concat(item.proportionsCase || []))}
                    disabled={index === fields.length - 1}
                />
            </Grade>
        );
    }

    renderDistributionFields = ({ fields }) => {
        return fields.map((name, index) =>
            <Distribution key={name}>
                <DistributionComplexity>
                    <Complexity level={index + 1} />
                </DistributionComplexity>
                <Row gutter={16}>
                    <Col span={15}>
                        <Field
                            name={`${name}.time`}
                            component={LevelTimeMasked}
                            label='Время'
                            hideComplexity
                            hideOptional />
                        <Form.Item wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} label={
                            <span>
                                Вопросы
                                <Tooltip title='количество / вес'>
                                    <QuestionCircleOutlined style={{ marginLeft: 7 }} />
                                </Tooltip>
                            </span>
                        }>
                            <FieldArray name={`${name}.proportions`} onChange={this.onChangeProportions}>
                                { this.renderProportionField }
                            </FieldArray>
                        </Form.Item>
                        <ListenerField listenFieldName='type'>
                            { ({ type }) => type === 'case' &&
                                <Form.Item wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} label='Кейсы'>
                                    <FieldArray name={`${name}.proportionsCase`}>
                                        { props => this.renderProportionField(props, true) }
                                    </FieldArray>
                                </Form.Item>
                            }
                        </ListenerField>
                        <Field name={`${name}.proportionsCase`} component={() => null} />
                    </Col>
                    <Col span={9}>
                        <Form.Item wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} label='Оценка'>
                            <FieldArray name={`${name}.grade`}>
                                { props => this.renderGradeField(props, fields.value[index]) }
                            </FieldArray>
                            <FormSpy subscriptions={{ errors: true }}>
                                { ({ errors, submitFailed }) => {
                                    const error = path(['_arrays', 'distributions', index, 'grade'], errors);
                                    return error && submitFailed ? <div style={{ color: '#f5222d' }}>{ error }</div> : null;
                                }}
                            </FormSpy>
                        </Form.Item>
                    </Col>
                </Row>
            </Distribution>
        );
    }

    onChangeType = () => {
        const distributions = this.props.form.getState().values.distributions.map(item => ({
            ...item,
            proportionsCase: null
        }));
        this.props.form.change('distributions', distributions);
    }

    render() {
        return <Fragment>
            <Row gutter={16}>
                <Col span={12}>
                    <Field
                        name='type'
                        component={Select}
                        label='Тип'
                        options={DISTRIBUTION}
                        onChange={this.onChangeType} />
                </Col>
                <Col span={12}>
                    <Field
                        name='default'
                        component={Switch}
                        label='Распределение по умолчанию'
                        hideOptional />
                </Col>
            </Row>
            <Field
                name='description'
                component={Textarea}
                label='Описание' />
            <FieldArray name='distributions'>
                { this.renderDistributionFields }
            </FieldArray>
            <SubmitButton type='primary'>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

yup.addMethod(yup.object, 'compare', function() {
    return this.test({
        name: 'compare',
        exclusive: true,
        params: { additionalPath: ['count'] },
        message: 'Значение должно быть больше предыдущего и меньше следующего',
        test: function(value) {
            const prev = this.parent[value.level - 1];
            const next = this.parent[value.level + 1];
            const prevCount = pathOr(0, ['count'], prev);
            const nextCount = pathOr(0, ['count'], next);
            const count = pathOr(0, ['count'], value);

            return prev && next ? prevCount < count && count < nextCount :
                prev && !next ? prevCount < count : true;
        }
    });
});

const validationSchema = yup.object().shape({
    type: yup.string().required(),
    distributions: yup.array().of(yup.object().shape({
        grade: yup.array().of(yup.object().shape({
            count: yup.number().nullable().required()
        }).compare()),
        proportions: yup.array().of(yup.object().shape({
            count: yup.number().required()
        }))
    }))
});

const decorators = createDecorator(
    {
        field: /distributions\[\d+\].proportions/,
        updates: {
            distributions: (_, allValues) => {
                return allValues.distributions.map(item => ({
                    ...item,
                    grade: item.grade.map((g, index) => ({
                        ...g,
                        count: index === item.grade.length - 1 ?
                            reduce((res, cur) => res + (cur.count * cur.cost), 0, item.proportions.concat(item.proportionsCase || [])) :
                            g.count
                    }))
                }))
            }
        }
    }
);

export default withFormWrapper(DistributionForm, {
    mapPropsToValues: ({ item }) => ({
        ...item,
        type: item.type || 'simple',
        code: 'code',
        distributions: (item.distributions || [1, 2, 3].map(level => ({
            level,
            time: 1
        }))).map((item, index) => {
            const proportionsCase = filter(i => !!i.case, item.proportions || []);

            return {
                ...item,
                grade: item.grade ? toPairs(item.grade).map(([count, level]) => ({ level, count: Number(count || 0) })) : range(0, index + 2).map(level => ({ level, count: null })),
                proportions: item.proportions ? filter(i => !i.case, item.proportions) : [],
                proportionsCase: proportionsCase.length ? proportionsCase : null
            };
        })
    }),
    validationSchema,
    mapBeforeSubmit: values => ({
        ...values,
        distributions: values.distributions.map(item => {
            const proportions = item.proportionsCase ? item.proportions.concat(item.proportionsCase) : item.proportions;

            return {
                ...omit(['proportionsCase'], item),
                grade: fromPairs(item.grade.map(({ level, count }) => ([ count, level ]))),
                proportions
            };
        })
    }),
    decorators
});
