import React, { Component } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/ru_RU';

import withFieldWrapper from '../../hocs/withFieldWrapper';

class DatepickerComponent extends Component {
    onChange = date => {
        this.props.onChange(date ? date.toISOString() : null);
    }

    render() {
        const { input: { value }, placeholder, disabled, disabledDate } = this.props;

        return <DatePicker
            value={value ? moment(value) : null}
            onChange={this.onChange}
            format='DD.MM.YYYY'
            placeholder={placeholder}
            locale={locale}
            disabled={disabled}
            disabledDate={disabledDate}
        />;
    }
}

export default withFieldWrapper(DatepickerComponent);
