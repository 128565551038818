import React, { Component } from 'react';

import Modal from './Modal';
import ExpertForm from '../forms/ExpertForm';

export default class TestAuditModal extends Component {
    onSubmitSuccess = () => this.props.close();

    render() {
        const { modal, params: { id, formAction, item }} = this.props;

        return <Modal
            {...modal}
            title={id ? 'Редактировать эксперта' : 'Добавить эксперта'}
            footer={null}>
            <ExpertForm
                formAction={formAction}
                onSubmitSuccess={this.onSubmitSuccess}
                item={item} />
        </Modal>
    }
}
