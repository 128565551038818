import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import Modal from './Modal';
import { postRespondentsScreening, putRespondentScreening } from '../../actions/asyncActions';
import TestRespondentForm from '../forms/TestRespondentForm';

class TestRespondentModal extends Component {
    render() {
        const { modal, postRespondentsScreening, putRespondentScreening, params } = this.props;

        return <Modal
            {...modal}
            title={params.id ? 'Редактировать респондента' : 'Добавить респондента'}
            footer={null}>
            <TestRespondentForm
                formAction={params.id ? putRespondentScreening : postRespondentsScreening}
                item={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    postRespondentsScreening: postRespondentsScreening
        .withSuccessHandler(({ close }) => {
            message.success('Респондент успешно добавлен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось добавить респондента'))
        .withOptions({ resetOnUnmount: true }),
    putRespondentScreening: putRespondentScreening
        .withSuccessHandler(({ close }) => {
            message.success('Респондент успешно изменен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось изменить респондента'))
        .withOptions({ resetOnUnmount: true })
})(TestRespondentModal);
