import React, { Component } from 'react';
import { Button, Tag } from 'antd';
import { withAsyncActions } from 'react-async-client';
import { pluralize } from 'numeralize-ru';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';

import TableList from '../user/table/TableList';
import { getCompanyProjects } from '../../actions/asyncActions';
import Modal from './Modal';

const StyledModal = styled(Modal)`
    .ant-modal-body {
        padding: 0;
    }
`;

class AddClientToProjectModal extends Component {
    getColumns = () => {
        return [
            {
                title: 'Название',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: 'Тесты',
                dataIndex: ['_embedded', 'testSuites'],
                key: 'testSuites',
                render: tests => (tests || []).map(({ name, id }) => <div key={id}>{ name }</div>)
            },
            {
                title: 'Статус',
                dataIndex: 'status',
                key: 'status',
                render: status => status === 'active' ?
                    <Tag color='green'>Активен</Tag> : status === 'archived' ?
                    <Tag color='orange'>В архиве</Tag> : <Tag color='red'>Скрытый</Tag>
            },
            {
                dataIndex: 'id',
                key: 'add',
                render: (id, { testSuites }) => <Button icon={<PlusOutlined />} shape='circle' onClick={() => this.add(id, testSuites)} />
            }
        ];
    }

    add = (id, testSuites) => {
        const { params: { company }, postCompanyTestRespondents } = this.props;

        postCompanyTestRespondents.dispatch({
            company,
            project: id,
        });
    }

    render() {
        const { modal, params: { company, items }} = this.props;

        return <Modal
            {...modal}
            title={`Добавить в проект ${items.length} ${pluralize(items.length, 'сотрудника', 'сотрудников', 'сотрудников')}`}
            footer={null}
            ModalComponent={StyledModal}>
            <TableList
                staticFilter={{ status: 'active' }}
                payload={{ company }}
                action={getCompanyProjects}
                columns={this.getColumns()}
                prefix='projects' />
        </Modal>;
    }
}

export default withAsyncActions({

})(AddClientToProjectModal);
