import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import { postCompanyTest, putCompanyTest } from '../../actions/asyncActions';
import TestForm from '../forms/TestForm';
import Modal from './Modal';

class TestModal extends Component {
    render() {
        const { modal, params, putCompanyTest, postCompanyTest } = this.props;

        return <Modal
            {...modal}
            title={params.id ? 'Редактировать тест' : 'Добавить тест'}
            footer={null}>
            <TestForm
                formAction={params.id ? putCompanyTest : postCompanyTest}
                item={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    postCompanyTest: postCompanyTest
        .withSuccessHandler(({ close }) => {
            message.success('Тест был успешно добавлен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось добавить тест'))
        .withOptions({ resetOnUnmount: true }),
    putCompanyTest: putCompanyTest
        .withSuccessHandler(({ close }) => {
            message.success('Тест был успешно изменен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось изменить тест'))
        .withOptions({ resetOnUnmount: true })
})(TestModal);
