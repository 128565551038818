import React, { Component, Fragment } from 'react';
import { Select, Alert, Button, Tooltip, message, Row, Col } from 'antd';
import { pathOr, find, propEq, any, flatten, has, findIndex, filter, path, assocPath, last, pathEq, update, remove } from 'ramda';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { asyncConnect, toSuccess } from 'react-async-client';
import { withRouter } from 'react-router-dom';
import { takeEvery } from 'redux-saga/effects';
import { FileOutlined, MenuOutlined, EditOutlined, DeleteOutlined, FileAddOutlined, RetweetOutlined, EyeOutlined } from '@ant-design/icons';

import { reorder } from '../../../utils/dnd';
import { getCompanyTestQuestions, putCompanyTestQuestions } from '../../../actions/asyncActions';
import { openQuestionPageModal, openQuestionModal, openQuestionPreviewModal, openReplaceTestQuestionModal } from '../../../actions/modalActions';
import { withStateHandlers } from 'recompose';
import Complexity from '../table/Complexity';
import { PUT_COMPANY_TEST_QUESTIONS, PUT_QUESTION, PATCH_COMPANY_TEST_QUESTIONS } from '../../../constants/actionTypes';

const Wrapper = styled.div`
    padding: 15px;
    background: #fff;
`;

const Empty = styled.div`
    text-align: center;
`;

const Question = styled.div`
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    background: #fff;
    display: flex;
`;

const Dragger = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ isSent }) => isSent ? 10 : 50}px;
    opacity: ${({ isSent }) => isSent ? 0 : 1};
`;

const QuestionText = styled.div`
    padding: 15px 0;
    display: flex;
    align-items: center;
`;

const QuestionContent = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .ant-btn-group {
        margin-right: 15px;
    }
`;

const QuestionCount = styled.span`
    border-radius: 50%;
    background: ${({ isPage }) => isPage ? '#1890ff' : '#f54d2e'};
    margin-right: 10px;
    color: #fff;
    min-width: 22px;
    height: 22px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
`;

const QuestionTitle = styled.div`
    p {
        margin: 0;
    }
`;

const CategoryAndComplexity = styled.div`
    display: flex;
    align-items: center;
    margin-right: 5px;
    .anticon-file {
        margin-right: 5px;
        font-size: 15px;
    }
`;

const ButtonGroup = styled(Button.Group)`
    display: flex;
`;

class ProjectSequenceTab extends Component {
    getTests = (props = this.props) => {
        return pathOr([], ['testSuites'], props);
    }

    onChangeTest = test => {
        const data = find(propEq('id', test), this.getTests());

        this.props.updateState({
            test,
            questions: pathOr([], ['_embedded', 'questions'], data),
            pages: data.pages || []
        });
    }

    patchCompanyTest = (pathIndex, value) => {
        const test = this.getCurrentTest();

        this.props.putCompanyTestQuestions.dispatch({
            company: test.company,
            project: test.project,
            id: test.id,
            data: {
                questions: this.props.questions,
                pages: update(pathIndex, value, this.props.pages)
            }
        });
    }

    onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        const [ type, id ] = result.draggableId.split('_');
        const questions = reorder(
            this.getData(),
            result.source.index,
            result.destination.index
        );
        const index = findIndex(propEq('id', id), questions);
        const prevIndex = findIndex(propEq('id', id), this.getData());
        const pageIndexes = filter(propEq('isPage', true), questions.map(((item, index) => ({ isPage: has('questionNumber', item), index }))));
        let valid = true;

        pageIndexes.forEach((page, i) => {
            if (valid) {
                valid = page.index + 1 !== path([i + 1, 'index'], pageIndexes);
            }
        });

        if (has('questionNumber', last(questions)) || !valid) {
            return;
        }

        const filtered = filter(q => !has('questionNumber', q), questions);

        if (type === 'page') {
            const questionNumber = findIndex(propEq('id', questions[index + 1].id), filtered)
            const pathIndex = findIndex(propEq('id', questions[index].id), this.props.pages);

            this.setPages(assocPath([pathIndex, 'questionNumber'], questionNumber, this.props.pages));
            this.patchCompanyTest(pathIndex, {
                ...questions[index],
                questionNumber
            });
        } else {
            const prevItem = questions[index - 1] || {};
            const questionNumber = findIndex(propEq('id', id), filtered);
            const updateQuestion = has('questionNumber', prevItem) && prevItem.questionNumber !== questionNumber;

            let pages = updateQuestion ? assocPath(
                [findIndex(propEq('id', questions[index - 1].id), this.props.pages), 'questionNumber'],
                questionNumber,
                this.props.pages
            ) : this.props.pages;

            if (prevIndex > index) {
                pages = pages.map(page => {
                    const indexInQuestions = findIndex(propEq('id', page.id), questions);
                    return indexInQuestions > index && indexInQuestions <= prevIndex ? {
                        ...page,
                        questionNumber: page.questionNumber + 1
                    } : page;
                });
            }

            const test = this.getCurrentTest();

            this.props.updateState({ questions: filtered, pages });
            this.props.putCompanyTestQuestions.dispatch({
                company: test.company,
                project: test.project,
                id: test.id,
                data: {
                    questions: filtered.map(({ id }) => id),
                    pages
                }
            });
        }
    }

    getCurrentTest = () => find(propEq('id', this.props.test), this.getTests());

    setPages = pages => this.props.setPages(pages);

    addQuestionPage = id => {
        const test = this.getCurrentTest();

        this.props.openQuestionPageModal({
            questions: this.props.questions,
            pages: this.props.pages,
            questionNumber: this.getQuestionIndex(id),
            company: test.company,
            project: test.project,
            test: test.id,
            setPages: this.setPages
        });
    }

    editPage = page => {
        const test = this.getCurrentTest();

        this.props.openQuestionPageModal({
            ...page,
            questions: this.props.questions,
            pages: this.props.pages,
            index: findIndex(propEq('id', page.id), this.props.pages),
            company: test.company,
            project: test.project,
            test: test.id,
            setPages: this.setPages
        });
    }

    hasPage = id => {
        return any(propEq('questionNumber', this.getQuestionIndex(id)), this.props.pages);
    }

    getData = () => {
        const { questions, pages } = this.props;

        return flatten(questions.map((q, index) => {
            const page = find(propEq('questionNumber', index), pages);
            return page ? [page, q] : q;
        }));
    }

    getQuestionIndex = id => {
        return findIndex(propEq('id', id), this.props.questions);
    }

    getQuestionCount = id => {
        return this.getQuestionIndex(id) + 1;
    }

    deletePage = id => {
        const test = this.getCurrentTest();

        this.props.deleteTestPage.dispatch({
            company: test.company,
            project: test.project,
            id: test.id,
            data: {
                questions: this.props.questions,
                pages: remove(findIndex(propEq('id', id), this.props.pages), 1, this.props.pages)
            }
        });
    }

    renderDraggableItem = (item, isSent) => {
        const { questions, test, company, project, openReplaceTestQuestionModal, openQuestionModal, openQuestionPreviewModal } = this.props;
        const isPage = has('questionNumber', item);

        return <QuestionContent>
            <QuestionText>
                <QuestionCount isPage={isPage}>
                    { isPage ? <FileOutlined /> : this.getQuestionCount(item.id) }
                </QuestionCount>
                { !isPage &&
                    <CategoryAndComplexity>
                        <Tooltip title={path(['_embedded', 'category', 'name'], item)}>
                            <FileOutlined />
                        </Tooltip>
                        <Complexity level={item.level} />
                    </CategoryAndComplexity>
                }
                <QuestionTitle dangerouslySetInnerHTML={{ __html: item.title }} />
            </QuestionText>
            { isPage ?
                ( !isSent &&
                    <ButtonGroup>
                        <Button icon={<EditOutlined />} onClick={() => this.editPage(item)} />
                        <Button icon={<DeleteOutlined />} type='danger' onClick={() => this.deletePage(item.id)} />
                    </ButtonGroup>
                ) :
                <ButtonGroup>
                    { !isSent &&
                        <Fragment>
                            { !this.hasPage(item.id) &&
                                <Tooltip title='Добавить страницу'>
                                    <Button icon={<FileAddOutlined />} onClick={() => this.addQuestionPage(item.id)} />
                                </Tooltip>
                            }
                            <Tooltip title='Заменить вопрос'>
                                <Button icon={<RetweetOutlined />} onClick={() => openReplaceTestQuestionModal({
                                    company,
                                    test,
                                    project: project.id,
                                    index: findIndex(propEq('id', item.id), questions),
                                    categories: [project.type === 'custom' ? item.category : path(['_embedded', 'category', 'parent'], item)],
                                    levels: [item.level],
                                    excludeIds: questions.map(({ id }) => id),
                                    questionCompany: item.company
                                })} />
                            </Tooltip>
                            <Button icon={<EditOutlined />} onClick={() => openQuestionModal(item)} />
                        </Fragment>
                    }
                    <Button icon={<EyeOutlined />} onClick={() => openQuestionPreviewModal({ item, questions: this.props.questions })} />
                </ButtonGroup>
            }
        </QuestionContent>;
    }

    renderQuestions = () => {
        const isSent = pathEq(['status'], 'sent', find(propEq('id', this.props.test), pathOr([], ['testSuites'], this.props)));

        return <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId='droppable'>
                { provided =>
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}>
                        { this.getData().map((item, index) =>
                            <Draggable
                                key={item.id}
                                draggableId={`${has('questionNumber', item) ? 'page' : 'question'}_${item.id}`}
                                index={index}
                                isSent={isSent}>
                                { provided =>
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        style={{
                                            marginBottom: 10,
                                            ...provided.draggableProps.style
                                        }}>
                                        <Question>
                                            <Dragger {...provided.dragHandleProps} isSent={isSent}>
                                                <MenuOutlined />
                                            </Dragger>
                                            { this.renderDraggableItem(item, isSent) }
                                        </Question>
                                    </div>
                                }
                            </Draggable>
                        )}
                        { provided.placeholder }
                    </div>
                }
            </Droppable>
        </DragDropContext>;
    }

    render() {
        const tests = this.getTests();
        const { test, questions } = this.props;

        return <Wrapper>
            <Alert
                style={{ marginBottom: 15 }}
                type='info'
                message={'На этой странице вы можете настроить последовательность в которой вопросы будут предложены респондентам. Также перед любым вопросом можно отобразить отдельную страницу с произвольным содержанием (например, в качестве предисловия к очередному разделу теста)'} />
            { tests.length > 1 &&
                <Row>
                    <Col span={8}>
                        <Select
                            value={test}
                            onChange={this.onChangeTest}
                            style={{ width: '100%', marginBottom: 15 }}>
                            { tests.map(test =>
                                <Select.Option key={test.id} value={test.id}>
                                    { test.name }
                                </Select.Option>
                            )}
                        </Select>
                    </Col>
                </Row>
            }
            { questions.length ? this.renderQuestions() : <Empty>Вопросы еще не были добавлены</Empty> }
        </Wrapper>;
    }
}

export default withStateHandlers(props => {
    const test = pathOr({}, [0], pathOr([], ['testSuites'], props));

    return {
        test: test.id,
        questions: pathOr([], ['_embedded', 'questions'], test),
        pages: test.pages || []
    };
}, {
    updateState: () => state => state,
    setPages: () => pages => ({ pages: pages || [] })
})(
    asyncConnect({
        getCompanyTestQuestions: getCompanyTestQuestions
            .withPayload(({ company, project, test }) => ({ company, project: project.id, id: test }))
            .withSuccessHandler(({ getCompanyTestQuestions: { data }, updateState }) => {
                return updateState({
                    questions: pathOr([], ['_embedded', 'questions'], data),
                    pages: data.pages || []
                });
            })
            .withSaga(function* (getProps) {
                yield takeEvery([toSuccess(PUT_COMPANY_TEST_QUESTIONS), toSuccess(PATCH_COMPANY_TEST_QUESTIONS), toSuccess(PUT_QUESTION)], () => {
                    getProps().getCompanyTestQuestions.refresh();
                });
            })
            .withOptions({ resetOnUnmount: true, dispatchOnMount: true, dispatchOnUpdate: true }),
        putCompanyTestQuestions: putCompanyTestQuestions
            .withOptions({ resetOnUnmount: true }),
        deleteTestPage: putCompanyTestQuestions
            .withParams({ type: 'delete' })
            .withSuccessHandler(({ deleteTestPage: { data }, setPages }) => {
                message.success('Страница успешно удалена');
                setPages(data.pages);
            })
            .withErrorHandler(() => message.error('Не удалось удалить страницу'))
            .withOptions({ resetOnUnmount: true })
    }, null, { openQuestionPageModal, openQuestionModal, openQuestionPreviewModal, openReplaceTestQuestionModal })(withRouter(ProjectSequenceTab))
);
