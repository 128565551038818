import React, { Component } from 'react';
import { Field } from 'react-final-form';

import withFormWrapper from '../../hocs/withFormWrapper';
import SubmitButton from '../formComponents/SubmitButton';
import Input from '../formComponents/Input';
import Textarea from '../formComponents/Textarea';

class ProjectPageElementsForm extends Component {
    render() {
        return <div>
            <Field
                name='translations.pageElements.startButton'
                component={Input}
                label='Кнопка "Начать"'
                translationName='startButton'
                translations />
            <Field
                name='translations.pageElements.continueButton'
                component={Input}
                label='Кнопка "Продолжить"'
                translationName='continueButton'
                translations />
            <Field
                name='translations.pageElements.submitButton'
                component={Input}
                label='Кнопка "Отправить"'
                translationName='submitButton'
                translations />
            <Field
                name='translations.pageElements.finishTitle'
                component={Input}
                label='Заголовок на завершение'
                translationName='finishTitle'
                translations />
            <Field
                name='translations.pageElements.finishText'
                component={Textarea}
                minRows={2}
                label='Текст на завершение'
                translationName='finishText'
                translations />
            <Field
                name='translations.pageElements.chooseText'
                component={Textarea}
                minRows={2}
                label='Текст выбора'
                translationName='chooseText'
                translations />
            <Field
                name='translations.pageElements.chooseLanguage'
                component={Input}
                label='Текст выбора языка'
                translationName='chooseLanguage'
                translations />
            <Field
                name='translations.pageElements.placeholder'
                component={Input}
                label='Плейсхолдер текстовых полей'
                translationName='placeholder'
                translations />
            <Field
                name='translations.pageElements.commentButton'
                component={Input}
                label='Кнопка "Комментарий"'
                translationName='commentButton'
                translations />
            <Field
                name='translations.pageElements.knowNothing'
                component={Input}
                label='Чекбокс "Не знаю"'
                translationName='knowNothing'
                translations />
            <SubmitButton type='primary'>Сохранить</SubmitButton>
        </div>;
    }
}

export default withFormWrapper(ProjectPageElementsForm, {
    mapPropsToValues: ({ item }) => {
        const pageElements = item.translations.pageElements || {};

        return {
            ...item,
            translations: {
                ...item.translations,
                pageElements: {
                    startButton: pageElements.startButton || 'Начать опрос',
                    continueButton: pageElements.continueButton || 'Продолжить',
                    submitButton: pageElements.submitButton || 'Ответить',
                    finishTitle: pageElements.finishTitle || 'Опрос завершен',
                    finishText: pageElements.finishText || 'Спасибо за участие!',
                    chooseText: pageElements.chooseText || 'Ниже представлен ряд утверждений, которые Вам предлагается оценить. Выберите насколько Вы согласны со следующими утверждениями',
                    chooseLanguage: pageElements.chooseLanguage || 'Выберите язык',
                    placeholder: pageElements.placeholder || 'Ответ',
                    commentButton: pageElements.commentButton || 'Комментарий',
                    knowNothing: pageElements.knowNothing || 'Не знаю'
                }
            }
        };
    },
});
