import React, { Component } from 'react';
import { message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import AcceptorForm from '../forms/AcceptorForm';
import { postAcceptor, putAcceptor } from '../../actions/asyncActions';
import Modal from './Modal';

class AcceptorModal extends Component {
    render() {
        const { modal, params, putAcceptor, postAcceptor } = this.props;

        return <Modal
            {...modal}
            title={params ? 'Редактировать подписанта' : 'Добавить подписанта'}
            footer={null}>
            <AcceptorForm
                formAction={params ? putAcceptor : postAcceptor}
                item={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    postAcceptor: postAcceptor
        .withSuccessHandler(({ close }) => {
            message.success('Подписант успешно добавлен');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
    putAcceptor: putAcceptor
        .withSuccessHandler(({ close }) => {
            message.success('Подписант успешно изменен');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(AcceptorModal);
