import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import Modal from './Modal';
import TestTemplateForm from '../forms/TestTemplateForm';
import { postTestTemplate, patchTestTemplate } from '../../actions/asyncActions';

class TestTemplateModal extends Component {
    render() {
        const { modal, postTestTemplate, patchTestTemplate, params } = this.props;

        return <Modal
            {...modal}
            title={params ? 'Редактировать тест' : 'Добавить тест'}
            footer={null}>
            <TestTemplateForm
                formAction={params ? patchTestTemplate : postTestTemplate}
                item={params} />
        </Modal>
    }
}

export default withAsyncActions({
    postTestTemplate: postTestTemplate
        .withSuccessHandler(({ close }) => {
            message.success('Тест успешно сохранен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось сохранить тест'))
        .withOptions({ resetOnUnmount: true }),
        patchTestTemplate: patchTestTemplate
        .withSuccessHandler(({ close }) => {
            message.success('Тест успешно сохранен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось сохранить тест'))
        .withOptions({ resetOnUnmount: true })
})(TestTemplateModal);
