export const DEFAULT_ERROR = 'Ошибка соединения с сервером';
export const DEFAULT_LOGIN_ERROR = 'Не удалось войти';
export const UNABLE_TO_SHOW = 'Не удалось отобразить страницу';

export const CATEGORIES_ERRORS = {
    'This value is already used.': 'Компетенция с таким названием уже существует'
};

export const CATEGORY_IN_PROJECT_ERROR = 'Category found in project categories and cannot be deleted.';

export default {
    'Invalid username and password combination': 'Неверная пара логин/пароль',
    'The client credentials are invalid': 'Неверная пара логин/пароль',
    'Client id was not found in the headers or body': 'Неверная пара логин/пароль',
    'Not Found': 'Страница не найдена',
    'Unauthorized': 'Не удалось авторизоваться',

    'this value is already used.': 'Название $value уже существует',
    'This value should not be blank.': 'значение не должно быть пустым',
    'This value is not a valid email address.': 'невалидный email',
    'invalid company slug.': 'Невалидный код компании',
    'user with that email not found.': 'Пользователь с таким email не найден',
    [CATEGORY_IN_PROJECT_ERROR]: 'Категория найдена в проекте и не может быть удалена'
};
