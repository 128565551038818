import React, { Component, Fragment } from 'react';
import { Button, Form, Spin, Row, Col } from 'antd';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Field, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { findIndex, propEq, filter, path, contains, range, omit } from 'ramda';
import * as yup from 'yup';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import withFormWrapper from '../hocs/withFormWrapper';
import ListenerField from './ListenerField';
import CategorySelect from './formComponents/CategorySelect';
import Editor from './formComponents/Editor';
import Select from './formComponents/Select';
import { ADMIN_MODERATOR } from '../../constants/roles';
import { QUESTION_LEVELS, QUESTION_TYPES } from '../../constants/questions';
import Datepicker from './formComponents/Datepicker';
import Switch from './formComponents/Switch';
import Radio from './formComponents/Radio';
import Checkbox from './formComponents/Checkbox';
import SubmitButton from './formComponents/SubmitButton';
import { openQuestionPreviewModal } from '../../actions/modalActions';
import { getUser } from '../../actions/asyncActions';
import StringWithoutStyles from '../user/table/StringWithoutStyles';
import DetailToolbar from '../user/DetailToolbar';
import Textarea from './formComponents/Textarea';
import InlineEditor from './formComponents/InlineEditor';

const Container = styled.div`
    padding: ${({ pageView }) => pageView ? 15 : 0}px;
    background: #fff;
    border: ${({ pageView }) => pageView ? '1px solid #e8e8e8' : 'none'};
    border-top: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
`;

const AnswerRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .ant-form-item {
        margin-bottom: 0;
    }
    .ant-checkbox-inner {
        margin-right: 8px;
    }
    .search-input {
        width: 70px;
        margin-right: 10px;
    }
`;

const AnswerTitleWrapper = styled.div`
    width: 100%;
    padding-right: ${({ length }) => length > 2 ? 10 : 0}px;
`;

const Footer = styled.div`
    text-align: right;
    margin-top: 15px;
    button {
        margin-left: 15px;
    }
`;

const ExtraLabel = styled.div`
    float: right;
    margin-top: -42px;
`;
const SubTitle = styled.h3`
    border-bottom: 1px solid #efefef;
    background: #fafafa;
    margin: 0px -15px 10px;
    padding: 5px 24px;
    display: block;
`;

const Error = styled.span`
    color: #f5222d;
    margin-left: 16px;
    font-size: 10px;
`;

export const defaultAnswerVariants = [
    {
        correct: true
    }, {
        correct: false
    }
];

const defaultRangingAnswerVariants = [
    {
        rank: 1
    }, {
        rank: 2
    }
];

class QuestionFormComponent extends Component {
    static defaultProps = {
        pageView: true
    };

    onChangeCorrect = (fields, index) => {
        const { form } = this.props;

        form.batch(() => {
            fields.forEach((_, i) => {
                if (i !== index) {
                    form.change(`answerVariants.${i}.correct`, false);
                }
            })
        })
    }

    renderAnswerVariants = (fields, multipleAnswers) => {
        const { disabled } = this.props;

        return <div>
            { fields.map((name, index) =>
                <AnswerRow key={name}>
                    <Field
                        name={`${name}.correct`}
                        component={multipleAnswers ? Checkbox : Radio}
                        onChange={() => !multipleAnswers && this.onChangeCorrect(fields, index)}
                        disabled={disabled || (multipleAnswers && filter(propEq('correct', true), fields.value).length < 2 && findIndex(propEq('correct', true), fields.value) === index)}
                        disableClear />
                    <AnswerTitleWrapper length={fields.length}>
                        <Field
                            name={`${name}.title`}
                            component={InlineEditor}
                            disabled={disabled}
                            showTextError={false} />
                    </AnswerTitleWrapper>
                    { fields.length > 2 && <Button icon={<DeleteOutlined />} type='danger' disabled={disabled} onClick={() => fields.remove(index)} /> }
                </AnswerRow>
            )}
            <Button icon={<PlusOutlined />} disabled={disabled} onClick={() => fields.push({ correct: false })}>
                <span className='hide-mobile'>Добавить вариант ответа</span>
            </Button>
        </div>;
    }

    renderRangingAnswerVariants = fields => {
        const ranks = range(1, fields.length + 1).map(r => ({ id: r, value: r }));

        return <FormSpy subscription={{ errors: true, submitFailed: true }}>
            { ({ errors, submitFailed }) =>
                <div>
                    { fields.map((name, index) =>
                        <AnswerRow key={name}>
                            <Field
                                name={`${name}.rank`}
                                component={Select}
                                options={ranks}
                                showTextError={false}
                                allowClear />
                            <AnswerTitleWrapper length={fields.length}>
                                <Field
                                    name={`${name}.title`}
                                    component={InlineEditor}
                                    showTextError={false} />
                            </AnswerTitleWrapper>
                            { fields.length > 2 && <Button icon={<DeleteOutlined />} type='danger' onClick={() => fields.remove(index)} /> }
                        </AnswerRow>
                    )}
                    <Button icon={<PlusOutlined />} onClick={() => fields.push({ rank: fields.length + 1 })}>
                        <span className='hide-mobile'>Добавить вариант ответа</span>
                    </Button>
                    { submitFailed && <Error>{ path(['_arrays', 'rangingAnswerVariants'], errors) }</Error> }
                </div>
            }
        </FormSpy>;
    }

    onChangeMultiple = multiple => {
            const { form } = this.props;
            const { answerVariants, type } = form.getState().values;

        if (!multiple && type === 'choice') {
            const firstIndex = findIndex(propEq('correct', true), answerVariants || []);

            firstIndex < 0 ?
                form.change(`answerVariants.0.correct`, true) :
                form.batch(() => {
                    answerVariants.forEach((item, i) => {
                        if (item.correct && i !== firstIndex) {
                            form.change(`answerVariants.${i}.correct`, false);
                        }
                    })
                });
        }
    }

    onChangeType = type => {
        const { form } = this.props;

        switch (type) {
            case 'choice':
                form.batch(() => {
                    form.change('answerVariants', defaultAnswerVariants);
                    form.change('rangingAnswerVariants', null);
                });
                break;
            case 'ranging':
                form.batch(() => {
                    form.change('answerVariants', null);
                    form.change('rangingAnswerVariants', defaultRangingAnswerVariants);
                });
                break;
            default:
                form.batch(() => {
                    form.change('answerVariants', null);
                    form.change('rangingAnswerVariants', null);
                });
        }
    }

    renderFields = () => {
        const { disabled } = this.props;
        const category = path(['_embedded', 'category'], this.props.item);

        return <Fragment>
            <Row>
                <Col span={24}>
                    <Field
                        name='category'
                        component={CategorySelect}
                        disabled={disabled}
                        label='Компетенция'
                        category={category} />
                </Col>
            </Row>
            <SubTitle>Вопрос</SubTitle>
            <Field
                name='title'
                component={Editor}
                disabled={disabled} />
            <SubTitle>Настройки</SubTitle>
            <Row gutter={16} type="flex" align="top">
                <Col sm={12} md={12} lg={8}>
                    <Field
                        name='type'
                        component={Select}
                        disabled={disabled}
                        label='Тип'
                        options={QUESTION_TYPES}
                        onChange={this.onChangeType} />
                </Col>
                <Col sm={12} md={12} lg={8}>
                    <Field
                        name='level'
                        component={Select}
                        disabled={disabled}
                        label='Сложность'
                        options={QUESTION_LEVELS.map(item => ({ id: item.id, value: item.icon }))} />
                </Col>
                <Col sm={12} md={12} lg={8}>
                    <Field
                        name='refreshDate'
                        component={Datepicker}
                        disabled={disabled}
                        label='Дата обновления' />
                </Col>
            </Row>
            <ListenerField listenFieldName='type'>
                { ({ type }) => contains(type, ['choice', 'ranging']) &&
                    <Fragment>
                        <SubTitle>Варианты ответов</SubTitle>
                        <Form.Item wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} label='Ответы'>
                            { type === 'choice' ?
                                <Fragment>
                                    <ExtraLabel className='ant-form-inline'>
                                        <Field
                                            name='multipleAnswers'
                                            component={Switch}
                                            disabled={disabled}
                                            label='Множественный выбор'
                                            onChange={this.onChangeMultiple}
                                            hideOptional />
                                    </ExtraLabel>
                                    <ListenerField listenFieldName='multipleAnswers'>
                                        { ({ multipleAnswers }) =>
                                            <FieldArray name='answerVariants'>
                                                { ({ fields }) => this.renderAnswerVariants(fields, multipleAnswers) }
                                            </FieldArray>
                                        }
                                    </ListenerField>
                                </Fragment> :
                                <FieldArray name='rangingAnswerVariants'>
                                    { ({ fields }) => this.renderRangingAnswerVariants(fields) }
                                </FieldArray>
                            }
                        </Form.Item>
                    </Fragment>
                }
            </ListenerField>
            <Field name='answerVariants' component={() => null} />
            <Field name='rangingAnswerVariants' component={() => null} />
            <SubTitle>Комментарий</SubTitle>
            <Field
                name='note'
                component={Textarea}
                disabled={disabled} />
        </Fragment>;
    }

    openPreview = () => {
        this.props.openQuestionPreviewModal({ item: this.props.form.getState().values });
    }

    render() {
        const { title, pending, pageView, disabled } = this.props;

        return <Fragment>
            { pageView &&
                <DetailToolbar
                    backUrl='/questions'
                    title={
                        title ?
                            <h1>{ title }</h1> :
                            <ListenerField listenFieldName='title'>
                                { ({ title }) => <h1><StringWithoutStyles text={title} /></h1> }
                            </ListenerField>
                    } />
            }
            { pending ?
                <Spin /> :
                <Container pageView={pageView}>
                    { this.renderFields() }
                    <Footer>
                        <Button.Group>
                            { pageView && <Button onClick={this.openPreview}>Предпросмотр</Button> }
                            <SubmitButton disabled={disabled} type='primary'>Сохранить</SubmitButton>
                        </Button.Group>
                    </Footer>
                </Container>
            }
        </Fragment>;
    }
}

const validationSchema = (props, values) => yup.object().shape({
    category: yup.string().required(),
    title: yup.string().required(),
    type: yup.string().required(),
    level: yup.string().required(),
    ...(values.type === 'choice' ? {
        answerVariants: yup.array().of(yup.object().shape({
            title: yup.string().required()
        }))
    } : values.type === 'ranging' ? {
        rangingAnswerVariants: yup.array().of(yup.object().shape({
            title: yup.string().required(),
            rank: yup.number().nullable().required()
        })).uniq('rank', 'Порядковый номер для каждого ответа должен быть уникальным')
    } : {})
});

const stateToProps = (state, props) => {
    const user = getUser.selectData(state);
    const isAdmin = contains(user.role, ADMIN_MODERATOR);

    return {
        isAdmin,
        disabled: !(isAdmin || props.item.company),
    };
};

const QuestionForm = connect(stateToProps, { openQuestionPreviewModal })(
    withFormWrapper(QuestionFormComponent, {
        mapPropsToValues: ({ item }) => ({
            ...item,
            updateRelatedTests: true
        }),
        validationSchema,
        mapBeforeSubmit: values => values.type === 'choice' ? omit(['rangingAnswerVariants'], values) :
            values.type === 'ranging' ? omit(['answerVariants'], values) : omit(['answerVariants', 'rangingAnswerVariants'], values)
    })
);

export default class QuestionFormWrapper extends Component {
    render() {
        const { pending } = this.props;

        return pending ?
            <Fragment>
                <DetailToolbar />
                <Spin />
            </Fragment> :
            <QuestionForm {...this.props} />;
    }
}
