import React, { useState } from 'react';
import { Tooltip, Typography} from 'antd';

const { Paragraph } = Typography;

const TooltipParagraph = ({ children, ellipsis, ...props }) => {
  const [truncated, setTruncated] = useState(false);

  return (
      <Tooltip title={truncated ? children : undefined}>
        <Paragraph
            {...props}
            ellipsis={{ ...ellipsis, onEllipsis: setTruncated }}
        >
          <>{children}</>
        </Paragraph>
      </Tooltip>
  );
};

export default TooltipParagraph;
