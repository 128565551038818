import React, { Component } from 'react';
import { message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import CompanyTemplateForm from '../forms/CompanyTemplateForm';
import { putCompanyTemplate, postCompanyTemplate } from '../../actions/asyncActions';
import Modal from './Modal';

class CompanyTemplateModal extends Component {
    render() {
        const { modal, params, putCompanyTemplate, postCompanyTemplate } = this.props;

        return <Modal
            {...modal}
            title={params.id ? 'Редактировать шаблон' : 'Добавить шаблон'}
            footer={null}>
            <CompanyTemplateForm
                formAction={params.id ? putCompanyTemplate : postCompanyTemplate}
                item={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    postCompanyTemplate: postCompanyTemplate
        .withSuccessHandler(({ close }) => {
            message.success('Шаблон успешно добавлен');
            close();
        })
        .withErrorHandler(({ postCompanyTemplate: { meta }}) =>
            message.error('Не удалось добавить шаблон')
        )
        .withOptions({ resetOnUnmount: true }),
    putCompanyTemplate: putCompanyTemplate
        .withSuccessHandler(({ close }) => {
            message.success('Шаблон успешно изменен');
            close();
        })
        .withErrorHandler(({ putCompanyTemplate: { meta }}) =>
            message.error('Не удалось изменить шаблон')
        )
        .withOptions({ resetOnUnmount: true })
})(CompanyTemplateModal);
