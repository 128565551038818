import React, { Component } from 'react';
import { message, Button, Popconfirm } from 'antd';
import { asyncConnect } from 'react-async-client';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { withState } from 'recompose';
import { remove, find, propEq, path, keys, pathOr } from 'ramda';
import uniqid from 'uniqid';
import { MenuOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

import { patchSurvey, getSurvey } from '../../../actions/asyncActions';
import { openSurveyResponseFieldFormModal, openSurveyPreviewModal } from '../../../actions/modalActions';
import { reorder } from '../../../utils/dnd';
import { SURVEY_RESPONSE_FORM_FIELD_TYPES } from '../../../constants/questionnary';

const Wrapper = styled.div`
    background: #fff;
    padding: 15px;
`;

const FieldWrapper = styled.div`
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    display: flex;
    background: #fff;
`;

const Handler = styled.div`
    padding: 15px;
    cursor: move;
    display: flex;
    align-items: center;
`;

const FieldContent = styled.div`
    padding: 10px 15px 10px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

class SurveyForm extends Component {
    onDragEnd = result => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }

        const sections = reorder(
            this.props.sections,
            result.source.index,
            result.destination.index
        );

        this.props.setSections(sections);
        this.props.patchSurvey.dispatch({
            id: this.props.survey.id,
            data: [
                {
                    op: 'replace',
                    path: `/sections`,
                    value: sections
                },
                ...keys(this.props.survey.languages).map(lang => ({
                    op: 'replace',
                    path: `/translations/${lang}/sections`,
                    value: reorder(
                        pathOr([], ['translations', lang, 'sections'], this.props.survey),
                        result.source.index,
                        result.destination.index
                    )
                }))
            ]
        });
    }

    removeField = index => {
        this.props.setSections(remove(index, 1, this.props.sections));
        this.props.patchSurvey.dispatch({
            id: this.props.survey.id,
            data: [{
                op: 'remove',
                path: `/sections/${index}`
            },
            ...keys(this.props.survey.languages).map(lang => ({
                op: 'remove',
                path: `/translations/${lang}/sections/${index}`
            }))]
        });
    }

    render() {
        const { getSurvey: { meta }, sections, survey, openSurveyResponseFieldFormModal, openSurveyPreviewModal } = this.props;

        return <Wrapper>
            <div style={{ textAlign: 'right', marginBottom: 15 }}>
                <Button type='primary' icon={<PlusOutlined />} loading={meta.pending} onClick={() => openSurveyResponseFieldFormModal({
                    add: true,
                    sectionsAmount: sections.length,
                    survey: survey.id,
                    translations: survey.translations,
                    values: {
                        key: uniqid()
                    },
                    languages: keys(survey.languages)
                })}>
                    Добавить поле
                </Button>
                <Button
                    key='preview'
                    onClick={() => openSurveyPreviewModal({
                        title: survey.name,
                        survey: survey.id,
                        main: true,
                    })}>
                    Просмотр
                </Button>
            </div>
            { sections.length ?
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId='droppable' isDropDisabled={meta.pending}>
                        { provided =>
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                { sections.map((item, index) =>
                                    <Draggable key={`item-${item.key}`} isDragDisabled={meta.pending} draggableId={item.key} index={index}>
                                        { provided =>
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                style={{ ...provided.draggableProps.style, paddingBottom: 15 }}>
                                                <FieldWrapper>
                                                    <Handler {...provided.dragHandleProps}>
                                                        <MenuOutlined />
                                                    </Handler>
                                                    <FieldContent>
                                                        <div>
                                                            <div dangerouslySetInnerHTML={{ __html: item.title }} />
                                                            <div style={{ color: '#999' }}>{ path(['value'], find(propEq('id', item.type), SURVEY_RESPONSE_FORM_FIELD_TYPES)) }</div>
                                                        </div>
                                                        <Button.Group>
                                                            <Button
                                                                icon={<EditOutlined />}
                                                                disabled={meta.pending}
                                                                onClick={() => openSurveyResponseFieldFormModal({
                                                                    index,
                                                                    survey: survey.id,
                                                                    values: item,
                                                                    translations: survey.translations,
                                                                    languages: keys(survey.languages)
                                                                })} />
                                                            <Popconfirm
                                                                title='Вы уверены, что хотите удалить поле?'
                                                                okText='Да'
                                                                cancelText='Нет'
                                                                disabled={meta.pending}
                                                                onConfirm={() => this.removeField(index)}
                                                                placement='left'>
                                                                <Button type='danger' disabled={meta.pending} icon={<DeleteOutlined />} />
                                                            </Popconfirm>
                                                        </Button.Group>
                                                    </FieldContent>
                                                </FieldWrapper>
                                            </div>
                                        }
                                    </Draggable>
                                )}
                                { provided.placeholder }
                            </div>
                        }
                    </Droppable>
                </DragDropContext> :
                <div style={{ textAlign: 'center' }}>Нет добавленных полей</div>
            }
        </Wrapper>
    }
}

export default withState('sections', 'setSections', props => props.survey.sections || [])(
    asyncConnect({
        getSurvey: getSurvey
            .withSuccessHandler(({ getSurvey, setSections }) => setSections(getSurvey.data.sections)),
        patchSurvey: patchSurvey
            .withParams(() => ({ type: 'list' }))
            .withErrorHandler(({ project, setSections }) => {
                setSections(project.sections);
                message.error('Не удалось изменить форму');
            })
            .withOptions({ resetOnUnmount: true })
    }, null, { openSurveyResponseFieldFormModal, openSurveyPreviewModal })(SurveyForm)
);
