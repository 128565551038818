import React, { Component, Fragment } from 'react';
import { Button } from 'antd';
import { toPairs, update } from 'ramda';
import styled, { css } from 'styled-components';

import Modal from './Modal';
import { VariablesInput } from '../forms/formComponents/VariablesInput';
import { SURVEY_LETTER_TEMPLATE_VARIABLES } from '../../constants/questionnary';

const Item = styled.div`
    margin-left: 0 !important;
    ${({ last }) => !last && css`
        border-bottom: 1px solid #ccc;
        padding-bottom: 25px;
    `}
`;

export default class LanguagesSelectorModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            values: props.params.values || {}
        };
    }

    onChange = (value, index) => {
        this.setState(prev => ({
            values: update(index, value, prev.values)
        }));
    }

    save = () => {
        this.props.params.save(this.state.values);
        this.props.close();
    }

    render() {
        const { modal, close, params } = this.props;

        return <Modal
            {...modal}
            title={params.title}
            footer={
                <Fragment>
                    <Button type='primary' onClick={this.save}>Сохранить</Button>
                    <Button onClick={close}>Отмена</Button>
                </Fragment>
            }>
            { toPairs(params.languages).map(([ lang, langString ], index) =>
                <Item key={`translation-${index}`} style={{ marginLeft: -22, marginBottom: 5 }} last={index >= toPairs(params.languages).length - 1}>
                    <div style={{ marginBottom: 5 }}>{ langString }</div>
                    <VariablesInput
                        input={{ value: this.state.values[index] }}
                        onChange={value => this.onChange(value, index)}
                        variables={SURVEY_LETTER_TEMPLATE_VARIABLES} />
                </Item>
            )}
        </Modal>;
    }
}
