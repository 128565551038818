import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { MailOutlined, LockOutlined } from '@ant-design/icons';

import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import SubmitButton from './formComponents/SubmitButton';

class LoginForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='username'
                component={Input}
                htmlType='email'
                size='large'
                icon={<MailOutlined />}
                placeholder='Логин' />
            <Field
                name='password'
                component={Input}
                htmlType='password'
                size='large'
                icon={<LockOutlined />}
                placeholder='Пароль' />
            <SubmitButton size='large' type='primary'>Войти</SubmitButton>
        </Fragment>
    }
}

const validationSchema = yup.object().shape({
    username: yup.string().email().required(),
    password: yup.string().required()
});

export default withFormWrapper(LoginForm, {
    validationSchema
});
