import React, { Component, Fragment } from 'react';
import { pathOr, contains, filter, pathEq } from 'ramda';
import { Row, Col, Tooltip } from 'antd';
import styled from 'styled-components';
import { QuestionCircleOutlined, BulbOutlined, UserOutlined } from '@ant-design/icons';

import { getExecutionTime } from '../../../utils/time';
import ProjectRespondentStat from '../projects/ProjectRespondentStat';

const TestName = styled.div`
    padding: 5px 15px;
    border-top: 0;
    font-size: 1.4rem;
    text-align: center;
    color: #2b3d4f;
    margin-top: 15px;
`;

const InfoBlock = styled.div`
    background: #fafafa;
    color: #fff;
`;

const InfoRow = styled(Row)`
    margin: 15px 0;
`;

const ResultInfo = styled.div`
    text-align: center;
    padding: 10px;
    color: #333;
`;

const StartedResultInfo = styled.div`
    text-align: center;
    padding: 10px;
`;

const InfoCol = styled(Col)`
    ${StartedResultInfo}{
        background: #2b3d4f;
    }
    ${ResultInfo}{
        color: #2b3d4f;
    }
`;

const Categories = styled.div`
    margin-top: 30px;
    background: #fff;
    padding: 15px;
    border-left: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    h1{
        border: 1px solid #eaeaea;
        background: #fafafa;
        margin: -30px -16px 15px;
        padding: 10px 15px;
    }
`;

const ImportantInfo = styled.div`
    font-size: 20px;
    font-weight: bold;
`;

export default class TestSolutionInfo extends Component {
    render() {
        const { data, testTemplate } = this.props;
        const solution = pathOr({}, ['_embedded', 'solution'], data);
        const isStarted = contains(data.status, ['started', 'finished']);
        const test = pathOr({}, ['_embedded', testTemplate ? 'testTemplate' : 'testSuite'], data);
        const questionsNum = (test.questions || []).length;
        const answeredNum = filter(i => i.answeredAt, pathOr([], ['_embedded', 'answers'], solution)).length;
        const rightAnsweredNum = filter(i => i.correct, pathOr([], ['_embedded', 'answers'], solution)).length;
        const isIntegralResultText = pathEq(['_embedded', 'company', 'reportType'], 'integralResultText', data);

        return <Fragment>
            <TestName>{test.name}</TestName>
            <InfoRow gutter={16}>
                <InfoCol span={8}>
                    <InfoBlock>
                        <ResultInfo>
                            <ImportantInfo>{test.timeLimited ? getExecutionTime(test.executionTime) : '∞'}</ImportantInfo>
                            Доступное время
                        </ResultInfo>
                        {isStarted &&
                        <StartedResultInfo>
                            <ImportantInfo>{getExecutionTime(solution.solutionTime)}</ImportantInfo>
                            Затрачено времени
                        </StartedResultInfo>
                        }
                    </InfoBlock>
                </InfoCol>
                <InfoCol span={8}>
                    <InfoBlock>
                        <ResultInfo>
                            <Tooltip title='Средний процент прохождения по вопросам'>
                                <ImportantInfo><QuestionCircleOutlined /> {(test.averagePercents || 0).toFixed(1)}%</ImportantInfo>
                            </Tooltip>
                            Средний процент ({test.finishedRespondentsCount || 0} <UserOutlined />)
                        </ResultInfo>
                        {isStarted &&
                        <StartedResultInfo>
                            <ImportantInfo>
                                <Tooltip title='Процент прохождения по вопросам'><QuestionCircleOutlined /> {(solution.result || 0).toFixed(1)}%</Tooltip> / <Tooltip
                                title='Процент прохождения по баллам'><BulbOutlined /> {(solution.points || 0).toFixed(1)}%</Tooltip></ImportantInfo>
                            Процент прохождения
                        </StartedResultInfo>
                        }
                    </InfoBlock>
                </InfoCol>
                <InfoCol span={8}>
                    <InfoBlock>
                        <ResultInfo>
                            <ImportantInfo>{questionsNum}</ImportantInfo>
                            Количество вопросов
                        </ResultInfo>
                        {isStarted &&
                        <StartedResultInfo>
                            <ImportantInfo>{rightAnsweredNum} { questionsNum !== answeredNum ? <Tooltip title='Респондент ответил не на все вопросы'>(из {answeredNum})</Tooltip> : '' }</ImportantInfo>
                            Отвечено правильно
                        </StartedResultInfo>
                        }
                    </InfoBlock>
                </InfoCol>
            </InfoRow>
            {data.status === 'finished' && !testTemplate &&
                <Categories>
                    <h1>По компетенциям</h1>
                    <ProjectRespondentStat respondent={data} testTemplate={testTemplate} isIntegralResultText={isIntegralResultText} />
                </Categories>
            }
        </Fragment>;
    }
}
