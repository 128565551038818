import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import { patchSurvey } from '../../actions/asyncActions';
import SurveyResponseFieldForm from '../forms/survey/SurveyResponseFieldForm';

class SurveyResponseFieldFormModal extends Component {
    render() {
        const { modal, params, patchSurvey } = this.props;

        return <Modal
            {...modal}
            title='Добавить поле'
            footer={null}>
            <SurveyResponseFieldForm
                formAction={patchSurvey}
                data={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    patchSurvey: patchSurvey
        .withParams(() => ({ type: 'edit' }))
        .withSuccessHandler(({ close }) => {
            message.success('Форма успешно сохранена');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось сохранить форму'))
        .withOptions({ resetOnUnmount: true })
})(SurveyResponseFieldFormModal);
