import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';
import { path } from 'ramda';

import { putCategory } from '../../../actions/asyncActions';
import CategoryForm from '../../forms/CategoryForm';
import { CATEGORIES_ERRORS } from '../../../constants/errors';

class CategoryEdit extends Component {
    onVisibleChange = visible => {
        this.props.onVisibleChange(visible ? this.props.item.id : null);
    }

    render() {
        const { putCategory, item, common, elementRef } = this.props;

        return <CategoryForm
            formAction={putCategory}
            item={item}
            visible={this.props.visible}
            onVisibleChange={this.onVisibleChange}
            common={common}
            elementRef={elementRef}
            edit />;
    }
}

export default withAsyncActions({
    putCategory: putCategory
        .withParams(({ item, common }) => ({ parent: common ? null : item.parent, id: item.id }))
        .withSuccessHandler(({ onVisibleChange }) => onVisibleChange(false))
        .withErrorHandler(({ putCategory: { meta }}) => {
            const error = path(['error', 'data', 'errors', 'name', 0, 'message'], meta);
            message.error(CATEGORIES_ERRORS[error] || 'Не удалось изменить компетенцию')
        })
        .withOptions({ resetOnUnmount: true })
})(CategoryEdit);
