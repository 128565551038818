import React from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm } from 'antd';
import { asyncConnect } from 'react-async-client';

import { deleteAcceptor, getAcceptors } from '../../actions/asyncActions';
import { openAcceptorModal } from '../../actions/modalActions';
import TableList from './table/TableList';
import { DELETE_ACCEPTOR, POST_ACCEPTOR, PUT_ACCEPTOR } from '../../constants/actionTypes';
import AcceptorFilter from '../forms/filters/AcceptorFilter';

const Acceptors = props => {
    const getColumns = () => {
        return [
            {
                title: 'ФИО',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: 'На основании',
                dataIndex: 'reason',
                key: 'reason'
            },
            {
                key: 'actions',
                align: 'right',
                render: item =>
                    <Button.Group>
                        <Button onClick={() => props.openAcceptorModal(item)}>
                            <EditOutlined />
                        </Button>
                        <Popconfirm
                            title='Вы уверены, что хотите удалить подписанта?'
                            okText='Да'
                            cancelText='Нет'
                            placement='left'
                            onConfirm={() => props.deleteAcceptor.dispatch(item.id)}>
                            <Button danger>
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    </Button.Group>
            }
        ];
    }

    const getButtons = () => {
        return <Button.Group>
            <Button type='primary' icon={<PlusOutlined />} onClick={() => props.openAcceptorModal()}>
                <span className='hide-mobile'>Добавить</span>
            </Button>
        </Button.Group>;
    }

    return <TableList
        action={getAcceptors}
        refreshActions={[POST_ACCEPTOR, PUT_ACCEPTOR, DELETE_ACCEPTOR]}
        columns={getColumns()}
        filterForm={AcceptorFilter}
        buttons={getButtons()} />;
}

export default asyncConnect({
    deleteAcceptor: deleteAcceptor
        .withSuccessHandler(() => message.success('Подписант успешно удален'))
        .withErrorHandler(() => message.error('Не удалось удалить подписанта'))
        .withOptions({ resetOnUnmount: true })
}, null, { openAcceptorModal })(Acceptors);
