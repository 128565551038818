import React, { Component, Fragment } from 'react';
import { InputNumber } from 'antd';
import styled from 'styled-components';
import { path, assocPath, is, all } from 'ramda';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import Complexity from '../../user/table/Complexity';
import { FormSpy } from 'react-final-form';

const LevelSelectors = styled.div`
    display: flex;
`;

const LevelSelector = styled.div`
    display: flex;
    align-items: center;
    margin-right: 7px;
    .ant-input-number {
        width: 50px;
        margin-left: 2px;
        border-color: ${({ error }) => error ? '#f54d2e' : '#d9d9d9'};
    }
`;

class CountTestsField extends Component {
    onChange = (count, i) => {
        const { input } = this.props;
        const value = assocPath([i - 1], count, is(Array, input.value) ? input.value : []);

        this.props.onChange(all(i => !i, value) ? null : value);
    }

    getValue = i => {
        return path([i - 1], this.props.input.value);
    }

    renderSelector = ({ errors, submitFailed }) => {
        const error = submitFailed ? path(['_arrays', ...this.props.input.name.split('.')], errors) : null;

        return <Fragment>
            <LevelSelectors>
                { [1, 2, 3].map(i =>
                    <LevelSelector key={`complexity-${i}`} error={error && !is(Number, this.getValue(i))}>
                        <Complexity level={i} />
                        <InputNumber
                            size='small'
                            value={this.getValue(i)}
                            onChange={value => this.onChange(value, i)}
                            min={0} />
                    </LevelSelector>
                )}
            </LevelSelectors>
            <div style={{ color: '#f54d2e', marginTop: 5 }}>{ error }</div>
        </Fragment>;
    }

    render() {
        return <FormSpy subscription={{ errors: true, submitFailed: true }}>
            { this.renderSelector }
        </FormSpy>;
    }
}

export default withFieldWrapper(CountTestsField);
