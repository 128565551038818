import React, { Component } from 'react';
import { message, Spin } from 'antd';
import { withAsyncActions } from 'react-async-client';
import styled from 'styled-components';

import QuestionsSettingsForm from '../forms/QuestionsSettingsForm';
import { getComplexity, putComplexity } from '../../actions/asyncActions';
import Modal from './Modal';

const StyledModal = styled(Modal)`
    .ant-modal-body {
        padding: 0;
    }
`;

class QuestionsSettingsModal extends Component {
    render() {
        const { modal, getComplexity: { data, meta }, putComplexity } = this.props;

        return <Modal
            {...modal}
            title='Настройки для сложностей'
            footer={null}
            ModalComponent={StyledModal}>
            { meta.pending ?
                <Spin /> :
                <QuestionsSettingsForm
                    formAction={putComplexity}
                    data={data} />
            }
        </Modal>;
    }
}

export default withAsyncActions({
    getComplexity: getComplexity
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    putComplexity: putComplexity
        .withSuccessHandler(({ close }) => {
            message.success('Настройки для сложностей успешно сохранены');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(QuestionsSettingsModal);
