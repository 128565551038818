import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import Modal from './Modal';
import { putQuestion } from '../../actions/asyncActions';
import QuestionForm from '../forms/QuestionForm';

class QuestionModal extends Component {
    render() {
        const { modal, putQuestion, params } = this.props;

        return <Modal
            {...modal}
            title='Редактировать вопрос'
            footer={null}>
            <QuestionForm
                formAction={putQuestion}
                item={params}
                pageView={false} />
        </Modal>;
    }
}

export default withAsyncActions({
    putQuestion: putQuestion
        .withSuccessHandler(({ close }) => {
            message.success('Вопрос был успешно изменен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось изменить вопрос'))
        .withOptions({ resetOnUnmount: true })
})(QuestionModal);
