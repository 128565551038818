import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';

const Wrapper = styled(Row)`
    padding: 15px;
    border: 1px solid #e8e8e8;
    background: #fafafa;
    .ant-form-item {
        margin-bottom: 0;
    }
    .ant-form-item-control .search-input {
        margin-top: 0 !important;
    }
`;

const ButtonsCol = styled(Col)`
    text-align: right;
`;

const Toolbar = ({ filterForm, title, buttons, buttonsTop }) => (
    <Wrapper>
        { !!buttons && buttonsTop && (
            <ButtonsCol sm={24} lg={24} style={{ marginBottom: buttonsTop ? 10 : 0 }}>
                { buttons }
            </ButtonsCol>
        )}
        <Col xs={20} sm={16} lg={buttons && !buttonsTop ? 14 : 24}>
            { filterForm || title }
        </Col>
        { !!buttons && !buttonsTop && (
            <ButtonsCol xs={4} sm={8} lg={10}>
                { buttons }
            </ButtonsCol>
        )}
    </Wrapper>
);

export default Toolbar;
