import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { Form, Button } from 'antd';
import { FieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import { pathOr, map, omit, remove, includes, path, last } from 'ramda';
import * as yup from 'yup';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { MenuOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import SubmitButton from '../formComponents/SubmitButton';
import withFormWrapper from '../../hocs/withFormWrapper';
import ListenerField from '../ListenerField';
import { SURVEY_RESPONSE_FORM_FIELD_TYPES } from '../../../constants/questionnary';
import Input from '../formComponents/Input';
import Select from '../formComponents/Select';
import Switch from '../formComponents/Switch';
import LanguagesSelector from '../formComponents/LanguagesSelector';
import { reorder } from '../../../utils/dnd';
import FieldHelp from '../FieldHelper';
import Editor from '../formComponents/Editor';

const OptionItem = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    .ant-form-item {
        margin-bottom: 0;
        width: 100%;
        margin-right: 15px;
    }
`;

const OptionItemWrapper = styled.div`
    display: flex;
    background: #fff;
`;

const Handler = styled.div`
    padding: 15px;
    cursor: move;
    display: flex;
    align-items: center;
`;

class SurveyResponseFieldForm extends Component {
    removeOptionFromTranslations = (index, translations) => {
        const { form, data } = this.props;
        const sectionIndex = data.add ? data.sectionsAmount : data.index;

        form.change('translations', map(value => ({
            ...value,
            sections: (value.sections || []).map((item, i) => i === sectionIndex ? ({
                ...item,
                options: remove(index, 1, item.options || [])
            }) : item)
        }), translations));
    }

    onChangeType = (type, field, translations) => {
        if (includes(type, ['number', 'input', 'checkbox'])) {
            const { form, data } = this.props;
            const index = data.add ? data.sectionsAmount : data.index;

            form.batch(() => {
                form.change(field, null);
                form.change('translations', map(value => ({
                    ...value,
                    sections: (value.sections || []).map((item, i) => i === index ? omit(['options'], item) : item)
                }), translations));
            });
        }
    }

    onDragEnd = result => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }

        const { data, form } = this.props;
        const items = reorder(
            form.getState().values.section.options,
            result.source.index,
            result.destination.index
        );
        const translations = map(value => ({
            ...value,
            sections: value.sections.map((section, index) => index === (data.add ? data.sectionsAmount : data.index) ? ({
                ...section,
                options: reorder(section.options, result.source.index, result.destination.index)
            }) : section)
        }), form.getState().values.translations);

        form.change('section.options', items);
        form.change('translations', translations);
    }

    renderOptionsFields = ({ fields }) => {
        const { data } = this.props;

        return <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId='options-droppable'>
                { provided =>
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}>
                        { fields.map((name, index) =>
                            <Draggable key={name} draggableId={name} index={index}>
                                { provided =>
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        style={{ ...provided.draggableProps.style, paddingBottom: 10 }}>
                                        <OptionItemWrapper>
                                            <Handler {...provided.dragHandleProps}>
                                                <MenuOutlined />
                                            </Handler>
                                            <OptionItem>
                                                <Field
                                                    name={name}
                                                    component={Input}
                                                    validate={value => !value ? 'required' : undefined}
                                                    disableClear
                                                    hideErrorMsg />
                                                <Button.Group style={{ width: 72 }}>
                                                    <LanguagesSelector
                                                        name={`sections[${data.add ? data.sectionsAmount : data.index}].options[${index}]`}
                                                        defaultValue={fields.value[index]} />
                                                    <ListenerField listenFieldName='translations'>
                                                        { ({ translations }) =>
                                                            <Button type='danger' icon={<DeleteOutlined />} onClick={() => {
                                                                fields.remove(index);
                                                                this.removeOptionFromTranslations(index, translations);
                                                            }} />
                                                        }
                                                    </ListenerField>
                                                </Button.Group>
                                            </OptionItem>
                                        </OptionItemWrapper>
                                    </div>
                                }
                            </Draggable>
                        )}
                        { provided.placeholder }
                    </div>
                }
            </Droppable>
            <Button icon={<PlusOutlined />} onClick={() => fields.push('')}>
                Добавить вариант ответа
            </Button>
        </DragDropContext>;
    }

    render() {
        const { data } = this.props;

        return <Fragment>
            <ListenerField listenFieldName='translations'>
                { ({ translations }) =>
                    <Field
                        name='section.type'
                        component={Select}
                        label='Тип'
                        options={SURVEY_RESPONSE_FORM_FIELD_TYPES}
                        onChange={type => this.onChangeType(type, 'section.options', translations)}
                    />
                }
            </ListenerField>
            <Field
                name='section.title'
                component={Editor}
                label='Название'
                inline
                translationName={`sections[${data.add ? data.sectionsAmount : data.index}].title`}
                translations />
            <Field
                name='section.required'
                component={Switch}
                label='Обязательное поле' />
            <FieldHelp text={`Например: {{ form['Название поля'] != 'Значение' }}`}>
                <Field
                    name={`section.hideCondition`}
                    component={Input}
                    label='Скрывать поле' />
            </FieldHelp>
            <ListenerField listenFieldName='section.type'>
                { ({ section: { type }}) => type && !includes(type, ['number', 'input', 'checkbox']) &&
                    <Form.Item label='Варианты ответа' wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
                        <FieldArray name='section.options'>
                            { this.renderOptionsFields }
                        </FieldArray>
                    </Form.Item>
                }
            </ListenerField>
            <Field name='section.options' component={() => null} />
            <SubmitButton type='primary'>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    section: yup.object().shape({
        title: yup.string().required(),
        type: yup.string().required()
    })
});

const getPatchTranslations = (values, { data: { translations, add, languages, index }}) =>
    (languages || []).map(lang => ({
        op: add ? 'add' : 'replace',
        path: add ?
            (!path([lang], translations) ? `/translations/${lang}` : !path([lang, 'sections'], translations) ? `/translations/${lang}/sections` : `/translations/${lang}/sections/-`) :
            `/translations/${lang}/sections/${index}`,
        value: add ?
            (!path([lang], translations) ? path([lang], values) : !path([lang, 'sections'], translations) ? path([lang, 'sections'], values) : last(path([lang, 'sections'], values))) :
            path([lang, 'sections', index], values)
    }));

export default withFormWrapper(SurveyResponseFieldForm, {
    mapPropsToValues: props => ({
        section: {
            ...props.data.values,
            required: props.data.add ? true : props.data.values.required
        },
        translations: props.data.add ?
            map(value => ({
                ...value,
                sections: (value.sections || []).concat({})
            }), pathOr({}, ['data', 'translations'], props)) :
            pathOr({}, ['data', 'translations'], props)
    }),
    validationSchema,
    mapBeforeSubmit: (values, props) => props.data.add ? ({
        data: [
            {
                op: 'add',
                path: '/sections/-',
                value: values.section
            },
            ...getPatchTranslations(values.translations, props)
        ],
        id: props.data.survey
    }) : ({
        id: props.data.survey,
        data: [
            {
                op: 'replace',
                path: `/sections/${props.data.index}`,
                value: values.section
            },
            ...getPatchTranslations(values.translations, props)
        ]
    })
});
