import React, { Component, Fragment } from 'react';
import { FormSpy } from 'react-final-form';
import { Button } from 'antd';
import * as yup from 'yup';

import { PdaCheckboxes } from '@planka/common';

import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import Checkbox from './formComponents/Checkbox';

class PdaForm extends Component {
    render() {
        return <Fragment>
            <p>
                Мы заботимся о ваших персональных данных в строгом соответствии со всеми требованиям российского законодательства. Пожалуйста, ознакомьтесь с нашими документами и дайте свое согласие ниже:
            </p>
            <PdaCheckboxes
                Checkbox={Checkbox}
                agreementLabel={(
                    <span>
                        Нажимая кнопку "Продолжить", подтверждаю свое согласие на обработку персональных данных
                    </span>
                )}
                contractLabel={({ toggleModal }) => (
                    <span>
                    Ознакомлен с <Button type="link" className='checkboxes' onClick={toggleModal}>Пользовательским соглашением</Button>
                    </span>
                )}
                politicsLabel={({ toggleModal }) => (
                    <span>
                    Ознакомлен с <Button type="link" className='checkboxes' onClick={toggleModal}>Политикой в отношении обработки и защиты персональных данных</Button>
                    </span>
                )}
            />
            <FormSpy subscription={{ invalid: true }}>
                { ({ invalid }) =>
                    <SubmitButton size='large' type='primary' disabled={invalid}>Продолжить</SubmitButton>
                }
            </FormSpy>
        </Fragment>
    }
}

const validationSchema = yup.object().shape({
    agreement: yup.boolean().nullable().oneOf([true]).required(),
    politics: yup.boolean().nullable().oneOf([true]).required(),
    contract: yup.boolean().nullable().oneOf([true]).required(),
});

export default withFormWrapper(PdaForm, {
    validationSchema
});
