import React, { Component } from 'react';
import { Avatar, Button, Tooltip, message } from 'antd';
import { asyncConnect } from 'react-async-client';
import { withState } from 'recompose';
import { path, has, equals } from 'ramda';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TeamOutlined, GlobalOutlined, CloseCircleOutlined, CheckCircleOutlined, PlusOutlined, StarOutlined, StarFilled } from '@ant-design/icons';

import TableList from '../table/TableList';
import { getCompanies, putCompaniesEnabled, getUser, putCompanyFavorite } from '../../../actions/asyncActions';
import { POST_COMPANY, PUT_COMPANIES_ENABLED , PUT_COMPANY_FAVORITE} from '../../../constants/actionTypes';
import { getAvatar } from '../../../constants/urls';
import CompaniesFilter from '../../forms/filters/CompaniesFilter';
import { getFilters, getUrlParams } from '../../../utils/urlParams';
import { openCompanyModal } from '../../../actions/modalActions';
import { ADMIN } from '../../../constants/roles';

const WrapTableList = styled(TableList)`
    .ant-row{
        border-bottom: 0;
    }
`;

class Companies extends Component {
    componentDidUpdate(prev) {
        if (!equals(getUrlParams(this.props.location), getUrlParams(prev.location))) {
            this.props.setSelected([]);
        }
    }

    getColumns = () => {
        return [
            {
                dataIndex: 'logo',
                key: 'logo',
                width: 50,
                render: logo => <Avatar src={getAvatar(logo)} icon={<GlobalOutlined />} />
            },
            {
                title: 'Компания',
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                render: (name, { id }) => <Link to={`/companies/${id}/info/details`}>{ name }</Link>
            },
            {
                title: 'Отрасль',
                dataIndex: 'industry',
                key: 'industry'
            },
            {
                title: 'Количество сотрудников',
                dataIndex: 'size',
                key: 'size',
                render: size => size ? <span><TeamOutlined /> { size }</span> : null
            },
            {
                key: 'actions',
                align: 'right',
                width: 80,
                render: item =>
                    <Button.Group>
                        <Tooltip placement='left' title={item.favorite ? 'Убрать из избранного' : 'Добавить в избранное'}>
                            <Button
                                icon={item.favorite ? <StarFilled /> : <StarOutlined />}
                                onClick={() => this.companyFavoriteToggle(item)} />
                        </Tooltip>
                    </Button.Group>
            }
        ];
    }

    setEnabled = enabled => {
        this.props.putCompaniesEnabled.dispatch({
            items: this.props.selected,
            enabled
        });
    }

    companyFavoriteToggle = item => {
        this.props.putCompanyFavorite.dispatch({
            id: item.id,
            favorite: !item.favorite,
        });
    }

    renderButtons = () => {
        const { selected, openCompanyModal, location, putCompaniesEnabled, isAdmin } = this.props;
        const filters = getFilters(location) || {};
        const enabled = has('enabled', filters) ? filters.enabled : true;

        return <Button.Group>
            { isAdmin &&
                <Button
                    icon={enabled ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                    type={enabled ? 'danger' : 'default'}
                    onClick={() => this.setEnabled(!enabled)}
                    loading={putCompaniesEnabled.meta.pending}
                    disabled={!selected.length}>
                    <span className='hide-mobile'>{ enabled ? 'Заблокировать' : 'Разблокировать'}</span>
                </Button>
            }
            <Button icon={<PlusOutlined />} type='primary' onClick={() => openCompanyModal()}>
                <span className='hide-mobile'>Добавить</span>
            </Button>
        </Button.Group>
    }

    setSelected = selected => this.props.setSelected(selected);

    render() {
        return <WrapTableList
            action={getCompanies}
            columns={this.getColumns()}
            refreshActions={[PUT_COMPANIES_ENABLED, POST_COMPANY, PUT_COMPANY_FAVORITE]}
            externalPending={this.props.putCompanyFavorite.meta.pending}
            filterForm={CompaniesFilter}
            initFilters={{ enabled: true }}
            buttons={this.renderButtons()}
            rowSelection={{
                selectedRowKeys: this.props.selected,
                onChange: this.setSelected,
                type: 'checkbox'
            }} />;
    }
}

const stateToProps = state => ({
    isAdmin: getUser.selectData(state).role === ADMIN
});

export default withState('selected', 'setSelected', [])(
    asyncConnect({
        putCompanyFavorite: putCompanyFavorite
            .withSuccessHandler((props, { requestAction }) => message.success(
                path(['payload', 'favorite'], requestAction) ? 'Компания успешно добавлена в избранные'
                : 'Компания удалена из избранных'
            ))
            .withErrorHandler(() => message.error('Не удалось изменить состояние'))
            .withOptions({ resetOnUnmount: true }),
        putCompaniesEnabled: putCompaniesEnabled
            .withSuccessHandler(({ setSelected }) => {
                message.success('Состояние успешно изменено');
                setSelected([]);
            })
            .withErrorHandler(() => message.error('Не удалось изменить состояние'))
            .withOptions({ resetOnUnmount: true })
    }, stateToProps, { openCompanyModal })(Companies)
);
