import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { MailOutlined, PhoneOutlined, PlusOutlined } from '@ant-design/icons';

import TableList from './table/TableList';
import Username from './table/Username';
import { getExperts } from '../../actions/asyncActions';
import { openExpertModal } from '../../actions/modalActions';
import ExpertsFilter from '../forms/filters/ExpertsFilter';
import { POST_EXPERT } from '../../constants/actionTypes';

class Experts extends Component {
    getColumns = () => {
        return [
            {
                title: 'Эксперт',
                key: 'lastName',
                sorter: true,
                render: (item) =>
                    <Link to={`/experts/${item.id}`}>
                        <Username user={item} />
                    </Link>
            },
            {
                title: 'Контакты',
                dataIndex: 'email',
                key: 'email',
                render: (email, { phone }) =>
                    <Fragment>
                        <div><MailOutlined /> { email }</div>
                        { phone && <div><PhoneOutlined /> { phone }</div> }
                    </Fragment>
            }
        ];
    };

    addExpert = () => this.props.openExpertModal();

    renderButtons = () => {
        return <Button.Group>
            <Button type='primary' icon={<PlusOutlined />} onClick={this.addExpert}>
                <span className='hide-mobile'>Добавить</span>
            </Button>
        </Button.Group>;
    }

    render() {
        return <TableList
            action={getExperts}
            columns={this.getColumns()}
            filterForm={ExpertsFilter}
            buttons={this.renderButtons()}
            refreshActions={[POST_EXPERT]} />
    }
}

export default connect(null, { openExpertModal })(Experts);
