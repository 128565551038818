export const TABLE_LOADING_DELAY = 200;
export const LINES_PER_PAGE = 10;

export const ENABLED = [
    { id: true, value: 'Активные' },
    { id: false, value: 'Заблокированные' }
];

export const ORDER_TYPES = {
    descend: 'desc',
    ascend: 'asc'
};
