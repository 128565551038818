import React, { Component } from 'react';
import { message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import AdminForm from '../forms/AdminForm';
import { postAdmin, putAdmin } from '../../actions/asyncActions';
import Modal from './Modal';

class AdminModal extends Component {
    render() {
        const { modal, params, putAdmin, postAdmin } = this.props;

        return <Modal
            {...modal}
            title={params ? 'Редактировать администратора' : 'Добавить администратора'}
            footer={null}>
            <AdminForm
                formAction={params ? putAdmin : postAdmin}
                item={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    postAdmin: postAdmin
        .withSuccessHandler(({ close }) => {
            message.success('Администратор успешно добавлен');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
    putAdmin: putAdmin
        .withSuccessHandler(({ close }) => {
            message.success('Администратор успешно изменен');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(AdminModal);
