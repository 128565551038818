import React, { Component } from 'react';
import { ChromePicker } from 'react-color';
import styled from 'styled-components';
import { prop } from 'ramda';

const Swatch = styled.div`
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: pointer;
`;

const SwatchColor = styled.div`
    width: 36px;
    height: 14px;
    border-radius: 2px;
    background: ${prop('color')}
`;

const Popover = styled.div`
    position: absolute;
    z-index: 2;
`;

const Cover = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
`;

const Wrapper = styled.div`
    position: relative;
`;

export default class ColorPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false,
            value: props.value
        };
    }

    toggle = () => this.setState(prev => ({ opened: !prev.opened }));

    close = () => this.setState({ opened: false });

    onChange = ({ hex }) => {
        this.setState({ value: hex });
        this.props.onChange(hex);
    }

    render() {
        const { value, opened } = this.state;

        return <Wrapper>
            <Swatch onClick={this.toggle}>
                <SwatchColor color={value} />
            </Swatch>
            { opened ?
                <Popover>
                    <Cover onClick={this.close}/>
                    <ChromePicker color={value} onChange={this.onChange} disableAlpha />
                </Popover> : null
            }
        </Wrapper>;
    }
}
