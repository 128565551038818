import React, { Component } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import styled from 'styled-components';
import { pathOr } from 'ramda';
import 'react-quill/dist/quill.bubble.css';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import { COMPANY_TEMPLATE_VARIABLES } from '../../../constants/companies';
import SpanAsLink from '../../user/table/SpanAsLink';

const Container = styled.div`
    display: flex;
    align-items: start;
`;

const EditorWrapper = styled.div`
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    width: calc(100% - 250px);
    .ql-tooltip {
        z-index: 1000;
    }
    .variable-text {
        color: #1890ff;
    }
    .ql-editor{
        min-height: 296px;
    }
`;

const Variables = styled.div`
    width: 240px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-left: 10px;
    padding: 15px;
    h3{
        margin: -15px -14px 15px!important;
    }
`;

const Embed = Quill.import('blots/embed');
const Parchment = Quill.import('parchment');

class QuillVariable extends Embed {
    static create(value) {
        let node = super.create(value);

        node.setAttribute('data-var', value.var);
        node.setAttribute('data-title', value.title);
        node.innerText = value.title;

        return node;
    }

    static value(node) {
        return {
            var: node.dataset.var,
            title: node.dataset.title
        };
    }
}

QuillVariable.blotName = 'variable';
QuillVariable.className = 'variable-text';
QuillVariable.tagName = 'span';
Quill.register({
    'formats/hr': QuillVariable
});

const modules = {
    toolbar: [
        ['bold', 'italic', 'underline']
    ]
};

const formats = [
    'bold', 'italic', 'underline', 'variable'
];

class TemplateEditor extends Component {
    static defaultProps = {
        options: COMPANY_TEMPLATE_VARIABLES,
    };

    componentDidMount() {
        this.quill.editor.root.addEventListener('click', (ev) => {
            const variable = Parchment.find(ev.target, true);

            if (variable instanceof QuillVariable) {
                this.quill.editor.setSelection(variable.offset(this.quill.editor.scroll), 1, 'user');
            }
        });
    }

    onChange = value => {
        this.props.onChange(value);
    }

    addVariable = item => {
        const cursorPosition = pathOr(0, ['index'], this.quill.editor.getSelection());

        this.quill.editor.insertEmbed(cursorPosition, 'variable', item);
        this.quill.editor.setSelection(cursorPosition + 1)
    }

    onMouseDown = e => {
        e.preventDefault();
        e.stopPropagation();
    }

    render() {
        return <Container>
            <EditorWrapper className="editor-wrap">
                <ReactQuill
                    ref={node => this.quill = node}
                    value={this.props.input.value}
                    onChange={this.onChange}
                    theme='bubble'
                    modules={modules}
                    formats={formats} />
            </EditorWrapper>
            <Variables>
                <h3>Вставить переменную</h3>
                { this.props.options.map(item =>
                    <div key={item.var}>
                        <SpanAsLink
                            onClick={() => this.addVariable(item)}
                            onMouseDown={this.onMouseDown}>
                            { item.title }
                        </SpanAsLink>
                    </div>
                )}
            </Variables>
        </Container>;
    }
}

export default withFieldWrapper(TemplateEditor);
