import * as R from 'ramda';

export const mapObjectKeys = R.curry((fn, obj) =>
    R.fromPairs(R.map(R.adjust(0, fn), R.toPairs(obj))));

export const isArray = R.is(Array);

export const isFunction = R.is(Function);

export const isPlainObject = v => R.is(Object, v) && v.constructor === Object;
