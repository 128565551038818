import React, { Component, Fragment } from 'react';
import { asyncConnect, toSuccess } from 'react-async-client';
import { Wrapper, CategoriesWrapper, Toggler, TableWrapper, QuestionsHeader } from './questions/Questions';
import { LeftOutlined, RightOutlined, PlusOutlined, DeleteOutlined, FileOutlined, FireOutlined } from '@ant-design/icons';
import { Table, Button, message, Popconfirm, Badge, Tooltip, Input } from 'antd';
import { path, find, propEq, filter, includes, pathOr } from 'ramda';
import { takeEvery } from 'redux-saga/effects';
import { connect } from 'react-redux';
import styled from 'styled-components';
import cx from 'classnames';
import { withStateHandlers } from 'recompose';
import { withRouter } from 'react-router';

import Categories from './questions/Categories';
import { getCategoryRecommendations, putCategoryRecommendations } from '../../actions/asyncActions';
import { openCategoryRecommendationModal } from '../../actions/modalActions';
import { getFilters, getUrlParams, extendSearchPath } from '../../utils/urlParams';
import { PUT_CATEGORY_RECOMMENDATIONS } from '../../constants/actionTypes';
import { QUESTION_LEVELS } from '../../constants/questions';
import SpanAsLink from './table/SpanAsLink';
import Toolbar from './table/Toolbar';

const RecommendationsTableWrapper = styled.div`
    .ant-table-wrapper {
        border: none;
    }
`;

const ImportanceIcon = styled(FireOutlined)`
    color: ${({ important }) => important ? '#f54d2e' : '#696e75'} !important;
    display: ${({ important }) => important ? 'block' : 'none'};
    margin: 0;
    position: absolute;
    padding: 5px;
    top: 5px;
    color: #696e75;
    border-radius: 3px;
    border: 1px solid transparent;
    right: 0;
    &:hover{
        color: #000;
        background-color: #f4f5f7;
        border-color: #eaeaea;
    }
`;

const CategoriesWrapperStyled = styled(CategoriesWrapper)`
    .ant-tree-treenode:not(.tree-node-has-children) {
        position: relative;
        .ant-tree-switcher-line-icon.anticon-file {
            display: none;
        }
        &:not(.tree-node-level-0) .ant-tree-switcher:before {
            content: '';
            border-right: 1px solid #d9d9d9;
            position: absolute;
            top: 0;
            left: 35px;
            height: 36px;
        }
    }
    .ant-tree-treenode:hover ${ImportanceIcon} {
        display: block;
    }
`;

const TableTextColumn = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: ${({ width }) => width}px;
`;

class RecommendationsTableComponent extends Component {
    state = {
        tableTextWidth: 0
    };

    componentDidMount() {
        this.setTableTextWidth();
        window.addEventListener('resize', this.setTableTextWidth);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setTableTextWidth);
    }

    setTableTextWidth = () => this.setState({ tableTextWidth: document.querySelector('.recommendations-table-wrapper').clientWidth - 350 });

    delete = () => {
        const { getCategoryRecommendations: { data }, putCategoryRecommendations, category } = this.props;
        const indexes = this.props.selected.map(id => Number(id.split('-')[1]));
        const recommendations = filter(item => !!item, data.recommendations.map((item, i) => includes(i, indexes) ? null : item));

        putCategoryRecommendations.dispatch({
            id: category,
            data: {
                ...data,
                recommendations
            }
        });
    }

    getColumns = () => {
        return [
            {
                title: 'Описание',
                dataIndex: 'text',
                key: 'text',
                render: (text, item, index) => <SpanAsLink
                    onClick={() => this.props.openCategoryRecommendationModal({
                        id: this.props.category,
                        index
                    })}>
                    <TableTextColumn width={this.state.tableTextWidth}>{ text }</TableTextColumn>
                </SpanAsLink>
            },
            {
                title: 'Категория',
                dataIndex: 'category',
                key: 'category',
                align: 'center',
                width: 300,
                render: (category, { complexityLevel }) => <Fragment>
                    <div>{ category }</div>
                    <div>{ path(['icon'], find(propEq('id', complexityLevel), QUESTION_LEVELS)) }</div>
                </Fragment>
            }
        ];
    }

    setSelected = selected => this.props.setSelected(selected);

    getButtons = () => {
        return this.props.selected.length ?
            <Popconfirm
                title='Вы уверены, что хотите удалить выделенные рекомендации?'
                okText='Да'
                cancelText='Нет'
                onConfirm={this.delete}
                placement='left'>
                <Button
                    icon={<DeleteOutlined />}
                    danger>
                    Удалить
                </Button>
            </Popconfirm> :
            <Button
                icon={<DeleteOutlined />}
                disabled
                danger>
                Удалить
            </Button>;
    }

    onChangeSearch = e => this.props.setText(e.target.value);

    onSearch = () => {
        const { location, history, text } = this.props;

        history.replace(extendSearchPath(location, {
            text
        }));
    }

    getSearch = () => {
        return <Input.Search
            placeholder='Поиск'
            value={this.props.text}
            onChange={this.onChangeSearch}
            onSearch={this.onSearch} />;
    }

    render() {
        const { getCategoryRecommendations: { data, meta }} = this.props;

        return <RecommendationsTableWrapper>
            <Toolbar
                filterForm={this.getSearch()}
                buttons={this.getButtons()} />
            <Table
                columns={this.getColumns()}
                dataSource={data.recommendations}
                pagination={false}
                loading={meta.pending}
                scroll='calc(100vh - 315px)'
                rowKey={(item, index) => `recommendation-${index}`}
                rowSelection={{
                    selectedRowKeys: this.props.selected,
                    onChange: this.setSelected,
                    type: 'checkbox'
                }} />
        </RecommendationsTableWrapper>;
    }
}

const RecommendationsTable = withRouter(
    withStateHandlers(({ location }) => ({
        selected: [],
        text: getUrlParams(location).text || ''
    }), {
        setSelected: () => selected => ({ selected }),
        setText: () => text => ({ text })
    })(
        asyncConnect({
            getCategoryRecommendations: getCategoryRecommendations
                .withParams(({ category }) => ({ category, type: 'table' }))
                .withPayload(({ category, location }) => ({
                    category,
                    params: {
                        q: { text: getUrlParams(location).text }
                    }
                }))
                .withSuccessHandler(({ setSelected, selected }) => selected.length && setSelected([]))
                .withSaga(function* (getProps) {
                    yield takeEvery([toSuccess(PUT_CATEGORY_RECOMMENDATIONS)], function(action) {
                        const { getCategoryRecommendations } = getProps();

                        if (getProps().category === action.requestAction.params.category) {
                            getCategoryRecommendations.refresh();
                        }
                    })
                })
                .withOptions({ dispatchOnMount: true, dispatchOnUpdate: true }),
            putCategoryRecommendations: putCategoryRecommendations
                .withParams(({ category }) => ({ type: 'delete', category }))
                .withSuccessHandler(({ setSelected }) => {
                    message.success('Выделенные рекомендации успешно удалены');
                    setSelected([]);
                })
                .withErrorHandler(() => message.error('Не удалось удалить рекомендацию'))
                .withOptions({ resetOnUnmount: true })
        })(RecommendationsTableComponent)
    )
);

class ImportanceToggleComponent extends Component {
    toggleImportance = () => {
        const { putCategoryRecommendations, getCategoryRecommendations: { data }, category } = this.props;

        putCategoryRecommendations.dispatch({
            id: category,
            data: {
                ...data,
                important: !data.important
            }
        });
    }

    render() {
        const { getCategoryRecommendations: { data }} = this.props;

        return <Tooltip title={data.important ? 'Отметить как неважную' : 'Отметить как важную'}>
            <ImportanceIcon onClick={this.toggleImportance} important={data.important} />
        </Tooltip>;
    }
}

const ImportanceToggle = asyncConnect({
    getCategoryRecommendations: getCategoryRecommendations
        .withParams(({ category }) => ({ category }))
        .withPayload(({ category }) => ({ category }))
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_CATEGORY_RECOMMENDATIONS)], function(action) {
                const { getCategoryRecommendations } = getProps();

                if (getProps().category === action.requestAction.params.category) {
                    getCategoryRecommendations.refresh();
                }
            })
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true }),
    putCategoryRecommendations: putCategoryRecommendations
        .withParams(({ category }) => ({ type: 'importance', category }))
        .withErrorHandler(() => message.error('Не удалось изменить важность'))
        .withOptions({ resetOnUnmount: true })
})(ImportanceToggleComponent);

class CategoryRecomendationsTextComponent extends Component {
    render() {
        const { getCategoryRecommendations: { data, meta }} = this.props;

        return meta.lastSucceedAt &&!!(data.recommendations || []).length &&
            <div>
                <FileOutlined />{ data.recommendations.length }
            </div>;
    }
}

const CategoryRecomendationsText = asyncConnect({
    getCategoryRecommendations: getCategoryRecommendations
        .withParams(({ category }) => ({ category }))
})(CategoryRecomendationsTextComponent);

class Recommendations extends Component {
    state = {
        collapsed: false
    };

    toggleCollapsed = () => this.setState(prev => ({ collapsed: !prev.collapsed }));

    editCategoryButtons = item => {
        return <ImportanceToggle category={item.id} />;
    }

    renderCategoryText = item => {
        return <CategoryRecomendationsText category={item.id} />;
    }

    render() {
        const { openCategoryRecommendationModal, location, count } = this.props;
        const { collapsed } = this.state;
        const category = path(['categories', 0], getFilters(location));

        return  <Wrapper>
            <CategoriesWrapperStyled collapsed={collapsed}>
                <Categories
                    selectOnly
                    maxLevel={1}
                    selectableLevels={[1]}
                    editButtons={this.editCategoryButtons}
                    renderComplexity={this.renderCategoryText}
                    treeClassName={(item, level) => cx(`tree-node-level-${level}`, { 'tree-node-has-children': item.childrenCount > 0 })} />
                <Toggler onClick={this.toggleCollapsed}>
                    {collapsed ? <LeftOutlined /> : <RightOutlined />}
                </Toggler>
            </CategoriesWrapperStyled>
            <TableWrapper collapsed={collapsed} className='recommendations-table-wrapper'>
                <QuestionsHeader>
                    <span>Рекомендации <Badge style={{ backgroundColor: '#f54d2e' }} count={count} overflowCount={1000000} /></span>
                    { category &&
                        <Button
                            icon={<PlusOutlined />}
                            type='primary'
                            onClick={() => openCategoryRecommendationModal({
                                id: category,
                                add: true
                            })}>
                            Добавить
                        </Button>
                    }
                </QuestionsHeader>
                { category ?
                    <RecommendationsTable category={category} openCategoryRecommendationModal={openCategoryRecommendationModal} /> :
                    <div style={{ marginTop: 20, textAlign: 'center' }}>Выберите категорию</div>
                }
            </TableWrapper>
        </Wrapper>;
    }
}

const stateToProps = state => ({
    count: pathOr([], ['recommendations'], getCategoryRecommendations.selectData(state)).length
})

export default connect(stateToProps, { openCategoryRecommendationModal })(Recommendations);
