import React, { Component, Fragment } from 'react';
import { withAsyncActions } from 'react-async-client';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import LoginForm from '../forms/LoginForm';
import { postLogin } from '../../actions/asyncActions';
import { DEFAULT_LOGIN_ERROR } from '../../constants/errors';
import LogoImg from '../../assets/img/logo_red.svg';

export const Logo = styled.div`
    display: inline-block;
    background-image: url("${LogoImg}");
    background-repeat: no-repeat;
    width: 220px;
    height: 55px;
    background-size: contain;
    @media screen and (orientation: landscape) and (max-width: 896px) {
        width: 160px;
        height: 40px;
    }
`;

export const H3 = styled.h3`
    margin: 20px 0 25px;
    @media screen and (orientation: landscape) and (max-width: 896px) {
        margin: 10px 0 15px;
    }
`;

class Login extends Component {
    render() {
        return <Fragment>
            <Logo />
            <H3>Вход</H3>
            <LoginForm formAction={this.props.postLogin} defaultError={DEFAULT_LOGIN_ERROR} />
            <Link style={{ marginTop: 15, display: 'inline-block' }} to='/recovery'>Восстановить пароль</Link>
        </Fragment>;
    }
}

export default withAsyncActions({
    postLogin: postLogin
        .withOptions({ resetOnUnmount: true })
})(Login);
