import React, { Component } from 'react';
import { withAsyncActions, toSuccess, asyncConnect } from 'react-async-client';
import { connect } from 'react-redux';
import { Button, message, Popconfirm } from 'antd';
import { takeEvery } from 'redux-saga/effects';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { getRespondentScreening, deleteRespondentScreening, getUser } from '../../../actions/asyncActions';
import { openTestRespondentModal } from '../../../actions/modalActions';
import { ProjectRespondent } from '../projects/ProjectRespondent';
import { PUT_RESPONDENT_SCREENING } from '../../../constants/actionTypes';
import { ADMIN } from '../../../constants/roles';

class TestRespondentButtonsComponent extends Component {
    delete = () => {
        const { deleteRespondentScreening, match: { params: { itemId }}} = this.props;

        deleteRespondentScreening.dispatch(itemId);
    }

    edit = () => {
        const { data, openTestRespondentModal } = this.props;

        openTestRespondentModal(data);
    }

    render() {
        return <Button.Group>
            <Button type='primary' icon={<EditOutlined />} onClick={this.edit} disabled={this.props.pending}>
                <span className='hide-mobile'>Редактировать</span>
            </Button>
            <Popconfirm
                okText='Да'
                cancelText='Нет'
                title='Вы уверены, что хотите удалить респондента?'
                onConfirm={this.delete}>
                <Button type='danger' icon={<DeleteOutlined />}>
                    <span className='hide-mobile'>Удалить</span>
                </Button>
            </Popconfirm>
        </Button.Group>
    }
}

const stateToProps = state => ({
    data: getRespondentScreening.selectData(state),
    pending: getRespondentScreening.selectMeta(state).pending
});

export const TestRespondentButtons = connect(stateToProps, { openTestRespondentModal })(withAsyncActions({
    deleteRespondentScreening: deleteRespondentScreening
        .withSuccessHandler(({ history }) => {
            message.success('Респондент успешно удален');
            history.goBack();
        })
        .withErrorHandler(() => message.error('Не удалось удалить респондента'))
        .withOptions({ resetOnUnmount: true })
})(TestRespondentButtonsComponent));

class TestRespondent extends Component {
    render() {
        return <ProjectRespondent {...this.props} testTemplate />;
    }
}

const testRespondentStateToProps = state => ({
    isAdmin: getUser.selectData(state).role === ADMIN
});

export default asyncConnect({
    getRespondent: getRespondentScreening
        .withPayload(({ match: { params: { itemId }}}) => itemId)
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_RESPONDENT_SCREENING)], function() {
                const { getRespondent } = getProps();

                getRespondent.refresh();
            })
        })
        .withOptions({ resetOnUnmount: true, dispatchOnMount: true })
}, testRespondentStateToProps)(TestRespondent);
